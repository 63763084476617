import React from 'react';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import TooltipWrapper from 'components/TooltipWrapper';

const PercentToolTip = ({ i18n, ...props }) => (
  <Box>
    <Text
      fs={props.showFormWithSteps ? 14 : 16}
      fw400={!props.showFormWithSteps}
      fw600={props.showFormWithSteps}
      bold
      lh={1.25}
      navy
      i18n={i18n}
    />

    <sup>
      <TooltipWrapper
        trigger="hover"
        preferPlace="above"
        theme="white"
        {...props}
      >
        <Icon size="sm" type="questionCircle" />
      </TooltipWrapper>
    </sup>
  </Box>
);

export default PercentToolTip;
