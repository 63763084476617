import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { getSchema } from 'fe-core/Entities/schema';

// to remove entityId (fromId) from relatedEntity (state[toType]?.[toId]) relationships
// it also deletes all relatedEntities that BELONGS_TO removed entity
const destroyEntityRelationship = (
  state,
  fromType,
  fromId,
  relationshipName,
  toType,
  toId
) => {
  const fromSchema = getSchema(fromType);
  const toSchema = getSchema(toType);

  if (!fromSchema || !toSchema) return;

  const { inverseOf } = fromSchema.relationships[relationshipName];

  const { relationshipType } = toSchema.relationships[inverseOf];
  const relatedEntity = state[toType]?.[toId];

  if (!relatedEntity) return;

  // if HAS_MANY, remove from relationships.data array
  if (relationshipType === HAS_MANY) {
    const list = relatedEntity.relationships[inverseOf]?.data || [];

    relatedEntity.relationships[inverseOf] = {
      data: list.filter(
        item => !(item.type === fromType && item.id === fromId)
      ),
    };
    return;
  }

  // delete relatedEntity, as its parent will be deleted
  // TODO:fe-core is this safe, what if that relatedEntity is referenced as relationship anywhere
  if (relationshipType === BELONGS_TO) {
    delete state[toType][toId];
    return;
  }

  throw new Error(
    `SCHEMA ERROR: Inverse relationship mismatch between ${fromType} and ${toType}`
  );
};

export default destroyEntityRelationship;
