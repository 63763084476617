import { fromJS, List, Map } from 'immutable';

import { actionTypes } from 'actions/teamEmails';

const INITIAL_STATE = Map({
  announcements: List(),
  currentEmailId: null,
  showNewEmailView: false,
  isNewEmailSubmitting: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ANNOUNCEMENT: {
      const index = state
        .get('announcements')
        .findIndex(a => a.get('id') === action.payload.id);
      return state.mergeIn(['announcements', index], action.payload.params);
    }
    case actionTypes.CREATE_ACKNOWLEDGMENT_SUCCESS: {
      const index = state
        .get('announcements')
        .findIndex(a => a.get('id') === action.meta.id);
      return state.setIn(
        ['announcements', index, 'needs_acknowledgement'],
        false
      );
    }

    case actionTypes.FETCH_TEAM_EMAILS_SUCCESS:
      return state.merge({
        announcements: state
          .get('announcements')
          .concat(fromJS(action.payload)),
      });

    case actionTypes.FETCH_TEAM_ANNOUNCEMENTS_SUCCESS:
      return state.set('announcements', fromJS(action.payload));

    case actionTypes.SEND_TEAM_EMAILS_REQUEST: {
      return state.merge({ isNewEmailSubmitting: true });
    }

    case actionTypes.SEND_TEAM_EMAILS_FAILURE:
      return state.merge({ isNewEmailSubmitting: false });

    case actionTypes.UNPIN_TEAM_ANNOUNCEMENT_SUCCESS: {
      const emails = state.get('announcements').toJS();
      const index = emails.findIndex(email => email.id === action.meta.id);
      emails[index] = { ...emails[index], pinned: false };

      return state.merge({
        announcements: state.get('announcements').merge(fromJS(emails)),
      });
    }

    case actionTypes.ADD_NEW_ANNOUNCEMENT: {
      return state
        .merge({
          isNewEmailSubmitting: false,
        })
        .setIn(
          ['announcements'],
          state.get('announcements').push(fromJS(action.payload))
        );
    }

    case actionTypes.ADD_NEW_EMAILS: {
      const existingEmails = state.get('announcements').toJS();
      const newlySentEmails = action.payload.emails;

      // If the user pinned the newly-sent email, unpin any existing emails that are pinned at
      // the locations in which the newly-sent emails were pinned
      if (newlySentEmails[0].pinned === true) {
        const newlySentLocationIds = newlySentEmails.map(
          emailToAdd => emailToAdd.location_id
        );
        const existingEmailsToUpdate = existingEmails.filter(email =>
          newlySentLocationIds.includes(email.location_id)
        );

        existingEmailsToUpdate.map(emailToUpdate => {
          const index = existingEmails.findIndex(
            email => email.id === emailToUpdate.id
          );

          return (existingEmails[index] = {
            ...existingEmails[index],
            pinned: false,
          });
        });
      }

      // Insert new emails at the beginning of the list - `action.emails` is
      // an array of emails, one for each selected location.
      return state.merge({
        announcements: fromJS(newlySentEmails).concat(fromJS(existingEmails)),
        isNewEmailSubmitting: false,
      });
    }

    case actionTypes.SET_CURRENT_EMAIL_ID:
      return state.merge({ currentEmailId: action.payload.id });

    case actionTypes.SHOW_NEW_EMAIL_VIEW:
      return state.merge({ showNewEmailView: action.payload.showNewEmailView });

    case actionTypes.CLEAR_TEAM_EMAILS:
      return INITIAL_STATE;

    default:
      return state;
  }
};
