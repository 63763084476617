import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import * as entitiesSelectors from 'selectors/entities';
import { getIsFetching } from 'selectors/fetching';
import * as sessionSelectors from 'selectors/session';

import {
  fetchingInitialMessagesRequestId,
  fetchingMoreMessagesRequestId,
} from 'util/applicantMessenger';
import { momentInZone } from 'util/dateTime';

const buildGetApplicantMessenger = key => state =>
  state.getIn(['applicantMessenger', key]);

export const getCurrentApplicationId =
  buildGetApplicantMessenger('applicationId');
export const getApplicationLoading =
  buildGetApplicantMessenger('applicationLoading');
export const getUseLinkInHeader = buildGetApplicantMessenger('useLinkInHeader');
export const getApplications = entitiesSelectors.getApplications;

export const getCurrentApplication = createSelector(
  entitiesSelectors.getApplications,
  getCurrentApplicationId,
  (applications, applicationId) =>
    applications.get((applicationId || '').toString(), Map())
);

export const getCurrentConversation = createSelector(
  entitiesSelectors.getApplicationConversations,
  getCurrentApplicationId,
  (applicationConversations, applicationId) =>
    applicationConversations.find(
      conversation => conversation.get('job_application_id') === applicationId
    ) || Map()
);

export const getCurrentConversationId = createSelector(
  getCurrentConversation,
  conversation => conversation.get('id')
);

export const hasUnreadApplicationConversations = createSelector(
  entitiesSelectors.getApplicationConversations,
  conversations =>
    conversations.some(conversation => conversation.get('has_unread'))
);

export const sortedMessagesToDecoratedList = (
  sortedMessages,
  currentUserId,
  opts = {}
) => {
  let messageList = List();
  let prevMessage;

  sortedMessages.forEach(message => {
    if (!prevMessage) {
      // If the first message in the set, show title and avatar
      message = message.merge({ showDayTitle: true, showAvatar: true });
    } else {
      // Otherwise, compare current and previous messages to determine how they should render
      const mPrevious = momentInZone(prevMessage.get('created_at_sec'), 'unix');
      const mCurrent = momentInZone(message.get('created_at_sec'), 'unix');
      const diffDays = !mPrevious.isSame(mCurrent, 'day');
      const diffMinutes = !mPrevious.isSame(mCurrent, 'minute');
      const diffSenders =
        prevMessage.get('sending_user_id') !== message.get('sending_user_id');

      message = message.merge({
        showDayTitle: diffDays,
        showAvatar: diffDays || diffSenders,
      });

      prevMessage = prevMessage.merge({
        showDetails: diffDays || diffSenders || diffMinutes,
      });

      messageList = messageList.push(prevMessage);
    }

    // If mine, compute read status from received message statuses
    if (!opts.noReadStatus && message.get('mine')) {
      let readCounter = 0;

      message.get('received_messages').forEach(rm => {
        if (
          rm.get('user_id') !== currentUserId &&
          rm.get('status') === 'read'
        ) {
          readCounter += 1;
        }
      });

      message = message.merge({
        readCounter,
        participantsCounter: message.get('received_messages').size - 1,
      });
    }

    prevMessage = message;
  });

  messageList = messageList.push(prevMessage.set('showDetails', true));

  return messageList;
};

export const getCurrentMessages = createSelector(
  entitiesSelectors.getApplicationMessages,
  getCurrentConversation,
  sessionSelectors.getCurrentUserId,
  (messages, conversation, currentUserId) => {
    const messageIds = conversation.get('application_messages');
    if (!messageIds) {
      return List();
    }

    const sortedMessages = messageIds
      .map(messageId => {
        const message = messages.get(messageId.toString());
        const mine =
          message.get('sender_type') === 'Account' &&
          message.get('sender_id').toString() === currentUserId.toString();

        return message.merge({ mine });
      })
      .sortBy(message => message.get('created_at_sec'));

    return sortedMessagesToDecoratedList(sortedMessages, currentUserId, {
      noReadStatus: true,
    });
  }
);

const isFetchingMessagesForApplication = isInitial => (state, props) => {
  const conversationId = getCurrentConversationId(state, props);
  if (!conversationId) {
    return false;
  }

  const requestId = isInitial
    ? fetchingInitialMessagesRequestId(conversationId)
    : fetchingMoreMessagesRequestId(conversationId);

  return getIsFetching(state, { requestId });
};

export const getIsFetchingInitialMessagesForApplication =
  isFetchingMessagesForApplication(true);
export const getIsFetchingMoreMessagesForApplication =
  isFetchingMessagesForApplication(false);
