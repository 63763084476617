import { createSelector } from 'reselect';

import { getCurrentCompany } from 'selectors/session';

const getBulkRows = state => state.getIn(['bulkAdd', 'rows']);
export const getBulkRowsCount = state => getBulkRows(state).size;

export const getColumnValue = createSelector(
  getBulkRows,
  (state, props) => props.rowIndex,
  (state, props) => props.column,
  (rows, index, column) => {
    const value = rows.getIn([index, column, 'value']);
    return value !== null && value !== undefined ? value : '';
  }
);

const getColumnData = attr =>
  createSelector(
    getBulkRows,
    (state, props) => props.rowIndex,
    (state, props) => props.column,
    (rows, index, column) => rows.getIn([index, column, attr])
  );

export const getColumnDisabled = getColumnData('disabled');
export const getColumnFieldMetaData = getColumnData('meta');
export const getColumnError = getColumnData('error');

export const getLocationOptions = createSelector(getCurrentCompany, company =>
  company
    .get('locations')
    .map(l => ({ value: l.get('id'), label: l.get('name') }))
    .toArray()
);

export const getChangedRows = createSelector(getBulkRows, rows =>
  rows.filter(
    row =>
      row.getIn(['first_name', 'value']) &&
      row.some(cell => cell.get('changed') && cell.get('value'))
  )
);

export const getChangedRowsData = createSelector(getBulkRows, rows =>
  rows
    .map((r, i) => r.set('row_id', i))
    .filter(
      row =>
        row.getIn(['first_name', 'value']) &&
        row.some(cell => cell.get && cell.get('changed') && cell.get('value'))
    )
);

export const getChangedRowsCount = createSelector(
  getChangedRows,
  changedRows => changedRows.size
);

export const getBulkRowsHaveError = createSelector(getChangedRows, rows =>
  rows.some(row => row.some(cell => cell.get('error')))
);
