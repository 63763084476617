import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgAttach = ({
  alt = 'Attach Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M18.572 3.928a2.931 2.931 0 0 0-4.143 0l-8.625 8.625a5.05 5.05 0 0 0 0 7.144 5.05 5.05 0 0 0 7.143 0l7.125-7.125a1.315 1.315 0 0 1 1.857 0c.51.511.51 1.345 0 1.856l-7.125 7.125c-3 3-7.857 3-10.857 0-3-3-3-7.856 0-10.856l8.625-8.625a5.554 5.554 0 0 1 7.857 0 5.554 5.554 0 0 1 0 7.856l-8.25 8.25a3.435 3.435 0 0 1-4.857-4.856l6.75-6.75a1.318 1.318 0 0 1 1.857 0c.51.511.51 1.345 0 1.856l-6.75 6.75a.812.812 0 0 0 0 1.144.812.812 0 0 0 1.143 0l8.25-8.25a2.931 2.931 0 0 0 0-4.144Z"
    />
  </svg>
);
export default SvgAttach;
