import * as routes from 'api';
import { postJSON } from 'api/fetch';

export const sendFeatureFeedbackRequest = ({
  featureName,
  rating,
  comments,
  categories,
}) =>
  postJSON(routes.featureFeedback(), {
    feature_feedback: {
      feature_name: featureName,
      rating,
      comments,
      categories,
    },
  });
