const STRICT_URLS = {
  '/payroll/tax_returns': {
    strict: false,
  },
};

export const checkStrictSubRoutes = (url, strictMatch) =>
  STRICT_URLS[url] ? STRICT_URLS[url].strict : strictMatch;

export const checkHighlightTopMenu = (menuItem, pathname) =>
  menuItem.get('highlighted_top_menu') && menuItem.get('url') === pathname;
