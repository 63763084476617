import { colors, typography } from 'theme/constants';

export const DEFAULT_STYLES = Object.freeze({
  fullWidth: true,
  FormHelperTextProps: {
    error: false,
  },
  InputLabelProps: {
    style: {
      color: colors.grayDark,
      fontSize: typography.fs18,
      fontWeight: typography.fw400,
    },
    classes: {
      focused: 'TextField--label-default-focused',
    },
    shrink: false,
    focused: false,
  },
  InputProps: {
    disableUnderline: true,
  },
  inputProps: {
    style: {
      color: colors.navy,
      fontSize: typography.fs16,
    },
  },
  style: {
    height: 35,
  },
});

const HIRING_BASE_STYLE = Object.freeze({
  ...DEFAULT_STYLES,
  FormHelperTextProps: {
    error: false,
  },
  InputLabelProps: {
    style: {
      color: colors.grayDark,
      fontSize: typography.fs18,
      fontWeight: typography.fw400,
      top: 0,
    },
    classes: {
      focused: 'TextField--label-hiring-focused',
    },
    shrink: false,
    focused: false,
  },
  InputProps: {
    disableUnderline: false,
  },
  inputProps: {
    style: {
      color: colors.navy,
      fontSize: typography.fs16,
      marginTop: -6,
      borderWidth: 0,
      padding: 0,
      background: 'transparent',
    },
  },
  style: {
    height: 65,
  },
});

export const THEME_STYLES = Object.freeze({
  dark: HIRING_BASE_STYLE,
  darkInline: {
    ...HIRING_BASE_STYLE,
    InputProps: {
      disableUnderline: false,
    },
    inputProps: {
      style: {
        ...HIRING_BASE_STYLE.inputProps.style,
        marginTop: -9,
      },
    },
  },
  lg: {
    ...DEFAULT_STYLES,
    inputProps: {
      style: {
        fontSize: typography.fs18,
      },
    },
    style: {
      height: 55,
    },
  },
  bordered: {
    ...DEFAULT_STYLES,
    inputProps: {
      style: {
        height: 40,
      },
    },
    style: {
      height: 40,
    },
  },
  fancy: {
    ...DEFAULT_STYLES,
    InputProps: {
      disableUnderline: false,
    },
    inputProps: {
      style: {
        color: colors.navy,
        fontSize: typography.fs18,
        borderWidth: 0,
        padding: 0,
        background: 'transparent',
      },
    },
    style: {
      height: 78,
    },
  },
});

export const THEME_OPTIONS = Object.freeze(Object.keys(THEME_STYLES));
