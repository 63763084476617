import { EARNING_TYPE_OPTIONS, FREQUENCY_TYPE_OPTIONS } from './constants';

export const getRecurringEarningType = recurring_earning =>
  EARNING_TYPE_OPTIONS.find(
    option => option.value === recurring_earning.get('earning_type')
  );

export const getFrequencyType = recurring_earning =>
  FREQUENCY_TYPE_OPTIONS.find(
    option => option.value === recurring_earning.get('frequency_type')
  );
