export const ssnPublicKey = () => {
  if (window.Homebase.env === 'production') {
    return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA+G/mCU+dOxsA8je0L5Aq\n9DL1acsbk2tr0v/phaS/wXdpWWFHzUUsCnEDXfFpTxHPoYAZbY+L8h0Ud4T3c7WE\n3Y9pXOpXr60nNT9t8qKdeSDC8urTQ2P95yK2jUoHHl5kDHLNIazhX26ZeBZpfFrj\nuT/8PV80OaxtmA6LGRtb32Yts9wJfoqrvtM1mhQ80fkeTgoFW18ciFJXXDMtpyNk\nnRoOK0ogAdBP9GfNb80lpOF4MIwUPG7ELzVpAfY77FOFb7JWqj04pY3yBI5E2XTR\nweRE9CDFdiKb27iMw8icZ8Z9u5A8FbVPdtqCob1sQ8WsnRF5IN0TijRXtsdVOvzK\nvwIDAQAB';
  }

  return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnnylN0RkCmrN+LiAu0Xg\nLxkPlEHL+bbQxdkh6MkNifY7Qe13S+UNvIyB0wIDVYddEUUvAoHoNQClYMNlto5+\nrLhqOTEKIfy5UM8MYB6ExMS/bnQMYk5ez2Ig5pFRLqNeyaiJylOV7LhYrkoMeOWK\n8Ko4oj10NjTC9D6fYT+uPxzxg1RHIOW8KZNTOMOtmWwITvsX1iveEVvvIQQRddU1\nB3QX2A/zavWgPqofINjRpf1D20OxAOOy2RWWLtsjeY5oQaj2zxJou2zsZnMbhkM2\nY85qeLHLw32XOZ+XNrj7UaBhIQ5j1YFPPk+5n8vqK2u4+GCRzzkx3SBlQLJfwTTe\nEQIDAQAB';
};

export const einPublicKey = () => {
  if (window.Homebase.env === 'production') {
    return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsDMbuvzby4jSy4V5xLV+\nnoJdY8zeNKrS2T292RolHMKxkMt6ecAFX9M9X1K/RGTA/noYnJMRyToe5bRdc7en\nZViuyE0JBB6eFbAZUGOkdYxBoixL7Zs13sOLDKNePL8yBGSNOBvuqdS2VK2gutho\nxPxNNj/UM4h7qQ5L/GChfE8KQvvW/D7R1Prgx8Bca2Qdb30M3XSh+busW3lp6dHs\nXP7dw+MZPLBeyVcB2vz7Bst9xl21GGI+KkVN0VaUP66U+Sa83eX454rtLlEwjVZl\n/QefGN6B1KRdHLGd3AGT/Te0qT/fGeJYmdLJKqNKz2E2SVMEd4ZUust/Bf9dpbRG\nCwIDAQAB';
  }

  return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnnylN0RkCmrN+LiAu0Xg\nLxkPlEHL+bbQxdkh6MkNifY7Qe13S+UNvIyB0wIDVYddEUUvAoHoNQClYMNlto5+\nrLhqOTEKIfy5UM8MYB6ExMS/bnQMYk5ez2Ig5pFRLqNeyaiJylOV7LhYrkoMeOWK\n8Ko4oj10NjTC9D6fYT+uPxzxg1RHIOW8KZNTOMOtmWwITvsX1iveEVvvIQQRddU1\nB3QX2A/zavWgPqofINjRpf1D20OxAOOy2RWWLtsjeY5oQaj2zxJou2zsZnMbhkM2\nY85qeLHLw32XOZ+XNrj7UaBhIQ5j1YFPPk+5n8vqK2u4+GCRzzkx3SBlQLJfwTTe\nEQIDAQAB';
};

export const routingNumberPublicKey = () => {
  if (window.Homebase.env === 'production') {
    return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxDYgiX7o8PayWDH7y5JZ\nAyjlzn91vUA8t+OYyy52o3aYbsNdTNuTtD5xLot897sWSbnA+crDbQ5MHydzx8o8\n8W83z2iHGTbYrgZnL45SrHB1JJr9bdav3RCBD/NVttfZ1B1bx7Yn6qCraU0vV5z0\nfwOmQk+xYPTbVuuctIzFQnyAsOvpBxT8L2AF3eElbmScfF1gI0TH6dkK42jqvbcG\ncLF42YO7506XgVcMohfe9W1lVY2UW8dHujmUgoYq46gPDW5MTO0FB47p9I9oYfnz\n +lflb7z6ULobeiMOJ86ksVJZI1XmmaUmq6jVQj2Wmy+hF99S1/Ebwhzapj0YK69n\nawIDAQAB';
  }

  return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnnylN0RkCmrN+LiAu0Xg\nLxkPlEHL+bbQxdkh6MkNifY7Qe13S+UNvIyB0wIDVYddEUUvAoHoNQClYMNlto5+\nrLhqOTEKIfy5UM8MYB6ExMS/bnQMYk5ez2Ig5pFRLqNeyaiJylOV7LhYrkoMeOWK\n8Ko4oj10NjTC9D6fYT+uPxzxg1RHIOW8KZNTOMOtmWwITvsX1iveEVvvIQQRddU1\nB3QX2A/zavWgPqofINjRpf1D20OxAOOy2RWWLtsjeY5oQaj2zxJou2zsZnMbhkM2\nY85qeLHLw32XOZ+XNrj7UaBhIQ5j1YFPPk+5n8vqK2u4+GCRzzkx3SBlQLJfwTTe\nEQIDAQAB';
};

export const accountNumberPublicKey = () => {
  if (window.Homebase.env === 'production') {
    return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtBRvaiaWb/5ciE2dKTAw\nZRcNhQmKJyf1q4vV3vmLIvF+U1tvtoHCMGR/0/n6UIJTZ1eUCetXUhv7mOEgYBja\nKHXc6hOQ/MZc0A8mOnplobmjU6HMuiijrBPocR5Fr7r5Bh+v7Gq3Uh0oHIc8k+nS\n /GKuxI0LebYF2Ri/0jGV/tzMGhShaOWBWk2rD5nCC/ZQaRbrgTSd8d1e+Py8vTpj\n6NqqVEjZnU+CT94VkLTZwvluiIrFU6/Va8eQ8vBv8FtpL5SnwKlbfj2xB/7AuxA1\n9z8EFfoHu/fUxO2QGj+Hz8HcSNP3lBn7/zdCWFViYKYQn3rakRD75Rmpg0UBQ/cx\nfwIDAQAB';
  }

  return 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAnnylN0RkCmrN+LiAu0Xg\nLxkPlEHL+bbQxdkh6MkNifY7Qe13S+UNvIyB0wIDVYddEUUvAoHoNQClYMNlto5+\nrLhqOTEKIfy5UM8MYB6ExMS/bnQMYk5ez2Ig5pFRLqNeyaiJylOV7LhYrkoMeOWK\n8Ko4oj10NjTC9D6fYT+uPxzxg1RHIOW8KZNTOMOtmWwITvsX1iveEVvvIQQRddU1\nB3QX2A/zavWgPqofINjRpf1D20OxAOOy2RWWLtsjeY5oQaj2zxJou2zsZnMbhkM2\nY85qeLHLw32XOZ+XNrj7UaBhIQ5j1YFPPk+5n8vqK2u4+GCRzzkx3SBlQLJfwTTe\nEQIDAQAB';
};
export const PACKET_STEPS = {
  INTRODUCTION: 'introduction',
  SET_PASSWORD: 'set_password',
  CONTACT: 'contact',
  TAX_FORM_CONSENT: 'tax_form_consent',
  W4: 'w4',
  I9: 'i9',
  W9: 'w9',
  STATE_WITHHOLDING: 'state_withholding',
  CUSTOM_DOCUMENT: 'custom_document',
  DIRECT_DEPOSIT: 'direct_deposit',
  CONFIRMATION: 'confirmation',
  INVALID: 'invalid',
};

export const EMPLOYEE = 'w2';
export const CONTRACTOR = '1099';

export const PACKET_TYPES = [EMPLOYEE, CONTRACTOR];

export const PERSONAL_INFORMATION = 'personal_information';
export const GOVERNMENT_DOCUMENTS = 'government_documents';
export const CUSTOM_DOCUMENTS = 'custom_documents';

export const PACKET_SECTIONS = [
  PERSONAL_INFORMATION,
  GOVERNMENT_DOCUMENTS,
  CUSTOM_DOCUMENTS,
];

export const DIRECT_DEPOSIT_FORM_INDEX = 'DirectDepositFormIndex';
export const DIRECT_DEPOSIT_SELECTION_PAGE = 'DirectDepositSelectionPage';
export const PLAID_DIRECT_DEPOSIT_FORM = 'PlaidDirectDepositForm';
export const DIRECT_DEPOSIT_FORM = 'DirectDepositForm';

export const WEBVIEW_WIDTH = 528;
