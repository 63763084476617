export const NAVIGATION = {
  ACCOUNT_SIGN_IN: '/accounts/sign-in',
  SCHEDULE_BUILDER: '/schedule_builder',
  TIMESHEETS: '/timesheets',
  TEAM: '/team',
  SETTINGS: '/settings',
  GENERAL_SETTINGS: '/settings/location/basic_info',
  WEB_TIME_CLOCK: '/web_timeclock',
  EMPLOYEES: '/employees',
};
