import { getOriginContextTrackingInfo, trackUxEvent } from 'util/tracking';
import {
  TRACK_ACTION_TYPES,
  TRACK_STARTING_PATHS,
} from 'util/tracking_constants';

import { toI18n } from '../../util/i18n';
import { ONBOARDING_OPTIONS } from '../team/components/AddEmployeeForm/constants';
import { TAX_FORM_STEP } from '../team/components/TaxEntryForm/constants';
import {
  DO_NOTHING,
  NOT_PRESENT,
  PRESENT_INVALID,
  PRESENT_VALID,
  SEND_INVITE,
} from '../team/constants';

export const LINK_TEXT = {
  ADD_EMAIL: 'Add Email',
  ADD_ROLE: 'Add Role',
  ADD_PHONE: 'Add Phone',
  ADD_WAGE: 'Add Wage',
  DOCUMENTS: 'Documents',
  JOB_DETAILS: 'Job Details',
  PERFORMANCE: 'Performance',
  PERSONAL_INFORMATION: 'Personal Information',
  TERMINATE_TEAM_MEMBER: 'Terminate Team Member',
  TERMINATION_HISTORY: 'Termination History',
};

export const LOCATION_PROPERTIES = {
  ROSTER_INLINE: 'Roster - Inline',
  ROSTER_TOP: 'Roster - Top',
};

export const BANNER_VERSION = {
  ADDRESS_CONFIRMED: 'address confirmed',
  CONFIRM_SUGGESTED_ADDRESS: 'confirm suggested address',
  UNABLE_TO_FIND_AND_VERIFY_ADDRESS: 'unable to find and verify address',
};

export const BUTTON_TEXT = {
  CONFIRM_ADDRESS: 'confirm address',
  CSV_EXPORT: 'CSV Export',
  ENTER_TAX_DETAILS: 'Enter tax details',
  DONE: 'done',
  ILL_DO_THIS_LATER: "I'll do this later",
  PRINT: 'Print',
  RESEND_INVITE: 'resend invite',
  YES_REHIRE: 'yes rehire',
};

export const CHECKBOX_TEXT = {
  SHOW_TERMINATED: 'show_terminated',
};

export const DROPDOWN = {
  LOCATION: 'location',
  ACCESS_LEVEL: 'access_level',
  ROLE: 'role',
  STATUS: 'status',
  CLASSIFICATION: 'classification',
};

export const ELEMENT = {
  ADD_ANOTHER_TEAM_MEMBER: 'add_another_team_member',
  BACK: 'back',
  CANCEL_REHIRE: 'cancel_rehire',
  EMPLOYEE_NAME_IN_SUCCESS_MODAL: 'employee_name_in_success_modal',
  EMPLOYEE_PROFILE: 'employee_profile',
};
export const SOURCE = {
  ADD_TEAM_DRAWER: 'add_team_drawer',
  BULK_DRAWER: 'bulk_drawer',
};

export const ADD_TEAM_PAYROLL_FLOW_STEP = {
  FEDERAL: 'federal',
  PERSONAL_INFO: 'personal_info',
  PAYMENTS: 'payments',
  STATE: 'state',
};

export const PRODUCT_AREAS = {
  NEW_HIRE_PACKET: 'new_hire_packet',
  TEAM: 'team',
};

export const EVENT_CATEGORIES = {
  ADD_TEAM_DRAWER: 'add_team_drawer',
  ADD_TEAM_ERROR: 'add_team_error',
  ADD_TEAM_PAYROLL_FLOW: 'add_team_payroll_flow',
  ADD_TEAM_SUCCESS: 'add_team_success',
  BULK_ACTIONS_DRAWER: 'bulk_actions_drawer',
  EMPLOYEE_PROFILE_PAGE: 'employee_profile_page',
  TEAM_DRAWER: 'team_drawer',
  TEAM_REQUEST: 'Team Request',
  TEAM_ROSTER: 'roster',
};

export const FORM_TYPES = {
  MULTIPLE_OPTIONS: 'multiple_options_displayed',
  ADD_TEAM_INDIVIDUALLY: 'add_team_individually',
};
export const EVENT_ACTIONS = {
  ADD_TEAM_MEMBER_CLICKED: 'Add Team Member Clicked',
  ADD_TEAM_DRAWER_SHOWN: 'Add Team Drawer Shown',
  ADD_TEAM_PAYROLL_SUCCESS_MODAL_SHOWN: 'Add Team Payroll Success Modal Shown',
  BANNER_VIEWED: 'Banner Viewed',
  BULK_ACTIONS_SHOWN: 'Bulk Actions Shown',
  BULK_ADD_CLICKED: 'Bulk Add Clicked',
  BULK_TERMINATE_CLICKED: 'Bulk Terminate Clicked',
  BUTTON_CLICKED: 'Button Clicked',
  CHECKBOX_CLICKED: 'Checkbox Clicked',
  DISMISS_CLICKED: 'Dismiss Clicked',
  DROPDOWN_CLICKED: 'Dropdown Clicked',
  ERROR_MESSAGE_SHOWN: 'Error Message Shown',
  INVITE_TEAM_CLICKED: 'Invite Team Clicked',
  LINK_CLICKED: 'Link Clicked',
  MODAL_VIEWED: 'Modal Viewed',
  PAGE_VIEWED: 'Page Viewed',
  PARTNER_ADD_CLICKED: 'Partner Add Clicked',
  PARTNER_POS_IMPORT_CLICKED: 'Partner POS Import Clicked',
  PARTNER_PAYROLL_IMPORT_CLICKED: 'Partner Payroll Import Clicked',
  SEARCH: 'Search',
  SEND_INVITES_CLICKED: 'Send Invites Clicked',
  SERVER_ERROR_MESSAGE_CLICKED: 'Server Error Message Clicked',
  SERVER_ERROR_MESSAGE_SHOWN: 'Server Error Message Shown',
  TEAM_REQUEST_APPROVED: 'Team Request Approved',
  TEAM_REQUEST_DECLINED: 'Team Request Declined',
  TEAM_FIRST_NAME_CLICKED: 'Team Member First Name Clicked',
  TEAM_LAST_NAME_CLICKED: 'Team Member Last Name Clicked',
  TEAM_ROSTER_SHOWN: 'Team Roster Shown',
};

const startingPathFromContext = () => {
  const trackingInfo = getOriginContextTrackingInfo();
  if (
    !!trackingInfo &&
    [
      TRACK_STARTING_PATHS.DASHBOARD,
      TRACK_STARTING_PATHS.ALERTS,
      TRACK_STARTING_PATHS.TEAM,
    ].includes(trackingInfo.starting_path)
  ) {
    return {
      starting_path: trackingInfo.starting_path,
    };
  }

  return {};
};

export const payrollOnboardingOptions = onboardingValue => {
  switch (onboardingValue) {
    case ONBOARDING_OPTIONS.INVITE_AND_PACKET:
      return 'packet';
    case ONBOARDING_OPTIONS.NOTHING:
      return 'manual';
    default:
      return 'none';
  }
};

export const getPayrollFlowStep = currentStep => {
  switch (currentStep) {
    case TAX_FORM_STEP.PERSONAL_INFORMATION:
      return ADD_TEAM_PAYROLL_FLOW_STEP.PERSONAL_INFO;
    case TAX_FORM_STEP.PAYMENT_METHOD:
      return ADD_TEAM_PAYROLL_FLOW_STEP.PAYMENTS;
    case TAX_FORM_STEP.FEDERAL:
      return ADD_TEAM_PAYROLL_FLOW_STEP.FEDERAL;
    default:
      return ADD_TEAM_PAYROLL_FLOW_STEP.STATE;
  }
};

export const getUxTracking = (
  values,
  isAdding,
  isAIOLocation,
  currentLocationTierId,
  result,
  hasContactInfoRequiredError,
  isPayrollEnrolled,
  keys = []
) => {
  const emailValid = keys.includes('email') ? PRESENT_INVALID : PRESENT_VALID;
  const phoneValid = keys.includes('phone') ? PRESENT_INVALID : PRESENT_VALID;

  const contactInfoRequiredError = hasContactInfoRequiredError ? 'yes' : 'no';

  const onboardingOptions = values.sendSms ? SEND_INVITE : DO_NOTHING;

  const manualSendOptions = values.sendSms ? 'yes' : 'no';

  trackUxEvent({
    productArea: PRODUCT_AREAS.TEAM,
    eventCategory: EVENT_CATEGORIES.ADD_TEAM_DRAWER,
    eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
    properties: {
      button_text: toI18n(
        `new_team_drawer.${isAdding ? 'add_button_text' : 'rehire_button_text'}`
      ),
      result,
      add_team_tier: currentLocationTierId,
      email_field_status: values.email ? emailValid : NOT_PRESENT,
      phone_field_status: values.phone ? phoneValid : NOT_PRESENT,
      missing_phone_email_error: values.sendSms
        ? contactInfoRequiredError
        : null,
      job_level_selected: values.level.toLowerCase(),
      // default row is not in wageAttributes
      role_or_wage_rows: values.wageAttributes.length + 1,
      basic_to_plus_onboarding_options: isAIOLocation
        ? null
        : onboardingOptions,
      aio_onboarding_options: isAIOLocation ? values.onboarding : null,
      payroll_enabled_location: isPayrollEnrolled ? 'yes' : 'no',
      include_in_payroll: values.includedInPayroll ? 'yes' : 'no',
      payroll_onboarding_options: isPayrollEnrolled
        ? payrollOnboardingOptions(values.onboarding)
        : null,
      send_option:
        values.onboarding === ONBOARDING_OPTIONS.NOTHING
          ? manualSendOptions
          : 'yes',
    },
  });
};

const setDataDogRUM = properties => {
  if (window.Homebase.env === 'production') {
    window.DD_RUM.onReady(() => {
      Object.entries(properties).forEach(entry => {
        const [key, value] = entry;
        window.DD_RUM.setUserProperty(key, value.toString());
      });
    });
  }
};

export const trackTeamRosterShown = (properties = {}) => {
  trackUxEvent({
    productArea: PRODUCT_AREAS.TEAM,
    eventCategory: EVENT_CATEGORIES.TEAM_ROSTER,
    eventAction: EVENT_ACTIONS.TEAM_ROSTER_SHOWN,
    actionType: TRACK_ACTION_TYPES.CLICK,
    properties,
  });
  setDataDogRUM(properties);
};

export const trackTeamClickEvents = ({ eventAction, properties = {} }) => {
  const trackingData = {
    productArea: PRODUCT_AREAS.TEAM,
    eventCategory: EVENT_CATEGORIES.TEAM_ROSTER,
    eventAction,
    actionType: TRACK_ACTION_TYPES.CLICK,
  };

  properties.starting_path = TRACK_STARTING_PATHS.TEAM;
  trackingData.properties = properties;

  trackUxEvent(trackingData);
};

export const trackTeamSearch = () => {
  const trackingData = {
    productArea: PRODUCT_AREAS.TEAM,
    eventCategory: EVENT_CATEGORIES.TEAM_ROSTER,
    eventAction: EVENT_ACTIONS.SEARCH,
    actionType: TRACK_ACTION_TYPES.FOCUS,
    properties: {
      starting_path: TRACK_STARTING_PATHS.TEAM,
    },
  };

  trackUxEvent(trackingData);
};

export const trackCheckBoxClickEvents = ({ properties }) => {
  trackTeamClickEvents({
    eventAction: EVENT_ACTIONS.CHECKBOX_CLICKED,
    properties,
  });
};

export const trackDropdownClickEvents = ({ properties }) => {
  trackTeamClickEvents({
    eventAction: EVENT_ACTIONS.DROPDOWN_CLICKED,
    properties,
  });
};

export const trackButtonClickEvents = ({ properties }) => {
  trackTeamClickEvents({
    eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
    properties,
  });
};

export const trackLinkClickEvents = ({ properties }) => {
  trackTeamClickEvents({
    eventAction: EVENT_ACTIONS.LINK_CLICKED,
    properties,
  });
};

export const trackErrorMessageShown = ({
  eventCategory,
  productArea,
  properties,
}) => {
  trackUxEvent({
    eventAction: EVENT_ACTIONS.ERROR_MESSAGE_SHOWN,
    eventCategory,
    productArea,
    actionType: TRACK_ACTION_TYPES.VIEW,
    properties,
  });
};

export const trackTeamRequestEvents = ({ approve, startingPath }) => {
  const eventAction = approve
    ? EVENT_ACTIONS.TEAM_REQUEST_APPROVED
    : EVENT_ACTIONS.TEAM_REQUEST_DECLINED;

  const properties = !!startingPath
    ? { starting_path: startingPath }
    : startingPathFromContext();

  trackUxEvent({
    eventAction,
    eventCategory: EVENT_CATEGORIES.TEAM_REQUEST,
    productArea: PRODUCT_AREAS.TEAM,
    actionType: TRACK_ACTION_TYPES.CLICK,
    properties,
  });
};
