import { CUSTOM_TIMINGS_KEY } from './dataDogRUMConstants';
import {
  getObjectFromLocalStorage,
  setObjectInLocalStorage,
  updateObjectInLocalStorage,
} from './localStorageWrapper';

// timedEvents should be an object described in dataDogRUMConstants.js
// custom timing object should be set in top most component of view
export const setCustomTimingObject = timedEvents =>
  setObjectInLocalStorage(CUSTOM_TIMINGS_KEY, timedEvents);

const checkShouldTrackEventTiming = key =>
  getObjectFromLocalStorage(CUSTOM_TIMINGS_KEY)[key];

const updateEventTimed = event =>
  updateObjectInLocalStorage(CUSTOM_TIMINGS_KEY, event);

// Key should be a valid key described in the timedEvents object
export const trackCustomTimingEvent = key => {
  if (checkShouldTrackEventTiming(key)) {
    window.DD_RUM.onReady(() => {
      window.DD_RUM.addTiming(key, Date.now());
    });

    // Update the key to false so that measurement is only captured once
    updateEventTimed({ [key]: false });
  }
};
