import * as routes from 'api';

import { getShowUpsellAndMetering } from 'selectors/session';

import { createAsyncGetAction } from 'util/redux';

export const actionTypes = {
  FETCH_SHIFT_NOTES_FOR_DATE_COUNT_REQUEST:
    'METERING/FETCH_SHIFT_NOTES_FOR_DATE_COUNT_REQUEST',
  FETCH_SHIFT_NOTES_FOR_DATE_COUNT_SUCCESS:
    'METERING/FETCH_SHIFT_NOTES_FOR_DATE_COUNT_SUCCESS',
  FETCH_SHIFT_NOTES_FOR_DATE_COUNT_FAILURE:
    'METERING/FETCH_SHIFT_NOTES_FOR_DATE_COUNT_FAILURE',
  FETCH_EVENTS_REQUEST: 'METERING/FETCH_EVENTS_REQUEST',
  FETCH_EVENTS_SUCCESS: 'METERING/FETCH_EVENTS_SUCCESS',
  FETCH_EVENTS_FAILURE: 'METERING/FETCH_EVENTS_FAILURE',
};

export const fetchMeteringShiftNotesForDateCount = () =>
  createAsyncGetAction(
    routes.meteringShiftNotesForDate(),
    [
      { type: actionTypes.FETCH_SHIFT_NOTES_FOR_DATE_COUNT_REQUEST },
      { type: actionTypes.FETCH_SHIFT_NOTES_FOR_DATE_COUNT_SUCCESS },
      { type: actionTypes.FETCH_SHIFT_NOTES_FOR_DATE_COUNT_FAILURE },
    ],
    { bailout: state => !getShowUpsellAndMetering(state) }
  );

export const fetchMeteringLocationEventsForDateCount = () =>
  createAsyncGetAction(
    routes.meteringEvents(),
    [
      { type: actionTypes.FETCH_EVENTS_REQUEST },
      { type: actionTypes.FETCH_EVENTS_SUCCESS },
      { type: actionTypes.FETCH_EVENTS_FAILURE },
    ],
    { bailout: state => !getShowUpsellAndMetering(state) }
  );
