import * as routes from 'api';
import { fetchJSON } from 'api/fetch';

const DELAY = 1000;
const SUCCESS_STATUS = 'done';
const FAILURE_STATUS = 'fail';

const pause = delay => new Promise(resolve => setTimeout(resolve, delay));

class WorkerFailureError {
  constructor(response) {
    this.response = response;
  }
}

export const pollWorkerStatus = (jobId, retry = 0, delay = DELAY) =>
  fetchJSON(routes.workerStatusRoute({ job_id: jobId })).then(response => {
    if (response.status === SUCCESS_STATUS) {
      return response;
    } else if (response.status === FAILURE_STATUS) {
      throw new WorkerFailureError(response);
    } else {
      return pause(delay).then(() =>
        pollWorkerStatus(jobId, retry + 1, retry > 9 ? delay * 2 : delay)
      );
    }
  });

export const pollWorkersStatus = (jobIds, retry = 0, delay = DELAY) =>
  fetchJSON(routes.workersStatusRoute({ job_ids: jobIds.join('|') })).then(
    response => {
      if (response.status === SUCCESS_STATUS) {
        return response;
      } else if (response.status === FAILURE_STATUS) {
        throw new WorkerFailureError(response);
      } else {
        return pause(delay).then(() =>
          pollWorkersStatus(jobIds, retry + 1, retry > 9 ? delay * 2 : delay)
        );
      }
    }
  );

export const pollBatchStatus = (batchId, retry = 0, delay = DELAY) =>
  fetchJSON(routes.batchStatusRoute(batchId)).then(response => {
    if (response.is_complete && response.failures === 0) {
      return response;
    } else if (response.is_complete && response.failures > 0) {
      throw new WorkerFailureError(response);
    } else {
      return pause(delay).then(() =>
        pollBatchStatus(batchId, retry + 1, retry > 9 ? delay * 2 : delay)
      );
    }
  });
