import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export default action => WrappedComponent => {
  @withRouter
  @connect(null, { action: () => action })
  class WithDispatchOnNavigation extends PureComponent {
    componentDidMount() {
      this.ignoreChange =
        (this.props.location.pathname === '/payroll' ||
          this.props.location.pathname === '/schedule_builder' ||
          this.props.location.pathname === '/timesheets') &&
        this.props.location.hash === '';

      this.unlisten = this.props.history.listen(() => {
        if (this.ignoreChange) {
          this.ignoreChange = false;
          return;
        }
        this.props.action();
        if (this.child && this.child.resetState) this.child.resetState();
      });
    }

    componentWillUnmount() {
      this.unlisten();
      this.child = null;
    }

    setChildRef = ref => (this.child = ref);

    render() {
      return <WrappedComponent onRef={this.setChildRef} {...this.props} />;
    }
  }

  return WithDispatchOnNavigation;
};
