import { getCookie } from 'util/cookies';
import { getOriginContextStartingPath, trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

export const DAY_VIEW_TYPE = 'day';
export const WEEK_VIEW_TYPE = 'week';
export const MONTH_VIEW_TYPE = 'month';
export const EMPLOYEE_VIEW_SUB_TYPE = 'employee';
export const DEPT_VIEW_SUB_TYPE = 'department';
export const ROLE_VIEW_SUB_TYPE = 'role';
export const START_TIME_VIEW_SUB_TYPE = 'start time';

export const getScheduleTrackingViewTypes = () => {
  const viewType = getCookie('ScheduleBuilderMainType');
  const subType = getCookie('ScheduleBuilderSubType');
  let viewSubType = subType;

  if (viewType === DAY_VIEW_TYPE && subType === 'shift') {
    viewSubType = START_TIME_VIEW_SUB_TYPE;
  } else if (viewType === MONTH_VIEW_TYPE) {
    viewSubType = null;
  }

  return { viewType, viewSubType };
};

export const trackPublishDrawerUxEvents = ({
  eventAction,
  isByDepartment,
  hasConflicts,
  actionType,
  notifyType,
  isViaSms,
  repeatOption,
}) => {
  const { viewType, viewSubType } = getScheduleTrackingViewTypes();
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER_PUBLISH_DRAWER,
    eventAction,
    actionType: actionType || TRACK_ACTION_TYPES.CLICK,
    properties: {
      view_type: viewType,
      view_sub_type: viewSubType,
      is_by_department: isByDepartment,
      ...(notifyType && { notify_type: notifyType }),
      ...(hasConflicts !== undefined && { has_conflicts: hasConflicts }),
      ...(isViaSms !== undefined && { is_via_sms: isViaSms }),
      ...(repeatOption !== undefined && { repeat_option: repeatOption }),
    },
  });
};

const trackGeneralScheduleUxEvents = ({
  eventAction,
  eventCategory,
  actionType,
}) => {
  const { viewType, viewSubType } = getScheduleTrackingViewTypes();
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: eventCategory || EVENT_CATEGORIES.SCHEDULE_BUILDER,
    eventAction,
    actionType: actionType || TRACK_ACTION_TYPES.CLICK,
    properties: {
      view_type: viewType,
      view_sub_type: viewSubType,
    },
  });
};

const trackOriginContextUxEvents = ({
  eventAction,
  eventCategory,
  actionType,
}) => {
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory,
    eventAction,
    actionType,
    properties: {
      starting_path: getOriginContextStartingPath(),
    },
  });
};

export const trackTemplateDrawerUxEvents = ({ eventAction, actionType }) => {
  trackGeneralScheduleUxEvents({
    eventAction,
    actionType,
    eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER_TEMPLATE_DRAWER,
  });
};

export const trackManageTemplatesDrawerUxEvents = ({
  eventAction,
  actionType,
}) => {
  trackGeneralScheduleUxEvents({
    eventAction,
    actionType,
    eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER_MANAGE_TEMPLATES_DRAWER,
  });
};

export const trackAutoScheduleInfoModalUxEvents = ({
  eventAction,
  actionType,
}) => {
  trackGeneralScheduleUxEvents({
    eventAction,
    actionType,
    eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER_AUTO_SCHEDULE_INFO_MODAL,
  });
};

export const trackTeamAvailabilityUxEvents = approval => {
  const eventAction = approval
    ? EVENT_ACTIONS.AVAILABILITY_REQUEST_APPROVED
    : EVENT_ACTIONS.AVAILABILITY_REQUEST_DECLINED;
  trackOriginContextUxEvents({
    eventAction,
    eventCategory: EVENT_CATEGORIES.TEAM_AVAILABILITY,
    actionType: TRACK_ACTION_TYPES.CLICK,
  });
};

export const trackOpenShiftClaimUxEvents = () =>
  trackOriginContextUxEvents({
    eventAction: EVENT_ACTIONS.OPEN_SHIFT_CLAIM_APPROVED,
    eventCategory: EVENT_CATEGORIES.OPEN_SHIFT,
    actionType: TRACK_ACTION_TYPES.CLICK,
  });

export const trackTradeRequestUxEvents = (approval, startingPath) => {
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.SHIFT_TRADE,
    eventAction: approval
      ? EVENT_ACTIONS.SHIFT_TRADE_REQUEST_APPROVED
      : EVENT_ACTIONS.SHIFT_TRADE_REQUEST_DECLINED,
    actionType: TRACK_ACTION_TYPES.CLICK,
    properties: {
      starting_path: startingPath,
    },
  });
};

export const trackCoverRequestUxEvents = (approval, startingPath) => {
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.SHIFT_COVER,
    eventAction: approval
      ? EVENT_ACTIONS.SHIFT_COVER_REQUEST_APPROVED
      : EVENT_ACTIONS.SHIFT_COVER_REQUEST_DECLINED,
    actionType: TRACK_ACTION_TYPES.CLICK,
    properties: {
      starting_path: startingPath,
    },
  });
};

export const trackRepeatScheduleViewUxEvents = ({
  productArea = PRODUCT_AREAS.SCHEDULE,
  eventCategory = EVENT_CATEGORIES.SCHEDULE_BUILDER_REPEAT_SCHEDULE_DRAWER,
  eventAction,
  actionType,
  properties,
}) => {
  const { viewType, viewSubType } = getScheduleTrackingViewTypes();
  trackUxEvent({
    productArea,
    eventCategory,
    eventAction,
    actionType,
    properties: {
      viewType,
      viewSubType,
      isByDepartment: false,
      ...properties,
    },
  });
};

export const trackJumpStartDrawerEvent = (eventAction, actionType) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER_JUMPSTART_DRAWER,
    eventAction,
    actionType,
  });

export const trackPastWeekDrawerEvents = (
  eventAction,
  actionType,
  props = {}
) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.JUMPSTART_PAST_WEEK_DRAWER,
    eventAction,
    actionType,
    properties: props,
  });

export const trackSuccessBannerEvent = (eventAction, actionType) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.SCHEDULE_BUILDER,
    eventAction,
    actionType,
  });

export const trackTemplateDrawerEvents = (eventAction, actionType) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.SCHEDULE,
    eventCategory: EVENT_CATEGORIES.JUMPSTART_TEMPLATE_DRAWER,
    eventAction,
    actionType,
  });
