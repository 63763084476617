import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import * as navSelectors from 'selectors/nav';

export default id => WrappedComponent => {
  const getNavItemData = navSelectors.buildItemDataSelector(id);

  @connect((state, props) => ({
    data: getNavItemData(state, props),
  }))
  class WithNavItemData extends Component {
    static propTypes = {
      data: PropTypes.instanceOf(Map),
    };

    render() {
      const { data, ...props } = omit(this.props, 'dispatch');

      return data ? <WrappedComponent {...props} {...data.toObject()} /> : null;
    }
  }

  return WithNavItemData;
};
