import React from 'react';

import Avatar from 'components/Avatar';
import Box from 'components/Box';
import TooltipWrapper from 'components/TooltipWrapper';

import Icon from './Icon';

const IconMenuItem = ({
  iconType,
  color,
  avatarUrl,
  collapsedState,
  itemName,
  cxEl,
}) => {
  const getIconState = () => {
    if (iconType && collapsedState) {
      return (
        <TooltipWrapper
          trigger="hover"
          preferPlace="right"
          theme="light-blue"
          content={itemName}
        >
          <Icon type={iconType} color={color} />
        </TooltipWrapper>
      );
    } else if (iconType) {
      return <Icon type={iconType} color={color} />;
    } else if (avatarUrl) {
      return <Avatar url={avatarUrl} size={24} />;
    }
  };

  return (
    <Box className={cxEl('icon-menu-tem')} mr={16} tag="span" vcenter>
      {getIconState()}
    </Box>
  );
};

export default IconMenuItem;
