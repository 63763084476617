import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgUpload = ({
  alt = 'Upload Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M19.5 16.5v3A1.5 1.5 0 0 1 18 21H6a1.5 1.5 0 0 1-1.5-1.5v-3a1.5 1.5 0 0 0-3 0v3A4.5 4.5 0 0 0 6 24h12a4.5 4.5 0 0 0 4.5-4.5v-3a1.5 1.5 0 0 0-3 0ZM10.94.44l-6 6a1.5 1.5 0 1 0 2.122 2.12L10.5 5.123V15a1.499 1.499 0 1 0 3 0V5.123l3.44 3.44a1.5 1.5 0 1 0 2.12-2.121l-6-6a1.496 1.496 0 0 0-2.12-.003Z"
    />
  </svg>
);
export default SvgUpload;
