type Props = {
  textWidth: number;
  inputContainerWidth: number;
  isFocused?: boolean;
  type?: string;
  textArea?: boolean;
  noTooltip?: boolean;
  startIcon?: boolean;
  endIcon?: boolean;
};

/**
 WIDTH_ADJUST constants are the amount of pixels
 difference between inputContainerWidth and textWidth
 depending on start/end icons before overflow
 */
export const WIDTH_ADJUST_NO_ICONS = 22;
export const WIDTH_ADJUST_START_ICON = 56;
export const WIDTH_ADJUST_END_ICON = 54;
export const WIDTH_ADJUST_BOTH_ICONS = 90;

export const shouldRenderTooltip = ({
  textWidth,
  inputContainerWidth,
  isFocused,
  type,
  textArea,
  noTooltip,
  startIcon,
  endIcon,
}: Props) => {
  const widthAdjustments = {
    false_false: WIDTH_ADJUST_NO_ICONS,
    true_false: WIDTH_ADJUST_START_ICON,
    false_true: WIDTH_ADJUST_END_ICON,
    true_true: WIDTH_ADJUST_BOTH_ICONS,
  };
  const widthAdjustNumber =
    widthAdjustments[
      `${startIcon}_${endIcon}` as keyof typeof widthAdjustments
    ];

  return Boolean(
    textWidth + widthAdjustNumber > inputContainerWidth &&
      !isFocused &&
      type !== 'password' &&
      !textArea &&
      !noTooltip
  );
};
