import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgPrint = ({
  alt = 'Print Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M6 0C4.345 0 3 1.345 3 3v4.5h3V3h10.627L18 4.373V7.5h3V4.373a3 3 0 0 0-.877-2.123L18.75.877A3.001 3.001 0 0 0 16.627 0H6Zm12 16.5V21H6v-4.5h12Zm3 1.5h1.5c.83 0 1.5-.67 1.5-1.5V12c0-1.655-1.345-3-3-3H3c-1.655 0-3 1.345-3 3v4.5c0 .83.67 1.5 1.5 1.5H3v3c0 1.655 1.345 3 3 3h12c1.655 0 3-1.345 3-3v-3Zm-.75-4.125a1.122 1.122 0 0 1-1.125-1.125c0-.623.502-1.125 1.125-1.125s1.125.502 1.125 1.125-.502 1.125-1.125 1.125Z"
    />
  </svg>
);
export default SvgPrint;
