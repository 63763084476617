import { createSelector } from 'reselect';

import { getCanManageTier } from 'selectors/session';

import { selectTierBasic } from '../biller/selectors';

import { selectMultiLocationEnforcementLocations } from './EnforcementBanner/selectors';

export const selectRemoveLocationsError = state =>
  state.get('enforcement').removeLocationsError;

export const selectRemoveLocationsPending = state =>
  state.get('enforcement').removeLocationsPending;

export const selectRemovedLocations = state =>
  state.get('enforcement').removedLocations;

export const selectSelectedTierName = state =>
  state.get('enforcement').selectedTierName;

export const selectSelectedLocationsById = state =>
  state.get('enforcement').selectedLocationsById;

export const selectStep = state => state.get('enforcement').step;

export const selectDisplayMultilocationEnforcementDialog = state =>
  state.getIn([
    'session',
    'enforcement',
    'displayMultilocationEnforcementDialog',
  ]);

export const selectCanCloseMultilocationEnforcementDialog = state =>
  state.getIn([
    'session',
    'enforcement',
    'canCloseMultilocationEnforcementDialog',
  ]);

// The ee caps enforcement paywall
export const selectDisplayDialog = state =>
  state.getIn(['session', 'enforcement', 'displayDialog']);

export const selectBasicMleLocations = createSelector(
  selectMultiLocationEnforcementLocations,
  selectTierBasic,
  (mleLocations, tierBasic) =>
    mleLocations.filter(location => location.tier_id === tierBasic.get('id'))
);

export const selectShouldShowEnforcementDialogOnInitialLoad = createSelector(
  selectDisplayDialog,
  getCanManageTier,
  (displayDialog, canManageTier) => displayDialog && canManageTier
);
