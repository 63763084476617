import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { openSharedTimeclockModal } from 'actions/modals';

import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

export const useHubTimeClock = () => {
  const dispatch = useDispatch();

  return useCallback(
    (trackingProperties = {}) => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.TIMESHEETS,
        eventCategory: EVENT_CATEGORIES.GET_A_TIME_CLOCK,
        eventAction: EVENT_ACTIONS.WEB_TIME_CLOCK_LAUNCH_CLICKED,
        properties: trackingProperties,
      });

      dispatch(openSharedTimeclockModal());
    },
    [dispatch]
  );
};
