import React from 'react';
import Icon from 'react-icon-base';

const Knife = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M15.375 15.125l-7.827 7.53c-.238.268-.149.684.208.803 3.393 1.072 7.113.239 9.643-2.202l1.816-1.756c.208-.178.208-.476 0-.655l-3.84-3.72zm8.9-5.149l-1.043-1.012c-.386-.386-1.011-.386-1.398 0L16.09 14.47l3.333 3.215 1.042-.804c.178-.178.267-.446.267-.684v-1.34l3.542-3.541c.387-.357.387-.982 0-1.34zm-3.632 3.155c-.208.179-.506.179-.714 0-.179-.178-.179-.476 0-.655.208-.208.506-.208.714 0 .179.179.179.477 0 .655zm1.786-1.726c-.179.179-.506.179-.685 0-.208-.179-.208-.506 0-.685.179-.178.506-.178.685 0 .208.179.208.506 0 .685z"
    />
  </Icon>
);

export default Knife;
