import './Popover.scss';

import React, { PureComponent } from 'react';
// https://github.com/littlebits/react-popover
import ReactPopover from 'react-popover';
import PropTypes from 'prop-types';

import cxHelpers from 'util/className';

@cxHelpers('Popover')
export default class Popover extends PureComponent {
  static propTypes = {
    body: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    preferPlace: PropTypes.string,
    theme: PropTypes.string,
    style: PropTypes.object,
    tipSize: PropTypes.number,
  };

  render() {
    const { theme, ...props } = this.props;
    return <ReactPopover {...props} className={this.cx({ [theme]: theme })} />;
  }
}
