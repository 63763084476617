import { isPlainObject, keys, snakeCase } from 'lodash';

export const keysToSnakeCase = object => {
  const newObject = { ...object };

  keys(newObject).forEach(key => {
    let value = object[key];

    delete newObject[key];

    if (isPlainObject(value)) {
      value = keysToSnakeCase(value);
    }

    newObject[snakeCase(key)] = value;
  });
  return newObject;
};
