export const PAST_DUE_DIALOG_VIEW_KEY = {
  hide: 'hide',
  grace_period: 'grace_period',
  after_grace_period: 'after_grace_period',
  enforced_location: 'enforced_location',
  stay_on_basic: 'stay_on_basic',
  restored_to_paid: 'restored_to_paid',
  grace_period_non_billing: 'grace_period_non_billing',
  after_grace_period_non_billing: 'after_grace_period_non_billing',
};
