export const MODULE_NAME = 'fe-core';
export const ENTITIES_SLICE = 'entities';
export const RESOURCES_SLICE = 'resources';

export const ACTION_FETCH = 'fetch';
export const ACTION_CREATE = 'create';
export const ACTION_UPDATE = 'update';
export const ACTION_POST = 'post';
export const ACTION_DESTROY = 'destroy';
export const ACTION_CONFLICT_CHECK = 'conflict check';
