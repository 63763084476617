import React, { PureComponent } from 'react';

import { toI18n } from 'util/i18n';
import { setOriginContext } from 'util/tracking';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import RequestsListItem from '../RequestsListItem';

export default class Availability extends PureComponent {
  handleApprove = () => {
    setOriginContext({ starting_path: TRACK_STARTING_PATHS.ALERTS });
    window.location = '/availabilities';
  };

  render() {
    return (
      <RequestsListItem
        showDeclineButton={false}
        acceptButtonCopy={toI18n('notifications.requests.availability.accept')}
        body={this.props.item.get('body')}
        onApprove={this.handleApprove}
        {...this.props}
      />
    );
  }
}
