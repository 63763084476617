import { combineReducers } from 'redux-immutable';

import * as noticeActions from 'features/biller/noticeActions';

import { reducer as shopify } from './implementations/shopify';
import { reducer as stripe } from './implementations/stripe';
import * as selectors from './selectors';
import { actions as sliceActions, reducer as main } from './slice';
import * as thunkActions from './thunks';

const reducer = combineReducers({
  main,
  stripe,
  shopify,
});

const actions = {
  ...noticeActions,
  ...sliceActions,
  ...thunkActions,
};

export { actions, selectors, reducer };
