import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  managerApproveTimeOffRequest,
  managerRejectTimeOffRequest,
} from 'actions/notifications';

import { trackPTORequestEvents } from 'features/ptoPolicy/tracking';

import { duration, moment } from 'util/dateTime';
import { setOriginContext } from 'util/tracking';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import RequestsListItem from '../RequestsListItem';

@connect(() => ({}), {
  managerApproveTimeOffRequest,
  managerRejectTimeOffRequest,
})
export default class TimeOff extends PureComponent {
  static propTypes = {
    managerApproveTimeOffRequest: PropTypes.func.isRequired,
    managerRejectTimeOffRequest: PropTypes.func.isRequired,
  };

  timeOffStartAt = () => {
    const day = this.props.item.get('start_at');
    return moment(day).format('ddd, MMM D, YYYY');
  };

  bodyProps = () => {
    const numDays = this.timeOffDays();
    const plural = numDays > 1;
    const i18n = `notifications.requests.time_off.desc_${
      plural ? 'plural' : 'single'
    }`;
    const i18nProps = {
      name: this.formattedFullName(),
      start_at: this.timeOffStartAt(),
    };

    if (plural) {
      i18nProps.num_days = numDays;
    }

    return { i18n, i18nProps };
  };

  timeOffDays = () => {
    const startAt = moment(this.props.item.get('start_at'));
    const endAt = moment(this.props.item.get('end_at'));
    const timeOffDuration = endAt.diff(startAt);
    const days = duration(timeOffDuration).asDays();

    return Math.round(days);
  };

  formattedFullName = () =>
    [
      this.props.item.get('user_first_name'),
      this.props.item.get('user_last_name'),
    ].join(' ');

  handleApprove = () => {
    setOriginContext({ starting_path: TRACK_STARTING_PATHS.ALERTS });
    trackPTORequestEvents({ approved: true });
    this.props.managerApproveTimeOffRequest(
      this.props.item,
      this.props.item.get('_id')
    );
  };

  handleDecline = () => {
    setOriginContext({ starting_path: TRACK_STARTING_PATHS.ALERTS });
    trackPTORequestEvents({ approved: false });
    this.props.managerRejectTimeOffRequest(
      this.props.item,
      this.props.item.get('_id')
    );
  };

  render() {
    return (
      <RequestsListItem
        bodyProps={this.bodyProps()}
        onApprove={this.handleApprove}
        onDecline={this.handleDecline}
        {...this.props}
      />
    );
  }
}
