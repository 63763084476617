const buildHrProSelector = key => state => state.getIn(['hrPro', key]);

export const getHrProPrice = buildHrProSelector('price');

export const getHrProLoading = buildHrProSelector('loading');
export const getHrProDemandLoading = buildHrProSelector('hrDemandLoading');

export const getHrProPaid = buildHrProSelector('isPaid');
export const getHrProActiveTrialPeriod = buildHrProSelector(
  'hasActiveTrialPeriod'
);
export const getHrProSsoCardData = buildHrProSelector('ssoCardData');

// MODAL
export const getModalIsSubmitting = buildHrProSelector('modalIsSubmitting');

export const getCanAccessHrProViaTier = buildHrProSelector(
  'canAccessHrProViaTier'
);
