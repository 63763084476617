import { createSlice } from '@reduxjs/toolkit';

import { actionTypes as i9VerificationActions } from 'actions/employeeView';
import { actionTypes as notificationActions } from 'actions/notifications';
import { actionTypes as teamViewActions } from 'actions/teamView';
import { actionTypes as timeCardActions } from 'actions/timecard';

import {
  dismissMissedBreaks,
  submitTimecardData,
} from 'features/timesheets/TimesheetsPage/TimecardModal/slices';

// This may be used by components to know when relevant state may have been changed
// externally (to force a reset).  It is a simple counter that can be used as
// a useEffect dependency.
const getDirtyCount = state => state.get('needsReviewWidget').dirtyCount;

// This will trigger widget updates when external actions reflect changes
const updateDirtyTimestamp = state => {
  state.dirtyCount += 1;
  return state;
};

const slice = createSlice({
  name: 'needsReviewWidget',
  initialState: { dirtyCount: 0 },
  extraReducers: {
    [timeCardActions.SUBMIT_CHANGES_SUCCESS]: updateDirtyTimestamp,
    [submitTimecardData.fulfilled]: updateDirtyTimestamp,
    [notificationActions.MANAGER_APPROVE_TRADE_SUCCESS]: updateDirtyTimestamp,
    [notificationActions.MANAGER_REJECT_TRADE_SUCCESS]: updateDirtyTimestamp,
    [teamViewActions.APPROVE_PENDING_JOB_SUCCESS]: updateDirtyTimestamp,
    [teamViewActions.DECLINE_PENDING_JOB_SUCCESS]: updateDirtyTimestamp,
    [i9VerificationActions.SIGN_I9_SUCCESS]: updateDirtyTimestamp,
    [dismissMissedBreaks.fulfilled]: updateDirtyTimestamp,
  },
});

export const reducer = slice.reducer;
export const actions = slice.actions;
export const selectors = {
  getDirtyCount,
};
