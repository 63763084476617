import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgTemplate = ({
  alt = 'Template Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M6 1.5h1.5v3H6A1.5 1.5 0 0 0 4.5 6v1.5h-3V6A4.5 4.5 0 0 1 6 1.5ZM4.5 9v6h-3V9h3Zm18 0v6h-3V9h3Zm-3-1.5V6c0-.83-.67-1.5-1.5-1.5h-1.5v-3H18A4.5 4.5 0 0 1 22.5 6v1.5h-3Zm3 10.5c0 2.484-2.016 4.5-4.5 4.5h-1.5v-3H18c.83 0 1.5-.67 1.5-1.5v-1.5h3V18Zm-18 0c0 .83.672 1.5 1.5 1.5h1.5v3H6A4.5 4.5 0 0 1 1.5 18v-1.5h3V18ZM9 22.5v-3h6v3H9Zm0-21h6v3H9v-3Z"
    />
  </svg>
);
export default SvgTemplate;
