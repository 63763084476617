import React from 'react';
import Icon from 'react-icon-base';

const Interviews = props => (
  <Icon viewBox="0 0 135 121" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#EEE" d="M-362.5-199.5h859v1312h-859z" />
      <g fill="currentColor">
        <path d="M52 70.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2M50 61.366a1 1 0 1 1-.998-1.732A1 1 0 0 1 50 61.366M50.366 79.5a1 1 0 1 1-1.732-1 1 1 0 0 1 1.732 1M43.5 86.365a1 1 0 1 1-1.001-1.73 1 1 0 0 1 1.001 1.73M33.5 89a1 1 0 1 1-.001-2 1 1 0 0 1 .001 2M25 54.366a1 1 0 1 1-1-1.733 1 1 0 0 1 1 1.733M25.365 86A1 1 0 1 1 25 84.634 1 1 0 0 1 25.365 86M18.365 61A1 1 0 1 1 16.633 60a1 1 0 0 1 1.732.998M18 79.866a1 1 0 1 1-1-1.733 1 1 0 0 1 1 1.733M15 70.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2M33.5 50a1 1 0 1 1 0 1.999 1 1 0 0 1 0-1.999M42.134 53a1 1 0 1 1 1.731 1 1 1 0 0 1-1.73-1" />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="3"
        d="M33 46c-12.702 0-23 10.297-23 23s10.298 23 23 23c12.703 0 23-10.297 23-23S45.703 46 33 46"
      />
      <path
        fill="currentColor"
        d="M22.163 75.45c.216.354.601.55.996.55.201 0 .405-.05.59-.158L35 69.322V55.127c0-.622-.518-1.127-1.157-1.127-.64 0-1.158.505-1.158 1.127v12.915l-10.12 5.863a1.11 1.11 0 0 0-.402 1.545"
      />
      <g fill="currentColor">
        <path d="M96.885 40.664a3.194 3.194 0 1 1 0-6.389 3.195 3.195 0 0 1 0 6.389m-10.446 0a3.195 3.195 0 1 1 3.196-3.194 3.195 3.195 0 0 1-3.196 3.194m-10.446 0a3.195 3.195 0 1 1-.002-6.39 3.195 3.195 0 0 1 .002 6.39m42.096 18.032a12.573 12.573 0 0 1-2.82-4.223c3.543-4.52 5.61-9.918 5.61-15.72 0-15.88-15.42-28.753-34.44-28.753C67.418 10 52 22.873 52 38.752c0 5.262 1.7 10.19 4.658 14.433 5.386-2.28 11.472-3.573 17.923-3.573 14.01 0 26.318 6.063 33.386 15.212 2.893.203 6.167.28 9.104-.044 5.88-.65 8.929-2.476 8.929-2.476s-4.553-.295-7.911-3.608" />
        <path d="M84.975 86.222c-1.767 0-3.2-1.442-3.2-3.221 0-1.781 1.433-3.223 3.2-3.223 1.767 0 3.2 1.442 3.2 3.223 0 1.779-1.433 3.22-3.2 3.22m-10.46 0c-1.767 0-3.2-1.441-3.2-3.22 0-1.781 1.433-3.223 3.2-3.223 1.767 0 3.201 1.442 3.201 3.223a3.212 3.212 0 0 1-3.201 3.22m-10.457 0c-1.769 0-3.2-1.441-3.2-3.22 0-1.781 1.431-3.223 3.2-3.223 1.765 0 3.199 1.442 3.199 3.223 0 1.779-1.434 3.22-3.2 3.22M74.516 54C55.47 54 40.032 66.983 40.032 83c0 5.084 1.562 9.862 4.296 14.017a12.708 12.708 0 0 1-3.408 6.099c-3.362 3.341-7.92 3.639-7.92 3.639s3.051 1.841 8.94 2.497c6.084.676 13.665-.356 16.161-.744C62.983 110.735 68.574 112 74.515 112 93.56 112 109 99.017 109 83S93.561 54 74.515 54" />
      </g>
    </g>
  </Icon>
);

export default Interviews;
