import './Logo.scss';

import withCx, { CxProps } from 'fe-core/util/withCx';
import SvgLogo from 'fe-design-base/assets/Logo/logos/HomebaseLogo';
import Box from 'fe-design-base/atoms/Box';

export const LOGO_COLORS = ['mono0', 'mono900', 'primary500'] as const;

type LogoColor = (typeof LOGO_COLORS)[number];

export interface LogoProps {
  color?: LogoColor;
  height?: number;
  alt?: string;
}

const Logo = ({
  cx,
  color = 'primary500',
  height = 26,
  alt,
}: LogoProps & CxProps) => (
  <Box color={color} className={cx()} data-testid={cx()} hcenter h={height}>
    <SvgLogo alt={alt} />
  </Box>
);

export default withCx<LogoProps>('FDBLogo')(Logo);
