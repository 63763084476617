import { createAsyncThunk } from '@reduxjs/toolkit';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

import { postDownloadAppSms } from './api';
import { SLICE_NAME } from './constants';

export const sendDownloadAppSms = createAsyncThunk(
  `${SLICE_NAME}/sendDownloadAppSms`,
  (phone, { rejectWithValue }) =>
    postDownloadAppSms(phone)
      .then(() => {
        flashNotice.success(
          `${toI18n('dashboard.location.download_app_section.success', {
            props: { phone_number: phone },
          })}`,
          {
            autoHideTimeout: 2500,
          }
        );
      })
      .catch(error => {
        trackUxEvent({
          productArea: PRODUCT_AREAS.DASHBOARD,
          eventCategory: EVENT_CATEGORIES.GET_MOBILE_APP_CARD_MANAGER,
          eventAction: EVENT_ACTIONS.ERROR_MESSAGE_SHOWN,
          actionType: TRACK_ACTION_TYPES.VIEW,
          properties: {
            error_message: toI18n('errors.generic'),
          },
        });
        flashNotice.error(toI18n('errors.generic'));
        return rejectWithValue(error.body || error.message);
      })
);
