import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgExpand = ({
  alt = 'Expand Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M13.06.44a1.502 1.502 0 0 0-2.124 0l-3 3a1.494 1.494 0 0 0-.324 1.635A1.5 1.5 0 0 0 9 6.004h1.5v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V5.999H15a1.502 1.502 0 0 0 1.064-2.564l-3-3-.005.004ZM10.5 16.498v1.5H9a1.502 1.502 0 0 0-1.064 2.564l3 3a1.502 1.502 0 0 0 2.123 0l3-3a1.494 1.494 0 0 0 .324-1.636 1.5 1.5 0 0 0-1.388-.928H13.5v-1.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5Zm-7.5-6c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h18c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H3Z"
    />
  </svg>
);
export default SvgExpand;
