import JSEncrypt from 'jsencrypt';

export const encryptWithPublicKey = (publicKey, str) => {
  if (str === null) {
    return null;
  }

  const crypt = new JSEncrypt({ default_key_size: 2048 });

  crypt.setPublicKey(publicKey);

  return crypt.encrypt(str.trim());
};
