import { HAS_MANY } from 'fe-core/Entities/relationshipTypes';

// return relationship as data object
function buildRelationshipItem(defaultType, relationship) {
  if (relationship?.type) {
    return relationship;
  }
  return {
    id: relationship,
    type: defaultType,
  };
}

// build data as array or object
function buildRelationshipData(name, defaultType, relationshipType, data) {
  if (relationshipType === HAS_MANY) {
    return data.map(relationship =>
      buildRelationshipItem(defaultType, relationship)
    );
  }
  return buildRelationshipItem(defaultType, data);
}

// To create payload and addPending entities, create Entity with attributes and relationships
function entityFromProperties(schema, { id, type, _new, ...properties }) {
  if (!schema) return { id, type, attributes: properties };

  // reduce to only attributes defined in schema
  const attributes = schema.attributes.reduce((result, attribute) => {
    result[attribute] = properties[attribute];
    return result;
  }, {});

  const relationships = Object.entries(schema.relationships).reduce(
    (result, [relationshipName, { defaultType, relationshipType }]) => {
      const relationshipData = properties[relationshipName];

      if (relationshipData) {
        result[relationshipName] = {
          data: buildRelationshipData(
            relationshipName,
            defaultType,
            relationshipType,
            relationshipData
          ),
        };
      } else if (!relationshipData && schema.savePending) {
        result[relationshipName] = {
          data: relationshipType === HAS_MANY ? [] : null,
        };
      }
      return result;
    },
    {}
  );

  return {
    id,
    type,
    _new,
    attributes,
    relationships,
  };
}

export default entityFromProperties;
