import React, { useCallback, useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'fe-design-base/molecules/Button';
import FDBDatePickerField from 'fe-design-base/organisms/FDBDatePickerField';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
import { datePickerFormat, df, formatDate, today } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import FieldRenderer from '../../FieldRenderer';

import CalSaversFormFieldRows from './CalSaversFormFieldRows';
import ChildSupportFormFieldRows from './ChildSupportFormFieldRows';
import {
  PTD_TYPE_CALSAVERS,
  PTD_TYPE_CHILD_SUPPORT,
  PTD_TYPE_MISC,
  PTD_TYPE_OPTIONS,
  PTD_TYPE_OPTIONS_WITH_CALSAVERS,
  PTD_TYPES_LABEL,
} from './constants';
import MiscFormFieldRows from './MiscFormFieldRows';

const { cxEl, cx } = cxHelpers('PostTaxDeductionFormFields');
const US_STATE_CA = 'CA';

const PostTaxDeductionFormFields = ({
  isEditing,
  isGmod,
  values,
  onClickRemove,
  isSaveButtonDisabled,
  setFieldValue,
  user,
  showFormWithSteps,
}) => {
  const blockDatesBefore = useMemo(() => {
    if (!isGmod) {
      return today(df('parsable_reversed'));
    }
  }, [isGmod]);

  const handleOnChange = useCallback(
    ({ target: { value } }) => {
      if (value === PTD_TYPE_CALSAVERS) {
        setFieldValue('name', PTD_TYPES_LABEL.calsavers);
      } else if (values.name === PTD_TYPES_LABEL.calsavers) {
        setFieldValue('name', '');
      }
    },
    [setFieldValue, values.name]
  );

  const handleStartDateBlur = useCallback(() => {
    setFieldValue('start_date', formatDate(values.start_date, 'parsable_date'));
  }, [setFieldValue, values.start_date]);

  const handleEndDateBlur = useCallback(() => {
    setFieldValue('end_date', formatDate(values.end_date, 'parsable_date'));
  }, [setFieldValue, values.end_date]);

  return (
    <Box pb20 bb mb20 className={cx()}>
      <Box row mb32>
        <Box grow basis="40%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="payroll.run_workflow.shared.type_label"
            />
          </Box>
          <Box w={242}>
            <FieldRenderer
              data-testid="ptd-select-input"
              className={cxEl('ptd_type_select_input')}
              savedValue={
                values.name === PTD_TYPES_LABEL.calsavers
                  ? PTD_TYPES_LABEL.calsavers
                  : PTD_TYPES_LABEL[values.type]
              }
              editMode={!isEditing}
              name="type"
              component="SelectInput"
              options={
                user.get('address_state') === US_STATE_CA
                  ? PTD_TYPE_OPTIONS_WITH_CALSAVERS
                  : PTD_TYPE_OPTIONS
              }
              hideClear
              onChange={handleOnChange}
            />
          </Box>
        </Box>
        <Box grow basis="60%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.name_label"
            />
          </Box>
          <Box>
            <Box w={242}>
              <FieldRenderer
                editMode={values.name !== PTD_TYPES_LABEL.calsavers}
                name="name"
                component="TextInput"
                maxLength={40}
                className={cxEl('ptd_name')}
                savedValue={
                  values.name === PTD_TYPES_LABEL.calsavers ? values.name : null
                }
              />
            </Box>
            {values.name !== PTD_TYPES_LABEL.calsavers && (
              <Box>
                <Text
                  fs14
                  fw400
                  lh={1.25}
                  navy
                  i18n="team.employee_profile.tax_deductions.name_description_label"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box row mb32>
        <Box grow basis="40%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.start_date_label"
            />
          </Box>
          <Box className={cxEl('start-date')} maxw={220}>
            <FDBDatePickerField
              hideClearIcon
              name="start_date"
              blockDatesBefore={blockDatesBefore}
              value={
                values.start_date ? datePickerFormat(values.start_date) : null
              }
              onBlur={handleStartDateBlur}
            />
          </Box>
        </Box>
        <Box grow basis="60%">
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.end_date_optional_label"
            />
          </Box>
          <Box className={cxEl('start-date')} maxw={220}>
            <FDBDatePickerField
              hideClearIcon
              name="end_date"
              blockDatesBefore={values.start_date}
              value={values.end_date ? datePickerFormat(values.end_date) : null}
              onBlur={handleEndDateBlur}
            />
          </Box>
        </Box>
      </Box>

      {values.type === PTD_TYPE_CHILD_SUPPORT && (
        <ChildSupportFormFieldRows showFormWithSteps={showFormWithSteps}>
          {isEditing && (
            <Box className={cxEl('remove-deduction-button')}>
              <Button
                onClick={onClickRemove}
                size={showFormWithSteps ? 'small' : 'medium'}
                variant="secondaryDestructive"
                isLoading={isSaveButtonDisabled}
              >
                {toI18n(
                  'team.employee_profile.tax_deductions.remove_deduction'
                )}
              </Button>
            </Box>
          )}
        </ChildSupportFormFieldRows>
      )}

      {values.type === PTD_TYPE_MISC &&
        values.name !== PTD_TYPES_LABEL.calsavers && (
          <MiscFormFieldRows
            values={values}
            showFormWithSteps={showFormWithSteps}
          >
            {isEditing && (
              <Box className={cxEl('remove-deduction-button')}>
                <Button
                  onClick={onClickRemove}
                  size={showFormWithSteps ? 'small' : 'medium'}
                  variant="secondaryDestructive"
                  isLoading={isSaveButtonDisabled}
                >
                  {toI18n(
                    'team.employee_profile.tax_deductions.remove_deduction'
                  )}
                </Button>
              </Box>
            )}
          </MiscFormFieldRows>
        )}

      {(values.type === PTD_TYPE_CALSAVERS ||
        values.name === PTD_TYPES_LABEL.calsavers) && (
        <CalSaversFormFieldRows values={values}>
          {isEditing && (
            <Box className={cxEl('remove-deduction-button')}>
              <Button
                data-testid="calsaver-remove-button"
                onClick={onClickRemove}
                size={showFormWithSteps ? 'small' : 'medium'}
                variant="secondaryDestructive"
                isLoading={isSaveButtonDisabled}
              >
                {toI18n(
                  'team.employee_profile.tax_deductions.remove_deduction'
                )}
              </Button>
            </Box>
          )}
        </CalSaversFormFieldRows>
      )}
      <Box row mb12>
        {values.type === PTD_TYPE_CALSAVERS ||
        values.name === PTD_TYPES_LABEL.calsavers ? (
          <Box row grow basis={0} bradius bg="grayLight" pa20 vcenter>
            <Box row>
              <Box mr16>
                <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
              </Box>
              <Box hleft column>
                <Text
                  fs16
                  fw400
                  lh={1.25}
                  navy
                  i18n="team.employee_profile.tax_deductions.calsaver_reminder"
                  asHTML
                />
                <Button
                  variant="tertiary"
                  size={showFormWithSteps ? 'small' : 'medium'}
                  target="_blank"
                  href="https://www.calsavers.com/"
                >
                  {toI18n(
                    'team.employee_profile.tax_deductions.learn_calsaver'
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          values.type === PTD_TYPE_MISC && (
            <Box row grow basis={0} bradius bg="grayLight" pa20 vcenter>
              <Box data-testid="ptd_reminder">
                <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
                <Text
                  ml8
                  fs16
                  fw400
                  lh={1.25}
                  navy
                  i18n="team.employee_profile.tax_deductions.ptd_reminder"
                  asHTML
                />
              </Box>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};

PostTaxDeductionFormFields.propTypes = {
  isGmod: PropTypes.bool,
  values: PropTypes.object,
  onClickRemove: PropTypes.func,
  isEditing: PropTypes.bool,
  isSaveButtonDisabled: PropTypes.bool,
  setFieldValue: PropTypes.func,
  user: ImmutablePropTypes.map.isRequired,
};

export default PostTaxDeductionFormFields;
