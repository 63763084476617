export const FORM_BOX_SHADOW = '0 1px 6px 0 rgba(0, 0, 0, 0.12)';

export const ACKNOWLEDGEMENT = 'acknowledgement';
export const FILLABLE_FORM = 'fillable_form';

export const MULTIPLIER = 10000;
export const UPLOAD_DOCUMENT_PATH = [
  '/employee_onboarding/custom_documents/new',
];

export const STATE_TAX_FORM = {
  AL: 'Alabama A4',
  AK: null,
  AZ: 'Arizona A-4',
  AR: 'Arkansas AR4EC',
  CA: 'California DE-4',
  CO: 'Colorado DR 0004',
  CT: 'Connecticut CT-W4',
  DE: 'Delaware DE W-4',
  FL: null,
  GA: 'Georgia G-4',
  HI: 'Hawaii HW-4',
  ID: 'Idaho W-4',
  IL: 'Illinois IL-W-4',
  IN: 'Indiana WH-4',
  IA: 'Iowa IA W-4',
  KS: 'Kansas K-4',
  KY: 'Kentucky K-4',
  LA: 'Louisiana L-4',
  ME: 'Maine W-4ME',
  MD: 'Maryland MW507',
  MA: 'Massachusetts M-4',
  MI: 'Michigan MI-W4',
  MN: 'Minnesota W-4MN',
  MS: 'Mississippi 89-350',
  MO: 'Missouri MO W-4',
  MT: 'Montana MW-4',
  NE: 'Nebraska W-4N',
  NV: null,
  NH: null,
  NJ: 'New Jersey NJ-W4',
  NM: null,
  NY: 'New York IT-2104',
  NC: 'North Carolina NC-4',
  ND: null,
  OH: 'Ohio IT 4',
  OK: 'Oklahoma OK-W-4',
  OR: 'Oregon OR-W-4',
  PA: null,
  RI: 'Rhode Island RI W-4',
  SC: 'South Carolina SC-W-4',
  SD: null,
  TN: null,
  TX: null,
  UT: null,
  VT: 'Vermont W-4VT',
  VA: 'Virginia VA-4',
  WA: null,
  WV: 'West Virginia IT-104',
  WI: 'Wisconsin WT-4',
  WY: null,
};
