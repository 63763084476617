import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { toggleAddEmployeeWarningModalOpen } from 'actions/addTeam';

import { getAddEmployeeWarningModalOpen } from 'selectors/addTeam';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import FadeInBox from 'components/FadeInBox';
import Modal from 'components/modals/Modal';
import ModalHeader from 'components/modals/ModalHeader';
import Text from 'components/Text';

import cxHelpers from 'util/className';

import { PAY_ANYWHERE_ADD_TEAM_LINK } from '../constants';

@connect(
  state => ({
    isOpen: getAddEmployeeWarningModalOpen(state),
  }),
  {
    toggleModal: toggleAddEmployeeWarningModalOpen,
  }
)
@cxHelpers('AddEmployeeWarningModal')
export default class AddEmployeeWarningModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
  };

  handleClick = () => {
    window.open(PAY_ANYWHERE_ADD_TEAM_LINK, '_blank');
    this.handleClose();
  };

  handleClose = () => this.props.toggleModal(false);

  render() {
    return (
      <Modal
        className={this.cx(null, 'bootstrap')}
        isOpen={this.props.isOpen}
        onRequestClose={this.handleClose}
      >
        <FadeInBox column hcenter>
          <ModalHeader>
            <Text i18n="add_team.add_employee_warning.title" />
          </ModalHeader>

          <Box mvm tcenter>
            <Text i18n="add_team.add_employee_warning.description" />
          </Box>

          <Button
            size="medium"
            theme="primary"
            onClick={this.handleClick}
            i18n="add_team.add_employee_warning.button"
          />
        </FadeInBox>
      </Modal>
    );
  }
}
