import React from 'react';
import Icon from 'react-icon-base';

const Checklist = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M20.286 10.571h-2.38c0-1.041-.864-1.905-1.906-1.905-1.071 0-1.904.864-1.904 1.905h-2.381c-.804 0-1.429.655-1.429 1.429v10.476c0 .804.625 1.429 1.429 1.429h8.571c.774 0 1.429-.625 1.429-1.429V12c0-.774-.655-1.429-1.429-1.429zM16 9.857c.387 0 .715.327.715.714 0 .417-.328.715-.715.715-.416 0-.714-.298-.714-.715 0-.387.298-.714.714-.714zm-3.78 5.06l.388-.387c.06-.06.148-.06.208 0l.625.625 1.428-1.4c.06-.059.15-.059.209 0l.387.388c.06.06.06.148 0 .208l-1.905 1.905c-.06.06-.179.06-.238 0l-1.101-1.131c-.06-.06-.06-.149 0-.209zm.923 5.416c-.416 0-.714-.297-.714-.714 0-.387.298-.714.714-.714.387 0 .715.327.715.714 0 .416-.328.714-.715.714zm6.667-.476c0 .149-.12.238-.238.238h-4.286c-.149 0-.238-.09-.238-.238v-.476c0-.12.09-.238.238-.238h4.286c.119 0 .238.119.238.238v.476zm0-3.81c0 .15-.149.239-.298.239H14.87l.953-.953h3.69c.15 0 .298.12.298.238v.476z"
    />
  </Icon>
);

export default Checklist;
