import { createAsyncThunk } from '@reduxjs/toolkit';
import { locationRoute } from 'api';
import { putJSON } from 'api/fetch';

export const updateLocationSettings = createAsyncThunk(
  `tipSettings/updateUserSettings`,
  ({ id, data }, { rejectWithValue }) =>
    putJSON(locationRoute(id), {
      tip_shortage_enabled: data.tip_shortage_enabled,
      state_min_wage: data.state_min_wage,
      properties_attributes: {
        payroll_import_tips_enabled: data.payroll_import_tips_enabled,
      },
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);
