import { createSelector } from 'reselect';

import * as sessionSelectors from 'selectors/session';

export const getCanUseWebTimeClock = createSelector(
  sessionSelectors.getCurrentUserIsManagerial,
  sessionSelectors.getWebTimeClockEmployeeAccess,
  (isManagerial, webTimeClockEmployeeAccess) =>
    !!(isManagerial || webTimeClockEmployeeAccess) // !! prevents undefined result
);

export const getNotificationStatus = state =>
  state.getIn(['userWebTimeclock', 'status']);
