import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import SelectInput from 'components/forms/SelectInput';

import cxHelpers from 'util/className';

@cxHelpers('MaterialSelectInput')
export default class MaterialSelectInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    theme: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    theme: 'mui',
    placeholder: '',
  };

  handleChange = value => {
    if (this.props.onChange) {
      this.props.onChange({ target: { value } });
    }
  };

  render() {
    const { theme, placeholder, ...props } = this.props;
    return (
      <SelectInput
        theme={theme}
        placeholder={placeholder}
        {...omit(props, ['onChange', 'type'])}
        onChange={this.handleChange}
      />
    );
  }
}
