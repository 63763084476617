import './Success.scss';

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { CukooClock } from 'fe-design-base/assets/gifsDeprecated';
import Text from 'fe-design-base/atoms/Text';
import colors from 'fe-design-base/styles/colors';
import PropTypes from 'prop-types';

import {
  getCurrentCompanyonboardingRedirectPath,
  getUserOneTimeEventDone,
} from 'selectors/session';

import { EVENT_ACTIONS, STEP_LABELS } from 'features/signUp/constants';

import FadeIn from 'components/Animation/FadeIn';
import Box from 'components/Box';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import {
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useExtendUx, useTrackUxOnMount } from 'util/uxEvents';

const { SIGN_UP } = PRODUCT_AREAS;
const { ONBOARDING } = EVENT_CATEGORIES;
const { PAGE_VIEWED } = EVENT_ACTIONS;
const { VIEW } = TRACK_ACTION_TYPES;
const { SUCCESS_LOADING_SCREEN } = STEP_LABELS;

const { cx } = cxHelpers('Success');

const REDIRECT_WAIT_TIME = 1500;

export const Success = ({
  redirectUrl,
  gotOnboardingSms,
  confirmationFlow,
}) => {
  const extendUx = useExtendUx();

  const extendUxTrackingData = useMemo(() => {
    if (!confirmationFlow) {
      return { step_label: SUCCESS_LOADING_SCREEN };
    }

    return {
      productArea: SIGN_UP,
      eventCategory: ONBOARDING,
      step_label: SUCCESS_LOADING_SCREEN,
      confirmationFlow,
    };
  }, [confirmationFlow]);

  extendUx(extendUxTrackingData);
  useTrackUxOnMount(PAGE_VIEWED, VIEW);

  useEffect(() => {
    setTimeout(() => {
      window.location = gotOnboardingSms
        ? redirectUrl
        : '/onboarding/questions';
    }, REDIRECT_WAIT_TIME);
  });

  return (
    <Box
      w="100vw"
      h="100vh"
      column
      vcenter
      hcenter
      bg={colors.purple100}
      className={cx()}
    >
      <Box vcenter hcenter mb={24}>
        <FadeIn delay={500}>
          <CukooClock className="welcome-gif" />
        </FadeIn>
      </Box>
      <Box tcenter className="text-container">
        <FadeIn delay={250}>
          <Text variant="display3" className="title">
            {toI18n('onboarding.success_title')}
          </Text>
        </FadeIn>
      </Box>

      {/* Background doodles */}
      <FadeIn>
        <img
          style={{ position: 'absolute', left: 0, top: 0 }}
          src={require('./img/doodles1.svg')}
        />
        <img
          style={{ position: 'absolute', left: -30, bottom: -50, width: 300 }}
          src={require('./img/doodles2.svg')}
        />
        <img
          style={{ position: 'absolute', right: -75, top: 338 }}
          src={require('./img/doodles3.svg')}
        />
      </FadeIn>
    </Box>
  );
};

Success.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  gotOnboardingSms: PropTypes.bool.isRequired,
  confirmationFlow: PropTypes.bool,
};

Success.defaultProps = {
  confirmationFlow: false,
};

export default connect(
  state => ({
    redirectUrl: getCurrentCompanyonboardingRedirectPath(state),
    gotOnboardingSms: getUserOneTimeEventDone(state, 'sign_up_flow_sms'),
  }),
  {}
)(Success);
