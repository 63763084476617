import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgInstantPayOutline = ({
  alt = 'Instant Pay Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M20.869 8.1c-.192-.37-.577-.6-.994-.6h-7.34l2.383-5.958c.139-.346.096-.74-.113-1.049a1.176 1.176 0 0 0-.93-.493h-8.25c-.564 0-1.04.417-1.115.977l-1.5 11.25A1.128 1.128 0 0 0 4.125 13.5h6.858l-1.959 9.098a1.126 1.126 0 0 0 .657 1.27.727.727 0 0 0 .444.132c.362 0 .713-.176.929-.49l9.75-14.25c.234-.344.262-.79.065-1.16Zm-8.447 9.427 1.053-4.913a1.123 1.123 0 0 0-.225-.943 1.118 1.118 0 0 0-.875-.421H5.41l1.2-9h5.606L9.83 8.208c-.136.347-.094.74.112 1.05.206.31.558.492.933.492h6.867l-5.32 7.777Z"
    />
  </svg>
);
export default SvgInstantPayOutline;
