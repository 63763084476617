import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchEmployees } from 'features/signUp/lightspeed/api';
import { SLICE_NAME } from 'features/signUp/lightspeed/constants';

export const fetchLightspeedEmployees = createAsyncThunk(
  `${SLICE_NAME}/fetchEmployees`,
  (_, { rejectWithValue }) =>
    fetchEmployees().catch(error =>
      rejectWithValue(error.body || error.message)
    )
);
