import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as routes from 'api';
import { postJSON } from 'api/fetch';
import PropTypes from 'prop-types';

import { getShowMobileInterstitial } from 'selectors/session';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import withUxTracking from 'components/hocs/withUxTracking';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { EVENT_ACTIONS, PRODUCT_AREAS } from 'util/tracking_constants';

import { Mobile } from '../MediaQuery';

@withUxTracking('Mobile Web Interstitial', 'Mobile Web Interstitial')
@withRouter
@connect((state, props) => ({
  getShowMobileInterstitial: getShowMobileInterstitial(state, props),
}))
@cxHelpers('BottomMobileInterstitial')
export default class BottomMobileInterstitial extends PureComponent {
  static propTypes = {
    trackUxEvent: PropTypes.func.isRequired,
  };

  state = {
    isDismissed: false,
  };

  shouldRenderMobileInterstitial = pathName => {
    switch (pathName) {
      case '/schedule_builder':
      case '/timesheets':
      case '/team':
        return true;
      default:
        return false;
    }
  };

  onDismissClick = productArea => {
    postJSON(routes.dismissMobileInterstitial()).then(() => {
      this.onDismissSuccess(productArea);
    });
  };

  onDismissSuccess = productArea => {
    this.props.trackUxEvent({
      productArea,
      eventAction: EVENT_ACTIONS.BASELINE_CLOSE_CLICKED,
    });
    this.setState({ isDismissed: true });
  };

  onGetAppClick = productArea => {
    this.props.trackUxEvent({
      productArea,
      eventAction: EVENT_ACTIONS.BASELINE_DOWNLOAD_CLICKED,
    });
    window.location = '/interstitial';
  };

  show = (mobile, productArea) => {
    const show =
      mobile && !this.state.isDismissed && this.props.getShowMobileInterstitial;

    if (show) {
      this.props.trackUxEvent({
        productArea,
        eventAction: EVENT_ACTIONS.BASELINE_SHOWN,
      });
    }

    return show;
  };

  getPageProperties = pathName => {
    switch (pathName) {
      case '/schedule_builder':
        return {
          productArea: PRODUCT_AREAS.SCHEDULE_BUILDER,
          title: 'bottom_mobile_interstitial.schedule_builder.title',
          subtitle: 'bottom_mobile_interstitial.schedule_builder.subtitle',
        };
      case '/timesheets':
        return {
          productArea: PRODUCT_AREAS.TIMESHEETS,
          title: 'bottom_mobile_interstitial.timesheets.title',
          subtitle: 'bottom_mobile_interstitial.timesheets.subtitle',
        };
      case '/team':
        return {
          productArea: PRODUCT_AREAS.TEAM,
          title: 'bottom_mobile_interstitial.team.title',
          subtitle: 'bottom_mobile_interstitial.team.subtitle',
        };
      default:
        return {
          title: 'bottom_mobile_interstitial.title',
        };
    }
  };

  renderTitle = translation => (
    <Box>
      <Text fw700 navy fs18 i18n={translation} />
    </Box>
  );

  renderSubTitle = translation => (
    <Box>
      <Text navy fs14 i18n={translation} />
    </Box>
  );

  handleClickDismiss = () => {
    const pageProperties = this.getPageProperties(this.props.location.pathname);
    this.onDismissClick(pageProperties.productArea);
  };

  handleClickGetApp = () => {
    const pageProperties = this.getPageProperties(this.props.location.pathname);
    this.onGetAppClick(pageProperties.productArea);
  };

  render() {
    if (!this.shouldRenderMobileInterstitial(this.props.location.pathname)) {
      return null;
    }

    const pageProperties = this.getPageProperties(this.props.location.pathname);
    const productArea = pageProperties.productArea;

    return (
      <Mobile>
        {mobile =>
          this.show(mobile, productArea) && (
            <Box
              z={1000}
              bg="white"
              muiShadow
              bt
              className={this.cx(null, 'bootstrap')}
              maxh={144}
              fixed
              bottom={0}
              w="100%"
              pt={16}
              pr={48}
            >
              <Box row pl={16}>
                <Box w={36} h={36}>
                  <img
                    width={36}
                    height={36}
                    src={require('../BottomMobileInterstitial/images/homebase_app_icon.png')}
                    alt="Homebase Icon"
                  />
                </Box>
                <Box>
                  <Box ml={16}>
                    {pageProperties.title &&
                      this.renderTitle(pageProperties.title)}
                    {pageProperties.subtitle &&
                      this.renderSubTitle(pageProperties.subtitle)}
                  </Box>
                </Box>
              </Box>

              <Box row ml={56} mb={24} mt={16}>
                <Box w={136} h={48}>
                  <Box p={12}>
                    <Button
                      className="phxl"
                      size="medium"
                      theme="link-purple"
                      i18n="bottom_mobile_interstitial.dismiss"
                      onClick={this.handleClickDismiss}
                    />
                  </Box>
                </Box>

                <Box w={136} h={48}>
                  <Button
                    fullWidth
                    size="medium"
                    theme="primary-purple"
                    i18n="bottom_mobile_interstitial.download"
                    onClick={this.handleClickGetApp}
                  />
                </Box>
              </Box>
            </Box>
          )
        }
      </Mobile>
    );
  }
}
