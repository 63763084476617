import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { getTipInsByDayData } from 'features/bottomDrawers/TipPoolDrawer/CalculateTipOutsDrawer/selectors';
import {
  getActiveTipPolicy,
  getTipPoolingSources,
} from 'features/settings/TipManagerSettingView/selectors';
import { getPolicyRolesFromReceivers } from 'features/settings/TipManagerSettingView/TableRow/utils';

import { toCurrency } from 'util/currency';
import { formatDate, isToday } from 'util/dateTime';
import { toI18n } from 'util/i18n';
const I18NPATH_COLUMNS =
  'tip_pooling_settings.tip_pool_drawer.calculate_tips_drawer.tip_ins_step.table_columns';

export const formatName = policy => {
  const MAX_LABEL_CONTENT_LENGTH = 25;
  if (policy.length > MAX_LABEL_CONTENT_LENGTH) {
    return policy.slice(0, MAX_LABEL_CONTENT_LENGTH) + '...';
  }
  return policy;
};

export const formatReceivers = receivers => {
  const policyRoles = getPolicyRolesFromReceivers(receivers);
  if (policyRoles.length > 0) {
    return toI18n(`tip_pooling_settings.table_data.roles.selected_roles`, {
      props: { count: policyRoles.length },
    });
  }
  return toI18n(`tip_pooling_settings.table_data.roles.all_roles`);
};

export const formatMethod = method =>
  toI18n(`tip_pooling_settings.table_data.${method}`);

export const formatFrequency = frequency =>
  toI18n(`tip_pooling_settings.table_data.${frequency}`);

export const getTipPolicyValue = (tipPolicy, column) => {
  switch (column) {
    case 'name':
      return formatName(tipPolicy.name);
    case 'recipients':
      return formatReceivers(tipPolicy.policy_receivers);
    case 'method':
      return formatMethod(tipPolicy.calculation_method);
    case 'frequency':
      return formatFrequency(tipPolicy.frequency);
    default:
      return '';
  }
};

// Utility functions
const formatDateValue = date => {
  const formattedDate = formatDate(date, 'pretty_full_no_year');
  if (isToday(date)) {
    return (
      <Box
        key="date"
        bradiusl
        bgcolor="blue100"
        ph={12}
        pv={4}
        color="blue300"
        w="fit-content"
        sx={{
          transform: 'translate(-22px, 0)',
        }}
      >
        <Text variant="bodyBold" noWrap>
          {formattedDate}
        </Text>
      </Box>
    );
  }
  return formattedDate;
};

const formatTipValue = value => {
  if (isNaN(value)) {
    return '--';
  }
  return `$${(value || 0).toFixed(2)}`;
};

const generateRowTotals = (tipsByDay, key) => {
  const getNumberValue = value => {
    if (!value || value === '--') {
      return 0;
    }
    return parseFloat(value);
  };
  const total = tipsByDay.reduce(
    (acc, day) => acc + getNumberValue(day[key]),
    0
  );
  return toCurrency(total);
};

export const generateColumnData = ({
  key,
  label,
  isEditable,
  align = 'right',
  width,
  maxWidth,
  variant,
  onEdit,
  tipsByDay,
}) => {
  const data = {
    id: key,
    label,
    align,
    width,
    maxWidth: key === 'date' ? null : maxWidth,
    rowValues: tipsByDay.map(day =>
      key === 'date' ? formatDateValue(day[key]) : formatTipValue(day[key])
    ),
    footerValue: key === 'date' ? 'Total' : generateRowTotals(tipsByDay, key),
    isEditable,
    cellPrefix: key === 'date' ? undefined : '$',
    variant,
    onEdit: isEditable ? onEdit : null,
  };

  // delete keys with null values
  Object.keys(data).forEach(
    keyName =>
      (data[keyName] === null || data[keyName] === undefined) &&
      delete data[keyName]
  );
  return data;
};

// Column generation functions
export const generateDateColumn = tipsByDay =>
  generateColumnData({
    key: 'date',
    label: toI18n(`${I18NPATH_COLUMNS}.date`),
    align: 'left',
    variant: 'bodyBold',
    tipsByDay,
  });

export const generatePosColumn = ({ tipsByDay, importTipsFromPOS }) =>
  generateColumnData({
    key: importTipsFromPOS ? 'imported_pos_tips' : 'pos_tips',
    label: toI18n(`${I18NPATH_COLUMNS}.credit`),
    maxWidth: '140px',
    width: '140px',
    isEditable: !importTipsFromPOS,
    tipsByDay,
  });

export const generateEditableColumns = ({
  tipsByDay,
  editableColumnKeys,
  handleUpdateCell,
}) =>
  editableColumnKeys
    .filter(
      key =>
        key !== 'date' && key !== 'imported_pos_tips' && key !== 'total_tips'
    )
    .map(key =>
      generateColumnData({
        key,
        label: toI18n(`${I18NPATH_COLUMNS}.${key}`),
        maxWidth: '140px',
        width: '140px',
        isEditable: true,
        onEdit: handleUpdateCell,
        tipsByDay,
      })
    );

const generateTotalColumnRowValues = (tipsByDay, tableColumns) => {
  const summableColumns = tableColumns.filter(column => column.id !== 'date');
  const getNumberValue = value => {
    if (!value || value === '--') {
      return 0;
    }
    return parseFloat(value.replace('$', ''));
  };

  return tipsByDay.map((day, index) =>
    summableColumns.reduce(
      (acc, column) => acc + getNumberValue(column.rowValues[index]),
      0
    )
  );
};

export const generateTotalColumn = (tipsByDay, tableColumns) => {
  const rowValues = generateTotalColumnRowValues(tipsByDay, tableColumns);
  return {
    id: 'totals',
    label: toI18n(`${I18NPATH_COLUMNS}.totals`),
    align: 'right',
    width: '140px',
    maxWidth: '140px',
    rowValues: rowValues.map(value => toCurrency(value)),
    variant: 'bodyBold',
    footerValue: `${toCurrency(
      rowValues.reduce((acc, value) => acc + value, 0)
    )}`,
  };
};

// Main function
export function convertToTableColumns({
  tipsByDay,
  importTipsFromPOS,
  editableColumnKeys,
  handleUpdateCell,
}) {
  if (!tipsByDay) return [];

  const tableColumns = [];

  tableColumns.push(generateDateColumn(tipsByDay));

  tableColumns.push(generatePosColumn({ tipsByDay, importTipsFromPOS }));

  tableColumns.push(
    ...generateEditableColumns({
      tipsByDay,
      editableColumnKeys,
      handleUpdateCell,
    })
  );

  if (tableColumns.length > 2) {
    const totalColumn = generateTotalColumn(tipsByDay, tableColumns);
    tableColumns.push(totalColumn);
  }

  return tableColumns;
}

export const getTipInsUpdateParams = (state, payload) => {
  const { columnKey, rowIndex, value } = payload;
  let tipPoolingPolicySourceId = null;

  const tipsByDay = getTipInsByDayData(state);
  const policySources = getTipPoolingSources(state);
  const activeTipPolicy = getActiveTipPolicy(state);

  const activePolicySources = activeTipPolicy.all_policy_sources;

  if (columnKey === 'pos_tips') {
    const policySource = activePolicySources.find(
      source => source.selectable === false
    );

    tipPoolingPolicySourceId = policySource.id;
  } else {
    const sourceTypeId = policySources
      .find(source => source.get('source_type') === columnKey)
      .get('id');

    const policySource = activePolicySources.find(
      source => source.tip_pooling_source_id === sourceTypeId
    );

    tipPoolingPolicySourceId = policySource.id;
  }

  const params = {
    collected_date: tipsByDay[rowIndex].date,
    tip_pooling_policy_source_id: tipPoolingPolicySourceId,
    amount: value,
  };

  return params;
};

export const populateResponseWithSourceType = (state, response) => {
  const tipPoolingSources = getTipPoolingSources(state);
  const sourceIdsInResponse = Object.keys(response);
  const transformedResponse = [];

  const dates = response[sourceIdsInResponse[0]].map(day => day.date);

  dates.forEach(date => {
    const dateObj = {
      date,
    };

    sourceIdsInResponse.forEach(sourceId => {
      const source = tipPoolingSources.find(
        s => s.get('id') === parseInt(sourceId, 10)
      );
      const sourceType = source ? source.get('source_type') : 'pos_tips';
      dateObj[sourceType] = response[sourceId].find(
        day => day.date === date
      ).total_tips;
    });

    transformedResponse.push(dateObj);
  });

  return transformedResponse;
};

export const getTipInsTotalsFromData = data => {
  const total = data.reduce(
    (acc, day) =>
      acc +
      Object.values(day).reduce(
        (sum, value) => (typeof value === 'number' ? sum + value : sum),
        0
      ),
    0
  );

  return total;
};
