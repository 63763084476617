import { schema } from 'normalizr';

const jobSchema = new schema.Entity('jobs');
const locationSchema = new schema.Entity('locations');
const userSchema = new schema.Entity('users');
const jobRequestSchema = new schema.Entity('job_requests');
const companyProfileSchema = new schema.Entity(
  'company_profiles',
  {},
  { idAttribute: 'url' }
);
const locationProfileSchema = new schema.Entity('location_profiles');
const channelSchema = new schema.Entity('channels');
const messageSchema = new schema.Entity('messages');
const departmentSchema = new schema.Entity('departments');
const timeOffSchema = new schema.Entity('time_offs');
const ptoPolicySchema = new schema.Entity('pto_policies');
const applicationSchema = new schema.Entity('applications');
const applicationMessageSchema = new schema.Entity('application_messages');
const applicationConversationSchema = new schema.Entity(
  'application_conversations'
);
const applicantSchema = new schema.Entity('applicant');
const boostSchema = new schema.Entity('boosts');

jobSchema.define({
  location: locationSchema,
  user: userSchema,
  timeOffs: [timeOffSchema],
});

locationSchema.define({
  jobs: [jobSchema],
  applications: [applicationSchema],
  job_requests: [jobRequestSchema],
});

userSchema.define({
  jobs: [jobSchema],
  pto_policy: ptoPolicySchema,
});

jobRequestSchema.define({
  location: locationSchema,
  applications: [applicationSchema],
  boosts: [boostSchema],
});

companyProfileSchema.define({
  location_profiles: [locationProfileSchema],
});

locationProfileSchema.define({
  job_requests: [jobRequestSchema],
});

channelSchema.define({
  users: [userSchema],
  messages: [messageSchema],
});

messageSchema.define({
  channel: channelSchema,
});

timeOffSchema.define({
  job: jobSchema,
});

applicationSchema.define({
  application_conversation: applicationConversationSchema,
});

applicationConversationSchema.define({
  application: applicationSchema,
  application_messages: [applicationMessageSchema],
});

applicationMessageSchema.define({
  application_conversation: applicationConversationSchema,
});

applicantSchema.define({
  applications: [applicationSchema],
});

export {
  jobSchema,
  locationSchema,
  userSchema,
  jobRequestSchema,
  companyProfileSchema,
  locationProfileSchema,
  channelSchema,
  messageSchema,
  departmentSchema,
  timeOffSchema,
  ptoPolicySchema,
  applicationSchema,
  applicationConversationSchema,
  applicationMessageSchema,
  applicantSchema,
  boostSchema,
};
