import { keys } from 'lodash';

const schemas = {};

export const registerSchema = (resourceName, schema) =>
  (schemas[resourceName] = schema);

export const getSchema = resourceName => schemas[resourceName];

// Really just useful for testing.
export const dropSchemas = () =>
  keys(schemas).forEach(key => delete schemas[key]);
