export enum ApprovalState {
  Unapplied = 'Unapplied',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export type PanelStates =
  | 'zeroState'
  | 'ownerForm'
  | 'companyForm'
  | 'thankYou'
  | 'emptyState'
  | 'candidateInfo'
  | 'paymentForm'
  | 'steadyState';

export interface BackgroundCheckState {
  approvalStatus: ApprovalState;
  currentPanelKey: PanelStates;
  candidateQuery: CandidateQuery;
  ownerInfo: OwnerInfo;
  companyInfo: CompanyInfo;
  candidateInfo: CandidateApplicationInfo;
  candidateCheckPresubmission: CandidateCheckPresubmissionResponse;
  products: ProductsResponse;
  paymentInProgress: boolean;
}

export interface CandidateQuery {
  candidates: CandidateInfo[];
  currentPage: number;
  loading: boolean;
  error: string;
}

export interface OwnerInfo {
  first_name: string;
  last_name: string;
  email: string;
  title: string;
  phone_number: string;
}

export interface CompanyInfo {
  companyApplicationId: string;
  companyLegalName: string;
  dbaTradeName: string;
  companyWebsite: string;
  companyType: string;
  phone: string;
  industry: string;
  fein: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  termsAccepted: boolean;
}

export interface CandidateApplicationInfo {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  phone_number: string;
}

export interface PanelMethods {
  handleNextStep: () => Promise<void>;
  handleBackStep: () => Promise<void>;
}

export interface CandidatePayload {
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_email: string;
  candidate_role: string;
  candidate_phone_number: string;
}

export interface ApplicationPayload {
  company: {
    name: string;
    dba_name: string;
    address_street: string;
    address_city: string;
    address_state: string;
    address_zip: string;
    phone: string;
    website: string;
    industry: string;
    entity_state: string;
    entity_type: string;
    compliance_state: string;
    fein: string;
  };
  billing: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
  admin_user: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
  };
}

export interface ApplicationResponse {
  status: boolean;
}

export interface StatusResponse {
  status: string;
}

export interface ApplicationReferenceIdResponse {
  provider_reference_id?: string;
}

export interface CandidateChecksResponse {
  candidate_checks: CandidateInfo[];
  total_count: number;
}

export interface CandidateCheckPresubmissionResponse {
  candidate_check?: {
    id: number;
    company_uuid: string;
    user_uuid: string;
    package_tier?: string;
    background_checks_provider: string;
    background_checks_provider_reference_id?: string;
    completed_at?: string;
    canceled_at?: string;
    candidate_first_name: string;
    candidate_last_name: string;
    candidate_email: string;
    status: string;
    created_at: string;
    updated_at: string;
    uuid?: string;
    candidate_role: string;
  };
}

export interface CandidateInfo {
  name: string;
  status: string;
  package_details: string;
  purchase_date: string;
  score: string;
  report: string;
  uuid: string;
}

export interface ReportUUIDResponse {
  redirect_url: string;
}

export interface ProductPrice {
  system_id: string;
  amount_in_cents: number;
  metadata: Record<string, any>;
}

export interface Product {
  product_id: number;
  product_name: string;
  category: string;
  billing_frequency: string;
  purchase_type: string;
  price: ProductPrice[];
}

export interface ProductsResponse {
  products: Product[];
}
