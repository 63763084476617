import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import FormikErrorMessage from 'components/forms/FormikErrorMessage';
import FormikInput from 'components/forms/FormikInput';
import Text from 'components/Text';

import RequiredAttribute from './components/RequiredAttribute';

const FieldRenderer = ({ editMode, name, savedValue, ...props }) => {
  const Component = FormikInput;
  if (editMode) {
    return (
      <Box>
        <Component {...props} name={name} />
        <FormikErrorMessage name={name} fs={12} withoutIcon />
      </Box>
    );
  }

  if (props.type === 'currency' && savedValue) {
    return (
      <Text navy fs16>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(savedValue)}
      </Text>
    );
  }

  if (props.isRequired) {
    return (
      <RequiredAttribute
        user={props.user}
        targetObject={props.user}
        attribute={name}
      >
        <Text navy>{savedValue}</Text>
      </RequiredAttribute>
    );
  }

  return (
    <Text navy fs16>
      {savedValue}
    </Text>
  );
};

FieldRenderer.propTypes = {
  editMode: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

export default FieldRenderer;
