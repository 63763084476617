import React, { useCallback } from 'react';
import Box from 'fe-components/Box';
import Icon from 'fe-design-base/atoms/Icon';
import PropTypes from 'prop-types';

const DeleteRoleWage = ({
  idx,
  numAdditionalRoleWage,
  setFieldValue,
  setNumAdditionalRoleWage,
  updateSelectedRoles,
  values,
}) => {
  const removeRoleWage = useCallback(
    i => {
      const arr = values.wageAttributes;
      if (i === -1) return arr;
      updateSelectedRoles('clear', values.wageAttributes[i].role_name, '');

      arr.splice(i, 1);
      setFieldValue('wageAttributes', arr);
    },
    [setFieldValue, updateSelectedRoles, values.wageAttributes]
  );

  const handleDeleteRoleWage = useCallback(() => {
    removeRoleWage(idx);
    setNumAdditionalRoleWage(numAdditionalRoleWage - 1);
  }, [idx, numAdditionalRoleWage, removeRoleWage, setNumAdditionalRoleWage]);
  return (
    <Box
      mh={8}
      h={40}
      w={21}
      column
      vcenter
      inlineBlock
      onClick={handleDeleteRoleWage}
      pointer
    >
      <Icon iconName="Delete" color="red300" />
    </Box>
  );
};

DeleteRoleWage.propTypes = {
  idx: PropTypes.number,
  numAdditionalRoleWage: PropTypes.number,
  setNumAdditionalRoleWage: PropTypes.func,
  setFieldValue: PropTypes.func,
  updateSelectedRoles: PropTypes.func,
  values: PropTypes.object,
};
export default DeleteRoleWage;
