import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';
export const fetchPacketInitialData = createAsyncThunk(
  'packetWorkflow/fetchPacketInitialData',
  ({ packetId, queryParams }) =>
    fetchJSON(`/employee_onboarding/packet/${packetId}.json${queryParams}`)
);

export const updateContactInformation = createAsyncThunk(
  'packetWorkFlow/updateContactInformation',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    putJSON(
      `/employee_onboarding/packet/${packetId}/contact_information.json${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const saveTaxFormConsent = createAsyncThunk(
  'packetWorkFlow/saveTaxFormConsent',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    postJSON(
      `/employee_onboarding/packet/${packetId}/tax_form_consent${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const completeForm = createAsyncThunk(
  'packetWorkFlow/completeForm',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    postJSON(
      `/employee_onboarding/packet/${packetId}/complete_form${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const completeCustomDocument = createAsyncThunk(
  'packetWorkFlow/completeCustomDocument',
  ({ packetId, data, queryParams }, { rejectWithValue }) =>
    postJSON(
      `/employee_onboarding/packet/${packetId}/complete_custom_document${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const setPassword = createAsyncThunk(
  'packetWorkflow/setPassword',
  ({ packetId, queryParams, data }, { rejectWithValue }) =>
    postJSON(
      `/employee_onboarding/packet/${packetId}/set_password${queryParams}`,
      data
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const packetConfirmation = createAsyncThunk(
  'packetWorkflow/packetConfirmation',
  ({ packetId, queryParams }) =>
    postJSON(
      `/employee_onboarding/packet/${packetId}/packet_confirmation${queryParams}`
    )
);

export const completeDirectDepositForm = createAsyncThunk(
  'packetWorkflow/completeDirectDepositForm',
  ({ packetId, data, queryParams }) =>
    postJSON(`${packetId}/complete_direct_deposit_form${queryParams}`, data)
);

const packetWorkflowSlice = createSlice({
  name: 'packetWorkflow',
  initialState: {
    packet: {},
    location: {},
    user: {},
    currentStep: 0,
    remainingSteps: [],
    serverErrors: [],
    isSubmitting: false,
    addressError: null,
  },
  reducers: {
    nextStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
  extraReducers: {
    [fetchPacketInitialData.fulfilled]: (state, action) => {
      state.packet = action.payload.packet;
      state.location = action.payload.location;
      state.user = action.payload.user;
      state.currentStep = action.payload.current_step;
      state.remainingSteps = action.payload.remaining_steps;
    },
    [setPassword.fulfilled]: (state, action) => {
      state.serverErrors = [];
      state.remainingSteps = action.payload.remaining_steps;
      state.currentStep = action.payload.current_step;
    },
    [setPassword.rejected]: (state, action) => {
      state.serverErrors = action.payload.errors;
    },
    [updateContactInformation.pending]: state => {
      state.isSubmitting = true;
    },
    [updateContactInformation.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      state.serverErrors = [];
      state.addressError = null;
      state.packet = action.payload.packet;
      state.user = action.payload.user;
      state.remainingSteps = action.payload.remaining_steps;
      state.currentStep = action.payload.current_step;
    },
    [updateContactInformation.rejected]: (state, action) => {
      state.isSubmitting = false;
      state.serverErrors = action.payload.errors;
      state.addressError = action.payload.address_error;
    },
    [saveTaxFormConsent.fulfilled]: (state, action) => {
      state.serverErrors = [];
      state.packet = action.payload.packet;
      state.remainingSteps = action.payload.remaining_steps;
      state.currentStep = action.payload.current_step;
    },
    [saveTaxFormConsent.rejected]: (state, action) => {
      state.serverErrors = action.payload.errors;
    },
    [completeForm.fulfilled]: (state, action) => {
      state.packet = action.payload.packet;
      state.user = action.payload.user;
      state.remainingSteps = action.payload.remaining_steps;
      state.currentStep = action.payload.current_step;
    },
    [completeCustomDocument.fulfilled]: (state, action) => {
      state.packet = action.payload.packet;
      state.user = action.payload.user;
      state.remainingSteps = action.payload.remaining_steps;
      state.currentStep = action.payload.current_step;
    },
    [completeDirectDepositForm.fulfilled]: (state, action) => {
      state.packet = action.payload.packet;
      state.user = action.payload.user;
      state.remainingSteps = action.payload.remaining_steps;
      state.currentStep = action.payload.current_step;
    },
  },
});

export const packetWorkflowReducer = packetWorkflowSlice.reducer;
export const packetWorkflowActions = packetWorkflowSlice.actions;
