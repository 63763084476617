import { updateQuery } from 'util/router';
import { filterUsersBySearchTerm } from 'util/user';

import { TABLE_DIMENSIONS } from './constants';
export const updateFilter = (filter, value) => updateQuery(filter, value);
export const updateSearch = value => updateQuery('q', value);

export const clearFiltersAndSearch = () => {
  updateFilter('location', null);
  updateFilter('role', null);
  updateFilter('status', null);
  updateFilter('accessLevel', null);
  updateSearch(null);
};

// Builds locations filter drop-down option data from a Map of the
// company's locations.
export const buildLocationOptions = locations =>
  locations.reduce((memo, location) => {
    memo.push({
      label: location.get('name'),
      value: location.get('id'),
    });

    return memo;
  }, []);

export const getSuggestions = (value, options) => {
  // Don't show anything if the input is empty
  if (!value) {
    return [];
  }

  return filterUsersBySearchTerm(options, value).slice(0, 5);
};

export const getSuggestionValue = value => value.get('full_name');

const {
  MIN_ROW_HEIGHT,
  PENDING_BASE_HEIGHT,
  PENDING_VAR_HEIGHT,
  LOCATIONS_BASE_HEIGHT,
  LOCATIONS_VAR_HEIGHT,
} = TABLE_DIMENSIONS;

export const calculateRowHeight = (user, showRemoved) => {
  const numLocations = user
    .get('jobs')
    .filter(job => showRemoved || !job.get('archived_at')).size;
  const numPendingJobs = user.get('pending_jobs').size;

  const locationsHeight =
    LOCATIONS_BASE_HEIGHT + numLocations * LOCATIONS_VAR_HEIGHT;
  const pendingJobsHeight =
    PENDING_BASE_HEIGHT + numPendingJobs * PENDING_VAR_HEIGHT;

  return Math.max(MIN_ROW_HEIGHT, locationsHeight, pendingJobsHeight);
};
