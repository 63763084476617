import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dismissShiftAlert, editShiftAlert } from 'actions/notifications';
import { enableEditMode, showTimecardModal } from 'actions/timecard';

import { df, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import AlertsListItem from '../AlertsListItem';
@connect(() => ({}), {
  editShiftAlert,
  dismissShiftAlert,
  enableEditMode,
  showTimecardModal,
})
export default class ShiftAlert extends PureComponent {
  static propTypes = {
    descI18n: PropTypes.string,
    editShiftAlert: PropTypes.func.isRequired,
    dismissShiftAlert: PropTypes.func.isRequired,
    enableEditMode: PropTypes.func.isRequired,
    showTimecardModal: PropTypes.func.isRequired,
  };

  actualStartDate() {
    return moment(this.props.item.get('actual_start_date')).format(
      df('msg_time')
    );
  }

  scheduledStartDate() {
    return moment(this.props.item.get('scheduled_start_date')).format(
      df('msg_time')
    );
  }

  formattedFullName() {
    const { item } = this.props;
    return `${item.get('first_name')} ${item.get('last_name')}`;
  }

  descI18nProps = () => ({
    name: this.formattedFullName(),
    actual_start_at: this.actualStartDate(),
    scheduled_start_at: this.scheduledStartDate(),
  });

  handleDismiss = () => this.props.dismissShiftAlert(this.props.item);

  handleEdit = () => this.props.editShiftAlert(this.props.item);

  handleEditClockOut = () => {
    this.props.showTimecardModal(this.props.item.get('id'), null, null, {
      startingPath: TRACK_STARTING_PATHS.ALERTS,
    });
    this.props.enableEditMode('actual_end_at');
  };

  params = () => ({
    desc: toI18n(this.props.descI18n || 'notifications.alerts.default.desc', {
      props: this.descI18nProps(),
    }),
    onEdit:
      this.props.item.get('type') === 'clock_out'
        ? this.handleEditClockOut
        : this.handleEdit,
    onDismiss: this.handleDismiss,
    ...this.props, // we should always include this props at the very end of the hash
  });

  render() {
    return <AlertsListItem {...this.params()} />;
  }
}
