import React, { useCallback } from 'react';
import { animated, config, useTransition } from '@react-spring/web';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

const { cxEl } = cxHelpers('BottomDrawer');

const { BUTTON_CLICKED, DISMISS_CLICKED } = EVENT_ACTIONS;
const { CLICK } = TRACK_ACTION_TYPES;

const NavActionsBar = ({
  bottomDrawerHelpers,
  nextStepButtonText,
  previousStepButtonText,
  closeButtonText,
  onNextStepClick,
  onPreviousStepClick,
  onCancelClick,
  ctaTracking,
}) => {
  const trackUx = useTrackUx();

  const handleCloseClick = useCallback(() => {
    trackUx(DISMISS_CLICKED, CLICK, {
      button_text: 'Cancel',
      ...ctaTracking,
    });

    if (onCancelClick) {
      onCancelClick();
    } else {
      bottomDrawerHelpers.closeBottomDrawer();
    }
  }, [trackUx, ctaTracking, onCancelClick, bottomDrawerHelpers]);

  const handleNextStepClick = useCallback(() => {
    trackUx(BUTTON_CLICKED, CLICK, {
      button_text: 'Continue',
      ...ctaTracking,
    });

    if (onNextStepClick) {
      onNextStepClick();
    } else {
      bottomDrawerHelpers.nextStep();
    }
  }, [bottomDrawerHelpers, ctaTracking, onNextStepClick, trackUx]);

  const handlePreviousStepClick = useCallback(() => {
    trackUx(BUTTON_CLICKED, CLICK, {
      button_text: 'Back',
      ...ctaTracking,
    });
    if (onPreviousStepClick) {
      onPreviousStepClick();
    } else {
      bottomDrawerHelpers.previousStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bottomDrawerHelpers.previousStep, onPreviousStepClick]);

  const backButtonTransition = useTransition(previousStepButtonText, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.default,
  });

  return (
    <Box className={`${cxEl('ActionsBar')} NavActionsBar`} spacebetween vcenter>
      <div>
        {backButtonTransition(
          (anim, visible) =>
            visible && (
              <animated.div style={anim}>
                <Button
                  variant="tertiary"
                  startIcon="ChevronLeft"
                  onClick={handlePreviousStepClick}
                >
                  {previousStepButtonText}
                </Button>
              </animated.div>
            )
        )}
      </div>
      <div>
        {closeButtonText && (
          <Box inline mr={nextStepButtonText ? 12 : 0}>
            <Button variant="secondaryDestructive" onClick={handleCloseClick}>
              {closeButtonText}
            </Button>
          </Box>
        )}
        {nextStepButtonText && (
          <Button
            dataTestId="bottom-drawer-continue-button"
            variant="primary"
            onClick={handleNextStepClick}
          >
            {nextStepButtonText}
          </Button>
        )}
      </div>
    </Box>
  );
};

export const bottomDrawerHelpersShape = {
  currentStep: PropTypes.number.isRequired,
  closeBottomDrawer: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  setOnNextStepClick: PropTypes.func.isRequired,
  setOnPreviousStepClick: PropTypes.func.isRequired,
  setNextStepButtonText: PropTypes.func.isRequired,
  setPreviousStepButtonText: PropTypes.func.isRequired,
  setCloseButtonText: PropTypes.func.isRequired,
};

NavActionsBar.propTypes = {
  bottomDrawerHelpers: PropTypes.shape(bottomDrawerHelpersShape).isRequired,
  nextStepButtonText: PropTypes.string,
  previousStepButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  onNextStepClick: PropTypes.func,
  onPreviousStepClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  ctaTracking: PropTypes.object,
};

NavActionsBar.defaultProps = {
  nextStepButtonText: '',
  previousStepButtonText: '',
  closeButtonText: '',
  onNextStepClick: undefined,
  onPreviousStepClick: undefined,
  onCancelClick: undefined,
  ctaTracking: {},
};

export default NavActionsBar;
