export const DASHBOARD_ROUTES = ['/dashboard', '/profile'];

export const SCHEDULE_ROUTES = [
  '/schedule_builder',
  '/departments',
  '/availabilities',
  '/user/availability',
];

export const HIRING_ROUTES = ['/hiring'];

export const TIMESHEETS_ROUTES = ['/timesheets', '/web_timeclock'];

export const SETTINGS_ROUTES = ['/settings'];

export const DOCUMENTS_ROUTES = ['/user_documents'];

export const REPORTS_ROUTES = ['/reports'];

export const SAFETY_ROUTES = ['/timeclock/health_answers'];

export const TEAM_ROUTES = [
  '/team',
  '/time_offs',
  '/documents',
  '/new_hire_onboarding',
];

export const PAYROLL_ROUTES = ['/payroll'];

export const MORE_ROUTES = ['/safety', '/manager_log'];

export const SUB_NAV_ROUTES = [
  ...DASHBOARD_ROUTES,
  ...SCHEDULE_ROUTES,
  ...TIMESHEETS_ROUTES,
  ...SETTINGS_ROUTES,
  ...REPORTS_ROUTES,
  ...TEAM_ROUTES,
  ...HIRING_ROUTES,
  ...PAYROLL_ROUTES,
];

export const NAV_STATES = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded',
};
