import Icon from 'react-icon-base';

const HrCenterOutlined = props => (
  <Icon
    viewBox="0 0 23 22"
    fill="transparent"
    style={{ color: props.color || '#605F56' }}
    {...props}
  >
    <path
      d="M10.5 5.98352C9.79688 5.98352 9.25 5.43665 9.25 4.73352V2.23352C9.25 1.56946 9.79688 0.983521 10.5 0.983521H13C13.6641 0.983521 14.25 1.56946 14.25 2.23352V4.73352C14.25 5.43665 13.6641 5.98352 13 5.98352H10.5ZM11.75 12.8585C10.3438 12.8585 9.25 11.7648 9.25 10.3585C9.25 8.99133 10.3438 7.85852 11.75 7.85852C13.1172 7.85852 14.25 8.99133 14.25 10.3585C14.25 11.7648 13.1172 12.8585 11.75 12.8585ZM20.5 3.48352C21.8672 3.48352 23 4.61633 23 5.98352V18.4835C23 19.8898 21.8672 20.9835 20.5 20.9835H3C1.59375 20.9835 0.5 19.8898 0.5 18.4835V5.98352C0.5 4.61633 1.59375 3.48352 3 3.48352H8V5.35852H3C2.64844 5.35852 2.375 5.67102 2.375 5.98352V18.4835C2.375 18.8351 2.64844 19.1085 3 19.1085H20.5C20.8125 19.1085 21.125 18.8351 21.125 18.4835V5.98352C21.125 5.67102 20.8125 5.35852 20.5 5.35852H15.5V3.48352H20.5ZM8 17.8585C7.64844 17.8585 7.375 17.5851 7.375 17.2335C7.375 15.5148 8.74219 14.1085 10.5 14.1085H13C14.7188 14.1085 16.125 15.5148 16.125 17.2335C16.125 17.5851 15.8125 17.8585 15.5 17.8585H8Z"
      fill="currentColor"
    />
  </Icon>
);

export default HrCenterOutlined;
