import { Map } from 'immutable';
import { omit, startsWith, toNumber } from 'lodash';

import { df, moment } from 'util/dateTime';
import * as formsUtil from 'util/forms';
import { isValidEmail } from 'util/validators';

import { buildRouteWithParams } from '../../util/router';

import * as constants from './constants';

const validateEmail = isValidEmail();

export const manageApplicantFilterKey = key => `hiring-dashboard-filter-${key}`;

export const removeManageApplicantFilter = key =>
  window.localStorage.removeItem(`hiring-dashboard-filter-${key}`);

export const saveManageApplicantFilter = (key, value) =>
  window.localStorage.setItem(`hiring-dashboard-filter-${key}`, value);

export const getManageApplicantFilter = key =>
  window.localStorage.getItem(`hiring-dashboard-filter-${key}`);

export const buildHiringManageApplicantsRoute = () => {
  const ownerType =
    window.localStorage.getItem('hiring-dashboard-filter-ownerType') ||
    'locations';
  const ownerId = window.localStorage.getItem(
    'hiring-dashboard-filter-ownerId'
  );

  return buildRouteWithParams(
    `/hiring/${ownerType}/${ownerId}/manage_applicants`,
    {
      status:
        window.localStorage.getItem('hiring-dashboard-filter-status') ||
        'active',
      distance: window.localStorage.getItem('hiring-dashboard-filter-distance'),
      quality: window.localStorage.getItem('hiring-dashboard-filter-quality'),
      source: window.localStorage.getItem('hiring-dashboard-filter-source'),
      weekAvailabilities: window.localStorage.getItem(
        'hiring-dashboard-filter-weekAvailabilities'
      ),
      sort: window.localStorage.getItem('hiring-dashboard-filter-sort'),
    }
  );
};

export const buildCompanyProfileFormData = (companyProfile, locationProfiles) =>
  constants.INITIAL_COMPANY_PROFILE_FORM_STATE.get('fields').map(
    (_, fieldKey) => {
      if (fieldKey === 'location_profiles') {
        return locationProfiles.map(locationProfile =>
          constants.LOCATION_PROFILE_FIELDS.reduce(
            (fields, locationProfileField) =>
              fields.set(
                locationProfileField,
                formsUtil.buildInitialFieldState(
                  locationProfile.get(locationProfileField)
                )
              ),
            Map()
          )
        );
      }

      return formsUtil.buildInitialFieldState(companyProfile.get(fieldKey));
    }
  );

export const formatCompanyProfileFormForServer = fields => {
  const locationProfiles = fields.get('location_profiles');

  const locationProfilesAttributes = locationProfiles
    .map(locationProfile => ({
      id: locationProfile.getIn(['id', 'value']),
      title: locationProfile.getIn(['title', 'value']) || null,
    }))
    .valueSeq()
    .toJS();

  const locationsAttributes = locationProfiles
    .map(locationProfile => ({
      id: locationProfile.getIn(['location_id', 'value']),
      address_1: locationProfile.getIn(['address_1', 'value']),
      address_2: locationProfile.getIn(['address_2', 'value']),
      city: locationProfile.getIn(['city', 'value']),
      state: locationProfile.getIn(['state', 'value']),
      zip: locationProfile.getIn(['zip', 'value']),
    }))
    .valueSeq()
    .toJS();

  return {
    company_profile: {
      id: fields.getIn(['id', 'value']),
      logo: fields.getIn(['logo', 'value']),
      cover: fields.getIn(['cover', 'value']),
      description: fields.getIn(['description', 'value']),
      title: fields.getIn(['title', 'value']) || null,
      location_profiles: locationProfilesAttributes,
      locations: locationsAttributes,
    },
  };
};

export const formatApplicantProfileForServer = (fields, oldApplicant) => {
  const formattedFields = {};

  Object.keys(fields).forEach(key => {
    // We currently do not allow a user to delete a previously-saved resume.
    // In such a case, if a user edits a non-resume field and submits the form,
    // preserve the persisted resume by removing the blank `resume` key from the form.
    if (key === 'resume' && !fields[key]) {
      return;
    }

    // If user didn't add a new avatar, don't update the server value
    if (key === 'avatar' && !fields[key]) {
      return;
    }

    if (key === 'availabilities') {
      const availabilities = omit(fields[key].toJS(), 'owner_id', 'owner_type');
      formattedFields.week_availabilities_attributes = availabilities;
      return;
    }

    if (key === 'desired_rate') {
      formattedFields.desired_rate = toNumber(fields[key]);
    }

    if (key === 'show_work_stats') {
      if (!oldApplicant.has('applicant_user')) {
        return;
      }

      formattedFields.applicant_user_attributes = {
        show_work_stats: fields[key],
      };
    }

    if (key === 'experiences') {
      // Handle the deletion of any experiences the applicant deleted before
      // submitting the form. These are experiences which the `oldApplicant` had but
      // are not present in this form being submitted

      // Strip IDs that start with `__NEW__`, which we prepend to help keep track
      // of newly-created experiences

      const retainedExperienceIds = [];
      const deletedExperienceIds = [];

      let newExperiences = fields[key].map(experience => {
        if (startsWith(experience.get('id'), '__NEW__')) {
          experience = experience.remove('id');
        } else {
          retainedExperienceIds.push(experience.get('id'));
        }

        return experience;
      });

      oldApplicant.get('experiences').forEach(experience => {
        if (!retainedExperienceIds.includes(experience.get('id'))) {
          deletedExperienceIds.push(experience.get('id'));
        }
      });

      newExperiences = newExperiences.toJS().map(experience => {
        // Format dates for server
        ['start_date', 'end_date'].forEach(attrKey => {
          const rawDate = experience[attrKey];

          // Ensures Applicant Profile versions that predate start_/end_date fields
          // won't execute this logic. Length must be 7 like "MM/YYYY"
          if (rawDate && rawDate.length === 7) {
            experience[attrKey] = moment(rawDate, 'MM/YYYY').format(
              df('parsable_date')
            );
          }
        });

        return experience;
      });

      deletedExperienceIds.forEach(id =>
        newExperiences.push({ id, _destroy: '1' })
      );

      formattedFields.experiences_attributes = newExperiences;

      return;
    }

    // For all other blank fields, user wishes to remove the attribute.
    formattedFields[key] = fields[key] || null;
  });

  return formattedFields;
};

export const activeUnhiredApplicationsGreaterThan21Days =
  activeUnhiredApplications =>
    activeUnhiredApplications.filter(
      application =>
        moment().diff(moment(application.get('created_at')), 'days') > 21
    );

export const findStandardizedRoleById = (roles, id) =>
  roles.find(role => role.get('id') === id, this, Map());

export const findLocationById = (locations, id) =>
  locations.find(location => location.get('id') === id, this, Map());

export const findElementById = (elementMap, id) =>
  elementMap.find(element => element.get('id') === id, this, Map());

export const applicantEmailIsValid = email => {
  const notEmpty = email.length > 0;
  const validFormat = validateEmail(email) === undefined;
  return notEmpty && validFormat;
};

export const applicantPhoneIsValid = phone =>
  phone.replace(/\D/g, '').length === 10;

export const applicantZipIsValid = (zip, zipMask) => {
  if (zipMask === 'canadianZip') {
    const postalCodeRegex = new RegExp(
      /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i
    );
    return postalCodeRegex.test(zip);
  }
  return zip.replace(/\D/g, '').length === 5;
};
