// Interface for modifying URL state.

import { createBrowserHistory } from 'history';
import { omit, reduce } from 'lodash';
import queryString from 'query-string';

import { NEW_USER_ID } from 'features/employeeView/constants';

export const browserHistory = createBrowserHistory();

export const addNewUserRoute = () => `/team/${NEW_USER_ID}`;
export const importEmployeesRoute = type => `/team/import?type=${type}`;

export const addQuery = query => {
  const location = { ...browserHistory.location };
  const newQuery = { ...queryString.parse(location.search), ...query };
  location.search = queryString.stringify(newQuery);
  browserHistory.push(location);
};

export const removeQueries = queryKeysToRemove => {
  const location = { ...browserHistory.location };
  const query = queryString.parse(location.search);
  const newQuery = omit(query, ...queryKeysToRemove);
  location.search = queryString.stringify(newQuery);
  browserHistory.push(location);
};

export const updateQuery = (key, value) =>
  value || value === false ? addQuery({ [key]: value }) : removeQueries([key]);

export const updateUrlFilterParams = filterParams => {
  Object.keys(filterParams).forEach(key => updateQuery(key, filterParams[key]));
};

export const addNewUser = () => browserHistory.push(addNewUserRoute());

export const importEmployees = type =>
  browserHistory.push(importEmployeesRoute(type));

export const queryStringPresent = string =>
  window.location.search.includes(string);

export const parseQueryString = () => queryString.parse(window.location.search);

// any part of the route with a number will be replaced with a #
export const normalizeRoute = pathname =>
  pathname.replace(/\/[^/]*\d[^/]*/g, '/#');

export const buildRouteWithParams = (route, params = {}) =>
  reduce(
    params,
    (result, value, name) => result.replace(`:${name}`, value),
    route
  );
