import { snakeCase } from 'lodash';

import { isDateAfterNow } from 'util/dateTime';
import { pick } from 'util/objectMethods';
import {
  GTM_EVENT_ACTIONS_ACCEPT_LIST,
  GTM_EVENT_NAMES_ACCEPT_LIST,
  GTM_PROPERTY_ACCEPT_LIST,
} from 'util/trackingModules/constants';

const GTM_ACCEPT_LIST_MAP = GTM_EVENT_NAMES_ACCEPT_LIST.reduce(
  (result, name) => Object.assign(result, { [name]: true }),
  {}
);

const GTM_EVENT_ACTIONS_MAP = GTM_EVENT_ACTIONS_ACCEPT_LIST.reduce(
  (result, name) => Object.assign(result, { [name]: true }),
  {}
);

function sendEvent({ event_name, event_action, properties, ...other }) {
  if (
    !window.dataLayer ||
    !GTM_ACCEPT_LIST_MAP[event_name] ||
    !GTM_EVENT_ACTIONS_MAP[event_action]
  )
    return;

  const data = {
    event: snakeCase(event_action),
    timestamp: new Date().toUTCString(),
    on_trial: isDateAfterNow(other?.location_trial_end_date),
    ...other,
    ...properties,
  };

  window.dataLayer.push(pick(data, GTM_PROPERTY_ACCEPT_LIST));
}

export default {
  sendEvent,
};
