import React, {
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
} from 'react';
import { ThemeProvider } from '@mui/material/styles';

import designSystemTheme from './theme';

const withDesignSystemTheme = (
  WrappedComponent: FC<any> | ForwardRefExoticComponent<any>
) =>
  forwardRef((props: any, ref: ForwardedRef<any>) => (
    <ThemeProvider theme={designSystemTheme}>
      <WrappedComponent ref={ref} {...props} />
    </ThemeProvider>
  ));

export default withDesignSystemTheme;
