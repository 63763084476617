import { createAsyncThunk } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON } from 'api/fetch';
import { List } from 'immutable';

import { showModal } from 'actions/modals';

import {
  employeeFormSelectors,
  getIsEmployeeLoaded,
  getIsEmployeeLoading,
  getIsEmployeeTabLoaded,
  getIsEmployeeTabLoading,
  terminationFormSelectors,
} from 'selectors/employeeView';
import * as entitiesSelectors from 'selectors/entities';
import * as sessionSelectors from 'selectors/session';
import * as teamViewSelectors from 'selectors/teamView';

import * as constants from 'features/employeeView/constants';

import * as formsUtil from 'util/forms';
import { toI18n } from 'util/i18n';
import * as reduxUtil from 'util/redux';
import { browserHistory } from 'util/router';

// # ACTION TYPES
const employeeFormActionTypes = formsUtil.buildFormActionTypes(
  constants.EMPLOYEE_FORM_NAME
);
const terminationFormActionTypes = formsUtil.buildFormActionTypes(
  constants.TERMINATION_FORM_NAME
);

const ADD_MATCHING_USER = formsUtil.buildFormActionType(
  constants.EMPLOYEE_FORM_NAME,
  ['ADD_MATCHING_USER']
);
const REMOVE_MATCHING_USER = formsUtil.buildFormActionType(
  constants.EMPLOYEE_FORM_NAME,
  ['REMOVE_MATCHING_USER']
);

const {
  REQUEST: EMPLOYEE_FORM_REQUEST,
  SUCCESS: EMPLOYEE_FORM_SUCCESS,
  FAILURE: EMPLOYEE_FORM_FAILURE,
} = reduxUtil.createAsyncActionTypes(constants.EMPLOYEE_FORM_NAME);
const {
  REQUEST: TERMINATION_FORM_REQUEST,
  SUCCESS: TERMINATION_FORM_SUCCESS,
  FAILURE: TERMINATION_FORM_FAILURE,
} = reduxUtil.createAsyncActionTypes(constants.TERMINATION_FORM_NAME);

export const actionTypes = {
  UPDATE_SEARCH: 'EMPLOYEE/UPDATE_SEARCH',
  ADD_MATCHING_USER,
  REMOVE_MATCHING_USER,
  REMOVE_USER: 'EMPLOYEE/REMOVE_USER',
  INITIALIZE_EMPLOYEE_FORM: employeeFormActionTypes.INITIALIZE,
  UPDATE_EMPLOYEE_FORM_FIELD: employeeFormActionTypes.UPDATE_FIELD,
  ADD_EMPLOYEE_FORM_FIELD: employeeFormActionTypes.ADD_FIELD,
  REMOVE_EMPLOYEE_FORM_FIELD: employeeFormActionTypes.REMOVE_FIELD,
  REMOVE_EMPLOYEE_FORM_SERVER_ERROR:
    employeeFormActionTypes.REMOVE_SERVER_ERROR,
  EMPLOYEE_FORM_REQUEST,
  EMPLOYEE_FORM_SUCCESS,
  EMPLOYEE_FORM_FAILURE,
  INITIALIZE_TERMINATION_FORM: terminationFormActionTypes.INITIALIZE,
  UPDATE_TERMINATION_FORM_FIELD: terminationFormActionTypes.UPDATE_FIELD,
  TERMINATION_FORM_REQUEST,
  TERMINATION_FORM_SUCCESS,

  TERMINATION_FORM_FAILURE,
  ADD_LAST_HIRE_DATE: 'EMPLOYEE/ADD_LAST_HIRE_DATE',
  UNARCHIVE_JOB_REQUEST: 'EMPLOYEE/UNARCHIVE_JOB_REQUEST',
  UNARCHIVE_JOB_SUCCESS: 'EMPLOYEE/UNARCHIVE_JOB_SUCCESS',
  UNARCHIVE_JOB_FAILURE: 'EMPLOYEE/UNARCHIVE_JOB_FAILURE',
  CREATE_JOB_NOTE_REQUEST: 'EMPLOYEE/CREATE_JOB_NOTE_REQUEST',
  CREATE_JOB_NOTE_SUCCESS: 'EMPLOYEE/CREATE_JOB_NOTE_SUCCESS',
  CREATE_JOB_NOTE_FAILURE: 'EMPLOYEE/CREATE_JOB_NOTE_FAILURE',
  DELETE_MANAGER_NOTE_REQUEST: 'EMPLOYEE/DELETE_MANAGER_NOTE_REQUEST',
  DELETE_MANAGER_NOTE_SUCCESS: 'EMPLOYEE/DELETE_MANAGER_NOTE_SUCCESS',
  DELETE_MANAGER_NOTE_FAILURE: 'EMPLOYEE/DELETE_MANAGER_NOTE_FAILURE',
  UPDATE_NEW_JOB_NOTE_BODY: 'EMPLOYEE/UPDATE_NEW_JOB_NOTE_BODY',
  DELETE_HISTORICAL_WAGES_FOR_JOB_REQUEST:
    'EMPLOYEE/DELETE_HISTORICAL_WAGES_FOR_JOB_REQUEST',
  DELETE_HISTORICAL_WAGES_FOR_JOB_SUCCESS:
    'EMPLOYEE/DELETE_HISTORICAL_WAGES_FOR_JOB_SUCCESS',
  DELETE_HISTORICAL_WAGES_FOR_JOB_FAILURE:
    'EMPLOYEE/DELETE_HISTORICAL_WAGES_FOR_JOB_FAILURE',
  FETCH_EMPLOYEE_REQUEST: 'EMPLOYEE/FETCH_EMPLOYEE_REQUEST',
  FETCH_EMPLOYEE_SUCCESS: 'EMPLOYEE/FETCH_EMPLOYEE_SUCCESS',
  FETCH_EMPLOYEE_FAILURE: 'EMPLOYEE/FETCH_EMPLOYEE_FAILURE',
  FETCH_EMPLOYEE_TAB_REQUEST: 'EMPLOYEE/FETCH_EMPLOYEE_TAB_REQUEST',
  FETCH_EMPLOYEE_TAB_SUCCESS: 'EMPLOYEE/FETCH_EMPLOYEE_TAB_SUCCESS',
  FETCH_EMPLOYEE_TAB_FAILURE: 'EMPLOYEE/FETCH_EMPLOYEE_TAB_FAILURE',
  UPLOAD_DOCUMENT_REQUEST: 'EMPLOYEE/UPLOAD_DOCUMENT_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS: 'EMPLOYEE/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_FAILURE: 'EMPLOYEE/UPLOAD_DOCUMENT_FAILURE',
  SIGN_I9_REQUEST: 'EMPLOYEE/SIGN_I9_REQUEST',
  SIGN_I9_SUCCESS: 'EMPLOYEE/SIGN_I9_SUCCESS',
  SIGN_I9_FAILURE: 'EMPLOYEE/SIGN_I9_FAILURE',
  UPDATE_USER_INFO: 'EMPLOYEE/UPDATE_USER_INFO',
  UPDATE_DEFAULT_TAB: 'EMPLOYEE/UPDATE_DEFAULT_TAB',
};

// # ACTIONS
export const updateSearch = search => ({
  type: actionTypes.UPDATE_SEARCH,
  payload: { search },
});

export const updateUserInfo = payload => ({
  type: actionTypes.UPDATE_USER_INFO,
  payload,
});

export const deleteManagerNote = (noteId, userId, onDone) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncDeleteAction(routes.jobNoteRoute(noteId), [
      {
        type: actionTypes.DELETE_MANAGER_NOTE_REQUEST,
        meta: { userId, noteId },
      },
      {
        type: actionTypes.DELETE_MANAGER_NOTE_SUCCESS,
        meta: { userId, noteId },
      },
      {
        type: actionTypes.DELETE_MANAGER_NOTE_FAILURE,
        meta: { userId, noteId },
      },
    ]),
    {
      onDone,
      success: toI18n('employee_edit.delete_note_success'),
      error: toI18n('employee_edit.delete_note_error'),
    }
  );

export const addMatchingUser = matchingUser => ({
  type: ADD_MATCHING_USER,
  payload: { matchingUser },
});

export const showEffectiveDate = fieldName => ({
  type: actionTypes.UPDATE_EMPLOYEE_FORM_FIELD,
  payload: { fieldName, fieldData: { value: true, errors: List() } },
});

export const removeMatchingUser = () => ({ type: REMOVE_MATCHING_USER });

export const unarchiveJob = (jobId, userId, oldProfile = true) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(
      routes.employeeUnarchiveRoute(jobId),
      [
        { type: actionTypes.UNARCHIVE_JOB_REQUEST, meta: { userId } },
        {
          type: actionTypes.UNARCHIVE_JOB_SUCCESS,
          meta: { userId, oldProfile },
        },
        { type: actionTypes.UNARCHIVE_JOB_FAILURE, meta: { userId } },
      ],
      { body: { old_profile: oldProfile } }
    ),
    {
      success: toI18n('employee_edit.unarchive_success'),
      error: toI18n('employee_edit.unarchive_error'),
    }
  );

export const createJobNote = (jobId, body, onSuccess, onError) => {
  const noteId = constants.NEW_NOTE_ID;

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.jobNotesRoute(),
      [
        { type: actionTypes.CREATE_JOB_NOTE_REQUEST, meta: { jobId, noteId } },
        { type: actionTypes.CREATE_JOB_NOTE_SUCCESS, meta: { jobId, noteId } },
        { type: actionTypes.CREATE_JOB_NOTE_FAILURE, meta: { jobId, noteId } },
      ],
      { body: { job_note: { job_id: jobId, body } } }
    ),
    {
      onSuccess,
      onError,
      success: toI18n('employee_edit.create_note_success'),
      error: toI18n('employee_edit.create_note_error'),
    }
  );
};

export const updateNewJobNoteBody = body => ({
  type: actionTypes.UPDATE_NEW_JOB_NOTE_BODY,
  payload: { body },
});

export const showTerminateEmployeeModal = (user, modalType) =>
  showModal(modalType, { deprecatedModal: true, user });
export const showPayAnywhereWarningModal = user =>
  showModal(constants.PAY_ANYWHERE_WARNING_MODAL_TYPE, {
    deprecatedModal: true,
    user,
  });

export const updateEmployee = (userId, userData, opts = {}) => {
  const {
    onSuccess,
    onError,
    noErrorFlash = false,
    combineWithUserId,
    archiveRecord = null,
  } = opts;

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncPatchAction(
      routes.employeeRoute(userId),
      [
        { type: EMPLOYEE_FORM_REQUEST, meta: { combineWithUserId } },
        { type: EMPLOYEE_FORM_SUCCESS, meta: { combineWithUserId } },
        { type: EMPLOYEE_FORM_FAILURE, meta: { combineWithUserId } },
      ],
      {
        body: {
          user: userData,
          archive_record: archiveRecord,
        },
      }
    ),
    {
      success: toI18n('employee_edit.update_employee_success'),
      error: toI18n('employee_edit.update_employee_error'),
      noErrorFlash,
      onError,
      onSuccess,
    }
  );
};

export const initializeEmployeeForm = userId => (dispatch, getState) => {
  const formData = employeeFormSelectors.getInitialData(getState(), { userId });
  dispatch(formsUtil.initializeForm(constants.EMPLOYEE_FORM_NAME)(formData));
};

export const fetchEmployeeTabData = (userId, tab, { force, reset } = {}) =>
  reduxUtil.createAsyncGetAction(
    // eg.: /employees/:user_id/personal_information_tab
    `${routes.employeeRoute(userId)}/${tab}_tab`,
    [
      {
        type: actionTypes.FETCH_EMPLOYEE_TAB_REQUEST,
        meta: { userId, tab },
      },
      {
        type: actionTypes.FETCH_EMPLOYEE_TAB_SUCCESS,
        meta: { userId, tab, reset },
      },
      {
        type: actionTypes.FETCH_EMPLOYEE_TAB_FAILURE,
        meta: { userId, tab },
      },
    ],
    {
      bailout: state =>
        !force &&
        (getIsEmployeeTabLoading(state, { id: userId, tab }) ||
          getIsEmployeeTabLoaded(state, { id: userId, tab })),
    }
  );

export const fetchEmployeeData = (userId, forceLoad) =>
  reduxUtil.createAsyncGetAction(
    routes.employeeRoute(userId),
    [
      { type: actionTypes.FETCH_EMPLOYEE_REQUEST, meta: { userId } },
      { type: actionTypes.FETCH_EMPLOYEE_SUCCESS, meta: { userId } },
      { type: actionTypes.FETCH_EMPLOYEE_FAILURE, meta: { userId } },
    ],
    {
      bailout: state =>
        !forceLoad &&
        (getIsEmployeeLoading(state, { id: userId }) ||
          getIsEmployeeLoaded(state, { id: userId })),
    }
  );

export const fetchEmployee = (userId, forceLoad) => async dispatch => {
  if (userId === 'new') {
    return dispatch(initializeEmployeeForm(userId));
  }

  await dispatch(fetchEmployeeData(userId, forceLoad));

  return dispatch(initializeEmployeeForm(userId));
};

export const submitTerminationForm =
  (user, opts = {}) =>
  async (dispatch, getState) => {
    const {
      notes,
      reason,
      archived_at,
      onError,
      onSuccess,
      noRedirection,
      eligible_for_rehire,
    } = opts;
    const state = getState();
    const { getFieldValue } = terminationFormSelectors;
    const isOwner = sessionSelectors.getCurrentUserIsOwner(state);
    const jobId = opts.jobId || getFieldValue(state, { fieldName: 'job_id' });

    const allLocations =
      isOwner ||
      jobId === constants.TERMINATION_FORM_ALL_LOCATIONS_OPTION_DATA.value;
    const userId = user.get('id');
    const resourceId = allLocations ? userId : jobId;
    const locationId = allLocations
      ? null
      : entitiesSelectors
          .getJobs(state)
          .get(jobId.toString())
          .get('location_id');
    const route = allLocations
      ? routes.employeeArchiveAllRoute
      : routes.employeeArchiveRoute;

    const response = await dispatch(
      reduxUtil.withAlerts(
        reduxUtil.createAsyncPutAction(
          route(resourceId),
          [
            {
              type: TERMINATION_FORM_REQUEST,
              meta: { allLocations, resourceId, userId },
            },
            {
              type: TERMINATION_FORM_SUCCESS,
              meta: {
                allLocations,
                resourceId,
                userId,
                locationId,
              },
            },
            {
              type: TERMINATION_FORM_FAILURE,
              meta: { allLocations, resourceId, userId },
            },
          ],
          {
            body: {
              archive_record: {
                reason: reason || getFieldValue(state, { fieldName: 'reason' }),
                notes: notes || getFieldValue(state, { fieldName: 'notes' }),
                archived_at:
                  archived_at ||
                  getFieldValue(state, { fieldName: 'archived_at' }),
                eligible_for_rehire:
                  eligible_for_rehire === true || eligible_for_rehire === false
                    ? eligible_for_rehire
                    : getFieldValue(state, { fieldName: 'rehire' }),
              },
            },
          }
        ),
        {
          onError,
          onSuccess,
          success: toI18n('employee_edit.terminate_employee_success'),
          error: toI18n('employee_edit.terminate_employee_error'),
        }
      )
    );

    if (response.type === TERMINATION_FORM_SUCCESS && !noRedirection) {
      browserHistory.push(`/team${teamViewSelectors.getQueryParams(state)}`);
    }

    return response;
  };

export const initializeTerminationForm = user =>
  formsUtil.initializeForm(constants.TERMINATION_FORM_NAME)({
    job_id: user.get('jobs').size === 1 ? user.getIn(['jobs', 0, 'id']) : '',
  });

export const fetchLastHireDate = createAsyncThunk(
  actionTypes.ADD_LAST_HIRE_DATE,
  ({ id }) => fetchJSON(routes.employeeLastHireDateRoute(id))
);

export const terminateEmployee = user => dispatch => {
  const modalType = constants.OLD_TERMINATE_EMPLOYEE_MODAL_TYPE;
  dispatch(initializeTerminationForm(user));
  dispatch(showTerminateEmployeeModal(user, modalType));
  dispatch(fetchLastHireDate({ id: user.get('id') }));
};

export const uploadDocument = (
  userId,
  documentData,
  onSuccess,
  employeeProfile
) => {
  if (employeeProfile) {
    documentData.employee_profile = true;
  }

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncUploadAction(
      routes.userDocumentsRoute(userId),
      [
        { type: actionTypes.UPLOAD_DOCUMENT_REQUEST },
        {
          type: actionTypes.UPLOAD_DOCUMENT_SUCCESS,
          meta: { employeeProfile },
        },
        { type: actionTypes.UPLOAD_DOCUMENT_FAILURE },
      ],
      {
        body: documentData,
      }
    ),
    {
      success: toI18n('employee_documents.add_document_modal.success'),
      onSuccess,
    }
  );
};

export const signI9Verification = (payload, onSuccess) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.signI9VerificationRoute(),
      [
        actionTypes.SIGN_I9_REQUEST,
        actionTypes.SIGN_I9_SUCCESS,
        actionTypes.SIGN_I9_FAILURE,
      ],
      {
        body: {
          signature_request_id: payload.signatureRequestId,
          data: payload.data,
        },
      }
    ),
    {
      success: toI18n('employee_documents.verification.complete'),
      onSuccess,
    }
  );

export const updateDefaultEmployeeProfileTab = tabName => ({
  type: actionTypes.UPDATE_DEFAULT_TAB,
  payload: { tabName },
});
