import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgInfo = ({
  alt = 'Info Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0Zm0 6a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm1.875 12h-3.75a1.125 1.125 0 0 1 0-2.25h.75v-3H10.5a1.125 1.125 0 0 1 0-2.25H12c.621 0 1.125.504 1.125 1.125v4.125h.75a1.125 1.125 0 0 1 0 2.25Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgInfo;
