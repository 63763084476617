import React from 'react';
import Icon from 'react-icon-base';

const XMark = props => (
  <Icon {...props}>
    <path d="m1 1 8 8M9.001 1l-8 8" stroke="#969BA0" />
  </Icon>
);

export default XMark;
