import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Tooltip from 'fe-design-base/molecules/Tooltip';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import ChipGroup from './ChipGroup';

const ChipGroupField = ({
  name,
  options,
  label,
  helperText,
  hasAsterisk,
  infoTooltip,
}) => {
  const { errors, setFieldValue, setFieldTouched, touched, values } =
    useFormikContext();

  const handleOnClick = useCallback(
    async target => {
      await setFieldValue(name, target.value);
      setFieldTouched(name);
    },
    [name, setFieldValue, setFieldTouched]
  );

  const hasError = touched?.[name] && errors?.[name];

  return (
    <Box>
      {label && (
        <Box mb={8} row vcenter>
          <Text
            color="mono900"
            variant="bodySmBold"
            className={hasAsterisk ? 'required' : undefined}
          >
            {label}
          </Text>
          {infoTooltip && (
            <Tooltip content={infoTooltip} placement="top-start">
              <Icon ml={4} size="xsmall" iconName="Info" color="mono700" />
            </Tooltip>
          )}
        </Box>
      )}
      <ChipGroup
        chipOptions={options}
        selectedValue={values?.[name]}
        onClick={handleOnClick}
      />
      {hasError && (
        <Box mt={8}>
          <Text color="red300" variant="captionBold">
            {errors?.[name]}
          </Text>
        </Box>
      )}
      {!hasError && helperText && (
        <Box mt={8}>
          <Text color="mono700" variant="captions">
            {helperText}
          </Text>
        </Box>
      )}
    </Box>
  );
};

ChipGroupField.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string,
  hasAsterisk: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
};

export default ChipGroupField;
