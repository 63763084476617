import React from 'react';
import Icon from 'react-icon-base';

const FileExplorer = props => (
  <Icon viewBox="0 0 24 22" {...props}>
    <path
      d="M24 20H19.5V21.5H15V20H9V21.5H4.5V20H0V2C0 1.78906 0.0390625 1.59375 0.117188 1.41406C0.195312 1.23438 0.300781 1.07812 0.433594 0.945312C0.574219 0.804688 0.734375 0.695313 0.914062 0.617188C1.09375 0.539062 1.28906 0.5 1.5 0.5H7.125C7.47656 0.5 7.77734 0.539062 8.02734 0.617188C8.28516 0.6875 8.51172 0.777344 8.70703 0.886719C8.91016 0.996094 9.08984 1.11719 9.24609 1.25C9.40234 1.38281 9.55859 1.50391 9.71484 1.61328C9.87891 1.72266 10.0508 1.81641 10.2305 1.89453C10.418 1.96484 10.6328 2 10.875 2H22.5C22.7109 2 22.9062 2.03906 23.0859 2.11719C23.2656 2.19531 23.4219 2.30469 23.5547 2.44531C23.6953 2.57812 23.8047 2.73438 23.8828 2.91406C23.9609 3.09375 24 3.28906 24 3.5V20ZM1.5 2V3.5H7.125C7.3125 3.5 7.47656 3.48438 7.61719 3.45312C7.75781 3.41406 7.88672 3.36328 8.00391 3.30078C8.12109 3.23047 8.23438 3.15234 8.34375 3.06641C8.46094 2.97266 8.58594 2.86719 8.71875 2.75C8.58594 2.63281 8.46094 2.53125 8.34375 2.44531C8.23438 2.35156 8.12109 2.27344 8.00391 2.21094C7.88672 2.14062 7.75781 2.08984 7.61719 2.05859C7.47656 2.01953 7.3125 2 7.125 2H1.5ZM15 18.5V15.5H9V18.5H15ZM18 20V13.25C18 13.0469 17.9258 12.8711 17.7773 12.7227C17.6289 12.5742 17.4531 12.5 17.25 12.5H6.75C6.54688 12.5 6.37109 12.5742 6.22266 12.7227C6.07422 12.8711 6 13.0469 6 13.25V20H7.5V14H16.5V20H18ZM22.5 3.5H10.875C10.6328 3.5 10.418 3.53906 10.2305 3.61719C10.0508 3.6875 9.87891 3.77734 9.71484 3.88672C9.55859 3.99609 9.39844 4.11719 9.23438 4.25C9.07812 4.38281 8.90234 4.50391 8.70703 4.61328C8.51172 4.72266 8.28516 4.81641 8.02734 4.89453C7.77734 4.96484 7.47656 5 7.125 5H1.5V18.5H4.5V13.25C4.5 12.9375 4.55859 12.6445 4.67578 12.3711C4.79297 12.0977 4.95312 11.8594 5.15625 11.6562C5.35938 11.4531 5.59766 11.293 5.87109 11.1758C6.14453 11.0586 6.4375 11 6.75 11H17.25C17.5625 11 17.8555 11.0586 18.1289 11.1758C18.4023 11.293 18.6406 11.4531 18.8438 11.6562C19.0469 11.8594 19.207 12.0977 19.3242 12.3711C19.4414 12.6445 19.5 12.9375 19.5 13.25V18.5H22.5V3.5Z"
      fill="black"
    />
  </Icon>
);

export default FileExplorer;
