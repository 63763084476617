import React from 'react';
import Icon from 'react-icon-base';

const Restaurant = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path
        fill="#8A5EA6"
        d="M12 0C5.372 0 0 5.372 0 12c0 4.925 2.97 9.152 7.213 11.001l.157-3.029c0-2.41-1.781-1.638-1.781-3.324 0-.944.03-2.913.03-4.391 0-3.94 1.672-7.75 2.083-8.6.413-.85 1.385-1.776 1.396-.884 0 1.294.055 16.341.055 16.341H9.15l.184 4.578c.86.196 1.749.308 2.666.308.503 0 .995-.04 1.482-.1l.397-7.693c-.98-.214-1.815-.838-2.34-1.62-.648-.973.5-9.573.5-9.573h.748v8.071h.741v-8.07h.81v8.07h.74v-8.07h.841v8.07h.74v-8.07h.574s1.147 8.599.498 9.572c-.519.774-1.346 1.396-2.313 1.617-.017.005-.033.012-.033.012l.375 7.175c4.784-1.578 8.241-6.077 8.241-11.39C24 5.371 18.628 0 12.001 0z"
      />
    </g>
  </Icon>
);

export default Restaurant;
