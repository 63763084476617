import { createSlice } from '@reduxjs/toolkit';

import {
  MESSAGE_DRAWER_TABS,
  MESSAGING_V2_SLICE_NAME,
  READ_RECEIPTS_TABS,
  VIEWS,
} from './constants';
import { EXTRA_REDUCERS, REDUCERS } from './reducers';

const INITIAL_STATE = {
  currentChannelId: null,
  currentChatView: VIEWS.INDEX,
  currentTab: MESSAGE_DRAWER_TABS.TEAM,
  readReceiptTab: READ_RECEIPTS_TABS.READ,
  channels: {},
  afterTerminus: false,
  beforeTerminus: false,
  isFetchingChannels: false,
  messages: {},
  messagePagination: {},
  currentReadReceiptsMessageId: null,
  isCreatingChannel: false,
  isReadReceiptsDrawerOpen: false,
  availableMessageReactions: {},
  isFetchingMessages: {},
  isFetchingReadReceipts: {},
  hasUnreadMessages: null,
  isUpdatingChannel: {},
  isMarkingMessageAsRead: {},
  locationParticipants: {},
  locationQuickAccess: {},
  locationParticipantsPagination: {},
  isFetchingLocationParticipants: {},
  channelParticipants: {},
  channelParticipantsPagination: {},
  existingChannelParticipants: {},
  isFetchingChannelParticipants: {},
  isDeletingMessage: {},
  channelViewScrollPosition: 0,
  prefilledMessage: '',
  prefilledUserIds: null,
};

const slice = createSlice({
  name: MESSAGING_V2_SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: REDUCERS,
  extraReducers: EXTRA_REDUCERS,
});

const { actions, reducer } = slice;

export const {
  setChannels,
  setExistingChannelParticipants,
  setChannelViewScrollPosition,
  setMessages,
  setCurrentChatView,
  setMessageReadList,
  openReadReceiptsDrawer,
  closeReadReceiptsDrawer,
  addNewMessage,
  updateChannelWithIndex,
  updateMessage,
  setUnreadMessages,
  deleteChannel,
  updateChannel,
  setLocationParticipants,
  setChannelParticipants,
  replaceMessage,
  addChannel,
  clearPrefilledData,
  setCurrentTab,
  setCurrentReadReceiptTab,
} = actions;

export default reducer;
