import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSafetyOutline = ({
  alt = 'Safety Outline Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M21.333 2.7H2.667A2.666 2.666 0 0 0 0 5.367V18.7a2.667 2.667 0 0 0 2.667 2.667h18.666A2.666 2.666 0 0 0 24 18.7V5.367A2.668 2.668 0 0 0 21.333 2.7ZM4 19.367H2.667A.669.669 0 0 1 2 18.7V5.367C2 5 2.3 4.7 2.667 4.7H4v14.667Zm14 0H6V4.7h12v14.667Zm4-.667c0 .367-.3.667-.667.667H20V4.7h1.333c.367 0 .667.3.667.667V18.7ZM8.667 13.367h2v2c0 .366.3.666.666.666h1.334a.667.667 0 0 0 .666-.666v-2h2c.367 0 .667-.3.667-.667v-1.333c0-.367-.3-.667-.667-.667h-2v-2c0-.366-.3-.666-.666-.666h-1.334c-.366 0-.666.3-.666.666v2h-2C8.3 10.7 8 11 8 11.367V12.7c0 .367.3.667.667.667Z"
    />
  </svg>
);
export default SvgSafetyOutline;
