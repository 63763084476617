import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';

import { toI18n } from 'util/i18n';

// Actions
export const fetchPayrollRunSummary = createAsyncThunk(
  'payroll/fetchPayrollRunSummary',
  payrollRunId => fetchJSON(`/payroll/runs/${payrollRunId}/summary.json`)
);

export const fetchPayrollRunExportLastAttempt = createAsyncThunk(
  'payroll/fetchPayrollRunExportLastAttempt',
  ({ payrollRunId, integrationId }) =>
    fetchJSON(
      // eslint-disable-next-line max-len
      `/payroll/accounting_integrations/${payrollRunId}/last_attempt.json?integration_id=${integrationId}`
    )
);

export const exportPayrollRunExportToQuickBooks = createAsyncThunk(
  'payroll/exportPayrollRunExportToQuickBooks',
  (_, { getState }) => {
    const state = getState().get('payroll').payrollRunSummary;
    const payrollRunId = state.payrollRun.id;
    const integrationId = state.quickBooksExport.integrationId;

    return postJSON(
      `/payroll/accounting_integrations/${payrollRunId}/export.json`,
      {
        integration_id: integrationId,
      }
    );
  }
);

export const fetchPayrollRunIntegration = createAsyncThunk(
  'payroll/fetchPayrollRunIntegration',
  (payrollRunId, { dispatch }) => {
    const result = fetchJSON(
      `/payroll/accounting_integrations/${payrollRunId}`
    );
    result.then(response =>
      dispatch(
        fetchPayrollRunExportLastAttempt({
          payrollRunId,
          integrationId: response.integration_id,
        })
      )
    );
    return result;
  }
);

export const updatePaymentCheckNumber = createAsyncThunk(
  'payroll/updatePaymentCheckNumber',
  (
    { value, paymentId, paymentIndex, isContractor, paymentsTab = false },
    { getState, rejectWithValue }
  ) => {
    let currentPayments;
    let payment;

    if (paymentsTab) {
      currentPayments =
        getState().get('payroll').payrollRunSummary.payrollRun.totals
          .allPaymentsSortedByStatus;
      payment = currentPayments.find(
        pmt => pmt.is_contractor === isContractor && pmt.id === paymentId
      );
    } else {
      currentPayments =
        getState().get('payroll').payrollRunSummary.payrollRun.totals
          .manualDepositPayments;
      payment = currentPayments[paymentIndex];
    }

    // return if value doesn't change
    if (
      value === payment.paper_check_number ||
      (value === '' && payment.paper_check_number === null)
    ) {
      return;
    }

    const paymentType = payment.is_contractor ? 'contractors' : 'items';
    const url = `/payroll/payments/${paymentType}/${paymentId}`;

    return putJSON(url, { paper_check_number: value }).catch(err =>
      err.response.json().then(body =>
        rejectWithValue({
          ...body,
          oldValue: payment.paper_check_number,
        })
      )
    );
  }
);

export const bulkDownloadPaperChecks = createAsyncThunk(
  'payroll/bulkDownloadPaperChecks',
  ({ payrollRunId }, { rejectWithValue }) =>
    fetchJSON(`payroll/runs/${payrollRunId}/generate_paper_checks`).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

// Selectors
export const getFlashMessage = state =>
  state.get('payroll').payrollRunSummary.flash;

export const getPayrollRunSummary = state =>
  state.get('payroll').payrollRunSummary.payrollRun;

export const getPayrollRunIntegrationId = state =>
  state.get('payroll').payrollRunSummary.quickBooksExport.integrationId;

export const getPayrollRunExportStatus = state =>
  state.get('payroll').payrollRunSummary.quickBooksExport;

export const getIsFetchingPayrollRunSummary = state =>
  state.get('payroll').payrollRunSummary.isFetching;

export const getBulkPaperChecksStatus = state =>
  state.get('payroll').payrollRunSummary.bulkPaperChecksDownload;

const payrollRunSummarySlice = createSlice({
  name: 'payrollRunSummary',
  initialState: {
    payrollRun: {
      amountsBreakdowns: {},
    },
    quickBooksExport: {
      lastAttempt: {},
      isExporting: false,
      integrationId: null,
      exportSuccess: false,
    },
    isFetching: false,
    flash: {
      type: null,
      message: null,
    },
    bulkPaperChecksDownload: {
      isGenerating: false,
    },
    showPayrollJournalCoachmarks: false,
  },
  reducers: {
    setFlashBanner: (state, action) => {
      state.flash.type = action.payload.type;
      state.flash.message = action.payload.message;
    },
    resetFlashBanner: state => {
      state.flash.type = null;
      state.flash.message = null;
    },
    resetExportSuccess: state => {
      state.quickBooksExport.exportSuccess = false;
    },
    resetBulkPaperChecksIsGeneratingStatus: state => {
      state.bulkPaperChecksDownload.isGenerating = false;
    },
  },
  extraReducers: {
    [fetchPayrollRunSummary.pending]: state => {
      state.isFetching = true;
    },
    [fetchPayrollRunSummary.fulfilled]: (state, action) => {
      state.payrollRun = action.payload;
      state.isFetching = false;
      state.showPayrollJournalCoachmarks =
        action.payload.show_payroll_journal_coachmarks;
    },
    [fetchPayrollRunSummary.rejected]: state => {
      state.isFetching = false;
    },
    [fetchPayrollRunIntegration.pending]: state => {
      state.quickBooksExport.integrationId = null;
    },
    [fetchPayrollRunIntegration.fulfilled]: (state, action) => {
      state.quickBooksExport.integrationId = action.payload.integration_id;
    },
    [fetchPayrollRunIntegration.rejected]: state => {
      state.quickBooksExport.integrationId = null;
    },
    [fetchPayrollRunExportLastAttempt.pending]: state => {
      state.quickBooksExport.lastAttempt = {};
    },
    [fetchPayrollRunExportLastAttempt.fulfilled]: (state, action) => {
      state.quickBooksExport.lastAttempt = action.payload;
    },
    [fetchPayrollRunExportLastAttempt.rejected]: state => {
      state.quickBooksExport.lastAttempt = {};
    },
    [exportPayrollRunExportToQuickBooks.pending]: state => {
      state.quickBooksExport.lastAttempt = {};
      state.quickBooksExport.isExporting = true;
    },
    [exportPayrollRunExportToQuickBooks.fulfilled]: (state, action) => {
      state.quickBooksExport.isExporting = false;
      state.quickBooksExport.exportSuccess = true;
      state.quickBooksExport.lastAttempt = action.payload;
    },
    [exportPayrollRunExportToQuickBooks.rejected]: state => {
      state.quickBooksExport.lastAttempt = {};
      state.quickBooksExport.isExporting = false;
    },
    [updatePaymentCheckNumber.pending]: state => {
      state.flash.type = null;
      state.flash.message = null;
    },
    [updatePaymentCheckNumber.fulfilled]: (state, action) => {
      state.flash.type = 'success';
      state.flash.message = toI18n(
        'payroll.dashboard.payroll_run_summary.paychecks.check_number_updated'
      );
      if (action.meta.arg.paymentsTab) {
        state.payrollRun.totals.allPaymentsSortedByStatus.find(
          payment =>
            payment.is_contractor === action.meta.arg.isContractor &&
            payment.id === action.meta.arg.paymentId
        ).paper_check_number = action.meta.arg.value;
      } else {
        state.payrollRun.totals.manualDepositPayments[
          action.meta.arg.paymentIndex
        ].paper_check_number = action.meta.arg.value;
      }
    },
    [updatePaymentCheckNumber.rejected]: (state, action) => {
      state.flash.type = 'error';
      state.flash.message = action.payload.errors[0];
      if (action.meta.arg.paymentsTab) {
        state.payrollRun.totals.allPaymentsSortedByStatus.find(
          payment =>
            payment.is_contractor === action.meta.arg.isContractor &&
            payment.id === action.meta.arg.paymentId
        ).paper_check_number = action.payload.oldValue;
      } else {
        state.payrollRun.totals.manualDepositPayments[
          action.meta.arg.paymentIndex
        ].paper_check_number = action.payload.oldValue;
      }
    },
    [bulkDownloadPaperChecks.pending]: state => {
      state.bulkPaperChecksDownload.isGenerating = true;
      state.flash.type = 'success';
      state.flash.message = toI18n(
        'payroll.dashboard.payroll_run_summary.paychecks.print_all_checks.generating_message'
      );
    },
    [bulkDownloadPaperChecks.fulfilled]: state => {
      state.flash.type = 'success';
    },
    [bulkDownloadPaperChecks.rejected]: (state, action) => {
      state.bulkPaperChecksDownload.isGenerating = false;
      state.flash.type = 'error';
      state.flash.message = action.payload.error;
    },
  },
});

export const {
  setFlashBanner,
  resetFlashBanner,
  resetExportSuccess,
  resetBulkPaperChecksIsGeneratingStatus,
} = payrollRunSummarySlice.actions;
export const payrollRunSummaryReducer = payrollRunSummarySlice.reducer;
