import { isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import {
  createTask,
  createTaskList,
  deleteSingleTask,
  deleteTaskList,
  fetchTasks,
  updateSingleTask,
  updateTaskList,
} from 'features/taskManager/action';

import { ReducerBuilder } from './index.interface';

const createTaskReducer = (builder: ReducerBuilder) => {
  builder.addMatcher(isPending(createTask, createTaskList), state => ({
    ...state,
    isCreatingTask: true,
  }));
  builder.addMatcher(isRejected(createTask, createTaskList), state => ({
    ...state,
    isCreatingTask: false,
  }));
  builder.addMatcher(
    isFulfilled(createTask, createTaskList),
    (state, { payload: task }) => ({
      ...state,
      isCreatingTask: false,
      tasks: [task, ...state.tasks],
    })
  );
};

const fetchTasksReducer = (builder: ReducerBuilder) => {
  builder.addMatcher(fetchTasks.pending.match, state => ({
    ...state,
    isFetchingTasks: true,
  }));
  builder.addMatcher(fetchTasks.rejected.match, state => ({
    ...state,
    isFetchingTasks: false,
  }));
  builder.addMatcher(
    fetchTasks.fulfilled.match,
    (state, { payload: { tasks, page_number, ...others } }) => ({
      ...state,
      tasks: page_number === 1 ? tasks : [...state.tasks].concat(tasks),
      pagination: { ...others, page_number },
      isFetchingTasks: false,
    })
  );
};

const deleteSingleTaskReducer = (builder: ReducerBuilder) => {
  builder.addMatcher(
    isFulfilled(deleteSingleTask, deleteTaskList),
    (state, { payload: id }) => ({
      ...state,
      tasks: [...state.tasks].filter(task => task.id !== id),
    })
  );
};

const updateSingleTaskReducer = (builder: ReducerBuilder) => {
  builder.addMatcher(
    isFulfilled(updateSingleTask, updateTaskList),
    (state, { payload: updatedTask }) => ({
      ...state,
      tasks: [...state.tasks].map(task => {
        if (task.id === updatedTask.id) return { ...task, ...updatedTask };
        return task;
      }),
    })
  );
};

export const extraReducers = (builder: ReducerBuilder) => {
  createTaskReducer(builder);
  fetchTasksReducer(builder);
  deleteSingleTaskReducer(builder);
  updateSingleTaskReducer(builder);
};
