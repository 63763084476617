import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSchedule = ({
  alt = 'Schedule Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M4 2.633v1.333H2a2 2 0 0 0-2 2v2h18.667v-2a2 2 0 0 0-2-2h-2V2.633c0-.737-.596-1.333-1.334-1.333-.737 0-1.333.596-1.333 1.333v1.333H6.667V2.633c0-.737-.596-1.333-1.334-1.333C4.596 1.3 4 1.896 4 2.633ZM18.667 9.3H0v11.333a2 2 0 0 0 2 2h11.783A7.333 7.333 0 0 1 18.666 9.33V9.3ZM24 16.633c0-3.312-2.688-6-6-6-3.313 0-6 2.688-6 6a6 6 0 0 0 12 0ZM18 13.3c.367 0 .667.3.667.666v2H20c.367 0 .667.3.667.667 0 .367-.3.667-.667.667h-2a.669.669 0 0 1-.667-.667v-2.666c0-.367.3-.667.667-.667Z"
    />
  </svg>
);
export default SvgSchedule;
