import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';

import { actions } from 'features/biller';
import { actions as billerActions } from 'features/biller/slice';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUxHandler, withUxRoot } from 'util/uxEvents';

import { labelForPlanName } from '../../monetization/util';
import {
  selectCurrentLocationTier,
  selectDaysLeftBeforeDowngrade,
  selectLocationBiller,
  selectLocationId,
  selectPastDueDeadline,
} from '../selectors';
import { CurrentLocationTierType } from '../types';

export type PastDueSubscriptionViewProps = {
  biller: string | null;
  pastDueDeadline: boolean | string | null;
  currentLocationId: number | null;
  currentLocationTier: CurrentLocationTierType;
  daysLeftBeforeDowngrade: number | null;
  setIsBillingModalSourcePastDue: (flag: boolean) => void;
  onChangePaymentMethod: (args: {
    biller: string | null;
    locationId: number | null;
    subscriptionType: string;
  }) => void;
};

const { cx } = cxHelpers('PastDueSubscriptionView');

const DeadlineText = ({
  deadlineDate,
  currentPlanName,
}: {
  deadlineDate: boolean | string | null;
  currentPlanName: string | null;
}) => (
  <Text variant="body" color="mono900" asHTML>
    {toI18n('past_due_subscription.banner.content', {
      props: {
        deadline: deadlineDate,
        currentTierName: labelForPlanName(currentPlanName),
      },
    })}
  </Text>
);

const DaysLeftText = ({ daysLeft }: { daysLeft: number | null }) => (
  <Text variant="body" color="mono900">
    {toI18n('biller.banner.past_due.credit_card_expired_with_days_left', {
      props: { days_left: daysLeft },
    })}
  </Text>
);

const PastDueSubscriptionView = ({
  biller,
  pastDueDeadline,
  currentLocationId,
  currentLocationTier,
  onChangePaymentMethod,
  daysLeftBeforeDowngrade,
  setIsBillingModalSourcePastDue,
}: PastDueSubscriptionViewProps) => {
  const handleViewBannerTracking = useTrackUxHandler(
    EVENT_ACTIONS.BANNER_VIEWED,
    TRACK_ACTION_TYPES.VIEW
  );

  const currentPlanName = currentLocationTier?.get('name') || null;
  const primaryCtaText = toI18n('past_due_subscription.primary_cta');

  useEffect(() => {
    handleViewBannerTracking();
  }, [handleViewBannerTracking]);

  const handleChangePaymentMethod = useCallback(() => {
    if (pastDueDeadline) {
      setIsBillingModalSourcePastDue(true);
    }

    onChangePaymentMethod({
      biller,
      subscriptionType: 'team_app',
      locationId: currentLocationId,
    });
  }, [
    biller,
    pastDueDeadline,
    currentLocationId,
    onChangePaymentMethod,
    setIsBillingModalSourcePastDue,
  ]);

  return (
    <Box
      row
      pl={24}
      pv={16}
      pr={16}
      vcenter
      spacebetween
      bgcolor="red100"
      className={cx()}
    >
      <Box row gap={12} vcenter>
        <Icon color="red300" iconName="Critical" size="small" />
        {pastDueDeadline === null ? (
          <DaysLeftText daysLeft={daysLeftBeforeDowngrade} />
        ) : (
          <DeadlineText
            deadlineDate={pastDueDeadline}
            currentPlanName={currentPlanName}
          />
        )}
      </Box>

      <Button
        size="small"
        variant="secondary"
        uxElement={primaryCtaText}
        onClick={handleChangePaymentMethod}
      >
        {primaryCtaText}
      </Button>
    </Box>
  );
};

export default connect(
  state => ({
    biller: selectLocationBiller(state),
    currentLocationId: selectLocationId(state),
    pastDueDeadline: selectPastDueDeadline(state),
    currentLocationTier: selectCurrentLocationTier(state),
    daysLeftBeforeDowngrade: selectDaysLeftBeforeDowngrade(state),
  }),
  {
    onChangePaymentMethod: actions.changePaymentMethod,
    setIsBillingModalSourcePastDue:
      billerActions.setIsBillingModalSourcePastDue,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.BILLING,
    eventCategory: EVENT_CATEGORIES.PAST_DUE_SUBSCRIPTIONS_BANNER,
  })(PastDueSubscriptionView)
);
