import './FDBDatePicker.scss';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SyntheticInputEvent } from 'fe-components/types';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import TextInput, { TextInputProps } from 'fe-design-base/atoms/TextInput';
import IconButton from 'fe-design-base/molecules/IconButton';
import zIndex from 'fe-design-base/styles/zIndex';

import { cxHelpers } from 'util/className';
import {
  moment,
  momentDate,
  STANDARD_DATE_FORMAT,
  updateLocale,
} from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { isValidInputFormat, parseDate, validateDateFormat } from './helpers';

const { cx, cxEl } = cxHelpers('FDBDatePicker');

export interface FDBDatePickerProps
  extends Pick<
    TextInputProps,
    | 'name'
    | 'placeholder'
    | 'label'
    | 'readOnly'
    | 'disabled'
    | 'error'
    | 'value'
    | 'uxElement'
  > {
  blockFutureDates?: boolean;
  blockDatesBefore?: string; // any date format recognized as valid in moment
  blockDatesAfter?: string; // any date format recognized as valid in moment
  displayWeekday?: boolean;
  onChange: (event: SyntheticInputEvent) => void;
  startOfWorkWeek?: 0 | 1 | 2 | 3 | 4 | 5 | 6; // sun-sat
  value?: string | undefined; // must be YYYY-MM-DD format
  onBlur?: () => void;
  hideClearIcon?: boolean;
  overrideZIndex?: number; // use if the calendar popper isn't showing
  isActiveAdmin?: boolean;
  overrideTop?: 'initial' | number;
  onMonthChange?: (month: moment.Moment) => void;
  onYearChange?: (year: moment.Moment) => void;
  /**
   shouldDisableDates is less performant and should be last resort
   if blockFutureDates/blockDatesBefore/blockDatesAfter can't get the result you need
   https://mui.com/x/api/date-pickers/desktop-date-picker
   /#desktop-date-picker-prop-shouldDisableDate
  */
  shouldDisableDates?: (day: moment.Moment) => boolean;
}

const CustomLeftArrow = () => <Icon iconName="ChevronLeft" size="small" />;
const CustomRightArrow = () => <Icon iconName="ChevronRight" size="small" />;

const FDBDatePicker = ({
  blockFutureDates,
  blockDatesBefore,
  blockDatesAfter,
  readOnly,
  disabled,
  displayWeekday,
  error = false,
  hideClearIcon = false,
  overrideZIndex,
  isActiveAdmin,
  name,
  label,
  onChange,
  onBlur,
  placeholder = toI18n('fe_design_base.placeholder.date_picker'),
  startOfWorkWeek = 0,
  uxElement,
  value,
  overrideTop = 'initial',
  shouldDisableDates,
  onMonthChange,
  onYearChange,
}: FDBDatePickerProps) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(
    parseDate(value, displayWeekday)
  );
  const [errorState, setErrorState] = useState(error);
  const [isOpen, setIsOpen] = useState(false);
  const [touched, setTouched] = useState(false);

  const topPx = (() => {
    if (overrideTop === 'initial') return overrideTop;

    return `${overrideTop}px !important`;
  })();

  useEffect(() => {
    // handles the touched issue for Formik validation errors
    if ((!isOpen && touched) || !!errorState) onBlur?.();
  }, [errorState, touched, isOpen, onBlur]);

  // Required to shift first day of the week from sunday to mon/tues/wed...
  // in order to match custom start of week
  updateLocale('en', {
    week: {
      dow: startOfWorkWeek,
    },
  });

  useEffect(() => {
    if (!!value && !validateDateFormat(value)) setErrorState(true);
  }, [value]);

  const trackUx = useTrackUx({
    element: uxElement,
    field: label && typeof label === 'string' ? label : name,
  } as any);

  // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleInputChange = useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  const handleDateChange = useCallback(
    (newDate: moment.Moment | null) => {
      onChange({
        target: {
          name,
          value: newDate
            ? momentDate(newDate).format(STANDARD_DATE_FORMAT)
            : null,
        },
      });

      if (uxElement) {
        trackUx(
          EVENT_ACTIONS.DATE_SELECTION_CLICKED,
          TRACK_ACTION_TYPES.CLICK,
          {
            value: parseDate(
              momentDate(newDate).format(STANDARD_DATE_FORMAT),
              false
            ),
          }
        );
      }

      setInputValue('');
      setIsOpen(false);
    },
    [name, onChange, trackUx, uxElement]
  );

  const handleBlockDates = useCallback(
    (day: moment.Moment | null) => {
      if (!blockDatesBefore && !blockDatesAfter) {
        return false;
      }

      return (
        day &&
        ((blockDatesBefore &&
          day.isBefore(moment(blockDatesBefore).startOf('day'))) ||
          (blockDatesAfter &&
            day.isAfter(moment(blockDatesAfter).endOf('day'))))
      );
    },
    [blockDatesBefore, blockDatesAfter]
  );

  const processDateInput = useCallback(() => {
    const parsedInputDate = moment(inputValue);
    const validAndNoBlockedDates =
      !handleBlockDates(parsedInputDate) && isValidInputFormat(inputValue);

    if (validAndNoBlockedDates) handleDateChange(parsedInputDate);
    else setErrorState(true);
  }, [handleBlockDates, handleDateChange, inputValue]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    if (disabled || readOnly) return;

    if (uxElement) {
      trackUx(EVENT_ACTIONS.DATE_PICKER_CLICKED, TRACK_ACTION_TYPES.CLICK);
    }

    setIsOpen(true);
    setTouched(true);
  }, [disabled, readOnly, trackUx, uxElement]);

  const handleKeyDown = useCallback(
    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    event => {
      event.stopPropagation();
      if (disabled || readOnly) return;

      if (event.key === 'Enter') {
        return inputValue ? processDateInput() : handleClick();
      }
    },
    [disabled, handleClick, inputValue, processDateInput, readOnly]
  );

  const handleClear = useCallback(
    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e => {
      e.stopPropagation();
      setInputValue('');
      setErrorState(false);
      setIsOpen(false);
      // onBlur to trigger Formik touched for validation error handling
      onBlur?.();

      onChange({
        target: {
          name,
          value: '',
        },
      });

      if (inputRef.current) {
        // re-focus on the input after clear
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputRef.current.focus();
      }
    },
    [name, onBlur, onChange]
  );

  const showClearIcon =
    !hideClearIcon && (errorState || (value && !disabled && !readOnly));
  const shortWeekday = useCallback((day: string) => `${day}`, []);

  return (
    <Box className={cx({ error })}>
      <Box onClick={handleClick}>
        <TextInput
          ref={inputRef}
          dataTestId="FDBDatePicker"
          className={value || errorState || hideClearIcon ? 'hideCursor' : ''}
          name="DatePickerInput"
          startIcon={!readOnly ? 'Calendar' : null}
          endIcon={
            showClearIcon && (
              <IconButton
                icon="Clear"
                onClick={handleClear}
                dataTestId="FDBIconButton-close-icon"
              />
            )
          }
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
          error={error || errorState}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          value={
            errorState
              ? toI18n('fe_design_base.errors.field.date_invalid')
              : parseDate(value, displayWeekday) || inputValue
          }
          noTooltip
        />
      </Box>
      <LocalizationProvider
        dateLibInstance={moment}
        dateAdapter={AdapterMoment}
      >
        <DesktopDatePicker
          className={cxEl('hidden')}
          value={value ? moment(value) : null}
          open={isOpen}
          onClose={handleClose}
          onAccept={handleDateChange}
          disableFuture={blockFutureDates}
          minDate={blockDatesBefore ? moment(blockDatesBefore) : undefined}
          maxDate={blockDatesAfter ? moment(blockDatesAfter) : undefined}
          shouldDisableDate={shouldDisableDates}
          onMonthChange={onMonthChange}
          onYearChange={onYearChange}
          dayOfWeekFormatter={shortWeekday}
          yearsPerRow={3}
          slots={{
            leftArrowIcon: CustomLeftArrow,
            rightArrowIcon: CustomRightArrow,
          }}
          slotProps={{
            popper: {
              className: cx({ isActiveAdmin }),
              sx: {
                zIndex: overrideZIndex || zIndex.zindexFdbPopper,
                top: topPx,
              },
            },
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default FDBDatePicker;
