import './TextInput.scss';

import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask-old';
// Ref: https://github.com/andreypopp/react-textarea-autosize
import Textarea from 'react-textarea-autosize';
import PropTypes from 'prop-types';
import { colors, typography } from 'theme/constants';

import Icon, { ICON_TYPES } from 'components/Icon';

import cxHelpers from 'util/className';
import { sanitizeString } from 'util/formatter';
import { createKeyPressHandler } from 'util/keybindings';

const INPUT_SIZES = ['narrow', 'tall', 'fullwidth'];
const PHONE_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const MASKED_INPUT_PROPS = {
  phone: { mask: PHONE_MASK, type: 'tel' },
  tel: {
    mask: PHONE_MASK,
    type: 'phone',
  },
  zip: { mask: [/\d/, /\d/, /\d/, /\d/, /\d/] },
  ein: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    type: 'ein',
  },
  date: { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] },
  dateMY: { mask: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] },
  pin6: { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/] },
  pin4: { mask: [/\d/, /\d/, /\d/, /\d/] },
  time: { mask: [/\d/, /\d/, ':', /\d/, /\d/, ' ', /[AaPp]/, /[Mm]/] },
  ssn: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  },
};

const DEFAULT_TYPE = 'text';
const TEXT_AREA_TYPE = 'textarea';
const CURRENCY_TYPE = 'currency';
const PASSWORD_TYPE = 'password';
const NUMBER_TYPE = 'number';
const PERCENTAGE_TYPE = 'percent';
const VALID_TYPES = [
  DEFAULT_TYPE,
  TEXT_AREA_TYPE,
  CURRENCY_TYPE,
  PASSWORD_TYPE,
  NUMBER_TYPE,
  PERCENTAGE_TYPE,
  ...Object.keys(MASKED_INPUT_PROPS),
];

@cxHelpers('TextInput')
class TextInput extends PureComponent {
  static propTypes = {
    errorBorder: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    getInputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.oneOf(ICON_TYPES),
    inputProps: PropTypes.object,
    size: PropTypes.oneOf(INPUT_SIZES),
    border: PropTypes.bool,
    type: PropTypes.oneOf(VALID_TYPES),
    maxLength: PropTypes.number,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    search: PropTypes.bool,
    stretch: PropTypes.bool,
    focusOnMount: PropTypes.bool,
    readOnly: PropTypes.bool,
    resize: PropTypes.string,
    fontSize: PropTypes.string,
    textAlign: PropTypes.string,
    color: PropTypes.string,
    noRightBorderRadius: PropTypes.bool,
    hideCloseIcon: PropTypes.bool,
    showIconWithValue: PropTypes.bool,
    valueOnChange: PropTypes.bool,
  };

  static defaultProps = {
    inputProps: {},
    type: DEFAULT_TYPE,
    resize: 'auto',
    fontSize: typography.fs16,
    maxLength: 524288,
    color: colors.navyLight,
    border: true,
    onChange: () => {},
    noRightBorderRadius: false,
    decimalScale: 1,
    errorBorder: false,
  };

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.focus();
    }
  }

  handleBlur = evt => {
    if (!this.props.onBlur) {
      return;
    }

    // We are sending value and synthetic event to support FormikTextInput component
    this.props.onBlur(evt.target.value, evt);
  };

  handleFocus = ({ target: { value } }) => {
    if (!this.props.onFocus) {
      return;
    }
    this.props.onFocus(value);
  };

  handleClear = e => {
    e.preventDefault();

    if (this.props.onClear) {
      return this.props.onClear();
    }

    // We are simulating a "synthetic event" to support FormikTextInput component
    this.sendChangeEvent('');
    this.focus();
  };

  handleClickIcon = e => {
    e.preventDefault();
    this.focus();
  };

  sendChangeEvent = (value, evt) => {
    const sendEvt = evt || { target: { value, name: this.props.name } };
    if (this.props.valueOnChange) {
      // Older usages of TextInput expect value as the first argument, so
      // this is being retained for backwards compatiblity
      this.props.onChange(value, sendEvt);
    } else {
      // Any new usage of TextInput should expect to get event.  This allows
      // simpler integration with Formik and probably any other form building
      // framework.
      this.props.onChange(sendEvt);
    }
  };

  handleChange = evt => {
    // Sanitizing masked inputs will cause the update value to done asynchronous
    // Updating controlled field async will make the cursor jump to the end
    // Read more: https://github.com/facebook/react/issues/955
    if (!MASKED_INPUT_PROPS.hasOwnProperty(this.props.type)) {
      let {
        target: { value },
      } = evt;
      value = sanitizeString(value);
      evt.target.value = value;
    }

    if (this.props.valueOnChange) {
      // Older usages of TextInput expect value as the first argument, so
      // this is being retained for backwards compatiblity
      this.sendChangeEvent(evt.target.value, evt);
    } else {
      // Any new usage of TextInput should expect to get event.  This allows
      // simpler integration with Formik and probably any other form building
      // framework.
      this.sendChangeEvent(evt.target.value, evt);
    }
  };

  handleCurrencyChange = value => {
    value = sanitizeString(value.replace(/[^\d.]/g, ''));

    this.sendChangeEvent(value);
  };

  handleNumberFormatChange = values => {
    this.handleCurrencyChange(values.value);
  };

  defaultHandleKeyDown = createKeyPressHandler({
    Escape: e => this.handleClear(e),
  });

  handleKeyDown = e => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    } else {
      this.defaultHandleKeyDown(e);
    }
  };

  handleKeyUp = e => (this.props.onKeyUp ? this.props.onKeyUp(e) : e);

  focus() {
    const { input } = this;

    if (!input) {
      return;
    }
    if (input.focus) {
      return input.focus();
    }

    // Fallback for third-party components
    if (input.refs && input.refs.input && input.refs.input.focus) {
      return input.refs.input.focus();
    }
  }

  renderInput = (type, inputProps) => {
    if (type === DEFAULT_TYPE) {
      return <input type={DEFAULT_TYPE} {...inputProps} />;
    } else if (type === NUMBER_TYPE) {
      return <NumberFormat {...inputProps} />;
    } else if (type === PASSWORD_TYPE) {
      return <input type={PASSWORD_TYPE} {...inputProps} />;
    } else if (type === TEXT_AREA_TYPE) {
      // https://github.com/andreypopp/react-textarea-autosize#special-props
      inputProps.inputRef = this.props.getInputRef;
      delete inputProps.ref;

      return <Textarea {...inputProps} />;
    } else if (type === CURRENCY_TYPE) {
      return (
        <NumberFormat
          {...inputProps}
          onValueChange={this.handleNumberFormatChange}
          thousandSeparator
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          isNumericString
        />
      );
    } else if (type === PERCENTAGE_TYPE) {
      return (
        <NumberFormat
          {...inputProps}
          onValueChange={this.handleNumberFormatChange}
          suffix="%"
          decimalScale={inputProps.decimalScale || 1}
          fixedDecimalScale
          isNumericString
        />
      );
    }

    const { maxLength, ...maskedInputProps } = {
      ...inputProps,
      ...MASKED_INPUT_PROPS[type],
    };
    return <MaskedInput {...maskedInputProps} />;
  };

  hasValue = () => this.props.value === 0 || this.props.value;

  renderClearZone = () => (
    <a
      className={this.cxEl('clear-zone', {
        show: !this.props.hideCloseIcon && this.hasValue(),
      })}
      onClick={this.handleClear}
    >
      <span className={this.cxEl('clear')}>×</span>
    </a>
  );

  renderIconZone = () => (
    <a
      className={this.cxEl('icon-zone', {
        show:
          this.props.icon && (this.props.showIconWithValue || !this.hasValue()),
      })}
      onClick={this.handleClickIcon}
    >
      {this.props.icon ? (
        <Icon
          type={this.props.icon}
          className={this.cxEl('icon')}
          size="sm"
          color="green"
        />
      ) : null}
    </a>
  );

  render() {
    const {
      value,
      size,
      border,
      type,
      maxLength,
      disabled,
      inline,
      search,
      stretch,
      placeholder,
      name,
      resize,
      fontSize,
      textAlign,
      color,
      readOnly,
      noRightBorderRadius,
    } = this.props;
    const isTextarea = type === TEXT_AREA_TYPE;
    const inputCx = isTextarea ? 'textarea-input' : 'input';

    const inputProps = {
      onKeyDown: this.handleKeyDown,
      onKeyUp: this.handleKeyUp,
      className: this.cxEl(inputCx, { [size]: size, inline }),
      onBlur: this.handleBlur,
      onFocus: this.handleFocus,
      value: this.hasValue() ? value : '',
      maxLength,
      disabled,
      placeholder,
      name,
      title: this.props.title,
      'aria-label': this.props.ariaLabel || name,
      style: { resize, fontSize, color, textAlign },
      ref: this.props.getInputRef,
      readOnly,
      ...this.props.inputProps,
    };

    if (type !== CURRENCY_TYPE && type !== PERCENTAGE_TYPE) {
      // <NumberFormat> uses onValueChange, not onChange. Passing onChange
      // to it results in two change events being dispatched on each keypress
      inputProps.onChange = this.handleChange;
    }

    return (
      <div
        className={this.cx({
          [size]: size,
          unbordered: !border,
          textarea: type === TEXT_AREA_TYPE,
          search,
          stretch,
          'no-right-border-radius': noRightBorderRadius,
          errorborder: this.props.errorBorder,
        })}
      >
        {this.renderInput(type, inputProps)}
        {disabled || inline || isTextarea ? null : this.renderClearZone()}
        {inline || isTextarea ? null : this.renderIconZone()}
      </div>
    );
  }
}

export default TextInput;
