import { fromJS, Map, OrderedMap } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'actions/notifications';
import { actionTypes as teamActionTypes } from 'actions/teamView';

const INITIAL_STATE = Map({
  requestsItems: OrderedMap(),
  alertsItems: OrderedMap(),
  hasMoreRequests: true,
  hasMoreAlerts: true,
});

// Because some items have an id like 'id', and some like '_id'.
const getUniqueItemId = item =>
  `${item.get('type')}__${item.get('_id', item.get('id'))}`;

const items = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_NOTIFICATIONS_SUCCESS: {
      const { alertType } = action.meta;

      let requestsItems = state.get('requestsItems');
      let alertsItems =
        alertType === 'alerts' ? OrderedMap() : state.get('alertsItems');
      let hasMoreRequests = state.get('hasMoreRequests');
      let hasMoreAlerts = state.get('hasMoreAlerts');

      action.payload.forEach(item => {
        item = fromJS(item);
        const uniqueItemId = getUniqueItemId(item);
        item = item.set('unique_id', uniqueItemId);

        if (item.get('alert_type') === 'request') {
          requestsItems = requestsItems.set(uniqueItemId, item);
        } else {
          alertsItems = alertsItems.set(uniqueItemId, item);
        }
      });

      if (action.payload.length === 0) {
        if (alertType === 'requests') {
          hasMoreRequests = false;
        } else if (alertType === 'alerts') hasMoreAlerts = false;
      }

      return state.merge({
        requestsItems,
        alertsItems,
        hasMoreRequests,
        hasMoreAlerts,
      });
    }

    case actionTypes.READ_REQUEST_SUCCESS: {
      const newState = state.deleteIn([
        'requestsItems',
        action.meta.item.get('unique_id'),
      ]);
      return newState.deleteIn([
        'alertsItems',
        action.meta.item.get('unique_id'),
      ]);
    }

    case actionTypes.APPROVE_CLAIM_REQUEST: {
      const item = state
        .get('requestsItems')
        .find(
          request =>
            request.get('type') === 'open_shift' &&
            request.get('id') === action.payload.id
        );

      if (item) {
        return state.deleteIn(['requestsItems', item.get('unique_id')]);
      }

      return state;
    }

    case teamActionTypes.APPROVE_PENDING_JOB_SUCCESS:
    case teamActionTypes.DECLINE_PENDING_JOB_SUCCESS: {
      const item = state
        .get('requestsItems')
        .find(
          request =>
            request.get('id') === action.meta.jobId &&
            request.get('type') === 'pending_job'
        );

      if (item) {
        return state.deleteIn(['requestsItems', item.get('unique_id')]);
      }

      return state;
    }

    case actionTypes.DISMISS_SHIFT_TRADE_UPSELL:
    case actionTypes.MANAGER_APPROVE_TIMEOFF_REQUEST:
    case actionTypes.MANAGER_REJECT_TIMEOFF_REQUEST:
    case actionTypes.READ_REQUEST_REQUEST:
      return state.deleteIn([
        'requestsItems',
        action.meta.item.get('unique_id'),
      ]);

    case actionTypes.MANAGER_APPROVE_TRADE_REQUEST:
    case actionTypes.MANAGER_REJECT_TRADE_REQUEST: {
      if (action.meta.item && !action.meta.shouldRenderShiftTradeUpsell) {
        return state.deleteIn([
          'requestsItems',
          action.meta.item.get('unique_id'),
        ]);
      }

      return state;
    }

    case actionTypes.APPROVE_AVAILABILITY: {
      const request = state
        .get('requestsItems')
        .find(r => r.get('id') === action.payload.id);

      if (request) {
        return state.deleteIn(['requestsItems', request.get('unique_id')]);
      }

      return state;
    }

    case actionTypes.RESOLVE_CLOCK_OUT_APPROVAL_ALERT: {
      const alert = state
        .get('alertsItems')
        .find(
          r =>
            r.get('shift_id') === action.payload.shiftId &&
            r.get('type') === 'clock_out_approval'
        );

      if (alert) {
        return state.deleteIn(['alertsItems', alert.get('unique_id')]);
      }

      return state;
    }

    case actionTypes.MANAGER_APPROVE_TIMEOFF_FAILURE:
    case actionTypes.MANAGER_REJECT_TIMEOFF_FAILURE:
    case actionTypes.MANAGER_APPROVE_TRADE_FAILURE:
    case actionTypes.MANAGER_REJECT_TRADE_FAILURE:
    case actionTypes.READ_REQUEST_FAILURE:
      if (action.meta.item) {
        return state.setIn(
          ['requestsItems', action.meta.item.get('unique_id')],
          action.meta.item
        );
      }
      return state;

    case actionTypes.DISMISS_SHIFT_ALERT_REQUEST:
    case actionTypes.DISMISS_WAGE_RATE_ALERT_REQUEST:
    case actionTypes.APPROVE_SHIFT_REQUEST:
    case actionTypes.READ_HIRING_JOB_APPLICANT_ALERT_REQUEST:
    case actionTypes.DISMISS_CLOCK_OUT_APPROVAL_REQUEST:
    case actionTypes.DISMISS_HEALTH_ALERT_REQUEST:
    case actionTypes.DISMISS_EMPLOYEE_EDIT_ALERT_REQUEST:
      return state.deleteIn(['alertsItems', action.meta.item.get('unique_id')]);

    case actionTypes.DISMISS_SHIFT_ALERT_FAILURE:
    case actionTypes.DISMISS_WAGE_RATE_ALERT_FAILURE:
    case actionTypes.APPROVE_SHIFT_FAILURE:
    case actionTypes.READ_HIRING_JOB_APPLICANT_ALERT_FAILURE:
    case actionTypes.DISMISS_CLOCK_OUT_APPROVAL_FAILURE:
    case actionTypes.DISMISS_HEALTH_ALERT_FAILURE:
    case actionTypes.DISMISS_EMPLOYEE_EDIT_ALERT_FAILURE:
      return state.setIn(
        ['alertsItems', action.meta.item.get('unique_id')],
        action.meta.item
      );

    default:
      return state;
  }
};

export default combineReducers({
  items,
});
