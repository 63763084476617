import React from 'react';
import Icon from 'react-icon-base';

const ThumbsDownFilled = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M23.266 11.484c.468-.45.734-1.074.734-1.74 0-.806-.402-1.559-1.058-2 .166-.333.252-.702.252-1.076 0-1.07-.7-2.009-1.686-2.297.102-.272.154-.56.154-.857 0-1.316-1.04-2.387-2.319-2.387h-8.735c-.807 0-1.532.354-2.045.916A1.716 1.716 0 0 0 6.999 1H1.717C.771 1 0 1.793 0 2.767v8.377c0 .974.77 1.767 1.717 1.767H7a1.69 1.69 0 0 0 1.269-.581c.183.384.436.813.793 1.327l3.357 5.352.743 4.071h1.453c1.364 0 2.235-1.983 2.235-3.35l-.49-4.042 5.324-.077c1.276 0 2.317-1.07 2.317-2.388a2.4 2.4 0 0 0-.734-1.74z"
      clipRule="evenodd"
    />
  </Icon>
);

export default ThumbsDownFilled;
