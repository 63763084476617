import { toI18n } from 'util/i18n';

export const HEADER_CELL_PROPS = {
  bg: 'white',
  vcenter: false,
  vtop: true,
  pv: 10,
};

export const FIXED_COLUMNS = {
  employee: {
    type: 'freeStatic',
    width: 160,
    header: toI18n('timeclock.health_answers_report.headers.employee'),
    sticky: true,
    leftPosition: 0,
  },
  flags: {
    type: 'freeStatic',
    header: toI18n('timeclock.health_answers_report.headers.flags'),
    width: 80,
    sticky: true,
    leftPosition: 160,
    wideBorder: true,
  },
};

export const COLUMN_WIDTH = 180;

export const DEMO_QUESTIONS = {
  1: { id: 1, text: 'Do you have a fever of 100.4 or higher?' },
  2: { id: 2, text: 'Do you have a cough?' },
  3: { id: 3, text: 'Do you have chills and/or repeated shaking?' },
  4: { id: 4, text: 'Do you have muscle pain?' },
  5: { id: 5, text: 'Do you have a headache?' },
  6: { id: 6, text: 'Do you have a sore throat?' },
  7: { id: 7, text: 'Do you have a new loss of taste or smell?' },
};

export const DEMO_ANSWERS = [
  {
    user_name: 'Rosie Adams',
    answers: [
      { question_id: 1, status: 'Yes', error: true },
      { question_id: 2, status: 'No', error: false },
      { question_id: 3, status: 'No', error: false },
      { question_id: 4, status: 'No', error: false },
      { question_id: 5, status: 'No', error: false },
      { question_id: 6, status: 'No', error: false },
      { question_id: 7, status: 'No', error: false },
    ],
  },
  {
    user_name: 'Elliot Bryamt',
    answers: [
      { question_id: 1, status: 'No', error: false },
      { question_id: 2, status: 'Yes', error: true },
      { question_id: 3, status: 'No', error: false },
      { question_id: 4, status: 'No', error: false },
      { question_id: 5, status: 'No', error: false },
      { question_id: 6, status: 'Yes', error: true },
      { question_id: 7, status: 'No', error: false },
    ],
  },
  {
    user_name: 'Roberta Evans',
    answers: [
      { question_id: 1, status: 'No', error: false },
      { question_id: 2, status: 'No', error: false },
      { question_id: 3, status: 'No', error: false },
      { question_id: 4, status: 'No', error: false },
      { question_id: 5, status: 'No', error: false },
      { question_id: 6, status: 'No', error: false },
      { question_id: 7, status: 'No', error: false },
    ],
  },
  {
    user_name: 'Phillis Fernando',
    answers: [
      { question_id: 1, status: 'Yes', error: true },
      { question_id: 2, status: 'No', error: false },
      { question_id: 3, status: 'No', error: false },
      { question_id: 4, status: 'No', error: false },
      { question_id: 5, status: 'No', error: false },
      { question_id: 6, status: 'No', error: false },
      { question_id: 7, status: 'No', error: false },
    ],
  },
  {
    user_name: 'Ginny Fields',
    answers: [
      { question_id: 1, status: 'Yes', error: false },
      { question_id: 2, status: 'No', error: false },
      { question_id: 3, status: 'No', error: false },
      { question_id: 4, status: 'No', error: false },
      { question_id: 5, status: 'No', error: false },
      { question_id: 6, status: 'No', error: false },
      { question_id: 7, status: 'No', error: false },
    ],
  },
];
