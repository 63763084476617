import React from 'react';
import ClickAwayListener, {
  ClickAwayListenerProps,
} from '@mui/material/ClickAwayListener';

const ClickAwayWrapper: React.FC<ClickAwayListenerProps> = ({
  children,
  onClickAway,
  disableReactTree = false,
  mouseEvent = 'onClick',
  touchEvent = 'onTouchEnd',
}) => (
  <ClickAwayListener
    onClickAway={onClickAway}
    disableReactTree={disableReactTree}
    mouseEvent={mouseEvent}
    touchEvent={touchEvent}
  >
    {children}
  </ClickAwayListener>
);

export default ClickAwayWrapper;
