import './AnnouncementBanner.scss';

import React, { useCallback, useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { announcementReadsCreateRoute } from 'api';
import { postJSON } from 'api/fetch';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import StickyBanner from 'components/StickyBanner';
import Text from 'components/Text';

import { cxFunction } from 'util/className';
import { trackAnnouncementsEvent } from 'util/tracking';
import { TRACK_ACTIONS, TRACK_SOURCES } from 'util/tracking_constants';

const CLOSE_ICON_STYLE = { color: 'white' };

const AnnouncementBanner = ({
  announcement,
  setAnnouncementCanShowToFalse,
  cx,
}) => {
  const isRenderable = !announcement.isEmpty();
  const announcementId = announcement.get('id');

  useEffect(() => {
    if (isRenderable) {
      trackAnnouncementsEvent({
        announcementType: TRACK_SOURCES.BANNER,
        eventAction: TRACK_ACTIONS.VIEW,
        properties: {
          announcementName: announcement.get('name'),
        },
      });
    }
  }, [isRenderable, announcement]);

  const handleClose = useCallback(() => {
    postJSON(announcementReadsCreateRoute(), {
      announcement_id: announcementId,
    });

    setAnnouncementCanShowToFalse(`${announcementId}`);
  }, [announcementId, setAnnouncementCanShowToFalse]);

  return isRenderable ? (
    <Box className={cx(null, 'bootstrap')}>
      <StickyBanner
        type="blue"
        h={60}
        closeIconProps={CLOSE_ICON_STYLE}
        onClose={handleClose}
      >
        <Box w="100%" vcenter hcenter>
          <Text fs16 asHTML>
            {announcement.get('body')}
          </Text>
        </Box>
      </StickyBanner>
    </Box>
  ) : null;
};

AnnouncementBanner.propTypes = {
  announcement: ImmutablePropTypes.map.isRequired,
  setAnnouncementCanShowToFalse: PropTypes.func.isRequired,
};

export default cxFunction('AnnouncementBanner', AnnouncementBanner);
