import colors from 'fe-design-base/styles/colors';
import { typography } from 'fe-design-base/styles/typography';
import { borderRadius } from 'fe-design-base/styles/utils';

interface SelectInputStyleProps {
  disabled?: boolean;
}

export const getSelectInputStyles = ({ disabled }: SelectInputStyleProps) => ({
  minHeight: '40px',
  marginRight: '4px',
  width: '100%',
  cursor: disabled ? 'not-allowed' : 'pointer',
  borderRadius: borderRadius.small,
  backgroundColor: disabled ? `${colors.mono300} !important` : undefined,
  ...typography.body,
  '&.FDBSelect': {
    '&.FDBSelect .FDBSelect__clear-icon': {
      marginRight: '38px',
    },
  },
});
