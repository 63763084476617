export const SLICE_NAME = 'bottomDrawer';

export const ARROW_CLASSES = {
  TOP_RIGHT: 'arrow-top-right',
  BOTTOM_RIGHT: 'arrow-bottom-right',
};

export const POPOVER_IMAGES = {
  TRADE_SHIFT: {
    file: 'trade_shift_popover.svg',
    position: 'absolute',
    top: '52%',
    right: '89%',
    borderRadius: 6,
    boxShadow: '0px 4px 12px rgba(17, 17, 17, 0.16)',
  },
  FIND_COVER: {
    file: 'find_cover_popover.svg',
    position: 'absolute',
    top: '33.5%',
    right: '-19%',
    borderRadius: 6,
    boxShadow: '0px 4px 12px rgba(17, 17, 17, 0.16)',
  },
};

export const BOTTOM_RIGHT_IMAGE_PROPERTIES = {
  SELENA: {
    file: 'selena-image.png',
    style: {
      height: 470,
    },
  },
  PHONE_FRAME: {
    file: 'faux-phone-frame-with-notification.svg',
    style: {
      height: 588,
    },
  },
  UPCOMING_SHIFTS_SCHEDULER: {
    file: 'upcoming_shifts_scheduler.svg',
    style: {
      height: 588,
    },
  },
  CLOCK_IN_TIME_PAY: {
    file: 'clock_in_time_pay.svg',
    style: {
      height: 588,
      minWidth: 382,
    },
  },
  CLOCK_OUT_TIME_PAY: {
    file: 'clock_out_time_pay.svg',
    style: {
      height: 588,
    },
  },
  TIMECARD_TIME_PAY: {
    file: 'timecard_time_pay.svg',
    style: {
      height: 588,
      minWidth: 382,
    },
  },
  NEXT_SCHEDULE_AVAILABILITY: {
    file: 'next_schedule_availability.svg',
    style: {
      height: 588,
    },
  },
  FAUX_PHONE_BLANK: {
    file: 'fauxPhone.svg',
    style: {
      height: 588,
      minWidth: 382,
    },
  },
};

export const CTA_TRACKING = {
  BACK_TO_GUIDE: 'backToGuide',
  NEXT_PREVIEW_SCHEDULER: 'nextPreviewScheduler',
  NEXT_PREVIEW_CLOCKIN: 'nextPreviewClockin',
  SETUP_FIRST_TIME_CLOCK: 'setupFirstTimeClock',
  SETUP_PAYROLL: 'setupPayroll',
};

export const LOTTIES_SELECTORS = {
  SLIDE_2: {
    BUTTON_1: {
      WRAPPER: '#onboarding_qsg_payroll_fix_timecard',
      TEXT: '#onboarding_qsg_payroll_fix_timecard + g',
    },
  },
  SLIDE_3: {
    BUTTON_1: {
      WRAPPER: '#onboarding_qsg_payroll_run_payroll_s3',
      TEXT: '#onboarding_qsg_payroll_run_payroll_s3 + g',
    },
  },
  SLIDE_5: {
    BUTTON_1: {
      WRAPPER: '#onboarding_qsg_payroll_run_payroll_s5',
      TEXT: '#onboarding_qsg_payroll_run_payroll_s5 + g',
    },
    BUTTON_2: {
      WRAPPER: '#onboarding_qsg_payroll_continue',
      TEXT: '#onboarding_qsg_payroll_continue + g',
    },
  },
};
