import queryString from 'query-string';
import { RSAA } from 'redux-api-middleware';

import * as flashNotice from 'util/flashNotice';

// Reducer-creator utility to reduce boilerplate. For details, see:
// http://redux.js.org/docs/recipes/ReducingBoilerplate.html#generating-reducers
export const createReducer =
  (initialState, handlers) =>
  (state = initialState, action) => {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    }

    return state;
  };

// Takes a namespace and an array of action types and returns an
// object where each key is an action type with a value corresponding
// to the namespaced action type.
// ex: 'team', ['SHOW', 'HIDE'] => { 'SHOW': 'team/SHOW', 'HIDE': 'team/HIDE' }
export const createActionTypes = (namespace, actionTypes) =>
  actionTypes.reduce((memo, actionType) => {
    memo[actionType] = `${namespace}/${actionType}`;
    return memo;
  }, {});

const ASYNC_ACTION_TYPES = ['REQUEST', 'SUCCESS', 'FAILURE'];

// Takes a namespace and returns namespaced async action types.
// ex: 'team' => { REQUEST: 'team/REQUEST', SUCCESS: 'team/SUCCESS', FAILURE: 'team/FAILURE' }
export const createAsyncActionTypes = namespace =>
  createActionTypes(namespace, ASYNC_ACTION_TYPES);

const formatOpts = ({ body, ...otherOpts }) => {
  let formattedOpts = otherOpts;

  // Body data won't be passed unless it's serialized JSON.
  if (body && typeof body === 'object') {
    formattedOpts = { ...formattedOpts, body: JSON.stringify(body) };
  }

  return formattedOpts;
};

const formatEndpoint = (endpoint, opts) => {
  if (opts.query) {
    const formattedEndpoint = `${endpoint}?${queryString.stringify(
      opts.query
    )}`;
    delete opts.query;

    return formattedEndpoint;
  }

  return endpoint;
};

export const getCSRFToken = () => {
  const csrfElement = document.getElementsByName('csrf-token')[0];
  return (csrfElement && csrfElement.content) || 'csrf-token';
};

const GET_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};

export const getMutationHeaders = () => {
  const headers = {
    ...GET_HEADERS,
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getCSRFToken(),
  };

  if (window.amplitude && window.amplitude.getInstance().getSessionId) {
    headers['amplitude-session-id'] = window.amplitude
      .getInstance()
      .getSessionId();
    headers['amplitude-device-id'] =
      window.amplitude.getInstance().options.deviceId;
  }

  return headers;
};

// Takes request params and an array of action types and returns an
// action creator that generates an async action.
export const createAsyncGetAction = (endpoint, actionTypes, opts = {}) => {
  const formattedEndpoint = formatEndpoint(endpoint, opts);
  const formattedOpts = formatOpts(opts);

  return {
    [RSAA]: {
      credentials: 'include',
      headers: GET_HEADERS,
      method: 'GET',
      types: actionTypes,
      endpoint: formattedEndpoint,
      ...formattedOpts,
    },
  };
};

const createMutationAction = (method, endpoint, actionTypes, opts = {}) => {
  const formattedOpts = formatOpts(opts);

  return {
    [RSAA]: {
      credentials: 'include',
      headers: getMutationHeaders(),
      types: actionTypes,
      method,
      endpoint,
      ...formattedOpts,
    },
  };
};

export const createAsyncPutAction = (...params) =>
  createMutationAction('PUT', ...params);
export const createAsyncPostAction = (...params) =>
  createMutationAction('POST', ...params);
export const createAsyncPatchAction = (...params) =>
  createMutationAction('PATCH', ...params);
export const createAsyncDeleteAction = (...params) =>
  createMutationAction('DELETE', ...params);

export const createAsyncUploadAction = (
  endpoint,
  actionTypes,
  { body = {}, opts = {} }
) => {
  const formattedEndpoint = formatEndpoint(endpoint, opts);
  const formattedOpts = formatOpts(opts);

  // the File and data to the server should be in the root of the object.
  // You cannot have for example body: { file, employee: {id: 1, name: 'Name' } } kind of format,
  // in that case employee will be appended as [Object object] string.
  // This will defuse having params for rails namespaced by model,
  // i.e. params[:employee][:name] etc.. sorry :/
  const bodyFormData = new FormData();
  Object.keys(body).forEach(key => bodyFormData.append(key, body[key]));

  return {
    [RSAA]: {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': getCSRFToken(),
      },
      method: 'POST',
      types: actionTypes,
      endpoint: formattedEndpoint,
      body: bodyFormData,
      ...formattedOpts,
    },
  };
};

const withHandler = (handler, response) =>
  typeof handler === 'function' ? handler(response) : handler;

export const withAlerts =
  (action, opts = {}) =>
  async dispatch => {
    const {
      error,
      onError,
      success,
      onSuccess,
      onDone,
      noErrorFlash,
      noSuccessFlash,
      errorFlashOpts,
    } = opts;

    const response = await dispatch(action);

    if (response === undefined) {
      return;
    }

    if (response.error) {
      if (onError) {
        onError(response);
      }
      if (onDone) {
        onDone();
      }
      if (noErrorFlash) {
        return response;
      }

      const errorMessage = error
        ? withHandler(error, response)
        : (response.payload &&
            response.payload.response &&
            response.payload.response.errors) ||
          'An unknown error occurred.';

      flashNotice.error(errorMessage, errorFlashOpts || { autoHide: false });
      return response;
    }

    if (onSuccess) {
      onSuccess(response);
    }
    if (onDone) {
      onDone();
    }

    if (
      success &&
      !(typeof noSuccessFlash === 'function'
        ? noSuccessFlash(response)
        : noSuccessFlash)
    ) {
      flashNotice.success(withHandler(success, response), {
        autoHideTimeout: 2500,
      });
    }

    return response;
  };
