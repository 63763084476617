import { isArray, isObject, isString, map } from 'lodash';

import { centsToDollar } from 'util/formatter';

import { OLD_PRICING_TIER_NAMES, TIER_NAMES } from './constants';

export const aggregateErrors = errors => {
  if (isString(errors)) {
    return errors;
  } else if (isArray(errors)) {
    return errors.join(', ');
  } else if (isObject(errors)) {
    if (errors.response) {
      return aggregateErrors(errors.response.json().errors);
    }
    if (errors.errors) {
      return aggregateErrors(errors.errors);
    }
    if (errors.message) {
      return errors.message;
    }
    return map(errors, (fieldErrors, field) =>
      field === 'base'
        ? aggregateErrors(fieldErrors)
        : `${field}: ${aggregateErrors(fieldErrors)}`
    ).join(', ');
  }
};

export const getTierName = tierName =>
  tierName === 'enterprise' ? 'all-in-one' : tierName;

export const refreshPage = () => window.location.reload();

export const navigateToPage = url => (window.location = url);

export const getLessThanTiers = tierName => {
  if (
    tierName === TIER_NAMES.BASIC ||
    tierName === OLD_PRICING_TIER_NAMES.BASIC
  ) {
    return [];
  } else if (
    tierName === TIER_NAMES.ESSENTIALS ||
    tierName === OLD_PRICING_TIER_NAMES.ESSENTIALS
  ) {
    return [TIER_NAMES.BASIC];
  } else if (
    tierName === TIER_NAMES.PLUS ||
    tierName === OLD_PRICING_TIER_NAMES.PLUS
  ) {
    return [TIER_NAMES.BASIC, TIER_NAMES.ESSENTIALS];
  } else if (
    tierName === TIER_NAMES.ENTERPRISE ||
    tierName === OLD_PRICING_TIER_NAMES.ENTERPRISE
  ) {
    return [TIER_NAMES.BASIC, TIER_NAMES.ESSENTIALS, TIER_NAMES.PLUS];
  }
};

export const isDowngrading = (currentTierName, newTierName) =>
  getLessThanTiers(currentTierName)?.includes(newTierName);

export const getBillingPlan = (billingPlans, planName) =>
  billingPlans.find(
    billingPlan => billingPlan.tier_name === getTierName(planName)
  ) || {};

export const planPricing = (
  products,
  tierName,
  annual = false,
  showTotal = false
) => {
  const {
    monthlyProducts: monthlyPlans = [],
    annualProducts: annualPlans = [],
  } = products;

  if (annual) {
    const plan = getBillingPlan(annualPlans, tierName);
    let price = centsToDollar(plan.monthly_discounted_amount);

    if (showTotal) price *= 12;

    return price;
  }

  const plan = getBillingPlan(monthlyPlans, tierName);
  return centsToDollar(plan.monthly_base_amount);
};

export const tierPricing = (
  products,
  tierName,
  annual = false,
  showTotal = false
) => {
  if (!tierName) return;

  const price = planPricing(products, tierName, annual, showTotal);

  if (price === '0') return 'Free';

  return `$${price}`;
};
