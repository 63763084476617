import { createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { hideModal, showConfirmModal, showModal } from 'actions/modals';

import {
  MODAL_SELECTION,
  MODAL_TYPES,
  MODULE_NAME,
} from 'features/biller/constants';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import * as constants from '../../modals/constants';
import { PAST_DUE_DIALOG_VIEW_KEY } from '../../monetization/PastDueSubscriptionDialog/constants';
import { paymentMethodUpdated } from '../noticeActions';

export const downgradeTierThunk = slice =>
  createAsyncThunk(`${slice}/downgradeTier`, ({ tier }, { dispatch }) => {
    dispatch(
      showConfirmModal({
        title: 'Downgrade to Basic?',
        message: 'Are you sure?',
        submitText: 'Yes',
        cancelText: 'No',
        onConfirm: () => {
          trackUxEvent({
            productArea: PRODUCT_AREAS.TIERS_PAGE,
            eventCategory: EVENT_CATEGORIES.DOWNGRADE_MODAL,
            eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
            properties: {
              selection: MODAL_SELECTION.YES,
            },
          });
          window.Homebase.RailsReactBridge.navigateToReactView(
            '/tiers/biller/downgrade'
          );
        },
        onCancel: () => {
          trackUxEvent({
            productArea: PRODUCT_AREAS.TIERS_PAGE,
            eventCategory: EVENT_CATEGORIES.DOWNGRADE_MODAL,
            eventAction: EVENT_ACTIONS.BUTTON_CLICKED,
            properties: {
              selection: MODAL_SELECTION.NO,
            },
          });
        },
        onShow: () => {
          trackUxEvent({
            productArea: PRODUCT_AREAS.TIERS_PAGE,
            eventCategory: EVENT_CATEGORIES.TIERS_PAGE,
            eventAction: EVENT_ACTIONS.MODAL_SHOWN,
            properties: {
              modal_type: MODAL_TYPES.DOWNGRADE,
              selected_tier_id: tier.get('id'),
            },
          });
        },
        onClose: closeModal => {
          trackUxEvent({
            productArea: PRODUCT_AREAS.TIERS_PAGE,
            eventCategory: EVENT_CATEGORIES.DOWNGRADE_MODAL,
            eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
          });
          closeModal();
        },
      })
    );
  });

export const updatePaymentMethod = createAsyncThunk(
  `${MODULE_NAME}/updatePaymentMethod`,
  (
    {
      hasMultipleLocations,
      billerAction,
      isBillingModalSourcePastDue,
      setIsBillingModalSourcePastDue,
      showPastDueDialog,
      subscriptionType,
      ...updateProps
    },
    { dispatch }
  ) => {
    const showFlashMessage = () => {
      flashNotice.success(toI18n('biller.change_payment_method.success'));
    };
    const showMultipleLocationsModal = () => {
      showFlashMessage();
      dispatch(
        showModal(constants.UPDATE_LOCATION_PAYMENT_MESSAGE_MODAL, {
          deprecatedModal: true,
          onClose: closeModal => {
            trackUxEvent({
              productArea: PRODUCT_AREAS.BILLING_PAGE,
              eventCategory:
                EVENT_CATEGORIES.MULTILOCATION_CARD_UPDATE_REMINDER_MODAL,
              eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
            });
            closeModal();
          },
        })
      );
    };
    const hideModalAndShowFlashMessage = () => {
      showFlashMessage();
      return dispatch(hideModal());
    };
    const finishUpdate = () => {
      dispatch(paymentMethodUpdated());

      if (isBillingModalSourcePastDue) {
        dispatch(setIsBillingModalSourcePastDue(false));
        return dispatch(
          showPastDueDialog({
            initialViewKey: PAST_DUE_DIALOG_VIEW_KEY.restored_to_paid,
          })
        );
      }

      if (hasMultipleLocations && subscriptionType !== 'Payroll') {
        showMultipleLocationsModal();
      } else {
        hideModalAndShowFlashMessage();
      }
    };
    return dispatch(
      billerAction(merge(updateProps, { finishUpdate, subscriptionType }))
    );
  }
);
