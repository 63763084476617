import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgImage = ({
  alt = 'Image Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M0 4.5c0-1.655 1.345-3 3-3h18c1.655 0 3 1.345 3 3v15c0 1.655-1.345 3-3 3H3c-1.655 0-3-1.345-3-3v-15Zm15.178 4.992a1.122 1.122 0 0 0-1.856 0l-4.078 5.981-1.242-1.551a1.122 1.122 0 0 0-1.753 0l-3 3.75a1.125 1.125 0 0 0 .877 1.828h15.748c.417 0 .802-.23.994-.6.192-.37.169-.816-.066-1.158l-5.625-8.25ZM5.25 9a2.25 2.25 0 1 0-.001-4.501A2.25 2.25 0 0 0 5.25 9Z"
    />
  </svg>
);
export default SvgImage;
