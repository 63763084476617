import React from 'react';
import Icon from 'react-icon-base';

const Eye = props => (
  <Icon viewBox="0 0 14 11" {...props}>
    <path
      fillRule="evenodd"
      d="M13.83 3.889c.097.194.17.389.17.583 0 .219-.073.413-.17.584a7.717 7.717 0 0 1-2.844 2.819c-1.215.73-2.552 1.07-3.986 1.07-1.458 0-2.77-.34-3.986-1.07A7.372 7.372 0 0 1 .17 5.055.969.969 0 0 1 0 4.473c0-.194.049-.389.17-.583a7.72 7.72 0 0 1 2.844-2.82A7.772 7.772 0 0 1 7 0c1.434 0 2.77.365 3.986 1.07a8.102 8.102 0 0 1 2.844 2.819zM7 7.778c.583 0 1.142-.146 1.653-.438a3.36 3.36 0 0 0 1.19-1.215 3.188 3.188 0 0 0 .463-1.653c0-.583-.17-1.142-.462-1.653a3.49 3.49 0 0 0-1.191-1.19A3.323 3.323 0 0 0 7 1.166c-.608 0-1.167.17-1.653.461A3.36 3.36 0 0 0 4.132 2.82a3.298 3.298 0 0 0-.438 1.653c0 .608.146 1.167.438 1.653.292.51.705.924 1.215 1.215A3.17 3.17 0 0 0 7 7.778zm2.528-3.306c0-.68-.268-1.288-.754-1.774S7.681 1.944 7 1.944c-.438 0-.826.098-1.191.292.292 0 .535.122.73.316.194.195.315.438.315.73 0 .29-.121.558-.316.753a.99.99 0 0 1-.729.291c-.292 0-.559-.097-.753-.291a1.046 1.046 0 0 1-.292-.754 2.61 2.61 0 0 0-.292 1.191c0 .705.243 1.313.73 1.799C5.687 6.757 6.294 7 7 7c.68 0 1.288-.243 1.774-.73a2.479 2.479 0 0 0 .754-1.798z"
      clipRule="evenodd"
    />
  </Icon>
);

export default Eye;
