import { useCallback, useState } from 'react';

export const useCalendlyEnterpriseModalHandlers = () => {
  const [calendlyEnterpriseModalIsOpen, setCalendlyEnterpriseModalIsOpen] =
    useState(false);
  const handleCalendlyEnterpriseClick = useCallback(() => {
    setCalendlyEnterpriseModalIsOpen(true);
  }, []);
  const handleCalendlyEnterpriseClose = useCallback(() => {
    setCalendlyEnterpriseModalIsOpen(false);
  }, []);

  return {
    calendlyEnterpriseModalIsOpen,
    handleCalendlyEnterpriseClick,
    handleCalendlyEnterpriseClose,
  };
};
