import { fromJS, Map, Set } from 'immutable';

import { toI18n } from 'util/i18n';

export const INITIAL_GENERAL_STATE = fromJS({
  showTools: false,
  groupByTooltipIsOpen: false,
  mandatedBreaks: null,
  locationSettings: null,
  monetizationUpsellExportModalOpen: false,
  canShowGroupByTooltip: false,
});

export const INITIAL_PAYROLL_SUMMARIES_STATE = fromJS({
  payrollPeriods: Map(),
  loadingPeriods: false,
  pageData: null,
  loadingPageData: false,
  loadingTotals: false,
  loadingTotalsForPayrollPeriods: false,
});

export const INITIAL_PAGE_STATE = fromJS({
  showNav: true,
  rows: Map(),
  daysSummary: Map(),
  jobsSummary: Map(),
  loadingRows: true,
  loadingTotals: true,
  loadingSummaries: true,
  loadingInitData: true,
  columns: [],
  locationRoles: [],
  locationIssues: [],
  locationEmployees: [],
  loadingLocationData: false,
  sortColumn: new Map({
    columnKey: 'date',
    direction: 'asc',
  }),
  startDate: null,
  endDate: null,
  footerTotals: Map(),
  timecardApprovals: Map(),
  collapsedCards: Set(),
  employeeSortBy: 'first_name',
  importCreditTips: null,
  showTipChangesAlert: false,
  showTimesheetsDownloadedToast: false,
});
export const HEADER_SECTION_HEIGHT = 130;
export const EMBEDDED_HEIGHT_ADJUSTMENT = 290;
export const GROUP_BY_OPTIONS = ['employee', 'role', 'day'];

export const INSTRUCTION_VIDEO_MODAL_TYPE =
  'TIMESHEETS/INSTRUCTION_VIDEO_MODAL_TYPE';

export const PHOTOS_MODAL_TYPE = 'TIMESHEETS/PHOTOS_MODAL_TYPE';
export const DOWNLOAD_TIMESHEETS_MODAL = 'TIMESHEETS/DOWNLOAD_TIMESHEETS_MODAL';
export const PAYROLL_SUMMARY_MODAL = 'TIMESHEETS/PAYROLL_SUMMARY_MODAL';
export const DOWNLOAD_TYPE_MODAL = 'TIMESHEETS/DOWNLOAD_TYPE_MODAL';
export const PAYROLL_CODES_MODAL = 'TIMESHEETS/PAYROLL_CODES_MODAL';
export const MAX_PAYROLL_MONTH_SELECT = 1;
export const BASIC_TIER_MAX_PAYROLL_DAYS = 93;
export const LOCK_ICON_WIDTH = 38;
export const ROW_MENU_WIDTH = 48;
export const CARET_ICON_WIDTH = 15;

export const HIDDEN_FOOTER_COLUMNS = ['issues', 'wage_rate'];
export const DEFAULT_WIDTH = 2;
export const LEFT_WIDTH = 8;

export const CACHE_REFRESH_TIME = 55; // in minutes
export const CACHE_REFRESH_INTERVAL = 1000 * 60 * 2; // 2 minutes

export const VALUE_TYPES = {
  main: 'text',
  role: 'text',
  timecard: 'text',
  date: 'text',
  issues: 'text',
  wage_rate: 'text',
};

export const PAYROLL_PERIODS_TODAY = 'today';

export const PAYROLL_PERIODS_YESTERDAY = 'yesterday';

function getPayrollPeriod(payroll) {
  return Map({
    label_type: payroll,
    label_translation: toI18n(
      `timesheets.react_page.payroll_periods.${payroll}`
    ),
  });
}

export const CUSTOM_PAYROLL = 'custom';
export const CUSTOM_PAYROLL_PERIOD = getPayrollPeriod(CUSTOM_PAYROLL);
export const LAST_7_DAYS_PAYROLL = 'last_7_days';
export const LAST_7_DAYS_PAYROLL_PERIOD = getPayrollPeriod(LAST_7_DAYS_PAYROLL);
export const IMPORT_TIMECARDS_LOADING_MODAL =
  'TIMESHEETS/IMPORT_TIMECARDS_LOADING_MODAL';

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm a';

export const MAX_VAL_LENGTH = 15;
export const MAX_LABEL_LENGTH = 12;
export const MAX_EMPLOYEE_GROUP_WIDTH = 300;
export const MAX_EMPLOYEE_GROUP_WIDTH_WITHOUT_ROLE_AND_WAGE = 100;
export const MAX_ROLE_GROUP_WIDTH = 380;
