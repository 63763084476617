import React from 'react';
import Icon from 'react-icon-base';

const MapMarker = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <path d="M8.476 15.097c-1.143-1.092-5.714-5.63-5.714-8.478 0-3.23 2.563-5.857 5.714-5.857 3.151 0 5.714 2.627 5.714 5.857 0 2.847-4.57 7.386-5.714 8.478M8.476 0C4.906 0 2 2.97 2 6.62c0 3.573 5.966 9.05 6.22 9.282a.381.381 0 0 0 .513 0c.254-.232 6.22-5.709 6.22-9.282C14.952 2.969 12.046 0 8.475 0" />
      <path d="M8.476 8.428A2.289 2.289 0 0 1 6.19 6.142a2.289 2.289 0 0 1 2.286-2.286 2.289 2.289 0 0 1 2.286 2.286 2.289 2.289 0 0 1-2.286 2.286m0-5.334A3.05 3.05 0 0 0 5.43 6.142 3.05 3.05 0 0 0 8.476 9.19a3.05 3.05 0 0 0 3.048-3.048 3.05 3.05 0 0 0-3.048-3.048" />
    </g>
  </Icon>
);

export default MapMarker;
