import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dimensions } from 'theme/constants';

import { openAddApplicantsManuallyDrawer } from 'actions/hiring';
import { createMonetizationCtaEvent } from 'actions/session';

import * as ftuSelectors from 'selectors/ftu';
import * as sessionSelectors from 'selectors/session';

import Box from 'components/Box';
import withCurrentRoute from 'components/hocs/withCurrentRoute';

import cxHelpers from 'util/className';

import ActiveTrialNavItem from '../items/ActiveTrialNavItem';
import AvatarMenuNavItem from '../items/AvatarMenuNavItem';
import CloseSideNavButton from '../items/CloseSideNavButton';
import DashboardNavItem from '../items/DashboardNavItem';
import DocumentsNavItem from '../items/DocumentsNavItem';
import InsuranceNavItem from '../items/InsuranceNavItem';
import LocationsMenuNavItem from '../items/LocationsMenuNavItem';
import MessengerNavItem from '../items/MessengerNavItem';
import NotificationsNavItem from '../items/NotificationsNavItem';
import ScheduleNavItem from '../items/ScheduleNavItem';
import SettingsNavItem from '../items/SettingsNavItem';
import SignOutNavItem from '../items/SignOutNavItem';
import TeamNavItem from '../items/TeamNavItem';
import TimesheetsNavItem from '../items/TimesheetsNavItem';
import UserWebTimeclockNavItem from '../items/UserWebTimeclockNavItem';
import WebTimeclockNavItem from '../items/WebTimeclockNavItem';

@withCurrentRoute
@connect(
  (state, props) => ({
    isOnboarding: sessionSelectors.getIsOnboarding(state, props),
    archived: sessionSelectors.getCurrentLocationArchived(state, props),
    showSimpleNav: ftuSelectors.getShowSimpleNav(state, props),
    isManagerial: sessionSelectors.getCurrentUserIsManagerial(state, props),
    currentLocationId: sessionSelectors.getCurrentLocationId(state, props),
  }),
  { createMonetizationCtaEvent, openAddApplicantsManuallyDrawer }
)
@cxHelpers('UserNav')
export default class UserNav extends PureComponent {
  static propTypes = {
    isManagerial: PropTypes.bool,
    mobileView: PropTypes.bool,
    isOnboarding: PropTypes.bool,
    archived: PropTypes.bool,
    showSimpleNav: PropTypes.bool,
    openAddApplicantsManuallyDrawer: PropTypes.func,
    currentLocationId: PropTypes.number,
  };

  renderTopNav() {
    if (this.props.showSimpleNav) {
      return (
        <Box
          className={this.cx()}
          vcenter
          hright
          grow
          basis={0}
          h={dimensions.navHeight}
        >
          <ActiveTrialNavItem />
          <AvatarMenuNavItem mlxs signOutOnly />
        </Box>
      );
    }

    return (
      <Box
        className={this.cx()}
        vcenter
        spacebetween
        grow
        wrapreverse
        basis={0}
        data-test="top-nav"
      >
        <DashboardNavItem />
        <Box vcenter h={dimensions.navHeight} grow>
          <ScheduleNavItem />
          <TimesheetsNavItem />
          <WebTimeclockNavItem />
          <MessengerNavItem isText />
          <TeamNavItem />
          <InsuranceNavItem />
          <DocumentsNavItem />
          <Box mls mrxs br="violet" bwidth={1} h={36} />
          <SettingsNavItem />
        </Box>
        <Box h={dimensions.navHeight} vcenter grow hright>
          {!this.props.isManagerial && <UserWebTimeclockNavItem />}
          <ActiveTrialNavItem />
          {this.props.isManagerial && <UserWebTimeclockNavItem />}
          <NotificationsNavItem noCaret />
          <LocationsMenuNavItem mlxs />
          <AvatarMenuNavItem />
        </Box>
      </Box>
    );
  }

  renderMobile() {
    const { archived, showSimpleNav } = this.props;

    if (showSimpleNav) {
      return (
        <Box className={this.cx()} pas column grow bg="purple">
          <Box vcenter>
            <AvatarMenuNavItem mtxs grow signOutOnly />
            <CloseSideNavButton />
          </Box>
        </Box>
      );
    }

    return (
      <Box
        className={this.cx()}
        pas
        column
        grow
        bg="violetXdark"
        data-test="mobile-nav"
      >
        <LocationsMenuNavItem mb={2} whiteText />
        <DashboardNavItem mb={4} whiteText />
        <ScheduleNavItem mb={4} whiteText />
        <TimesheetsNavItem mb={4} whiteText />
        <TeamNavItem mb={4} whiteText />
        <InsuranceNavItem mb={4} whiteText />
        <DocumentsNavItem mb={4} whiteText />

        {!archived && <Box mb={4} bb="white" bwidth={1} />}

        <SettingsNavItem mb={4} whiteText mobile fullText />
        <AvatarMenuNavItem mb={4} />
      </Box>
    );
  }

  renderOnboarding() {
    return (
      <Box className={this.cx()} vcenter hright grow basis={0}>
        <SignOutNavItem />
      </Box>
    );
  }

  render() {
    if (this.props.isOnboarding) {
      return this.renderOnboarding();
    }

    return this.props.mobileView ? this.renderMobile() : this.renderTopNav();
  }
}
