import { fromJS, List } from 'immutable';
import { each, memoize, startCase } from 'lodash';
import queryString from 'query-string';

import {
  SOURCE_FILTER_MAP,
  WEEK_AVAILABILITIES_DAYS,
  WEEK_AVAILABILITIES_SHIFTS,
} from 'features/hiring/manageApplicants/constants';

import { toI18n } from 'util/i18n';
import { browserHistory } from 'util/router';
import { logHiringEvent, trackUxEvent } from 'util/tracking';
import { PRODUCT_AREAS, TRACK_ACTIONS } from 'util/tracking_constants';

export const appliedForTitle = (ownerType, owner) =>
  ownerType === 'locations'
    ? owner.get('name')
    : toI18n('hiring.manage_applicants.title_with_role', {
        props: {
          role: owner.get('role_name'),
          location: owner.get('location_name'),
        },
      });

const qualityFilterMatch = (application, parsedFilter) =>
  // return `true` if all of the checked filter's attribute's values match
  // all of the respective `application_quality` attribute's values
  parsedFilter.every((filterValue, filterAttribute) => {
    const applicationQualityValue = application
      .get('application_quality')
      .toJS()[filterAttribute];

    return filterValue === applicationQualityValue;
  });

const sourceFilterMatch = (application, parsedFilter) =>
  // return `true` if any of the checked filter's attribute's values match
  // the application's `source` value
  parsedFilter.some(
    (filterValue, filterAttribute) =>
      SOURCE_FILTER_MAP[filterAttribute] === application.get('source')
  );

const isMatchOnDaysAndShifts = (application, daysSelected, shiftsSelected) =>
  // When a user selects *both* Day(s) and Shift(s), we use an AND between the two entities, and
  // we use an OR between groupings of the two.

  // ex: (Monday AND Morning)
  // ex: (Monday AND Morning) OR (Monday AND Afternoon) or (Tuesday AND Morning) or
  // Tuesday AND Afternoon)

  // Return `true` if *any* Day + Shift combos entered in the filter matches *any* of
  // the Day + Shift combos in the applicant's week_availabilities.

  daysSelected.some(day =>
    shiftsSelected.some(shift =>
      application.get('week_availabilities').get(day, List()).includes(shift)
    )
  );

const isMatchOnDays = (application, daysSelected) =>
  // When a user only selects Day(s), we use an OR between each day.

  // ex: (Monday OR Tuesday OR Wednesday)

  // Return `true` if any Day entered in the filter matches *any* Day in the applicant's
  // week_availabilities

  daysSelected.some(
    day => application.get('week_availabilities').get(day, List()).size > 0
  );

const isMatchOnShifts = (application, shiftsSelected) =>
  // When a user only selects Shifts(s), we use an OR between each shift.

  // ex: (Morning OR Evening)

  // Return `true` if any Shift entered in the filter matches *any* Shift in the applicant's
  // week_availabilities

  shiftsSelected.some(shift =>
    application
      .get('week_availabilities')
      .some(
        (availabilityValue, availabilityAttribute) =>
          WEEK_AVAILABILITIES_DAYS.includes(startCase(availabilityAttribute)) &&
          availabilityValue.includes(shift)
      )
  );

const weekAvailabilitiesFilterMatch = (application, parsedFilter) => {
  const daysSelected = [];
  const shiftsSelected = [];

  parsedFilter.forEach((filterValue, filterAttribute) => {
    if (WEEK_AVAILABILITIES_DAYS.includes(filterAttribute)) {
      daysSelected.push(filterAttribute.toLowerCase());
    } else {
      shiftsSelected.push(filterAttribute.toLowerCase());
    }
  });

  if (daysSelected.length > 0 && shiftsSelected.length > 0) {
    return isMatchOnDaysAndShifts(application, daysSelected, shiftsSelected);
  }

  if (daysSelected.length > 0) {
    return isMatchOnDays(application, daysSelected);
  }

  if (shiftsSelected.length > 0) {
    return isMatchOnShifts(application, shiftsSelected);
  }

  return false;
};

export const filterByAvailability = (application, weekAvailabilitiesFilter) => {
  const truthyParsedWeekAvailabilitiesFilter = fromJS(
    weekAvailabilitiesFilter
  ).filter(value => value);

  if (truthyParsedWeekAvailabilitiesFilter.count() === 0) {
    return true;
  }

  return weekAvailabilitiesFilterMatch(
    application,
    truthyParsedWeekAvailabilitiesFilter
  );
};

export const filterByQuality = (application, qualityFilter) => {
  const truthyParsedQualityFilter = fromJS(qualityFilter).filter(
    value => value
  );

  if (truthyParsedQualityFilter.count() === 0) {
    return true;
  }

  return qualityFilterMatch(application, truthyParsedQualityFilter);
};

export const filterBySource = (application, sourceFilter) => {
  const truthyParsedSourceFilter = fromJS(sourceFilter).filter(value => value);

  if (truthyParsedSourceFilter.count() === 0) {
    return true;
  }

  return sourceFilterMatch(application, truthyParsedSourceFilter);
};

export const applicantFilterToStates = filter => {
  // TODO: consider constant'izing the return arrays in case we ever use them elsewhere
  switch (filter) {
    case 'all':
      return [
        'interested',
        'pending',
        'interview_requested',
        'interview_canceled',
      ];
    case 'favorites':
      return [
        'interested',
        'pending',
        'interview_requested',
        'interview_canceled',
      ];
    case 'interviewing':
      return ['interview_requested', 'interview_canceled'];
    case 'hired':
      return ['hired'];
    case 'declined':
      return ['passed', 'hidden'];
    case 'archived':
      return ['archived'];
    default:
      return [
        'interested',
        'pending',
        'interview_requested',
        'interview_canceled',
      ];
  }
};

// Rebuilds current route location with given parameters. Any omitted parameters use the existing
// values from the current route.
// - ownerType, ownerId, token = pathname parameters
// - others = search parameters
//
// Returns a location object for the new route.
const buildRouteLocation = ({
  ownerType,
  ownerId,
  token,
  filter,
  distance,
}) => {
  const location = { ...browserHistory.location };
  const pathnamePieces = location.pathname.split('/');
  // => ["", "hiring", "job_requests", "12", "manage_applicants", "token"]

  if (ownerType) pathnamePieces.splice(2, 1, ownerType);
  if (ownerId) pathnamePieces.splice(3, 1, ownerId);
  if (token !== undefined) pathnamePieces.splice(5, 1, token);

  location.pathname = pathnamePieces.join('/');

  const query = queryString.parse(location.search) || {};
  if (filter) query.filter = filter;
  if (distance) query.distance = distance;

  location.search = queryString.stringify(query);

  return location;
};

export const updateRoute = routeParams => {
  const location = buildRouteLocation(routeParams);
  browserHistory.replace(location);
};

export const buildRoutePath = routeParams => {
  const location = buildRouteLocation(routeParams);
  return location.search
    ? `${location.pathname}?${location.search}`
    : location.pathname;
};

export const logHiringReviewApplicantsEvent = (
  action,
  applicantId,
  other = {}
) => {
  logHiringEvent({
    product_location: 'review_applicants',
    action,
    applicant_id: applicantId,
    additional_data: other,
  });
};

export const trackToggleLiked = (applicantId, liked, other = {}) => {
  const action = liked ? 'liked' : 'unliked';

  logHiringReviewApplicantsEvent(action, applicantId, other);
};

export const buildWeekAvailabilitiesFilterBody = memoize(() => {
  // Structure:

  // {
  //  'DAYS OF THE WEEK':
  //    {
  //      'monday': false,
  //      'tuesday': false,
  //      'wednesday': false,
  //      'thursday': false,
  //      'friday': false,
  //      'saturday': false,
  //      'sunday': false
  //    },
  //  'SHIFTS':
  //    {
  //      'morning': false,
  //      'afternoon': false,
  //      'evening': false
  //    },
  //  }

  const builtWeekAvailabilitiesDays = {};
  const builtWeekAvailabilitiesShifts = {};

  each(WEEK_AVAILABILITIES_DAYS, day => {
    builtWeekAvailabilitiesDays[day] = false;
  });

  each(WEEK_AVAILABILITIES_SHIFTS, day => {
    builtWeekAvailabilitiesShifts[day] = false;
  });

  const i18nKey = 'hiring.manage_applicants.filters.week_availabilities';

  return {
    [toI18n(`${i18nKey}.groups.days`)]: builtWeekAvailabilitiesDays,
    [toI18n(`${i18nKey}.groups.shifts`)]: builtWeekAvailabilitiesShifts,
  };
});

const trackHiringManageApplicantsEvent = (
  eventCategory,
  eventAction,
  properties = {}
) =>
  trackUxEvent({
    productArea: PRODUCT_AREAS.MANAGE_APPLICANTS,
    eventCategory,
    eventAction,
    properties,
  });

export const trackHiringManageApplicantsFilterClickEvent = (
  filterName,
  properties = {}
) =>
  trackHiringManageApplicantsEvent(
    `${filterName} filter`,
    TRACK_ACTIONS.CLICKED,
    properties
  );
