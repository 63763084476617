import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

export default WrappedComponent => {
  @withRouter
  class WithCurrentRoute extends Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
    };

    render() {
      const { location, ...props } = omit(
        this.props,
        'match',
        'history',
        'staticContext'
      );
      return <WrappedComponent currentRoute={location.pathname} {...props} />;
    }
  }

  return WithCurrentRoute;
};
