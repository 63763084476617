import { fetchJSON, postJSON } from 'api/fetch';

import { resolveError } from 'util/error';

import { ENDPOINTS } from './constants';

const { FETCH_EMPLOYEES } = ENDPOINTS;

export const fetchEmployees = () =>
  fetchJSON(FETCH_EMPLOYEES).catch(resolveError);

export const postUpdatePassword = (password: string) =>
  postJSON('/lightspeed/sign-up/welcome', { password }).catch(resolveError);

export const postUpdateOnboardingResponses = (values: object) =>
  postJSON('/lightspeed/sign-up/business_details', { ...values }).catch(
    resolveError
  );
