import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgUser = ({
  alt = 'User Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M12 12a6 6 0 1 0 0-12 6 6 0 1 0 0 12Zm-2.142 2.25A8.356 8.356 0 0 0 1.5 22.608c0 .769.623 1.392 1.392 1.392h18.216c.769 0 1.392-.623 1.392-1.392a8.356 8.356 0 0 0-8.358-8.358H9.858Z"
    />
  </svg>
);
export default SvgUser;
