import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgTablet = ({
  alt = 'Tablet Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M1.5 3c0-1.655 1.345-3 3-3h15c1.655 0 3 1.345 3 3v18c0 1.655-1.345 3-3 3h-15c-1.655 0-3-1.345-3-3V3Zm12 18c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5Zm6-18h-15v15h15V3Z"
    />
  </svg>
);
export default SvgTablet;
