import React from 'react';
import Icon from 'react-icon-base';

const Bowl = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M14.065 13.041c.03.209.239.387.477.387h.476c.297 0 .535-.238.476-.535-.09-.715-.566-1.697-1.072-2.203-.357-.387-.714-1.1-.803-1.607-.03-.208-.238-.417-.476-.417h-.476c-.268 0-.477.239-.477.506v.03c.09.834.625 1.935 1.19 2.53.298.298.596.893.685 1.31zm3.334 0c.03.209.238.387.476.387h.476c.298 0 .536-.238.476-.535-.089-.715-.565-1.697-1.071-2.203-.357-.387-.714-1.1-.804-1.607-.03-.208-.238-.417-.476-.417H16c-.298 0-.536.268-.476.536.089.834.625 1.935 1.19 2.53.298.298.595.893.685 1.31zm5.268 1.34H9.333c-.535 0-.952.446-.952.952 0 2.828 1.518 5.298 3.81 6.607v1.012c0 .536.416.953.952.953h5.714c.506 0 .952-.417.952-.953V21.94c2.262-1.31 3.81-3.78 3.81-6.607 0-.506-.447-.952-.952-.952z"
    />
  </Icon>
);

export default Bowl;
