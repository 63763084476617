import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  bottomDrawerType: null,
  initiatedFrom: null,
  completionEventName: null,
  shouldFireCompletionEvent: false,
  trackingDetails: null,
  shouldFireModuleComplete: false,
  moduleOrder: null,
  drawerProps: null,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    openBottomDrawer: (
      state,
      {
        payload: {
          drawerType,
          initiatedFrom = null,
          completionEventName = null,
          shouldFireCompletionEvent = false,
          trackingDetails = null,
          shouldFireModuleComplete = false,
          moduleOrder = null,
          ...drawerProps
        },
      }
    ) =>
      state
        .set('bottomDrawerType', drawerType)
        .set('initiatedFrom', initiatedFrom)
        .set('completionEventName', completionEventName)
        .set('shouldFireCompletionEvent', shouldFireCompletionEvent)
        .set('trackingDetails', trackingDetails)
        .set('shouldFireModuleComplete', shouldFireModuleComplete)
        .set('moduleOrder', moduleOrder)
        .set('drawerProps', drawerProps),
    removeBottomDrawer: state =>
      state
        .set('bottomDrawerType', null)
        .set('initiatedFrom', null)
        .set('completionEventName', null)
        .set('shouldFireCompletionEvent', false)
        .set('trackingDetails', null)
        .set('shouldFireModuleComplete', false)
        .set('moduleOrder', null)
        .set('drawerProps', null),
  },
});

export const { actions, reducer } = slice;
