// When adding custom timings, follow the pattern set with TS_TIMING_EVENTS
// each key should be set to true and stored in local storage indicating that a
// measurement should be captured.
// Upon capturing each measurement, localStorage should be updated to false
// to avoid capturing a second later measurement.

export const CUSTOM_TIMINGS_KEY = 'RUM_CustomTimings';

export const TS_TIMING_EVENTS = {
  timesheets_labor_loaded: true,
  timesheets_load_complete: true,
  timesheets_first_response: true,
};
