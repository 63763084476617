import React from 'react';
import Icon from 'react-icon-base';

const Email = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <path d="M1.667 13.333a.98.98 0 0 1-.417-.094l4.648-5.303L7.78 9.589a.333.333 0 0 0 .44 0l1.93-1.695 4.645 5.32a.982.982 0 0 1-.462.12H1.667zm-.894-.561a.99.99 0 0 1-.106-.439V3.667c0-.099.018-.193.046-.284l4.684 4.113-4.624 5.276zm13.56-10.105c.209 0 .403.064.564.174L8 8.895 1.105 2.84a.994.994 0 0 1 .562-.174h12.666zm-3.681 4.787l4.636-4.07a.99.99 0 0 1 .045.283v8.666c0 .14-.028.272-.08.393l-4.601-5.272zM16 3.667c0-.381-.133-.729-.349-1.01-.006-.006-.007-.016-.013-.022-.005-.006-.011-.008-.015-.012A1.657 1.657 0 0 0 14.333 2H1.667c-.524 0-.987.248-1.293.627-.003.003-.007.004-.01.008l-.007.014C.137 2.93 0 3.28 0 3.667v8.666c0 .48.207.909.532 1.214l.002.003.006.003c.297.275.69.447 1.127.447h12.666a1.65 1.65 0 0 0 1.046-.38.33.33 0 0 0 .15-.07.325.325 0 0 0 .102-.184A1.65 1.65 0 0 0 16 12.333V3.667z" />
    </g>
  </Icon>
);

export default Email;
