import React from 'react';
import Icon from 'react-icon-base';

const HeartAura = props => (
  <Icon viewBox="0 0 135 121" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#EEE" d="M-362.5-199.5h859v1312h-859z" />
      <g fill="currentColor">
        <path d="M116 63.29c0 7.96-4.132 16.066-12.396 24.315l-33.722 32.422c-.65.649-1.444.973-2.382.973-.938 0-1.732-.324-2.382-.973l-33.776-32.53c-.361-.288-.858-.756-1.489-1.405-.632-.648-1.633-1.828-3.004-3.539a47.817 47.817 0 0 1-3.681-5.268c-1.083-1.802-2.048-3.981-2.896-6.539C19.424 68.188 19 65.703 19 63.29c0-7.925 2.291-14.121 6.874-18.588 4.583-4.468 10.917-6.701 19-6.701 2.237 0 4.52.387 6.847 1.162a27.734 27.734 0 0 1 6.496 3.134c2.003 1.315 3.726 2.549 5.17 3.701a49.844 49.844 0 0 1 4.113 3.675 49.844 49.844 0 0 1 4.114-3.675c1.443-1.152 3.166-2.386 5.17-3.701a27.734 27.734 0 0 1 6.495-3.134c2.327-.775 4.61-1.162 6.847-1.162 8.083 0 14.417 2.233 19 6.7C113.709 49.169 116 55.365 116 63.29z" />
        <rect
          width="20"
          height="3"
          x="57.5"
          y="18.5"
          rx="1.5"
          transform="rotate(90 67.5 20)"
        />
        <rect
          width="20"
          height="3"
          x="5.132"
          y="31.632"
          rx="1.5"
          transform="rotate(45 15.132 33.132)"
        />
        <rect
          width="10"
          height="3"
          x="32.132"
          y="20.632"
          rx="1.5"
          transform="rotate(67 37.132 22.132)"
        />
        <rect
          width="20"
          height="3"
          x="110.132"
          y="31.632"
          rx="1.5"
          transform="rotate(-45 120.132 33.132)"
        />
        <rect
          width="10"
          height="3"
          x="92.132"
          y="20.632"
          rx="1.5"
          transform="rotate(-67 97.132 22.132)"
        />
      </g>
    </g>
  </Icon>
);

export default HeartAura;
