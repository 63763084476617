import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgArrowRight = ({
  alt = 'Arrow Right Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M22.06 13.059a1.502 1.502 0 0 0 0-2.123l-7.5-7.5a1.502 1.502 0 0 0-2.124 2.123l4.945 4.94H3c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h14.377L12.44 18.44a1.502 1.502 0 0 0 2.123 2.124l7.5-7.5-.005-.005Z"
    />
  </svg>
);
export default SvgArrowRight;
