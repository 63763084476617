import './IconButton.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/clickables/Button';
import Icon from 'components/Icon';

import cxHelpers from 'util/className';

@cxHelpers('IconButton')
class IconButton extends PureComponent {
  static propTypes = {
    iconColor: Icon.propTypes.color,
    onClick: PropTypes.func,
    noBorder: PropTypes.bool,
    cramped: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
  };

  static defaultProps = {
    theme: 'link',
  };

  render() {
    const { type, size, iconColor, noBorder, cramped, ...props } = this.props;

    return (
      <Button
        {...props}
        className={this.cx({ 'no-border': noBorder, cramped, [type]: type })}
      >
        <Icon
          className={this.cxEl('icon')}
          type={type}
          size={size}
          color={iconColor}
        />
      </Button>
    );
  }
}

export default IconButton;
