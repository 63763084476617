import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { showConfirmModal } from 'actions/modals';
import {
  approvePendingJob,
  declinePendingJob,
  fetchRosterData,
} from 'actions/teamView';

import { trackTeamRequestEvents } from 'features/teamView/tracking';

import { toI18n } from 'util/i18n';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import RequestsListItem from '../RequestsListItem';

@connect(() => ({}), {
  approvePendingJob,
  declinePendingJob,
  showConfirmModal,
  fetchRosterData,
})
export default class PendingJob extends PureComponent {
  static propTypes = {
    approvePendingJob: PropTypes.func.isRequired,
    declinePendingJob: PropTypes.func.isRequired,
    showConfirmModal: PropTypes.func.isRequired,
    fetchRosterData: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchRosterData();
  }

  formattedFullName = () =>
    [this.props.item.get('first_name'), this.props.item.get('last_name')].join(
      ' '
    );

  sendApproveRequest = () => {
    trackTeamRequestEvents({
      approve: true,
      startingPath: TRACK_STARTING_PATHS.ALERTS,
    });
    this.props.approvePendingJob(
      this.props.item.get('id'),
      this.props.item.get('existing_user_id')
    );
  };

  handleApprove = () => {
    if (this.props.item.get('existing_user_name')) {
      this.props.showConfirmModal({
        title: toI18n('notifications.requests.pending_job.confirmation_title'),
        message: toI18n('notifications.requests.pending_job.confirmation', {
          props: {
            name: this.formattedFullName(),
            existing_user_name: this.props.item.get('existing_user_name'),
          },
        }),
        onConfirm: this.sendApproveRequest,
      });
    } else {
      this.sendApproveRequest();
    }
  };

  handleDecline = () => {
    trackTeamRequestEvents({
      approve: false,
      startingPath: TRACK_STARTING_PATHS.ALERTS,
    });
    this.props.declinePendingJob(this.props.item.get('id'));
  };

  render() {
    return (
      <RequestsListItem
        body={toI18n('notifications.requests.pending_job.desc', {
          props: { name: this.formattedFullName() },
        })}
        onApprove={this.handleApprove}
        onDecline={this.handleDecline}
        {...this.props}
      />
    );
  }
}
