import afterFrame from 'afterframe';

import { trackCustomMeasure } from 'features/scheduleBuilder/ScheduleBuilderView/utils/datadog';

import { ACTION_FETCH, ACTION_UPDATE } from '../module-constants';

export const markPerformance = (name, action) => {
  const startMarkName = `sb_after_${name}_${action}_start`;
  const endMarkName = `sb_after_${name}_${action}_end`;
  const measureName = `sb_after_${name}_${action}_task_duration`;

  performance.mark(startMarkName);

  let visibilityWasHidden = false;
  const visibilityChangeHandler = () => {
    if (document.hidden) {
      visibilityWasHidden = true;
    }
  };
  document.addEventListener('visibilitychange', visibilityChangeHandler);

  afterFrame(() => {
    performance.mark(endMarkName);
    const measure = performance.measure(
      measureName,
      startMarkName,
      endMarkName
    );

    document.removeEventListener('visibilitychange', visibilityChangeHandler);
    // if visibility was changed to hidden, we shouldn't send measure to datadog as it is invalid
    if (visibilityWasHidden) {
      return;
    }

    // send to datadog
    if (
      name === 'shift' ||
      (name === 'schedule' && action === ACTION_FETCH) ||
      (name === 'schedule' && action === ACTION_UPDATE)
    ) {
      trackCustomMeasure(measure.name, measure.duration);
    }
  });
};
