export const getFTUChecklistSteps = state =>
  state.getIn(['ftu', 'checklist', 'steps']);
export const getFTUChecklistCompact = state =>
  state.getIn(['ftu', 'checklist', 'compact']);
export const getFTUChecklistVersion = state =>
  state.getIn(['ftu', 'checklist', 'version']);

export const getShowChecklistSimpleNav = state =>
  state.getIn(['ftu', 'showChecklistSimpleNav']);

export const getShowSimpleNav = state =>
  state.getIn(['session', 'nav', 'type']) === 'user' &&
  getShowChecklistSimpleNav(state);

export const getShowPayAnywhereTimesheetsModal = state =>
  state.getIn(['ftu', 'showPayAnywhereTimesheetsModal']);

export const getShowPayAnywhereTeamModal = state =>
  state.getIn(['ftu', 'showPayAnywhereTeamModal']);

export const getIsShowingScheduleTour = state =>
  state.getIn(['ftu', 'isShowingScheduleTour']);

export const getTrialLength = state => state.getIn(['ftu', 'trialLength']);

export const getShowCloverOnboardingEnabled = state =>
  state.getIn(['ftu', 'showCloverOnboardingEnabled']);

export const getShowQuickbooksAuthModal = state =>
  state.getIn(['ftu', 'showQuickbooksAuthModal']);

export const getShowQuickbooksAuthUrl = state =>
  state.getIn(['ftu', 'quickbooksAuthUrl']);
