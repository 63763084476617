import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import { readHiringJobApplicantAlert } from 'actions/notifications';

import { toI18n } from 'util/i18n';

import AlertsListItem from '../AlertsListItem';

@connect(() => ({}), { readAlert: readHiringJobApplicantAlert })
export default class HiringJobApplication extends PureComponent {
  static propTypes = {
    item: ImmutablePropTypes.map.isRequired,
    readAlert: PropTypes.func.isRequired,
  };

  render() {
    const { item, readAlert } = this.props;

    return (
      <AlertsListItem
        desc={item.get('body')}
        onEdit={partial(readAlert, item, true)}
        onDismiss={partial(readAlert, item, false)}
        editButton={toI18n('notifications.alerts.job_application.edit')}
        {...this.props}
      />
    );
  }
}
