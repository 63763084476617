import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import PropTypes from 'prop-types';

import { actions } from 'features/biller';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUxHandler, withUxRoot } from 'util/uxEvents';

import {
  selectLocationBiller,
  selectLocationId,
  selectRemainingDaysOnCreditCard,
} from '../selectors';
const { cx } = cxHelpers('ExpiringCreditCardView');

const ExpiringCreditCardView = ({
  onChangePaymentMethod,
  remainingDaysOnCreditCard,
  biller,
  currentLocationId,
}) => {
  const handleViewBannerTracking = useTrackUxHandler(
    EVENT_ACTIONS.BANNER_VIEWED,
    TRACK_ACTION_TYPES.VIEW
  );

  const handleLinkClickTracking = useTrackUxHandler(
    EVENT_ACTIONS.BANNER_CLICKED,
    TRACK_ACTION_TYPES.CLICK,
    {
      link_text: toI18n('errors.credit_card_expiry.will_expire_in', {
        props: { remaining_days: remainingDaysOnCreditCard },
      }),
    }
  );

  useEffect(() => {
    handleViewBannerTracking();
  }, [handleViewBannerTracking]);

  const handleChangePaymentMethod = useCallback(() => {
    handleLinkClickTracking();
    onChangePaymentMethod({
      biller,
      locationId: currentLocationId,
      subscriptionType: 'team_app',
    });
  }, [
    biller,
    currentLocationId,
    handleLinkClickTracking,
    onChangePaymentMethod,
  ]);

  return (
    <Box h={48} bgcolor="red300" vcenter hcenter ph={16} className={cx()}>
      <Link onClick={handleChangePaymentMethod}>
        <Text variant="bodyBold" color="mono0">
          {toI18n('errors.credit_card_expiry.will_expire_in', {
            props: { remaining_days: remainingDaysOnCreditCard },
          })}
        </Text>
      </Link>
    </Box>
  );
};

ExpiringCreditCardView.propTypes = {
  remainingDaysOnCreditCard: PropTypes.number,
  onChangePaymentMethod: PropTypes.func.isRequired,
  biller: PropTypes.string.isRequired,
  currentLocationId: PropTypes.number.isRequired,
  currentCompanyId: PropTypes.number.isRequired,
};

export default connect(
  state => ({
    remainingDaysOnCreditCard: selectRemainingDaysOnCreditCard(state),
    biller: selectLocationBiller(state),
    currentLocationId: selectLocationId(state),
  }),
  { onChangePaymentMethod: actions.changePaymentMethod }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.BILLING,
    eventCategory: EVENT_CATEGORIES.EXPIRED_CREDIT_CARDS_BANNER,
  })(ExpiringCreditCardView)
);
