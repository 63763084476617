import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';

const AddButton = ({ onClick, i18n }) => (
  <Box minw={220} ml={12}>
    <Button onClick={onClick} variant="tertiary" startIcon="AddWithCircle">
      <Text variant="bodyBold" i18n={i18n} />
    </Button>
  </Box>
);

export default AddButton;
