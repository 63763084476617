import { each, isString, startCase } from 'lodash';

import { df, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

export const formatPhoneNumber = number => {
  if (!number || !number.trim()) {
    return '';
  }

  // TODO: replace with proper internation support
  const numberString = `${number}`.replace(/\D/g, '');
  const matches = numberString.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !matches ? '' : `(${matches[1]}) ${matches[2]}-${matches[3]}`;
};

export const formatServerErrors = res => {
  const errors = {};

  each(res, (error, name) => {
    errors[name] = `${startCase(name)} ${error[0]}`;
  });

  return errors;
};

export const sanitizeString = value =>
  isString(value) ? value.replace(/<\/?script[^>]*>/g, '') : value;

export const capitalizeFirstLetter = text => startCase(text);

const parseDay = (value, format) => {
  if (typeof value !== 'string') return value;

  return moment(value, format);
};

export const centsToDollar = value =>
  Number((value ?? 0) / 100).toLocaleString();

export const formatShortDateRange = (
  startOfWeek,
  endOfWeek,
  format = df('parsable_date')
) => {
  const parsedStartOfWeek = parseDay(startOfWeek, format);
  const parsedEndOfWeek = parseDay(endOfWeek, format);

  if (parsedStartOfWeek.month() === parsedEndOfWeek.month()) {
    return `${parsedStartOfWeek.format('MMM D')} - ${parsedEndOfWeek.format(
      'D'
    )}`;
  }
  return `${parsedStartOfWeek.format('MMM D')} - ${parsedEndOfWeek.format(
    'MMM D'
  )}`;
};

export const getProperties = message => {
  if (
    message.includes(
      toI18n('team.employee_profile.add_existing_user.resend_invite')
    )
  ) {
    return toI18n('team.employee_profile.error_msg.existing_error');
  } else if (
    message.includes(
      toI18n('activerecord.errors.models.user.attributes.email.taken')
    )
  ) {
    return toI18n('team.employee_profile.error_msg.email_taken');
  } else if (
    message.includes(
      toI18n('activerecord.errors.models.user.attributes.phone.taken')
    )
  ) {
    return toI18n('team.employee_profile.error_msg.phone_taken');
  }
  return message;
};
