import React from 'react';
import Icon from 'react-icon-base';

const Stars = props => (
  <Icon viewBox="0 0 22 22" {...props}>
    <g>
      <path
        stroke="#fff"
        d="M14.681 5.268a.504.504 0 0 1 .92 0l.379-.171-.38.171.098.217a10.59 10.59 0 0 0 5.285 5.285l.216.098a.504.504 0 0 1 0 .919l-.216.097a10.59 10.59 0 0 0-5.285 5.285l-.098.217a.504.504 0 0 1-.919 0l-.098-.217a10.59 10.59 0 0 0-5.285-5.285l-.216-.097a.504.504 0 0 1 0-.92l-.208-.459.208.46.216-.098a10.59 10.59 0 0 0 5.285-5.285l.098-.217zM4.406.624a.203.203 0 0 1 .37 0A7.58 7.58 0 0 0 8.56 4.406c.16.072.16.299 0 .37A7.58 7.58 0 0 0 4.777 8.56a.203.203 0 0 1-.37 0A7.58 7.58 0 0 0 .623 4.777a.203.203 0 0 1 0-.37A7.58 7.58 0 0 0 4.406.623zM5.14 14.42a.063.063 0 0 1 .024-.03.074.074 0 0 1 .039-.01c.016 0 .03.004.038.01a.063.063 0 0 1 .025.03 6.238 6.238 0 0 0 3.112 3.113c.02.01.027.018.031.025.006.009.01.022.01.038 0 .017-.004.03-.01.039a.064.064 0 0 1-.03.024l.207.46-.208-.46a6.238 6.238 0 0 0-3.112 3.113.063.063 0 0 1-.025.03.074.074 0 0 1-.038.01.074.074 0 0 1-.039-.01.063.063 0 0 1-.024-.03 6.238 6.238 0 0 0-3.113-3.113l-.192.425.192-.425a.064.064 0 0 1-.03-.024.074.074 0 0 1-.01-.039c0-.016.004-.03.01-.038a.064.064 0 0 1 .03-.025A6.238 6.238 0 0 0 5.14 14.42z"
      />
    </g>
  </Icon>
);

export default Stars;
