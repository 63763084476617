import { SLICE_NAME } from './constants';

export const getBottomDrawerType = state =>
  state.getIn([SLICE_NAME, 'bottomDrawerType']);

export const getInitiatedFrom = state =>
  state.getIn([SLICE_NAME, 'initiatedFrom']);

export const getCompletionEventName = state =>
  state.getIn([SLICE_NAME, 'completionEventName']);

export const getShouldFireCompletionEvent = state =>
  state.getIn([SLICE_NAME, 'shouldFireCompletionEvent']);

export const getTrackingDetails = state =>
  state.getIn([SLICE_NAME, 'trackingDetails']);

export const getShouldFireModuleComplete = state =>
  state.getIn([SLICE_NAME, 'shouldFireModuleComplete']);

export const getModuleOrder = state => state.getIn([SLICE_NAME, 'moduleOrder']);

export const getDrawerProps = state => state.getIn([SLICE_NAME, 'drawerProps']);
