import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgWarning = ({
  alt = 'Warning Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M23.733 19.547 13.735 2.484c-.766-1.312-2.697-1.312-3.468 0L.273 19.547c-.77 1.308.189 2.953 1.73 2.953h19.998c1.535 0 2.496-1.64 1.732-2.953ZM10.875 7.875a1.125 1.125 0 0 1 2.25 0v6c0 .621-.504 1.125-1.082 1.125-.58 0-1.168-.502-1.168-1.125v-6ZM12 19.5a1.474 1.474 0 1 1 0-2.948 1.474 1.474 0 0 1 0 2.948Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgWarning;
