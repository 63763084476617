import React from 'react';
import Icon from 'react-icon-base';

const ComputerBlue = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g>
      <circle cx="24" cy="24" r="24" fill="#E9F8FC" />
      <path
        fill="#70CAD9"
        d="M36.293 12.48H12.026c-1.34 0-2.426 1.075-2.426 2.4v14.4c0 1.325 1.087 2.4 2.426 2.4h9.707l-1.213 4.8h-3.64a.807.807 0 0 0-.81.8c0 .44.365.8.81.8h14.56c.444 0 .808-.36.808-.8 0-.44-.363-.8-.808-.8H27.8l-1.214-4.8h9.707c1.34 0 2.427-1.075 2.427-2.4v-14.4c0-1.325-1.087-2.4-2.427-2.4zm-14.105 24l.809-3.2h2.325l.81 3.2h-3.944zm14.914-7.2c0 .44-.364.8-.81.8H12.027a.807.807 0 0 1-.809-.8v-14.4c0-.44.364-.8.81-.8h24.266c.445 0 .809.36.809.8v14.4z"
      />
    </g>
  </Icon>
);

export default ComputerBlue;
