import './SocialSharing.scss';

import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import Link from 'fe-design-base/molecules/Link';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

interface SocialSharingProps {
  googleDriveUrl: string;
  handleClickFacebookShare: () => void;
  handleClickInstagramShare: () => void;
}
const { cx, cxEl } = cxHelpers('SocialSharing');

const SocialSharing = ({
  googleDriveUrl,
  handleClickFacebookShare,
  handleClickInstagramShare,
}: SocialSharingProps) => (
  <Box flex gap={16} className={cx()}>
    <Box w={350} h={350} className={cxEl('provided-hours-image')} />

    <Box w={240} column justifyContentCenter>
      <Box mb={8}>
        <Text
          variant="heading2"
          i18n="growth.hours_provided.social_sharing.title"
        />
      </Box>

      <Box mb={24}>
        <Text
          variant="bodySmTight"
          color="mono700"
          i18n="growth.hours_provided.social_sharing.tag_homebase"
        />
      </Box>

      <Box column gap={16}>
        <Button
          variant="secondary"
          startIcon="MonoFacebook"
          uxElement="facebook"
          onClick={handleClickFacebookShare}
        >
          Facebook
        </Button>

        <Button
          variant="secondary"
          startIcon="MonoInstagram"
          uxElement="instagram"
          onClick={handleClickInstagramShare}
        >
          Instagram
        </Button>

        <Box>
          <Text
            inline
            variant="bodySmTight"
            color="mono700"
            i18n="growth.hours_provided.social_sharing.send_to_manager"
          />
          &nbsp; &nbsp;
          <Link
            size="small"
            target="_blank"
            href={googleDriveUrl}
            endIcon="ExternalLink"
            uxElement="download now"
          >
            {toI18n('growth.hours_provided.social_sharing.download_now')}
          </Link>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default SocialSharing;
