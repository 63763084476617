import { createAsyncThunk } from '@reduxjs/toolkit';
import * as routes from 'api';
import { putJSON } from 'api/fetch';

export const updateLocation = createAsyncThunk(
  'entities/updateLocation',
  ({ id, ...payload }, { rejectWithValue }) =>
    putJSON(routes.locationRoute(id), payload).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);
