import React, { useCallback, useMemo, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Box from 'fe-components/Box';
import Autocomplete from 'fe-design-base/atoms/Autocomplete';
import PropTypes from 'prop-types';

import { getCompanyRoles, getCompanyRolesLoading } from 'selectors/addTeam';

import {
  ADD_NEW_ROLE,
  I18N_JOB_DETAILS_PATH,
} from 'features/team/components/AddEmployeeForm/constants';
import { openAddNewRoleModal } from 'features/team/slice';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

const { cxEl } = cxHelpers('JobDetailsSection');

const RoleAutocomplete = ({
  autoCompleteValue,
  companyAndSelectedRoles,
  companyRoles,
  companyRolesLoading,
  fieldValue,
  onOpenAddNewRoleModal,
  selectedRoles,
  setFieldValue,
  setRoleFieldValue,
  updateSelectedRoles,
}) => {
  const [inputRole, setInputRole] = useState('');

  const roleAssigned = toI18n(`${I18N_JOB_DETAILS_PATH}.search_assigned`);

  const getNoOptionsText = useMemo(() => {
    if (companyRolesLoading) {
      return toI18n(`${I18N_JOB_DETAILS_PATH}.loading_roles`);
    }
    return companyRoles.size > 0
      ? toI18n(`${I18N_JOB_DETAILS_PATH}.no_options_text`, {
          props: { role: inputRole },
        })
      : toI18n(`${I18N_JOB_DETAILS_PATH}.get_started_role`);
  }, [companyRolesLoading, companyRoles.size, inputRole]);

  const handleRoleInputChange = useCallback(
    (_, value) => {
      setInputRole(value);
    },
    [setInputRole]
  );

  const handleWageRoleChange = useCallback(
    (_, option, reason) => {
      if (option?.value === ADD_NEW_ROLE) {
        onOpenAddNewRoleModal();
        setRoleFieldValue(fieldValue);
      } else {
        const roleName = option?.label;
        setFieldValue(fieldValue, roleName);
        updateSelectedRoles(reason, autoCompleteValue, roleName);
      }
    },
    [
      autoCompleteValue,
      fieldValue,
      onOpenAddNewRoleModal,
      setFieldValue,
      setRoleFieldValue,
      updateSelectedRoles,
    ]
  );

  const fixedOptions = [
    {
      label: '',
      value: ADD_NEW_ROLE,
      buttonProps: {
        children: toI18n(`${I18N_JOB_DETAILS_PATH}.add_new_role`),
      },
    },
  ];

  const roleOptions = useMemo(
    () =>
      companyAndSelectedRoles
        ?.sortBy(role => role.toLowerCase())
        .reduce((memo, role) => {
          const selected = selectedRoles.indexOf(role) > -1;
          const label = selected ? `${role} (${roleAssigned})` : role;
          memo.push({
            value: role,
            label,
            disabled: selected,
          });
          return memo;
        }, []),
    [companyAndSelectedRoles, roleAssigned, selectedRoles]
  );

  return (
    <Box className={cxEl('role-autocomplete')}>
      <Autocomplete
        fixedOptions={fixedOptions}
        menuMaxHeight={290}
        noOptionsText={getNoOptionsText}
        onInputChange={handleRoleInputChange}
        onChange={handleWageRoleChange}
        options={roleOptions}
        placeholder={toI18n(`${I18N_JOB_DETAILS_PATH}.search_placeholder`)}
        showCaret
        value={autoCompleteValue}
      />
    </Box>
  );
};

RoleAutocomplete.propTypes = {
  autoCompleteValue: PropTypes.string,
  companyAndSelectedRoles: ImmutablePropTypes.list,
  companyRoles: PropTypes.object,
  companyRolesLoading: PropTypes.bool,
  fieldValue: PropTypes.string,
  onOpenAddNewRoleModal: PropTypes.func,
  selectedRoles: PropTypes.array,
  setFieldValue: PropTypes.func,
  setRoleFieldValue: PropTypes.func,
  updateSelectedRoles: PropTypes.func,
};

export default connect(
  state => ({
    companyRoles: getCompanyRoles(state),
    companyRolesLoading: getCompanyRolesLoading(state),
  }),
  {
    onOpenAddNewRoleModal: openAddNewRoleModal,
  }
)(RoleAutocomplete);
