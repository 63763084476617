import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMenu = ({
  alt = 'Menu Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M0 3.75C0 2.784.768 2 1.714 2h20.572C23.234 2 24 2.784 24 3.75c0 .968-.766 1.75-1.714 1.75H1.714C.768 5.5 0 4.718 0 3.75Zm0 8.75c0-.968.768-1.75 1.714-1.75h20.572c.948 0 1.714.782 1.714 1.75s-.766 1.75-1.714 1.75H1.714C.768 14.25 0 13.468 0 12.5ZM22.286 23H1.714C.768 23 0 22.218 0 21.25s.768-1.75 1.714-1.75h20.572c.948 0 1.714.782 1.714 1.75S23.234 23 22.286 23Z"
    />
  </svg>
);
export default SvgMenu;
