import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgProfile = ({
  alt = 'Profile Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0Zm0 6a3.375 3.375 0 1 1 0 6.75A3.375 3.375 0 0 1 12 6Zm0 15a8.976 8.976 0 0 1-6.36-2.639C6.397 16.401 8.272 15 10.5 15h3c2.228 0 4.103 1.4 4.86 3.361A8.972 8.972 0 0 1 12 21Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgProfile;
