import { trackUxEvent } from 'util/tracking';
import { TRACK_ACTION_TYPES } from 'util/tracking_constants';

export const PRODUCT_AREAS = {
  DASHBOARD: 'dashboard',
};

export const EVENT_CATEGORIES = {
  NOTIFICATIONS_DRAWER: 'notifications_drawer',
  ALERTS_TAB: 'alerts_tab',
};

export const EVENT_ACTIONS = {
  REQUESTS_CLICKED: 'Requests Clicked',
  ALERTS_CLICKED: 'Alerts Clicked',
  CLOSE_CLICKED: 'Close Clicked',
  DISMISS_CLICKED: 'Dismiss Clicked',
  VIEW_CLICKED: 'View Clicked',
};

export const trackNotificationEvents = ({ eventAction }) => {
  trackUxEvent({
    eventAction,
    eventCategory: EVENT_CATEGORIES.NOTIFICATIONS_DRAWER,
    productArea: PRODUCT_AREAS.DASHBOARD,
    actionType: TRACK_ACTION_TYPES.CLICK,
  });
};

export const trackAlertEvents = ({ eventAction, alertType }) => {
  trackUxEvent({
    eventAction,
    eventCategory: EVENT_CATEGORIES.ALERTS_TAB,
    productArea: PRODUCT_AREAS.DASHBOARD,
    actionType: TRACK_ACTION_TYPES.CLICK,
    properties: {
      alert_type: alertType,
    },
  });
};
