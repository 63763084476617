import fetchIntercept from 'fetch-intercept';

const registerFetchIntercept = () => {
  // This variable is evaluated when running feature specs to ensure that all active fetch requests
  // have completed before making assertions that rely on asynchronous data
  window.activeFetchRequests = 0;

  if (
    window.Homebase.env &&
    (window.Homebase.env === 'development' || window.Homebase.env === 'test')
  ) {
    fetchIntercept.register({
      request: (url, config) => {
        window.activeFetchRequests += 1;
        return [url, config];
      },
      response: response => {
        window.activeFetchRequests -= 1;
        return response;
      },
      responseError: error => {
        window.activeFetchRequests -= 1;
        console.log(error);
        return Promise.reject(error);
      },
    });
  }
};

export default registerFetchIntercept;
