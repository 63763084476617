import { fromJS } from 'immutable';

import { actionTypes } from 'actions/retrial';

const INITIAL_STATE = fromJS({
  modalIsOpen: true,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CLOSE_MODAL:
      return state.merge({ modalIsOpen: false });

    case actionTypes.OPEN_MODAL:
      return state.merge({ modalIsOpen: true });

    default:
      return state;
  }
};
