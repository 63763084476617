import colors from 'fe-design-base/styles/colors';

import { Overrides } from '../../types';

export const alertOverrides: Overrides = {
  MuiAlert: {
    styleOverrides: {
      root: {
        '&.MuiPaper-root.FDBAlert': {
          padding: '16px',
          borderRadius: '8px',
          borderStyle: 'solid',

          '& .MuiAlert-message': {
            width: '100%',
            display: 'grid',
            overflow: 'visible',
            padding: 0,
            alignItems: 'center',
          },
          '& .MuiAlert-action': {
            marginLeft: '16px',
            marginRight: 0,
            padding: 0,
            alignItems: 'center',

            '& .MuiIconButton-root': {
              color: colors.mono900,
              alignSelf: 'flex-start',
              width: '20px',
              height: '20px',
              marginRight: '-8px',

              '&:hover': {
                border: 'none',
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
          },

          '&.FDBAlert--isToast': {
            padding: '12px 16px',
            minWidth: '260px',
            maxWidth: '460px',

            '& .MuiAlert-message': {
              minWidth: 'fit-content',
            },
          },
        },
      },
    },
  },
};
