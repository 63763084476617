import { isEmpty, isNil, omitBy } from 'lodash';

export const springConfig = key => {
  switch (key) {
    case 'bgOpacity':
      return { duration: 300 };
    default:
      return { mass: 4, friction: 44, tension: 180, duration: 300 };
  }
};

export const formatEmployeeWithholdingPayload = (fieldValues, parameters) => {
  const keys = parameters.reduce((memo, param) => {
    memo[param.name] = true;
    return memo;
  }, {});

  const validateValue = (value, key) =>
    isNil(value) || isEmpty(value) || !keys[key];

  return Object.keys(omitBy(fieldValues, validateValue)).map(key => ({
    name: key,
    value: fieldValues[key],
  }));
};
