import * as routes from 'api';

import { fetchTimeOffs } from 'actions/timeOff';
import { fetchPageData } from 'actions/timeOffsDashboard';

import { buildGetDrawerOpen } from 'selectors/drawers';
import { getIsFetching } from 'selectors/timeOff';

import { NOTIFICATIONS_DRAWER_KEY } from 'features/drawers/keys';
import ScheduleBuilderTimeOff from 'features/scheduleBuilder/ScheduleBuilderView/resources/TimeOff';

import { df, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import {
  createAsyncGetAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';
import * as routerUtil from 'util/router';

export const actionTypes = {
  TOGGLE_SHOW_NOTIFICATIONS: 'NOTIFICATIONS/TOGGLE_SHOW_NOTIFICATIONS',

  FETCH_NOTIFICATIONS_REQUEST: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_REQUEST',
  FETCH_NOTIFICATIONS_SUCCESS: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_SUCCESS',
  FETCH_NOTIFICATIONS_FAILURE: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_FAILURE',
  MANAGER_APPROVE_TIMEOFF_REQUEST:
    'NOTIFICATIONS/MANAGER_APPROVE_TIMEOFF_REQUEST',
  MANAGER_APPROVE_TIMEOFF_SUCCESS:
    'NOTIFICATIONS/MANAGER_APPROVE_TIMEOFF_SUCCESS',
  MANAGER_APPROVE_TIMEOFF_FAILURE:
    'NOTIFICATIONS/MANAGER_APPROVE_TIMEOFF_FAILURE',

  MANAGER_REJECT_TIMEOFF_REQUEST:
    'NOTIFICATIONS/MANAGER_REJECT_TIMEOFF_REQUEST',
  MANAGER_REJECT_TIMEOFF_SUCCESS:
    'NOTIFICATIONS/MANAGER_REJECT_TIMEOFF_SUCCESS',
  MANAGER_REJECT_TIMEOFF_FAILURE:
    'NOTIFICATIONS/MANAGER_REJECT_TIMEOFF_FAILURE',

  MANAGER_APPROVE_TRADE_REQUEST: 'NOTIFICATIONS/MANAGER_APPROVE_TRADE_REQUEST',
  MANAGER_APPROVE_TRADE_SUCCESS: 'NOTIFICATIONS/MANAGER_APPROVE_TRADE_SUCCESS',
  MANAGER_APPROVE_TRADE_FAILURE: 'NOTIFICATIONS/MANAGER_APPROVE_TRADE_FAILURE',

  MANAGER_REJECT_AVAILABILITY_REQUEST:
    'NOTIFICATIONS/MANAGER_REJECT_AVAILABILITY_REQUEST',
  MANAGER_REJECT_AVAILABILITY_SUCCESS:
    'NOTIFICATIONS/MANAGER_REJECT_AVAILABILITY_SUCCESS',
  MANAGER_REJECT_AVAILABILITY_FAILURE:
    'NOTIFICATIONS/MANAGER_REJECT_AVAILABILITY_FAILURE',

  MANAGER_APPROVE_AVAILABILITY_REQUEST:
    'NOTIFICATIONS/MANAGER_APPROVE_AVAILABILITY_REQUEST',
  MANAGER_APPROVE_AVAILABILITY_SUCCESS:
    'NOTIFICATIONS/MANAGER_APPROVE_AVAILABILITY_SUCCESS',
  MANAGER_APPROVE_AVAILABILITY_FAILURE:
    'NOTIFICATIONS/MANAGER_APPROVE_AVAILABILITY_FAILURE',

  MANAGER_REJECT_TRADE_REQUEST: 'NOTIFICATIONS/MANAGER_REJECT_TRADE_REQUEST',
  MANAGER_REJECT_TRADE_SUCCESS: 'NOTIFICATIONS/MANAGER_REJECT_TRADE_SUCCESS',
  MANAGER_REJECT_TRADE_FAILURE: 'NOTIFICATIONS/MANAGER_REJECT_TRADE_FAILURE',

  DISMISS_SHIFT_TRADE_UPSELL: 'NOTIFICATIONS/DISMISS_SHIFT_TRADE_UPSELL',

  ACCEPT_TRADE_REQUEST: 'NOTIFICATIONS/ACCEPT_TRADE_REQUEST',
  ACCEPT_TRADE_SUCCESS: 'NOTIFICATIONS/ACCEPT_TRADE_SUCCESS',
  ACCEPT_TRADE_FAILURE: 'NOTIFICATIONS/ACCEPT_TRADE_FAILURE',

  DECLINE_TRADE_REQUEST: 'NOTIFICATIONS/DECLINE_TRADE_REQUEST',
  DECLINE_TRADE_SUCCESS: 'NOTIFICATIONS/DECLINE_TRADE_SUCCESS',
  DECLINE_TRADE_FAILURE: 'NOTIFICATIONS/DECLINE_TRADE_FAILURE',

  READ_REQUEST_REQUEST: 'NOTIFICATIONS/READ_REQUEST_REQUEST',
  READ_REQUEST_SUCCESS: 'NOTIFICATIONS/READ_REQUEST_SUCCESS',
  READ_REQUEST_FAILURE: 'NOTIFICATIONS/READ_REQUEST_FAILURE',

  READ_HIRING_JOB_APPLICANT_ALERT_REQUEST:
    'NOTIFICATIONS/READ_HIRING_JOB_APPLICANT_ALERT_REQUEST',
  READ_HIRING_JOB_APPLICANT_ALERT_SUCCESS:
    'NOTIFICATIONS/READ_HIRING_JOB_APPLICANT_ALERT_SUCCESS',
  READ_HIRING_JOB_APPLICANT_ALERT_FAILURE:
    'NOTIFICATIONS/READ_HIRING_JOB_APPLICANT_ALERT_FAILURE',

  APPROVE_AVAILABILITY: 'NOTIFICATIONS/APPROVE_AVAILABILITY',

  DISMISS_SHIFT_ALERT_REQUEST: 'NOTIFICATIONS/DISMISS_SHIFT_ALERT_REQUEST',
  DISMISS_SHIFT_ALERT_SUCCESS: 'NOTIFICATIONS/DISMISS_SHIFT_ALERT_SUCCESS',
  DISMISS_SHIFT_ALERT_FAILURE: 'NOTIFICATIONS/DISMISS_SHIFT_ALERT_FAILURE',

  DISMISS_WAGE_RATE_ALERT_REQUEST:
    'NOTIFICATIONS/DISMISS_WAGE_RATE_ALERT_REQUEST',
  DISMISS_WAGE_RATE_ALERT_SUCCESS:
    'NOTIFICATIONS/DISMISS_WAGE_RATE_ALERT_SUCCESS',
  DISMISS_WAGE_RATE_ALERT_FAILURE:
    'NOTIFICATIONS/DISMISS_WAGE_RATE_ALERT_FAILURE',

  APPROVE_CLAIM_REQUEST: 'NOTIFICATIONS/APPROVE_CLAIM_REQUEST',

  APPROVE_SHIFT_REQUEST: 'NOTIFICATIONS/APPROVE_SHIFT_REQUEST',
  APPROVE_SHIFT_SUCCESS: 'NOTIFICATIONS/APPROVE_SHIFT_SUCCESS',
  APPROVE_SHIFT_FAILURE: 'NOTIFICATIONS/APPROVE_SHIFT_FAILURE',

  REJECT_SHIFT_REQUEST: 'NOTIFICATIONS/REJECT_SHIFT_REQUEST',
  REJECT_SHIFT_SUCCESS: 'NOTIFICATIONS/REJECT_SHIFT_SUCCESS',
  REJECT_SHIFT_FAILURE: 'NOTIFICATIONS/REJECT_SHIFT_FAILURE',

  DISMISS_CLOCK_OUT_APPROVAL_REQUEST:
    'NOTIFICATIONS/DISMISS_CLOCK_OUT_APPROVAL_REQUEST',
  DISMISS_CLOCK_OUT_APPROVAL_SUCCESS:
    'NOTIFICATIONS/DISMISS_CLOCK_OUT_APPROVAL_SUCCESS',
  DISMISS_CLOCK_OUT_APPROVAL_FAILURE:
    'NOTIFICATIONS/DISMISS_CLOCK_OUT_APPROVAL_FAILURE',
  RESOLVE_CLOCK_OUT_APPROVAL_ALERT:
    'NOTIFICATIONS/RESOLVE_CLOCK_OUT_APPROVAL_ALERT',

  DISMISS_HEALTH_ALERT_REQUEST: 'NOTIFICATIONS/DISMISS_HEALTH_ALERT_REQUEST',
  DISMISS_HEALTH_ALERT_SUCCESS: 'NOTIFICATIONS/DISMISS_HEALTH_ALERT_SUCCESS',
  DISMISS_HEALTH_ALERT_FAILURE: 'NOTIFICATIONS/DISMISS_HEALTH_ALERT_FAILURE',

  DISMISS_EMPLOYEE_EDIT_ALERT_REQUEST:
    'NOTIFICATIONS/DISMISS_EMPLOYEE_EDIT_ALERT_REQUEST',
  DISMISS_EMPLOYEE_EDIT_ALERT_SUCCESS:
    'NOTIFICATIONS/DISMISS_EMPLOYEE_EDIT_ALERT_SUCCESS',
  DISMISS_EMPLOYEE_EDIT_ALERT_FAILURE:
    'NOTIFICATIONS/DISMISS_EMPLOYEE_EDIT_ALERT_FAILURE',
};

export const fetchNotifications = (alertType, page) => {
  const meta = { requestId: 'FETCH_NOTIFICATIONS', alertType };

  return createAsyncGetAction(
    routes.notificationsRoute({ alert_type: alertType, page }),
    [
      {
        type: actionTypes.FETCH_NOTIFICATIONS_REQUEST,
        meta,
      },
      {
        type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        meta,
      },
      {
        type: actionTypes.FETCH_NOTIFICATIONS_FAILURE,
        meta,
      },
    ]
  );
};

export const toggleNotificationsShown = isShown => (dispatch, getState) =>
  dispatch({
    type: actionTypes.TOGGLE_SHOW_NOTIFICATIONS,
    payload: {
      drawerKey: NOTIFICATIONS_DRAWER_KEY,
      // Default to toggle current 'drawerOpen' value in state if no value is passed
      drawerOpen: isShown
        ? !!isShown
        : !buildGetDrawerOpen(NOTIFICATIONS_DRAWER_KEY)(getState()),
    },
  });

const handleTimeOffChange = (type, item, dispatch) => {
  if (window.location.href.match('/schedule_builder_beta')) {
    return dispatch(ScheduleBuilderTimeOff.fetch({ id: item.get('_id') }));
  } else if (window.location.href.match('/schedule_builder')) {
    window.Backbone.Mediator.pub(
      `schedule:time_offs:${item.get('_id')}:${type}`
    );
  } else if (window.location.href.match('/time_offs/history')) {
    return dispatch(fetchTimeOffs(routerUtil.parseQueryString()));
  } else if (window.location.href.match('/time_offs')) {
    return dispatch(fetchPageData());
  }

  return Promise.resolve();
};

const handleTradeRequestChange = () => {
  window.Backbone.Mediator.pub('schedule:navigation:rerender');
};

export const managerApproveTimeOffRequest =
  (item, itemId) => (dispatch, getState) => {
    const requestId = `TIME_OFF_APPROVE/${itemId}`;
    const meta = { item, requestId };

    return dispatch(
      withAlerts(
        createAsyncPutAction(
          routes.approveTimeOffRoute(itemId),
          [
            { type: actionTypes.MANAGER_APPROVE_TIMEOFF_REQUEST, meta },
            { type: actionTypes.MANAGER_APPROVE_TIMEOFF_SUCCESS, meta },
            { type: actionTypes.MANAGER_APPROVE_TIMEOFF_FAILURE, meta },
          ],
          {
            bailout: state => getIsFetching(state, { id: requestId }),
          }
        ),
        {
          success: toI18n('notifications.approved'),
          error: toI18n('notifications.error_msg'),
        }
      )
    ).then(() => handleTimeOffChange('approve', item, dispatch, getState()));
  };

export const managerRejectTimeOffRequest =
  (item, itemId) => (dispatch, getState) => {
    const requestId = `TIME_OFF_REJECT/${itemId}`;
    const meta = { item, requestId };
    return dispatch(
      withAlerts(
        createAsyncPutAction(routes.declineTimeOffRoute(itemId), [
          { type: actionTypes.MANAGER_REJECT_TIMEOFF_REQUEST, meta },
          { type: actionTypes.MANAGER_REJECT_TIMEOFF_SUCCESS, meta },
          { type: actionTypes.MANAGER_REJECT_TIMEOFF_FAILURE, meta },
        ]),
        {
          success: toI18n('notifications.rejected'),
          error: toI18n('notifications.error_msg'),
        }
      )
    ).then(() => handleTimeOffChange('reject', item, dispatch, getState()));
  };

export const dismissShiftTradeUpsell = item => ({
  type: actionTypes.DISMISS_SHIFT_TRADE_UPSELL,
  meta: { item },
});

export const managerApproveTradeRequest = ({
  id,
  item,
  shouldRenderShiftTradeUpsell,
  alertOptions = {},
}) =>
  withAlerts(
    createAsyncPutAction(routes.approveTradeRequestRoute(id), [
      {
        type: actionTypes.MANAGER_APPROVE_TRADE_REQUEST,
        meta: { item, shouldRenderShiftTradeUpsell },
      },
      { type: actionTypes.MANAGER_APPROVE_TRADE_SUCCESS, meta: { item } },
      { type: actionTypes.MANAGER_APPROVE_TRADE_FAILURE, meta: { item } },
    ]),
    {
      success: toI18n('notifications.approved'),
      error: toI18n('notifications.error_msg'),
      onSuccess: handleTradeRequestChange,
      ...alertOptions,
    }
  );

export const managerRejectTradeRequest = ({
  id,
  item,
  shouldRenderShiftTradeUpsell,
  alertOptions = {},
}) =>
  withAlerts(
    createAsyncPutAction(routes.rejectTradeRequestRoute(id), [
      {
        type: actionTypes.MANAGER_REJECT_TRADE_REQUEST,
        meta: { item, shouldRenderShiftTradeUpsell },
      },
      { type: actionTypes.MANAGER_REJECT_TRADE_SUCCESS, meta: { item } },
      { type: actionTypes.MANAGER_REJECT_TRADE_FAILURE, meta: { item } },
    ]),
    {
      success: toI18n('notifications.rejected'),
      error: toI18n('notifications.error_msg'),
      onSuccess: handleTradeRequestChange,
      ...alertOptions,
    }
  );

export const employeeAcceptTradeRequest = item =>
  withAlerts(
    createAsyncPutAction(routes.acceptTradeRequestRoute(item.get('id')), [
      { type: actionTypes.MANAGER_REJECT_TRADE_REQUEST, meta: { item } },
      { type: actionTypes.MANAGER_REJECT_TRADE_SUCCESS, meta: { item } },
      { type: actionTypes.MANAGER_REJECT_TRADE_FAILURE, meta: { item } },
    ]),
    {
      success: toI18n('notifications.accepted'),
      error: toI18n('notifications.error_msg'),
    }
  );

export const employeeDeclineTradeRequest = item =>
  withAlerts(
    createAsyncPutAction(routes.rejectTradeRequestRoute(item.get('id')), [
      { type: actionTypes.MANAGER_REJECT_TRADE_REQUEST, meta: { item } },
      { type: actionTypes.MANAGER_REJECT_TRADE_SUCCESS, meta: { item } },
      { type: actionTypes.MANAGER_REJECT_TRADE_FAILURE, meta: { item } },
    ]),
    {
      success: toI18n('notifications.declined'),
      error: toI18n('notifications.error_msg'),
    }
  );

export const deleteAlert = item => ({
  type: actionTypes.READ_REQUEST_SUCCESS,
  meta: { item },
});

export const readRequest = item =>
  withAlerts(
    createAsyncPutAction(routes.readAlertRoute(item.get('id')), [
      { type: actionTypes.READ_REQUEST_REQUEST, meta: { item } },
      { type: actionTypes.READ_REQUEST_SUCCESS, meta: { item } },
      { type: actionTypes.READ_REQUEST_FAILURE, meta: { item } },
    ]),
    {
      error: toI18n('notifications.error_msg'),
    }
  );

export const readHiringJobApplicantAlert = (item, redirect) =>
  withAlerts(
    createAsyncPutAction(routes.readHiringJobApplicantRoute(item.get('id')), [
      {
        type: actionTypes.READ_HIRING_JOB_APPLICANT_ALERT_REQUEST,
        meta: { item },
      },
      {
        type: actionTypes.READ_HIRING_JOB_APPLICANT_ALERT_SUCCESS,
        meta: { item },
      },
      {
        type: actionTypes.READ_HIRING_JOB_APPLICANT_ALERT_FAILURE,
        meta: { item },
      },
    ]),
    {
      error: toI18n('notifications.error_msg'),
      onSuccess: () => {
        if (redirect) {
          window.Homebase.RailsReactBridge.navigateToReactView(
            item.get('view_applicant_link')
          );
        }
      },
    }
  );

export const approveClaimRequest = id => ({
  type: actionTypes.APPROVE_CLAIM_REQUEST,
  payload: { id },
});

export const approveAvailabilityAlert = id => ({
  type: actionTypes.APPROVE_AVAILABILITY,
  payload: { id },
});

export const resolveClockOutApprovalAlert = shiftId => ({
  type: actionTypes.RESOLVE_CLOCK_OUT_APPROVAL_ALERT,
  payload: { shiftId },
});

export const dismissShiftAlert = item =>
  withAlerts(
    createAsyncPutAction(routes.rejectShiftRoute(item.get('id')), [
      { type: actionTypes.DISMISS_SHIFT_ALERT_REQUEST, meta: { item } },
      { type: actionTypes.DISMISS_SHIFT_ALERT_SUCCESS, meta: { item } },
      { type: actionTypes.DISMISS_SHIFT_ALERT_FAILURE, meta: { item } },
    ]),
    {
      error: toI18n('notifications.error_msg'),
    }
  );

export const dismissWageRateAlert = item =>
  withAlerts(
    createAsyncPutAction(routes.jobsIgnoreEmptyWageRoute(item.get('id')), [
      { type: actionTypes.DISMISS_WAGE_RATE_ALERT_REQUEST, meta: { item } },
      { type: actionTypes.DISMISS_WAGE_RATE_ALERT_SUCCESS, meta: { item } },
      { type: actionTypes.DISMISS_WAGE_RATE_ALERT_FAILURE, meta: { item } },
    ]),
    {
      error: toI18n('notifications.error_msg'),
    }
  );

export const editShiftAlert = item => dispatch => {
  withAlerts(
    createAsyncPutAction(routes.approveShiftRoute(item.get('id')), [
      { type: actionTypes.APPROVE_SHIFT_REQUEST, meta: { item } },
      { type: actionTypes.APPROVE_SHIFT_SUCCESS, meta: { item } },
      { type: actionTypes.APPROVE_SHIFT_FAILURE, meta: { item } },
    ]),
    {
      error: toI18n('notifications.error_msg'),
      onSuccess: () => {
        const jobId = item.get('job_id');
        const date = moment(item.get('scheduled_start_date')).format(
          df('parsable_dashed')
        );
        window.location = `/timesheets#detailed/${jobId}/${date}/${date}`;
      },
    }
  )(dispatch);
};

export const dismissClockOutApprovalAlert = item =>
  withAlerts(
    createAsyncPutAction(
      routes.dismissClockOutAlertRoute(item.get('shift_id')),
      [
        {
          type: actionTypes.DISMISS_CLOCK_OUT_APPROVAL_REQUEST,
          meta: { item },
        },
        {
          type: actionTypes.DISMISS_CLOCK_OUT_APPROVAL_SUCCESS,
          meta: { item },
        },
        {
          type: actionTypes.DISMISS_CLOCK_OUT_APPROVAL_FAILURE,
          meta: { item },
        },
      ]
    ),
    {
      error: toI18n('notifications.error_msg'),
    }
  );

export const dismissHealthAlert = item =>
  withAlerts(
    createAsyncPutAction(routes.dismissHealthAlertRoute(item.get('id')), [
      { type: actionTypes.DISMISS_HEALTH_ALERT_REQUEST, meta: { item } },
      { type: actionTypes.DISMISS_HEALTH_ALERT_SUCCESS, meta: { item } },
      { type: actionTypes.DISMISS_HEALTH_ALERT_FAILURE, meta: { item } },
    ]),
    {
      error: toI18n('notifications.error_msg'),
    }
  );

export const dismissEmployeeEditAlert = item =>
  withAlerts(
    createAsyncPutAction(
      routes.updateEmployeeEditAlertRoute(item.get('id')),
      [
        {
          type: actionTypes.DISMISS_EMPLOYEE_EDIT_ALERT_REQUEST,
          meta: { item },
        },
        {
          type: actionTypes.DISMISS_EMPLOYEE_EDIT_ALERT_SUCCESS,
          meta: { item },
        },
        {
          type: actionTypes.DISMISS_EMPLOYEE_EDIT_ALERT_FAILURE,
          meta: { item },
        },
      ],
      {
        body: {
          timecard_employee_edit: { dismissed: true },
        },
      }
    ),
    {
      error: toI18n('notifications.error_msg'),
    }
  );
