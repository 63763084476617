import React from 'react';
import Icon from 'react-icon-base';

const Coffee = ({ color, height, width, ...props }) => (
  <Icon
    fill={color || 'none'}
    height={height || '16'}
    width={width || '16'}
    viewBox="0 0 18 20"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <use fill={color || '#313A43'} xlinkHref="#a-coffee" />
      <defs>
        <path
          id="a-coffee"
          fill={color || '#8A5EA6'}
          d="M17.06 15.29c0 1.175-.956 2.13-2.13 2.13h-1.304V9.753h1.304c1.174 0 2.13.955 2.13 2.13v3.406zM3.405 13.187h2.555v-2.555H3.406v2.555zm9.37 4.968c0 .539-.586.993-1.278.993H2.129c-.693 0-1.277-.454-1.277-.993V7.225h3.37V9.78H2.982a.425.425 0 0 0-.426.426v3.407c0 .236.19.425.426.425h3.407c.235 0 .425-.19.425-.425v-3.407a.426.426 0 0 0-.425-.426H5.074V7.225h7.701v10.93zm2.155-9.253h-1.304V6.373H0v11.782C0 19.173.955 20 2.13 20h9.368c1.128 0 2.045-.767 2.115-1.73h1.318a2.984 2.984 0 0 0 2.98-2.98v-3.407a2.984 2.984 0 0 0-2.98-2.981zM5.94 5.11h.032c.22 0 .407-.17.424-.394.042-.56.52-1.037.667-1.17.117-.107.583-.483 1.405-.436.827-.003 1.3-.341 1.604-.61.439-.387.846-1.193.546-2.197A.427.427 0 0 0 9.8.55c.185.615-.042 1.092-.294 1.313-.239.212-.545.388-1.022.396-1.109-.015-1.772.455-1.996.66-.262.238-.879.885-.943 1.735a.427.427 0 0 0 .394.456z"
        />
      </defs>
    </g>
  </Icon>
);

export default Coffee;
