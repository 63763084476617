import { createSelector } from 'reselect';

import { buildGetDrawerOpen } from 'selectors/drawers';

import { EMPTY_SCHEDULE_DRAWER_KEY } from 'features/drawers/keys';
import { LAST_PUBLISHED_ID } from 'features/scheduleBuilder/constants';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

export const getShowShiftNotesContextualUpsell = state =>
  state.getIn(['scheduleBuilder', 'general', 'showShiftNotesContextualUpsell']);

export const getCurrentTemplateId = state =>
  state.getIn(['scheduleBuilder', 'general', 'currentTemplateId']);

const buildPublishDrawerSelector = key => state =>
  state.getIn(['scheduleBuilder', 'publishDrawer', key]);
const buildPublishDrawerTemplateSelector = key => state =>
  state.getIn(['scheduleBuilder', 'publishDrawer', 'template', key]);

export const getSchedulePublished =
  buildPublishDrawerSelector('schedulePublished');

export const getTemplateId = buildPublishDrawerTemplateSelector('id');
export const getTemplateName = buildPublishDrawerTemplateSelector('name');
export const getTemplateIsChanged =
  buildPublishDrawerSelector('templateIsChanged');

export const getHasCreatedNote = buildPublishDrawerSelector('hasCreatedNote');

const buildShiftsSyncDrawerSelector = key => state =>
  state.getIn(['scheduleBuilder', 'shiftsSyncDrawer', key]);

export const getStartDateShiftsSyncDrawer =
  buildShiftsSyncDrawerSelector('startDate');
export const getEndDateShiftsSyncDrawer =
  buildShiftsSyncDrawerSelector('endDate');
export const getErrorsCountShiftsSyncDrawer =
  buildShiftsSyncDrawerSelector('errorsCount');

const buildPublishDataSelector = key => state =>
  state.getIn(['scheduleBuilder', 'publishDrawer', 'data', key]);

export const getPublishStartDate = buildPublishDataSelector('startDate');
export const getPublishEndDate = buildPublishDataSelector('endDate');
export const getPublishStats = buildPublishDataSelector('stats');
export const getPublishDepartmentId = buildPublishDataSelector('departmentId');
export const getHasUnpublishedShifts = buildPublishDataSelector(
  'hasUnpublishedShifts'
);
export const getHasAlreadyPublishedShifts = buildPublishDataSelector(
  'hasAlreadyPublished'
);
export const getHasConflicts = buildPublishDataSelector('hasConflicts');
export const getWithoutContactInfoCount = buildPublishDataSelector(
  'withoutContactInfoCount'
);
export const getDepartmentsCount = buildPublishDataSelector('departmentsCount');

const buildEmptyScheduleDrawerSelector = key => state =>
  state.getIn(['scheduleBuilder', 'emptyScheduleDrawer', key]);

export const getShowEmptyScheduleDrawer = createSelector(
  buildGetDrawerOpen(EMPTY_SCHEDULE_DRAWER_KEY),
  buildEmptyScheduleDrawerSelector('hasBeenClosed'),
  (isShown, hasBeenClosed) => isShown && !hasBeenClosed
);

export const getEmptyScheduleDrawerDate =
  buildEmptyScheduleDrawerSelector('date');
export const getScheduleTemplates =
  buildEmptyScheduleDrawerSelector('templates');
export const getTemplatesIsFetching = buildEmptyScheduleDrawerSelector(
  'templatesIsFetching'
);
export const getLastPublishedSchedule = buildEmptyScheduleDrawerSelector(
  'lastPublishedSchedule'
);

const buildPrintDrawerSelector = key => state =>
  state.getIn(['scheduleBuilder', 'printDrawer', key]);

export const getStartDatePrintDrawer = buildPrintDrawerSelector('startDate');
export const getEndDatePrintDrawer = buildPrintDrawerSelector('endDate');
export const getViewTypePrintDrawer = buildPrintDrawerSelector('viewType');

export const getPublishDateRange = createSelector(
  getPublishStartDate,
  getPublishEndDate,
  (startDate, endDate) => {
    if (startDate.format('MM') === endDate.format('MM')) {
      return `${startDate.format('MMM D')} - ${endDate.format('D')}`;
    }

    return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}`;
  }
);

export const getLastPublishedOptionLabel = createSelector(
  getLastPublishedSchedule,
  lastPublishedSchedule => {
    if (!lastPublishedSchedule) {
      return null;
    }

    const date = moment(lastPublishedSchedule, 'MM/DD/YYYY');
    const startDate = date.clone().startOf('week').format('MMM DD');
    const endDate = date.clone().endOf('week').format('MMM DD');

    return `${startDate} - ${endDate}`;
  }
);

export const getEmptyScheduleTemplateOptions = createSelector(
  getScheduleTemplates,
  getLastPublishedOptionLabel,
  (templates, lastPublishedOptionLabel) => {
    const options = templates.map(template => ({
      label: template.get('name'),
      value: template.get('id'),
    }));

    if (!lastPublishedOptionLabel) {
      return options;
    }

    const lastPublishedScheduleOption = {
      value: LAST_PUBLISHED_ID,
      label: `${lastPublishedOptionLabel} (${toI18n(
        'schedule_builder.empty_state.last_published'
      )})`,
    };

    // Insert the "Last Published" option at array index 1
    // If options is empty, "Last Published" becomes the only option
    return options.splice(1, 0, lastPublishedScheduleOption);
  }
);
