const EVENT_NAME = 'stripe.checkout.drawer.cta.confirmAndPay.click';
export const subscribeToOnConfirmAndPay = (listener: () => void) => {
  document.addEventListener(EVENT_NAME, listener);
};

export const unsubscribeFromOnConfirmAndPay = (listener: () => void) => {
  document.removeEventListener(EVENT_NAME, listener);
};

export const publishOnConfirmAndPay = () => {
  document.dispatchEvent(new CustomEvent(EVENT_NAME));
};
