import { ACCESS_LEVEL_OPTIONS } from 'features/team/constants';

import { toI18n } from 'util/i18n';

const I18N_ACCESS_LEVELS_PATH = 'team.access_levels';

export const removeGeneralManagerOption = options =>
  options.filter(
    option =>
      option.value !== toI18n(`${I18N_ACCESS_LEVELS_PATH}.general_manager`)
  );

export const removeEmployeeOption = options =>
  options.filter(
    option => option.value !== toI18n(`${I18N_ACCESS_LEVELS_PATH}.employee`)
  );

export const getAccessLevelOptions = (
  isOwnerProfile,
  currentUserJobAccessLevels,
  editLocationId,
  isCurrentUserOwner,
  hasGMFeatureAccess
) => {
  let options = [];

  if (hasGMFeatureAccess) {
    options = ACCESS_LEVEL_OPTIONS;
  } else {
    options = removeGeneralManagerOption(ACCESS_LEVEL_OPTIONS);
  }

  if (isCurrentUserOwner) {
    return options;
  }

  if (isOwnerProfile) {
    options = removeEmployeeOption(options);
  } else {
    const managerialAccessForEditLocation = currentUserJobAccessLevels
      .toJS()
      .filter(al => al.location_id === editLocationId);

    const isManagerialAccessManager =
      managerialAccessForEditLocation[0]?.access_level ===
      toI18n(`${I18N_ACCESS_LEVELS_PATH}.manager`);

    if (isManagerialAccessManager) {
      options = removeGeneralManagerOption(options);
    }
  }

  return options;
};
