import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMinus = ({
  alt = 'Minus Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M24 11.996a1.844 1.844 0 0 1-1.846 1.847H1.846A1.844 1.844 0 0 1 0 11.996c0-1.02.825-1.846 1.846-1.846h20.308c1.021 0 1.846.825 1.846 1.846Z"
    />
  </svg>
);
export default SvgMinus;
