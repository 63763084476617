import getMuiTheme from 'material-ui/styles/getMuiTheme';

import { colors, dimensions, typography } from './constants';

// All options: https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js
const muiTheme = getMuiTheme({
  spacing: {
    iconSize: parseInt(dimensions.lg, 10),
    desktopGutter: parseInt(dimensions.lg, 10),
    desktopGutterMore: parseInt(dimensions.xl, 10),
    desktopGutterLess: parseInt(dimensions.sm, 10),
    desktopGutterMini: parseInt(dimensions.xxs, 10),
    desktopKeylineIncrement: parseInt(dimensions.xxxl, 10),
    desktopDropDownMenuItemHeight: parseInt(dimensions.xl, 10),
    desktopDropDownMenuFontSize: parseInt(dimensions.sm, 10),
    desktopDrawerMenuItemHeight: parseInt(dimensions.xxl, 10),
    desktopSubheaderHeight: parseInt(dimensions.xxl, 10),
    desktopToolbarHeight: parseInt(dimensions.xxxl, 10),
  },
  fontFamily: typography.baseFontFamily,
  borderRadius: dimensions.borderRadius,
  palette: {
    primary1Color: colors.navy,
    primary2Color: colors.blue,
    primary3Color: colors.grayDark,
    accent1Color: colors.purple,
    accent2Color: colors.grayLight,
    accent3Color: colors.grayDark,
    textColor: colors.navy,
    secondaryTextColor: colors.navyDark,
    alternateTextColor: colors.white,
    canvasColor: colors.white,
    borderColor: colors.grayDark,
    disabledColor: colors.grayLight,
    pickerHeaderColor: colors.navy,
    clockCircleColor: colors.navyDark,
    shadowColor: colors.black,
  },
  checkbox: {
    boxColor: colors.grayDark,
    checkedColor: colors.green,
    requiredColor: colors.navy,
    disabledColor: colors.grayLight,
    labelColor: colors.navy,
    labelDisabledColor: colors.grayLight,
  },
  radioButton: {
    borderColor: colors.grayDark,
    checkedColor: colors.purple,
  },
  radio: {
    borderColor: colors.grayDark,
    checkedColor: colors.purple,
  },
  circularProgress: {
    color: colors.blue,
  },
  stepper: {
    iconColor: colors.purple,
    textColor: colors.purple,
    inactiveIconColor: colors.grayMediumDark,
    disabledTextColor: colors.grayMediumDark,
    connectorLineColor: colors.grayMedium,
    hoverBackgroundColor: colors.grayLight,
  },
});

export default muiTheme;
