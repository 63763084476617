import React, { ReactNode } from 'react';
import parse from 'autosuggest-highlight/parse/';
import Button from 'fe-design-base/molecules/Button/Button';
import { typography } from 'theme/constants';

import { Option as OptionType } from './Autocomplete';
interface DropdownItemProps {
  option: OptionType;
  props: React.HTMLAttributes<HTMLLIElement>;
  key: string;
  noOptionsTextNode?: ReactNode;
  parts: ReturnType<typeof parse>;
}

const DropdownItem = ({ props, option, parts }: DropdownItemProps) => {
  const { value, buttonProps, noOptionsTextNode } = option;

  if (noOptionsTextNode) {
    return (
      <li {...props} style={{ opacity: 1 }}>
        {noOptionsTextNode}
      </li>
    );
  }

  if (buttonProps) {
    return (
      <li {...props} key={value}>
        <Button {...buttonProps} variant="tertiary" />
      </li>
    );
  }

  return (
    <li {...props} key={value}>
      <div>
        {parts.map((part, index) => (
          <span
            key={`${part.text}-${index}`}
            style={{
              fontWeight: part.highlight ? typography.fw700 : 'inherit',
            }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </li>
  );
};

export default DropdownItem;
