import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRoute } from 'api';
import { putJSON } from 'api/fetch';
import { useIsMediumScreen } from 'fe-design-base/utils/useMediaQuery';
import { Field, Form, Formik } from 'formik';
import { startCase } from 'lodash';
import * as Yup from 'yup';

import { closePasswordResetModal } from 'actions/session';

import { getResetPasswordToken } from 'selectors/session';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import BasicTextField from 'components/forms/BasicTextField';
import Modal from 'components/modals/Modal';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { PASSWORD_REGEX_V3 } from 'util/validators';

const { cx } = cxHelpers('PasswordResetModal');
export const ResetPasswordFormSchema = Yup.object({
  password: Yup.string()
    .trim()
    .matches(PASSWORD_REGEX_V3, toI18n('onboarding.v3.password.errors.valid'))
    .required(toI18n('onboarding.v3.password.errors.required')),
  passwordConfirmation: Yup.string()
    .trim()
    .matches(PASSWORD_REGEX_V3, toI18n('onboarding.v3.password.errors.valid'))
    .required(toI18n('onboarding.v3.password.errors.required'))
    .oneOf(
      [Yup.ref('password')],
      toI18n('onboarding.password.forgot.modal.match')
    ),
});

const PasswordResetModal = () => {
  const dispatch = useDispatch();
  const resetPasswordToken = useSelector(getResetPasswordToken);

  const [isOpen, setIsOpen] = useState(true);

  const isMobile = !useIsMediumScreen();

  const handleClose = useCallback(() => {
    setIsOpen(false);
    dispatch(closePasswordResetModal());
  }, [dispatch]);

  const handleSubmit = useCallback(
    ({ password, passwordConfirmation }, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      putJSON(resetPasswordRoute(), {
        password,
        reset_password_token: resetPasswordToken,
        password_confirmation: passwordConfirmation,
      })
        .then(() => setIsOpen(false))
        .catch(error => {
          error?.response.json().then(data => {
            if (data && data.errors) {
              Object.entries(data.errors).forEach(([attribute, errors]) => {
                let errorMessage;

                if (attribute === 'reset_password_token') {
                  errorMessage = toI18n(
                    'onboarding.password.forgot.modal.generic_error_message'
                  );
                } else {
                  errorMessage = `${startCase(attribute)} ${errors.join(
                    ' and '
                  )}`;
                }
                setFieldError('password', errorMessage);
              });
            }
          });
        })
        .finally(() => setSubmitting(false));
    },
    [resetPasswordToken]
  );

  return (
    <Modal
      auto
      isOpen={isOpen}
      onRequestClose={handleClose}
      fullScreenAtMobile={isMobile}
    >
      <Formik
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={ResetPasswordFormSchema}
      >
        {({ isValid, isSubmitting, touched, errors }) => (
          <Form>
            <Box
              pa16
              borderbox
              column
              hcenter
              maxw={600}
              className={cx()}
              mt={isMobile ? 30 : 0}
            >
              <Box mb8 tcenter>
                <Text
                  fs24
                  fw600
                  navy
                  i18n="onboarding.password.forgot.modal.header"
                />
              </Box>
              <Box mb24 tcenter>
                <Text fs16 navy i18n="onboarding.password.forgot.modal.copy" />
              </Box>
              <Box mb8 w="100%" h={75}>
                <Field
                  as={BasicTextField}
                  name="password"
                  type="password"
                  theme="bordered"
                  placeholder={toI18n(
                    'onboarding.password.forgot.modal.set_password'
                  )}
                  errorText={touched.password && errors.password}
                />
              </Box>
              <Box mb8 w="100%" h={75}>
                <Field
                  as={BasicTextField}
                  name="passwordConfirmation"
                  type="password"
                  theme="bordered"
                  placeholder={toI18n(
                    'onboarding.password.forgot.modal.confirm_password'
                  )}
                  errorText={
                    touched.passwordConfirmation && errors.passwordConfirmation
                  }
                />
              </Box>
              <Box w="100%">
                <Button
                  fullWidth
                  type="submit"
                  size="large"
                  theme="primary-purple"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                >
                  <Text
                    fs18
                    fw600
                    i18n="onboarding.password.forgot.modal.button"
                  />
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PasswordResetModal;
