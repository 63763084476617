import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgBroken = ({
  alt = 'Broken Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M20.355 11.154c.308 0 .614-.117.848-.351l2.355-2.355a1.2 1.2 0 1 0-1.697-1.697l-2.355 2.355a1.2 1.2 0 0 0 0 1.697c.279.281.584.351.849.351Zm-4.4-1.6c.662 0 1.155-.537 1.155-1.2v-4c.045-.617-.45-1.155-1.155-1.155-.705 0-1.2.538-1.2 1.155V8.31c0 .75.58 1.245 1.2 1.245Zm-5.205 1.25c.235.28.54.35.805.35s.614-.117.848-.351a1.2 1.2 0 0 0 0-1.697L10.005 6.75a1.2 1.2 0 1 0-1.697 1.697l2.442 2.356Zm18.375 2.315c-2.183-.825-4.678.013-5.815 1.96-.147.252-.47.382-.72.354l-3.925-.878a1.193 1.193 0 0 0-1.433.907c-.145.647.261 1.29.908 1.433l3.993.892c1.29.21 2.608-.392 3.258-1.513.547-.936 1.782-1.325 2.887-.912a2.039 2.039 0 0 1 1.29 2.284l-.037.189c-.142.747-.734 1.346-1.553 1.568-.672.19-1.16.725-1.273 1.355a1.724 1.724 0 0 0 .737 1.719c.653.444.974 1.17.835 1.855l-.033.18c-.192 1.003-1.086 1.756-2.128 1.793-1.11.006-2.136-.672-2.321-1.665l-.01-.066c-.155-1.277-1.225-2.28-2.456-2.477l-5.325-1.31-5.23 1.291c-1.326.216-2.351 1.219-2.548 2.483l-.016.089c-.19.988-1.182 1.714-2.32 1.653-1.04-.036-1.934-.789-2.125-1.786l-.036-.19c-.137-.726.183-1.452.846-1.902a1.72 1.72 0 0 0 .728-1.713 1.754 1.754 0 0 0-1.281-1.401c-.811-.22-1.403-.82-1.545-1.57l-.04-.092a2.038 2.038 0 0 1 1.288-2.289c1.099-.41 2.34-.023 2.89.919.645 1.115 1.95 1.73 3.32 1.495l3.924-.878a1.197 1.197 0 0 0 .908-1.432 1.195 1.195 0 0 0-1.433-.908l-3.854.863c-.335.055-.645-.09-.795-.355-1.13-1.935-3.63-2.77-5.808-1.945a4.433 4.433 0 0 0-2.798 4.984l.036.183c.247 1.296 1.07 2.386 2.234 3.018a4.228 4.228 0 0 0-.983 3.556l.036.193c.399 2.092 2.249 3.665 4.397 3.74 2.337.13 4.43-1.514 4.785-3.726l.011-.061c.052-.235.277-.422.653-.486L16 23.35l4.847 1.189c.284.047.504.231.56.484l.009.044c.346 2.154 2.307 3.73 4.6 3.73.061 0 .122 0 .183-.002 2.105-.077 4-1.605 4.4-3.748l.033-.181a4.235 4.235 0 0 0-.98-3.563c1.164-.632 1.988-1.72 2.233-3.014l.037-.19a4.433 4.433 0 0 0-2.796-4.98Z"
    />
  </svg>
);
export default SvgBroken;
