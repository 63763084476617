import { datadogLogInfo } from 'util/datadogLogs';
import { omit } from 'util/objectMethods';
import { EVENT_ACTIONS } from 'util/tracking_constants';
import {
  AMPLITUDE_ACCEPT_LIST,
  AMPLITUDE_PROPERTY_EXCLUDE_LIST,
} from 'util/trackingModules/constants';

import { formatAmplitudeData } from './helpers';

const AMPLITUDE_ACCEPT_LIST_MAP = AMPLITUDE_ACCEPT_LIST.reduce(
  (result, name) => Object.assign(result, { [name]: true }),
  {}
);

const waitAmpEvent = new Promise(res =>
  document.addEventListener('amplitude-loaded', () => res(true))
);
const waitAmpTimeout = new Promise(res => setTimeout(() => res(false), 30000));
const waitAmplitude = () => Promise.race([waitAmpEvent, waitAmpTimeout]);

async function sendEvent({
  properties,
  event_action,
  event_name,
  product_area,
  event_category,
  should_fire_dd_log,
  ...otherCoreAttributes
}) {
  if (should_fire_dd_log) {
    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'amplitude-sendEvent',
        ...properties,
        ...otherCoreAttributes,
      },
    });
  }

  if (!AMPLITUDE_ACCEPT_LIST_MAP[event_name]) return;

  const payload = {
    ...formatAmplitudeData({
      event_name,
      product_area,
      event_category,
      properties,
    }),
    ...omit(otherCoreAttributes, AMPLITUDE_PROPERTY_EXCLUDE_LIST),
  };

  if (should_fire_dd_log) {
    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'payloadCreated',
        ...payload,
      },
    });
  }

  if (!window.amplitude && !(await waitAmplitude())) return;
  window.amplitude.getInstance().logEvent(event_action, payload);

  if (should_fire_dd_log) {
    datadogLogInfo({
      message: `${EVENT_ACTIONS.OWNER_SIGN_UP}-logs`,
      context: {
        scenario: 'amplitudeTrackingFired',
        ...payload,
      },
    });
  }
}

export const amplitudeIdentify = async ({
  userAgent,
  userProperties = {},
  amplitudeGroups = [],
}) => {
  if (!window.amplitude && !(await waitAmplitude())) return;

  window.amplitude.getInstance().setUserProperties({
    ...JSON.parse(userProperties),
    ...{ user_agent: userAgent, app_name: 'Web' },
  });
  amplitudeGroups.forEach(({ group_properties, group_type, group_value }) => {
    Object.entries(group_properties).forEach(([key, value]) => {
      const identify = new window.amplitude.Identify().set(key, value);
      window.amplitude
        .getInstance()
        .groupIdentify(group_type, `${group_value}`, identify);
    });
    window.amplitude.getInstance().setGroup(group_type, group_value);
  });
};

export const amplitudeSetUserId = async userId => {
  if (!window.amplitude && !(await waitAmplitude())) return;
  window.amplitude.getInstance().setUserId(userId);
};

export default {
  sendEvent,
};
