function isProduction() {
  return window.Homebase?.env === 'production';
}

function runDD(callback) {
  if (isProduction()) {
    window.DD_RUM.onReady(() => {
      callback();
    });
  }
}

// Add some context variables to datadog sessions.
// We will be able to use these variables in datadog charts
// e.g. get P95 on page load for customs that have more than 10 employees
export const ddAddContext = response => {
  if (!window.scheduleBuilderDataDogInitLoadFlag) {
    runDD(() => {
      window.DD_RUM.setUserProperty(
        'jobs_count',
        response.payload.data.relationships.jobs.data.length
      );
      window.DD_RUM.setUserProperty(
        'shifts_count',
        response.payload.data.relationships.shifts.data.length
      );
      window.DD_RUM.setUserProperty(
        'used_heap_size',
        window.performance?.memory?.usedJSHeapSize
      );
    });
  }
};

// We call this method when a week gets changed to trigger "shift rendered" event again
export const ddResetShiftRenderedEvent = () => {
  window.scheduleBuilderDataDogShiftsRenderedFlag = null;
};

export const ddResetInitLoadEvent = () => {
  window.scheduleBuilderDataDogInitLoadFlag = null;
  window.scheduleBuilderDataDogShiftsRenderedFlag = true;
};

// Add a custom timing
export const ddTrackShiftsRenderedEvent = () => {
  if (!window.scheduleBuilderDataDogInitLoadFlag) {
    // we set this flag to make sure we trigger this event ONLY when a week gets changed
    window.scheduleBuilderDataDogInitLoadFlag = true;

    runDD(() => {
      window.DD_RUM.addTiming('schedule_route_change', Date.now());
    });
  } else if (!window.scheduleBuilderDataDogShiftsRenderedFlag) {
    // we set this flag to make sure we trigger this event ONLY when a week gets changed
    window.scheduleBuilderDataDogShiftsRenderedFlag = true;

    runDD(() => {
      window.DD_RUM.addTiming('schedule_shifts_rendered', Date.now());
    });
  }
};

export const trackCustomMeasure = (name, time) => {
  if (!name || !time) {
    return;
  }

  runDD(() => {
    window.DD_RUM.addTiming(name, time);
  });
};
