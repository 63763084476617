import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box/Box';
import Text from 'fe-design-base/atoms/Text';
import PropTypes from 'prop-types';

import IconButton from 'components/clickables/IconButton';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

const { cxEl } = cxHelpers('BottomDrawer');

const CloseActionsBar = ({
  onCancelClick,
  bottomDrawerHelpers,
  headerTitle,
}) => {
  const trackUx = useTrackUx();

  const handleDrawerCancelClick = useCallback(() => {
    trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK);

    if (onCancelClick) {
      onCancelClick();
    } else {
      bottomDrawerHelpers.closeBottomDrawer();
    }
  }, [trackUx, bottomDrawerHelpers, onCancelClick]);

  return (
    <Box className={cxEl('ActionsBar')} spacebetween vcenter>
      <Box>
        {headerTitle && (
          <Text variant="heading2" color="mono900">
            {toI18n(headerTitle)}
          </Text>
        )}
      </Box>
      <IconButton
        className={cxEl('CloseButton')}
        type="mdClose"
        iconColor="grayXdark"
        size="md"
        data-testid="close-button"
        onClick={handleDrawerCancelClick}
      />
    </Box>
  );
};

const bottomDrawerHelpersShape = {
  currentStep: PropTypes.number.isRequired,
  closeBottomDrawer: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  setOnCloseClick: PropTypes.func.isRequired,
  setOnNextStepClick: PropTypes.func.isRequired,
  setOnPreviousStepClick: PropTypes.func.isRequired,
  setNextStepButtonText: PropTypes.func.isRequired,
  setPreviousStepButtonText: PropTypes.func.isRequired,
  setCloseButtonText: PropTypes.func.isRequired,
};

CloseActionsBar.propTypes = {
  bottomDrawerHelpers: PropTypes.shape(bottomDrawerHelpersShape).isRequired,
  onCancelClick: PropTypes.func,
};

export default CloseActionsBar;
