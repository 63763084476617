import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';

import cxHelpers from 'util/className';

import * as constants from '../constants';

@cxHelpers('BaseListItem')
export default class BaseListItem extends PureComponent {
  static propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    titleI18n: PropTypes.string,
    date: PropTypes.string,
    bg: PropTypes.string,
  };

  render() {
    const { icon, titleI18n, title, date, bg } = this.props;

    return (
      <Box mbxs className={this.cx()} bb bg={bg}>
        <Box ptm phxl pbxs vcenter>
          {icon && (
            <Box basis={constants.LEFT_COLUMN_WIDTH}>
              <Icon size={16} type={icon} />
            </Box>
          )}

          <Box grow>
            <Text fs14 fw700 i18n={titleI18n}>
              {title}
            </Text>
          </Box>

          <Box
            basis={constants.RIGHT_COLUMN_WIDTH}
            minw={constants.RIGHT_COLUMN_WIDTH}
            hright
          >
            <Text fs12 navyDark>
              {date}
            </Text>
          </Box>
        </Box>

        <Box pbm phxl>
          {this.props.children}
        </Box>
      </Box>
    );
  }
}
