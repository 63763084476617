import React from 'react';
import Icon from 'react-icon-base';

const ThumbsDownSelected = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M21.154 12.44l.76.393c.501.258.813.772.813 1.341 0 .873-.587 1.222-1.573 1.217l-6.286.005.632 5.267c-.001.964-.5 1.837-1.5 1.837l-.39-2.85-3.445-5.47c-.69-.993-.9-1.557-1-2.18V4.604c0-.604.667-1.448 1.443-1.448h8.63c.808 0 1.117.844 1.13 1.344.01.363-.11.66-.319.93l-.55.71h.958c.807 0 1.464.674 1.464 1.502 0 .35-.122.691-.343.961l-.391.48.572.214c.58.217.968.783.968 1.41 0 .569-.312 1.083-.814 1.342l-.76.392zm-13.467-.333c0 .338-.336.659-.689.659H1.717c-.217 0-.561-.266-.561-.66V3.762c0-.261.266-.605.56-.605h5.282c.369 0 .69.235.69.605v8.346zm15.578.339c.469-.448.735-1.07.735-1.734 0-.803-.402-1.553-1.058-1.992.165-.332.252-.7.252-1.073 0-1.065-.7-2-1.687-2.288.103-.27.155-.557.155-.854 0-1.311-1.04-2.505-2.319-2.505h-8.736c-.806 0-1.532.479-2.045 1.04A1.716 1.716 0 0 0 6.998 2H1.717C.77 2 0 2.79 0 3.76v8.347c0 .971.77 1.761 1.717 1.761h5.281c.503 0 .955-.225 1.27-.58.183.384.436.811.792 1.323l3.357 5.333.744 4.056h1.452c1.365 0 2.236-1.976 2.236-3.337l-.49-4.028 5.323-.077c1.277 0 2.318-1.067 2.318-2.379 0-.663-.266-1.286-.735-1.733z"
      clipRule="evenodd"
    />
  </Icon>
);

export default ThumbsDownSelected;
