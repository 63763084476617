import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Tooltip from 'fe-design-base/molecules/Tooltip';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('EnforcementTooltip');

const EnforcementTooltip = ({ content }) => (
  <Box
    vcenter
    hcenter
    h={24}
    w={24}
    className={cx()}
    bgcolor="primary100"
    ml={8}
    bradiuss
    pointer
  >
    <Tooltip content={content} placement="bottom" variant="mono">
      <Icon iconName="Star" color="primary700" size="small" />
    </Tooltip>
  </Box>
);

export default EnforcementTooltip;
