import { EVENT_ACTIONS } from 'util/tracking_constants';

export const AMPLITUDE_ACCEPT_LIST = ['ux', 'signup'];

export const AMPLITUDE_PROPERTY_EXCLUDE_LIST = [
  'user_id',
  'company_name',
  'location_name',
  'location_city',
  'tier_id',
  'tier_name',
  'location_count',
];

export const CREATE_EVENT_PROPERTY_EXCLUDE_LIST = [
  'user_id',
  'company_id',
  'company_name',
  'location_id',
  'location_name',
  'location_city',
  'tier_id',
  'tier_name',
  'job_level',
  'device_type',
  'company_age',
  'location_age',
  'location_count',
  'is_owner',
];

export const GTM_EVENT_NAMES_ACCEPT_LIST = ['ux'];

export const GTM_EVENT_ACTIONS_ACCEPT_LIST = [
  EVENT_ACTIONS.NEW_LOCATION_ADDED,
  EVENT_ACTIONS.OWNER_SIGN_UP,
  EVENT_ACTIONS.SCHEDULE_PUBLISHED,
  EVENT_ACTIONS.UPGRADE_SUCCESSFUL,
  EVENT_ACTIONS.SIGN_IN_SUCCESS,
  EVENT_ACTIONS.INITIAL_ACTIVATION,
  EVENT_ACTIONS.SIGNUP_1D1_CONVERSION,
  EVENT_ACTIONS.SIGNUP_2D7_CONVERSION,
  EVENT_ACTIONS.SIGNUP_2D30_CONVERSION,
];

/*
 * GTM limits the number of custom properties allowed.
 * Consult with Marketing before adding additional properties to this list.
 */
export const GTM_PROPERTY_ACCEPT_LIST = [
  'event',
  'timestamp',
  'user_id',
  'company_id',
  'company_name',
  'location_name',
  'location_city',
  'tier_id',
  'tier_name',
  'job_level',
  'location_count',
  'on_trial',
  'selected_tier_id',
  'selected_tier_name',
  'subscription_price',
  'total_pre_tax',
  'quantity',
  'activation_1d1',
  'activation_2d7',
  'activation_2d30',
  'location_id',
];
