import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCheck = ({
  alt = 'Check Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M2.667 4A2.67 2.67 0 0 0 0 6.667v10.666A2.67 2.67 0 0 0 2.667 20h18.666A2.67 2.67 0 0 0 24 17.333V6.667A2.67 2.67 0 0 0 21.333 4H2.667Zm2 6.667h6.666c.367 0 .667.3.667.666 0 .367-.3.667-.667.667H4.667A.669.669 0 0 1 4 11.333c0-.366.3-.666.667-.666ZM4 15.333c0-.366.3-.666.667-.666h14.666c.367 0 .667.3.667.666 0 .367-.3.667-.667.667H4.667A.669.669 0 0 1 4 15.333ZM15.667 8H19c.554 0 1 .446 1 1v2c0 .554-.446 1-1 1h-3.333c-.554 0-1-.446-1-1V9c0-.554.446-1 1-1Z"
    />
  </svg>
);
export default SvgCheck;
