import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

import { getCurrentLocationId } from 'selectors/session';

export const fetchProducts = createAsyncThunk('products/fetchProducts', () =>
  fetchJSON('/tier_products.json')
);

export const fetchFeatures = createAsyncThunk('products/fetchFeatures', () =>
  fetchJSON('/get_features.json')
);

export const fetchTipManagerSubscriptions = createAsyncThunk(
  'tipManager/fetchSubscriptions',
  () =>
    fetchJSON(
      'biller/product_subscriptions/index?external_identifier=tip_manager'
    )
);

export const fetchProductTipManager = createAsyncThunk(
  'products/fetchTipManagerProduct',
  (_, { getState }) =>
    fetchJSON(
      `/products/location/${getCurrentLocationId(
        getState()
      )}?external_identifier=tip_manager`
    )
);
