import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSortUp = ({
  alt = 'Sort Up Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M21.987 11.52c-.339.338-.787.505-1.236.505-.448 0-.896-.168-1.237-.503L13.75 5.868v16.463c0 .95-.784 1.669-1.701 1.669-.918 0-1.8-.72-1.8-1.67V5.869L4.488 11.52c-.684.671-1.792.671-2.475 0a1.695 1.695 0 0 1 0-2.429l8.75-8.588a1.773 1.773 0 0 1 2.475 0l8.75 8.588a1.696 1.696 0 0 1 0 2.43Z"
    />
  </svg>
);
export default SvgSortUp;
