import React, { memo } from 'react';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import baseColors from 'fe-design-base/styles/colors';

import StepLabel from './StepLabel';
import { StepIndicatorProps } from './types';

const ColorLibConnector = styled(StepConnector)(() => ({
  left: '-50%',
  right: '50%',
  fontFamily: 'Plus Jakarta Sans',
  marginTop: '2px',
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: baseColors.purple500,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: baseColors.purple500,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: baseColors.mono300,
    borderRadius: 1,
    display: 'flex',
    width: '100%',
  },
}));

const StepIndicator = ({
  steps,
  activeIndex,
  onStepClick,
  uxElement,
}: StepIndicatorProps) => (
  <Stack sx={{ width: '100%' }} spacing={4}>
    <Stepper
      alternativeLabel
      activeStep={activeIndex}
      connector={<ColorLibConnector />}
    >
      {steps.map((label, index) => {
        const isClickable = Boolean(onStepClick && index < activeIndex);

        return (
          <Step key={`${label}-${index}`}>
            <StepLabel
              onStepClick={isClickable ? onStepClick : undefined}
              label={label}
              index={index}
              uxElement={uxElement}
            />
          </Step>
        );
      })}
    </Stepper>
  </Stack>
);

export default memo(StepIndicator);
