import { Overrides } from '../../types';
import baseColors from '../colors';
import { gutters } from '../utils';

export const inputFieldOverrides: Overrides = {
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& .MuiSelect-select': {
          overflowX: 'auto !important',
          '::-webkit-scrollbar': {
            display: /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)
              ? undefined
              : 'none !important',
          },
        },

        '&, & *': {
          boxSizing: 'border-box',
        },
        '&[aria-disabled="false"] input[readonly]': {
          backgroundColor: baseColors.mono0,
        },

        '&.MuiFormControl-root.FDBTextInput--readOnly': {
          '& .MuiInputBase-root.Mui-readOnly.MuiInputBase-readOnly': {
            backgroundColor: 'transparent',
          },
        },

        '&[aria-disabled="true"]': {
          cursor: 'not-allowed',

          '& .MuiOutlinedInput-input, & .MuiOutlinedInput-root': {
            backgroundColor: baseColors.mono300,
            color: baseColors.mono500,
            pointerEvents: 'none',
          },
          '& .MuiOutlinedInput-root .FDBIcon': {
            color: `${baseColors.mono500} !important`,
          },
        },

        // NumberField
        '&.FDBNumericInput.FDBNumericInput': {
          '& input': {
            textAlign: 'right',
          },
          '&--left-align input': {
            textAlign: 'left',
          },
        },

        // TextInput
        '&.FDBTextInput .MuiOutlinedInput-root': {
          backgroundColor: baseColors.mono0,

          // TextArea
          '&.MuiInputBase-multiline': {
            paddingTop: '10px',
            paddingBottom: '10px',
          },
        },

        '&.FDBTextInput[aria-disabled="true"] .MuiOutlinedInput-root': {
          backgroundColor: baseColors.mono300,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        position: 'relative',
        transform: 'none',
        whiteSpace: 'inherit',
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        height: '100%',
        '& button': {
          background: 'transparent',
          border: '2px solid transparent',
          borderRadius: '50%',
          outline: 'none',
          cursor: 'pointer',
          padding: '3px',
        },
        '& button:focus': {
          border: `2px solid ${baseColors.teal300}`,
        },
        '&.MuiInputAdornment-positionStart': {
          marginRight: 0,
        },
        '&.MuiInputAdornment-positionEnd': {
          marginLeft: 0,
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        backgroundColor: baseColors.mono0,
        borderRadius: gutters[4],
        boxSizing: 'border-box',
        border: 'none !important',
        boxShadow: 'none !important',
        outline: 'none',
        '::placeholder': {
          color: baseColors.mono500,
          opacity: 1,
          fontWeight: 500,
        },
        '&.MuiInputBase-input': {
          color: baseColors.mono900,
        },
      },
      root: ({ ownerState }) => ({
        '&:focus': {
          outline: 'none',
          outlineOffset: 'none',
          border: 'none !important',
          boxShadow: 'none !important',
        },
        '&.Mui-focused:not(.Mui-disabled) > fieldset': {
          borderColor: `${
            (ownerState?.error && baseColors.red300) ||
            (ownerState?.readOnly && 'transparent') ||
            baseColors.purple700
          } !important`,
          borderWidth: '2px !important',
        },
        paddingLeft: 0,
        '& > fieldset': {
          borderStyle: 'solid !important',
          borderWidth: `${(ownerState?.error && '2px') || '1px'} !important`,
          borderColor: `${
            (ownerState?.error && baseColors.red300) ||
            (ownerState?.readOnly && 'transparent') ||
            baseColors.mono400
          }  !important`,
        },
        '& > fieldset > legend': {
          borderBottom: 'none',
          width: 'initial',
        },
        '&:hover:not(.Mui-disabled)': {
          '& > fieldset': {
            borderColor: `${
              (ownerState?.error && baseColors.red300) ||
              (ownerState?.readOnly && 'transparent') ||
              baseColors.purple700
            }   !important`,
            borderWidth: '2px !important',
          },
        },
        // FDBSelect
        '&.FDBSelect.MuiInputBase-root': {
          '& .MuiSelect-select': {
            cursor: 'pointer',
          },

          '&[aria-disabled="true"]': {
            cursor: 'not-allowed',
            backgroundColor: baseColors.mono300,

            '& > fieldset': {
              border: 'none !important',
            },
            '& .MuiSelect-select': {
              cursor: 'not-allowed',
              backgroundColor: baseColors.mono300,
            },
            '& .MuiSelect-icon': {
              color: baseColors.mono500,
            },
            // multi select display as text color
            '& .FDBBox': {
              color: baseColors.mono500,
            },
          },
        },
        '&.FDBSelect--error > fieldset': {
          border: `2px solid ${baseColors.red300} !important`,
        },
        '&.FDBSelect--error:hover > fieldset': {
          border: `2px solid ${baseColors.red300} !important`,
        },
        '&.MuiInputBase-adornedEnd:not(.MuiInputBase-adornedStart)': {
          paddingRight: 0,
        },
      }),
    },
  },
};
