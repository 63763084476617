import './Popper.scss';

import React, { useMemo, useState } from 'react';
import MuiPopper from '@material-ui/core/Popper';
import Zoom from '@mui/material/Zoom';
import PropTypes from 'prop-types';

import { cxFunction } from 'util/className';

const style = { zIndex: 999 };

const Popper = ({
  cx,
  open,
  children,
  customStyle = {},
  showArrow = false,
  stickToViewport = false,
  ...props
}) => {
  const [arrowEl, setArrowEl] = useState(null);
  const modifiers = useMemo(() => {
    let arrowModifier;
    if (showArrow) {
      arrowModifier = {
        arrow: {
          enabled: true,
          element: arrowEl,
        },
      };
    }

    let preventOverflowModifier;
    if (stickToViewport) {
      preventOverflowModifier = {
        preventOverflow: {
          enabled: true,
          boundariesElement: 'viewport',
        },
      };
    }

    if (arrowModifier || preventOverflowModifier) {
      return {
        ...arrowModifier,
        ...preventOverflowModifier,
      };
    }

    return null;
  }, [showArrow, stickToViewport, arrowEl]);

  return (
    <MuiPopper
      open={open}
      style={{ ...style, ...customStyle }}
      modifiers={modifiers}
      {...props}
      className={cx()}
    >
      {({ TransitionProps }) => (
        <>
          {showArrow && (
            <Zoom in {...TransitionProps} timeout={200}>
              <div id="arrow" ref={setArrowEl} style={style} />
            </Zoom>
          )}

          <Zoom in {...TransitionProps} timeout={200}>
            <div>{children}</div>
          </Zoom>
        </>
      )}
    </MuiPopper>
  );
};

Popper.propTypes = {
  customStyle: PropTypes.object,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  cx: PropTypes.func.isRequired,
};

export default cxFunction('Popper', Popper);
