import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import { TabletAndBelow } from 'components/MediaQuery';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import FieldRenderer from '../../FieldRenderer';
import PercentToolTip from '../BenefitsWidget/PercentToolTip';

const RADIO_GROUP_STYLE = {
  marginLeft: 0,
  marginRight: 8,
  paddingLeft: 0,
};

const { cx, cxEl } = cxHelpers('MiscFormFieldRows');

const MiscFormFieldRows = ({ children, values, showFormWithSteps }) => {
  const contributionGroupOptions = [
    {
      value: 'amount',
      description: (
        <Text
          fs={showFormWithSteps ? 14 : 16}
          fw600
          lh={1.25}
          navy
          i18n="team.employee_profile.tax_deductions.fixed_amount_label"
        />
      ),
    },
    {
      value: 'percent',
      description: (
        <PercentToolTip
          content={toI18n(
            'team.employee_profile.tax_deductions.ptd_percent_tooltip_content'
          )}
          i18n="team.employee_profile.tax_deductions.percent_label"
          showFormWithSteps={showFormWithSteps}
        />
      ),
    },
  ];

  const contributionLimitGroupOptions = [
    {
      value: 'maximum_amount',
      description: (
        <PercentToolTip
          content={toI18n(
            'team.employee_profile.tax_deductions.max_amount_tooltip_content'
          )}
          i18n="team.employee_profile.tax_deductions.maximum_amount_label"
          showFormWithSteps={showFormWithSteps}
        />
      ),
    },
    {
      value: 'annual_limit',
      description: (
        <PercentToolTip
          content={toI18n(
            'team.employee_profile.tax_deductions.annual_limit_tooltip_content'
          )}
          i18n="team.employee_profile.tax_deductions.annual_limit"
          showFormWithSteps={showFormWithSteps}
        />
      ),
    },
  ];

  return (
    <TabletAndBelow>
      {tabletAndBelow => (
        <Box className={cx()} pb20 bb mb20>
          <Box column={tabletAndBelow} row={!tabletAndBelow} mb32>
            <Box grow basis="40%">
              <Box mb12>
                <Text
                  fs={showFormWithSteps ? 14 : 16}
                  fw600
                  lh={1.25}
                  navy
                  i18n="team.employee_profile.tax_deductions.employee_contribution_label"
                />
              </Box>
              <Box row>
                <Box>
                  <FieldRenderer
                    editMode
                    name="amount"
                    component="TextInput"
                    inputProps={{
                      decimalScale: 2,
                    }}
                    placeholder={
                      values.amount_type === 'amount' ? '$0.00' : '0.00%'
                    }
                    type={
                      values.amount_type === 'amount' ? 'currency' : 'percent'
                    }
                  />
                </Box>
                <Box vcenter ml12>
                  <Text
                    fs={showFormWithSteps ? 14 : 16}
                    fw600
                    lh={1.25}
                    navy
                    i18n="team.employee_profile.tax_deductions.per_pay_period_label"
                  />
                </Box>
              </Box>
              <Box mt8>
                <FieldRenderer
                  className={cxEl('ptd_amount_type')}
                  editMode
                  name="amount_type"
                  component="RadioGroup"
                  row
                  options={contributionGroupOptions}
                  radioStyle={RADIO_GROUP_STYLE}
                  radioGroupStyle={{ paddingLeft: 0 }}
                  labelStyle={{ marginLeft: 0, width: 160 }}
                />
              </Box>
            </Box>

            <Box grow mt8={tabletAndBelow} basis="30%" vbottom>
              <Box column>
                <Box mb12>
                  <Text
                    fs={showFormWithSteps ? 14 : 16}
                    fw600
                    lh={1.25}
                    navy
                    data-testid="employeeContributionLimitLabel"
                    i18n="team.employee_profile.tax_deductions.employee_contribution_limit_label"
                  />
                </Box>
                <Box column>
                  <Box>
                    <FieldRenderer
                      editMode
                      name="contribution_amount"
                      component="TextInput"
                      placeholder="$0.00"
                      type="currency"
                    />
                  </Box>
                  <Box w={150} mt8>
                    <FieldRenderer
                      className={cxEl('ptd_amount_type')}
                      editMode
                      name="contribution_type"
                      component="RadioGroup"
                      row
                      options={contributionLimitGroupOptions}
                      radioStyle={RADIO_GROUP_STYLE}
                      radioGroupStyle={{ paddingLeft: 0 }}
                      labelStyle={{ marginLeft: 0, width: 180 }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box vbottom hright grow basis="30%">
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </TabletAndBelow>
  );
};

MiscFormFieldRows.propTypes = {
  values: PropTypes.object,
  isEditing: PropTypes.bool,
};

export default MiscFormFieldRows;
