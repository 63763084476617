import './ModalCloseIcon.scss';

import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('ModalCloseIcon');

const ModalCloseIcon = ({
  onClick,
  iconColor,
  closeIcon,
  iconSize,
  className,
}) => (
  <Box
    data-testid="ModalCloseIcon"
    inlineBlock
    className={[cx(), className]}
    onClick={onClick}
  >
    <Icon iconName={closeIcon || 'Close'} color={iconColor} size={iconSize} />
  </Box>
);

ModalCloseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
  closeIcon: PropTypes.object,
  iconSize: PropTypes.string,
  className: PropTypes.string,
};

export default ModalCloseIcon;
