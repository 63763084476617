import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

export const createApolloClient = () => {
  // eslint-disable-next-line no-unused-vars
  const errorLink = onError(({ _, networkError }) => {
    if (networkError) {
      if (networkError.bodyText && networkError.bodyText.startsWith('<')) {
        networkError.message =
          'An unexpected error occurred. Please try again.';
      }
    }
  });

  // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#overview
  const retryLink = new RetryLink({
    delay: {
      initial: 100,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      // eslint-disable-next-line no-unused-vars
      retryIf: (error, _operation) => !!error,
    },
  });

  const httpLink = createHttpLink({
    credentials: 'same-origin',
  });

  const link = ApolloLink.from([errorLink, retryLink, httpLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
};
