import { toI18n } from 'util/i18n';
import { isRequired, isValidDate } from 'util/validators';

const REHIRE_OPTIONS = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
const REASON_OPTIONS = [];

export const COLUMNS = {
  counter: {
    type: 'counter',
    width: 40,
  },
  employee: {
    type: 'static',
    flex: 4,
    header: toI18n('employees_roster.bulk_terminate.headers.employee'),
    textStyles: { fs14: true, fw600: true, navy: true },
  },
  reason: {
    type: 'select',
    flex: 4,
    options: () => REASON_OPTIONS,
    header: toI18n('employees_roster.bulk_terminate.headers.reason'),
    validations: [isRequired('Termination Reason')],
  },
  notes: {
    type: 'text',
    flex: 6,
    header: toI18n('employees_roster.bulk_terminate.headers.notes'),
  },
  archived_at: {
    type: 'date',
    flex: 2,
    header: toI18n('employees_roster.bulk_terminate.headers.archived_at'),
    validations: [isRequired('Termination Date'), isValidDate()()],
  },
  eligible_for_rehire: {
    type: 'select',
    flex: 2,
    options: () => REHIRE_OPTIONS,
    header: toI18n('employees_roster.bulk_terminate.headers.rehire'),
  },
};

export const COLUMN_NAMES = Object.keys(COLUMNS).map(name => ({ name }));
