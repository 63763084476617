import { fromJS } from 'immutable';

import { actionTypes as addTeamActionTypes } from 'actions/addTeam';
import { actionTypes as applicantMessengerActionTypes } from 'actions/applicantMessenger';
import { actionTypes as drawerActionTypes } from 'actions/drawers';
import { actionTypes as hiringActionTypes } from 'actions/hiring/index';
import { actionTypes as notificationsActionTypes } from 'actions/notifications';
import { actionTypes as scheduleBuilderActionTypes } from 'actions/scheduleBuilder';
import { actionTypes as sessionActionTypes } from 'actions/session';
import { actionTypes as timesheetActionTypes } from 'actions/timesheets';

import {
  EMPTY_SCHEDULE_DRAWER_KEY,
  PUBLISH_DRAWER_KEY,
} from 'features/drawers/keys';
import { ACTION_TYPES as messengerV2ActionTypes } from 'features/messengerv2/constants';

const INITIAL_STATE = fromJS({
  openDrawerKey: null,
  drawerProps: {},
  packetProps: {},
});

const toggleDrawer = (state, drawerKey, drawerOpen, drawerProps = {}) => {
  // If no drawerKey passed and drawerOpen is false, close any open drawer.
  if (!drawerKey && drawerOpen === false) return state.merge(INITIAL_STATE);

  // If drawerKey passed and drawerOpen is true, set that drawerKey as the open drawer.
  if (drawerKey && drawerOpen === true)
    return state.merge({ openDrawerKey: drawerKey, drawerProps });

  // If drawerKey is passed and drawerOpen is false, close the open drawer iff the
  // openDrawerKey matches the passed drawerKey.
  if (
    drawerKey &&
    drawerOpen === false &&
    state.get('openDrawerKey') === drawerKey
  ) {
    return state.merge({ openDrawerKey: null, drawerProps: {} });
  }

  return state;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case applicantMessengerActionTypes.SHOW_APPLICANT_MESSENGER:
    case applicantMessengerActionTypes.HIDE_APPLICANT_MESSENGER:
    case timesheetActionTypes.TOGGLE_PRINT_DRAWER:
    case scheduleBuilderActionTypes.TOGGLE_PUBLISH_DRAWER:
    case scheduleBuilderActionTypes.TOGGLE_SYNC_SHIFTS_DRAWER:
    case scheduleBuilderActionTypes.TOGGLE_PRINT_DRAWER:
    case scheduleBuilderActionTypes.TOGGLE_EMPTY_SCHEDULE_DRAWER:
    case scheduleBuilderActionTypes.TOGGLE_TEMPLATES_DRAWER:
    case scheduleBuilderActionTypes.TOGGLE_SETTINGS_DRAWER:
    case messengerV2ActionTypes.TOGGLE_MESSENGER:
    case notificationsActionTypes.TOGGLE_SHOW_NOTIFICATIONS:
    case addTeamActionTypes.CHOOSE_HOW_DRAWER:
    case hiringActionTypes.TOGGLE_HIRING_DASHBOARD_PAGE_TIPS_DRAWER_OPEN:
    case hiringActionTypes.TOGGLE_APPLICATION_NOTES_DRAWER_OPEN:
    case hiringActionTypes.TOGGLE_MANAGE_APPLICANTS_ZERO_STATE_DRAWER_OPEN:
    case hiringActionTypes.ADD_APPLICANTS_MANUALLY_DRAWER:
    case addTeamActionTypes.SHOW_DRAWER:
    case addTeamActionTypes.TOGGLE_UPDATE_TEAM_DRAWER:
    case timesheetActionTypes.HIDE_ZERO_STATE_DRAWER:
    case addTeamActionTypes.CLOSE_DRAWER:
    case drawerActionTypes.CLOSE_DRAWER:
    case drawerActionTypes.ACTIONS_LIST_DRAWER:
    case drawerActionTypes.HEALTH_REPORT_EXPORT_DRAWER:
    case drawerActionTypes.OPEN_DRAWER: {
      const { drawerKey, drawerOpen, drawerProps } = action.payload;

      return toggleDrawer(state, drawerKey, drawerOpen, drawerProps);
    }

    case scheduleBuilderActionTypes.CREATE_FROM_TEMPLATE_SUCCESS: {
      return [PUBLISH_DRAWER_KEY, EMPTY_SCHEDULE_DRAWER_KEY].reduce(
        (newState, key) => toggleDrawer(newState, key, false),
        state
      );
    }

    case 'newHire/savePacketTemplate/fulfilled':
    case sessionActionTypes.UPDATE_SESSION: {
      const { packetProps } = action.payload;
      return state.merge({ packetProps });
    }

    case 'newHire/fetchPacketTemplate/fulfilled': {
      if (action.payload.newHireOnboardingActivated) {
        const { packetProps } = action.payload;
        return state.merge({ packetProps });
      }
      return state;
    }

    case 'newHire/activate/fulfilled': {
      return state.setIn(
        ['packetProps', 'newHireOnboardingActivated'],
        action.payload.newHireOnboardingActivated
      );
    }

    default:
      return state;
  }
};
