import React, { useEffect, useState } from 'react';

import Box from 'components/Box';

const FadeInBox = props => {
  // This component relies on the fact that Box has
  // a transition assigned to opacity
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  return <Box {...props} opacity={opacity} />;
};

export default FadeInBox;
