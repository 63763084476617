import './UserWebTimeclockNavItem.scss';

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as userWebTimeclockActions from 'actions/userWebTimeclock';

import { getModalType } from 'selectors/modals';
import { getNotificationStatus } from 'selectors/timeClock';

import { HIGHLIGHT_CLOCK_IN_NAV_ITEM } from 'features/modals/constants';
import { useWebTimeClock } from 'features/timeClock/hooks/useWebTimeClock';
import * as constants from 'features/timeClock/modals/UserWebTimeclockModal/constants';

import Box from 'components/Box';
import Icon from 'components/Icon';

import { cxHelpers } from 'util/className';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

const { cx, cxEl } = cxHelpers('UserWebTimeclockNavItem');

const UserWebTimeclockNavItem = ({
  status,
  modalType,
  newHeader,
  hidden,
  fetchStatus,
}) => {
  useEffect(() => {
    if (!hidden) {
      fetchStatus();
    }
  }, [hidden, fetchStatus]);

  const handleClick = useWebTimeClock();

  const getIndicatorColor = useMemo(() => {
    if (status === constants.CLOCKED_OUT) return 'grayMedium';
    else if (status === constants.CLOCKED_IN) return 'green';
    return 'orange';
  }, [status]);

  const getClassName = useMemo(
    () =>
      modalType === HIGHLIGHT_CLOCK_IN_NAV_ITEM ? cxEl('custom_overlay') : cx(),
    [modalType]
  );

  if (hidden) {
    return null;
  }

  return (
    <Box className={getClassName}>
      <NavItem
        indicator
        className={cx()}
        onClick={handleClick}
        indicatorColor={getIndicatorColor}
      >
        <Box mr4>
          {newHeader ? (
            <img src={require('./timeclock.svg')} width={24} height={24} />
          ) : (
            <Icon
              type="timeclock"
              size={16}
              color={newHeader ? 'white' : null}
            />
          )}
        </Box>
      </NavItem>
    </Box>
  );
};

UserWebTimeclockNavItem.propTypes = {
  modalType: PropTypes.string,
};

export default connect(
  state => ({
    status: getNotificationStatus(state),
    modalType: getModalType(state),
  }),
  { fetchStatus: userWebTimeclockActions.fetchStatus }
)(withNavItemData('userWebTimeClock')(UserWebTimeclockNavItem));
