import { batch } from 'react-redux';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { newPendingId } from 'fe-core/Entities/pending';
import { getSchema } from 'fe-core/Entities/schema';
import { cloneDeep, isArray } from 'lodash';
import queryString from 'query-string';

import * as ApiClient from '../ApiClient';
import { actions as entitiesActions } from '../Entities';
import { RESOURCES_SLICE } from '../module-constants';

import entityFromProperties from './entityFromProperties';
import { markPerformance } from './markPerformance';
import { checkAndClearRequest, trackRequest } from './requestTracker';

export const resourceEventName = (name, action) => `resource/${name}/${action}`;

const resourceEvent = ({ name, action, payload }) => ({
  type: resourceEventName(name, action),
  payload,
});

const handleRequest = ({
  request,
  dispatch,
  name,
  action,
  beforeDigestCallback,
  requestId,
  pendingId,
}) =>
  request
    .then(response => {
      markPerformance(name, action);

      if (requestId && !checkAndClearRequest(requestId)) {
        return;
      }

      batch(() => {
        if (beforeDigestCallback) beforeDigestCallback();
        if (response.data) dispatch(entitiesActions.digest(response));
        dispatch(resourceEvent({ name, action, payload: response }));
        if (pendingId) dispatch(entitiesActions.clearPending(pendingId));
      });
      return response;
    })
    .catch(e => {
      batch(() => {
        if (requestId) checkAndClearRequest(requestId);
        if (pendingId) dispatch(entitiesActions.clearPending(pendingId));
      });
      throw e;
    });

export const get = createAsyncThunk(
  `${RESOURCES_SLICE}/fetch`,
  (
    { name, path, id, payload, beforeDigestCallback, action = 'fetch' },
    { dispatch }
  ) => {
    let idPath = id ? `${path}/${id}` : path;
    idPath = payload ? `${idPath}?${queryString.stringify(payload)}` : idPath;

    const requestId = trackRequest(name, id);

    return handleRequest({
      request: ApiClient.get(idPath),
      dispatch,
      name,
      action,
      beforeDigestCallback,
      requestId,
    });
  }
);

let newCounter = 0;
export const nextNewId = () => {
  newCounter += 1;
  return `_new_${newCounter}`;
};

export const post = createAsyncThunk(
  `${RESOURCES_SLICE}/post`,
  ({ name, path, payload, action = 'post' }, { dispatch }) =>
    handleRequest({
      request: ApiClient.post(path, payload),
      dispatch,
      name,
      action,
    })
);

export const addPending = createAsyncThunk(
  `${RESOURCES_SLICE}/addPending`,
  ({ name, entities }, { dispatch }) => {
    const pendingId = newPendingId();
    const pendingEntities = ((isArray(entities) && entities) || [entities]).map(
      ({ id, ...data }) => {
        const entityId = id || nextNewId();
        return { _new: !id, type: name, id: entityId, ...data };
      }
    );

    dispatch(
      entitiesActions.addPending({
        pendingId,
        entities: cloneDeep(pendingEntities).map(e =>
          entityFromProperties(getSchema(name), e)
        ),
      })
    );
    return Promise.resolve({ pendingEntities, pendingId });
  }
);

export const create = createAsyncThunk(
  `${RESOURCES_SLICE}/create`,
  (
    { name, path, payload, beforeDigestCallback, pendingId, newId },
    { dispatch }
  ) => {
    const entityId = newId || nextNewId();
    const addPendingId =
      !pendingId && getSchema(name)?.savePending && newPendingId();

    if (getSchema(name)?.savePending && addPendingId) {
      dispatch(
        entitiesActions.addPending({
          pendingId: addPendingId,
          entities: [
            entityFromProperties(getSchema(name), {
              _new: true,
              type: name,
              id: entityId,
              ...payload,
            }),
          ],
        })
      );
    }

    return handleRequest({
      request: ApiClient.post(path, payload),
      dispatch,
      name,
      action: 'create',
      beforeDigestCallback,
      pendingId: addPendingId,
    });
  }
);

export const update = createAsyncThunk(
  `${RESOURCES_SLICE}/update`,
  (
    {
      name,
      path,
      id,
      pendingId,
      payload,
      beforeDigestCallback,
      action = 'update',
    },
    { dispatch }
  ) => {
    const idPath = id ? `${path}/${id}` : path;
    const entityId = id || '1';
    const addPendingId =
      !pendingId && getSchema(name)?.savePending && newPendingId();

    if (getSchema(name)?.savePending && addPendingId) {
      dispatch(
        entitiesActions.addPending({
          pendingId: addPendingId,
          entities: [
            entityFromProperties(getSchema(name), {
              type: name,
              id: entityId,
              ...payload,
            }),
          ],
        })
      );
    }

    return handleRequest({
      request: ApiClient.put(idPath, payload),
      dispatch,
      name,
      action,
      beforeDigestCallback,
      pendingId: addPendingId,
    });
  }
);

export const destroy = createAsyncThunk(
  `${RESOURCES_SLICE}/destroy`,
  ({ name, path, id, action = 'destroy' }, { dispatch }) => {
    const idPath = id ? `${path}/${id}` : path;
    return handleRequest({
      request: ApiClient.del(idPath),
      dispatch,
      name,
      action,
      beforeDigestCallback: () =>
        dispatch(entitiesActions.destroy({ id, type: name })),
    });
  }
);
