import { actionTypes as applicantMessengerActionTypes } from 'actions/applicantMessenger';

import { error as flashError } from 'util/flashNotice';

import {
  ACTION_TYPES,
  FIRST_PAGE,
  GENERIC_ERROR_MESSAGE,
  READ_RECEIPT_STATUS,
} from './constants';

const {
  FETCH_CHANNELS,
  FETCH_CHANNEL_MESSAGES,
  UPDATE_CHANNEL,
  FETCH_MESSAGE_READ_LIST,
  FETCH_LOCATION_PARTICIPANTS,
  FETCH_CHANNEL_PARTICIPANTS,
  CREATE_MESSAGE,
  CREATE_CHANNEL,
  MARK_MESSAGE_AS_READ,
  DELETE_MESSAGE,
} = ACTION_TYPES;

const setMessageReadList = (
  state,
  {
    payload: {
      channelId,
      messageId,
      page_number: page,
      total_items: totalItems,
      total_pages: totalPages,
      read_receipts: newReceipts,
    },
  }
) => {
  const messages = state.messages[channelId];

  if (!messages.length) return;

  const messageIndex = messages.findIndex(message => message.id === messageId);

  if (messageIndex === -1) return;

  let message = messages[messageIndex];

  if (!message.receipts) {
    message.receipts = {
      readReceipts: [],
      unreadReceipts: [],
      unsentReceipts: [],
    };
  }

  const {
    receipts: { readReceipts, unreadReceipts, unsentReceipts },
  } = message;

  const newReadReceipts = [];
  const newUnreadReceipts = [];
  const newUnsentReceipts = [];

  newReceipts.forEach(receipt => {
    if (receipt.status === READ_RECEIPT_STATUS.READ)
      newReadReceipts.push(receipt);
    else if (receipt.status === READ_RECEIPT_STATUS.SENT)
      newUnreadReceipts.push(receipt);
    else newUnsentReceipts.push(receipt);
  });

  message = {
    ...message,
    receipts: {
      readReceipts: [...readReceipts, ...newReadReceipts],
      unreadReceipts: [...unreadReceipts, ...newUnreadReceipts],
      unsentReceipts: [...unsentReceipts, ...newUnsentReceipts],
    },
    readReceiptsPagination: { page, totalItems, totalPages },
  };

  state.messages[channelId][messageIndex] = message;
};

const setMessages = (
  state,
  {
    payload: {
      channelId,
      available_reactions,
      messages,
      page_number: page,
      total_items: totalItems,
      total_pages: totalPages,
    },
  }
) => {
  state.messages = {
    ...state.messages,
    [channelId]:
      page === FIRST_PAGE
        ? messages.reverse()
        : [...messages.reverse(), ...state.messages[channelId]],
  };
  state.messagePagination = {
    ...state.messagePagination,
    [channelId]: { page, totalPages, totalItems },
  };
  state.availableMessageReactions = {
    ...state.availableMessageReactions,
    [channelId]: available_reactions,
  };
};

const setChannels = (
  state,
  {
    payload: {
      channels: newChannels,
      after_terminus: afterTerminus,
      before_terminus: beforeTerminus,
    },
  }
) => {
  state.channels = {
    ...state.channels,
    ...newChannels.reduce((memo, channel) => {
      memo[channel.id] = channel;
      state.messages[channel.id] = [];
      return memo;
    }, {}),
  };
  state.afterTerminus = afterTerminus;
  state.beforeTerminus = beforeTerminus;
};

const addChannel = (state, { payload: channel }) => {
  const channelId = channel.id;
  state.channels = { [channelId]: channel, ...state.channels };
  state.messages = { ...state.messages, [channelId]: [] };
};

const setCurrentTab = (state, { payload: currentTab }) => {
  state.currentTab = currentTab;
};

const setCurrentReadReceiptTab = (
  state,
  { payload: currentReadReceiptTab }
) => {
  state.currentReadReceiptTab = currentReadReceiptTab;
};

const setCurrentChatView = (
  state,
  {
    payload: {
      prefilledMessage,
      prefilledUserIds,
      view: currentChatView,
      channelId: currentChannelId,
    },
  }
) => {
  closeReadReceiptsDrawer(state);

  if (prefilledMessage) state.prefilledMessage = prefilledMessage;
  if (prefilledUserIds) state.prefilledUserIds = prefilledUserIds;

  state.currentChatView = currentChatView;
  state.currentChannelId = currentChannelId;
};

const clearPrefilledData = state => {
  state.prefilledMessage = '';
  state.prefilledUserIds = null;
};

const closeReadReceiptsDrawer = state => {
  state.isReadReceiptsDrawerOpen = false;
  state.currentReadReceiptsMessageId = null;
};

const openReadReceiptsDrawer = (
  state,
  { payload: currentReadReceiptsMessageId }
) => {
  state.currentReadReceiptsMessageId = currentReadReceiptsMessageId;
  state.isReadReceiptsDrawerOpen = true;
};

const addNewMessage = (state, { payload: message }) => {
  const channelId = message.channel_id;
  const isMine = message.is_mine;
  const existingChannelMessages = state.messages[channelId]?.length;

  if (!existingChannelMessages && isMine) {
    state.messages[channelId] = [message];
  } else if (existingChannelMessages) {
    state.messages[channelId].push(message);
  }
};

const updateMessage = (state, { payload: message }) => {
  const existingChannelMessages = state.messages[message.channel_id];

  if (existingChannelMessages) {
    state.messages[message.channel_id] = existingChannelMessages.map(
      currMessage => {
        if (currMessage.id === message.id)
          return { ...currMessage, ...message };
        return currMessage;
      }
    );
  }
};

const updateChannelWithIndex = (state, { payload: { channel, index } }) => {
  if (!channel || !state.channels[channel.id]) return;

  state.channels = index.reduce((memo, channelId) => {
    if (channelId === channel.id)
      memo[channelId] = { ...state.channels[channelId], ...channel };
    else if (state.channels[channelId])
      memo[channelId] = state.channels[channelId];
    return memo;
  }, {});
};

const setUnreadMessages = (state, { payload: unreadCount }) => {
  state.hasUnreadMessages = unreadCount;
};

const deleteChannel = (state, { payload: channelId }) => {
  delete state.channels[channelId];
};

const updateChannel = (state, { payload: channel }) => {
  state.channels[channel.id] = { ...state.channels[channel.id], ...channel };
};

const setLocationParticipants = (
  state,
  {
    payload: {
      participants,
      page_number: pageNumber,
      total_items: totalItems,
      total_pages: totalPages,
      quick_access: quickAccess,
      locationId,
      filter,
    },
  }
) => {
  state.locationParticipants = {
    ...state.locationParticipants,
    [locationId]:
      pageNumber === FIRST_PAGE
        ? participants
        : [...state.locationParticipants[locationId], ...participants],
  };
  state.locationQuickAccess = {
    ...state.locationQuickAccess,
    [locationId]: quickAccess,
  };
  state.locationParticipantsPagination = {
    ...state.locationParticipantsPagination,
    [locationId]: {
      pageNumber,
      totalItems,
      totalPages,
      filter,
    },
  };
};

const setChannelParticipants = (
  state,
  {
    payload: {
      participants,
      page_number: pageNumber,
      total_items: totalItems,
      total_pages: totalPages,
      channelId,
      filter,
      locationId,
    },
  }
) => {
  state.channelParticipants = {
    ...state.channelParticipants,
    [locationId]: {
      ...state.channelParticipants[locationId],
      [channelId]:
        pageNumber === FIRST_PAGE
          ? participants
          : [
              ...state.channelParticipants[locationId][channelId],
              ...participants,
            ],
    },
  };

  state.channelParticipantsPagination = {
    ...state.channelParticipantsPagination,
    [locationId]: {
      ...state.channelParticipantsPagination[locationId],
      [channelId]: {
        pageNumber,
        totalItems,
        totalPages,
        filter,
      },
    },
  };
};

const replaceMessage = (state, { payload: { replacementId, message } }) => {
  state.messages[message.channel_id] = state.messages[message.channel_id].map(
    currMessage => {
      if (currMessage.id === replacementId) return message;
      return currMessage;
    }
  );
};

const setChannelViewScrollPosition = (
  state,
  { payload: channelViewScrollPosition }
) => {
  state.channelViewScrollPosition = channelViewScrollPosition;
};

const setExistingChannelParticipants = (
  state,
  { payload: { channelId, participants } }
) => {
  state.existingChannelParticipants = {
    ...state.existingChannelParticipants,
    [channelId]: participants,
  };
};

export const REDUCERS = {
  addChannel,
  setChannels,
  setChannelViewScrollPosition,
  setMessages,
  setExistingChannelParticipants,
  setCurrentChatView,
  setMessageReadList,
  openReadReceiptsDrawer,
  closeReadReceiptsDrawer,
  addNewMessage,
  setLocationParticipants,
  setChannelParticipants,
  updateChannelWithIndex,
  replaceMessage,
  updateMessage,
  setUnreadMessages,
  deleteChannel,
  updateChannel,
  clearPrefilledData,
  setCurrentTab,
  setCurrentReadReceiptTab,
};

export const EXTRA_REDUCERS = {
  [`${FETCH_CHANNELS}/pending`]: state => {
    state.isFetchingChannels = true;
  },
  [`${FETCH_CHANNELS}/fulfilled`]: state => {
    state.isFetchingChannels = false;
  },
  [`${FETCH_CHANNELS}/rejected`]: state => {
    state.isFetchingChannels = false;
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${FETCH_CHANNEL_MESSAGES}/pending`]: (state, payload) => {
    const { channelId } = payload.meta.arg;

    state.isFetchingMessages = {
      ...state.isFetchingMessages,
      [channelId]: true,
    };
  },
  [`${FETCH_CHANNEL_MESSAGES}/fulfilled`]: (state, payload) => {
    const { channelId } = payload.meta.arg;

    state.isFetchingMessages = {
      ...state.isFetchingMessages,
      [channelId]: false,
    };
  },
  [`${FETCH_CHANNEL_MESSAGES}/rejected`]: (state, payload) => {
    const { channelId } = payload.meta.arg;

    state.isFetchingMessages = {
      ...state.isFetchingMessages,
      [channelId]: false,
    };
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${FETCH_MESSAGE_READ_LIST}/pending`]: (state, payload) => {
    const { messageId } = payload.meta.arg;

    state.isFetchingReadReceipts = {
      ...state.isFetchingReadReceipts,
      [messageId]: true,
    };
  },
  [`${FETCH_MESSAGE_READ_LIST}/fulfilled`]: (state, payload) => {
    const { messageId } = payload.meta.arg;

    state.isFetchingReadReceipts = {
      ...state.isFetchingReadReceipts,
      [messageId]: false,
    };
  },
  [`${FETCH_MESSAGE_READ_LIST}/rejected`]: (state, payload) => {
    const { messageId } = payload.meta.arg;

    state.isFetchingReadReceipts = {
      ...state.isFetchingReadReceipts,
      [messageId]: false,
    };
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${FETCH_LOCATION_PARTICIPANTS}/pending`]: (state, payload) => {
    const { locationId } = payload.meta.arg;

    state.isFetchingLocationParticipants = {
      ...state.isFetchingLocationParticipants,
      [locationId]: true,
    };
  },
  [`${FETCH_LOCATION_PARTICIPANTS}/fulfilled`]: (state, payload) => {
    const { locationId } = payload.meta.arg;

    state.isFetchingLocationParticipants = {
      ...state.isFetchingLocationParticipants,
      [locationId]: false,
    };
  },
  [`${FETCH_LOCATION_PARTICIPANTS}/rejected`]: (state, payload) => {
    const { locationId } = payload.meta.arg;

    state.isFetchingLocationParticipants = {
      ...state.isFetchingLocationParticipants,
      [locationId]: false,
    };
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${FETCH_CHANNEL_PARTICIPANTS}/pending`]: (state, payload) => {
    const { channelId, locationId } = payload.meta.arg;

    state.isFetchingChannelParticipants = {
      ...state.isFetchingChannelParticipants,
      [locationId]: {
        ...state.isFetchingChannelParticipants[locationId],
        [channelId]: true,
      },
    };
  },
  [`${FETCH_CHANNEL_PARTICIPANTS}/fulfilled`]: (state, payload) => {
    const { channelId, locationId } = payload.meta.arg;

    state.isFetchingChannelParticipants = {
      ...state.isFetchingChannelParticipants,
      [locationId]: {
        ...state.isFetchingChannelParticipants[locationId],
        [channelId]: false,
      },
    };
  },
  [`${FETCH_CHANNEL_PARTICIPANTS}/rejected`]: (state, payload) => {
    const { channelId, locationId } = payload.meta.arg;

    state.isFetchingChannelParticipants = {
      ...state.isFetchingChannelParticipants,
      [locationId]: {
        ...state.isFetchingChannelParticipants[locationId],
        [channelId]: false,
      },
    };
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${UPDATE_CHANNEL}/pending`]: (state, payload) => {
    const { channelId } = payload.meta.arg;
    state.isUpdatingChannel = { ...state.isUpdatingChannel, [channelId]: true };
  },
  [`${UPDATE_CHANNEL}/fulfilled`]: (state, payload) => {
    const { channelId } = payload.meta.arg;
    state.isUpdatingChannel = {
      ...state.isUpdatingChannel,
      [channelId]: false,
    };
  },
  [`${UPDATE_CHANNEL}/rejected`]: (state, payload) => {
    const { channelId } = payload.meta.arg;
    state.isUpdatingChannel = {
      ...state.isUpdatingChannel,
      [channelId]: false,
    };
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${CREATE_MESSAGE}/rejected`]: () => {
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${MARK_MESSAGE_AS_READ}/pending`]: (state, payload) => {
    const { messageId } = payload.meta.arg;
    state.isMarkingMessageAsRead = {
      ...state.isMarkingMessageAsRead,
      [messageId]: true,
    };
  },
  [`${MARK_MESSAGE_AS_READ}/fulfilled`]: (state, payload) => {
    const { messageId } = payload.meta.arg;
    state.isMarkingMessageAsRead = {
      ...state.isMarkingMessageAsRead,
      [messageId]: false,
    };
  },
  [`${MARK_MESSAGE_AS_READ}/rejected`]: (state, payload) => {
    const { messageId } = payload.meta.arg;
    state.isMarkingMessageAsRead = {
      ...state.isMarkingMessageAsRead,
      [messageId]: false,
    };
  },
  [`${CREATE_CHANNEL}/pending`]: state => {
    state.isCreatingChannel = true;
  },
  [`${CREATE_CHANNEL}/fulfilled`]: state => {
    state.isCreatingChannel = false;
  },
  [`${CREATE_CHANNEL}/rejected`]: state => {
    state.isCreatingChannel = false;
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [`${DELETE_MESSAGE}/pending`]: (state, payload) => {
    const { messageId } = payload.meta.arg;

    state.isDeletingMessage = { ...state.isDeletingMessage, [messageId]: true };
  },
  [`${DELETE_MESSAGE}/fulfilled`]: (state, payload) => {
    const { messageId } = payload.meta.arg;

    state.isDeletingMessage = {
      ...state.isDeletingMessage,
      [messageId]: false,
    };
  },
  [`${DELETE_MESSAGE}/rejected`]: (state, payload) => {
    const { messageId } = payload.meta.arg;

    state.isDeletingMessage = {
      ...state.isDeletingMessage,
      [messageId]: false,
    };
    flashError(GENERIC_ERROR_MESSAGE);
  },
  [applicantMessengerActionTypes.SHOW_APPLICANT_MESSENGER]: (
    state,
    {
      payload: {
        drawerOpts: { tab },
      },
    }
  ) => {
    state.currentTab = tab;
  },
};
