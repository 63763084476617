export const REQUEST_KEYS = {
  tiersData: 'TIERS_DATA',
};

export const TIER_NAMES = {
  BASIC: 'basic',
  ESSENTIALS: 'essentials',
  PLUS: 'plus',
  ENTERPRISE: 'enterprise',
  ALL_IN_ONE: 'all-in-one',
};
