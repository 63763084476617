import { createAsyncThunk } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON } from 'api/fetch';
import { debounce } from 'lodash';
import queryString from 'query-string';

import { fetchPayrollIneligibleEmployees } from 'features/payroll/DashboardView/payrollDashboardSlice';

import {
  formatDate,
  isValidDate,
  STANDARD_DATE_FORMAT,
  yesterday,
} from 'util/dateTime';
import { updateUrlFilterParams } from 'util/router';
const DEBOUNCE_DELAY = 500;

export const fetchTimesheetsInitialData = createAsyncThunk(
  'dailyReview/fetchTimesheetsInitialData',
  () => fetchJSON(`/timesheets/timesheets_initial_data`)
);

export const fetchDailyReviewLocationData = createAsyncThunk(
  'dailyReview/fetchDailyReviewLocationData',
  async (_, { dispatch }) => {
    await Promise.all([dispatch(fetchTimesheetsInitialData())]);
    dispatch(fetchPayrollIneligibleEmployees());

    return true;
  }
);

export const fetchDailyReviewRows = createAsyncThunk(
  'dailyReview/fetchDailyReviewRows',
  date =>
    fetchJSON(
      routes.dailyReviewRowsRoute({
        date,
      })
    )
);

export const fetchLocationData = createAsyncThunk(
  'dailyReview/fetchLocationData',
  () =>
    fetchJSON(
      `/timesheets/timecards/location_data?${queryString.stringify({
        include_employees: false,
      })}`
    )
);

export const fetchRowData = createAsyncThunk(
  'dailyReview/fetchRowData',
  async (date, { dispatch }) => {
    try {
      dispatch(fetchDailyReviewRows(date));
      return date;
    } catch (error) {
      throw error;
    }
  }
);

const debouncedFetchRowData = debounce(
  (dispatch, date) => dispatch(fetchRowData(date)),
  DEBOUNCE_DELAY
);

export const updateDate = createAsyncThunk(
  'dailyReview/updateDate',
  async (date, { dispatch }) => {
    if (!date || !isValidDate(date)) {
      date = yesterday(STANDARD_DATE_FORMAT);
    }
    debouncedFetchRowData(dispatch, date);
    updateUrlFilterParams({ date: formatDate(date, 'parsable_date') });
    return date;
  }
);

export const updateDailyReviewRow = createAsyncThunk(
  'dailyReview/updateRow',
  async shiftId => {
    try {
      const rowData = await fetchJSON(`/timesheets/timecards/${shiftId}.json`);
      return rowData;
    } catch (error) {
      throw error;
    }
  }
);
