import { teamAnnouncementsReadRoute, teamAnnouncementsRoute } from 'api';

import { showModal } from 'actions/modals';

import { TEAM_ANNOUNCEMENT_MODAL } from 'features/dashboard/constants';

import { createAsyncGetAction, createAsyncPostAction } from 'util/redux';

export const actionTypes = {
  FETCH_TEAM_ANNOUNCEMENTS_REQUEST:
    'DASHBOARD/FETCH_TEAM_ANNOUNCEMENTS_REQUEST',
  FETCH_TEAM_ANNOUNCEMENTS_SUCCESS:
    'DASHBOARD/FETCH_TEAM_ANNOUNCEMENTS_SUCCESS',
  FETCH_TEAM_ANNOUNCEMENTS_FAILURE:
    'DASHBOARD/FETCH_TEAM_ANNOUNCEMENTS_FAILURE',
  CLOSE_TEAM_ANNOUNCEMENT_REQUEST: 'CLOSE_TEAM_ANNOUNCEMENT_REQUEST',
  CLOSE_TEAM_ANNOUNCEMENT_SUCCESS: 'CLOSE_TEAM_ANNOUNCEMENT_SUCCESS',
  CLOSE_TEAM_ANNOUNCEMENT_FAILURE: 'CLOSE_TEAM_ANNOUNCEMENT_FAILURE',

  HIDE_TEAM_ANNOUNCEMENT: 'DASHBOARD/HIDE_TEAM_ANNOUNCEMENT',
};

export const fetchTeamAnnouncements = () =>
  createAsyncGetAction(teamAnnouncementsRoute(), [
    actionTypes.FETCH_TEAM_ANNOUNCEMENTS_REQUEST,
    actionTypes.FETCH_TEAM_ANNOUNCEMENTS_SUCCESS,
    actionTypes.FETCH_TEAM_ANNOUNCEMENTS_FAILURE,
  ]);

export const closeTeamAnnouncement = id => dispatch => {
  dispatch(
    createAsyncPostAction(teamAnnouncementsReadRoute(id), [
      { type: actionTypes.CLOSE_TEAM_ANNOUNCEMENT_REQUEST },
      { type: actionTypes.CLOSE_TEAM_ANNOUNCEMENT_SUCCESS, meta: { id } },
      { type: actionTypes.CLOSE_TEAM_ANNOUNCEMENT_FAILURE },
    ])
  );
};

export const hideTeamAnnouncement = id => ({
  type: actionTypes.HIDE_TEAM_ANNOUNCEMENT,
  meta: { id },
});

export const showTeamAnnouncementModal = teamAnnouncement =>
  showModal(TEAM_ANNOUNCEMENT_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    teamAnnouncement,
  });
