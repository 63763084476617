import { fromJS } from 'immutable';

import { actionTypes } from 'actions/dashboard';

const INITIAL_STATE = fromJS({
  teamAnnouncements: {},
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TEAM_ANNOUNCEMENTS_SUCCESS: {
      // if announcements_v2 is on
      if (!action.payload.team_announcements) {
        return state.merge({
          teamAnnouncements: action.payload.reduce((memo, a) => {
            memo[a.id] = a;
            return memo;
          }, {}),
        });
      }
      return state.merge({
        teamAnnouncements: action.payload.team_announcements,
      });
    }
    case actionTypes.CLOSE_TEAM_ANNOUNCEMENT_SUCCESS:
    case actionTypes.HIDE_TEAM_ANNOUNCEMENT:
      return state.deleteIn(['teamAnnouncements', action.meta.id]);
    default:
      return state;
  }
};
