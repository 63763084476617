import React, { useEffect } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';
import {
  ELEMENTS,
  EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

const LaunchWeekStepServeCommunity = ({ onNextStep, trackUx }) => {
  const setTimeoutId = onNextStep();

  useEffect(() => {
    trackUx(EVENT_ACTIONS.PAGE_VIEWED, TRACK_ACTION_TYPES.VIEW, {
      step_label: ELEMENTS.LAUNCH_WEEK_STEP_LABELS.SERVE_COMMUNITY,
    });
    return () => {
      clearTimeout(setTimeoutId);
    };
  }, [setTimeoutId, trackUx]);

  return (
    <Box tcenter className="content-fade-in-and-out">
      <Text variant="display1" color="mono0" asHTML>
        {toI18n('growth.launch_week.steps.serve_community.title')}
      </Text>
    </Box>
  );
};

export default LaunchWeekStepServeCommunity;
