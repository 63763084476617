import { createAsyncThunk } from '@reduxjs/toolkit';

import { createOneTimeEvent } from 'actions/session';

import {
  fetchListData,
  patchUpdateLocationSettings,
  postAddEmployee,
  putUpdateLocationSettings,
} from './api';
import { SLICE_NAME } from './constants';

export const getListData = createAsyncThunk(
  `${SLICE_NAME}/listData`,
  (_, { rejectWithValue }) =>
    fetchListData().catch(error => rejectWithValue(error.body || error.message))
);

export const ownerAddEmployee = createAsyncThunk(
  `${SLICE_NAME}/ownerAddEmployee`,
  (
    {
      setErrors,
      setSubmitting,
      resetForm,
      completionEventName,
      ...userProperties
    },
    { dispatch, rejectWithValue }
  ) =>
    postAddEmployee(userProperties)
      .then(() => {
        dispatch(createOneTimeEvent(completionEventName));
        setSubmitting(false);
        resetForm();
      })
      .catch(error => {
        if (error?.response?.status === 422) {
          setErrors({
            email: error.body.errors[0] || error.message,
          });
        }

        setSubmitting(false);
        return rejectWithValue(error.body || error.message);
      })
);

export const updateLocationSettings = createAsyncThunk(
  `${SLICE_NAME}/updateLocationSettings`,
  (
    { completionEventName, setSubmitting, ...settings },
    { dispatch, rejectWithValue }
  ) =>
    patchUpdateLocationSettings(settings)
      .then(() => {
        dispatch(createOneTimeEvent(completionEventName));
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        return rejectWithValue({ message: error.body || error.message });
      })
);

export const updateUserSettings = createAsyncThunk(
  `${SLICE_NAME}/updateUserSettings`,
  (
    { completionEventName, setSubmitting, ...settings },
    { dispatch, rejectWithValue }
  ) =>
    putUpdateLocationSettings(settings)
      .then(() => {
        dispatch(createOneTimeEvent(completionEventName));
        setSubmitting(false);
      })
      .catch(error => {
        setSubmitting(false);
        return rejectWithValue({ message: error.body || error.message });
      })
);
