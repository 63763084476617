import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dismissClockOutApprovalAlert } from 'actions/notifications';

import { df, moment } from 'util/dateTime';

import AlertsListItem from '../AlertsListItem';

@connect(() => ({}), { dismissAlert: dismissClockOutApprovalAlert })
export default class ClockOutApproval extends PureComponent {
  static propTypes = {
    item: ImmutablePropTypes.map.isRequired,
    dismissAlert: PropTypes.func.isRequired,
  };

  handleEdit = () => {
    const { item } = this.props;

    const jobId = item.get('job_id');
    const date = moment(item.get('scheduled_start_date')).format(
      df('parsable_dashed')
    );

    window.location = `/timesheets#detailed/${jobId}/${date}/${date}`;
  };

  handleDismiss = () => this.props.dismissAlert(this.props.item);

  render() {
    const { item } = this.props;

    return (
      <AlertsListItem
        desc={item.get('body')}
        onEdit={this.handleEdit}
        onDismiss={this.handleDismiss}
        {...this.props}
      />
    );
  }
}
