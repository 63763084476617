import './SubNav.scss';

import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { openAddApplicantsManuallyDrawer } from 'actions/hiring';

import * as navSelectors from 'selectors/nav';
import * as sessionSelectors from 'selectors/session';

import UpgradeWrapper from 'features/permissions/UpgradeWrapper';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import withCurrentRoute from 'components/hocs/withCurrentRoute';
import { MobileAndTablet } from 'components/MediaQuery';

import cxHelpers from 'util/className';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import SubNavItem from '../items/SubNavItem';

@withCurrentRoute
@connect(
  (state, props) => ({
    subNavItems: navSelectors.getCurrentSubNavItems(state, props),
    activeItem: navSelectors.getActiveSubNavItem(state, props),
    mobileSubNavOpen: navSelectors.getMobileSubNavOpen(state, props),
    isOwner: sessionSelectors.getCurrentUserIsOwner(state, props),
    isManagerial: sessionSelectors.getCurrentUserIsManagerial(state, props),
    repackagingEnabled: sessionSelectors.getRepackagingEnabled(state, props),
    currentLocationId: sessionSelectors.getCurrentLocationId(state, props),
  }),
  {
    openAddApplicantsManuallyDrawer,
  }
)
@cxHelpers('SubNav')
export default class SubNav extends PureComponent {
  static propTypes = {
    subNavItems: ImmutablePropTypes.list,
    activeItem: ImmutablePropTypes.map,
    mobileSubNavOpen: PropTypes.bool,
    repackagingEnabled: PropTypes.bool,
    openAddApplicantsManuallyDrawer: PropTypes.func,
    currentLocationId: PropTypes.number,
  };

  itemActive = subNavItem => {
    const { currentRoute } = this.props;
    const url = subNavItem.get('url');
    const urlIncludes = subNavItem.get('url_includes');

    if (urlIncludes) {
      return urlIncludes.some(urlItem => currentRoute.includes(urlItem));
    }

    return this.props.activeItem.get('url') === url;
  };

  renderSubNavItems(mobile) {
    return this.props.subNavItems.map(subNavItem => {
      const url = subNavItem.get('url');

      return (
        <SubNavItem
          key={url}
          mobile={mobile}
          active={this.itemActive(subNavItem)}
          openAddApplicantsManuallyDrawer={
            this.props.openAddApplicantsManuallyDrawer
          }
          currentLocationId={this.props.currentLocationId}
          {...subNavItem.toJS()}
        />
      );
    });
  }

  trackExportReportClicked = () =>
    trackUxEvent({
      productArea: PRODUCT_AREAS.SCHEDULE,
      eventCategory: EVENT_CATEGORIES.TEAM_AVAILABILITY,
      eventAction: EVENT_ACTIONS.EXPORT_REPORT_CLICKED,
    });

  renderRightSideItems() {
    const { currentRoute } = this.props;

    if (currentRoute === '/availabilities') {
      return (
        // after removing repackaging rollout remove `paywallVersion`
        // and move its value to `feature` prop
        <UpgradeWrapper
          feature="availability_export"
          paywallVersion={
            this.props.repackagingEnabled ? 'availability_approval' : null
          }
        >
          <Button
            onClick={this.trackExportReportClicked}
            theme="link-purple"
            className="js-print-csv"
            href="/availabilities.csv"
            target="_blank"
            i18n="nav_links.export_report"
          />
        </UpgradeWrapper>
      );
    }
  }

  render() {
    const { subNavItems, mobileSubNavOpen } = this.props;

    if (!subNavItems.size) {
      return null;
    }

    return (
      <MobileAndTablet>
        {(mobile, tablet) => {
          const open = (!mobile && !tablet) || mobileSubNavOpen;

          return (
            <Box
              className={this.cx({ open })}
              relative
              bg="grayLight"
              spacebetween={!mobile}
              hcenter={mobile}
            >
              <Box paxs vcenter={!mobile} hcenter={mobile} column={mobile}>
                {this.renderSubNavItems(mobile)}
              </Box>

              {!mobile && (
                <Box vcenter mrs paxs>
                  {this.renderRightSideItems()}
                </Box>
              )}
            </Box>
          );
        }}
      </MobileAndTablet>
    );
  }
}
