import React, { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import MaskedTextInput from 'fe-design-base/atoms/MaskedTextInput';
import { TextInputProps } from 'fe-design-base/atoms/TextInput';
import { escapeRegExp } from 'lodash';
import { createNumberMask } from 'text-mask-addons';

export interface NumberInputProps extends TextInputProps {
  /** Number of decimal places */
  places?: number;
  /** Normalizes value with specified places onBlur */
  requireDecimal?: boolean;
  /** Allows for negative value */
  allowNegative?: boolean;
  /** Prefix for presentation of value */
  prefix?: string;
  /** Suffix for presentation of value */
  suffix?: string;
  /** Left aligns input value */
  leftAlign?: boolean;
  uxElement?: string;
}

const NumberInput = forwardRef(
  (
    {
      places = 2,
      requireDecimal = false,
      allowNegative = false,
      prefix = '',
      suffix = '',
      leftAlign = false,
      cx,
      uxElement,
      label,
      name,
      ...otherProps
    }: NumberInputProps & CxProps,
    ref: ForwardedRef<any>
  ) => {
    const mask = useMemo(
      () =>
        createNumberMask({
          prefix,
          suffix,
          allowDecimal: places > 0,
          decimalLimit: places,
          allowLeadingZeros: true,
          allowNegative,
        }),
      [allowNegative, places, prefix, suffix]
    );

    const placeholder = `${prefix}0${
      places > 0 ? `.${'0'.repeat(places)}` : ''
    }${suffix}`;

    const stripChars = useMemo(() => {
      const entries = [/,/g];
      if (prefix)
        entries.push(
          new RegExp(
            `^${escapeRegExp(prefix)}|(?:(?!-)${escapeRegExp(prefix)})`
          )
        );
      if (suffix) entries.push(new RegExp(`${escapeRegExp(suffix)}$`));
      return entries;
    }, [prefix, suffix]);

    const handleFormatDecimals = useCallback(
      (strippedValue: string) =>
        parseFloat(strippedValue).toFixed(places).toString() as string,
      [places]
    );

    return (
      <MaskedTextInput
        placeholder={placeholder}
        ref={ref}
        mask={mask}
        name={name}
        label={label}
        uxElement={uxElement}
        stripChars={stripChars}
        className={cx({
          'left-align': leftAlign,
        })}
        normalizeOnBlur={
          requireDecimal && places ? handleFormatDecimals : undefined
        }
        {...otherProps}
      />
    );
  }
);

export default withCx<NumberInputProps>('FDBNumericInput')(NumberInput);
