import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';

import GoToManagePlanPageButton from 'features/managePlan/GoToManagePlanPageButton';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { selectMultiLocationEnforcementDeadLine } from './selectors';
import { deadlineToDate, toMonthDayOrdinal } from './util';

const { cx, cxEl } = cxHelpers('MultiLocationView');

export const MultiLocationView = ({
  onClose,
  onClickCTATracking,
  multiLocationEnforcementDeadLine,
}) => {
  const deadline = deadlineToDate(multiLocationEnforcementDeadLine);
  const bannerText = toI18n('enforcement.multi_location.banner_text', {
    props: { date: toMonthDayOrdinal(deadline) },
  });

  return (
    <Box row vcenter spacebetween ph={16} className={cx()} bgcolor="red300">
      <Text variant="body" color="mono0">
        {bannerText}
      </Text>

      <Box row vcenter>
        <Box mr={50}>
          <GoToManagePlanPageButton
            size="small"
            variant="tertiary"
            baseComponent="designbase"
            onClick={onClickCTATracking}
          >
            <Box row>
              <Text variant="bodyBold" color="mono0" underline>
                {toI18n('enforcement.banner_cta')}
              </Text>
              <Icon iconName="ArrowRight" size="medium" color="mono0" />
            </Box>
          </GoToManagePlanPageButton>
        </Box>

        <Icon
          color="mono0"
          size="medium"
          iconName="Close"
          onClick={onClose}
          className={cxEl('close-button')}
          dataTestId={cxEl('close-button')}
        />
      </Box>
    </Box>
  );
};

export default connect(state => ({
  multiLocationEnforcementDeadLine:
    selectMultiLocationEnforcementDeadLine(state),
}))(MultiLocationView);
