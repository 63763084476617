import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorFacebook = ({
  alt = 'Facebook Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M29.333 16c0-7.364-5.97-13.334-13.333-13.334C8.636 2.666 2.666 8.636 2.666 16c0 6.655 4.876 12.17 11.25 13.171v-9.317h-3.385V16h3.385v-2.938c0-3.341 1.991-5.187 5.037-5.187 1.458 0 2.984.26 2.984.26v3.281h-1.68c-1.657 0-2.174 1.028-2.174 2.084V16h3.698l-.591 3.854h-3.107v9.317c6.374-1 11.25-6.516 11.25-13.171Z"
      fill="#1877F2"
    />
  </svg>
);
export default SvgColorFacebook;
