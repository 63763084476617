import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import CircularInfinite from 'fe-design-base/molecules/Loaders/CircularInfinite';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

import { getCompanyRolesArray } from 'selectors/addTeam';
import { getPacketProps } from 'selectors/drawers';

import BenefitsWidget from 'features/employeeProfile/JobDetailsView/BenefitsWidget';
import PostTaxDeductionsWidget from 'features/employeeProfile/JobDetailsView/PostTaxDeductionsWidget/PostTaxDeductionsWidget';
import RecurringEarningsWidget from 'features/employeeProfile/JobDetailsView/RecurringEarningsWidget';
import {
  FORM_WITH_STEPS_BOX_STYLE,
  I18N_TITLE_PATH,
} from 'features/team/components/AddEmployeeForm/constants';
import { closeAddNewRoleModal, openToast } from 'features/team/slice';

import { toI18n } from 'util/i18n';

const StepTwo = ({ updateUserInfo, user, showFormWithSteps }) => {
  const [userObj, setUserObj] = useState(user);

  useEffect(() => setUserObj(user), [user]);

  if (isEmpty(user)) {
    return <CircularInfinite size="large" variant="dark" />;
  }

  return (
    <Box {...FORM_WITH_STEPS_BOX_STYLE}>
      <Box mb={24}>
        <Box mb={12}>
          <Text color="mono900" variant="heading3">
            {toI18n(`${I18N_TITLE_PATH}.benefits_and_deductions.title`)}
          </Text>
        </Box>
        <Text color="mono700" variant="bodySm">
          {toI18n(`${I18N_TITLE_PATH}.benefits_and_deductions.subtitle`, {
            props: { teamMemberFirstName: user.get('first_name') },
          })}
        </Text>
      </Box>
      <BenefitsWidget
        user={userObj}
        updateUserInfo={updateUserInfo}
        showFormWithSteps={showFormWithSteps}
      />
      <PostTaxDeductionsWidget
        user={userObj}
        updateUserInfo={updateUserInfo}
        showFormWithSteps={showFormWithSteps}
      />
      <RecurringEarningsWidget
        user={userObj}
        updateUserInfo={updateUserInfo}
        showFormWithSteps={showFormWithSteps}
      />
    </Box>
  );
};

StepTwo.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  showFormWithSteps: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    companyRolesArray: getCompanyRolesArray(state),
    packetProps: getPacketProps(state),
  }),
  {
    onCloseAddNewRoleModal: closeAddNewRoleModal,
    onOpenToast: openToast,
  }
)(StepTwo);
