import { useCallback, useState } from 'react';

import { CommandBarCallbackAction } from 'features/commandBar/commandBarCallbackActions';

import { datadogLogInfo } from 'util/datadogLogs';

// ---------------------------------------------------------------
// Hooks
// ---------------------------------------------------------------
export const useRegisterCommandBarCallbackAction = (
  callbackSlug: string,
  callback: CommandBarCallbackAction
) => {
  const [registered, setRegistered] = useState(false);
  const registerCallbackAction = useCallback(() => {
    if (registered) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).CommandBar) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).CommandBar.addCallback(callbackSlug, callback);
      setRegistered(true);
    } else {
      datadogLogInfo({
        message: 'command.bar.callback.not.registered',
        context: { callbackSlug },
      });
    }
  }, [registered, callback, setRegistered, callbackSlug]);

  // If CommandBar is already loaded, register the callback action immediately
  // Otherwise, wait for the 'commandbar-loaded' event, because in case
  // the CustomEvent is fired before the callback action is added to event listeners,
  // the event will be missed, therefore no callback action will be registered
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).CommandBar) {
    registerCallbackAction();
  } else {
    window.addEventListener('commandbar-loaded', registerCallbackAction);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRegisterCommandBarRouter = (history: any) => {
  const [registered, setRegistered] = useState(false);
  const registerRouter = useCallback(() => {
    if (registered) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).CommandBar) {
      let routerFunc;

      try {
        routerFunc = (newUrl: string) => history.push(newUrl);
      } catch (e) {
        datadogLogInfo({
          message: 'command.bar.router.error',
          context: { error: e },
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).CommandBar.addRouter(routerFunc);
      setRegistered(true);
    } else {
      datadogLogInfo({
        message: 'command.bar.router.not.registered',
      });
    }
  }, [registered, history]);

  // If CommandBar is already loaded, register the router immediately
  // Otherwise, wait for the 'commandbar-loaded' event, because in case
  // the CustomEvent is fired before the router is added registered,
  // the event will be missed, therefore no router will be registered
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if ((window as any).CommandBar) {
    registerRouter();
  } else {
    window.addEventListener('commandbar-loaded', registerRouter);
  }
};
