import { fromJS, List, Map } from 'immutable';

import { actionTypes } from 'actions/userReferrals';

import { momentInZone } from 'util/dateTime';

const INITIAL_STATE = Map({
  referrals: List(),
});

const getNewlyAddedEmails = (state, payload) => {
  const referrals = state.get('referrals');
  let id = referrals.size > 0 ? referrals.last().get('id') + 1 : 0;
  const createdAt = momentInZone().format('MMM D, YYYY');
  const status = 'sent';
  const amountInDollars = payload.amount;

  const newEmails = payload.emailAddresses.map(email => {
    id += 1;

    return {
      id,
      referred_user_email: email,
      status,
      created_at: createdAt,
      amount_in_dollars: amountInDollars,
    };
  });
  return fromJS(newEmails);
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_EMAIL_ADDRESSES:
      return state.merge({
        referrals: state
          .get('referrals')
          .concat(getNewlyAddedEmails(state, action.payload)),
      });
    case actionTypes.FETCH_USER_REFERRALS_SUCCESS: {
      return state.merge({
        referrals: action.payload,
      });
    }

    default:
      return state;
  }
};
