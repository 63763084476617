import React, { PureComponent } from 'react';

import Box from 'components/Box';

import cxHelpers from 'util/className';

import SignOutNavItem from '../items/SignOutNavItem';

@cxHelpers('UserOnlyNav')
export default class UserOnlyNav extends PureComponent {
  render() {
    return (
      <Box className={this.cx()} mls row hright grow>
        <SignOutNavItem />
      </Box>
    );
  }
}
