import { isPlainObject, omitBy, snakeCase } from 'lodash';

import { getCookie } from 'util/cookies';

export const addCommonUxProperties = properties => {
  const path = window.location.pathname || '';
  const hash = window.location.hash || '';

  return {
    ...properties,
    full_path: path + hash,
    filtered_path: path.replace(/[0-9]+/g, '*'),
    query_params: window.location.search,
    screen_width: window.screen.width,
    screen_height: window.screen.height,
    irclickid: getCookie('irclickid'),
  };
};

export const formatAmplitudeData = ({
  event_name,
  product_area,
  event_category,
  properties = {},
}) => {
  const specialChars = /[^\w\s]/gi;

  return {
    event_name,
    product_area: product_area
      ? snakeCase(product_area.replace(specialChars, ''))
      : null,
    event_category: event_category
      ? snakeCase(event_category.replace(specialChars, ''))
      : null,
    // Prevents nested object properties from being sent to the SDK
    ...omitBy(properties, prop => isPlainObject(prop)),
  };
};

export const updateGlobalUxTrackingData = newProps => {
  if (window.Homebase?.uxTrackingData) {
    window.Homebase.uxTrackingData = {
      ...window.Homebase.uxTrackingData,
      ...newProps,
    };
  }
};
