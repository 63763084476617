import { SLICE_NAME } from './constants';

export const getViewLogic = (state, key) =>
  state.getIn([SLICE_NAME, 'viewLogic', key]);

export const getSubmitPending = state =>
  state.getIn([SLICE_NAME, 'submitPending']);

export const getExperimentRefetchStatus = (state, key) =>
  state.getIn([SLICE_NAME, 'experimentReload', key]);

const geApiStatuses = state => state.getIn([SLICE_NAME, 'apiStatuses']);

export const getExperimentsEligibleForApi = (state, experimentNames) => {
  const activeApiStatuses = geApiStatuses(state)
    .filter(value => value === true)
    .keySeq()
    .toArray();

  return experimentNames.filter(experimentName =>
    activeApiStatuses.includes(experimentName)
  );
};
