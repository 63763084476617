import React from 'react';
import { useDispatch } from 'react-redux';
import { healthAnswersRoute } from 'api';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import { dismissHealthAlert } from 'actions/notifications';

import { toI18n } from 'util/i18n';

import AlertsListItem from '../AlertsListItem';

const navigateToSafety = () =>
  window.Homebase.RailsReactBridge.navigateToReactView(healthAnswersRoute());

const HealthAlert = ({ item, ...restProps }) => {
  const dispatch = useDispatch();

  const dismiss = partial(dispatch, dismissHealthAlert(item));

  const descProps = () => ({
    user: item.get('user'),
    answer: toI18n(`actions.${item.get('answer')}_word`),
    question: item.get('question'),
  });

  const desc = toI18n('notifications.alerts.health_alert.desc', {
    props: descProps(),
  });

  return (
    <AlertsListItem
      item={item}
      desc={desc}
      onDismiss={dismiss}
      onEdit={navigateToSafety}
      editButton={toI18n('actions.view')}
      {...restProps}
    />
  );
};

HealthAlert.propTypes = {
  item: PropTypes.object.isRequired,
};

export default HealthAlert;
