import destroyEntityRelationship from './destroyEntityRelationship';
import { BELONGS_TO } from './relationshipTypes';
import { getSchema } from './schema';

// helper method
// returns entity.relationships[name] data always as an array
const getRelatedEntities = (entity, name, relationshipType) => {
  if (relationshipType === BELONGS_TO) {
    const data = entity.relationships?.[name]?.data;
    return [
      {
        type: data?.type,
        id: data?.id,
      },
    ];
  }
  return entity?.relationships?.[name]?.data || [];
};

// when destroying entity (state[type][id]), to remove its id from all other entity relationships
const updateRelationships = (state, type, id) => {
  const schema = getSchema(type);
  if (!schema) return state;

  const entity = state[type]?.[id];

  // If entity has already been deleted, ignore.  This
  // might happen if response includes meta.deleted list
  // but a relationship change caused it to be deleted
  // already.
  if (!entity) return state;

  return Object.entries(schema.relationships).forEach(
    ([name, { relationshipType }]) => {
      const relatedEntities = getRelatedEntities(
        entity,
        name,
        relationshipType
      );
      relatedEntities.forEach(({ type: relatedType, id: relatedId }) =>
        destroyEntityRelationship(state, type, id, name, relatedType, relatedId)
      );
    }
  );
};

// removes entity and entityId from all relationships
// it also deletes all relatedEntities that BELONGS_TO removed entity
const destroy = (state, type, id) => {
  updateRelationships(state, type, id);

  if (state[type]?.[id]) delete state[type][id];
};

export default destroy;
