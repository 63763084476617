import { compact } from 'lodash';

import { createStringMatcher } from 'util/matchers';
export const buildFullName = user =>
  compact([user.get('first_name'), user.get('last_name')]).join(' ');

export const fullName = user => user?.get('name', buildFullName(user));

export const firstNameLastInitial = name => {
  const [first, last] = name.split(' ');
  return last ? `${first} ${last[0]}` : first;
};

export const filterUsersBySearchTerm = (users, searchTerm) => {
  if (!searchTerm) {
    return users.sortBy(user =>
      user.get('pending_jobs').size > 0
        ? '0'
        : user.get('full_name').toLowerCase()
    );
  }
  const stringMatcher = createStringMatcher(searchTerm);
  const searchPredicate = matcher => user =>
    matcher(user.get('full_name')) || matcher(user.get('email'));
  return users.filter(searchPredicate(stringMatcher));
};
