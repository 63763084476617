import { fromJS } from 'immutable';

import { actionTypes as notificationActionTypes } from 'actions/notifications';
import { actionTypes } from 'actions/timeOffsDashboard';

const DEFAULT_BALANCES_YEARS_OPTIONS = [{ label: '', value: '' }];

const INITIAL_STATE = fromJS({
  loading: true,
  timeOffs: [],
  upcoming: [],
  balances: {
    years_options: DEFAULT_BALANCES_YEARS_OPTIONS,
    rows: [],
  },
  timeOffLocationSettings: [],
  noPolicyCategoryNames: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAGE_DATA_REQUEST:
      return state.merge({
        loading: true,
      });
    case actionTypes.FETCH_PAGE_DATA_FAILURE:
      return state.merge({
        loading: false,
      });
    case actionTypes.FETCH_PAGE_DATA_SUCCESS:
      return state.merge({
        loading: false,
        timeOffs: action.payload.time_offs,
        balances: action.payload.balances,
        upcoming: action.payload.upcoming,
        timeOffLocationSettings: action.payload.time_off_location_settings,
        noPolicyCategoryNames: action.payload.no_policy_category_names,
      });

    case notificationActionTypes.MANAGER_APPROVE_TIMEOFF_SUCCESS:
    case notificationActionTypes.MANAGER_REJECT_TIMEOFF_SUCCESS: {
      const index = state
        .get('timeOffs')
        .findIndex(item => item.get('id') === action.payload.id);

      return state.merge({
        timeOffs: [
          ...state.get('timeOffs').slice(0, index),
          ...state.get('timeOffs').slice(index + 1),
        ],
      });
    }

    default:
      return state;
  }
};
