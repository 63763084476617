import './LoadingIndicator.scss';

import React, { PureComponent } from 'react';
import { kebabCase, keys, omit } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import cxHelpers from 'util/className';

const DEFAULT_SIZE = 40;
// ["1", "2", ..., "9"]
const SQUARE_INDICES = [...Array(9).keys()].map(i => (i + 1).toString());

export const TYPES = ['bigH', 'threeDots', 'bigClock', 'grayBar'];

const EXPLICIT_PROPS = {
  size: PropTypes.number,
  type: PropTypes.oneOf(TYPES),
  centered: PropTypes.bool,
  hAbsCentered: PropTypes.bool,
  fCentered: PropTypes.bool,
};

const EXPLICIT_PROP_KEYS = keys(EXPLICIT_PROPS);

@cxHelpers('LoadingIndicator')
class LoadingIndicator extends PureComponent {
  static propTypes = EXPLICIT_PROPS;

  static defaultProps = {
    size: DEFAULT_SIZE,
    type: 'threeDots',
  };

  getClassName = () => {
    const { centered, type, hAbsCentered, fCentered } = this.props;
    return this.cx({
      [kebabCase(type)]: type,
      centered,
      'h-abs-centered': hAbsCentered,
      'f-centered': fCentered,
    });
  };

  boxProps() {
    return omit(this.props, EXPLICIT_PROP_KEYS);
  }

  renderBigH() {
    const { size } = this.props;
    const style = { width: size, height: size };

    return (
      <Box {...this.boxProps()} className={this.getClassName()} style={style}>
        {SQUARE_INDICES.map(i => (
          <div key={i} className={this.cxEl('square', i)} />
        ))}
      </Box>
    );
  }

  renderGrayBar() {
    return <Box {...this.boxProps()} className={this.getClassName()} />;
  }

  renderDefault() {
    return (
      <Box {...this.boxProps()} className={this.getClassName()}>
        <div className={this.cxEl('child')} />
      </Box>
    );
  }

  render() {
    switch (this.props.type) {
      case 'bigH':
        return this.renderBigH();
      case 'greyBar':
        return this.renderGrayBar();
      case 'bigClock':
      case 'threeDots':
      default:
        return this.renderDefault();
    }
  }
}

export default LoadingIndicator;
