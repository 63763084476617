import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCaretDown = ({
  alt = 'Caret Down Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.305 18.317a2.451 2.451 0 0 0 3.396 0l9.596-9.33c.69-.67.892-1.67.517-2.544A2.402 2.402 0 0 0 21.595 5l-19.192.007A2.41 2.41 0 0 0 .184 6.45a2.288 2.288 0 0 0 .517 2.544l9.596 9.33.008-.007Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCaretDown;
