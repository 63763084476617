import './Text.scss';

import React, { FC } from 'react';
import TypographyBase from '@mui/material/Typography';
import withCx, { CxProps } from 'fe-core/util/withCx';
import { paletteCustom } from 'fe-design-base/styles/palette';
import { TypographyVariant } from 'fe-design-base/types';

import { toI18n } from 'util/i18n';

export const TYPOGRAPHY_COLOR_OPTIONS = [
  'mono0',
  'mono500',
  'mono700',
  'mono900',
  'info300',
  'primary300',
  'primary500',
  'primary700',
  'warning300',
  'warning500',
  'error300',
  'error500',
  'success300',
  'secondary500',
] as const;
export type TypographyColors = (typeof TYPOGRAPHY_COLOR_OPTIONS)[number];

export const TEXT_ALIGN_VALUES = [
  'center',
  'inherit',
  'justify',
  'left',
  'right',
] as const;
type TextAlignValues = (typeof TEXT_ALIGN_VALUES)[number];

export interface TextProps {
  children?: React.ReactNode | string;
  variant?: TypographyVariant;
  /*
   * If no color is passed, color will default to 'inherit'
   */
  color?: TypographyColors;
  component?: FC | string;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  capitalize?: boolean;
  uppercase?: boolean;
  lowercase?: boolean;
  titlecase?: boolean;
  asHTML?: boolean;
  inline?: boolean;
  i18n?: string;
  i18nProps?: object;
  /*
   * From MUI Docs: If true, the text will not wrap, but instead will truncate with a text overflow
   * ellipsis. Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   */
  noWrap?: boolean;
  textAlign?: TextAlignValues;
  className?: string;
  id?: string;
}

const Text = ({
  children,
  variant = 'body',
  color,
  component,
  asHTML,
  noWrap,
  textAlign,
  cx,
  cxEl,
  i18n,
  i18nProps,
  ...classProps
}: TextProps & CxProps) => {
  let textContent = children;
  if (i18n) {
    const i18nText = toI18n(i18n, { props: i18nProps || {} });
    textContent = asHTML ? (
      <span dangerouslySetInnerHTML={{ __html: i18nText }} />
    ) : (
      i18nText
    );

    if (children) {
      textContent = (
        <>
          {textContent}
          {children}
        </>
      );
    }
  }

  const content =
    asHTML && typeof textContent === 'string'
      ? { dangerouslySetInnerHTML: { __html: textContent } }
      : { children: textContent };

  return (
    <TypographyBase
      variant={variant}
      fontFamily="'Plus Jakarta Sans', sans-serif"
      color={color ? paletteCustom[color] : 'inherit'}
      noWrap={noWrap}
      align={textAlign}
      className={cx(classProps)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - TS2322 due to incorrect inheritance from styled component
      component={component}
      {...content}
    />
  );
};

export default withCx<TextProps>('FDBText')(Text);
