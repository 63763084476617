import React from 'react';
import Icon from 'react-icon-base';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

const CoffeeBreak = ({ color, width, height, ...props }) => (
  <Icon
    width={width || '36'}
    height={height || '36'}
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill={color || colors.purple}
      fillRule="evenodd"
      d="M32.546 10.12a.72.72 0 0 1-.728.708H4.182a.72.72 0 0 1-.727-.709V8.703a.72.72 0 0 1 .727-.709h27.636c.4 0 .728.319.728.709v1.416zm-5.82 22.67c0 .432-.332.793-.727.793H10c-.394 0-.727-.361-.733-.876l-2.48-20.463h22.426l-2.485 20.547zM7.794 3.747c.358-1.33.614-1.33.752-1.33h18.91c.19 0 .37.41.625 1.038l1.002 3.123H6.909l.884-2.83zm24.025 2.832h-1.207l-1.029-3.28-.15-.363C29.066 2.027 28.648 1 27.456 1H8.544C7.03 1 6.612 2.553 6.395 3.36L5.388 6.578H4.182C2.979 6.578 2 7.53 2 8.703v1.417c0 1.171.979 2.125 2.182 2.125h1.142L7.818 32.79C7.818 34.01 8.798 35 10 35h16c1.203 0 2.182-.99 2.176-2.125l2.5-20.63h1.142c1.203 0 2.182-.954 2.182-2.125V8.703c0-1.172-.979-2.125-2.182-2.125z"
      clipRule="evenodd"
    />
  </Icon>
);

CoffeeBreak.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CoffeeBreak;
