import { omit } from 'lodash';

import {
  TIME_OFF_CATEGORY_NAMES,
  UNPAID_TIME_OFF,
} from 'features/timeOff/constants';

import { toI18n } from 'util/i18n';
import { ordinalSuffix } from 'util/strings';

export const DETAILS_FORM_DEFAULTS = {
  name: '',
  accrual_method: 'hourly',
  total_hours: 0,
  hours_earned: 0,
  hours_worked: 0,
  max_carryover: 0,
  max_accrued: 0,
};

export const THROUGHOUT_THE_YEAR = 'throughout_the_year';
export const ANNUAL = 'annual';
export const FIXED = 'fixed';
export const HIRE_DATE_ANNIVERSARY = 'hire_date_anniversary';
export const HOURLY = 'hourly';
export const FIXED_PER_PAY_PERIOD = 'fixed_per_pay_period';
export const FREQUENCY_OPTIONS = [
  {
    label: toI18n('pto_policy.create.step_1.fixed.earning_frequency.option_1'),
    value: FIXED,
  },
  {
    label: toI18n('pto_policy.create.step_1.fixed.earning_frequency.option_2'),
    value: ANNUAL,
  },
  {
    label: toI18n('pto_policy.create.step_1.fixed.earning_frequency.option_3'),
    value: HIRE_DATE_ANNIVERSARY,
  },
];

const ONE_TO_THIRTY = Array.from({ length: 30 }, Number.call, i => i + 1);
export const i18nStep2BaseKey = 'pto_policy.create.step_2';
export const ANNIVERSARY_OPTIONS = ONE_TO_THIRTY.map(num => ({
  label: toI18n(`${i18nStep2BaseKey}.anniversary`, {
    props: { count: ordinalSuffix(num) },
  }),
  value: num,
}));

export const PTO_POLICY_CATEGORY_NAMES = omit(
  TIME_OFF_CATEGORY_NAMES,
  UNPAID_TIME_OFF
);

export const WEEK = 'week';
export const TWICE_WEEK = 'twice_week';
export const MONTH = 'month';
export const TWICE_MONTH = 'twice_month';

export const PAYROLL_MATCH = 'payroll_match';
export const PAYROLL_MISMATCH = 'payroll_mismatch';
export const PAYROLL_UNSET = 'payroll_unset';

export const DEFAULT_ACCRUAL_RATES = [
  { min_months_threshold: 0, hours_earned: '', hours_worked: '' },
];

export const PTO_POLICY_DETAILS = 'PTOPolicyDetails';
export const PTO_POLICY_OPTIONAL_SETTINGS = 'PTOPolicyOptionalSettings';
export const PTO_POLICY_ENROLL_EMPLOYEES = 'PTOPolicyEnrollEmployees';
export const PTO_POLICY_STARTING_BALANCES = 'PTOPolicyStartingBalances';
