import { Overrides } from '../../types';
import baseColors from '../colors';

export const linkOverrides: Overrides = {
  MuiLink: {
    styleOverrides: {
      root: () => ({
        '&.FDBLink': {
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          cursor: 'pointer',
          width: 'fit-content',
          color: baseColors.purple500,
          textDecoration: 'underline',
          textUnderlineOffset: '4px',
          textDecorationThickness: '1.5px',

          '&.FDBLink--allow-wrap': {
            whiteSpace: 'normal',
            display: 'inline',
          },
          '& .FDBIcon': {
            alignSelf: 'center',
          },
          '& span': {
            color: `${baseColors.purple500}`,
          },
          '&.FDBLink--xs:not(.FDBLink--allow-wrap)': {
            '& .MuiTypography-root': {
              lineHeight: '14px',
            },

            '& .FDBIcon': {
              transform: 'scale(0.875)',
            },
          },
          '&.FDBLink--small:not(.FDBLink--allow-wrap)': {
            '& .MuiTypography-root': {
              lineHeight: '16px',
            },

            '& .FDBIcon': {
              transform: 'scale(0.875)',
            },
          },
          '&.FDBLink--medium:not(.FDBLink--allow-wrap) .MuiTypography-root': {
            lineHeight: '20px',
          },
          '&:focus': {
            outline: 'none',
            color: `${baseColors.accent900}`,
          },
          '&:focus-visible': {
            outline: '2px solid',
            outlineOffset: '4px',
            outlineColor: baseColors.accent500,
            color: baseColors.accent900,
            textDecoration: 'none',
            borderRadius: '2px',

            '& span': {
              color: baseColors.accent900,
            },
          },
          '&:hover:not(:focus-visible)': {
            color: baseColors.accent900,
            textDecoration: 'none',

            '& span': {
              color: baseColors.accent900,
            },
          },
          '&:active:not(:focus-visible)': {
            color: baseColors.purple700,
            textDecoration: 'none',

            '& span': {
              color: `${baseColors.purple700}`,
            },
          },
          '&:visited': {
            color: baseColors.accent900,

            '& span': {
              color: `${baseColors.accent900}`,
            },
          },
        },
      }),
    },
  },
};
