import * as routes from 'api';

import * as tiersSelectors from 'selectors/tiers';

import * as constants from 'features/tiers/constants';

import * as reduxUtil from 'util/redux';

export const actionTypes = {
  FETCH_TIERS_DATA_REQUEST: 'TIERS/FETCH_TIERS_DATA_REQUEST',
  FETCH_TIERS_DATA_SUCCESS: 'TIERS/FETCH_TIERS_DATA_SUCCESS',
  FETCH_TIERS_DATA_FAILURE: 'TIERS/FETCH_TIERS_DATA_FAILURE',
};

const fetchTiersDataMeta = { requestId: constants.REQUEST_KEYS.tiersData };

export const fetchTiersData = () =>
  reduxUtil.createAsyncGetAction(
    routes.tiersRoute(),
    [
      { type: actionTypes.FETCH_TIERS_DATA_REQUEST, meta: fetchTiersDataMeta },
      { type: actionTypes.FETCH_TIERS_DATA_SUCCESS, meta: fetchTiersDataMeta },
      { type: actionTypes.FETCH_TIERS_DATA_FAILURE, meta: fetchTiersDataMeta },
    ],
    {
      bailout: state => tiersSelectors.getFetchTiersDataShouldBailout(state),
    }
  );
