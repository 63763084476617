import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCalendar = ({
  alt = 'Calendar Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M6 1.5a1.5 1.5 0 1 1 3 0V3h6V1.5a1.5 1.5 0 1 1 3 0V3h2.25a2.25 2.25 0 0 1 2.25 2.25V7.5h-21V5.25A2.25 2.25 0 0 1 3.75 3H6V1.5Zm16.5 20.25A2.25 2.25 0 0 1 20.25 24H3.75a2.25 2.25 0 0 1-2.25-2.25V9h21v12.75Z"
    />
  </svg>
);
export default SvgCalendar;
