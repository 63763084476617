import './NavItemWithSubNav.scss';

import React, { PureComponent } from 'react';
import Collapse from '@material-ui/core/Collapse';
import MuiList from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import { List, Map } from 'immutable';
import { omit, partial } from 'lodash';
import PropTypes from 'prop-types';

import { TRACK_EVENTS } from 'features/hiring/addApplicantManually/tracking_constants';
import { APPLIED_TO_LOCATION_VALUE } from 'features/hiring/manageApplicants/constants';
import UpgradeWrapper from 'features/permissions/UpgradeWrapper';

import Box from 'components/Box';
import Icon from 'components/Icon';
import { Desktop } from 'components/MediaQuery';
import Pill from 'components/Pill';
import Popper from 'components/Popper';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import {
  logHiringEvent,
  setOriginContextByUrl,
  trackAddApplicantsManuallyEvent,
  trackUxEvent,
} from 'util/tracking';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';

import withCloseMobileNav from '../../hocs/withCloseMobileNav';
import NavItem from '../NavItem';

@withCloseMobileNav
@cxHelpers('NavItemWithSubNav')
export default class NavItemWithSubNav extends PureComponent {
  static propTypes = {
    onCloseMobileNav: PropTypes.func,
    menuItems: PropTypes.instanceOf(List).isRequired,
    openAddApplicantsManuallyDrawer: PropTypes.func,
    currentLocationId: PropTypes.number,
  };

  state = {
    open: false,
    anchorEl: null,
  };

  receiveAnchorEl = element => this.setState({ anchorEl: element });

  handleTouchTap = event => {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      open: !this.state.open,
    });
  };

  handleRequestClose = () => this.setState({ open: false });

  handleClick = (item, e) => {
    e.preventDefault();
    const url = item.get('url');
    const logHiringData = item.get('log_hiring_data', Map());
    const eventAction = item.get('eventAction');
    setOriginContextByUrl(url);

    if (!logHiringData.isEmpty()) {
      logHiringEvent(logHiringData.toJS());
    }

    if (eventAction) {
      trackUxEvent({
        productArea: PRODUCT_AREAS.NAVIGATION,
        eventCategory: EVENT_CATEGORIES.SUB_MENU,
        eventAction,
      });
    }

    window.Homebase.RailsReactBridge.navigateToReactView(url);
    if (
      url ===
      `/hiring/locations/${this.props.currentLocationId}/manage_applicants`
    ) {
      trackAddApplicantsManuallyEvent(TRACK_EVENTS.HIRING_SUBNAV_DROPDOWN);

      this.props.openAddApplicantsManuallyDrawer(
        APPLIED_TO_LOCATION_VALUE,
        this.props.currentLocationId
      );
    }
    this.handleRequestClose();
    this.handleMouseLeave();
    this.props.onCloseMobileNav();
  };

  handleMouseEnter = () => this.setState({ open: true });
  handleMouseLeave = () => this.setState({ open: false });

  renderMenuBadge = badge => (
    <Box className={this.cxEl('badge')} inlineBlock>
      <Pill bradius={3} size="xs" ph={4} lh={1.1} ml={8}>
        <Text fs10 fw600 lspacing="initial">
          {badge}
        </Text>
      </Pill>
    </Box>
  );

  renderMenu(items, mobile) {
    return (
      <Popper
        anchorEl={this.state.anchorEl}
        open={this.state.open}
        placement="bottom-start"
      >
        <Paper>
          <MenuList>
            {items.map((item, i) => this.renderMenuItem(item, i, mobile))}
          </MenuList>
        </Paper>
      </Popper>
    );
  }

  renderMenuItem(item, i, mobile) {
    const url = item.get('url');
    const text = item.get('text');
    const feature = item.get('feature');
    const heapPaywallTrigger = item.get('heapPaywallTrigger');
    const badge = item.get('badge');

    const active = this.props.currentRoute.startsWith(url);

    const menuItem = (
      <MenuItem
        className={this.cxEl(
          'menu-item',
          { active, mobile },
          'NavItem__menu-item'
        )}
        key={i}
        onClick={partial(this.handleClick, item)}
      >
        <a>
          <Box h={mobile ? 30 : 40} vcenter>
            <Text
              fs14
              purpleLight={mobile && !active}
              white={mobile && active}
              fw400={mobile}
              className={this.cxEl('sub-menu', { mobile })}
            >
              {text}
              {badge && this.renderMenuBadge(badge)}
            </Text>
          </Box>
        </a>
      </MenuItem>
    );

    return feature ? (
      <UpgradeWrapper
        key={i}
        feature={feature}
        onClick={this.handleRequestClose}
        heapPaywallTrigger={heapPaywallTrigger}
        className="NavItem__menu-item"
      >
        {menuItem}
      </UpgradeWrapper>
    ) : (
      menuItem
    );
  }

  renderTabletAndBelow() {
    const { currentRoute, children, menuItems, ...navItemProps } = this.props;

    return (
      <NavItem
        {...navItemProps}
        persistMobileNavOnClick
        onClick={this.handleTouchTap}
        vcenter={false}
        h="auto"
        noAnchor
      >
        <Box h={40} vcenter relative>
          {children}

          <Box absolute top={10} right={0}>
            <Icon
              type={this.state.open ? 'chevronDownSlim' : 'chevronRightSlim'}
            />
          </Box>
        </Box>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <MuiList component="div" disablePadding>
            {menuItems.map((item, i) => this.renderMenuItem(item, i, true))}
          </MuiList>
        </Collapse>
      </NavItem>
    );
  }

  renderDesktop() {
    const { children, currentRoute, menuItems, ...navItemProps } = omit(
      this.props,
      'openAddApplicantsManuallyDrawer',
      'currentLocationId'
    );

    return (
      <Box
        relative
        onClick={this.handleMouseEnter}
        onMouseOver={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        getRef={this.receiveAnchorEl}
      >
        <NavItem {...navItemProps}>{children}</NavItem>
        {menuItems.size > 0 && this.renderMenu(menuItems)}
      </Box>
    );
  }

  render() {
    return (
      <Desktop>
        {desktop =>
          desktop ? this.renderDesktop() : this.renderTabletAndBelow()
        }
      </Desktop>
    );
  }
}
