/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React, { memo, useMemo } from 'react';
import Box from 'fe-design-base/atoms/Box';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('LaunchWeekStoryPagination');

import LaunchWeekStoryPaginationStep from './LaunchWeekStoryPaginationStep';

const LaunchWeekStoryPagination = ({
  awardHowYouDoItStepDelayMs,
  awardLastStepDelayMs,
  awardStepsDelayMs,
  currentStep,
  numSteps,
}) => {
  const steps = useMemo(() => Array.from(Array(numSteps).keys()), [numSteps]);

  const isLastStep = currentStep === numSteps - 1;
  const isHowYouDoItStep = currentStep === numSteps - 4;

  const defaultAwardDelayMs = isHowYouDoItStep
    ? awardHowYouDoItStepDelayMs
    : awardStepsDelayMs;

  return (
    <Box gap={4} className={cx()} hcenter bgcolor="purple900" bradius={8}>
      {steps.map(step => (
        <LaunchWeekStoryPaginationStep
          key={step}
          step={step}
          currentStep={currentStep}
          awardStepsDelayMs={
            isLastStep ? awardLastStepDelayMs : defaultAwardDelayMs
          }
        />
      ))}
      {/*
        Last placeholder pagination step, so the user can
        see there is still some content after JW's video.
      */}
      <Box
        className="LaunchWeekStoryPaginationStep"
        style={{ '--award-steps-delay-ms': `${awardStepsDelayMs}ms` }}
        w="100%"
        h={8}
        bgcolor="mono0"
      />
    </Box>
  );
};

export default memo(LaunchWeekStoryPagination);
