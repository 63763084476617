export const getObjectFromLocalStorage = key =>
  JSON.parse(localStorage.getItem(key));

export const updateObjectInLocalStorage = (key, object) => {
  validateObject(object);
  const item = JSON.parse(localStorage.getItem(key));
  localStorage.setItem(
    key,
    JSON.stringify({
      ...item,
      ...object,
    })
  );
};

export const setObjectInLocalStorage = (key, object) =>
  localStorage.setItem(key, JSON.stringify(object));

export const clearObjectInLocalStorage = key => localStorage.removeItem(key);

const validateObject = object => {
  if (typeof object !== 'object') {
    throw TypeError(`Invalid type: ${typeof object}, expecting object`);
  }
};
