import { toI18n } from 'util/i18n';

export const BENEFIT_TYPE_OPTIONS = [
  { value: '125_medical', label: '125 Medical' },
  { value: '125_vision', label: '125 Vision' },
  { value: '125_dental', label: '125 Dental' },
  { value: '401k', label: '401(k)' },
  { value: '403b', label: '403(b)' },
  { value: '457', label: '457' },
  { value: 'roth_401k', label: 'Roth 401(k)' },
  { value: 'roth_403b', label: 'Roth 403(b)' },
  { value: 'roth_457', label: 'Roth 457' },
  { value: 'fsa_medical', label: 'FSA Medical' },
  { value: 'fsa_dependent_care', label: 'FSA Dependent Care' },
  { value: 'hsa', label: 'HSA' },
  { value: 'simple_ira', label: 'SIMPLE IRA' },
  { value: '125_accident', label: '125 Accident' },
  { value: '125_cancer', label: '125 Cancer' },
  { value: '125_critical_illness', label: '125 Critical illness' },
  { value: '125_hospital', label: '125 Hospital' },
  { value: '125_life', label: '125 Life' },
  { value: '125_disability', label: '125 Disability' },
  { value: '125_medical_other', label: '125 Medical Other' },
];

export const HSA_LIMIT_OPTIONS = [
  {
    value: 'single',
    label: toI18n('team.employee_profile.tax_deductions.hsa.single'),
  },
  {
    value: 'family',
    label: toI18n('team.employee_profile.tax_deductions.hsa.family'),
  },
];

export const BENEFIT_TYPE_MAP = {
  '125_medical': {
    name: '125 Medical',
    description: 'team.employee_profile.tax_deductions.description.125_medical',
  },
  '125_vision': {
    name: '125 Vision',
    description: 'team.employee_profile.tax_deductions.description.125_vision',
  },
  '125_dental': {
    name: '125 Dental',
    description: 'team.employee_profile.tax_deductions.description.125_dental',
  },
  '401k': {
    name: '401(k)',
    description: 'team.employee_profile.tax_deductions.description.401k',
  },
  '403b': {
    name: '403(b)',
    description: 'team.employee_profile.tax_deductions.description.403b',
  },
  457: {
    name: '457',
    description: 'team.employee_profile.tax_deductions.description.457',
  },
  roth_401k: {
    name: 'Roth 401(k)',
    description: 'team.employee_profile.tax_deductions.description.roth_401k',
  },
  roth_403b: {
    name: 'Roth 403(b)',
    description: 'team.employee_profile.tax_deductions.description.roth_403b',
  },
  roth_457: {
    name: 'Roth 457',
    description: 'team.employee_profile.tax_deductions.description.roth_457',
  },
  fsa_medical: {
    name: 'FSA Medical',
    description: 'team.employee_profile.tax_deductions.description.fsa_medical',
  },
  fsa_dependent_care: {
    name: 'FSA Dependent Care',
    description:
      'team.employee_profile.tax_deductions.description.fsa_dependent_care',
  },
  hsa: {
    name: 'HSA',
    description: 'team.employee_profile.tax_deductions.description.hsa',
  },
  simple_ira: {
    name: 'SIMPLE IRA',
    description: 'team.employee_profile.tax_deductions.description.simple_ira',
  },
  '125_accident': {
    name: '125_accident',
    description:
      'team.employee_profile.tax_deductions.description.125_accident',
  },
  '125_cancer': {
    name: '125_cancer',
    description: 'team.employee_profile.tax_deductions.description.125_cancer',
  },
  '125_critical_illness': {
    name: '125_critical_illness',
    description:
      'team.employee_profile.tax_deductions.description.125_critical_illness',
  },
  '125_hospital': {
    name: '125_hospital',
    description:
      'team.employee_profile.tax_deductions.description.125_hospital',
  },
  '125_life': {
    name: '125_life',
    description: 'team.employee_profile.tax_deductions.description.125_life',
  },
  '125_disability': {
    name: '125_disability',
    description:
      'team.employee_profile.tax_deductions.description.125_disability',
  },
  '125_medical_other': {
    name: '125_medical_other',
    description:
      'team.employee_profile.tax_deductions.description.125_medical_other',
  },
};

export const INITIAL_VALUES = {
  company_contribution: '0',
  employee_contribution: '0',
  company_contribution_type: 'amount',
  employee_contribution_type: 'amount',
  hsa_contribution_limit: 'single',
};
