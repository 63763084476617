export const PAYMENT_METHODS = {
  direct_deposit: 'direct_deposit',
  manual: 'manual',
  skipped: 'skipped',
};
export const INPUT_HOURS_TYPES = { manual: 'manual', import: 'import' };

export const PAYMENT_TYPES = {
  employee: 'items',
  contractor: 'contractor_payments',
};
