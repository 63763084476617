import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgInstantPay = ({
  alt = 'Instant Pay Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M3 12 4.336 1.312A1.5 1.5 0 0 1 5.826 0h7.904a1.268 1.268 0 0 1 1.19 1.716L12.75 7.5h6.53a1.721 1.721 0 0 1 1.42 2.69l-9.01 13.173a1.473 1.473 0 0 1-1.213.642h-.136c-.736 0-1.336-.6-1.336-1.336 0-.108.014-.216.042-.324L11.25 13.5H4.5c-.83 0-1.5-.67-1.5-1.5Z"
    />
  </svg>
);
export default SvgInstantPay;
