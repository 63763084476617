import React from 'react';
import Box from 'fe-components/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import PropTypes from 'prop-types';

import { I18N_ONBOARDING_PATH } from 'features/team/components/AddEmployeeForm/constants';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('ContactInfoRequired');

export const ContactInfoRequired = ({ contactInfoRequired }) => {
  const iconName = contactInfoRequired ? 'Critical' : 'Info';
  const color = contactInfoRequired ? 'error300' : 'info300';
  return (
    <Box className={cx()} vcenter row gap={8}>
      <Icon size="xsmall" iconName={iconName} color={color} />
      <Text
        color={color}
        variant="captionBold"
        i18n={`${I18N_ONBOARDING_PATH}.requirement`}
      />
    </Box>
  );
};

ContactInfoRequired.propTypes = {
  contactInfoRequired: PropTypes.bool,
};
export default ContactInfoRequired;
