import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

export default class BottomSheetDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.rootElement = document.getElementById('react-bottom-modal-root');
    this.element = document.createElement('div');
  }

  componentDidMount() {
    this.rootElement.appendChild(this.element);
  }

  componentWillUnmount() {
    this.rootElement.removeChild(this.element);
  }

  render() {
    return createPortal(this.props.children, this.element);
  }
}
