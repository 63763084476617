import { isEmpty, isNil } from 'lodash';

import { usePayrollEligibility } from 'features/employeeProfile/hooks/usePayrollEligibility';

const isRequired = (fieldName, requiredAttributes) =>
  !isNil(
    requiredAttributes.find(
      requiredField => requiredField.split('.')[1] === fieldName
    )
  );

export const usePayrollRequiredAttribute = ({
  user,
  targetObject,
  attribute,
}) => {
  const payrollEligibility = usePayrollEligibility(user);

  const requiredFields = user.get('required_fields')?.toJS();
  if (!requiredFields) {
    return;
  }

  const attributeIsRequired = isRequired(attribute, requiredFields);

  let value = targetObject.get(attribute);

  // Lodash isEmpty always returns true when the value is a number
  if (typeof value === 'number') value = String(value);

  const attributeEmpty = isEmpty(value);

  return payrollEligibility && attributeEmpty && attributeIsRequired;
};
