import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import withCurrentRoute from 'components/hocs/withCurrentRoute';

import cxHelpers from 'util/className';

import CloseSideNavButton from '../items/CloseSideNavButton';
import HubDashboardManagerNavItem from '../items/HubDashboardManagerNavItem';
import HubScheduleNavItem from '../items/HubScheduleNavItem';
import HubTeamNavItem from '../items/HubTeamNavItem';
import HubTimeClockNavItem from '../items/HubTimeClockNavItem';
import SignOutNavItem from '../items/SignOutNavItem';

@withCurrentRoute
@cxHelpers('HubNav')
export default class HubNav extends PureComponent {
  static propTypes = {
    mobileView: PropTypes.bool,
  };

  renderNav() {
    return (
      <Box className={this.cx()} mls row spacebetween grow>
        <Box row>
          <HubTimeClockNavItem />
          <HubScheduleNavItem />
          <HubTeamNavItem />
        </Box>

        <Box row>
          <HubDashboardManagerNavItem />
          <SignOutNavItem />
        </Box>
      </Box>
    );
  }

  renderMobileNav() {
    return (
      <Box className={this.cx()} column>
        <Box ptxs prxs hright>
          <CloseSideNavButton />
        </Box>
        <HubTimeClockNavItem />
        <HubScheduleNavItem />
        <HubTeamNavItem />
        <HubDashboardManagerNavItem />
        <SignOutNavItem />
      </Box>
    );
  }

  render() {
    return this.props.mobileView ? this.renderMobileNav() : this.renderNav();
  }
}
