import colors from 'fe-design-base/styles/colors';
import { paletteCustom } from 'fe-design-base/styles/palette';

import {
  CLASS_PROP_SET,
  COLOR_PROP_MAPPING,
  STYLE_PROP_MAPPING,
} from './constants';
import { FlexProps, StyleProp } from './interface';

export const extractProps = (props: FlexProps): Record<string, FlexProps> =>
  Object.keys(props).reduce(
    (memo, value) => {
      const classProp = CLASS_PROP_SET.has(value);
      const colorProp = COLOR_PROP_MAPPING[value];
      const styleProp = STYLE_PROP_MAPPING[value];

      if (classProp) memo.classes[value] = props[value] as boolean;

      if (colorProp) {
        memo.style[colorProp] =
          colors[props[value] as string] ||
          paletteCustom[props[value] as string];
      }

      if (styleProp) {
        if (styleProp instanceof Function) {
          styleProp(
            memo.style as Record<string, StyleProp>,
            props[value] as StyleProp,
            props as FlexProps
          );
        } else {
          memo.style[styleProp] = props[value];
        }
      }

      /** Other props e.g. onClick, onTouchStart */
      if (!classProp && !styleProp && !colorProp)
        memo[value] = props[value] as unknown as FlexProps;

      return memo;
    },
    {
      classes: {},
      style: {},
    } as Record<string, FlexProps>
  );

export const getProps = (
  props: FlexProps,
  cx: (mods: Record<string, unknown>) => string
) => {
  const { style, classes, ...others } = extractProps(props);

  return {
    className: cx(classes),
    style,
    ...others,
  };
};
