import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

export const getMobileSubNavOpen = state =>
  state.getIn(['nav', 'mobileSubNavOpen']);
export const getMobileNavOpen = state => state.getIn(['nav', 'mobileNavOpen']);
export const getItems = state => state.getIn(['nav', 'items']);
export const getHiringMenuItems = state =>
  state.getIn(['nav', 'items', 'hiring', 'menuItems']);
export const buildItemDataSelector = key => state =>
  state.getIn(['nav', 'items', key]);
export const getUserData = buildItemDataSelector('avatarMenu');
export const getCurrentSubNavItems = createSelector(
  (_state, { currentRoute }) => currentRoute,
  getItems,
  (currentRoute, items) => {
    let currentSubNavItems;

    items.some(item =>
      item.get('menuItems', List()).some(menuItem => {
        const menuUrl = menuItem.get('url', '');
        const menuItemFilter = new RegExp(`^${menuUrl}\\b`);

        const urlIncludes = menuItem.get('url_includes', List());
        const urlIsEmptyAndCurrentRouteIncludesUrl =
          urlIncludes.isEmpty() && menuItemFilter.test(currentRoute);
        const urlIncludesIsEmptyAndCurrentRouteIncludesUrl =
          !urlIncludes.isEmpty() &&
          urlIncludes.some(url => currentRoute.includes(url));
        if (
          urlIsEmptyAndCurrentRouteIncludesUrl ||
          urlIncludesIsEmptyAndCurrentRouteIncludesUrl
        ) {
          currentSubNavItems = item.get('menuItems');
        }

        return currentSubNavItems;
      })
    );

    return currentSubNavItems || List();
  }
);

export const getActiveSubNavItem = createSelector(
  (_state, { currentRoute }) => currentRoute,
  getCurrentSubNavItems,
  (currentRoute, items) =>
    items.reverse().find(item => {
      const urlExcludes = item.get('url_excludes', List());
      if (
        !urlExcludes.isEmpty() &&
        urlExcludes.some(url => currentRoute.includes(url))
      ) {
        return false;
      }

      const urlIncludes = item.get('url_includes', List());
      if (urlIncludes.isEmpty()) {
        return currentRoute.includes(item.get('url', ''));
      }
      return urlIncludes.some(url => currentRoute.includes(url));
    }, Map())
);

export const getHiringManageApplicantsDefaultUrl = createSelector(
  getHiringMenuItems,
  items => {
    if (!items) {
      return '';
    }
    const menuItem = items.find(item => {
      const url = item.get('url') || '';
      return (
        url.startsWith('/hiring/job_requests') ||
        url.startsWith('/hiring/locations')
      );
    });
    return menuItem.get('url');
  }
);

export const getLeftNavCollapsed = state =>
  state.getIn(['nav', 'leftNavCollapsed']);

export const getIsLaunchWeekAwardMinimized = state =>
  state.getIn(['nav', 'isLaunchWeekAwardMinimized']);

export const getEmployeeBreakWaiverTooltipOpen = state =>
  state.getIn(['nav', 'employeeBreakWaiverTooltipOpen']);
