import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

import GoToManagePlanPageButton from 'features/managePlan/GoToManagePlanPageButton';

import Box from 'components/Box';

export const UpgradeToAccessLocations = () => (
  <MenuItem key="upgradeButton">
    <Box>
      <Box pvxs vcenter>
        <GoToManagePlanPageButton
          theme="primary-blue"
          i18n="nav_links.upgrade_locked"
        />
      </Box>
    </Box>
  </MenuItem>
);

export default UpgradeToAccessLocations;
