import { fromJS } from 'immutable';

import { actionTypes as addTeamActionTypes } from 'actions/addTeam';
import { actionTypes as ftuActionTypes } from 'actions/ftu';
import { actionTypes as sessionActionTypes } from 'actions/session';

const INITIAL_STATE = fromJS({
  ftuChecklist: {},
  showPayAnywhereTimesheetsModal: false,
  showPayAnywhereTeamModal: false,
  isShowingScheduleTour: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case sessionActionTypes.UPDATE_SESSION: {
      return state.merge(action.payload.ftu);
    }

    case ftuActionTypes.TOGGLE_SHOW_PAY_ANYWHERE_TIMESHEETS_MODAL: {
      const { showPayAnywhereTimesheetsModal } = action.payload;
      return state.merge({ showPayAnywhereTimesheetsModal });
    }

    case ftuActionTypes.TOGGLE_SHOW_QUICKBOOKS_OAUTH2_AUTH_MODAL: {
      const { showQuickbooksAuthModal } = action.payload;
      return state.merge({ showQuickbooksAuthModal });
    }

    case ftuActionTypes.TOGGLE_SHOW_PAY_ANYWHERE_TEAM_MODAL: {
      const { showPayAnywhereTeamModal } = action.payload;
      return state.merge({ showPayAnywhereTeamModal });
    }

    case ftuActionTypes.TOGGLE_IS_SHOWING_SCHEDULE_TOUR: {
      const { isShowingScheduleTour } = action.payload;
      return state.merge({ isShowingScheduleTour });
    }

    case ftuActionTypes.SKIP_FTU_CHECKLIST_STEP:
      return state.setIn(['checklist', 'steps', action.payload.stepKey], true);

    case ftuActionTypes.CLICKED_FTU_CHECKLIST_STEP:
      return state.setIn(['checklist', 'steps', action.payload.stepKey], true);

    case ftuActionTypes.ADD_INITIAL_EMPLOYEES_SUCCESS:
      return state.setIn(['checklist', 'steps', 'add_employees'], true);

    case addTeamActionTypes.CREATE_EMPLOYEES_SUCCESS:
      return state.setIn(['checklist', 'steps', 'add_employees'], true);

    case ftuActionTypes.ON_FTU_QUESTIONS_ANSWERED:
      return state.set('showChecklistSimpleNav', false);

    default:
      return state;
  }
};
