import * as routes from 'api';

import * as navSelectors from 'selectors/nav';

import { createAsyncPutAction } from 'util/redux';

export const actionTypes = {
  TOGGLE_MOBILE_SUB_NAV: 'NAV/TOGGLE_MOBILE_SUB_NAV',
  TOGGLE_MOBILE_NAV: 'NAV/TOGGLE_MOBILE_NAV',
  UPDATE_CURRENT_USER_REQUEST: 'NAV/UPDATE_CURRENT_USER_REQUEST',
  UPDATE_CURRENT_USER_SUCCESS: 'NAV/UPDATE_CURRENT_USER_SUCCESS',
  UPDATE_CURRENT_USER_FAILURE: 'NAV/UPDATE_CURRENT_USER_FAILURE',
  TOGGLE_LEFT_NAV: 'SESSION/TOGGLE_LEFT_NAV',
  MINIMIZE_LAUNCH_WEEK_AWARD: 'SESSION/MINIMIZE_LAUNCH_WEEK_AWARD',
  UPDATE_NAV_LOCATIONS: 'NAV/UPDATE_NAV_LOCATIONS',
  TOGGLE_EMPLOYEE_BREAK_WAIVER_TOOLTIP:
    'NAV/TOGGLE_EMPLOYEE_BREAK_WAIVER_TOOLTIP',
};

export const toggleEmployeeBreakWaiverTooltip = open => (dispatch, getState) =>
  dispatch({
    type: actionTypes.TOGGLE_EMPLOYEE_BREAK_WAIVER_TOOLTIP,
    payload: {
      open:
        open == null
          ? !navSelectors.getEmployeeBreakWaiverTooltipOpen(getState())
          : open,
    },
  });

export const toggleMobileSubNav = open => (dispatch, getState) =>
  dispatch({
    type: actionTypes.TOGGLE_MOBILE_SUB_NAV,
    payload: {
      open: open == null ? !navSelectors.getMobileSubNavOpen(getState()) : open,
    },
  });

export const toggleMobileNav = open => (dispatch, getState) =>
  dispatch({
    type: actionTypes.TOGGLE_MOBILE_NAV,
    payload: {
      open: open == null ? !navSelectors.getMobileNavOpen(getState()) : open,
    },
  });

export const updateCurrentUser = params =>
  createAsyncPutAction(
    routes.userUpdateRoute(),
    [
      { type: actionTypes.UPDATE_CURRENT_USER_REQUEST, meta: params },
      { type: actionTypes.UPDATE_CURRENT_USER_SUCCESS, meta: params },
      { type: actionTypes.UPDATE_CURRENT_USER_FAILURE, meta: params },
    ],
    { body: { user: params } }
  );

export const toggleLeftNav = collapsed => {
  window.sessionStorage.setItem('LEFT_NAV_COLLAPSED', collapsed);
  setTimeout(() => window.dispatchEvent(new Event('resize')), 600);

  return {
    type: actionTypes.TOGGLE_LEFT_NAV,
    payload: { collapsed },
  };
};

export const minimizeLaunchWeekAward = () => ({
  type: actionTypes.MINIMIZE_LAUNCH_WEEK_AWARD,
});

export const addNewLocations = addedLocations => ({
  type: actionTypes.UPDATE_NAV_LOCATIONS,
  payload: addedLocations,
});
