import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

export const fetchPayrollHistory = createAsyncThunk(
  'payroll/fetchPayrollHistory',
  ({ startDate, endDate }) =>
    fetchJSON(
      `/payroll/history.json?start_date=${startDate}&end_date=${
        endDate || startDate
      }`
    )
);

const payrollHistorySlice = createSlice({
  name: 'payrollHistory',
  initialState: {
    isFetching: false,
    errors: [],
    payrollRuns: [],
  },
  extraReducers: {
    [fetchPayrollHistory.pending]: state => {
      state.isFetching = true;
    },
    [fetchPayrollHistory.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.payrollRuns = action.payload;
    },
    [fetchPayrollHistory.rejected]: state => {
      state.isFetching = false;
    },
  },
});

export const payrollHistoryReducer = payrollHistorySlice.reducer;
export const payrollHistoryActions = payrollHistorySlice.actions;
