import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSearch = ({
  alt = 'Search Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M19.5 9.75c0 2.152-.698 4.14-1.875 5.752l5.934 5.939a1.502 1.502 0 0 1-2.123 2.123l-5.934-5.939A9.695 9.695 0 0 1 9.75 19.5 9.749 9.749 0 0 1 0 9.75 9.749 9.749 0 0 1 9.75 0a9.749 9.749 0 0 1 9.75 9.75ZM9.75 16.5a6.752 6.752 0 0 0 6.75-6.75A6.752 6.752 0 0 0 9.75 3 6.752 6.752 0 0 0 3 9.75a6.752 6.752 0 0 0 6.75 6.75Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSearch;
