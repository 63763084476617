import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import * as sessionSelectors from 'selectors/session';

import { showUpgradeModal } from 'util/upgrades';

export default WrappedComponent =>
  (
    @connect((state, props) => ({
      featureMinTiers: sessionSelectors.getFeatureMinTiers(state, props),
      reportMinTiers: sessionSelectors.getReportMinTiers(state, props),
    }))
    class ComponentWithUpgradeModal extends Component {
      static propTypes = {
        featureMinTiers: PropTypes.instanceOf(Map).isRequired,
        reportMinTiers: PropTypes.instanceOf(Map).isRequired,
        feature: PropTypes.string.isRequired,
        report: PropTypes.string,
        paywallVersion: PropTypes.string,
        locationProperty: PropTypes.string,
        serverKey: PropTypes.string,
      };

      minTier = () => {
        if (this.props.report) {
          return this.props.reportMinTiers.get(this.props.report);
        }

        return this.props.featureMinTiers.get(this.props.feature);
      };

      handleShowUpgradeModal = (target, { focusedFeature }) =>
        showUpgradeModal(
          this.minTier(),
          this.props.paywallVersion || this.props.feature,
          this.props.locationProperty,
          this.props.serverKey,
          target,
          undefined, // stripeEnrolled
          { focusedFeature }
        );

      render() {
        const props = {
          onShowUpgradeModal: this.handleShowUpgradeModal,
          ...omit(this.props, ['featureMinTiers', 'reportMinTiers']),
        };

        // Passes feature, onShowUpgradeModal, and any pass-through props
        return <WrappedComponent {...props} />;
      }
    }
  );
