import React from 'react';
import Icon from 'react-icon-base';

const LinkBlue = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g>
      <circle cx="24" cy="24" r="24" fill="#E9F8FC" />
      <path
        fill="#70CAD9"
        d="M26.258 30.934l-3.96 3.96a6.496 6.496 0 0 1-9.192 0 6.496 6.496 0 0 1 0-9.192l3.96-3.96c.057-.058.116-.115.176-.17a.6.6 0 0 1 1.01.414c.01.24.03.48.06.72a.603.603 0 0 1-.169.504l-3.765 3.764a4.698 4.698 0 0 0 0 6.647 4.698 4.698 0 0 0 6.647 0l3.96-3.96.018-.018a4.706 4.706 0 0 0-.018-6.629 4.669 4.669 0 0 0-1.38-.955.603.603 0 0 1-.356-.584c.015-.259.062-.515.14-.763a.595.595 0 0 1 .797-.364 6.46 6.46 0 0 1 2.072 1.394 6.508 6.508 0 0 1 0 9.192zm-4.515-4.677a6.461 6.461 0 0 0 2.072 1.393c.319.134.69-.034.795-.364.08-.247.127-.504.142-.763a.603.603 0 0 0-.357-.583 4.706 4.706 0 0 1-1.398-7.584l.018-.019 3.96-3.96a4.698 4.698 0 0 1 6.647 0 4.698 4.698 0 0 1 0 6.647c-.008.008-2.94 2.941-3.765 3.764a.603.603 0 0 0-.17.505c.031.239.052.479.061.72a.6.6 0 0 0 1.01.414c.06-.056.12-.113.177-.17l3.96-3.96a6.496 6.496 0 0 0 0-9.192 6.496 6.496 0 0 0-9.192 0l-3.96 3.96a6.508 6.508 0 0 0 0 9.192z"
      />
    </g>
  </Icon>
);

export default LinkBlue;
