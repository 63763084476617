import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { faBars, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from 'fe-design-base/assets/Logo';
import { dimensions } from 'theme/constants';

import * as navActions from 'actions/nav';

import * as sessionSelectors from 'selectors/session';

import { FULL_SCREEN_PATHS } from 'features/payroll/constants';

import Box from 'components/Box';
import withCurrentRoute from 'components/hocs/withCurrentRoute';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';

import GlobalComponents from '../GlobalComponents';
import ActiveTrialNavItem from '../items/ActiveTrialNavItem';
import AllInOneNavItem from '../items/AllInOneNavItem';
import MessengerNavItem from '../items/MessengerNavItem';
import NotificationsNavItem from '../items/NotificationsNavItem';
const { cx } = cxHelpers('NavView');

const MobileHeaderNav = ({
  navType,
  isGodModing,
  currentRoute,
  collapsedState,
  setCollapsedState,
  aioTrialSelfEnrollmentEnabled,
  aioRetrialState,
}) => {
  const toggleLeftNav = useCallback(() => {
    setCollapsedState(!collapsedState);
  }, [collapsedState, setCollapsedState]);
  if (navType === 'hidden') {
    return <GlobalComponents />;
  }

  // For when they are running payroll
  if (FULL_SCREEN_PATHS.some(path => currentRoute.includes(path))) {
    return null;
  }

  const inAioRetrial = aioRetrialState === 'in_retrial';

  return (
    <Box>
      <Box
        className={cx({}, 'js-nav')}
        minh={dimensions.navHeight}
        z={11}
        pl={24}
        vcenter
        bg={isGodModing ? 'black' : 'purpleXdark'}
        row
        spacebetween
      >
        <Box vcenter mrxs>
          <a href="/">
            <Logo height={16} color="mono0" />
          </a>
        </Box>

        {collapsedState ? (
          <Box row vcenter hright mrxs grow basis={0}>
            {aioTrialSelfEnrollmentEnabled && inAioRetrial && (
              <Box ph={8}>
                <AllInOneNavItem />
              </Box>
            )}

            <Box ph={8}>
              <ActiveTrialNavItem fullText />
            </Box>
            <Box ph={8}>
              <MessengerNavItem newHeader />
            </Box>
            <Box ph={8}>
              <NotificationsNavItem newHeader />
            </Box>
            <Box ph={8}>
              <Text fs24 tag="a">
                <FontAwesomeIcon
                  onClick={toggleLeftNav}
                  icon={faBars}
                  color="white"
                />
              </Text>
            </Box>
          </Box>
        ) : (
          <Box row vcenter hright mrxs grow basis={0}>
            <Box ph={8}>
              <Text fs24 tag="a">
                <FontAwesomeIcon
                  onClick={toggleLeftNav}
                  icon={faTimesCircle}
                  color="white"
                />
              </Text>
            </Box>
          </Box>
        )}
      </Box>

      <GlobalComponents />
    </Box>
  );
};

export default connect(
  (state, props) => ({
    navType: sessionSelectors.getNavType(state, props),
    isGodModing: sessionSelectors.getIsGodModing(state, props),
    currentUser: sessionSelectors.getCurrentUser(state, props),
    collapsedState: state.getIn(['nav', 'leftNavCollapsed']),
    aioTrialSelfEnrollmentEnabled:
      sessionSelectors.getShowAioTrialSelfEnrollment(state, props),
    aioRetrialState: sessionSelectors.getCurrentLocationAioRetrialState(state),
  }),
  {
    setCollapsedState: navActions.toggleLeftNav,
  }
)(withCurrentRoute(MobileHeaderNav));
