export const getSpacingProps = props => {
  const {
    man,
    maxxxxl,
    maxxxl,
    maxxl,
    maxl,
    mal,
    mam,
    mas,
    maxs,
    maxxs,
    maxxxs,
    matiny,
    maa,
    ma0,
    ma1,
    ma2,
    ma4,
    ma8,
    ma12,
    ma16,
    ma20,
    ma24,
    ma32,
    ma36,
    ma48,
    ma64,
    mtn,
    mtxxxxl,
    mtxxxl,
    mtxxl,
    mtxl,
    mtl,
    mtm,
    mts,
    mtxs,
    mtxxs,
    mtxxxs,
    mttiny,
    mta,
    mt0,
    mt1,
    mt2,
    mt4,
    mt8,
    mt12,
    mt16,
    mt20,
    mt24,
    mt32,
    mt36,
    mt48,
    mt64,
    mrn,
    mrxxxxl,
    mrxxxl,
    mrxxl,
    mrxl,
    mrl,
    mrm,
    mrs,
    mrxs,
    mrxxs,
    mrxxxs,
    mrtiny,
    mra,
    mr0,
    mr1,
    mr2,
    mr4,
    mr8,
    mr12,
    mr16,
    mr20,
    mr24,
    mr32,
    mr36,
    mr48,
    mr64,
    mbn,
    mbxxxxl,
    mbxxxl,
    mbxxl,
    mbxl,
    mbl,
    mbm,
    mbs,
    mbxs,
    mbxxs,
    mbxxxs,
    mbtiny,
    mba,
    mb0,
    mb1,
    mb2,
    mb4,
    mb8,
    mb12,
    mb16,
    mb20,
    mb24,
    mb32,
    mb36,
    mb48,
    mb64,
    mvn,
    mvxxxxl,
    mvxxxl,
    mvxxl,
    mvxl,
    mvl,
    mvm,
    mvs,
    mvxs,
    mvxxs,
    mvxxxs,
    mvtiny,
    mva,
    mv0,
    mv1,
    mv2,
    mv4,
    mv8,
    mv12,
    mv16,
    mv20,
    mv24,
    mv32,
    mv36,
    mv48,
    mv64,
    mhn,
    mhxxxxl,
    mhxxxl,
    mhxxl,
    mhxl,
    mhl,
    mhm,
    mhs,
    mhxs,
    mhxxs,
    mhxxxs,
    mhtiny,
    mha,
    mh0,
    mh1,
    mh2,
    mh4,
    mh8,
    mh12,
    mh16,
    mh20,
    mh24,
    mh32,
    mh36,
    mh48,
    mh64,
    mln,
    mlxxxxl,
    mlxxxl,
    mlxxl,
    mlxl,
    mll,
    mlm,
    mls,
    mlxs,
    mlxxs,
    mlxxxs,
    mltiny,
    mla,
    ml0,
    ml1,
    ml2,
    ml4,
    ml8,
    ml12,
    ml16,
    ml20,
    ml24,
    ml32,
    ml36,
    ml48,
    ml64,
    pan,
    paxxxxl,
    paxxxl,
    paxxl,
    paxl,
    pal,
    pam,
    pas,
    paxs,
    paxxs,
    paxxxs,
    patiny,
    paa,
    pa0,
    pa1,
    pa2,
    pa4,
    pa8,
    pa12,
    pa16,
    pa20,
    pa24,
    pa32,
    pa36,
    pa48,
    pa64,
    pvn,
    pvxxxxl,
    pvxxxl,
    pvxxl,
    pvxl,
    pvl,
    pvm,
    pvs,
    pvxs,
    pvxxs,
    pvxxxs,
    pvtiny,
    pva,
    pv0,
    pv1,
    pv2,
    pv4,
    pv8,
    pv12,
    pv16,
    pv20,
    pv24,
    pv32,
    pv36,
    pv48,
    pv64,
    phn,
    phxxxxl,
    phxxxl,
    phxxl,
    phxl,
    phl,
    phm,
    phs,
    phxs,
    phxxs,
    phxxxs,
    phtiny,
    pha,
    ph0,
    ph1,
    ph2,
    ph4,
    ph8,
    ph12,
    ph16,
    ph20,
    ph24,
    ph32,
    ph36,
    ph48,
    ph64,
    ptn,
    ptxxxxl,
    ptxxxl,
    ptxxl,
    ptxl,
    ptl,
    ptm,
    pts,
    ptxs,
    ptxxs,
    ptxxxs,
    pttiny,
    pta,
    pt0,
    pt1,
    pt2,
    pt4,
    pt8,
    pt12,
    pt16,
    pt20,
    pt24,
    pt32,
    pt36,
    pt48,
    pt64,
    prn,
    prxxxxl,
    prxxxl,
    prxxl,
    prxl,
    prl,
    prm,
    prs,
    prxs,
    prxxs,
    prxxxs,
    prtiny,
    pra,
    pr0,
    pr1,
    pr2,
    pr4,
    pr8,
    pr12,
    pr16,
    pr20,
    pr24,
    pr32,
    pr36,
    pr48,
    pr64,
    pbn,
    pbxxxxl,
    pbxxxl,
    pbxxl,
    pbxl,
    pbl,
    pbm,
    pbs,
    pbxs,
    pbxxs,
    pbxxxs,
    pbtiny,
    pba,
    pb0,
    pb1,
    pb2,
    pb4,
    pb8,
    pb12,
    pb16,
    pb20,
    pb24,
    pb32,
    pb36,
    pb48,
    pb64,
    pln,
    plxxxxl,
    plxxxl,
    plxxl,
    plxl,
    pll,
    plm,
    pls,
    plxs,
    plxxs,
    plxxxs,
    pltiny,
    pla,
    pl0,
    pl1,
    pl2,
    pl4,
    pl8,
    pl12,
    pl16,
    pl20,
    pl24,
    pl32,
    pl36,
    pl48,
    pl64,
    ...newProps
  } = props;

  return {
    man,
    maxxxxl,
    maxxxl,
    maxxl,
    maxl,
    mal,
    mam,
    mas,
    maxs,
    maxxs,
    maxxxs,
    matiny,
    maa,
    ma0,
    ma1,
    ma2,
    ma4,
    ma8,
    ma12,
    ma16,
    ma20,
    ma24,
    ma32,
    ma36,
    ma48,
    ma64,
    mtn,
    mtxxxxl,
    mtxxxl,
    mtxxl,
    mtxl,
    mtl,
    mtm,
    mts,
    mtxs,
    mtxxs,
    mtxxxs,
    mttiny,
    mta,
    mt0,
    mt1,
    mt2,
    mt4,
    mt8,
    mt12,
    mt16,
    mt20,
    mt24,
    mt32,
    mt36,
    mt48,
    mt64,
    mrn,
    mrxxxxl,
    mrxxxl,
    mrxxl,
    mrxl,
    mrl,
    mrm,
    mrs,
    mrxs,
    mrxxs,
    mrxxxs,
    mrtiny,
    mra,
    mr0,
    mr1,
    mr2,
    mr4,
    mr8,
    mr12,
    mr16,
    mr20,
    mr24,
    mr32,
    mr36,
    mr48,
    mr64,
    mbn,
    mbxxxxl,
    mbxxxl,
    mbxxl,
    mbxl,
    mbl,
    mbm,
    mbs,
    mbxs,
    mbxxs,
    mbxxxs,
    mbtiny,
    mba,
    mb0,
    mb1,
    mb2,
    mb4,
    mb8,
    mb12,
    mb16,
    mb20,
    mb24,
    mb32,
    mb36,
    mb48,
    mb64,
    mvn,
    mvxxxxl,
    mvxxxl,
    mvxxl,
    mvxl,
    mvl,
    mvm,
    mvs,
    mvxs,
    mvxxs,
    mvxxxs,
    mvtiny,
    mva,
    mv0,
    mv1,
    mv2,
    mv4,
    mv8,
    mv12,
    mv16,
    mv20,
    mv24,
    mv32,
    mv36,
    mv48,
    mv64,
    mhn,
    mhxxxxl,
    mhxxxl,
    mhxxl,
    mhxl,
    mhl,
    mhm,
    mhs,
    mhxs,
    mhxxs,
    mhxxxs,
    mhtiny,
    mha,
    mh0,
    mh1,
    mh2,
    mh4,
    mh8,
    mh12,
    mh16,
    mh20,
    mh24,
    mh32,
    mh36,
    mh48,
    mh64,
    mln,
    mlxxxxl,
    mlxxxl,
    mlxxl,
    mlxl,
    mll,
    mlm,
    mls,
    mlxs,
    mlxxs,
    mlxxxs,
    mltiny,
    mla,
    ml0,
    ml1,
    ml2,
    ml4,
    ml8,
    ml12,
    ml16,
    ml20,
    ml24,
    ml32,
    ml36,
    ml48,
    ml64,
    pan,
    paxxxxl,
    paxxxl,
    paxxl,
    paxl,
    pal,
    pam,
    pas,
    paxs,
    paxxs,
    paxxxs,
    patiny,
    paa,
    pa0,
    pa1,
    pa2,
    pa4,
    pa8,
    pa12,
    pa16,
    pa20,
    pa24,
    pa32,
    pa36,
    pa48,
    pa64,
    pvn,
    pvxxxxl,
    pvxxxl,
    pvxxl,
    pvxl,
    pvl,
    pvm,
    pvs,
    pvxs,
    pvxxs,
    pvxxxs,
    pvtiny,
    pva,
    pv0,
    pv1,
    pv2,
    pv4,
    pv8,
    pv12,
    pv16,
    pv20,
    pv24,
    pv32,
    pv36,
    pv48,
    pv64,
    phn,
    phxxxxl,
    phxxxl,
    phxxl,
    phxl,
    phl,
    phm,
    phs,
    phxs,
    phxxs,
    phxxxs,
    phtiny,
    pha,
    ph0,
    ph1,
    ph2,
    ph4,
    ph8,
    ph12,
    ph16,
    ph20,
    ph24,
    ph32,
    ph36,
    ph48,
    ph64,
    ptn,
    ptxxxxl,
    ptxxxl,
    ptxxl,
    ptxl,
    ptl,
    ptm,
    pts,
    ptxs,
    ptxxs,
    ptxxxs,
    pttiny,
    pta,
    pt0,
    pt1,
    pt2,
    pt4,
    pt8,
    pt12,
    pt16,
    pt20,
    pt24,
    pt32,
    pt36,
    pt48,
    pt64,
    prn,
    prxxxxl,
    prxxxl,
    prxxl,
    prxl,
    prl,
    prm,
    prs,
    prxs,
    prxxs,
    prxxxs,
    prtiny,
    pra,
    pr0,
    pr1,
    pr2,
    pr4,
    pr8,
    pr12,
    pr16,
    pr20,
    pr24,
    pr32,
    pr36,
    pr48,
    pr64,
    pbn,
    pbxxxxl,
    pbxxxl,
    pbxxl,
    pbxl,
    pbl,
    pbm,
    pbs,
    pbxs,
    pbxxs,
    pbxxxs,
    pbtiny,
    pba,
    pb0,
    pb1,
    pb2,
    pb4,
    pb8,
    pb12,
    pb16,
    pb20,
    pb24,
    pb32,
    pb36,
    pb48,
    pb64,
    pln,
    plxxxxl,
    plxxxl,
    plxxl,
    plxl,
    pll,
    plm,
    pls,
    plxs,
    plxxs,
    plxxxs,
    pltiny,
    pla,
    pl0,
    pl1,
    pl2,
    pl4,
    pl8,
    pl12,
    pl16,
    pl20,
    pl24,
    pl32,
    pl36,
    pl48,
    pl64,
    props: newProps,
  };
};

export const getModifierProps = props => {
  const {
    tcenter,
    tleft,
    tright,
    scrollX,
    scrollY,
    row,
    rowreverse,
    column,
    columnreverse,
    wrap,
    wrapreverse,
    baseline,
    stretch,
    spacebetween,
    spacearound,
    spaceevenly,
    evenwidth,
    absolute,
    absoluteCover,
    relative,
    fixed,
    sticky,
    none,
    hide,
    inline,
    block,
    inlineBlock,
    borderbox,
    shadow,
    muiShadow,
    pointer,
    pointerEventsNone,
    ellipsis,
    disabled,
    transitionAll,
    noWrap,
    ...newProps
  } = props;

  return {
    tcenter,
    tleft,
    tright,
    scrollX,
    scrollY,
    row,
    rowreverse,
    column,
    columnreverse,
    wrap,
    wrapreverse,
    baseline,
    stretch,
    spacebetween,
    spacearound,
    spaceevenly,
    evenwidth,
    absolute,
    absoluteCover,
    relative,
    fixed,
    sticky,
    none,
    hide,
    inline,
    block,
    inlineBlock,
    borderbox,
    shadow,
    muiShadow,
    pointer,
    pointerEventsNone,
    ellipsis,
    disabled,
    transitionAll,
    noWrap,
    props: newProps,
  };
};

export const getStyleProps = props => {
  const {
    w,
    maxw,
    minw,
    h,
    maxh,
    minh,
    br,
    bl,
    bt,
    bb,
    bv,
    bh,
    b,
    bwidth,
    bstyle,
    bradius,
    bg,
    m,
    ml,
    mr,
    mt,
    mb,
    mv,
    mh,
    ma,
    p,
    pl,
    pr,
    pt,
    pb,
    pv,
    ph,
    top,
    right,
    bottom,
    left,
    z,
    zIndex,
    overflow,
    overflowY,
    overflowX,
    wordBreak,
    opacity,
    cursor,
    ...newProps
  } = props;

  return {
    w,
    maxw,
    minw,
    h,
    maxh,
    minh,
    br,
    bl,
    bt,
    bb,
    bv,
    bh,
    b,
    bwidth,
    bstyle,
    bradius,
    bg,
    m,
    ml,
    mr,
    mt,
    mb,
    mv,
    mh,
    ma,
    p,
    pl,
    pr,
    pt,
    pb,
    pv,
    ph,
    top,
    right,
    bottom,
    left,
    z,
    zIndex,
    overflow,
    overflowY,
    overflowX,
    wordBreak,
    opacity,
    cursor,
    props: newProps,
  };
};
