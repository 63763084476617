import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import PropTypes from 'prop-types';

import {
  EMPTY_ROSTER_OPTIONS,
  EMPTY_ROSTER_OPTIONS_PATH,
} from 'features/team/constants';

import { toI18n } from 'util/i18n';

import EmptyRosterOption from './EmptyRosterOption';

const EmptyRosterOptionGroup = ({
  handleClickPosImport,
  handleShowAddEmployee,
  handleShowBulkAddModal,
}) => {
  const emptyRosterOptions = [
    {
      key: EMPTY_ROSTER_OPTIONS.ADD_TEAM_MEMBER,
      onClick: handleShowAddEmployee,
    },
    {
      key: EMPTY_ROSTER_OPTIONS.BULK_ADD_MEMBERS,
      onClick: handleShowBulkAddModal,
    },
    {
      key: EMPTY_ROSTER_OPTIONS.POS_IMPORT_MEMBERS,
      onClick: handleClickPosImport,
    },
  ];

  return (
    <Box column gap={18}>
      {emptyRosterOptions.map(option => (
        <EmptyRosterOption
          className={option.key}
          key={option.key}
          title={toI18n(`${EMPTY_ROSTER_OPTIONS_PATH}.title.${option.key}`)}
          subtitle={toI18n(
            `${EMPTY_ROSTER_OPTIONS_PATH}.subtitle.${option.key}`
          )}
          onClick={option.onClick}
        />
      ))}
    </Box>
  );
};

EmptyRosterOptionGroup.propTypes = {
  handleClickPosImport: PropTypes.func,
  handleShowAddEmployee: PropTypes.func,
  handleShowBulkAddModal: PropTypes.func,
};

export default EmptyRosterOptionGroup;
