import React, { PureComponent } from 'react';

import Box from 'components/Box';

import cxHelpers from 'util/className';

import SignInItem from '../items/SignInItem';

@cxHelpers('PublicNav')
export default class PublicNav extends PureComponent {
  render() {
    return (
      <Box mls row hright grow>
        <SignInItem />
      </Box>
    );
  }
}
