import React, { Component } from 'react';

import { trackUxEvent } from 'util/tracking';

export default (productArea, eventCategory) => WrappedComponent => {
  class WithUxTracking extends Component {
    trackEvent = params => {
      trackUxEvent({
        productArea,
        eventCategory,
        ...params,
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} trackUxEvent={this.trackEvent} />
      );
    }
  }

  return WithUxTracking;
};
