import React from 'react';
import Box from 'fe-components/Box';
import Text from 'fe-design-base/atoms/Text/Text';
import SelectField from 'fe-design-base/molecules/SelectField';

import { I18N_JOB_DETAILS_PATH, WAGE_TYPE_OPTIONS } from '../../constants';

import DeleteRoleWage from './DeleteRoleWage';

const RateTypeSelector = ({
  isDefaultRole,
  idx,
  salaryEnabled,
  numAdditionalRoleWage,
  setFieldValue,
  onSetNumAdditionalRoleWage,
  values,
  cxEl,
  onUpdateSelectedRoles,
}) => {
  const salaryRolesNotEnabled = !salaryEnabled;
  const multipleRoleWages = numAdditionalRoleWage > 0;

  return (
    <Box row>
      {salaryRolesNotEnabled || multipleRoleWages ? (
        <Box w={68} className={cxEl('wage-type-hourly')} h={40} vcenter>
          <Text color="mono900" i18n={`${I18N_JOB_DETAILS_PATH}.hourly`} />
        </Box>
      ) : (
        <Box w={120} className={cxEl('select-rate')}>
          <SelectField
            name="wageType"
            multiple={false}
            options={WAGE_TYPE_OPTIONS}
            size="medium"
          />
        </Box>
      )}
      {!isDefaultRole && (
        <DeleteRoleWage
          idx={idx}
          numAdditionalRoleWage={numAdditionalRoleWage}
          setNumAdditionalRoleWage={onSetNumAdditionalRoleWage}
          setFieldValue={setFieldValue}
          updateSelectedRoles={onUpdateSelectedRoles}
          values={values}
        />
      )}
    </Box>
  );
};

RateTypeSelector.propTypes = {};
export default RateTypeSelector;
