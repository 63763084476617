import React, { useCallback } from 'react';
import ChipClosable from 'fe-design-base/molecules/ChipClosable';

export interface MultiValueTagProps {
  label: string;
  value: string | number;
  disabled?: boolean;
  onTagClick: (value: string | number) => void;
}

const MultiValueTag = ({
  label,
  value,
  disabled,
  onTagClick,
}: MultiValueTagProps) => {
  const handleButtonClick = useCallback(() => {
    onTagClick(value);
  }, [onTagClick, value]);
  return (
    <ChipClosable
      onButtonClick={handleButtonClick}
      disabled={disabled}
      key={label}
    >
      {label}
    </ChipClosable>
  );
};

export default MultiValueTag;
