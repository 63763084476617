import React from 'react';
import Icon from 'react-icon-base';

const Schedule = props => (
  <Icon viewBox="0 0 56 56" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.8453 49.525H36.2144L49.5257 37.7556V42.4289C49.5257 46.3422 46.5289 49.525 42.8453 49.525V49.525ZM47.4969 36.2475L34.1855 48.0169V39.5095C34.1855 37.7102 35.6285 36.2475 37.402 36.2475H47.4969ZM6.47412 42.4291V22.3087H49.5257V33.7733H37.402C34.2646 33.7733 31.7112 36.3465 31.7112 39.5095V49.5252H13.1545C9.47091 49.5252 6.47412 46.3423 6.47412 42.4291V42.4291ZM13.1545 12.412H13.8968V15.1337C13.8968 16.9072 15.3398 18.3502 17.1133 18.3502C18.8868 18.3502 20.3298 16.9072 20.3298 15.1337V12.412H35.67V15.1337C35.67 16.9072 37.113 18.3502 38.8865 18.3502C40.66 18.3502 42.103 16.9072 42.103 15.1337V12.412H42.8453C46.5289 12.412 49.5257 15.5949 49.5257 19.5081V19.8347H6.47412V19.5081C6.47412 15.5949 9.47091 12.412 13.1545 12.412V12.412ZM16.3712 7.2163C16.3712 6.81449 16.7117 6.47403 17.1135 6.47403C17.5153 6.47403 17.8558 6.81449 17.8558 7.2163V15.1338C17.8558 15.5356 17.5153 15.8761 17.1135 15.8761C16.7117 15.8761 16.3712 15.5356 16.3712 15.1338V7.2163ZM38.1443 7.2163C38.1443 6.81449 38.4847 6.47403 38.8866 6.47403C39.2884 6.47403 39.6288 6.81449 39.6288 7.2163V15.1338C39.6288 15.5356 39.2884 15.8761 38.8866 15.8761C38.4847 15.8761 38.1443 15.5356 38.1443 15.1338V7.2163ZM42.8454 9.93827H42.1031V7.21662C42.1031 5.44309 40.6601 4.00012 38.8866 4.00012C37.1131 4.00012 35.6701 5.44309 35.6701 7.21662V9.93827H20.3299V7.21662C20.3299 5.44309 18.8869 4.00012 17.1134 4.00012C15.3399 4.00012 13.8969 5.44309 13.8969 7.21662V9.93827H13.1546C8.10722 9.93827 4 14.2315 4 19.5086V42.4298C4 47.7068 8.10722 52.0001 13.1546 52.0001H42.8454C47.8928 52.0001 52 47.7068 52 42.4298V19.5086C52 14.2315 47.8928 9.93827 42.8454 9.93827V9.93827Z"
      fill="#04A2BD"
    />
  </Icon>
);

export default Schedule;
