import './BenefitsWidget.scss';

import React, { useCallback, useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import AddButton from 'components/clickables/AddButton';

import { cxHelpers } from 'util/className';

import BenefitForm from './BenefitForm';
import BenefitsList from './BenefitsList';

const { cx } = cxHelpers('BenefitsWidget');

const BenefitsWidget = ({ user, updateUserInfo, showFormWithSteps }) => {
  const [userObj, setUserObj] = useState(user);

  useEffect(() => {
    setUserObj(fromJS(user));
  }, [user]);

  const [isFormVisible, setIsFormVisible] = useState(false);
  const handleAddBenefit = useCallback(() => {
    setIsFormVisible(true);
  }, []);

  const handleOnCancel = useCallback(() => {
    setIsFormVisible(false);
  }, []);

  const handleOnSaveCompleted = useCallback(response => {
    setIsFormVisible(false);
    setUserObj(fromJS(response));
  }, []);

  if (!userObj.get('show_benefits_widget')) return null;

  return (
    <Box className={cx()} mb={32}>
      <Box mb={12}>
        <Text
          variant={showFormWithSteps ? 'heading4' : 'heading3'}
          i18n="team.employee_profile.tax_deductions.title"
          color={showFormWithSteps && 'mono900'}
        />
      </Box>
      <Box b={!showFormWithSteps} pb={8} row bradius>
        <Box grow={2}>
          <BenefitsList
            user={userObj}
            onSaveCompleted={handleOnSaveCompleted}
            updateUserInfo={updateUserInfo}
            showFormWithSteps={showFormWithSteps}
          />
          {isFormVisible ? (
            <BenefitForm
              onCancel={handleOnCancel}
              onSaveCompleted={handleOnSaveCompleted}
              user={userObj}
              updateUserInfo={updateUserInfo}
              showFormWithSteps={showFormWithSteps}
            />
          ) : (
            <Box mt={8}>
              {showFormWithSteps ? (
                <Button
                  startIcon="Add"
                  variant="tertiary"
                  onClick={handleAddBenefit}
                >
                  <Text
                    variant="bodyBold"
                    i18n="team.employee_profile.tax_deductions.add_benefit"
                    color="purple500"
                  />
                </Button>
              ) : (
                <AddButton
                  onClick={handleAddBenefit}
                  i18n="team.employee_profile.tax_deductions.add_benefit"
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

BenefitsWidget.propTypes = {
  user: ImmutablePropTypes.map.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
};

export default BenefitsWidget;
