import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { getSchema } from 'fe-core/Entities/schema';
import { get, isArray, setWith } from 'lodash';

import { PENDING_INDEX, PENDING_STACK, PENDING_STATE } from './constants';

let nextPendingId = 1;

export function newPendingId() {
  return (nextPendingId++).toString();
}

// get all pending entity relationships shallow merged
function getPendingEntityRelationships({ state, type, id }) {
  return state[PENDING_STATE][PENDING_STACK].map(
    pendingId =>
      state[PENDING_STATE][PENDING_INDEX][pendingId]?.[type]?.[id]
        ?.relationships
  ).reduce((mergedRelationships, pendingRelationships) => {
    if (!pendingRelationships) return mergedRelationships;

    return {
      ...mergedRelationships,
      ...pendingRelationships,
    };
  }, {});
}

function addPendingItem({ state, pendingState, type, id, data }) {
  // shallow merge relationships of saved, pending and new pending item
  const priorRelationships = {
    ...(get(state, [type, id, 'relationships']) || {}),
    ...getPendingEntityRelationships({ state, type, id }),
    ...(get(pendingState, [type, id, 'relationships']) || {}),
  };

  setWith(pendingState, [type, id], { type, id, ...data }, Object);
  if (data.relationships) {
    const schema = getSchema(type);
    if (schema) {
      Object.entries(data.relationships).forEach(
        ([name, { data: relationshipData }]) => {
          const inverseOf = schema.relationships[name].inverseOf;
          if (schema.relationships[name]?.relationshipType === HAS_MANY) {
            const priorRelationshipData = priorRelationships[name]?.data || [];
            priorRelationshipData.forEach(
              ({ type: relatedType, id: relatedId }) => {
                setWith(
                  pendingState,
                  [relatedType, relatedId, 'relationships', inverseOf, 'data'],
                  null,
                  Object
                );
              }
            );
            relationshipData.forEach((currentData = {}) => {
              const { type: relatedType, id: relatedId } = currentData;

              if (relatedId)
                setWith(
                  pendingState,
                  [relatedType, relatedId, 'relationships', inverseOf, 'data'],
                  { type, id },
                  Object
                );
            });
          } else if (
            schema.relationships[name]?.relationshipType === BELONGS_TO
          ) {
            const { type: relatedType, id: relatedId } = relationshipData || {};
            const priorRelationshipData = priorRelationships[name]?.data;

            if (priorRelationshipData) {
              const { type: priorType, id: priorId } = priorRelationshipData;
              const priorData =
                get(pendingState, [
                  priorType,
                  priorId,
                  'relationships',
                  inverseOf,
                  'data',
                ]) ||
                get(
                  getPendingEntityRelationships({
                    state,
                    type: priorType,
                    id: priorId,
                  }),
                  [inverseOf, 'data']
                ) ||
                get(state, [
                  priorType,
                  priorId,
                  'relationships',
                  inverseOf,
                  'data',
                ]) ||
                [];
              setWith(
                pendingState,
                [priorType, priorId, 'relationships', inverseOf, 'data'],
                priorData.filter(d => !(d.type === type && d.id === id)),
                Object
              );
            }

            const existingRelationship = (
              get(pendingState, [
                relatedType,
                relatedId,
                'relationships',
                inverseOf,
                'data',
              ]) ||
              get(
                getPendingEntityRelationships({
                  state,
                  type: relatedType,
                  id: relatedId,
                }),
                [inverseOf, 'data']
              ) ||
              get(state, [
                relatedType,
                relatedId,
                'relationships',
                inverseOf,
                'data',
              ]) ||
              []
            ).filter(r => !(r.type === type && r.id === id));
            if (relatedId)
              setWith(
                pendingState,
                [relatedType, relatedId, 'relationships', inverseOf, 'data'],
                [...existingRelationship, { type, id }],
                Object
              );
          }
        }
      );
    }
  }
  return pendingState;
}

export function addPending({ state, pendingId, entities }) {
  const pendingState = {};
  ((isArray(entities) && entities) || [entities]).forEach(
    ({ type, id, ...data }) =>
      addPendingItem({ state, pendingState, type, id, data })
  );
  state[PENDING_STATE][PENDING_INDEX][pendingId] = pendingState;
  state[PENDING_STATE][PENDING_STACK].push(pendingId);
}

export function clearPending({ state, pendingId }) {
  const pendingStack = state[PENDING_STATE][PENDING_STACK];
  delete state[PENDING_STATE][PENDING_INDEX][pendingId];
  state[PENDING_STATE][PENDING_STACK] = pendingStack.filter(
    id => id !== pendingId
  );
}
