import { date, ref, string } from 'yup';

import { toI18n } from 'util/i18n';

export const I18N_PATH = 'new_team_drawer.tax_entry_form.personal_info';
export const I18N_TAX_FORMS_PATH = 'new_team_drawer.tax_entry_form.tax_forms';

export const I18N_ERROR_PATH = `${I18N_PATH}.errors`;
export const I18N_LABEL_PATH = `${I18N_PATH}.labels`;

const SSN_REGEX = /^(000-00-0000)|(?!666|000)\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
const EIN_REGEX = /^\d{9}?$/;
const ZIP_REGEX = /^\d{5}?$/;

export const ROUTING_NUMBER_LENGTH = 9;

import {
  TAX_ENTRY_I18N_PATH,
  TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH,
  TAX_ENTRY_PAYMENT_METHOD_PATH,
} from 'features/team/constants';

export const TAX_FORM_STEP = {
  PERSONAL_INFORMATION: toI18n(`${TAX_ENTRY_I18N_PATH}.personal_information`),
  PAYMENT_METHOD: toI18n(`${TAX_ENTRY_I18N_PATH}.payment_method`),
  FEDERAL: 'Federal W-4',
};

export const INVALID_DATE = toI18n(`${I18N_ERROR_PATH}.date_of_birth_invalid`);
export const REQUIRED_FIELD_ERROR = toI18n(`${I18N_ERROR_PATH}.required`);
export const INVALID_SSN = toI18n(`${I18N_ERROR_PATH}.invalid_ssn`);
export const INVALID_EIN = toI18n(`${I18N_ERROR_PATH}.invalid_ein`);
export const INVALID_ZIP = toI18n(`${I18N_ERROR_PATH}.invalid_zip`);

export const personalInfoFormSchema = () => ({
  legal_first_name: string().required(REQUIRED_FIELD_ERROR),
  legal_last_name: string().required(REQUIRED_FIELD_ERROR),
  legal_middle_name: string().optional(),
  date_of_birth: date()
    .typeError(INVALID_DATE)
    .min(new Date('01/01/1900'), INVALID_DATE)
    .max(new Date('01/01/2100'), INVALID_DATE)
    .when('tax_classification', {
      is: 'w2',
      then: date().typeError(INVALID_DATE).required(REQUIRED_FIELD_ERROR),
    }),
  address_1: string().when(['tax_classification', 'contractor_type'], {
    is: (taxClassification, contractorType) =>
      taxClassification === 'w2' || contractorType === 'business',
    then: string().required(REQUIRED_FIELD_ERROR),
  }),
  city: string().when(['tax_classification', 'contractor_type'], {
    is: (taxClassification, contractorType) =>
      taxClassification === 'w2' || contractorType === 'business',
    then: string().required(REQUIRED_FIELD_ERROR),
  }),
  state: string().when(['tax_classification', 'contractor_type'], {
    is: (taxClassification, contractorType) =>
      taxClassification === 'w2' || contractorType === 'business',
    then: string().required(REQUIRED_FIELD_ERROR),
  }),
  zip_code: string()
    .when(['tax_classification', 'contractor_type'], {
      is: (taxClassification, contractorType) =>
        taxClassification === 'w2' || contractorType === 'business',
      then: string().required(REQUIRED_FIELD_ERROR),
    })
    .when('is_in_us', {
      is: true,
      then: string().matches(ZIP_REGEX, INVALID_ZIP),
    }),
  ssn: string()
    .when('tax_classification', {
      is: 'w2',
      then: string()
        .test(
          'ssn-starts-with-9',
          toI18n(`${I18N_ERROR_PATH}.invalid_ssn_beginning`),
          value => !value || !value.startsWith('9')
        )
        .matches(SSN_REGEX, INVALID_SSN)
        .required(REQUIRED_FIELD_ERROR),
    })
    .when(['tax_classification', 'contractor_type'], {
      is: (taxClassification, contractorType) =>
        taxClassification === '1099' && contractorType === 'individual',
      then: string()
        .matches(SSN_REGEX, INVALID_SSN)
        .required(REQUIRED_FIELD_ERROR),
    }),
  ein: string().when(['tax_classification', 'contractor_type'], {
    is: (taxClassification, contractorType) =>
      taxClassification !== 'w2' && contractorType === 'business',
    then: string()
      .matches(EIN_REGEX, INVALID_EIN)
      .required(REQUIRED_FIELD_ERROR),
  }),
  contractor_info_business_name: string().when(
    ['tax_classification', 'contractor_type'],
    {
      is: (taxClassification, contractorType) =>
        taxClassification !== 'w2' && contractorType === 'business',
      then: string().required(REQUIRED_FIELD_ERROR),
    }
  ),
});

export const paymentMethodFormSchema = () => {
  const schema = {
    payment_method: string().required(REQUIRED_FIELD_ERROR),
    bank_account_type: string().when('payment_method', {
      is: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.direct_deposit`),
      then: string().required(
        toI18n(`${TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH}.account_type_required`)
      ),
    }),
    decrypted_account_number: string().when('payment_method', {
      is: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.direct_deposit`),
      then: string().required(
        toI18n(`${TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH}.account_number_required`)
      ),
    }),
    decrypted_reentered_account_number: string().when('payment_method', {
      is: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.direct_deposit`),
      then: string()
        .oneOf(
          [ref('decrypted_account_number')],
          toI18n(
            'team.employee_profile.banking_information.invalid_bank_account_numbers'
          )
        )
        .required(
          toI18n(
            `${TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH}.reenter_account_number_required`
          )
        ),
    }),
    decrypted_routing_number: string().when('payment_method', {
      is: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.direct_deposit`),
      then: string()
        .length(
          9,
          toI18n(
            `${TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH}.invalid_routing_number`
          )
        )
        .required(
          toI18n(
            `${TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH}.routing_number_required`
          )
        ),
    }),
  };

  return schema;
};

export const PAYMENT_METHOD_OPTIONS = [
  {
    value: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.direct_deposit`),
    label: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.direct_deposit`),
  },
  {
    value: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.paper_check`),
    label: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.paper_check`),
  },
];

export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.checking`).toLowerCase(),
    label: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.checking`),
  },
  {
    value: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.savings`).toLowerCase(),
    label: toI18n(`${TAX_ENTRY_PAYMENT_METHOD_PATH}.savings`),
  },
];
