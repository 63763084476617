import { List, Map } from 'immutable';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import {
  getEmployees,
  getFilteredTableData,
  getRoles,
} from 'selectors/timesheets';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

const buildPageSelector = key => state =>
  state.getIn(['timesheets', 'payrollSummaries', key]);

export const getLoadingPeriods = buildPageSelector('loadingPeriods');
export const getLoadingTotals = buildPageSelector('loadingTotals');
export const getLoadingTotalsForPayrollPeriods = buildPageSelector(
  'loadingTotalsForPayrollPeriods'
);

export const getLoadingPayrollSummaries = createSelector(
  getLoadingTotals,
  getLoadingPeriods,
  getLoadingTotalsForPayrollPeriods,
  (loadingTotals, loadingPeriods, loadingTotalsForPayrollPeriods) =>
    loadingTotals || loadingPeriods || loadingTotalsForPayrollPeriods
);

export const getPageData = buildPageSelector('pageData');

export const getPayrollPeriodsMap = createSelector(
  buildPageSelector('payrollPeriods'),
  periods =>
    periods
      .map(period => {
        const startDate = moment(period.get('start_date'), 'MM/DD/YYYY');
        const endDate = moment(period.get('end_date'), 'MM/DD/YYYY');

        return period.merge({ startDate, endDate });
      })
      .sortBy(period => period.get('startDate').unix())
);

export const getCurrentPayrollPeriod = createSelector(
  getPayrollPeriodsMap,
  (_, props) => props.period,
  getRoles,
  getEmployees,
  (periods, periodKey, roles, employees) => {
    const period = periods.get(periodKey);

    if (!period || isEmpty(employees)) {
      return null;
    }

    const rows = (period.get('rows') || List()).map(row => {
      const employee = employees[row.get('job_id')];
      const role = roles[row.get('role_id')];
      const defaultRole = row.get('default_role');

      return row.merge({
        first_name: defaultRole ? employee.get('first_name') : '',
        last_name: defaultRole ? employee.get('last_name') : '',
        legal_first_name: defaultRole ? employee.get('legal_first_name') : '',
        legal_middle_name: defaultRole ? employee.get('middle_name') : '',
        legal_last_name: defaultRole ? employee.get('legal_last_name') : '',
        payroll_id: employee.get('payroll_id'),
        role: role ? role.get('name') : '',
      });
    });

    return period.set('rows', rows);
  }
);

export const getPayrollPeriods = createSelector(
  getPayrollPeriodsMap,
  (_, props) => props.month,
  (periods, month) => {
    if (!month || periods.isEmpty()) {
      return [];
    }

    return periods.filter(period => period.get('month') === month).toArray();
  }
);

const buildPageDataSelector = key => state =>
  state.getIn(['timesheets', 'payrollSummaries', 'pageData', key]);

export const getLoadingPageData = buildPageDataSelector('loadingPageData');

const columnLabel = column =>
  toI18n(
    `timesheets.react_page.payroll.payroll_summaries.table.headers.${column.get(
      'key'
    )}`
  );

const getAllColumns = buildPageDataSelector('columns');

export const getPayrollCodes = createSelector(
  buildPageDataSelector('payrollCodes'),
  codes => codes || Map()
);

export const getColumns = createSelector(getAllColumns, columns =>
  (columns || List())
    .filter(column => column.get('visible'))
    .map(column => column.set('name', columnLabel(column)))
);

export const getColumnOptions = createSelector(getAllColumns, columns =>
  (columns || List())
    .map(column => ({
      value: column.get('visible'),
      key: column.get('key'),
      label: columnLabel(column),
    }))
    .toArray()
);

export const getTotalTimecardsCount = createSelector(
  getFilteredTableData,
  rows => (rows ? rows.filter(r => !!r.get('actual_start_at')).size : 0)
);
