import React from 'react';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';

import { formatContributionValue, getDeductionType } from './utils';

const { cx, cxEl } = cxHelpers('GarnishDeduction');

const GarnishmentDeduction = ({
  deduction,
  onClickEdit,
  showFormWithSteps,
}) => (
  <Box pl={24} pt={24} pr={24} bb className={cx()}>
    <Box row mb32>
      <Box grow basis={0}>
        <Box mb12>
          <Text
            variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
            lh={1.25}
            i18n="team.employee_profile.tax_deductions.type_label_generic"
          />
        </Box>
        <Box>
          <Text variant="body" lh={1.25}>
            {getDeductionType(deduction).label}
          </Text>
        </Box>
      </Box>

      <Box grow={2} basis={0}>
        <Box mb12>
          <Text
            variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
            lh={1.25}
            i18n="team.employee_profile.tax_deductions.name_label"
          />
        </Box>
        <Box>
          <Text variant="body" lh={1.25}>
            {deduction.get('name')}
          </Text>
        </Box>
      </Box>
      <Box grow basis={0}>
        <Box className={cxEl('edit-button')} tright>
          <Button
            onClick={onClickEdit}
            variant="secondary"
            size={showFormWithSteps ? 'small' : 'medium'}
            startIcon="Edit"
          >
            <Text i18n="actions.edit" />
          </Button>
        </Box>
      </Box>
    </Box>

    <Box row mb32>
      <Box grow basis={0} row>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.start_date_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {deduction.get('formatted_start_date') || '--'}
            </Text>
          </Box>
        </Box>

        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.end_date_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {deduction.get('formatted_end_date') || '--'}
            </Text>
          </Box>
        </Box>

        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.external_id_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {deduction.get('external_id')}
            </Text>
          </Box>
        </Box>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
              lh={1.25}
              i18n="team.employee_profile.tax_deductions.state_issued_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {deduction.get('state_issued')}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>

    <Box row mb32>
      <Box grow basis={0}>
        <Box mb12>
          <Text
            variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
            lh={1.25}
            i18n="team.employee_profile.tax_deductions.issue_date_label"
          />
        </Box>
        <Box>
          <Text variant="body" lh={1.25}>
            {deduction.get('formatted_issue_date') || '--'}
          </Text>
        </Box>
      </Box>

      <Box grow={3} basis={0}>
        <Box mb12>
          <Text
            variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
            lh={1.25}
            i18n="team.employee_profile.tax_deductions.employee_contribution_label"
          />
        </Box>
        <Box>
          <Text variant="body" lh={1.25}>
            {formatContributionValue(deduction)}
          </Text>
        </Box>
      </Box>
    </Box>
  </Box>
);

GarnishmentDeduction.propTypes = {
  deduction: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default GarnishmentDeduction;
