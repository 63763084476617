import windowDocument from './windowDocument';
export const GOOGLE_MAPS_LIB =
  '//maps.googleapis.com/maps/api/js?key=AIzaSyA_FC2eG7HuJpChAGJmG6GdXlFodZy5I0M&libraries=places';

declare global {
  interface Window {
    google: any;
  }
}

let loading = false;
let loaded = !!window.google?.maps;

const callbacks: Array<(maps: any) => void> = [];

const loadGoogleMaps = (done: (maps: any) => void) => {
  const document = windowDocument();

  if (loaded) done(window.google.maps);

  callbacks.push(done);
  if (!loading) {
    loading = true;

    const script = document.createElement('script');

    script.setAttribute('src', GOOGLE_MAPS_LIB);
    script.onload = () => {
      loaded = true;
      callbacks.forEach(cb => cb(window.google.maps));
    };

    document.head.appendChild(script);
  }
};

// For testing, we need to be able to reset state.
export const resetLoadState = () => {
  loading = false;
  loaded = false;
  callbacks.length = 0;
};

export default loadGoogleMaps;
