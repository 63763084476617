import { fromJS, List, Map } from 'immutable';
import { toPath } from 'lodash';
import { createSelector } from 'reselect';

export const INITIAL_FIELD_STATE = Map({
  value: '',
  errors: List(),
  serverValue: '',
});

export const buildFormActionType = (formName, action) =>
  `FORMS/${formName}/${action}`;

// ## Form Action Types
const INITIALIZE = 'INITIALIZE';
const ADD_FIELD = 'ADD_FIELD';
const UPDATE_FIELD = 'UPDATE_FIELD';
const REMOVE_FIELD = 'REMOVE_FIELD';
const REMOVE_SERVER_ERROR = 'REMOVE_SERVER_ERROR';

export const buildFormActionTypes = formName => ({
  INITIALIZE: buildFormActionType(formName, INITIALIZE),
  ADD_FIELD: buildFormActionType(formName, ADD_FIELD),
  UPDATE_FIELD: buildFormActionType(formName, UPDATE_FIELD),
  REMOVE_FIELD: buildFormActionType(formName, REMOVE_FIELD),
  REMOVE_SERVER_ERROR: buildFormActionType(formName, REMOVE_SERVER_ERROR),
});

// ## Form Actions
export const initializeForm = formName => formData => ({
  type: buildFormActionType(formName, INITIALIZE),
  payload: {
    formData,
  },
});

export const addFormField = formName => (fieldName, fieldData) => ({
  type: buildFormActionType(formName, ADD_FIELD),
  payload: {
    fieldName,
    fieldData,
  },
});

export const updateFormField = formName => (fieldName, fieldData) => ({
  type: buildFormActionType(formName, UPDATE_FIELD),
  payload: {
    fieldName,
    fieldData,
  },
});

export const removeFormField = formName => fieldName => ({
  type: buildFormActionType(formName, REMOVE_FIELD),
  payload: {
    fieldName,
  },
});

export const removeServerError = formName => index => ({
  type: buildFormActionType(formName, REMOVE_SERVER_ERROR),
  payload: {
    index,
  },
});

export const buildFormActions = formName => ({
  initializeForm: initializeForm(formName),
  addFormField: addFormField(formName),
  updateFormField: updateFormField(formName),
  removeFormField: removeFormField(formName),
});

// ## Form State & Helpers
export const buildInitialFormState = (fields, additional) =>
  fromJS({
    fields,
    errors: Map(),
    serverErrors: List(),
    initial: Map(),
    isSubmitting: false,
    ...additional,
  });

export const buildInitialFieldState = value =>
  INITIAL_FIELD_STATE.merge({
    value,
    serverValue: value,
  });

export const buildNewFieldState = value => INITIAL_FIELD_STATE.merge({ value });

export const updateErrorsState = (state, payload) => {
  const {
    fieldData: { errors },
    fieldName,
  } = payload;
  const path = ['errors', fieldName];
  return errors.size > 0 ? state.setIn(path, errors) : state.deleteIn(path);
};

export const setManualErrorState = (state, fieldName, error) => {
  const fieldPath = toPath(fieldName);
  state = state.setIn(['fields', ...fieldPath, 'errors'], List([error]));
  return state.setIn(['errors', fieldName], List([error]));
};

export const updateFormFieldState = (state, path, newData) =>
  state.updateIn(path, INITIAL_FIELD_STATE, oldFieldData =>
    oldFieldData.merge(newData)
  );

export const handleInitializeForm = (initialState, action) =>
  initialState.merge({
    fields: action.payload.formData,
    initial: action.payload.formData,
  });

export const handleUpdateFormField = (state, action) => {
  const updatePath = ['fields', ...toPath(action.payload.fieldName)];
  const newState = updateFormFieldState(
    state,
    updatePath,
    action.payload.fieldData
  );

  if (action.payload.errors) {
    return updateErrorsState(newState, action.payload);
  }

  return newState;
};

export const handleRemoveServerError = (state, action) =>
  state.deleteIn(['serverErrors', action.payload.index]);

export const handleSubmitRequest = state => state.set('isSubmitting', true);

const getErrorMessagesFromPayload = payload => {
  let errors;

  if (payload.response && payload.response.errors) {
    errors = payload.response.errors;
  } else {
    errors = ['Something went wrong!'];
  }

  return fromJS(errors);
};

export const handleSubmitFailure = (state, action) =>
  state.merge({
    isSubmitting: false,
    serverErrors: getErrorMessagesFromPayload(action.payload),
  });

export const handleDeleteInFieldArray = (
  state,
  name,
  fieldName = '_destroy'
) => {
  const namePath = toPath(name);
  const id = namePath[namePath.length - 1];
  const path = ['fields', ...namePath];

  // If new, just remove the fields entirely
  if (id.startsWith('NEW')) {
    return state.deleteIn(path);
  }

  return state.updateIn(path, fields =>
    fields.set(fieldName, buildNewFieldState('1'))
  );
};

// ## Form Selectors
export const getFormIsSubmitting = formStatePath => state =>
  state.getIn([...formStatePath, 'isSubmitting']);
export const getFormFields = formStatePath => state =>
  state.getIn([...formStatePath, 'fields']);
export const getFormInitial = formStatePath => state =>
  state.getIn([...formStatePath, 'initial']);
export const getFormErrors = formStatePath => state =>
  state.getIn([...formStatePath, 'errors']);
export const getFormServerErrors = formStatePath => state =>
  state.getIn([...formStatePath, 'serverErrors']);
export const getFormIsPristine = formStatePath => state =>
  getFormFields(formStatePath)(state).equals(
    getFormInitial(formStatePath)(state)
  );
export const getInFormAppliedToAll =
  formStatePath =>
  (state, { fieldPath }) => {
    state.getIn([...formStatePath, 'appliedToAll', ...fieldPath]);
  };

export const getFormField =
  formStatePath =>
  (state, { fieldName }) => {
    const fieldPath = toPath(fieldName);
    return state.getIn([...formStatePath, 'fields', ...fieldPath]);
  };

const getInFormField =
  key =>
  formStatePath =>
  (state, { fieldName }) => {
    const fieldPath = toPath(fieldName);
    return state.getIn([...formStatePath, 'fields', ...fieldPath, key]);
  };

const getFormFieldValue = getInFormField('value');
const getFormFieldErrors = getInFormField('errors');
const getFormFieldInitial = getInFormField('initial');
const getFormFieldServer = getInFormField('serverValue');

export const buildFormSelectors = formStatePath => {
  const selectors = {
    getIsSubmitting: getFormIsSubmitting(formStatePath),
    getFields: getFormFields(formStatePath),
    getInitial: getFormInitial(formStatePath),
    getErrors: getFormErrors(formStatePath),
    getIsPristine: getFormIsPristine(formStatePath),
    getField: getFormField(formStatePath),
    getFieldValue: getFormFieldValue(formStatePath),
    getFieldErrors: getFormFieldErrors(formStatePath),
    getFieldInitial: getFormFieldInitial(formStatePath),
    getFieldServer: getFormFieldServer(formStatePath),
    getServerErrors: getFormServerErrors(formStatePath),
    getInAppliedToAll: getInFormAppliedToAll(formStatePath),
  };

  selectors.getHasErrors = createSelector(
    selectors.getErrors,
    errors => errors.size > 0
  );

  selectors.getHasServerErrors = createSelector(
    selectors.getServerErrors,
    errors => errors.size > 0
  );

  selectors.getSaveIsDisabled = createSelector(
    selectors.getHasErrors,
    selectors.getIsPristine,
    (hasErrors, isPristine) => hasErrors || isPristine
  );

  return selectors;
};

// ## Form Formatting
export const formFormatters = {
  phone: val =>
    val && val.length === 10
      ? `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6)}`
      : val,

  date: val => {
    if (!val) {
      return val;
    }

    const [year, month, day] = val.split('-');
    return day ? `${month}/${day}/${year}` : `${month}/${year}`;
  },
};

// ## Form Server Formatting
export const serverFormatters = {
  phone: val => (val ? val.replace(/[^\d]/g, '') : val),

  date: val => {
    if (!val) {
      return val;
    }

    const [month, day, year] = val.split('/');
    return `${year}-${month}-${day}`;
  },
};

export const copyFieldsValuesToObject = fields =>
  fields.reduce((data, field, fieldKey) => {
    data[fieldKey] = field.get('value');
    return data;
  }, {});
