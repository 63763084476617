import { listItemTextClasses } from '@mui/material/ListItemText';

import { Overrides } from '../../types';

export const listOverrides: Overrides = {
  MuiList: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        marginRight: '6px',
        minWidth: '16px',
        alignSelf: 'start',
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        marginTop: '0px',
        marginBottom: '0px',
        [`& .${listItemTextClasses.secondary}`]: {
          marginTop: '8px',
        },
      },
    },
  },
};
