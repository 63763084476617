import { useCallback, useState } from 'react';

export const useCalendlyModalHandlers = () => {
  const [calendlyModalIsOpen, setCalendlyModalIsOpen] = useState(false);
  const handleCalendlyClick = useCallback(() => {
    setCalendlyModalIsOpen(true);
  }, []);
  const handleCalendlyClose = useCallback(() => {
    setCalendlyModalIsOpen(false);
  }, []);

  return {
    calendlyModalIsOpen,
    handleCalendlyClick,
    handleCalendlyClose,
  };
};
