import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getShowPreNoteValidationBanner } from 'selectors/payroll';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
const { cx, cxEl } = cxHelpers('PayrollPreNoteValidationBanner');

const PayrollPreNoteValidationBanner = ({ showPreNoteValidationBanner }) => {
  if (showPreNoteValidationBanner) {
    return (
      <Box className={cx()}>
        <Box
          row
          h={44}
          tcenter
          vcenter
          hcenter
          bg="red"
          className={cxEl('text')}
        >
          <Text white i18n="payroll.dashboard.pre_note_validation.text" />
          &nbsp;
          <Button
            href="/payroll/pre_note_validation_handler"
            theme="link-white"
            className={cxEl('button')}
            i18n="payroll.dashboard.pre_note_validation.cta"
          />
        </Box>
      </Box>
    );
  }

  return null;
};

PayrollPreNoteValidationBanner.propTypes = {
  payrollPreNoteValidationEnabled: PropTypes.bool,
  hasFailedPreNoteValidation: PropTypes.bool,
};

export default connect(
  state => ({
    showPreNoteValidationBanner: getShowPreNoteValidationBanner(state),
  }),
  {}
)(PayrollPreNoteValidationBanner);
