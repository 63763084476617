import './NavItem.scss';

import React, { PureComponent } from 'react';
import { keys, omit } from 'lodash';
import PropTypes from 'prop-types';
import { colors, dimensions } from 'theme/constants';

import Box from 'components/Box';
import Icon from 'components/Icon';
import { TabletAndBelow } from 'components/MediaQuery';

import cxHelpers from 'util/className';

import withCloseMobileNav from '../../hocs/withCloseMobileNav';

export const INDICATOR_COLORS = [...keys(colors)];
const DEFAULT_INDICATOR_COLOR = 'red';

@withCloseMobileNav
@cxHelpers('NavItem')
export default class NavItem extends PureComponent {
  static propTypes = {
    onCloseMobileNav: PropTypes.func,
    onClick: PropTypes.func,
    persistMobileNavOnClick: PropTypes.bool,
    whiteText: PropTypes.bool,
    active: PropTypes.bool,
    indicator: PropTypes.bool,
    indicatorColor: PropTypes.oneOf(INDICATOR_COLORS),
    noCaret: PropTypes.bool,
    href: PropTypes.string,
    method: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    inverse: PropTypes.bool,
    getRef: PropTypes.func,
    noAnchor: PropTypes.bool,
  };

  static defaultProps = {
    indicatorColor: DEFAULT_INDICATOR_COLOR,
  };

  handleClick = e => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    if (!this.props.persistMobileNavOnClick) {
      this.props.onCloseMobileNav();
    }
  };

  handleRef = element => this.props.getRef(element);

  render() {
    const {
      target,
      active,
      indicator,
      indicatorColor,
      href,
      getRef,
      children,
      method,
      rel,
      noCaret,
      hidden,
      whiteText,
      inverse,
      noAnchor,
      ...boxProps
    } = omit(
      this.props,
      'className',
      'onCloseMobileNav',
      'onClick',
      'persistMobileNavOnClick',
      'menuItems',
      'companyIdIsEven',
      'mobile',
      'currentRoute',
      'serverIndicator'
    );

    if (hidden) {
      return null;
    }

    let anchorProps = {};
    let Component;

    if (noAnchor) {
      Component = Box;
    } else {
      Component = 'a';
      anchorProps = { href };
      if (method) {
        anchorProps['data-method'] = method;
      }
      if (rel) {
        anchorProps.rel = rel;
      }
    }

    return (
      <TabletAndBelow>
        {mobile => (
          <Box className={this.cx()} getRef={getRef && this.handleRef}>
            <Component
              className={this.cxEl('inner', { active, whiteText, inverse })}
              target={target}
              {...anchorProps}
              onClick={this.handleClick}
            >
              <Box
                mh={6}
                h={mobile ? 40 : dimensions.navHeight}
                vcenter
                relative
                {...boxProps}
              >
                {children}

                {indicator && (
                  <span
                    className={this.cxEl(
                      'notification-indicator',
                      { mobile },
                      'notification-indicator'
                    )}
                    style={{ background: colors[indicatorColor] }}
                  />
                )}

                {noCaret || mobile || (
                  <Icon
                    type="caretUp"
                    size="md"
                    className={this.cxEl('caret', { active })}
                  />
                )}
              </Box>
            </Component>
          </Box>
        )}
      </TabletAndBelow>
    );
  }
}
