import './StickyBanner.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Icon from 'components/Icon';

const StickyBanner = props => {
  const { children, closeIconProps, onClose, type, ...boxProps } = props;

  return (
    <Box
      className={`StickyBanner StickyBanner--${type}`}
      pa16
      row
      spacebetween
      borderbox
      fixed
      bottom={0}
      left={0}
      w="100%"
      z={100}
      {...boxProps}
    >
      {children}
      {onClose && (
        <Icon
          color="inherited"
          size="md"
          type="closeSimple"
          onClick={onClose}
          {...closeIconProps}
        />
      )}
    </Box>
  );
};

StickyBanner.propTypes = {
  closeIconProps: PropTypes.object,
  type: PropTypes.oneOf(['success', 'notice', 'error', 'blue']),
  onClose: PropTypes.func,
};

export default StickyBanner;
