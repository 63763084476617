import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { readRequest } from 'actions/notifications';

import { toI18n } from 'util/i18n';

import AlertsListItem from '../AlertsListItem';

@connect(() => ({}), { readRequest })
export default class ExpiredCertificate extends PureComponent {
  static propTypes = {
    item: ImmutablePropTypes.map.isRequired,
    readRequest: PropTypes.func.isRequired,
    closeDrawer: PropTypes.func.isRequired,
  };

  handleDismiss = () => this.props.readRequest(this.props.item);

  handleView = () => {
    this.props.closeDrawer();
    window.Homebase.RailsReactBridge.navigateToReactView(
      `/team/${this.props.item.get('user_id')}/personal_information`
    );
  };

  render() {
    const { item } = this.props;

    return (
      <AlertsListItem
        desc={item.get('body')}
        onEdit={this.handleView}
        onDismiss={this.handleDismiss}
        editButton={toI18n('actions.view')}
        dismissButton={toI18n('actions.dismiss')}
        {...this.props}
      />
    );
  }
}
