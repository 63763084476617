import React from 'react';
import Icon from 'react-icon-base';

const Mug = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M12.19 20.095h5.715c1.577 0 2.857-1.28 2.857-2.857h.952c2.084 0 3.81-1.696 3.81-3.81 0-2.083-1.727-3.809-3.81-3.809H10.048c-.417 0-.715.328-.715.714v6.905c0 1.578 1.28 2.857 2.857 2.857zm9.524-8.571c1.042 0 1.905.863 1.905 1.905 0 1.071-.863 1.904-1.905 1.904h-.952v-3.81h.952zm1.399 11.429c1.429 0 1.816-1.905 1.071-1.905H6.834c-.745 0-.358 1.905 1.07 1.905h15.209z"
    />
  </Icon>
);

export default Mug;
