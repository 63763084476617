import { fromJS, List, Map, Set } from 'immutable';
import { toPath } from 'lodash';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'actions/employeeView';

import * as constants from 'features/employeeView/constants';
import * as util from 'features/employeeView/util';

import { toEntityId } from 'util/entities';
import * as formsUtil from 'util/forms';

// Hard-resets the value and initial value for a field under both the 'fields' and 'initial' keys.
const initializeFormField = (state, path, value) => {
  state = formsUtil.updateFormFieldState(state, ['fields', ...path], {
    value,
    initial: value,
  });
  state = formsUtil.updateFormFieldState(state, ['initial', ...path], {
    value,
    initial: value,
  });
  return state;
};

const getLocationIdForJobId = (state, jobId) =>
  state
    .getIn(['fields', 'job_attributes'])
    .findKey(permissions => permissions.getIn(['job_id', 'value']) === jobId);

const initializeLocationPermissionsFields = (state, jobId, fieldsData) => {
  const locationId = getLocationIdForJobId(state, jobId);

  fieldsData.forEach((value, fieldKey) => {
    state = initializeFormField(
      state,
      ['job_attributes', locationId, fieldKey],
      value
    );
  });

  return state;
};

const removeMatchingUser = state =>
  state.set(
    'matchingUser',
    constants.INITIAL_EMPLOYEE_FORM_STATE.get('matchingUser')
  );

const form = (state = constants.INITIAL_EMPLOYEE_FORM_STATE, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEE_FORM_REQUEST:
      return action.error
        ? formsUtil.handleSubmitFailure(state, action)
        : formsUtil.handleSubmitRequest(state);

    case actionTypes.EMPLOYEE_FORM_FAILURE:
      return formsUtil.handleSubmitFailure(state, action);

    case actionTypes.REMOVE_EMPLOYEE_FORM_SERVER_ERROR:
      return formsUtil.handleRemoveServerError(state, action);

    case actionTypes.ADD_EMPLOYEE_FORM_FIELD: {
      const fieldPath = toPath(action.payload.fieldName);
      const newState = state.setIn(
        ['fields', ...fieldPath],
        formsUtil.INITIAL_FIELD_STATE.merge(action.payload.fieldData)
      );
      return formsUtil.updateErrorsState(newState, action.payload);
    }

    case actionTypes.UPDATE_EMPLOYEE_FORM_FIELD: {
      const updatePath = ['fields', ...toPath(action.payload.fieldName)];
      const newState = formsUtil.updateFormFieldState(
        state,
        updatePath,
        action.payload.fieldData
      );
      return formsUtil.updateErrorsState(newState, action.payload);
    }

    case actionTypes.REMOVE_EMPLOYEE_FORM_FIELD: {
      const fieldPath = toPath(action.payload.fieldName);
      const newState = state.deleteIn(['fields', ...fieldPath]);
      return newState.removeIn(['errors', action.payload.fieldName]);
    }

    case actionTypes.INITIALIZE_EMPLOYEE_FORM:
      return constants.INITIAL_EMPLOYEE_FORM_STATE.merge({
        fields: action.payload.formData,
        initial: action.payload.formData,
      });

    case actionTypes.EMPLOYEE_FORM_SUCCESS: {
      const formData = util.buildEmployeeFormDataForUser(
        fromJS(action.payload)
      );
      return constants.INITIAL_EMPLOYEE_FORM_STATE.merge({
        isSubmitting: false,
        fields: formData,
        initial: formData,
      });
    }

    // remove this once we remove old employee profile
    case actionTypes.UNARCHIVE_JOB_SUCCESS: {
      if (action.meta.oldProfile) {
        const fieldsData = Map({
          enabled: true,
          ...action.payload,
          claim_open_shift: true,
          in_schedule: true,
        });

        return initializeLocationPermissionsFields(
          state,
          action.payload.id,
          fieldsData
        );
      }

      return state;
    }

    case actionTypes.TERMINATION_FORM_SUCCESS: {
      if (action.meta.allLocations) {
        Object.keys(action.payload).forEach(jobId => {
          const fieldsData = Map({
            enabled: false,
            ...action.payload.archived_jobs[jobId],
            claim_open_shift: false,
            in_schedule: false,
          });

          state = initializeLocationPermissionsFields(
            state,
            parseInt(jobId, 10),
            fieldsData
          );
        });

        return state;
      }

      const fieldsData = Map({
        enabled: false,
        ...action.payload.archived_jobs,
        claim_open_shift: false,
        in_schedule: false,
      });

      return initializeLocationPermissionsFields(
        state,
        action.meta.resourceId,
        fieldsData
      );
    }

    case actionTypes.ADD_MATCHING_USER: {
      state.get('matchingUser').forEach((value, fieldName) => {
        state = formsUtil.updateFormFieldState(state, ['fields', fieldName], {
          value,
          errors: new List(),
        });
        state = state.deleteIn(['errors', fieldName]);
      });

      return removeMatchingUser(state);
    }

    case actionTypes.REMOVE_MATCHING_USER: {
      // Remove phone/email if current value doesn't match initial value
      ['phone', 'email'].forEach(attribute => {
        const initialValue = state.getIn(['initial', attribute, 'value']);
        const currentValue = state.getIn(['fields', attribute, 'value']);

        if (initialValue !== currentValue) {
          state = formsUtil.updateFormFieldState(state, ['fields', attribute], {
            value: initialValue,
          });
        }
      });

      return removeMatchingUser(state);
    }

    case actionTypes.DELETE_HISTORICAL_WAGES_FOR_JOB_SUCCESS:
      return state.updateIn(['fields', 'job_attributes'], jobs => {
        const jobKey = jobs.findKey(
          jobFields =>
            jobFields.getIn(['job_id', 'value']) === action.meta.jobId
        );
        return jobs.update(jobKey, jobFields =>
          jobFields.merge({ historical_wages: List() })
        );
      });

    default:
      return state;
  }
};

const search = (state = '', action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SEARCH:
      return action.payload.search;

    default:
      return state;
  }
};

const terminationForm = (
  state = constants.INITIAL_TERMINATION_FORM_STATE,
  action
) => {
  switch (action.type) {
    case actionTypes.TERMINATION_FORM_REQUEST:
      return state.set('isSubmitting', true);

    case actionTypes.TERMINATION_FORM_FAILURE: {
      let serverErrors = action.payload.message;
      if (!serverErrors) {
        const failedLocations = action.payload
          .map(loc => loc.location_name)
          .join(', ');
        serverErrors = `Failed to terminate employee from the following location${
          failedLocations.size > 1 ? 's' : ''
        }: ${failedLocations}`;
      }

      return state.merge({
        isSubmitting: false,
        currentView: constants.TERMINATION_FORM_VIEW_OPTIONS.FAILURE,
        serverErrors,
      });
    }

    case actionTypes.TERMINATION_FORM_SUCCESS:
      return state.merge({
        isSubmitting: false,
        currentView: constants.TERMINATION_FORM_VIEW_OPTIONS.SUCCESS,
        hireReplacementRedirectPath:
          action.payload.hire_replacement_redirect_path,
      });

    case actionTypes.UPDATE_TERMINATION_FORM_FIELD: {
      const fieldPath = toPath(action.payload.fieldName);
      const newState = formsUtil.updateFormFieldState(
        state,
        ['fields', ...fieldPath],
        action.payload.fieldData
      );
      return formsUtil.updateErrorsState(newState, action.payload);
    }

    case actionTypes.INITIALIZE_TERMINATION_FORM:
      return constants.INITIAL_TERMINATION_FORM_STATE.mergeIn(
        ['fields', 'job_id'],
        {
          value: action.payload.formData.job_id,
        }
      );

    case 'EMPLOYEE/ADD_LAST_HIRE_DATE/fulfilled':
      return state.merge({
        lastHireDate: action.payload.lastHireDate,
      });

    default:
      return state;
  }
};

const INITIAL_UNARCHIVE_STATE = Map({
  isLoadingIds: Set(),
});

const unarchive = (state = INITIAL_UNARCHIVE_STATE, action) => {
  switch (action.type) {
    case actionTypes.UNARCHIVE_JOB_REQUEST: {
      return state.update('isLoadingIds', ids => ids.add(action.jobId));
    }

    case actionTypes.UNARCHIVE_JOB_SUCCESS:
    case actionTypes.UNARCHIVE_JOB_FAILURE: {
      return state.update('isLoadingIds', ids => ids.remove(action.jobId));
    }

    default:
      return state;
  }
};

const INITIAL_NOTES_STATE = Map({
  isLoadingIds: Set(),
  newNoteBody: '',
});

const notes = (state = INITIAL_NOTES_STATE, action) => {
  switch (action.type) {
    case actionTypes.CREATE_JOB_NOTE_REQUEST:
      return state.update('isLoadingIds', ids => ids.add(action.meta.noteId));

    case actionTypes.CREATE_JOB_NOTE_FAILURE:
      return state.update('isLoadingIds', ids =>
        ids.remove(action.meta.noteId)
      );

    case actionTypes.CREATE_JOB_NOTE_SUCCESS: {
      const newState = state.update('isLoadingIds', ids =>
        ids.remove(action.meta.noteId)
      );
      return newState.set(
        'newNoteBody',
        INITIAL_NOTES_STATE.get('newNoteBody')
      );
    }

    case actionTypes.UPDATE_NEW_JOB_NOTE_BODY:
      return state.set('newNoteBody', action.payload.body);

    default:
      return state;
  }
};

const INITIAL_LOADED_EMPLOYEES_STATE = Map({
  loadingIds: Set(),
  loadedIds: Set(),
  loadingTabs: Set(),
  loadedTabs: Set(),
  defaultTab: 'job_details',
});

const loadedEmployees = (state = INITIAL_LOADED_EMPLOYEES_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYEE_REQUEST:
      return state.update('loadingIds', ids =>
        ids.add(toEntityId(action.meta.userId))
      );

    case actionTypes.FETCH_EMPLOYEE_SUCCESS: {
      state = state.update('loadingIds', ids =>
        ids.remove(toEntityId(action.meta.userId))
      );
      return state.update('loadedIds', ids =>
        ids.add(toEntityId(action.meta.userId))
      );
    }

    case actionTypes.FETCH_EMPLOYEE_FAILURE:
      return state.update('loadingTabs', ids =>
        ids.remove(toEntityId(action.meta.userId))
      );

    case actionTypes.FETCH_EMPLOYEE_TAB_REQUEST:
      return state.update('loadingTabs', ids =>
        ids.add(`${action.meta.userId}-${action.meta.tab}`)
      );

    case actionTypes.FETCH_EMPLOYEE_TAB_SUCCESS: {
      state = state.update('loadingTabs', ids =>
        ids.remove(`${action.meta.userId}-${action.meta.tab}`)
      );
      return state.update('loadedTabs', ids =>
        ids.add(`${action.meta.userId}-${action.meta.tab}`)
      );
    }

    case actionTypes.FETCH_EMPLOYEE_TAB_FAILURE:
      return state.update('loadingIds', ids =>
        ids.remove(`${action.meta.userId}-${action.meta.tab}`)
      );

    case actionTypes.UPDATE_DEFAULT_TAB:
      return state.set('defaultTab', action.payload.tabName);

    default:
      return state;
  }
};

export default combineReducers({
  search,
  form,
  terminationForm,
  unarchive,
  notes,
  loadedEmployees,
});
