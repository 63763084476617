import { createSelector } from 'reselect';

import { selectShowPayrollEoyBanner } from 'selectors/session';

import { selectShopifyUrl } from '../biller/implementations/shopify/selectors';
import {
  selectShowCreditCardExpiryBanner,
  selectShowSubscriptionPastDueBanner,
} from '../biller/selectors';
import { selectEnforcementBannerVariant } from '../enforcement/EnforcementBanner/selectors';

export const selectShouldShowBillingStateBanners = createSelector(
  selectShowCreditCardExpiryBanner,
  selectShowSubscriptionPastDueBanner,
  selectShopifyUrl,
  selectShowCreditCardExpiryBanner,
  (
    showCreditCardExpiryBanner,
    showSubscriptionPastDueBanner,
    shopifyUrl,
    shopifyTierDowngradeError
  ) =>
    showCreditCardExpiryBanner ||
    showSubscriptionPastDueBanner ||
    shopifyUrl ||
    shopifyTierDowngradeError
);

export const selectShouldShowPayrollEoyBanner = createSelector(
  selectShowPayrollEoyBanner,
  selectShouldShowBillingStateBanners,
  selectEnforcementBannerVariant,
  (
    showPayrollEoyBanner,
    shouldShowBillingStateBanners,
    enforcementBannerVariant
  ) =>
    showPayrollEoyBanner &&
    !shouldShowBillingStateBanners &&
    !Boolean(enforcementBannerVariant)
);
