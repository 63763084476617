import React from 'react';
import Box from 'fe-components/Box';
import RadioButtonField from 'fe-design-base/molecules/RadioButtonField';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';

const { cxEl } = cxHelpers('OnboardingOption');

const OnboardingOption = ({ disabled, label, onClick, subLabel, value }) => (
  <Box className={cxEl(value)} mv={8}>
    <RadioButtonField
      disabled={disabled}
      onClick={onClick}
      label={label}
      helperText={subLabel || null}
      data-testid="onboarding-option"
      name="onboarding"
      value={value}
    />
  </Box>
);

OnboardingOption.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  subLabel: PropTypes.object,
  value: PropTypes.string,
};

OnboardingOption.defaultProps = {
  disabled: false,
  subLabel: null,
};

export default OnboardingOption;
