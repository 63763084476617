import './Box.scss';

import React, { ForwardedRef, forwardRef } from 'react';

import { cxHelpers } from 'util/className';

import { getProps } from './helpers';
import { ClassProps, Props, StyleProps } from './interface';

const { cx } = cxHelpers('Box');

const Box = forwardRef(
  (
    { className, children, tag = 'div', ...props }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const Component = tag;

    return (
      <Component
        ref={ref}
        {...getProps(
          props as Record<string, ClassProps | StyleProps>,
          cx,
          className
        )}
      >
        {children}
      </Component>
    );
  }
);

export default Box;
