import { shadows } from 'fe-design-base/styles/shadows';

import { Overrides } from '../../types';
import baseColors from '../colors';

export const buttonOverrides: Overrides = {
  MuiIconButton: {
    styleOverrides: {
      root: () => ({
        '&.FDBIconButton': {
          color: baseColors.purple500,
          padding: 0,
          borderRadius: '50%',
          border: 'none',
          '&:hover': {
            background: baseColors.purple100,
            border: 'none',
            color: baseColors.purple700,
          },
          '&:focus-visible': {
            border: 'none',
            background: baseColors.purple100,
            color: baseColors.purple700,
            outlineOffset: '1px',
            outline: 'solid 2px',
            outlineColor: baseColors.accent500,
          },
          '&:active': {
            background: baseColors.purple300,
            color: baseColors.purple900,
          },
          '&:disabled': {
            color: baseColors.mono500,
            opacity: 1,
          },
          '&.FDBIconButton--destructive': {
            color: baseColors.red300,
            '&:hover': {
              background: baseColors.red100,
              border: 'none',
              color: baseColors.red500,
            },
            '&:focus-visible': {
              background: baseColors.red100,
              color: baseColors.red500,
            },
            '&:active': {
              background: baseColors.red100,
              color: baseColors.red900,
            },
            '&:disabled': {
              color: baseColors.mono500,
              opacity: 1,
            },
          },
        },
      }),
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: () => ({
        '&.FDBButton': {
          boxShadow: 'none',
          fontWeight: 700,
          lineHeight: 0,
          borderRadius: '8px',
          textTransform: 'none',
          textShadow: 'none',

          '&.MuiButton-sizeSmall span': {
            fontSize: '14px',
          },

          '&.FDBButton--loading': {
            pointerEvents: 'none',
          },
        },
        '&:focus-visible': {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: baseColors.accent500,
          outlineOffset: '2px',
          backgroundColor: baseColors.purple100,
        },
        '&.FDBButton.aria-disabled': {
          pointerEvents: 'none',
        },
        '&.FDBButton--iconWhite': {
          '&:hover': {
            backgroundColor: `${baseColors.purple500} !important`,
          },
        },
        '&:disabled': {
          opacity: 1,
        },
        '&:focus': {
          border: 0,
        },
        '&:active': {
          border: 0,
          boxShadow: 'none',
        },
        '&.FDBButton:focus-visible': {
          boxShadow: 'none',
        },
        '&.FDBButton--primary': {
          color: baseColors.mono0,
          backgroundColor: baseColors.purple500,
          '&:hover': {
            backgroundColor: baseColors.purple700,
            border: 0,
          },
          '&:active': {
            backgroundColor: baseColors.purple900,
          },
          '&.aria-disabled': {
            color: baseColors.mono500,
            backgroundColor: baseColors.mono300,
          },
          '&:focus-visible:not(&.aria-disabled)': {
            backgroundColor: baseColors.purple700,
          },
        },

        '&.FDBFab': {
          borderRadius: 64,
          height: '64px',
          width: '64px',
          boxShadow: `${shadows.z4()} !important`,
          color: baseColors.mono0,
          backgroundColor: baseColors.purple500,
          '&:hover': {
            backgroundColor: baseColors.purple700,
            border: 0,
          },
          '&:focus-visible': {
            backgroundColor: baseColors.purple700,
            outlineOffset: '3px',
          },
          '&:active': {
            backgroundColor: baseColors.purple900,
          },
        },

        '&.FDBButton--secondary': {
          color: baseColors.purple500,
          backgroundColor: baseColors.mono0,
          border: `solid 2px ${baseColors.purple500}`,
          padding: '5.5px 16px',
          '&:hover': {
            backgroundColor: baseColors.purple100,
          },
          '&:active': {
            backgroundColor: baseColors.purple300,
          },
          '&.aria-disabled': {
            color: baseColors.mono500,
            border: `solid 2px ${baseColors.mono500}`,
          },
          '&:focus-visible:not(&.aria-disabled)': {
            backgroundColor: baseColors.purple100,
          },
        },
        '&.FDBButton--tertiary': {
          color: baseColors.purple500,
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: baseColors.purple100,
            border: 'none',
          },
          '&:focus-visible': {
            backgroundColor: baseColors.purple100,
          },
          '&:active': {
            backgroundColor: baseColors.purple300,
          },
          '&.aria-disabled': {
            color: baseColors.mono500,
            backgroundColor: 'transparent',
            border: 'none',
          },
        },

        '&.FDBButton--primaryDestructive': {
          color: baseColors.mono0,
          backgroundColor: baseColors.red300,
          '&:hover': {
            backgroundColor: baseColors.red500,
            border: 'none',
          },
          '&:active': {
            backgroundColor: baseColors.red900,
          },
          '&:focus-visible:not(&.aria-disabled)': {
            backgroundColor: baseColors.red500,
          },
          '&.aria-disabled': {
            color: baseColors.mono500,
            backgroundColor: baseColors.mono300,
          },
        },

        '&.FDBButton--secondaryDestructive': {
          color: baseColors.red300,
          backgroundColor: baseColors.mono0,
          border: `solid 2px ${baseColors.red300}`,
          '&:hover': {
            backgroundColor: baseColors.red100,
            color: baseColors.red500,
            border: `solid 2px ${baseColors.red500}`,
          },
          '&:focus-visible:not(&.aria-disabled)': {
            backgroundColor: baseColors.red100,
            color: baseColors.red500,
          },
          '&:active': {
            backgroundColor: baseColors.red100,
            color: baseColors.red900,
            border: `solid 2px ${baseColors.red900}`,
          },
          '&.aria-disabled': {
            color: baseColors.mono500,
            border: `solid 2px ${baseColors.mono500}`,
          },
        },
        '&.FDBButton--mono': {
          background: baseColors.mono900,
          color: baseColors.mono0,
        },
        '&.FDBButton--blue': {
          background: baseColors.teal300,
          color: baseColors.mono0,
        },
        '&.FDBButton--purple': {
          background: baseColors.purple700,
          color: baseColors.mono0,
        },
        '&.FDBButton--purpleInverted': {
          background: baseColors.mono0,
          color: baseColors.purple900,
          '&:hover': {
            background: baseColors.purple100,
          },
        },

        '&:hover': {
          boxShadow: 'none',
        },
      }),
      sizeMedium: {
        '&.FDBButton': {
          fontSize: 16,
          lineHeight: '20px',
          padding: '10px 24px',

          '&.FDBButton--secondary, &.FDBButton--secondaryDestructive': {
            padding: '8px 22px',
          },
        },
      },
      sizeSmall: {
        '&.FDBButton': {
          fontSize: 12,
          lineHeight: '17px',
          padding: '7.5px 16px',

          '.FDBIcon svg': {
            transform: 'scale(0.875)',
          },

          '&.FDBButton--secondary, &.FDBButton--secondaryDestructive': {
            padding: '5.5px 14px',
          },
        },
      },
    },
  },
};
