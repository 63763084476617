import {
  addWeeks,
  endOfMonth,
  getTimeDifference,
  STANDARD_DATE_FORMAT,
  startOfMonth,
  today,
  workWeekContaining,
} from 'util/dateTime';

import { BASE_ROUTE, RANGE_TYPES, VIEW_TYPES } from '../constants';

const MAX_WEEKS = 6;

export const urlParamsToReduxProps = ({
  rangeStart,
  rangeEnd,
  viewType,
  rangeType,
  startOfWeek,
  departmentsAccess,
}) => {
  rangeStart = rangeStart || today(STANDARD_DATE_FORMAT);

  if (rangeType === RANGE_TYPES.day) {
    rangeEnd = rangeStart;
  } else {
    rangeEnd = rangeEnd || rangeStart;

    if (rangeType === RANGE_TYPES.month) {
      rangeStart = startOfMonth(rangeStart, STANDARD_DATE_FORMAT);
      rangeEnd = endOfMonth(rangeEnd, STANDARD_DATE_FORMAT);
    }

    rangeStart = workWeekContaining(rangeStart, startOfWeek).start;
    rangeEnd = workWeekContaining(rangeEnd, startOfWeek).end;

    const weekDiff = getTimeDifference(rangeStart, rangeEnd, 'weeks');

    if (rangeType !== RANGE_TYPES.month && weekDiff > 0) {
      rangeType = RANGE_TYPES.custom;
    }

    if (rangeType === RANGE_TYPES.custom) {
      if (weekDiff > MAX_WEEKS) {
        rangeEnd = addWeeks(rangeEnd, MAX_WEEKS - weekDiff).format(
          STANDARD_DATE_FORMAT
        );
      }
    }
  }

  if (viewType === VIEW_TYPES.department && !departmentsAccess) {
    viewType = VIEW_TYPES.employee;
  }

  return {
    viewType,
    rangeType,
    rangeStart,
    rangeEnd,
  };
};

export const pageUrl = ({ viewType, rangeType, rangeStart, rangeEnd }) => {
  if (rangeType === RANGE_TYPES.month) {
    viewType = VIEW_TYPES.employee;
  }

  if (rangeType === RANGE_TYPES.custom) {
    return `${BASE_ROUTE}/${viewType}/${rangeType}/${rangeStart}/${rangeEnd}`;
  }

  return `${BASE_ROUTE}/${viewType}/${rangeType}/${rangeStart}`;
};
