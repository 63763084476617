import React from 'react';
import Icon from 'react-icon-base';

const ThumbsUp = props => (
  <Icon viewBox="-1 0 24 24" {...props}>
    <g>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.428 13.554l-.76-.392a1.502 1.502 0 0 1-.814-1.341c0-.829.657-1.503 1.465-1.503l6.212-.08-.527-4.917c.001-.964.552-2.444 1.383-2.444h.744l.658 3.534 3.444 5.47c.69.993.952 1.582 1.051 2.205v7.396c-.213.869-.979 1.515-1.892 1.515H3.658c-.808 0-1.465-.674-1.465-1.502 0-.338.11-.66.319-.93l.55-.71h-.936c-.807 0-1.464-.674-1.464-1.502 0-.35.121-.691.342-.961l.392-.48-.572-.214a1.507 1.507 0 0 1-.968-1.41c0-.569.311-1.083.813-1.342l.76-.392zm13.711.34c0-.489.387-.886.862-.886h5.282c.476 0 .862.397.862.885v8.346a.874.874 0 0 1-.862.884H16a.875.875 0 0 1-.862-.884v-8.346zm-15.405-.34A2.389 2.389 0 0 0-1 15.288a2.4 2.4 0 0 0 1.058 1.991c-.165.333-.252.701-.252 1.074 0 1.065.7 2 1.687 2.288-.103.27-.155.557-.155.854 0 1.311 1.04 2.378 2.319 2.378h8.735c.807 0 1.532-.352 2.045-.912.27.612.869 1.039 1.564 1.039h5.282c.946 0 1.717-.79 1.717-1.76v-8.347c0-.971-.77-1.761-1.717-1.761H16c-.503 0-.955.225-1.269.58a9.588 9.588 0 0 0-.792-1.323l-3.358-5.333L9.84 2H8.386C7.022 2 6.151 3.976 6.151 5.337l.49 4.028-5.324.077C.041 9.442-1 10.509-1 11.82c0 .663.266 1.286.734 1.733z"
        clipRule="evenodd"
      />
    </g>
  </Icon>
);

export default ThumbsUp;
