// merge pending versions with saved entity and return that
function mergeEntities(entities) {
  return entities.reduce((mergedEntity, pendingEntity) => {
    if (!pendingEntity) return mergedEntity;

    return {
      _new: pendingEntity._new || mergedEntity._new,
      attributes: {
        ...(mergedEntity.attributes || {}),
        ...(pendingEntity.attributes || {}),
      },
      relationships: {
        ...(mergedEntity.relationships || {}),
        ...(pendingEntity.relationships || {}),
      },
    };
  }, {});
}

export default mergeEntities;
