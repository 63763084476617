import Text, {
  BigDetail,
  BigPageTitle,
  CapsLabel,
  CapsText,
  Detail,
  Error,
  FONT_SIZE_PROP_TYPES,
  FONT_WEIGHT_PROP_TYPES,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  HeroDescription,
  HeroSectionTitle,
  HeroTitle,
  Label,
  OTHER_MODIFIER_PROP_TYPES,
  P,
  PageSubTitle,
  PageTitle,
  Paragraph,
  SectionTitle,
  TeamSectionTitle,
  Warning,
} from './Text';

export default Text;

export {
  FONT_WEIGHT_PROP_TYPES,
  FONT_SIZE_PROP_TYPES,
  OTHER_MODIFIER_PROP_TYPES,
  PageTitle,
  BigPageTitle,
  SectionTitle,
  TeamSectionTitle,
  HeroTitle,
  HeroSectionTitle,
  HeroDescription,
  PageSubTitle,
  CapsLabel,
  CapsText,
  Error,
  Warning,
  Label,
  Detail,
  BigDetail,
  P,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  Paragraph,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
};
