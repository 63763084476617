import './Tab.scss';

import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import cxHelpers from 'util/className';
import getIsEmbedded from 'util/embedded';

@cxHelpers('Tab')
export default class Tab extends PureComponent {
  static propTypes = {
    content: PropTypes.node.isRequired,
    reactRouter: PropTypes.bool,
    upgradeRequired: PropTypes.bool,
    active: PropTypes.bool,
    large: PropTypes.bool,
    className: PropTypes.string,
    callBack: PropTypes.func,
  };

  isEmbedded = getIsEmbedded();

  render() {
    const {
      reactRouter,
      upgradeRequired,
      content,
      large,
      active,
      callback,
      ...props
    } = omit(this.props, ['pathname']);
    let Component = 'a';

    const className = this.cx({
      'upgrade-required': upgradeRequired,
      active: !reactRouter && active,
      large,
      isEmbeded: this.isEmbedded,
    });

    if (reactRouter) {
      props.activeClassName = this.cx('active');
      Component = NavLink;
    }

    if (callback) {
      props.onClick = callback;
    }

    return (
      <Component {...props} className={className}>
        <div className={this.cxEl('marker', { isEmbedded: this.isEmbedded })} />
        <div className={this.cxEl('content')}>{content}</div>
      </Component>
    );
  }
}
