import React from 'react';
import PropTypes from 'prop-types';

import GarnishmentDeduction from './GarnishmentDeduction';
import MiscDeduction from './MiscDeduction';
const PostTaxDeduction = ({ deduction, onClickEdit, showFormWithSteps }) =>
  deduction.get('type') === 'miscellaneous' ? (
    <MiscDeduction
      deduction={deduction}
      onClickEdit={onClickEdit}
      showFormWithSteps={showFormWithSteps}
    />
  ) : (
    <GarnishmentDeduction
      deduction={deduction}
      onClickEdit={onClickEdit}
      showFormWithSteps={showFormWithSteps}
    />
  );

PostTaxDeduction.propTypes = {
  deduction: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

export default PostTaxDeduction;
