import './LaunchWeekStoryDialog.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Dialog from 'fe-design-base/organisms/Dialog';

import {
  getCurrentLocationLaunchWeekSettings,
  getCurrentUserFirstName,
} from 'selectors/session';

import LaunchWeekStepHighlights from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepHighlights';
import LaunchWeekStepHoldOn from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepHoldOn';
import LaunchWeekStepHoursOfWork from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepHoursOfWork';
import LaunchWeekStepHowYouDoIt from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepHowYouDoIt';
import LaunchWeekStepJohnVideo from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepJohnVideo';
import LaunchWeekStepMadeWorkFun from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepMadeWorkFun';
import LaunchWeekStepNumberOfMessages from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepNumberOfMessages';
import LaunchWeekStepNumberOfShifts from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepNumberOfShifts';
import LaunchWeekStepOamName from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepOamName';
import LaunchWeekStepServeCommunity from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepServeCommunity';
import LaunchWeekStepStoodOut from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepStoodOut';
import LaunchWeekStepTopLocalWorkplace from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepTopLocalWorkplace';
import LaunchWeekStepWorkFlexible from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStepWorkFlexible';
import LaunchWeekStoryPagination from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/LaunchWeekStoryPagination';
import { useGoToNextStep } from 'features/Growth/LaunchWeek/LaunchWeekStoryPagination/useGoToNextStep';

import { cxHelpers } from 'util/className';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import LaunchWeekSharingDialog from './LaunchWeekSharingDialog';

const { cx } = cxHelpers('LaunchWeekStoryDialog');

const CONTENT_STYLE_CENTER = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
};

const RIGHT_ARROW_STYLE = {
  position: 'absolute',
  top: '50%',
  right: '20px',
  color: 'rgb(156, 73, 238)',
  width: '20px',
  height: '20px',
};

const LEFT_ARROW_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '20px',
  color: 'rgb(156, 73, 238)',
  width: '20px',
  height: '20px',
};

const HIGH_MESSAGES_NUMBER_THRESHOLD = 250;

const LaunchWeekStoryDialog = ({
  currentUserFirstName,
  launchWeekSettings,
  onClose,
  open,
}) => {
  const trackLaunchWeekStoryUxEvent = useTrackUx({
    productArea: PRODUCT_AREAS.LAUNCH_WEEK,
    eventCategory: EVENT_CATEGORIES.LAUNCH_WEEK_STORY,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const [isSharingDialogOpen, setIsSharingDialogOpen] = useState(true);

  const handleNextStep = useCallback(() => {
    clearTimeout();
    setCurrentStep(currentStep + 1);
  }, [currentStep]);

  const handlePreviousStep = useCallback(() => {
    clearTimeout();
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  // It automatically goes to the next step of the story
  const handleOnNextStep = useGoToNextStep(
    launchWeekSettings.get('award_steps_delay_ms'),
    handleNextStep
  );

  // Specific handler for the How You Do It step
  const handleOnHowYouDoItStep = useGoToNextStep(
    launchWeekSettings.get('award_how_you_do_it_step_delay_ms'),
    handleNextStep
  );

  // It automatically goes to the Share page after the last step
  const handleOnLastStep = useGoToNextStep(
    launchWeekSettings.get('award_last_step_delay_ms'),
    () => setCurrentStep(currentStep + 1)
  );

  const stepsAndComponents = useMemo(() => {
    const initialStepsComponents = [
      LaunchWeekStepOamName,
      LaunchWeekStepStoodOut,
      LaunchWeekStepHighlights,
      LaunchWeekStepServeCommunity,
      LaunchWeekStepHoursOfWork,
      LaunchWeekStepNumberOfShifts,
    ];

    let stepsComponents = initialStepsComponents;

    // Show message related steps only if the number of messages is high
    if (
      launchWeekSettings.get('number_of_messages') >=
      HIGH_MESSAGES_NUMBER_THRESHOLD
    ) {
      stepsComponents = stepsComponents.concat([
        LaunchWeekStepMadeWorkFun,
        LaunchWeekStepNumberOfMessages,
      ]);
    }

    return stepsComponents
      .concat([
        LaunchWeekStepWorkFlexible,
        LaunchWeekStepHowYouDoIt,
        LaunchWeekStepTopLocalWorkplace,
        LaunchWeekStepHoldOn,
        LaunchWeekStepJohnVideo,
      ])
      .reduce((acc, Component, index) => {
        acc[index] = (
          <Component
            awardStepsDelayMs={launchWeekSettings.get('award_steps_delay_ms')}
            currentUserFirstName={currentUserFirstName}
            handleNextStep={handleNextStep}
            launchWeekSettings={launchWeekSettings}
            onHowYouDoItStep={handleOnHowYouDoItStep}
            onLastStep={handleOnLastStep}
            onNextStep={handleOnNextStep}
            trackUx={trackLaunchWeekStoryUxEvent}
          />
        );
        return acc;
      }, {});
  }, [
    currentUserFirstName,
    handleNextStep,
    handleOnHowYouDoItStep,
    handleOnLastStep,
    handleOnNextStep,
    launchWeekSettings,
    trackLaunchWeekStoryUxEvent,
  ]);

  const numberOfSteps = Object.keys(stepsAndComponents).length;

  const handleClose = useCallback(() => {
    // reset the story to the beginning
    clearTimeout();
    setCurrentStep(0);
    setIsSharingDialogOpen(false);
    onClose();
  }, [onClose]);

  const handleCloseSharingOptionsDialog = useCallback(() => {
    // Make sure to close and reset the story dialog
    // when closing the share dialog
    handleClose();
  }, [handleClose]);

  const isFirstStep = useMemo(() => currentStep === 0, [currentStep]);
  const isStepsFinished = useMemo(
    () => currentStep > numberOfSteps - 1,
    [currentStep, numberOfSteps]
  );

  const handleArrowKeysNavigation = useCallback(
    e => {
      if (e.key === 'ArrowRight') {
        handleNextStep();
      } else if (e.key === 'ArrowLeft' && !isFirstStep) {
        handlePreviousStep();
      }
    },
    [handleNextStep, handlePreviousStep, isFirstStep]
  );

  useEffect(() => {
    setIsSharingDialogOpen(true);
  }, [open]);

  useEffect(() => {
    window.addEventListener('keydown', handleArrowKeysNavigation);
    // Returning a cleanup function to remove the event listener
    // when the component is unmounted
    return () => {
      window.removeEventListener('keydown', handleArrowKeysNavigation);
    };
  }, [handleArrowKeysNavigation]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Dialog
        bgcolor="purple900"
        className={cx()}
        disableBackdropClick
        fullScreen
        noPadding
        onClose={handleClose}
        open={open}
      >
        {() => (
          <Box minh="100%" column spacebetween className={cx()}>
            <Box p={20}>
              <Text variant="heading3" color="mono0">
                homebase
              </Text>
            </Box>
            <Box pl={20} column top={90}>
              <LaunchWeekStoryPagination
                currentStep={currentStep}
                numSteps={numberOfSteps}
                awardStepsDelayMs={launchWeekSettings.get(
                  'award_steps_delay_ms'
                )}
                awardHowYouDoItStepDelayMs={launchWeekSettings.get(
                  'award_how_you_do_it_step_delay_ms'
                )}
                awardLastStepDelayMs={launchWeekSettings.get(
                  'award_last_step_delay_ms'
                )}
              />
              <Box spacebetween tcenter vcenter hcenter>
                <Box>
                  <Icon
                    iconName="ArrowLeft"
                    size="medium"
                    onClick={isFirstStep ? null : handlePreviousStep}
                    color={isFirstStep ? 'purple900' : 'purple400'}
                    style={LEFT_ARROW_STYLE}
                  />
                </Box>
                <Box style={CONTENT_STYLE_CENTER}>
                  {stepsAndComponents[currentStep]}
                </Box>
                {!isStepsFinished && (
                  <Box>
                    <Icon
                      style={RIGHT_ARROW_STYLE}
                      iconName="ArrowRight"
                      onClick={handleNextStep}
                      color="purple400"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Dialog>
      {isStepsFinished && isSharingDialogOpen && (
        <LaunchWeekSharingDialog
          onClose={handleCloseSharingOptionsDialog}
          open
        />
      )}
    </>
  );
};

export default connect(state => ({
  launchWeekSettings: getCurrentLocationLaunchWeekSettings(state),
  currentUserFirstName: getCurrentUserFirstName(state),
}))(LaunchWeekStoryDialog);
