export const EARNING_TYPE_OPTIONS = [
  { value: 'bonus', label: 'Bonus' },
  { value: 'commission', label: 'Commission' },
  { value: 'non_hourly_regular', label: 'Additional Earnings' },
  { value: 'group_term_life', label: 'Group Term Life' },
  { value: 'other_imputed', label: 'Other Imputed' },
  { value: 'reimbursement', label: 'Reimbursement' },
];
export const CONTRACTOR_EARNING_TYPE_OPTIONS = [
  { value: 'non_hourly_regular', label: 'Additional Earnings' },
  { value: 'reimbursement', label: 'Reimbursement' },
];

export const FREQUENCY_TYPE_OPTIONS = [
  { value: 'every_payroll_period', label: 'Every pay period' },
  { value: 'monthly', label: 'First pay period each month' },
];

export const EARNING_TYPE_MAP = {
  bonus: {
    name: 'Bonus',
  },
  commission: {
    name: 'Commission',
  },
  non_hourly_regular: {
    name: 'Additional Earnings',
  },
  group_term_life: {
    name: 'Group Term Life',
  },
  other_imputed: {
    name: 'Other Imputed',
  },
  reimbursement: {
    name: 'Reimbursement',
  },
};

export const FREQUENCY_TYPE_MAP = {
  every_payroll_period: {
    name: 'Every pay period',
  },
  monthly: {
    name: 'First pay period each month',
  },
};

export const INITIAL_VALUES = {
  frequency_type: 'every_payroll_period',
};
