import * as routes from 'api';

import { hideModal } from 'actions/modals';

import {
  createAsyncDeleteAction,
  createAsyncGetAction,
  withAlerts,
} from 'util/redux';

import { unpassApplication } from './index';

export const actionTypes = {
  FETCH_DATA_REQUEST: 'HIRING/INTERVIEWS/FETCH_DATA_REQUEST',
  FETCH_DATA_SUCCESS: 'HIRING/INTERVIEWS/FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'HIRING/INTERVIEWS/FETCH_DATA_FAILURE',
  CANCEL_INTERVIEW_REQUEST: 'CANCEL_INTERVIEW_REQUEST',
  CANCEL_INTERVIEW_SUCCESS: 'CANCEL_INTERVIEW_SUCCESS',
  CANCEL_INTERVIEW_FAILURE: 'CANCEL_INTERVIEW_FAILURE',
};

export const fetchData = () =>
  withAlerts(
    createAsyncGetAction(
      routes.hiringInterviewsRoute({ for_company: true, include_past: true }),
      [
        actionTypes.FETCH_DATA_REQUEST,
        actionTypes.FETCH_DATA_SUCCESS,
        actionTypes.FETCH_DATA_FAILURE,
      ]
    )
  );

export const deleteInterview = (id, applicationId) => dispatch =>
  dispatch(
    withAlerts(
      createAsyncDeleteAction(routes.hiringInterviewAvailabilitiesRoute(id), [
        { type: actionTypes.CANCEL_INTERVIEW_REQUEST },
        { type: actionTypes.CANCEL_INTERVIEW_SUCCESS, meta: { id } },
        { type: actionTypes.CANCEL_INTERVIEW_FAILURE },
      ]),
      {
        onSuccess: () => {
          dispatch(hideModal());
          dispatch(unpassApplication(applicationId, 'applied'));
        },
      }
    )
  );
