import { createAsyncThunk } from '@reduxjs/toolkit';

import { postCreateFreeTrial } from './api';
import { SLICE_NAME } from './constants';

export const createFreeTrial = createAsyncThunk(
  `${SLICE_NAME}/createFreeTrial`,
  (trialTierId, { rejectWithValue }) => {
    postCreateFreeTrial({ trial_tier_id: trialTierId })
      .then(() => {
        window.location.reload();
      })
      .catch(error => rejectWithValue(error.body || error.message));
  }
);
