import React from 'react';
import Icon from 'react-icon-base';

const ScrollLeft = ({ ...props }) => (
  <Icon {...props}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="rotate(-180 16 16)"
        fill="#626970"
      />
      <path
        d="M17.3431 20.4497C17.3431 20.8952 16.8046 21.1183 16.4896 20.8033L12.0398 16.3536C11.8446 16.1583 11.8446 15.8417 12.0398 15.6464L16.4896 11.1967C16.8046 10.8817 17.3431 11.1048 17.3431 11.5503L17.3431 20.4497Z"
        fill="white"
      />
    </svg>
  </Icon>
);

export default ScrollLeft;
