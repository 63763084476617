import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgPlay = ({
  alt = 'Play Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M6.422 1.828a2.243 2.243 0 0 0-2.274-.042A2.254 2.254 0 0 0 3 3.75v16.5c0 .815.44 1.565 1.148 1.964.708.398 1.58.38 2.274-.042l13.5-8.25a2.251 2.251 0 0 0 0-3.844l-13.5-8.25Z"
    />
  </svg>
);
export default SvgPlay;
