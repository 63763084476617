import { showErrorModal } from 'actions/modals';

export const checkForServerErrors = (action, errorMessage) => dispatch =>
  dispatch(action).then(response => {
    if (response.error) {
      const errors =
        errorMessage ||
        response.payload.response.errors ||
        'An unknown error occurred.';
      dispatch(showErrorModal(errors));
    }

    return response;
  });
