import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMobile = ({
  alt = 'Mobile Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M18 0H6a2.25 2.25 0 0 0-2.25 2.25v19.5A2.25 2.25 0 0 0 6 24h12a2.25 2.25 0 0 0 2.25-2.25V2.25A2.25 2.25 0 0 0 18 0Zm-3.75 20.958c0 .455-.338.792-.75.792h-3.042c-.37 0-.708-.337-.708-.75s.338-.75.75-.75h3c.412 0 .75.337.75.708Zm3-2.958H6.75V3h10.5v15Z"
    />
  </svg>
);
export default SvgMobile;
