import React, { PureComponent } from 'react';

import {
  RANGE_TYPES,
  VIEW_TYPES,
} from 'features/scheduleBuilder/ScheduleBuilderView/constants';
import { pageUrl } from 'features/scheduleBuilder/ScheduleBuilderView/utils/urlParamsToReduxProps';

import { df, formatDate, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { browserHistory } from 'util/router';
import { setOriginContext } from 'util/tracking';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import RequestsListItem from '../RequestsListItem';

export default class OpenShift extends PureComponent {
  navigateToOpenShift = (date, openShiftId) => {
    if (this.props.isReactSbWeekViewEnabled) {
      const dateFormatted = formatDate(date, 'parsable_reversed');
      const urlParams = {
        viewType: VIEW_TYPES.employee,
        rangeType: RANGE_TYPES.week,
        rangeStart: dateFormatted,
      };
      browserHistory.replace(
        `${pageUrl(urlParams)}?openShiftId=${openShiftId}`
      );
    } else {
      window.Homebase.ScheduleBuilder.openApproveClaimModal(openShiftId, date);
    }
  };

  handleApprove = () => {
    const date = moment(this.props.item.get('date'), df('full'));
    const openShiftId = this.props.item.get('id');

    this.props.closeDrawer();
    setOriginContext({ starting_path: TRACK_STARTING_PATHS.ALERTS });

    this.navigateToOpenShift(date, openShiftId);
  };

  render() {
    return (
      <RequestsListItem
        body={toI18n('notifications.requests.open_shift.desc', {
          props: { date: this.props.item.get('date') },
        })}
        onApprove={this.handleApprove}
        acceptButtonCopy={toI18n('notifications.requests.open_shift.approve')}
        showDeclineButton={false}
        {...this.props}
      />
    );
  }
}
