import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('PaymentMethodModuleNoPermissionView');

export const PaymentMethodModuleNoPermissionView = () => (
  <Box p={12} className={cx()} gap={32} column alignItemsCenter>
    <Text
      variant="heading1"
      textAlign="center"
      i18n="biller.payment_method_module.no_permission.title"
    />
    <Text
      variant="body"
      i18n="biller.payment_method_module.no_permission.subtitle"
      textAlign="center"
      color="mono700"
    />
  </Box>
);
