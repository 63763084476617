import { partial } from 'lodash';

export const show = (type, text, options) =>
  window.Homebase.FlashNotice.show(type, text, options);

export const success = partial(show, 'notice');
export const error = partial(show, 'error');
export const info = partial(show, 'info');
export const warning = partial(show, 'warning');

export const hide = () => window.Homebase.FlashNotice.hide();
