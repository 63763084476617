import { showModal } from 'actions/modals';

import { BILLER_Q2_CHANGE_TIER_MODAL } from 'features/modals/constants';

const showBillerQ2ChangeModal = () =>
  showModal(BILLER_Q2_CHANGE_TIER_MODAL, { deprecatedModal: true });

export const changeTier = showBillerQ2ChangeModal;

export const downgradeTier = showBillerQ2ChangeModal;
