import { combineReducers } from 'redux-immutable';

import { actionTypes as applicantMessengerActionTypes } from 'actions/applicantMessenger';

import {
  APPLICATION_MESSENGER_VIEWS,
  INITIAL_APPLICATION_MESSENGER_ROUTER_STATE,
} from 'features/messengerv2/constants';

const applicationMessageRouter = (
  state = INITIAL_APPLICATION_MESSENGER_ROUTER_STATE,
  action
) => {
  switch (action.type) {
    case applicantMessengerActionTypes.SHOW_APPLICANT_MESSENGER: {
      const viewKey = action.payload.applicationId ? 'SHOW' : 'INDEX';
      const view = APPLICATION_MESSENGER_VIEWS[viewKey];
      return state.merge({ view });
    }

    default:
      return state;
  }
};

export default combineReducers({
  applicationMessageRouter,
});
