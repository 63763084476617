import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postJSON, putJSON } from 'api/fetch';
import { fromJS } from 'immutable';

import { getCurrentLocation } from 'selectors/session';

import {
  clockInBreakPath,
  clockInToShiftPath,
  clockInToUnscheduledShiftPath,
  clockOutFromBreakPath,
  clockOutFromShiftPath,
  EMPLOYEE_PIN_PATH,
  healthAnswersPath,
} from 'features/hubTimeClock/api';

const SLICE_NAME = 'hubTimeClock';

// Selector
export const getCurrentPin = state => state.getIn([SLICE_NAME, 'currentPin']);
export const getJobId = state =>
  state.getIn([SLICE_NAME, 'employee', 'job', 'id']);

export const getShiftId = state =>
  state.getIn([SLICE_NAME, 'employee', 'shifts', 0, 'id']);
export const getLocationName = state =>
  state.getIn([SLICE_NAME, 'location_name']) ||
  getCurrentLocation(state).get('name');
export const getPinLength = state =>
  state.getIn([SLICE_NAME, 'pin_length']) ||
  getCurrentLocation(state).get('pin_length');

// allow integrations to override the client to enable integration
// specific authorization headers
export const defaultClient = {
  post: postJSON,
  put: putJSON,
};

let client = defaultClient;
// Allow client to be overridden.
export const setClient = newClient => (client = newClient);

export const postEmployeePin = createAsyncThunk(
  'hubTimeClock/postEmployeePin',
  pin => client.post(EMPLOYEE_PIN_PATH, { pin })
);

export const putEmployeeShift = createAsyncThunk(
  'hubTimeClock/putEmployeeShift',
  (id, { getState }) =>
    client.put(clockInToShiftPath(id), {
      pin: getCurrentPin(getState()),
    })
);

export const postUnscheduledShift = createAsyncThunk(
  'hubTimeClock/postUnscheduledShift',
  (roleId, { getState }) =>
    client.post(clockInToUnscheduledShiftPath(getJobId(getState())), {
      pin: getCurrentPin(getState()),
      role_id: roleId,
    })
);

export const putEmployeeClockInBreak = createAsyncThunk(
  'hubTimeClock/putEmployeeClockInBreak',
  (id, { getState }) =>
    client.put(clockInBreakPath(getShiftId(getState())), {
      pin: getCurrentPin(getState()),
      mb_id: id,
    })
);

export const putEmployeeClockOut = createAsyncThunk(
  'hubTimeClock/putEmployeeClockOut',
  (_, { getState }) =>
    client.put(clockOutFromShiftPath(getShiftId(getState())), {
      pin: getCurrentPin(getState()),
    })
);

export const putEmployeeClockOutBreak = createAsyncThunk(
  'hubTimeClock/putEmployeeClockOutBreak',
  (_, { getState }) =>
    client.put(clockOutFromBreakPath(getShiftId(getState())), {
      pin: getCurrentPin(getState()),
    })
);

export const postHealthAnswers = createAsyncThunk(
  'hubTimeClock/postHealthAnswers',
  (payload, { getState }) =>
    client.post(healthAnswersPath(payload.shiftId), {
      pin: getCurrentPin(getState()),
      answers: payload.answers,
    })
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState: fromJS({
    currentPin: null,
    employee: null,
    shift: null,
    timecard: null,
    mandatedBreak: null,
    requestError: false,
    onBreak: false,
    breakEnded: false,
    clockedOut: false,
    timebreak: null,
    healthQuestionsAnswered: false,
    healthQuestionsResponse: null,
    client: defaultClient,
  }),
  reducers: {
    clearEmployee: state => state.set('employee', null),
    clearShift: state => state.set('shift', null),
    clearTimecard: state => state.set('timecard', null),
    clearBreak: state => state.set('mandatedBreak', null),
    clearOnBreak: state => state.set('onBreak', false),
    clearClockedOut: state => state.set('clockedOut', false),
    clearBreakEnded: state => state.set('breakEnded', false),
    clearTimebreak: state => state.set('timebreak', null),
    clearHealthQuestionsAnswered: state =>
      state.set('healthQuestionsAnswered', false),
    setLocationInfo: (state, action) => state.merge(action.payload),
  },
  extraReducers: {
    [postEmployeePin.fulfilled]: (state, action) =>
      state.set('employee', fromJS(action.payload)),
    [postEmployeePin.pending]: (state, action) =>
      state.set('requestError', null).set('currentPin', action.meta.arg),
    [postEmployeePin.rejected]: state => state.set('requestError', true),
    [putEmployeeShift.fulfilled]: (state, action) =>
      state.set('shift', fromJS(action.payload)),
    [putEmployeeShift.pending]: state => state.set('requestError', null),
    [putEmployeeShift.rejected]: state => state.set('requestError', true),
    [postUnscheduledShift.fulfilled]: (state, action) =>
      state.set('shift', fromJS(action.payload)),
    [postUnscheduledShift.pending]: state => state.set('requestError', null),
    [postUnscheduledShift.rejected]: state => state.set('requestError', true),
    [putEmployeeClockInBreak.fulfilled]: (state, action) =>
      state.set('mandatedBreak', fromJS(action.payload)).set('onBreak', true),
    [putEmployeeClockInBreak.pending]: state => state.set('requestError', null),
    [putEmployeeClockInBreak.rejected]: state =>
      state.set('requestError', true),
    [putEmployeeClockOut.fulfilled]: (state, action) =>
      state.set('timecard', fromJS(action.payload)).set('clockedOut', true),
    [putEmployeeClockOut.pending]: state => state.set('requestError', null),
    [putEmployeeClockOut.rejected]: state => state.set('requestError', true),
    [putEmployeeClockOutBreak.fulfilled]: (state, action) =>
      state.set('breakEnded', true).set('timebreak', fromJS(action.payload)),
    [putEmployeeClockOutBreak.pending]: state =>
      state.set('requestError', null),
    [putEmployeeClockOutBreak.rejected]: state =>
      state.set('requestError', true),
    [postHealthAnswers.fulfilled]: (state, action) =>
      state
        .set('healthQuestionsAnswered', true)
        .set('healthQuestionsResponse', fromJS(action.payload)),
    [postHealthAnswers.pending]: state => state.set('requestError', null),
    [postHealthAnswers.rejected]: state => state.set('requestError', true),
  },
});

export const reducer = slice.reducer;

// Export Actions
export const clearEmployee = slice.actions.clearEmployee;
export const clearShift = slice.actions.clearShift;
export const clearTimecard = slice.actions.clearTimecard;
export const clearMandatedBreak = slice.actions.clearBreak;
export const clearOnBreak = slice.actions.clearOnBreak;
export const clearClockedOut = slice.actions.clearClockedOut;
export const clearBreakEnded = slice.actions.clearBreakEnded;
export const clearTimebreak = slice.actions.clearTimebreak;
export const clearHealthQuestionsAnswered =
  slice.actions.clearHealthQuestionsAnswered;
export const setLocationInfo = slice.actions.setLocationInfo;

// Selectors
export const getEmployee = state => state.getIn([SLICE_NAME, 'employee']);
export const getShift = state => state.getIn([SLICE_NAME, 'shift']);
export const getOnBreak = state => state.getIn([SLICE_NAME, 'onBreak']);
export const getTimecard = state => state.getIn([SLICE_NAME, 'timecard']);
export const getMandatedBreak = state =>
  state.getIn([SLICE_NAME, 'mandatedBreak']);
export const getClockedOut = state => state.getIn([SLICE_NAME, 'clockedOut']);
export const getBreakEnded = state => state.getIn([SLICE_NAME, 'breakEnded']);
export const getTimebreak = state => state.getIn([SLICE_NAME, 'timebreak']);
export const getRequestError = state =>
  state.getIn([SLICE_NAME, 'requestError']);
export const getHealthQuestionsResponse = state =>
  state.getIn([SLICE_NAME, 'healthQuestionsResponse']);
export const getHealthQuestionsAnswered = state =>
  state.getIn([SLICE_NAME, 'healthQuestionsAnswered']);
