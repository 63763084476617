import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import * as sessionSelectors from 'selectors/session';

import Text from 'components/Text';

import cxHelpers from 'util/className';

import NavItem from '../NavItem';

@connect((state, props) => ({
  signInUrl: sessionSelectors.getSignInUrl(state, props),
}))
@cxHelpers('SignInItem')
export default class SignInItem extends PureComponent {
  static propTypes = {
    signInUrl: PropTypes.string.isRequired,
  };

  render() {
    return (
      <NavItem
        className={this.cx({}, 'js-sign-in')}
        href={this.props.signInUrl}
      >
        <Text i18n="nav_links.devise.sign_in" />
      </NavItem>
    );
  }
}
