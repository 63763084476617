import { fromJS } from 'immutable';

import { actionTypes as navActionTypes } from 'actions/nav';
import { actionTypes as retrialActionTypes } from 'actions/retrial';
import { actionTypes as sessionActionTypes } from 'actions/session';

import * as flashNotice from 'util/flashNotice';

const INITIAL_STATE = fromJS({
  mobileSubNavOpen: false,
  mobileNavOpen: false,
  employeeBreakWaiverTooltipOpen: false,
  leftNavCollapsed:
    window.sessionStorage.getItem('LEFT_NAV_COLLAPSED') === 'true',
  isLaunchWeekAwardMinimized: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case navActionTypes.TOGGLE_LEFT_NAV:
      return state.merge({ leftNavCollapsed: action.payload.collapsed });
    case navActionTypes.MINIMIZE_LAUNCH_WEEK_AWARD:
      return state.merge({ isLaunchWeekAwardMinimized: true });
    case sessionActionTypes.UPDATE_SESSION:
      return state.merge({
        ...action.payload.nav,
      });

    case navActionTypes.TOGGLE_MOBILE_SUB_NAV:
      return state.merge({ mobileSubNavOpen: action.payload.open });

    case navActionTypes.TOGGLE_MOBILE_NAV:
      return state.merge({ mobileNavOpen: action.payload.open });

    case navActionTypes.UPDATE_CURRENT_USER_SUCCESS: {
      window.location.reload();
      return state;
    }

    case navActionTypes.UPDATE_CURRENT_USER_FAILURE: {
      flashNotice.show(
        'error',
        'Sorry, something went wrong. Please try again later.'
      );
      return state;
    }

    case retrialActionTypes.ACTIVATE_RETRIAL: {
      return state.mergeDeepIn(
        ['items', 'activeTrial'],
        fromJS(action.payload)
      );
    }

    case navActionTypes.UPDATE_NAV_LOCATIONS: {
      return state.setIn(
        ['items', 'locationsMenu', 'locations'],
        fromJS(action.payload)
      );
    }

    case navActionTypes.TOGGLE_EMPLOYEE_BREAK_WAIVER_TOOLTIP: {
      return state.merge({
        employeeBreakWaiverTooltipOpen: action.payload.open,
      });
    }

    default:
      return state;
  }
};
