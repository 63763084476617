import { Overrides } from '../../types';
import baseColors from '../colors';

export const switchOverrides: Overrides = {
  MuiSwitch: {
    styleOverrides: {
      root: {
        '&.FDBSwitch': {
          padding: 0,
          width: '44px',
          height: '24px',
          overflow: 'visible',

          '& .MuiSwitch-track': {
            borderRadius: '16px',
          },

          '& .MuiSwitch-thumb': {
            width: '18px',
            height: '18px',
            boxShadow: 'none',
          },

          '& .Mui-Switch-input': {
            position: 'absolute',
          },

          '&.FDBSwitch--pressed.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked.MuiButtonBase-root + .MuiSwitch-track':
            {
              backgroundColor: baseColors.purple900,
              boxShadow: `0px 0px 0px 4px ${baseColors.purple300}`,
            },
          '&.FDBSwitch--pressed.MuiSwitch-root .MuiSwitch-switchBase.MuiButtonBase-root + .MuiSwitch-track':
            {
              backgroundColor: baseColors.mono900,
              boxShadow: `0px 0px 0px 4px ${baseColors.purple300}`,
            },
        },

        '&.FDBSwitch .MuiSwitch-switchBase': {
          padding: 0,
          margin: '3px',
          transitionDuration: '300ms',
          display: 'inline-flex',
          position: 'absolute',
          backgroundColor: 'transparent',
          color: baseColors.mono0,
          borderRadius: '50%',

          '& + .MuiSwitch-track': {
            backgroundColor: baseColors.mono700,
          },

          '&:hover:not([aria-disabled="true"]) + .MuiSwitch-track': {
            backgroundColor: baseColors.mono900,
            boxShadow: `0px 0px 0px 4px ${baseColors.purple100}`,
          },

          '&.Mui-checked': {
            transform: 'translateX(20px)',
            color: '#fff',

            '& + .MuiSwitch-track': {
              opacity: 1,
              border: 0,
              backgroundColor: baseColors.purple500,
            },

            '&:hover + .MuiSwitch-track': {
              backgroundColor: baseColors.purple700,
              boxShadow: `0px 0px 0px 4px ${baseColors.purple100}`,
            },

            '&.Mui-focusVisible + .MuiSwitch-track': {
              outline: `solid 2px ${baseColors.accent500}`,
              backgroundColor: baseColors.purple700,
              outlineOffset: '2px',
            },
          },

          '&.Mui-focusVisible + .MuiSwitch-track': {
            outline: `solid 2px ${baseColors.accent500}`,
            backgroundColor: baseColors.mono900,
            outlineOffset: '2px',
          },

          '&[aria-disabled="true"]': {
            pointerEvents: 'none',

            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: baseColors.mono500,
            },

            '& .MuiSwitch-thumb': {
              backgroundColor: baseColors.mono300,
            },

            '& input': {
              cursor: 'not-allowed',
            },
          },
        },
      },

      track: ({ theme }) => ({
        backgroundColor: baseColors.mono500,
        borderRadius: '16px',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 300,
        }),
      }),

      input: {
        position: 'absolute',
        '&:focus': {
          display: 'block',
        },
      },
    },
  },
};
