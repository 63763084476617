import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';

import { error as flashError } from 'util/flashNotice';

import { FEIN_VERIFICATION_STATUS } from './Steps/CompanyDetails/constants';
import { setCheckCompanyId } from './actions';

export const updateFein = createAsyncThunk(
  'linearFlow/updateCheckCompany',
  ({ fein }) =>
    putJSON('/payroll/check/fein_verifications/update_fein', {
      fein,
    }).catch(error =>
      error.response.json().then(body => {
        const errorMsg = body[0]?.error;
        if (errorMsg) {
          flashError(errorMsg, {
            autoHideTimeout: 15_000,
          });
        }
      })
    )
);

export const createCheckCompany = createAsyncThunk(
  'linearFlow/createCheckCompany',
  params => postJSON(`/payroll/check/company`, params)
);

export const updateCheckCompany = createAsyncThunk(
  'linearFlow/updateCheckCompany',
  params => putJSON(`/payroll/check/company`, params)
);

const fetchVerificationWhileProcessing = async (
  feinVerificationId,
  startTime,
  previousResponse
) => {
  // Check if 20 seconds has passed
  const isProcessingTimeExpired = (performance.now() - startTime) / 1000 >= 20;

  if (isProcessingTimeExpired) {
    return { ...previousResponse, status: FEIN_VERIFICATION_STATUS.expired };
  }

  const response = await fetchJSON(
    `/payroll/check/fein_verifications/${feinVerificationId}`
  );

  if (response.status === FEIN_VERIFICATION_STATUS.processing) {
    // Wait 3 seconds
    await new Promise(resolve => setTimeout(resolve, 3000));

    return fetchVerificationWhileProcessing(
      feinVerificationId,
      startTime,
      response
    );
  }

  return response;
};

const startFeinVerificationPolling = createAsyncThunk(
  'linearFlow/startFeinVerificationPolling',
  feinVerificationId =>
    fetchVerificationWhileProcessing(feinVerificationId, performance.now())
);

export const verifyFein = createAsyncThunk(
  'linearFlow/verifyFein',
  async (_, { dispatch, getState }) => {
    const { businessLegalName, fein, checkCompanyId } =
      getState().get('payroll').linearFlow;

    if (businessLegalName) {
      const createOrUpdateCheckCompany = checkCompanyId
        ? updateCheckCompany
        : createCheckCompany;

      const checkCompanyResponse = await dispatch(
        createOrUpdateCheckCompany({ legal_name: businessLegalName })
      );

      dispatch(setCheckCompanyId(checkCompanyResponse.payload.id));
    }

    if (fein) {
      await dispatch(updateFein({ fein }));
    }

    if (fein && businessLegalName) {
      const response = await fetchJSON(`/payroll/check/fein_verifications`);
      const verification = response.results[0];

      if (verification.status === FEIN_VERIFICATION_STATUS.processing) {
        const verificationPollingResponse = await dispatch(
          startFeinVerificationPolling(verification.id)
        );

        return verificationPollingResponse.payload;
      }

      return verification;
    }

    return {};
  }
);
