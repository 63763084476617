import Icon from 'react-icon-base';

const InstantPayOutlined = props => (
  <Icon
    viewBox="0 0 25 21"
    fill="transparent"
    style={{ color: props.color || '#605F56' }}
    {...props}
  >
    <path
      d="M1.875 7.54602C1.875 8.0929 1.44531 8.48352 0.9375 8.48352C0.390625 8.48352 0 8.0929 0 7.54602V4.42102C0 2.54602 1.52344 0.983521 3.4375 0.983521H21.5625C23.4375 0.983521 25 2.54602 25 4.42102V7.54602C25 8.0929 24.5703 8.48352 24.0625 8.48352C23.5156 8.48352 23.125 8.0929 23.125 7.54602V4.42102C23.125 3.56165 22.4219 2.85852 21.5625 2.85852H3.4375C2.53906 2.85852 1.875 3.56165 1.875 4.42102V7.54602ZM18.125 4.73352H20V18.4835C20 19.8898 18.8672 20.9835 17.5 20.9835H7.5C6.09375 20.9835 5 19.8898 5 18.4835V4.73352H6.875V16.6085C8.24219 16.6085 9.375 17.7413 9.375 19.1085H15.625C15.625 17.7413 16.7188 16.6085 18.125 16.6085V4.73352ZM12.5 14.7335C10.3906 14.7335 8.75 13.3663 8.75 11.6085C8.75 9.88977 10.3906 8.48352 12.5 8.48352C14.5703 8.48352 16.25 9.88977 16.25 11.6085C16.25 13.3663 14.5703 14.7335 12.5 14.7335Z"
      fill="currentColor"
    />
  </Icon>
);

export default InstantPayOutlined;
