import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgAddWithCircle = ({
  alt = 'Add With Circle Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M0 12C0 5.372 5.372 0 12 0s12 5.372 12 12-5.372 12-12 12S0 18.628 0 12Zm12 5.25c.623 0 1.125-.502 1.125-1.125v-3h3c.623 0 1.125-.502 1.125-1.125s-.502-1.125-1.125-1.125h-3v-3c0-.623-.502-1.125-1.125-1.125s-1.125.502-1.125 1.125v3h-3c-.623 0-1.125.502-1.125 1.125s.502 1.125 1.125 1.125h3v3c0 .623.502 1.125 1.125 1.125Z"
    />
  </svg>
);
export default SvgAddWithCircle;
