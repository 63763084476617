import * as routes from 'api';
import { pollWorkerStatus } from 'api/workerStatus';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { createAsyncGetAction, createAsyncPostAction } from 'util/redux';

export const actionTypes = {
  FETCH_ROLES_FOR_IMPORT_REQUEST: 'ROLES/FETCH_ROLES_FOR_IMPORT_REQUEST',
  FETCH_ROLES_FOR_IMPORT_SUCCESS: 'ROLES/FETCH_ROLES_FOR_IMPORT_SUCCESS',
  FETCH_ROLES_FOR_IMPORT_FAILURE: 'ROLES/FETCH_ROLES_FOR_IMPORT_FAILURE',
  INITIATE_IMPORT_ROLES_REQUEST: 'ROLES/INITIATE_IMPORT_ROLES_REQUEST',
  INITIATE_IMPORT_ROLES_SUCCESS: 'ROLES/INITIATE_IMPORT_ROLES_SUCCESS',
  INITIATE_IMPORT_ROLES_FAILURE: 'ROLES/INITIATE_IMPORT_ROLES_FAILURE',
  IMPORT_ROLES_FINISHED: 'ROLES/IMPORT_ROLES_FINISHED',
  UPDATE_MATCHED_ROLE: 'ROLES/UPDATE_MATCHED_ROLE',
};

export const fetchRolesForImport = () =>
  createAsyncGetAction(routes.importedRolesRoute(), [
    actionTypes.FETCH_ROLES_FOR_IMPORT_REQUEST,
    actionTypes.FETCH_ROLES_FOR_IMPORT_SUCCESS,
    actionTypes.FETCH_ROLES_FOR_IMPORT_FAILURE,
  ]);

const initializeRoleImport = matches =>
  createAsyncPostAction(
    routes.importedRolesRoute(),
    [
      actionTypes.INITIATE_IMPORT_ROLES_REQUEST,
      actionTypes.INITIATE_IMPORT_ROLES_SUCCESS,
      actionTypes.INITIATE_IMPORT_ROLES_FAILURE,
    ],
    { body: { matches } }
  );

const importRolesFinished = () => ({
  type: actionTypes.IMPORT_ROLES_FINISHED,
});

export const updateMatchedRole = (id, role) => ({
  type: actionTypes.UPDATE_MATCHED_ROLE,
  payload: { id, role },
});

export const importRoles = matches => async dispatch => {
  const response = await dispatch(initializeRoleImport(matches));

  if (response.type === actionTypes.INITIATE_IMPORT_ROLES_SUCCESS) {
    let flashMessage;
    let flashType;

    try {
      await pollWorkerStatus(response.payload.job_id);
      flashMessage = toI18n('match_roles.success');
      flashType = 'notice';
    } catch (e) {
      flashMessage = e.response.failure_count
        ? toI18n('match_roles.import_error', {
            props: { count: e.response.failure_count },
          })
        : toI18n('match_roles.general_error');
      flashType = 'error';
    } finally {
      dispatch(importRolesFinished());
      if (window.location.href.includes('ref=department')) {
        window.location = '/team/import?type=pos&ref=department';
      } else {
        window.location = '/team/import?type=pos';
      }

      flashNotice.show(flashType, flashMessage);
    }
  }
};
