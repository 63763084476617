export const DRAWER_COMPONENTS_NAME = {
  EXAMPLE_DRAWER: 'EXAMPLE_DRAWER',
  BULK_ADD_EMPLOYEE: 'BULK_ADD_EMPLOYEE',
  SCHEDULER_CARD: 'SCHEDULER_CARD',
  TIME_PAY_CARD: 'TIME_PAY_CARD',
  PAY_YOUR_TEAM_CARD: 'PAY_YOUR_TEAM_CARD',
  TIME_CLOCK_DRAWER: 'TIME_CLOCK_DRAWER',
};

export const SESSION_STORAGE_KEYS = {
  TIMESHEETS_GUIDED_SETUP_MAYBE_LATER: 'timesheets_guided_setup_maybe_later',
};

export const INFLUENCER_TRIAL_PERIODS = [
  'alex',
  'evanice',
  'senoreata',
  'green_industry_guy',
  'drew_talbert',
];
