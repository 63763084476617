import React from 'react';
import Icon from 'react-icon-base';

const CloseCircle = props => (
  <Icon viewBox="0 0 17 18" {...props}>
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5142 9.16266C16.5142 13.9825 12.895 17.8253 8.50711 17.8253C4.11921 17.8253 0.5 13.9825 0.5 9.16266C0.5 4.3428 4.11921 0.5 8.50711 0.5C12.895 0.5 16.5142 4.3428 16.5142 9.16266Z"
        stroke="#CBCDCF"
      />
      <path
        d="M5.67139 6.1084L11.3428 12.2168"
        stroke="#969BA0"
        strokeWidth="2"
      />
      <path
        d="M11.343 6.1084L5.67157 12.2168"
        stroke="#969BA0"
        strokeWidth="2"
      />
    </svg>
  </Icon>
);

export default CloseCircle;
