import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMoreVertical = ({
  alt = 'More Vertical Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M11.975 17a2.627 2.627 0 0 1 2.625 2.625 2.627 2.627 0 0 1-2.625 2.625 2.627 2.627 0 0 1-2.625-2.625A2.627 2.627 0 0 1 11.975 17Zm0-7.5a2.627 2.627 0 0 1 2.625 2.625 2.627 2.627 0 0 1-2.625 2.625 2.627 2.627 0 0 1-2.625-2.625A2.627 2.627 0 0 1 11.975 9.5ZM14.6 4.625a2.627 2.627 0 0 1-2.625 2.625A2.627 2.627 0 0 1 9.35 4.625 2.627 2.627 0 0 1 11.975 2 2.627 2.627 0 0 1 14.6 4.625Z"
    />
  </svg>
);
export default SvgMoreVertical;
