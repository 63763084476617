import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import {
  APPROVED_ITEM_HEIGHT,
  APPROVED_PADDING,
} from 'features/timeOff/constants';

import { SIMPLE_TABLE_HEADER_HEIGHT } from 'components/tables/Table';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import * as entitiesSelectors from './entities';
import * as sessionSelectors from './session';

export const getPaidTimeOffsList = (state, props) => {
  const currentLocationId = sessionSelectors
    .getCurrentLocationId(state, props)
    .toString();
  return state
    .getIn(['entities', 'locations', currentLocationId, 'paid_time_off'])
    .toArray();
};

export const getJobs = entitiesSelectors.createEntitiesForViewSelector(
  'jobs',
  'timeOff'
);
export const getUsers = entitiesSelectors.createEntitiesForViewSelector(
  'users',
  'timeOff'
);
export const getTimeOffs = entitiesSelectors.createEntitiesForViewSelector(
  'timeOffs',
  'timeOff'
);
export const getPTOPolicies = entitiesSelectors.createEntitiesForViewSelector(
  'ptoPolicies',
  'timeOff'
);
export const getPTOPoliciesLoaded = entitiesSelectors.createLoadedSelector(
  'ptoPolicies',
  'TIME_OFF'
);

export const getJobFilterOptions = createSelector(
  getJobs,
  getUsers,
  (jobs, users) => {
    const INITIAL_JOB_FILTER_OPTIONS = List([
      {
        value: '',
        label: toI18n('schedule_builder.time_off.all_employees'),
        style: { borderBottom: '1px solid #e5e6e7' },
      },
    ]);

    return INITIAL_JOB_FILTER_OPTIONS.concat(
      jobs
        .toList()
        .map(job => {
          const user = users.get(job.get('user').toString());

          return {
            value: job.get('id'),
            label: user.get('name'),
          };
        })
        .sortBy(option => option.label.toLowerCase())
    );
  }
);

export const getIsFetching = (state, { id }) =>
  state.getIn(['timeOff', 'isFetching']).has(id);

export const getIsFetchingTimeOffs = state =>
  getIsFetching(state, {
    id: 'FETCH_TIME_OFFS',
  });

export const getIsFetchingPTOPolicies = state =>
  getIsFetching(state, {
    id: 'FETCH_PTO_POLICIES',
  });

export const getIsFetchingTimeOffSummary = state =>
  getIsFetching(state, {
    id: 'FETCH_TIME_OFF_SUMMARY',
  });

export const getFilteredTimeOffs = createSelector(
  (state, { status, jobId, month }) => ({ status, jobId, month }),
  getTimeOffs,
  getJobs,
  getUsers,
  (filters, timeOffs, jobs, users) => {
    const filteredTimeOffs = timeOffs.filter(timeOff => {
      if (filters.jobId && timeOff.get('job_id').toString() !== filters.jobId) {
        return false;
      }

      const timeOffState = timeOff.get('state').toString();

      if (filters.status && filters.status !== 'all') {
        if (timeOffState !== filters.status) {
          return false;
        } else if (filters.status === 'pending') {
          return true;
        }
      } else if (timeOffState === 'pending') {
        return true;
      }

      const startDateFilter = moment(filters.month, 'MM-YYYY').startOf('month');
      const endDateFilter = moment(filters.month, 'MM-YYYY').endOf('month');

      const startAt = moment(timeOff.get('start_at'), 'full');
      const endAt = moment(timeOff.get('end_at'), 'full');

      return (
        (startAt.isSameOrAfter(startDateFilter) &&
          endAt.isSameOrBefore(endDateFilter)) ||
        (startDateFilter.isAfter(startAt) && startDateFilter.isBefore(endAt)) ||
        (endDateFilter.isAfter(startAt) && endDateFilter.isBefore(endAt))
      );
    });

    // Sorts by last name, then by date
    return filteredTimeOffs
      .toSeq()
      .map(timeOff => {
        const job = jobs.get(timeOff.get('job_id').toString());
        const user = users.get(job.get('user').toString());

        return timeOff.merge({
          name: user.get('name'),
          avatar: user.get('avatar'),
        });
      })
      .sort((a, b) => {
        const aname = a.get('name').toLowerCase();
        const bname = b.get('name').toLowerCase();

        if (aname === bname) {
          const adate = moment(a.get('start_at'));
          const bdate = moment(b.get('start_at'));

          return adate > bdate ? -1 : 1;
        }

        return aname > bname ? 1 : -1;
      })
      .toList();
  }
);

export const getUserIdForTimeOffId = createSelector(
  (_state, { id }) => id,
  getTimeOffs,
  getJobs,
  (id, timeOffs, jobs) =>
    jobs
      .get(timeOffs.get(id.toString(), Map()).get('job_id').toString(), Map())
      .get('user')
);

export const getJobsForAddNewPopup = createSelector(
  getJobs,
  getUsers,
  (jobs, users) =>
    jobs
      .toIndexedSeq()
      .map(job => ({
        id: job.get('id'),
        name: users.get(job.get('user').toString()).get('name'),
        enrolledPtoPolicies: job.get('enrolled_pto_policies').toJS(),
        noPolicyCategoryNames: job.get('no_policy_category_names').toJS(),
      }))
      .toList()
);

export const getBailoutFetchPTOPolicies = createSelector(
  getIsFetchingPTOPolicies,
  getPTOPoliciesLoaded,
  (loading, loaded) => loading || loaded
);

export const getTimeOffSummary = state => state.getIn(['timeOff', 'summary']);

// Fixed height = Baseline padding + "Total" row
const APPROVED_FIXED = APPROVED_PADDING + APPROVED_ITEM_HEIGHT;
const getHeight = categories =>
  Math.max(
    APPROVED_FIXED,
    APPROVED_FIXED + categories.size * APPROVED_ITEM_HEIGHT
  );

const setHeight = userSummary =>
  userSummary.set('height', getHeight(userSummary.get('approved_hours')));

export const getTimeOffSummaryTableData = createSelector(
  getTimeOffSummary,
  summary =>
    summary
      .valueSeq()
      .sortBy(userSummary => userSummary.get('name').split(' ').pop())
      .map(setHeight)
      .toList()
);

const MIN_TABLE_HEIGHT = 500;
const getTableHeight = summary =>
  Math.min(
    summary.reduce(
      (sum, userSummary) => sum + getHeight(userSummary.get('approved_hours')),
      SIMPLE_TABLE_HEADER_HEIGHT
    ),
    MIN_TABLE_HEIGHT
  );

export const getTimeOffSummaryTableHeight = createSelector(
  getTimeOffSummary,
  getTableHeight
);

const getTimeOffSummaryForUser = createSelector(
  getTimeOffSummary,
  (_state, { userId }) => userId,
  (summary, userId) => summary.get(parseInt(userId, 10))
);

const getIsFetchingTimeOffSummaryForUser = (state, { userId }) =>
  getIsFetching(state, { id: `FETCH_TIME_OFF_SUMMARY_FOR_USER_${userId}` });

export const getBailoutFetchTimeOffSummaryForUser = createSelector(
  getIsFetchingTimeOffSummaryForUser,
  getTimeOffSummaryForUser,
  (fetchingSummary, summary) => fetchingSummary || summary
);
