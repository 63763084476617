import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSortDown = ({
  alt = 'Sort Down Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="m21.986 14.907-8.75 8.588c-.34.339-.788.505-1.236.505-.449 0-.896-.168-1.237-.503l-8.75-8.589a1.695 1.695 0 0 1 0-2.429 1.773 1.773 0 0 1 2.474 0l5.763 5.66V1.668c0-.95.784-1.669 1.7-1.669.918 0 1.8.72 1.8 1.67v16.468l5.764-5.658a1.773 1.773 0 0 1 2.475 0c.683.671.68 1.756-.003 2.427Z"
    />
  </svg>
);
export default SvgSortDown;
