import { Overrides } from '../../types';
import baseColors from '../colors';
import { typography } from '../typography';
import { borderRadius } from '../utils';

const chipTypography = typography.captionBold;

export const chipOverrides: Overrides = {
  MuiChip: {
    styleOverrides: {
      root: {
        '&.FDBChip, &.FDBChipClosable, &.FDBPill': {
          maxWidth: '200px',
          boxShadow: 'unset !important',
          ...chipTypography,
          height: '26px',
          overflow: 'hidden',
          width: 'fit-content',

          // Chips
          '&.FDBChip': {
            maxWidth: '200px',
            boxShadow: 'unset !important',
            ...chipTypography,
            width: 'fit-content',
            backgroundColor: baseColors.mono0,
            color: baseColors.purple900,
            border: 'solid 1px',
            borderColor: baseColors.mono400,
            borderRadius: borderRadius.small,
            overflow: 'visible',

            '&:focus-visible': {
              outline: `2px solid ${baseColors.accent500}`,
              outlineOffset: '2px',
              backgroundColor: baseColors.purple100,
            },

            '& .MuiTouchRipple-root': {
              display: 'none',
            },

            '&:active': {
              backgroundColor: baseColors.purple300,
            },

            '&:hover': {
              '&:hover:not(&.FDBChip--selected)': {
                backgroundColor: baseColors.purple100,

                '&:active': {
                  backgroundColor: baseColors.purple300,
                },
              },
            },

            '& .MuiChip-label': {
              paddingRight: '12px',
              paddingLeft: '12px',
            },

            '&--selected': {
              backgroundColor: baseColors.purple900,
              color: baseColors.mono0,
              borderColor: 'transparent',
              '&:hover': {
                backgroundColor: baseColors.purple700,
              },
              '&:focus-visible': {
                outline: `2px solid ${baseColors.accent500}`,
                outlineOffset: '2px',
                backgroundColor: baseColors.purple700,
              },
              '&:active': {
                backgroundColor: baseColors.purple500,
              },
            },
            '&--small': {
              padding: '12px 0px',
            },
            '&--medium': {
              padding: '19px 11px',
              borderRadius: '8px',
            },
          },

          // ChipClosable
          '&.FDBChipClosable': {
            borderRadius: borderRadius.small,
            '& .MuiChip-label': {
              paddingRight: '6px',
              fontWeight: '600',
            },

            '& button': {
              background: 'transparent',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              padding: '0 6px 0 0',
            },
          },
          '& .FDBChipClosable__button': {
            borderRadius: '0',
            border: 'none',
            outline: 'none',
            height: '100%',
            backgroundColor: 'transparent',
          },

          '& .FDBChipClosable__button:disabled': {
            cursor: 'unset',
          },
          '&.FDBChipClosable--disabled': {
            border: `1px solid ${baseColors.mono400}`,

            '&.FDBChipClosable--primary, & .FDBIcon': {
              backgroundColor: baseColors.mono300,
              color: baseColors.mono500,
              cursor: 'unset',

              '& .FDBChipClosable__button:disabled svg': {
                color: baseColors.mono500,
              },
            },
          },

          '&.FDBChipClosable--primary': {
            backgroundColor: baseColors.purple500,
            color: baseColors.mono0,
            '& svg': {
              color: baseColors.mono0,
            },

            '&:hover:not(&.FDBChipClosable--disabled)': {
              backgroundColor: baseColors.purple700,
            },

            // Note: &#active is a workaround for storybook active state
            '&.FDBChipClosable--active:not(&.FDBChipClosable--disabled) , &#active':
              {
                backgroundColor: baseColors.purple900,
              },

            '& .FDBChipClosable__button:disabled': {
              '& svg': {
                color: baseColors.purple700,
              },
            },
          },

          // Pill
          '&.FDBPill': {
            height: '26px',
            borderRadius: '13px',
            '& .MuiChip-label': {
              padding: '0 11px',
            },
            color: baseColors.mono900,
            backgroundColor: baseColors.mono300,

            '& .FDBIcon': {
              color: baseColors.mono900,
              paddingLeft: '7px',
              paddingRight: '3px',
            },
          },

          '&.FDBPill--primary': {
            color: baseColors.purple500,
            backgroundColor: baseColors.purple100,

            '& .FDBIcon': {
              color: baseColors.purple500,
            },
          },

          '&.FDBPill--error': {
            color: baseColors.red300,
            backgroundColor: baseColors.red100,

            '& .FDBIcon': {
              color: baseColors.red300,
            },
          },

          '&.FDBPill--highlight': {
            color: baseColors.purple900,
            backgroundColor: baseColors.highlight500,

            '& .FDBIcon': {
              color: baseColors.purple900,
            },
          },

          '&.FDBPill--warning': {
            color: baseColors.orange500,
            backgroundColor: baseColors.orange100,

            '& .FDBIcon': {
              color: baseColors.orange500,
            },
          },

          '&.FDBPill--success': {
            color: baseColors.green300,
            backgroundColor: baseColors.green100,

            '& .FDBIcon': {
              color: baseColors.green300,
            },
          },

          '&.FDBPill--info': {
            color: baseColors.blue300,
            backgroundColor: baseColors.blue100,

            '& .FDBIcon': {
              color: baseColors.blue300,
            },
          },
        },
      },
      deleteIcon: {
        margin: 0,
      },
    },
  },
};
