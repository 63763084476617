import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgDislike = ({
  alt = 'Dislike Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M14.69 22.458a2.252 2.252 0 0 0 1.768-2.649l-.108-.534A11.174 11.174 0 0 0 15 15.75h6.75a2.25 2.25 0 0 0 .173-4.495 2.25 2.25 0 0 0-1.247-3.713 2.249 2.249 0 0 0-1.228-3.305A2.25 2.25 0 0 0 17.25 1.5h-3.445a4.51 4.51 0 0 0-2.499.755L9.502 3.459A4.5 4.5 0 0 0 7.5 7.205v5.212c0 1.369.623 2.658 1.688 3.516l.346.276a6.734 6.734 0 0 1 2.4 3.947l.108.535a2.252 2.252 0 0 0 2.649 1.767ZM1.5 15h3c.83 0 1.5-.67 1.5-1.5V3c0-.83-.67-1.5-1.5-1.5h-3C.67 1.5 0 2.17 0 3v10.5c0 .83.67 1.5 1.5 1.5Z"
    />
  </svg>
);
export default SvgDislike;
