import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCaretUp = ({
  alt = 'Caret Up Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.695 5.683a2.451 2.451 0 0 0-3.396 0l-9.596 9.33c-.69.67-.892 1.67-.517 2.544A2.402 2.402 0 0 0 2.405 19h19.192a2.41 2.41 0 0 0 2.219-1.443 2.288 2.288 0 0 0-.517-2.544l-9.596-9.33h-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCaretUp;
