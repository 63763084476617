import React from 'react';
import Icon from 'react-icon-base';
import PropTypes from 'prop-types';

const FindCover = ({ color, props }) => (
  <Icon height="1.5em" width="1.5em" viewBox="2 2 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8433 4.08333H2.41667C2.18667 4.08333 2 3.89667 2 3.66667C2 3.43667 2.18667 3.25 2.41667 3.25H16.8433C17.0733 3.25 17.26 3.43667 17.26 3.66667C17.26 3.89667 17.0733 4.08333 16.8433 4.08333ZM14.9885 19.9167C11.5818 19.9167 8.81013 17.1134 8.81013 13.6667C8.81013 10.22 11.5818 7.41669 14.9885 7.41669C18.3951 7.41669 21.1668 10.22 21.1668 13.6667C21.1668 17.1134 18.3951 19.9167 14.9885 19.9167ZM14.9883 6.5833C13.2717 6.5833 11.7 7.21164 10.48 8.24997H2.41667C2.18667 8.24997 2 8.43664 2 8.66664C2 8.89664 2.18667 9.0833 2.41667 9.0833H9.6525C8.69417 10.22 8.09 11.6658 7.9975 13.25H2.41667C2.18667 13.25 2 13.4366 2 13.6666C2 13.8966 2.18667 14.0833 2.41667 14.0833H7.9975C8.21333 17.795 11.2617 20.75 14.9883 20.75C18.855 20.75 22 17.5725 22 13.6666C22 9.7608 18.855 6.5833 14.9883 6.5833ZM17.5773 13.7899L15.4048 15.9841V10.3333C15.4048 10.1033 15.2181 9.91661 14.9881 9.91661C14.7581 9.91661 14.5715 10.1033 14.5715 10.3333V15.9883L12.3998 13.7899C12.2381 13.6274 11.974 13.6249 11.8106 13.7874C11.6465 13.9483 11.6448 14.2124 11.8073 14.3766L14.689 17.2933C14.7673 17.3716 14.874 17.4166 14.9848 17.4166H14.9856H14.9873H14.9881C15.1073 17.4166 15.2131 17.3658 15.2881 17.2866L18.1698 14.3766C18.3315 14.2133 18.3298 13.9491 18.1673 13.7874C18.0023 13.6258 17.7398 13.6266 17.5773 13.7899Z"
      fill={color || 'inherit'}
    />
  </Icon>
);

FindCover.propTypes = {
  color: PropTypes.string,
};

export default FindCover;
