import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgQuestionOutline = ({
  alt = 'Question Outline Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M21.75 12a9.75 9.75 0 1 0-19.5 0 9.75 9.75 0 0 0 19.5 0ZM0 12a12 12 0 1 1 24 0 12 12 0 0 1-24 0Zm7.96-4.252A2.63 2.63 0 0 1 10.433 6h2.733a2.958 2.958 0 0 1 2.958 2.958c0 1.06-.567 2.039-1.486 2.569l-1.514.867c-.01.61-.51 1.106-1.125 1.106a1.122 1.122 0 0 1-1.125-1.125v-.633c0-.403.216-.773.567-.975l2.077-1.19a.709.709 0 0 0-.352-1.322h-2.733a.37.37 0 0 0-.351.248l-.019.056a1.125 1.125 0 0 1-1.434.685 1.129 1.129 0 0 1-.685-1.435l.02-.056-.006-.005ZM10.5 16.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
    />
  </svg>
);
export default SvgQuestionOutline;
