import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * This class was borrowed from https://github.com/CassetteRocks/react-infinite-scroller.
 * It has some problems that made it unworkable in the TabbedNav component:
 *
 * TabbedNav has a single scroll container for all tab views, so the scroll listener
 * would call all scroll handlers even when the view was hidden.
 *
 * The other problem is that the react-infinite-scroller doesn't have an `isFetching` prop
 * and suppresses triggering a fetch by detaching the scroll listener before calling `loadMore`,
 * then reattaching the listener in `componentDidUpdate`. When the loading indicator renders,
 * an update occurs, and the `loadMore` call is made a few times while fetching is in progress.
 * There is an open PR to work around the problem with a debounce, but a better solution
 * (implemented here) is to pass the `isFetching` prop and don't reattach the listener
 * during fetching.
 */
export default class InfiniteScroll extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    element: PropTypes.node,
    hasMore: PropTypes.bool,
    loadMore: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    pageStart: PropTypes.number,
    getRef: PropTypes.func,
    getScrollParent: PropTypes.func.isRequired,
    threshold: PropTypes.number,
  };

  static defaultProps = {
    element: 'div',
    hasMore: false,
    isFetching: false,
    pageStart: 0,
    getRef: null,
    threshold: 250,
  };

  componentDidMount() {
    this.currentPageNumber = this.props.pageStart;
    this.attachScrollListener();
  }

  componentDidUpdate() {
    this.attachScrollListener();
  }

  componentWillUnmount() {
    this.detachScrollListener();
  }

  handleRef = element => {
    this.scrollComponent = element;
    if (this.props.getRef) this.props.getRef(element);
  };

  detachScrollListener() {
    const scrollParent = this.props.getScrollParent();

    if (!scrollParent) return;

    scrollParent.removeEventListener('scroll', this.scrollListener);
    scrollParent.removeEventListener('resize', this.scrollListener);
  }

  attachScrollListener() {
    const scrollParent = this.props.getScrollParent();

    if (this.props.isFetching || !this.props.hasMore || !scrollParent) {
      return;
    }

    scrollParent.addEventListener('scroll', this.scrollListener);
    scrollParent.addEventListener('resize', this.scrollListener);
  }

  scrollListener = () => {
    const el = this.scrollComponent;
    const { getScrollParent, threshold, loadMore } = this.props;
    const scrollParent = getScrollParent();

    if (!scrollParent) return;

    const offset =
      el.scrollHeight - scrollParent.scrollTop - scrollParent.clientHeight;

    if (offset < threshold && el && el.offsetParent !== null) {
      this.detachScrollListener();
      loadMore((this.currentPageNumber += 1));
    }
  };

  render() {
    const {
      children,
      element,
      hasMore,
      isFetching,
      loadMore,
      pageStart,
      getRef,
      threshold,
      getScrollParent,
      ...props
    } = this.props;

    props.ref = this.handleRef;

    return React.createElement(element, props, [children]);
  }
}
