import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  dismissShiftTradeUpsell,
  employeeAcceptTradeRequest,
  employeeDeclineTradeRequest,
  managerApproveTradeRequest,
  managerRejectTradeRequest,
} from 'actions/notifications';
import { createMonetizationCtaEvent } from 'actions/session';

import { getShowShiftTradeUpsellInterrupt } from 'selectors/session';

import { MANAGE_PLANS_PATH } from 'features/managePlan/constants';
import {
  trackCoverRequestUxEvents,
  trackTradeRequestUxEvents,
} from 'features/scheduleBuilder/tracking';

import { df, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { trackMonetizationCtaEventKinesis } from 'util/tracking';
import { TRACK_STARTING_PATHS } from 'util/tracking_constants';

import RequestsListItem from '../RequestsListItem';

const EVENT_TYPE = 'upsell_interrupt_shift_trade_approval';

@connect(
  (state, props) => ({
    eligibleForShiftTradeUpsell: getShowShiftTradeUpsellInterrupt(state, props),
  }),
  {
    managerRejectTradeRequest,
    employeeDeclineTradeRequest,
    managerApproveTradeRequest,
    employeeAcceptTradeRequest,
    dismissShiftTradeUpsell,
    createMonetizationCtaEvent,
  }
)
export default class Trade extends PureComponent {
  static propTypes = {
    showShiftTradeUpsell: PropTypes.bool,
    managerRejectTradeRequest: PropTypes.func.isRequired,
    employeeDeclineTradeRequest: PropTypes.func.isRequired,
    managerApproveTradeRequest: PropTypes.func.isRequired,
    employeeAcceptTradeRequest: PropTypes.func.isRequired,
    dismissShiftTradeUpsell: PropTypes.func.isRequired,
    createMonetizationCtaEvent: PropTypes.func.isRequired,
  };

  state = {
    showingShiftTradeUpsell: false,
  };

  titleI18n = () =>
    this.props.item.get('trade_shift')
      ? `notifications.requests.trade.title`
      : `notifications.requests.cover.title`;

  kinesisTrackEvent(actionType, properties = {}) {
    trackMonetizationCtaEventKinesis(EVENT_TYPE, actionType, {
      ...properties,
      productLocation: this.state.currentView,
    });
  }

  requesterFullName() {
    const { item } = this.props;
    return `${item.get('requester_first_name')} ${item.get(
      'requester_last_name'
    )}`;
  }

  receiverFirstName() {
    return this.props.item.get('receiver_first_name');
  }

  tradeShiftStartAt() {
    return moment(this.props.item.getIn(['trade_shift', 'start_at'])).format(
      df('pretty_time_date')
    );
  }

  shiftStartAt() {
    return moment(this.props.item.getIn(['shift', 'start_at'])).format(
      df('pretty_time_date')
    );
  }

  bodyProps = () => {
    const pending = this.props.item.get('state') === 'pending';

    let i18n;
    const i18nProps = {
      requester: this.requesterFullName(),
      shift_start_at: this.shiftStartAt(),
    };

    if (this.props.item.get('trade_shift')) {
      i18n = `notifications.requests.trade.${
        pending ? 'employee' : 'manager'
      }_trade_desc`;
      i18nProps.trade_shift_start_at = this.tradeShiftStartAt();
    } else {
      i18n = `notifications.requests.trade.${
        pending ? 'employee' : 'manager'
      }_cover_desc`;
    }

    if (!pending) {
      i18nProps.receiver = this.receiverFirstName();
    }

    return { i18n, i18nProps };
  };

  handleNoThanks = () => {
    this.kinesisTrackEvent('click_no_thanks');
    this.props.dismissShiftTradeUpsell(this.props.item);
  };

  handleLearnMore = () => {
    this.kinesisTrackEvent('click_learn_more');
    window.open(MANAGE_PLANS_PATH, '_blank');
    this.props.dismissShiftTradeUpsell(this.props.item);
  };

  approveTradeRequest(item) {
    this.props.managerApproveTradeRequest({
      id: item.get('id'),
      item,
      showingShiftTradeUpsell: this.state.showingShiftTradeUpsell,
    });
  }

  declineTradeRequest(item) {
    this.props.managerRejectTradeRequest({
      id: item.get('id'),
      item,
      showingShiftTradeUpsell: this.state.showingShiftTradeUpsell,
    });
  }

  handleApprove = () => {
    const { item, eligibleForShiftTradeUpsell } = this.props;
    if (item.get('trade_shift')) {
      trackTradeRequestUxEvents(true, TRACK_STARTING_PATHS.ALERTS);
    } else {
      trackCoverRequestUxEvents(true, TRACK_STARTING_PATHS.ALERTS);
    }

    if (item.get('state') === 'accepted') {
      if (eligibleForShiftTradeUpsell) {
        this.createMonetizationRecord();

        return this.setState({ showingShiftTradeUpsell: true }, () =>
          this.approveTradeRequest(item)
        );
      }

      return this.approveTradeRequest(item);
    }

    return this.props.employeeAcceptTradeRequest(item);
  };

  handleDecline = () => {
    const { item, eligibleForShiftTradeUpsell } = this.props;
    if (item.get('trade_shift')) {
      trackTradeRequestUxEvents(false, TRACK_STARTING_PATHS.ALERTS);
    } else {
      trackCoverRequestUxEvents(false, TRACK_STARTING_PATHS.ALERTS);
    }

    if (item.get('state') === 'accepted') {
      if (eligibleForShiftTradeUpsell) {
        this.createMonetizationRecord();

        return this.setState({ showingShiftTradeUpsell: true }, () =>
          this.declineTradeRequest(item)
        );
      }

      return this.declineTradeRequest(item);
    }

    return this.props.employeeDeclineTradeRequest(item);
  };

  createMonetizationRecord = () =>
    this.props.createMonetizationCtaEvent(EVENT_TYPE);

  render() {
    if (this.state.showingShiftTradeUpsell) {
      this.kinesisTrackEvent('view');
      return (
        <RequestsListItem
          hideDate
          bodyProps={{ asHTML: true }}
          body={toI18n('notifications.requests.trade.upsell.body')}
          acceptButtonTheme="primary-blue"
          declineButtonTheme="link"
          acceptButtonCopy={toI18n(
            'notifications.requests.trade.upsell.learn_more'
          )}
          declineButtonCopy={toI18n(
            'notifications.requests.trade.upsell.no_thanks'
          )}
          titleI18n=" "
          onApprove={this.handleLearnMore}
          onDecline={this.handleNoThanks}
          bg="blueLightest"
          {...this.props}
        />
      );
    }
    return (
      <RequestsListItem
        bodyProps={this.bodyProps()}
        titleI18n={this.titleI18n()}
        onApprove={this.handleApprove}
        onDecline={this.handleDecline}
        {...this.props}
      />
    );
  }
}
