import React, { memo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

const SecondaryAction = ({
  secondaryActionText,
  onSecondaryAction,
  isDone,
  secondaryIsLoading,
}) =>
  isDone ? (
    <Box row relative gap={6} vcenter top={-1}>
      <Text color="success300" variant="bodySmBold">
        {secondaryActionText}
      </Text>
      <Icon iconName="Success" size="xsmall" color="success300" />
    </Box>
  ) : (
    <Button
      variant="secondary"
      size="small"
      onClick={onSecondaryAction}
      isLoading={secondaryIsLoading}
      disabled={secondaryIsLoading}
    >
      <Text color="primary500" variant="bodySmBold">
        {secondaryActionText}
      </Text>
    </Button>
  );

SecondaryAction.propTypes = {
  secondaryActionText: PropTypes.string.isRequired,
  onSecondaryAction: PropTypes.func.isRequired,
  isDone: PropTypes.bool,
  secondaryIsLoading: PropTypes.bool,
};

export default memo(SecondaryAction);
