import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { postJSON } from 'api/fetch';

import * as actions from 'actions/notifications';

import { toI18n } from 'util/i18n';

import AlertsListItem from '../AlertsListItem';

const DeclinedUserMergeRequest = ({ item, closeDrawer, readRequest }) => {
  const handleEdit = useCallback(() => {
    closeDrawer();
    window.Homebase.RailsReactBridge.navigateToReactView(
      `/team/${item.get('user_id')}/personal_information`
    );
  }, [closeDrawer, item]);

  const handleDismiss = useCallback(() => {
    postJSON(`/team/user_merge_requests/${item.get('id')}/resend.json`).then(
      () => readRequest(item)
    );
  }, [readRequest, item]);

  return (
    <AlertsListItem
      item={item}
      desc={toI18n('notifications.alerts.declined_user_merge_request.desc', {
        props: { name: item.get('name') },
      })}
      onEdit={handleEdit}
      onDismiss={handleDismiss}
      editButton={toI18n(
        'notifications.alerts.declined_user_merge_request.edit'
      )}
      dismissButton={toI18n('actions.resend')}
    />
  );
};

export default connect(() => ({}), {
  readRequest: actions.deleteAlert,
})(DeclinedUserMergeRequest);
