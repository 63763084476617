import React from 'react';
import Icon from 'react-icon-base';

const CashRegister = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M23.59 19.946l-.804-4.762c-.09-.446-.476-.773-.952-.773h-7.262v-1.905h2.857c.238 0 .476-.238.476-.476V9.143c0-.238-.238-.477-.476-.477h-7.62c-.267 0-.475.239-.475.477V12c0 .268.208.476.476.476h2.857v1.905h-2.53c-.476 0-.863.357-.952.803l-.804 4.762c0 .12-.03.209-.03.328v2.678c0 .536.447.953.953.953h13.333c.536 0 .953-.417.953-.953v-2.678c.03-.12 0-.209 0-.328zm-6.875-3.899c0-.238.208-.476.476-.476h.476c.238 0 .476.238.476.476v.477c0 .267-.238.476-.476.476h-.476c-.268 0-.476-.209-.476-.476v-.477zm-.953 1.905h.476c.239 0 .477.238.477.476v.477c0 .267-.238.476-.477.476h-.476c-.268 0-.476-.209-.476-.476v-.477c0-.238.208-.476.476-.476zm-.952-2.38c.238 0 .476.237.476.476v.476c0 .267-.238.476-.476.476h-.476c-.268 0-.476-.209-.476-.476v-.477c0-.238.208-.476.476-.476h.476zm-4.048-4.524v-.953h5.715v.952h-5.715zM11.952 17h-.475c-.268 0-.477-.209-.477-.476v-.477c0-.238.209-.476.477-.476h.476c.238 0 .476.238.476.476v.477c0 .267-.238.476-.476.476zm.477 1.905v-.477c0-.238.208-.476.476-.476h.476c.238 0 .476.238.476.476v.477c0 .267-.238.476-.476.476h-.476c-.268 0-.476-.209-.476-.476zm6.429 3.333c0 .149-.12.238-.239.238h-5.238c-.149 0-.238-.09-.238-.238v-.476c0-.12.09-.238.238-.238h5.238c.12 0 .239.119.239.238v.476zm.714-3.333c0 .267-.238.476-.476.476h-.477c-.267 0-.476-.209-.476-.476v-.477c0-.238.209-.476.476-.476h.477c.238 0 .476.238.476.476v.477zM21 16.524c0 .267-.238.476-.476.476h-.476c-.268 0-.476-.209-.476-.476v-.477c0-.238.208-.476.476-.476h.476c.238 0 .476.238.476.476v.477z"
    />
  </Icon>
);

export default CashRegister;
