import React from 'react';
import Icon from 'react-icon-base';

const Rocket = require('react-icons/lib/fa/rocket');

const RocketCircle = props => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" />
    <Rocket x="13" y="13" width="41" height="41" fill="#fff" />
  </Icon>
);

export default RocketCircle;
