import * as routes from 'api';

import * as timeOffActions from 'actions/timeOff';

import * as ptoPolicySelectors from 'selectors/ptoPolicy';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import * as reduxUtil from 'util/redux';
import { browserHistory } from 'util/router';

export const actionTypes = {
  FETCH_COMPANY_USERS_REQUEST: 'PTO_POLICY/FETCH_COMPANY_USERS_REQUEST',
  FETCH_COMPANY_USERS_SUCCESS: 'PTO_POLICY/FETCH_COMPANY_USERS_SUCCESS',
  FETCH_COMPANY_USERS_FAILURE: 'PTO_POLICY/FETCH_COMPANY_USERS_FAILURE',
  FETCH_PTO_POLICY_REQUEST: 'PTO_POLICY/FETCH_PTO_POLICY_REQUEST',
  FETCH_PTO_POLICY_SUCCESS: 'PTO_POLICY/FETCH_PTO_POLICY_SUCCESS',
  FETCH_PTO_POLICY_FAILURE: 'PTO_POLICY/FETCH_PTO_POLICY_FAILURE',
  RESET_DETAILS_FORM: 'PTO_POLICY/RESET_DETAILS_FORM',
  UPDATE_DETAILS_FORM_FIELD: 'PTO_POLICY/UPDATE_DETAILS_FORM_FIELD',
  SAVE_NEW_POLICY: 'PTO_POLICY/SAVE_NEW_POLICY',
  CREATE_PTO_POLICY_REQUEST: 'PTO_POLICY/CREATE_PTO_POLICY_REQUEST',
  CREATE_PTO_POLICY_SUCCESS: 'PTO_POLICY/CREATE_PTO_POLICY_SUCCESS',
  CREATE_PTO_POLICY_FAILURE: 'PTO_POLICY/CREATE_PTO_POLICY_FAILURE',
  UPDATE_PTO_POLICY_REQUEST: 'PTO_POLICY/UPDATE_PTO_POLICY_REQUEST',
  UPDATE_PTO_POLICY_SUCCESS: 'PTO_POLICY/UPDATE_PTO_POLICY_SUCCESS',
  UPDATE_PTO_POLICY_FAILURE: 'PTO_POLICY/UPDATE_PTO_POLICY_FAILURE',
  DELETE_PTO_POLICY_REQUEST: 'PTO_POLICY/DELETE_PTO_POLICY_REQUEST',
  DELETE_PTO_POLICY_SUCCESS: 'PTO_POLICY/DELETE_PTO_POLICY_SUCCESS',
  DELETE_PTO_POLICY_FAILURE: 'PTO_POLICY/DELETE_PTO_POLICY_FAILURE',
  UPDATE_EMPLOYEES_FORM_IDS: 'PTO_POLICY/UPDATE_EMPLOYEES_FORM_IDS',
  UPDATE_BALANCES_FORM_BALANCE: 'PTO_POLICY/UPDATE_BALANCES_FORM_BALANCE',
  TOGGLE_EDITING_DETAILS: 'PTO_POLICY/TOGGLE_EDITING_DETAILS',
  TOGGLE_EDITING_EMPLOYEES: 'PTO_POLICY/TOGGLE_EDITING_EMPLOYEES',
  INIT_POLICY: 'PTO_POLICY/INIT_POLICY',
  SET_EDIT_EMPLOYEES_STEP: 'PTO_POLICY/SET_EDIT_EMPLOYEES_STEP',
  INITIALIZE_POLICY_BALANCES_FORM: 'PTO_POLICY/INITIALIZE_POLICY_BALANCES_FORM',
};

export const fetchCompanyUsers = () =>
  reduxUtil.createAsyncGetAction(
    routes.ptoPoliciesCompanyUsersRoute(),
    [
      {
        type: actionTypes.FETCH_COMPANY_USERS_REQUEST,
        meta: { requestId: 'companyUsers' },
      },
      {
        type: actionTypes.FETCH_COMPANY_USERS_SUCCESS,
        meta: { requestId: 'companyUsers' },
      },
      {
        type: actionTypes.FETCH_COMPANY_USERS_FAILURE,
        meta: { requestId: 'companyUsers' },
      },
    ],
    {
      bailout: ptoPolicySelectors.getBailoutFetchCompanyUsers,
    }
  );

export const fetchPTOPolicy = (id, force = false) =>
  reduxUtil.createAsyncGetAction(
    routes.ptoPolicyRoute(id),
    [
      {
        type: actionTypes.FETCH_PTO_POLICY_REQUEST,
        meta: { requestId: `policy${id}` },
      },
      {
        type: actionTypes.FETCH_PTO_POLICY_SUCCESS,
        meta: { requestId: `policy${id}` },
      },
      {
        type: actionTypes.FETCH_PTO_POLICY_FAILURE,
        meta: { requestId: `policy${id}` },
      },
    ],
    {
      bailout: state =>
        !force &&
        ptoPolicySelectors.getBailoutFetchPolicy(state, { policyId: id }),
    }
  );

export const updateDetailsFormField = (name, value) => ({
  type: actionTypes.UPDATE_DETAILS_FORM_FIELD,
  payload: { name, value },
});

export const updateEmployeesFormIds = ids => ({
  type: actionTypes.UPDATE_EMPLOYEES_FORM_IDS,
  payload: { ids },
});

export const updateBalancesFormBalance = (id, value) => ({
  type: actionTypes.UPDATE_BALANCES_FORM_BALANCE,
  payload: { id, value },
});

export const toggleEditingDetails = editing => ({
  type: actionTypes.TOGGLE_EDITING_DETAILS,
  payload: { editing },
});

export const toggleEditingEmployees = editing => ({
  type: actionTypes.TOGGLE_EDITING_EMPLOYEES,
  payload: { editing },
});

const formatPTOPolicyDetailsPayload = form => {
  if (form.accrual_method === 'annual') {
    delete form.hours_earned;
    delete form.hours_worked;
  } else {
    delete form.total_hours;
  }

  return form;
};

const formatPTOPolicyEmployeesPayload = (formIds, policy) => {
  const users = formIds.length
    ? formIds.map(id => {
        const userPayload = { user_id: id };
        const existingPolicyEmployee = policy
          .get('users')
          .find(user => user.get('user_id') === id);

        if (existingPolicyEmployee) {
          userPayload.id = existingPolicyEmployee.get('id');
        }

        return userPayload;
      })
    : // Server interprets an array with one empty string as "no employees";
      // this allows us to bypass Rails 4's deep-munging.
      // See https://stackoverflow.com/a/30844622 for details
      [''];

  return { users };
};

const formatPTOPolicyEmployeesWithBalancesPayload = (employees, policy) => {
  if (employees.isEmpty()) {
    return [''];
  }

  return employees.map(employee => {
    const userPayload = {
      user_id: employee.get('id'),
      accrued_hours: employee.get('accrued_hours'),
    };
    const existingPolicyEmployee = policy
      .get('users')
      .find(user => user.get('user_id') === employee.get('user_id'));

    if (existingPolicyEmployee) {
      userPayload.id = existingPolicyEmployee.get('id');
    }

    return userPayload;
  });
};

const formatCurrentPTOPolicyEmployeesPayload = (state, props) => {
  const formIds = ptoPolicySelectors
    .getPolicyEmployeesFormIds(state, props)
    .toJS();
  const policy = ptoPolicySelectors.getCurrentPolicy(state, props);

  return formatPTOPolicyEmployeesPayload(formIds, policy);
};

const formatPTOPolicyBalancesPayload = form => ({
  users: form.valueSeq().toJS(),
});

const createPTOPolicy = (payload, meta) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.ptoPoliciesRoute(),
      [
        { type: actionTypes.CREATE_PTO_POLICY_REQUEST, meta },
        { type: actionTypes.CREATE_PTO_POLICY_SUCCESS, meta },
        { type: actionTypes.CREATE_PTO_POLICY_FAILURE, meta },
      ],
      {
        body: { pto_policy: payload },
        bailout: state =>
          ptoPolicySelectors.getIsFetching(state, { id: meta.requestId }),
      }
    ),
    {
      success: toI18n('pto_policy.create_success'),
    }
  );

const updatePTOPolicy = (id, payload, meta, alerts) => dispatch =>
  dispatch(
    reduxUtil.withAlerts(
      reduxUtil.createAsyncPutAction(
        routes.ptoPolicyRoute(id),
        [
          { type: actionTypes.UPDATE_PTO_POLICY_REQUEST, meta },
          { type: actionTypes.UPDATE_PTO_POLICY_SUCCESS, meta },
          { type: actionTypes.UPDATE_PTO_POLICY_FAILURE, meta },
        ],
        {
          body: { pto_policy: payload },
          bailout: state =>
            ptoPolicySelectors.getIsFetching(state, { id: meta.requestId }),
        }
      ),
      {
        ...alerts,
        onSuccess: action => {
          if (alerts.onSuccess) {
            alerts.onSuccess(action);
          }
          dispatch(timeOffActions.fetchPTOPolicies({ force: true }));
        },
      }
    )
  );

const ptoPolicyAction = (form, id) => {
  const payload = formatPTOPolicyDetailsPayload(form.toJS());
  const meta = {
    form: 'details',
    new: true,
    requestId: 'detailsNew',
  };

  return id
    ? updatePTOPolicy(id, payload, meta, {
        success: toI18n('pto_policy.update_details_success'),
      })
    : createPTOPolicy(payload, meta);
};

export const savePtoPolicyForm = (form, id) => dispatch => {
  dispatch(ptoPolicyAction(form, id));
};

export const saveNewPolicyDetails = () => (dispatch, getState) => {
  const state = getState();
  const form = ptoPolicySelectors.getPolicyDetailsForm(state);
  const id = ptoPolicySelectors.getNewPolicyId(state);

  dispatch(ptoPolicyAction(form, id));
};

export const saveNewPolicyEmployees = () => (dispatch, getState) => {
  const state = getState();
  const id = ptoPolicySelectors.getNewPolicyId(state);
  const payload = formatCurrentPTOPolicyEmployeesPayload(state);
  const meta = {
    form: 'employees',
    new: true,
    requestId: 'employeesNew',
  };

  dispatch(
    updatePTOPolicy(id, payload, meta, {
      success: toI18n('pto_policy.update_employees_success'),
      onSuccess: action => {
        // If no employees added, redirect to policy view
        if (!action.payload.users || action.payload.users.length === 0) {
          browserHistory.push(`/pto_policies/${action.payload.id}`);
        }
      },
    })
  );
};

export const saveNewPolicyBalances = () => (dispatch, getState) => {
  const state = getState();
  const form = ptoPolicySelectors.getPolicyBalancesForm(state);
  const id = ptoPolicySelectors.getNewPolicyId(state);
  const payload = formatPTOPolicyBalancesPayload(form);
  const meta = {
    form: 'balances',
    new: true,
    requestId: 'balancesNew',
  };

  dispatch(
    updatePTOPolicy(id, payload, meta, {
      success: toI18n('pto_policy.update_balances_success'),
      onSuccess: action =>
        browserHistory.push(`/pto_policies/${action.payload.id}`),
    })
  );
};

export const savePolicyDetails = id => (dispatch, getState) => {
  const state = getState();
  const form = ptoPolicySelectors.getPolicyDetailsForm(state);
  const payload = formatPTOPolicyDetailsPayload(form.toJS());
  const meta = {
    form: 'details',
    new: false,
    requestId: 'details',
  };

  dispatch(
    updatePTOPolicy(id, payload, meta, {
      success: toI18n('pto_policy.update_details_success'),
    })
  );
};

export const savePolicyEmployees = id => (dispatch, getState) => {
  const state = getState();
  const payload = formatCurrentPTOPolicyEmployeesPayload(state, {
    policyId: id,
  });
  const meta = {
    form: 'employees',
    new: false,
    requestId: 'employees',
  };

  dispatch(
    updatePTOPolicy(id, payload, meta, {
      success: toI18n('pto_policy.update_employees_success'),
    })
  );
};

export const saveGivenPolicyEmployees = (employees, policy) => dispatch => {
  const payload = {
    users: formatPTOPolicyEmployeesWithBalancesPayload(employees, policy),
  };
  const meta = {
    form: 'employees',
    new: false,
    requestId: 'employees',
  };

  dispatch(
    updatePTOPolicy(policy.get('id'), payload, meta, {
      success: toI18n('pto_policy.update_employees_success'),
    })
  );
};

export const savePolicyAction = (id, form) => {
  const payload = formatPTOPolicyBalancesPayload(form);
  const meta = {
    form: 'balances',
    new: false,
    requestId: 'balances',
  };

  return updatePTOPolicy(id, payload, meta, {
    success: toI18n('pto_policy.update_balances_success'),
  });
};

export const savePolicyBalancesForm = (id, form) => dispatch => {
  dispatch(savePolicyAction(id, form));
};

export const savePolicyBalances = id => (dispatch, getState) => {
  const form = ptoPolicySelectors.getPolicyBalancesForm(getState());

  dispatch(savePolicyBalancesForm(id, form));
};

export const initPolicy = policy => ({
  type: actionTypes.INIT_POLICY,
  payload: policy,
});

export const setEditEmployeesStepIndex = stepIndex => ({
  type: actionTypes.SET_EDIT_EMPLOYEES_STEP,
  payload: { stepIndex },
});

export const deletePolicy = id => dispatch => {
  const meta = { requestId: `deletePolicy${id}`, id };

  return dispatch(
    reduxUtil.withAlerts(
      reduxUtil.createAsyncDeleteAction(routes.ptoPolicyRoute(id), [
        { type: actionTypes.DELETE_PTO_POLICY_REQUEST, meta },
        { type: actionTypes.DELETE_PTO_POLICY_SUCCESS, meta },
        { type: actionTypes.DELETE_PTO_POLICY_FAILURE, meta },
      ]),
      {
        success: toI18n('pto_policies.delete_success'),
        onSuccess: () => {
          // need to reload the jobs with the updated
          // policies categories after deleting policy
          // fetchTimeOffs will update jobs as needed.
          dispatch(
            timeOffActions.fetchTimeOffs({
              month: moment().format('MM-YYYY'),
              status: 'all',
              includeJobs: true,
            })
          );
          browserHistory.push('/time_offs');
        },
      }
    )
  );
};

export const initializeBalancesForm = policyId => (dispatch, getState) => {
  const users = ptoPolicySelectors.getCurrentPolicyUsers(getState(), {
    policyId,
  });

  dispatch({
    type: actionTypes.INITIALIZE_POLICY_BALANCES_FORM,
    payload: { users },
  });
};
