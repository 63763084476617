import React from 'react';
import Box from 'fe-components/Box';
import RadioButtonGroup from 'fe-design-base/organisms/RadioButtonGroup';

import { I18N_ONBOARDING_PATH } from 'features/team/components/AddEmployeeForm/constants';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import OnboardingOption from './OnboardingOption';
const { cx } = cxHelpers('OnboardingOptionGroup');
const OnboardingOptionGroup = ({ options, showFormWithSteps }) => (
  <Box color="mono900" className={cx()} mt={showFormWithSteps ? 20 : 24}>
    <RadioButtonGroup
      label={
        !showFormWithSteps && toI18n(`${I18N_ONBOARDING_PATH}.options_title`)
      }
      name="onboarding"
    >
      {options.map(option => (
        <OnboardingOption
          key={`onboarding-option-${option.value}`}
          disabled={option.disabled}
          label={option.label}
          subLabel={option.subLabel}
          value={option.value}
          onClick={option.onClick}
        />
      ))}
    </RadioButtonGroup>
  </Box>
);

export default OnboardingOptionGroup;
