import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import { compose } from 'redux';

import GoToManagePlanPageButton from 'features/managePlan/GoToManagePlanPageButton';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { selectGhostEmployeeCapV2ExperimentValue } from './selectors';
import { deadlineToDate, toMonthDayOrdinal } from './util';

const { cx, cxEl } = cxHelpers('PreDeadlineV2View');

export const PreDeadlineV2View = ({
  onClose,
  employeeCapDate,
  onClickCTATracking,
}) => {
  const deadline = deadlineToDate(employeeCapDate, 7);
  const bannerText = toI18n('enforcement.employee_cap.banner_text', {
    props: {
      date: toMonthDayOrdinal(deadline),
    },
  });

  return (
    <Box row vcenter spacebetween ph={16} className={cx()} bgcolor="orange300">
      <Text variant="body" color="mono0">
        {bannerText}
      </Text>

      <Box row vcenter>
        <Box mr={50}>
          <GoToManagePlanPageButton
            size="small"
            variant="tertiary"
            baseComponent="designbase"
            onClick={onClickCTATracking}
          >
            <Box row>
              <Text variant="bodyBold" color="mono0" underline>
                {toI18n('enforcement.banner_cta')}
              </Text>
              <Icon iconName="ArrowRight" size="medium" color="mono0" />
            </Box>
          </GoToManagePlanPageButton>
        </Box>

        <Icon
          color="mono0"
          size="medium"
          iconName="Close"
          onClick={onClose}
          className={cxEl('close-button')}
          dataTestId={cxEl('close-button')}
        />
      </Box>
    </Box>
  );
};

export default compose(
  connect(state => ({
    employeeCapDate: selectGhostEmployeeCapV2ExperimentValue(state),
  }))
)(PreDeadlineV2View);
