import React from 'react';
import PropTypes from 'prop-types';

import CloseActionsBar from './CloseActionsBar';
import NavActionsBar from './NavActionsBar';

const ActionsBar = ({ type, ...props }) => {
  switch (type) {
    case 'close':
      return <CloseActionsBar {...props} />;
    case 'nav':
      return <NavActionsBar {...props} />;
    default:
      throw new Error('Unknown type provided to <ActionsBar />');
  }
};

const bottomDrawerHelpersShape = {
  currentStep: PropTypes.number.isRequired,
  closeBottomDrawer: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  setOnCloseClick: PropTypes.func.isRequired,
  setOnNextStepClick: PropTypes.func.isRequired,
  setOnPreviousStepClick: PropTypes.func.isRequired,
  setNextStepButtonText: PropTypes.func.isRequired,
  setPreviousStepButtonText: PropTypes.func.isRequired,
  setCloseButtonText: PropTypes.func.isRequired,
};

ActionsBar.propTypes = {
  type: PropTypes.oneOf(['close', 'nav']).isRequired,
  bottomDrawerHelpers: PropTypes.shape(bottomDrawerHelpersShape).isRequired,
  nextStepButtonText: PropTypes.string,
  previousStepButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
  onNextStepClick: PropTypes.func,
  onPreviousStepClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  onCancelClick: PropTypes.func,
};

export default ActionsBar;
