import React, { useEffect } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';
import {
  ELEMENTS,
  EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

const LaunchWeekStepNumberOfShifts = ({
  launchWeekSettings,
  onNextStep,
  trackUx,
}) => {
  const setTimeoutId = onNextStep();

  useEffect(() => {
    trackUx(EVENT_ACTIONS.PAGE_VIEWED, TRACK_ACTION_TYPES.VIEW, {
      step_label: ELEMENTS.LAUNCH_WEEK_STEP_LABELS.NUMBER_OF_SHIFTS,
    });
    return () => {
      clearTimeout(setTimeoutId);
    };
  }, [setTimeoutId, trackUx]);

  const numberOfShifts = launchWeekSettings.get('number_of_shifts');
  const formmattedNumberOfShifts = new Intl.NumberFormat('en-US').format(
    numberOfShifts
  );

  return (
    <Box column hcenter>
      <Box tcenter className="content-with-subcontent-fade-in-and-out">
        <Text variant="display1" color="mono0" asHTML>
          {toI18n('growth.launch_week.steps.number_of_shifts.title', {
            props: { numberOfShifts: formmattedNumberOfShifts },
          })}
        </Text>
      </Box>

      <Box tcenter mt={40} className="subcontent-fade-in-and-out">
        <Text variant="heading2" color="mono0" asHTML>
          {toI18n('growth.launch_week.steps.number_of_shifts.subtitle')}
        </Text>
      </Box>
    </Box>
  );
};

export default LaunchWeekStepNumberOfShifts;
