/* eslint-disable react/prop-types */

import React from 'react';
import MediaQuery from 'react-responsive';
import { mapValues, toNumber } from 'lodash';
import { breakpoints } from 'theme/constants';

const {
  mobileBreakpoint,
  tabletBreakpoint,
  largeDesktopBreakpoint,
  smallTabletBreakpoint,
  ipadWidth,
} = mapValues(breakpoints, bp => toNumber(bp.replace('px', '')));

export default MediaQuery;

const LargeDesktop = props => (
  <MediaQuery minWidth={largeDesktopBreakpoint} {...props} />
);

const Desktop = props => <MediaQuery minWidth={tabletBreakpoint} {...props} />;

const Tablet = props => (
  <MediaQuery
    minWidth={mobileBreakpoint}
    maxWidth={tabletBreakpoint}
    {...props}
  />
);

const SmallTablet = props => (
  <MediaQuery
    minWidth={mobileBreakpoint}
    maxWidth={smallTabletBreakpoint}
    {...props}
  />
);

const LargeTablet = props => (
  <MediaQuery
    minWidth={smallTabletBreakpoint}
    maxWidth={tabletBreakpoint}
    {...props}
  />
);

const TabletVertical = props => <MediaQuery maxWidth={ipadWidth} {...props} />;

const TabletAndAbove = props => (
  <MediaQuery minWidth={mobileBreakpoint} {...props} />
);

const TabletAndBelow = props => (
  <MediaQuery maxWidth={tabletBreakpoint} {...props} />
);

const SmallTabletAndBelow = props => (
  <MediaQuery maxWidth={smallTabletBreakpoint} {...props} />
);

const Mobile = props => <MediaQuery maxWidth={mobileBreakpoint} {...props} />;

const MobileAndTablet = props => (
  <Tablet>
    {tablet => <Mobile>{mobile => props.children(mobile, tablet)}</Mobile>}
  </Tablet>
);

const MobileAndSmallTablet = props => (
  <SmallTablet>
    {smallTablet => (
      <Mobile>{mobile => props.children(mobile, smallTablet)}</Mobile>
    )}
  </SmallTablet>
);

export {
  LargeDesktop,
  Desktop,
  Tablet,
  SmallTablet,
  LargeTablet,
  TabletAndAbove,
  TabletAndBelow,
  SmallTabletAndBelow,
  Mobile,
  MobileAndTablet,
  MobileAndSmallTablet,
  TabletVertical,
  mobileBreakpoint,
  tabletBreakpoint,
  smallTabletBreakpoint,
  largeDesktopBreakpoint,
  ipadWidth,
};
