import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSupport = ({
  alt = 'Support Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M8.958 10.5a1.5 1.5 0 0 0-1.5-1.502L6.75 9a3 3 0 0 0-3 3.004v2.24a3.004 3.004 0 0 0 3 3.006h.75c.828 0 1.5-.673 1.5-1.503L8.958 10.5ZM12 0C5.292 0 .215 5.583.001 12L0 13.875C0 14.498.504 15 1.083 15s1.167-.502 1.167-1.125V12c0-5.377 4.375-9.74 9.75-9.74 5.377 0 9.75 4.363 9.75 9.74v6.75c0 1.035-.84 1.875-1.875 1.875h-5.19A2.236 2.236 0 0 0 12.75 19.5h-1.416c-1.088 0-2.086.735-2.29 1.804-.278 1.44.819 2.696 2.164 2.696h1.542a2.24 2.24 0 0 0 1.938-1.125h5.187A4.125 4.125 0 0 0 24 18.75V12c-.216-6.417-5.292-12-12-12Zm5.25 17.25c1.657 0 3-1.345 3-3.006v-2.24a3.004 3.004 0 0 0-3-3.004h-.75c-.828 0-1.5.672-1.5 1.502v5.243a1.5 1.5 0 0 0 1.5 1.505h.75Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSupport;
