import React from 'react';
import Icon from 'react-icon-base';

const EnvelopeBlue = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g>
      <path
        fill="#70CAD9"
        d="M43.5 6h-39A4.501 4.501 0 0 0 0 10.5v27C0 39.984 2.016 42 4.5 42h39c2.484 0 4.5-2.016 4.5-4.5v-27C48 8.016 45.984 6 43.5 6zm-39 2h39c.825 0 2.5 1.675 2.5 2.5v3.881c-2.053 1.735-5.987 4.125-15.119 11.372C29.297 27.01 26.175 30.038 24 30c-2.175.038-5.306-2.99-6.881-4.247C7.988 18.506 4.053 16.116 2 14.381V10.5C2 9.675 3.675 8 4.5 8zm39 32h-39C3.675 40 2 38.325 2 37.5V17.281c2.138 1.753 6.512 4.463 13.253 9.816C17.175 28.634 20.57 32.019 24 32c3.413.028 6.778-3.328 8.747-4.903 6.74-5.353 11.115-8.063 13.253-9.816V37.5c0 .825-1.675 2.5-2.5 2.5z"
      />
    </g>
  </Icon>
);

export default EnvelopeBlue;
