import * as routes from 'api';

import { createAsyncGetAction } from 'util/redux';

export const actionTypes = {
  FETCH_UNREAD_MESSAGES_FROM_APPLICANT_REQUEST:
    'HIRING/DASHBOARD_WIDGET/FETCH_UNREAD_MESSAGES_FROM_APPLICANT_REQUEST',
  FETCH_UNREAD_MESSAGES_FROM_APPLICANT_SUCCESS:
    'HIRING/DASHBOARD_WIDGET/FETCH_UNREAD_MESSAGES_FROM_APPLICANT_SUCCESS',
  FETCH_UNREAD_MESSAGES_FROM_APPLICANT_FAILURE:
    'HIRING/DASHBOARD_WIDGET/FETCH_UNREAD_MESSAGES_FROM_APPLICANT_FAILURE',
};

export const fetchUnreadMessagesFromApplicants = () =>
  createAsyncGetAction(routes.applicationMessagesUnreadFromApplicants(), [
    { type: actionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_REQUEST },
    { type: actionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_SUCCESS },
    { type: actionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_FAILURE },
  ]);
