import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

import { getRolloutEnabled } from 'selectors/session';

import { MANAGE_PLANS_PATH, SLICE_NAME } from './constants';

const shopifyRolloutEnabled = state =>
  getRolloutEnabled(state, 'admin_shopify_subscription_panel');

export const changeTier = createAsyncThunk(
  `${SLICE_NAME}/changeTier`,
  ({ tier }, { getState }) => {
    if (shopifyRolloutEnabled(getState())) {
      return fetchJSON(tier.get('upgradeUrlMonthly'))
        .then(({ url }) => (window.location.href = url || MANAGE_PLANS_PATH))
        .catch(() => (window.location.href = MANAGE_PLANS_PATH));
    }

    return (window.location.href = tier.get('upgradeUrlMonthly'));
  }
);

export const downgradeTier = createAsyncThunk(
  `${SLICE_NAME}/downgradeTier`,
  ({ tier }, { getState, rejectWithValue }) => {
    if (shopifyRolloutEnabled(getState())) {
      return fetchJSON(tier.get('upgradeUrlMonthly')).catch(err =>
        err.response.json().then(body => rejectWithValue(body))
      );
    }

    return (window.location.href = tier.get('upgradeUrlMonthly'));
  }
);
