import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { TRACK_EVENTS } from 'features/hiring/addApplicantManually/tracking_constants';
import { APPLIED_TO_LOCATION_VALUE } from 'features/hiring/manageApplicants/constants';
import UpgradeWrapper from 'features/permissions/UpgradeWrapper';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import withUxTracking from 'components/hocs/withUxTracking';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import {
  logHiringEvent,
  setOriginContextByUrl,
  trackAddApplicantsManuallyEvent,
} from 'util/tracking';

@withUxTracking('Navigation', 'Sub Menu')
@cxHelpers('SubNavItem')
export default class SubNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    feature: PropTypes.string,
    active: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired,
    eventAction: PropTypes.string,
    openAddApplicantsManuallyDrawer: PropTypes.func,
    currentLocationId: PropTypes.number,
    trackUxEvent: PropTypes.func.isRequired,
  };

  handleClick = e => {
    const { log_hiring_data: logHiringData, eventAction, url } = this.props;
    if (!e.metaKey) {
      e.preventDefault();
      if (logHiringData) {
        logHiringEvent(logHiringData);
      }

      if (eventAction) {
        this.props.trackUxEvent({ eventAction });
      }
      setOriginContextByUrl(url);
      window.Homebase.RailsReactBridge.navigateToReactView(this.props.url);
      if (
        this.props.url ===
        `/hiring/locations/${this.props.currentLocationId}/manage_applicants`
      ) {
        trackAddApplicantsManuallyEvent(TRACK_EVENTS.HIRING_SUBNAV_BAR);

        this.props.openAddApplicantsManuallyDrawer(
          APPLIED_TO_LOCATION_VALUE,
          this.props.currentLocationId
        );
      }
      return false;
    }
  };

  render() {
    const { url, active, text, feature } = this.props;

    const button = (
      <Button theme="link" href={url} onClick={this.handleClick} noPreloader>
        <Text fs14 color={active ? 'purple' : 'purple400'}>
          {text}
        </Text>
      </Button>
    );

    return (
      <Box className={this.cx()} mrxl>
        {feature ? (
          <UpgradeWrapper feature={feature}>{button}</UpgradeWrapper>
        ) : (
          button
        )}
      </Box>
    );
  }
}
