import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes as timecardActions } from 'actions/timecard';

const INITIAL_GENERAL_STATE = fromJS({
  isOpen: false,
  shiftId: null,
  mainInfoDisplayed: false,
  groupRows: [],
  shiftData: null,
});

const general = (state = INITIAL_GENERAL_STATE, action) => {
  switch (action.type) {
    case timecardActions.ENABLE_EDIT_MODE:
      return state.merge({
        editableField: action.payload.fieldName,
        forceUpdate: !!action.payload.forceUpdate,
      });
    case timecardActions.SUBMIT_CHANGES_SUCCESS: {
      return state.merge({
        isChanged: true,
        shiftId: action.payload.shift_id,
        changedFields: state.get('submittedFields'),
        submittedFields: [],
      });
    }
    default:
      return state;
  }
};

export default combineReducers({
  general,
});
