import { createSelector } from 'reselect';

export const getCustomDocuments = state =>
  state.getIn(['hirePacketConfiguration']).customDocuments;
export const getEmployerDetails = state =>
  state.getIn(['hirePacketConfiguration']).employerDetails;
export const getEmployerDetailsErrors = state =>
  state.getIn(['hirePacketConfiguration']).errors;
export const getSaveDisabled = state =>
  state.getIn(['hirePacketConfiguration']).saveDisabled;
export const getNewHireOnboardingActivated = state =>
  state.getIn(['hirePacketConfiguration']).newHireOnboardingActivated;
export const getPdfCustomDocumentPageInfo = state =>
  state.getIn(['hirePacketConfiguration']).pdfCustomDocumentPageInfo;
export const getEmployeeCustomDocument = state =>
  state.getIn(['hirePacketConfiguration']).employeeCustomDocument;
export const getIsFetching = state =>
  state.getIn(['hirePacketConfiguration']).isFetching;

export const getEmployeePacketTemplate = state =>
  state.getIn(['hirePacketConfiguration']).employeePacketTemplate;
export const getContractorPacketTemplate = state =>
  state.getIn(['hirePacketConfiguration']).contractorPacketTemplate;

export const getEmployerDetailsFulfilled = createSelector(
  getEmployerDetails,
  employerDetails => {
    const { ein, address_1, name, city, state, zip } = employerDetails;
    const fieldsFilled = !!(ein && address_1 && name && city && state && zip);
    return fieldsFilled;
  }
);
