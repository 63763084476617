import { BELONGS_TO } from 'fe-core/Entities/relationshipTypes';
import { createResource } from 'fe-core/Resources';

const RoleLaborTotals = createResource({
  name: 'roleLaborTotals',
  path: '/schedule_builder/labor',
  schema: {
    attributes: ['hours', 'has_overtime', 'wages', 'date'],
    relationships: {
      role: {
        relationshipType: BELONGS_TO,
        inverseOf: 'roleLaborTotals',
      },
      job: {
        relationshipType: BELONGS_TO,
        inverseOf: 'roleLaborTotals',
      },
    },
  },
});

export default RoleLaborTotals;
