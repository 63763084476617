import { postJSON } from 'api/fetch';

import { getCSRFToken } from 'util/redux';

const ENDPOINTS = {
  DOWNLOAD_APP_SMS: '/users/download_app_sms',
};

const resolveError = error =>
  error.response.json().then(body => {
    error.body = body;
    return Promise.reject(error);
  });

export const postDownloadAppSms = phone => {
  const payload = {
    phone,
    csrf_token: getCSRFToken(),
  };

  return postJSON(ENDPOINTS.DOWNLOAD_APP_SMS, payload).catch(resolveError);
};
