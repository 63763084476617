import React from 'react';
import Icon from 'react-icon-base';

const ScrollRight = ({ height, width, ...props }) => (
  <Icon {...props}>
    <svg
      width="48"
      height="48"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#626970" />
      <path
        d="M14.6569 11.5503C14.6569 11.1048 15.1954 10.8817 15.5104 11.1967L19.9602 15.6464C20.1554 15.8417 20.1554 16.1583 19.9602 16.3536L15.5104 20.8033C15.1954 21.1183 14.6569 20.8952 14.6569 20.4497L14.6569 11.5503Z"
        fill="white"
      />
    </svg>
  </Icon>
);

export default ScrollRight;
