import './AnimatedSuccessIcon.scss';

import React, { PureComponent } from 'react';

import cxHelpers from 'util/className';

export const SUCCESS_ANIMATION_DURATION = 2000;

@cxHelpers('AnimatedSuccessIcon')
export default class AnimatedSuccessIcon extends PureComponent {
  render() {
    return (
      <div className={this.cx()}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="-263.5 236.5 26 26"
        >
          <g className={this.cxEl('success')}>
            <circle
              className={this.cxEl('circle')}
              cx="-250.5"
              cy="249.5"
              r="12"
            />
            <path
              className={this.cxEl('path')}
              d="M-256.46 249.65l3.9 3.74 8.02-7.8"
            />
          </g>
        </svg>
      </div>
    );
  }
}
