import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { putJSON } from 'api/fetch';

import * as actions from 'actions/notifications';

import { toI18n } from 'util/i18n';

import AlertsListItem from '../AlertsListItem';

const ApprovedUserMergeRequest = ({ item, closeDrawer, readRequest }) => {
  const handleDismiss = useCallback(() => {
    putJSON(`/team/user_merge_requests/${item.get('id')}/read.json`).then(() =>
      readRequest(item)
    );
  }, [readRequest, item]);

  const handleEdit = useCallback(() => {
    handleDismiss();
    closeDrawer();
    window.Homebase.RailsReactBridge.navigateToReactView(
      `/team/${item.get('user_id')}/personal_information`
    );
  }, [handleDismiss, closeDrawer, item]);

  return (
    <AlertsListItem
      item={item}
      desc={toI18n('notifications.alerts.approved_user_merge_request.desc', {
        props: { name: item.get('name') },
      })}
      onEdit={handleEdit}
      onDismiss={handleDismiss}
      editButton={toI18n('actions.view')}
    />
  );
};

export default connect(() => ({}), {
  readRequest: actions.deleteAlert,
})(ApprovedUserMergeRequest);
