import React, { useCallback, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { SxProps } from '@mui/material/styles';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Text from 'fe-design-base/atoms/Text';
import { gutters } from 'fe-design-base/styles/utils';
import { useFormikContext } from 'formik';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { INPUT_BOX_SX } from './constants';
import FormHelperText from './FormHelperText';
import { FormFieldProps } from './index.type';

const FormField = ({
  name,
  label,
  fullWidth,
  width,
  labelWidth,
  shrink,
  labelPosition,
  labelTopShift,
  disabled,
  readOnly,
  inputId,
  children,
  uxElement,
  helperText,
  hasAsterisk,
  successText,
  cx,
  cxEl,
  isComponentResizable,
}: FormFieldProps & CxProps) => {
  const isLeftLabel = labelPosition === 'left';

  // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleFormControlClick = useCallback(e => {
    e.stopPropagation();
  }, []);

  const isRequired = hasAsterisk && !disabled && !readOnly;

  const formControlSx = useMemo<SxProps>(
    () => ({
      '&.FDBFormField': {
        width: fullWidth ? '100%' : width,
        flexShrink: shrink ? undefined : 0,
        display: 'flex',
        overflow: 'visible',
      },
    }),
    [fullWidth, shrink, width]
  );

  const formControlBoxSx = useMemo<SxProps>(
    () => ({
      '&.FDBFormField__form-control-box': {
        display: 'flex',
        flexDirection: isLeftLabel ? 'row' : 'column',
        alignItems: isLeftLabel ? 'center' : undefined,
        position: 'relative',
        width: '100%',
      },
    }),
    [isLeftLabel]
  );

  const labelSx = useMemo<SxProps>(() => {
    let topValue;

    if (isLeftLabel) {
      topValue = labelTopShift || '-10px';
    }

    return {
      '&.FDBFormField__label-wrapper': {
        height: (isLeftLabel && '40px') || undefined,
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        top: topValue,
      },
    };
  }, [labelTopShift, isLeftLabel]);

  const inputLabelSx = useMemo(
    () => ({
      '&.FDBFormField__input-label': {
        display: 'flex',
        marginRight: isLeftLabel ? gutters[32] : undefined,
        marginBottom: isLeftLabel ? undefined : gutters[8],
        width: isLeftLabel ? labelWidth : undefined,
      },
    }),
    [isLeftLabel, labelWidth]
  );

  const { touched, errors } = useFormikContext<Record<string, any>>();
  const validationErrors = !disabled && touched?.[name] && errors?.[name];

  const trackUx = useTrackUx(
    useMemo(
      () => ({
        element: uxElement,
      }),
      [uxElement]
    ) as any
  );
  useEffect(() => {
    if (validationErrors && uxElement)
      trackUx(EVENT_ACTIONS.ERROR_MESSAGE_SHOWN, TRACK_ACTION_TYPES.VIEW, {
        errorMsg: errors[name],
        field: label,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  return (
    <FormControl
      className={cx()}
      sx={formControlSx}
      onClick={handleFormControlClick}
    >
      <Box className={cxEl('form-control-box')} sx={formControlBoxSx}>
        {label && (
          <Box className={cxEl('label-wrapper')} sx={labelSx}>
            <InputLabel
              className={cxEl('input-label')}
              htmlFor={inputId}
              sx={inputLabelSx}
            >
              <Text
                variant="bodySmBold"
                color={disabled ? 'mono500' : 'mono700'}
                className={isRequired ? 'required' : undefined}
              >
                {label}
              </Text>
            </InputLabel>
          </Box>
        )}
        <Box display="flex" flexDirection="column" sx={INPUT_BOX_SX}>
          {children}
          <FormHelperText
            name={name}
            className={cxEl('form-helper-wrapper')}
            hasError={!!validationErrors}
            successText={successText}
            helperText={helperText}
            readOnly={readOnly}
            disabled={disabled}
            isComponentResizable={isComponentResizable}
          />
        </Box>
      </Box>
    </FormControl>
  );
};

export default withCx<FormFieldProps>('FDBFormField')(FormField);
