import React from 'react';
import Icon from 'react-icon-base';

const Referral = props => (
  <Icon viewBox="0 0 24 22" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9932 3.90039C19.3184 5.38672 21.6309 8.69141 21.6309 12.5312C21.6309 12.8184 21.6182 13.1016 21.5928 13.3828C23.0098 13.998 24.001 15.416 24.001 17.0625C24.001 19.2773 22.2119 21.0723 20.0049 21.0723C19.0967 21.0723 18.2598 20.7676 17.5889 20.2559C16.0225 21.3535 14.1074 22 12.0391 22C9.95312 22 8.02246 21.3418 6.44922 20.2266C5.77148 20.7559 4.91992 21.0723 3.99512 21.0723C1.78906 21.0723 0 19.2773 0 17.0625C0 15.3867 1.02637 13.9492 2.48242 13.3516C2.45898 13.082 2.44727 12.8086 2.44727 12.5312C2.44727 8.72461 4.72266 5.44141 8.00391 3.9375C8.04102 1.75586 9.81543 0 11.998 0C14.1699 0 15.9365 1.73633 15.9932 3.90039ZM16.417 18.8281C15.1719 19.6738 13.6641 20.168 12.0391 20.168C10.3887 20.168 8.8584 19.6582 7.60254 18.7891C7.85156 18.2656 7.99121 17.6797 7.99121 17.0625C7.99121 14.9609 6.37598 13.2363 4.32227 13.0684C4.30957 12.8906 4.30371 12.7109 4.30371 12.5312C4.30371 9.60547 5.96777 7.06641 8.41406 5.7832C9.06641 7.10742 10.4268 8.01758 11.998 8.01758C13.583 8.01758 14.9531 7.08984 15.5986 5.74805C18.0801 7.01953 19.7744 9.58008 19.7744 12.5312C19.7744 12.709 19.7676 12.8867 19.7559 13.0625C17.666 13.1914 16.0098 14.9336 16.0098 17.0625C16.0098 17.6973 16.1562 18.2949 16.417 18.8281Z"
    />
  </Icon>
);

export default Referral;
