import { fromJS } from 'immutable';

import { actionTypes } from 'actions/applicantMessenger';

const INITIAL_STATE = fromJS({
  applicationLoading: false,
  useLinkInHeader: false,
  applicationId: null,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SHOW_APPLICANT_MESSENGER:
      return state.merge({
        applicationId: action.payload.applicationId,
        useLinkInHeader: action.payload.useLinkInHeader,
      });

    case actionTypes.FETCH_APPLICATION_FOR_MESSENGER_REQUEST:
      return state.merge({ applicationLoading: true });

    case actionTypes.FETCH_APPLICATION_FOR_MESSENGER_SUCCESS:
    case actionTypes.FETCH_APPLICATION_FOR_MESSENGER_FAILURE:
      return state.merge({ applicationLoading: false });

    default:
      return state;
  }
};
