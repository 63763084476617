import React from 'react';
import Icon from 'react-icon-base';

const Message2 = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill={props.color || '#D0B6E0'}
      fillRule="evenodd"
      d="M15.92 11.543c0 .645-.8.945-1.225.459l-.927-1.064V6.432c0-2.766-1.724-4.337-4.394-4.39H3.352C3.828.842 4.827 0 6.084 0h6.759c1.697 0 3.077 1.531 3.077 3.413v8.13z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || '#D0B6E0'}
      d="M0 6.144V15.3c0 .587.679.912 1.136.545l1.856-1.493a3.491 3.491 0 0 1 2.187-.77h4.596a2.793 2.793 0 0 0 2.793-2.793V6.145a2.793 2.793 0 0 0-2.793-2.792H2.793A2.793 2.793 0 0 0 0 6.143z"
    />
  </Icon>
);

export default Message2;
