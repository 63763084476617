import { combineReducers } from 'redux';

import { reducer as entitiesReducer } from './Entities';
import {
  ENTITIES_SLICE,
  MODULE_NAME,
  RESOURCES_SLICE,
} from './module-constants';
import { reducer as resourcesReducer } from './Resources';

export const reducers = {
  [MODULE_NAME]: combineReducers({
    [ENTITIES_SLICE]: entitiesReducer,
    [RESOURCES_SLICE]: resourcesReducer,
  }),
};
