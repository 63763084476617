import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSettings = ({
  alt = 'Settings Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M23.246 7.81c.154.403.023.857-.296 1.153l-2.03 1.846a9.063 9.063 0 0 1 0 2.382l2.03 1.846c.32.296.45.75.296 1.154a11.898 11.898 0 0 1-.736 1.607l-.22.38c-.31.516-.657 1.003-1.037 1.467-.28.333-.736.45-1.148.32l-2.61-.835c-.63.483-1.365.886-2.063 1.195l-.586 2.677c-.094.421-.422.721-.854.834a12.24 12.24 0 0 1-3.984 0c-.431-.113-.76-.413-.853-.834l-.586-2.677a9.215 9.215 0 0 1-2.062-1.195l-2.61.834a1.07 1.07 0 0 1-1.148-.319c-.38-.464-.727-.951-1.037-1.467l-.22-.38a11.69 11.69 0 0 1-.739-1.607c-.152-.403-.024-.858.298-1.153l2.028-1.847a9.07 9.07 0 0 1 0-2.382L1.05 8.963a1.063 1.063 0 0 1-.298-1.154c.208-.557.456-1.096.74-1.607l.219-.38c.31-.516.656-1.003 1.037-1.465a1.065 1.065 0 0 1 1.148-.32l2.61.833a9.015 9.015 0 0 1 2.062-1.194L9.155 1c.094-.426.422-.764.853-.835a12.067 12.067 0 0 1 3.985 0c.43.071.759.41.853.835l.586 2.676c.698.306 1.434.71 2.062 1.194l2.611-.832a1.067 1.067 0 0 1 1.148.319c.38.462.727.95 1.036 1.465l.22.38c.282.51.53 1.05.737 1.607ZM12 15.75c2.072 0 3.75-1.678 3.75-3.792 0-2.03-1.678-3.75-3.75-3.75s-3.75 1.72-3.75 3.75c0 2.114 1.678 3.792 3.75 3.792Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSettings;
