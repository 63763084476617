import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';

import cxHelpers from 'util/className';

const ICONS = {
  requests: 'calendarCheck',
  alerts: 'bellO',
};

@cxHelpers('EmptyListItem')
export default class EmptyListItem extends PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
  };

  render() {
    const { type } = this.props;

    return (
      <Box className={this.cx()}>
        <Box mt48 paxs tcenter>
          <Icon size={48} type={ICONS[type]} color="blueLight" />
          <Box tcenter pt16>
            <Text fs16 grayXdark i18n={`notifications.${type}.empty_title`} />
          </Box>
          <Box tcenter pt12>
            <Text fs16 grayXdark i18n={`notifications.${type}.empty_desc`} />
          </Box>
        </Box>
      </Box>
    );
  }
}
