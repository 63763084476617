import { BENEFIT_TYPE_OPTIONS } from './constants';
export const formatContributionValue = (deduction, type) => {
  if (!deduction.get(`${type}_contribution_type`)) {
    return '--';
  }

  if (deduction.get(`${type}_contribution_type`) === 'amount') {
    return `$${deduction.get(`${type}_contribution`)}`;
  }

  return `${deduction.get(`${type}_contribution`)}%`;
};

export const getBenefitType = benefit =>
  BENEFIT_TYPE_OPTIONS.find(
    option => option.value === benefit.get('benefit_type')
  );
