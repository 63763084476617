import React, { useState } from 'react';
import Text from 'fe-design-base/atoms/Text';
import Alert from 'fe-design-base/molecules/Alert';
import Button from 'fe-design-base/molecules/Button';
import { lowerCase, partial } from 'lodash';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { EARNING_TYPE_MAP } from './constants.js';
import { getFrequencyType, getRecurringEarningType } from './utils.js';

const { cx, cxEl } = cxHelpers('RecurringEarning');

import { moment } from 'util/dateTime';

const RecurringEarning = ({
  recurring_earning,
  mobile,
  onClickEdit,
  onClickRemove,
  canEdit,
  showFormWithSteps,
}) => {
  const [alertCollapsed, setAlertCollapsed] = useState(false);

  return (
    <Box pl={24} pt={24} pr={24} bb className={cx()}>
      {!canEdit && !alertCollapsed && (
        <Box mb={24}>
          <Alert variant="info" onClose={partial(setAlertCollapsed, true)}>
            {toI18n(
              'team.employee_profile.recurring_earnings.delete_recurring_earning_explaination',
              {
                props: {
                  earning_type: lowerCase(
                    EARNING_TYPE_MAP[recurring_earning.get('earning_type')].name
                  ),
                },
              }
            )}
          </Alert>
        </Box>
      )}
      <Box row={!mobile} mb={mobile ? 0 : 32}>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
              lh={1.25}
              i18n="team.employee_profile.recurring_earnings.earning_type_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {getRecurringEarningType(recurring_earning).label || '--'}
            </Text>
          </Box>
        </Box>

        <Box grow={2} basis={0}>
          <Box mb12>
            <Text
              variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
              lh={1.25}
              i18n="team.employee_profile.recurring_earnings.name_label"
            />
          </Box>
          <Box>
            <Text variant="body" lh={1.25}>
              {recurring_earning.get('name') || '--'}
            </Text>
          </Box>
        </Box>

        <Box grow basis={0} tright>
          {canEdit && (
            <Box className={cxEl('edit-button')}>
              <Button
                onClick={onClickEdit}
                variant="secondary"
                size={showFormWithSteps ? 'small' : 'medium'}
                startIcon="Edit"
              >
                <Text i18n="actions.edit" />
              </Button>
            </Box>
          )}

          {!canEdit && (
            <Box className={cxEl('delete-button')}>
              <Button
                onClick={onClickRemove}
                variant="secondaryDestructive"
                size="medium"
                startIcon="Delete"
              >
                <Text i18n="actions.delete" />
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Box mb12 row>
        <Box grow basis={0} row mb={mobile ? 36 : 0}>
          <Box grow basis={0}>
            <Box mb12>
              <Text
                variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
                lh={1.25}
                i18n="team.employee_profile.recurring_earnings.amount_label"
              />
            </Box>
            <Box>
              <Text variant="body" lh={1.25}>
                ${numeral(recurring_earning.get('amount')).format('0,0.00')}
              </Text>
            </Box>
          </Box>

          <Box grow basis={0}>
            <Box mb12>
              <Text
                variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
                lh={1.25}
                i18n="team.employee_profile.recurring_earnings.start_date_label"
              />
            </Box>
            <Box>
              <Text variant="body" lh={1.25}>
                {recurring_earning.get('start_date')
                  ? moment(recurring_earning.get('start_date')).format(
                      'MMM D, YYYY'
                    )
                  : '--'}
              </Text>
            </Box>
          </Box>
          <Box grow basis={0}>
            <Box mb12>
              <Text
                variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
                lh={1.25}
                i18n="team.employee_profile.recurring_earnings.end_date_label"
              />
            </Box>
            <Box>
              <Text variant="body" lh={1.25}>
                {recurring_earning.get('end_date')
                  ? moment(recurring_earning.get('end_date')).format(
                      'MMM D, YYYY'
                    )
                  : '--'}
              </Text>
            </Box>
          </Box>

          <Box grow basis={0}>
            <Box mb12>
              <Text
                variant={showFormWithSteps ? 'bodySmBold' : 'bodyBold'}
                lh={1.25}
                i18n="team.employee_profile.recurring_earnings.frequency_type_label"
              />
            </Box>
            <Box>
              <Text variant="body" lh={1.25}>
                {getFrequencyType(recurring_earning).label || '--'}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

RecurringEarning.propTypes = {
  recurring_earning: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  canEdit: PropTypes.bool,
};

export default RecurringEarning;
