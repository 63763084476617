import * as Yup from 'yup';

import { toI18n } from 'util/i18n';

export const DRAWER_COMPONENTS_NAME = {
  CREATE_TIP_POOL_DRAWER: 'CREATE_TIP_POOL_DRAWER',
  EDIT_TIP_POOL_DRAWER: 'EDIT_TIP_POOL_DRAWER',
  CALCULATE_TIP_OUTS_DRAWER: 'CALCULATE_TIP_OUTS_DRAWER',
};

export const DEFAULT_IMPORT_FROM_POS = true;
export const DEFAULT_CALCULATION_METHOD = 'hours_worked';
export const DEFAULT_FREQUENCY = 'pay_period';

export const DEPARTMENT_NOT_SET = 'Dept. not set';

export const TIP_POOL_FORM_SCHEMA = Yup.object().shape({
  tipPoolName: Yup.string().required('Required field'),
});

export const infoStepListData = [
  {
    primary: toI18n(
      `tip_pooling_settings.tip_pool_drawer.info_step.point_1.primary`
    ),
    secondary: toI18n(
      `tip_pooling_settings.tip_pool_drawer.info_step.point_1.secondary`
    ),
  },
  {
    primary: toI18n(
      `tip_pooling_settings.tip_pool_drawer.info_step.point_2.primary`
    ),
    secondary: toI18n(
      `tip_pooling_settings.tip_pool_drawer.info_step.point_2.secondary`
    ),
  },
  {
    primary: toI18n(
      `tip_pooling_settings.tip_pool_drawer.info_step.point_3.primary`
    ),
    secondary: toI18n(
      `tip_pooling_settings.tip_pool_drawer.info_step.point_3.secondary`
    ),
  },
];
