import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Link from 'fe-design-base/molecules/Link';
import Toast from 'fe-design-base/molecules/Toast';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { actions as shopifyActions } from 'features/biller/implementations/shopify';

import { toI18n } from 'util/i18n';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUxHandler, withUxRoot } from 'util/uxEvents';

const ShopifySubscriptionChangeBanner = ({
  shopifyUrl,
  changeTierError,
  onCloseBanner,
}) => {
  const variant = changeTierError ? 'error' : 'success';
  const displayServerError = !shopifyUrl && Boolean(changeTierError);
  const shopifyLinkText = toI18n('biller.banner.shopify.link');

  const handleClose = useCallback(() => onCloseBanner(), [onCloseBanner]);

  const handlePageViewTracking = useTrackUxHandler(
    EVENT_ACTIONS.BANNER_VIEWED,
    TRACK_ACTION_TYPES.VIEW
  );

  const onCtaClicked = useTrackUxHandler(
    EVENT_ACTIONS.CLICK_CTA,
    TRACK_ACTION_TYPES.CLICK,
    { link_text: shopifyLinkText }
  );

  useEffect(() => {
    handlePageViewTracking();
  }, [handlePageViewTracking]);

  return (
    <Box>
      <Toast
        open
        autoHide
        onClose={handleClose}
        variant={variant}
        action={
          shopifyUrl && (
            <ShopifyUrlLink
              onClick={onCtaClicked}
              text={shopifyLinkText}
              shopifyUrl={shopifyUrl}
            />
          )
        }
      >
        {displayServerError
          ? changeTierError
          : toI18n(`biller.banner.shopify.toast.${variant}`)}
      </Toast>
    </Box>
  );
};

ShopifySubscriptionChangeBanner.propTypes = {
  shopifyUrl: PropTypes.string,
  changeTierError: PropTypes.string,
  onCloseBanner: PropTypes.func.isRequired,
};

const ShopifyUrlLink = ({ text, onClick, shopifyUrl }) => (
  <Link href={shopifyUrl} target="_blank" onClick={onClick}>
    {text}
  </Link>
);

ShopifyUrlLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  shopifyUrl: PropTypes.string.isRequired,
};

export default compose(
  connect(() => ({}), {
    onCloseBanner: shopifyActions.closeShopifyTierDowngradedBanner,
  }),
  withUxRoot({
    productArea: PRODUCT_AREAS.BILLING,
    event_category: EVENT_CATEGORIES.SHOPIFY_DOWNGRADE_BANNER,
  })
)(ShopifySubscriptionChangeBanner);
