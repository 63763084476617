import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Button from 'fe-design-base/molecules/Button';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { RESULT } from 'features/team/constants';
import { getPayrollFlowStep, getUxTracking } from 'features/teamView/tracking';

import { cxHelpers } from 'util/className';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { SCROLL_BEHAVIOR } from '../AddEmployeeForm/constants';

const { cx } = cxHelpers('DrawerFooter');

const DrawerFooter = ({
  buttonText,
  actionIsLoading,
  secondaryActionText,
  onClickSecondaryAction,
  currentLocationTierId,
  currentStep,
  isAdding,
  isAIOLocation,
  isPayrollEnrolled,
  payrollStep = false,
  showFormWithSteps,
}) => {
  let errors = {};
  let values = {};

  if (!showFormWithSteps) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ({ errors, values } = useFormikContext());
  }
  const trackUx = useTrackUx();

  const handleClick = useCallback(async () => {
    const keys = Object.keys(errors);

    if (keys.length) {
      if (payrollStep) {
        trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
          button_text: buttonText,
          result: RESULT.FAIL,
          add_team_payroll_flow_step: getPayrollFlowStep(currentStep),
        });
      } else {
        getUxTracking(
          values,
          isAdding,
          isAIOLocation,
          currentLocationTierId,
          RESULT.FAIL,
          false,
          isPayrollEnrolled,
          keys
        );
      }
      document.getElementsByName(keys[0])[0]?.scrollIntoView(SCROLL_BEHAVIOR);
    }
  }, [
    buttonText,
    currentLocationTierId,
    currentStep,
    errors,
    isAIOLocation,
    isAdding,
    isPayrollEnrolled,
    payrollStep,
    trackUx,
    values,
  ]);

  return (
    <Box hright vcenter h={64} bgcolor="mono0" ph={32} className={cx()}>
      <Box mr={16}>
        {secondaryActionText && (
          <Button onClick={onClickSecondaryAction} variant="secondary">
            {secondaryActionText}
          </Button>
        )}
      </Box>
      {buttonText && (
        <Button type="submit" isLoading={actionIsLoading} onClick={handleClick}>
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

DrawerFooter.propTypes = {
  actionIsLoading: PropTypes.bool,
  secondaryActionText: PropTypes.string,
  onClickSecondaryAction: PropTypes.func,
  buttonText: PropTypes.string,
  currentLocationTierId: PropTypes.number,
  currentStep: PropTypes.string,
  isAdding: PropTypes.bool,
  isAIOLocation: PropTypes.bool,
  isPayrollEnrolled: PropTypes.bool,
  payrollStep: PropTypes.bool,
};

export default DrawerFooter;
