import React, { Component } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import withCurrentRoute from 'components/hocs/withCurrentRoute';

export default activeRoutes => WrappedComponent => {
  @withCurrentRoute
  @connect((state, props) => ({
    routes:
      typeof activeRoutes === 'function'
        ? activeRoutes(state, props)
        : activeRoutes,
  }))
  class WithNavItemActive extends Component {
    static propTypes = {
      currentRoute: PropTypes.string,
      routes: PropTypes.array,
    };

    render() {
      const { currentRoute, ...props } = omit(this.props, 'routes');

      return (
        <WrappedComponent
          {...props}
          currentRoute={currentRoute}
          active={this.props.routes.some(route =>
            currentRoute.startsWith(route)
          )}
        />
      );
    }
  }

  return WithNavItemActive;
};
