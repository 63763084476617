import { capitalize } from 'lodash';

const isUppercase = string => string === string.toUpperCase();

const capitalizeWord = substring =>
  isUppercase(substring) ? substring : capitalize(substring);

export const titleize = string =>
  string
    .split(/[_\s]/)
    .map(substring => substring.split(/[/]/).map(capitalizeWord).join('/'))
    .join(' ');

// Converts "true"/"false" to true/false
export const stringBoolToBool = str => str === 'true';

export const ordinalSuffix = n => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const remainder = n % 100;

  return n + (suffix[(remainder - 20) % 10] || suffix[remainder] || suffix[0]);
};

export const stripTags = str => str.replace(/(<([^>]+)>)/gi, '');
