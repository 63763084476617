import { putJSON } from 'api/fetch';

const ENDPOINTS = {
  ENROLL: '/experiment/enroll',
};

const resolveError = error =>
  error.response.json().then(body => {
    error.body = body;
    return Promise.reject(error);
  });

export const putEnrollEntityInExperiment = experimentNames => {
  const payload = { experiments: { names: experimentNames } };

  return putJSON(ENDPOINTS.ENROLL, payload).catch(resolveError);
};
