export enum ButtonDataMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum ButtonTarget {
  BLANK = '_blank',
  PARENT = '_parent',
  TOP = '_top',
  SELF = '_self',
}
