import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSuccess = ({
  alt = 'Success Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M23.498 3.528c.67.703.67 1.84 0 2.543L9.783 20.473a1.654 1.654 0 0 1-2.422 0L.502 13.272a1.86 1.86 0 0 1 0-2.543 1.657 1.657 0 0 1 2.425 0l5.597 5.924L21.076 3.528a1.652 1.652 0 0 1 2.422 0Z"
    />
  </svg>
);
export default SvgSuccess;
