import './HoursProvidedDialog.scss';

import { useCallback, useState } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import Dialog from 'fe-design-base/organisms/Dialog';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import { useExtendUx, withUxRoot } from 'util/uxEvents';

import SocialSharingDialog from '../SocialSharingDialog';

const { cx, cxEl } = cxHelpers('HoursProvidedDialog');

const { HOURS_PROVIDED } = EVENT_CATEGORIES;
const { SCHEDULE } = PRODUCT_AREAS;

export interface HoursProvidedDialogProps {
  onClose: () => void;
  fromUrl?: boolean;
}

const HoursProvidedDialog = ({
  fromUrl,
  onClose,
}: HoursProvidedDialogProps) => {
  const extendUx = useExtendUx();

  const [openSharingDialog, setOpenSharingDialog] = useState(false);

  extendUx({ fromUrl });

  const handleOpenSharingDialog = useCallback(() => {
    setOpenSharingDialog(true);
  }, []);

  const handleCloseSharingDialog = useCallback(() => {
    setOpenSharingDialog(false);
  }, []);

  const handleOnClose = useCallback(() => {
    handleCloseSharingDialog();
    onClose();
  }, [handleCloseSharingDialog, onClose]);

  return (
    <>
      <Dialog
        open
        noPadding
        fullScreen
        disableBackdropClick
        className={cx()}
        bgcolor="purple900"
        onClose={handleOnClose}
        uxElement="hours provided milestone dialog"
      >
        {() => (
          <Box color="mono0">
            <Box
              relative
              justifyContentCenter
              width="100%"
              height="100%"
              overflow="hidden"
            >
              <video autoPlay muted className={cxEl('video-background')}>
                <source
                  type="video/webm"
                  src={require('../assests/10K-replay.webm')}
                />
              </video>
            </Box>

            <Box
              absolute
              justifyContentCenter
              top={0}
              left={0}
              right={0}
              bottom={0}
            >
              <Box className={cxEl('video-overlay-container')}>
                <Box alignItemsEnd>
                  <Text
                    variant="heading1"
                    i18n="growth.hours_provided.title.first_section"
                  />
                </Box>
                <Box alignItemsEnd>
                  <Text
                    variant="heading1"
                    i18n="growth.hours_provided.title.second_section"
                  />
                </Box>
                <Box mt={32}>
                  <Button
                    onClick={handleOpenSharingDialog}
                    uxElement="share milestone"
                  >
                    {toI18n('growth.hours_provided.share')}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Dialog>

      {openSharingDialog && (
        <SocialSharingDialog onClose={handleCloseSharingDialog} />
      )}
    </>
  );
};

export default withUxRoot({
  productArea: SCHEDULE,
  eventCategory: HOURS_PROVIDED,
})(HoursProvidedDialog);
