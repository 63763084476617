import React from 'react';
import Icon from 'react-icon-base';

const Bell = props => (
  <Icon viewBox="0 0 80 81" {...props}>
    <use fill="#ECECEC" transform="translate(7 4)" xlinkHref="#bell-a" />
    <defs>
      <path
        id="bell-a"
        d="M32.5 72c-5.318 0-9.75-3.884-10.34-9.261H6.5c-3.545 0-6.5-2.988-6.5-6.573 0-3.585 2.955-6.573 6.5-6.573.59 0 1.182-.597 1.182-1.195V32.863c0-11.054 7.09-20.614 17.432-23.9V7.469C25.114 3.286 28.364 0 32.5 0s7.386 3.286 7.386 7.469v1.494c10.341 3.286 17.432 12.846 17.432 23.9v15.535c0 .598.591 1.195 1.182 1.195 3.545 0 6.5 2.988 6.5 6.573 0 3.585-2.955 6.573-6.5 6.573H43.136C41.955 68.116 37.818 72 32.5 72zm-7.386-8.963c.59 3.585 3.84 6.274 7.386 6.274 3.545 0 6.795-2.689 7.386-6.274H25.114z"
      />
    </defs>
  </Icon>
);

export default Bell;
