import { useId } from 'react';
import FormField, { FormFieldProps } from 'fe-design-base/atoms/FormField';
import PhoneInput, { PhoneInputProps } from 'fe-design-base/atoms/PhoneInput';
import { Field, FormikValues, useFormikContext } from 'formik';

import { toI18n } from 'util/i18n';

export type PhoneFieldProps = Omit<FormFieldProps, 'children' | 'label'> &
  Omit<PhoneInputProps, 'value' | 'onChange'>;

const PhoneField = ({
  name,
  inputId,
  label = toI18n('fe_design_base.label.phone'),
  width,
  fullWidth,
  labelPosition,
  labelWidth,
  shrink,
  disabled,
  hasAsterisk,
  helperText,
  successText,
  ...inputProps
}: PhoneFieldProps) => {
  const { touched, errors } = useFormikContext<FormikValues>();
  const error = touched?.[name] && errors?.[name] && !disabled;
  const fieldId = useId();

  return (
    <FormField
      label={label}
      name={name}
      inputId={inputId || fieldId}
      width={width}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
      labelWidth={labelWidth}
      shrink={shrink}
      disabled={disabled}
      uxElement={inputProps.uxElement}
      hasAsterisk={hasAsterisk}
      helperText={helperText}
      successText={successText}
    >
      <Field
        as={PhoneInput}
        name={name}
        id={inputId || fieldId}
        disabled={disabled}
        fullWidth={fullWidth}
        error={error}
        label={toI18n('fe_design_base.label.phone')}
        {...inputProps}
      />
    </FormField>
  );
};

export default PhoneField;
