import React, { useCallback, useEffect, useState } from 'react';
import { fetchJSON } from 'api/fetch';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import NumberField from 'fe-design-base/molecules/NumberField';
import SelectField from 'fe-design-base/molecules/SelectField';
import TextField from 'fe-design-base/molecules/TextField';
import FDBDatePickerField from 'fe-design-base/organisms/FDBDatePickerField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';
import { df, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import {
  CONTRACTOR_EARNING_TYPE_OPTIONS,
  EARNING_TYPE_MAP,
  EARNING_TYPE_OPTIONS,
  FREQUENCY_TYPE_MAP,
  FREQUENCY_TYPE_OPTIONS,
} from './constants.js';
import RecurringEarningChipGroup from './RecurringEarningChipGroup';

const { cxEl } = cxHelpers('RecurringEarningFormFields');

const RecurringEarningFormFields = ({
  initialValues,
  onClickRemove,
  canDelete = false,
  isRemoveButtonLoading = false,
  isContractor,
  payPeriodStart,
  currentDate,
  showFormWithSteps,
}) => {
  const [currentYear, setCurrentYearObj] = useState(moment().year());

  const [fetchedYears, setFetchedYears] = useState({});
  const [payrollPeriodStartDates, setPayrollPeriodStartDates] = useState({});
  const [payrollPeriodEndDates, setPayrollPeriodEndDates] = useState({});
  const [monthStartDates, setMonthStartDates] = useState({});
  const [monthEndDates, setMonthEndDates] = useState({});
  const { setFieldValue, values } = useFormikContext();

  const handleFrequencyTypeChipSelection = useCallback(
    chip => {
      setFieldValue('frequency_type', chip.value);
      setFieldValue('start_date', undefined);
      setFieldValue('end_date', undefined);
    },
    [setFieldValue]
  );

  useEffect(
    () => {
      if (fetchedYears[currentYear] || currentYear < payPeriodStart.year()) {
        return;
      }
      setFetchedYears({ ...fetchedYears, [currentYear]: true });
      let startAt;
      if (currentYear === payPeriodStart.year()) {
        startAt = payPeriodStart.format(df('parsable_date'));
      } else {
        startAt = moment(currentYear, 'YYYY')
          .startOf('year')
          .format(df('parsable_date'));
      }
      const endAt = moment(currentYear, 'YYYY')
        .endOf('year')
        .format(df('parsable_date'));

      fetchJSON(
        `/locations/payroll_periods?start_date=${startAt}&end_date=${endAt}`
      ).then(response => {
        const newPayrollStartDates = response.reduce((memo, period) => {
          memo[period.start_date] = true;
          return memo;
        }, payrollPeriodStartDates);
        const newPayrollEndDates = response.reduce((memo, period) => {
          memo[period.end_date] = true;
          return memo;
        }, payrollPeriodEndDates);

        const newMonthEndDates = {};
        const newMonthStartDates = {};
        const currentMonth = moment(startAt);
        while (currentMonth.isBefore(endAt)) {
          newMonthEndDates[
            currentMonth.endOf('month').format(df('parsable_date'))
          ] = true;
          newMonthStartDates[
            currentMonth.startOf('month').format(df('parsable_date'))
          ] = true;
          currentMonth.add(1, 'month');
        }
        setMonthEndDates({ ...monthEndDates, ...newMonthEndDates });
        setMonthStartDates({ ...monthStartDates, ...newMonthStartDates });
        setPayrollPeriodStartDates({ ...newPayrollStartDates });
        setPayrollPeriodEndDates({ ...newPayrollEndDates });
      });
    },
    // do not add 'payrollPeriodEndDates' and 'payrollPeriodStartDates' to dependencies!
    // it will send infinite requests to the server
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentYear, payPeriodStart, fetchedYears, monthEndDates, monthStartDates]
  );

  const handleIsOutsideRange = useCallback(
    day => {
      if (values.frequency_type === 'every_payroll_period') {
        return !payrollPeriodStartDates[day.format(df('parsable_date'))];
      } else if (values.frequency_type === 'monthly') {
        return !monthStartDates[day.format(df('parsable_date'))];
      }
      return true;
    },
    [payrollPeriodStartDates, monthStartDates, values]
  );

  const handleIsOutsideRangeEndDate = useCallback(
    day => {
      if (values.frequency_type === 'every_payroll_period') {
        return !payrollPeriodEndDates[day.format(df('parsable_date'))];
      } else if (values.frequency_type === 'monthly') {
        return !monthEndDates[day.format(df('parsable_date'))];
      }
      return true;
    },

    [payrollPeriodEndDates, monthEndDates, values]
  );

  const handleChangeMonth = useCallback(
    month => {
      if (month.year() >= payPeriodStart.year()) {
        setCurrentYearObj(month.year());
      }
    },
    [payPeriodStart]
  );

  return (
    <Box pb20 bb mb20>
      <Box row mb32>
        <Box grow>
          <Box maxw={400}>
            {initialValues.earning_type === undefined && (
              <SelectField
                name="earning_type"
                label={toI18n(
                  'team.employee_profile.recurring_earnings.earning_type_label'
                )}
                options={
                  isContractor
                    ? CONTRACTOR_EARNING_TYPE_OPTIONS
                    : EARNING_TYPE_OPTIONS
                }
                placeholder={toI18n(
                  'team.employee_profile.recurring_earnings.choose_frequency_type_label'
                )}
                helperText={
                  initialValues.earning_type === undefined
                    ? toI18n(
                        'team.employee_profile.recurring_earnings.earning_type_description_label'
                      )
                    : null
                }
              />
            )}

            {initialValues.earning_type !== undefined && (
              <>
                <Box mb={8} vcenter>
                  <Text color="mono700" variant="bodySmBold">
                    {toI18n(
                      'team.employee_profile.recurring_earnings.earning_type_label'
                    )}
                  </Text>
                </Box>
                <Text>{EARNING_TYPE_MAP[initialValues.earning_type].name}</Text>
              </>
            )}
          </Box>
        </Box>

        <Box w={24} />
        <Box grow>
          <Box maxw={400}>
            <TextField
              name="name"
              label={toI18n(
                'team.employee_profile.recurring_earnings.name_optional_label'
              )}
              helperText={toI18n(
                'team.employee_profile.recurring_earnings.name_description_label'
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box row>
        <Box grow>
          <Box maxw={400}>
            <Box>
              <Box mb={8} vcenter>
                <Text color="mono700" variant="bodySmBold">
                  {toI18n(
                    'team.employee_profile.recurring_earnings.frequency_type_label'
                  )}
                </Text>
              </Box>

              {initialValues.id === undefined && (
                <RecurringEarningChipGroup
                  chipOptions={FREQUENCY_TYPE_OPTIONS}
                  onClick={handleFrequencyTypeChipSelection}
                  selectedValue={values.frequency_type}
                />
              )}

              {initialValues.id !== undefined && (
                <Text>
                  {FREQUENCY_TYPE_MAP[initialValues.frequency_type].name}
                </Text>
              )}
            </Box>
          </Box>
        </Box>

        <Box w={24} />
        <Box grow>
          <Box row maxw={400}>
            <Box grow>
              <Box>
                <Box>
                  <FDBDatePickerField
                    name="start_date"
                    onMonthChange={handleChangeMonth}
                    shouldDisableDates={handleIsOutsideRange}
                    label={toI18n(
                      'team.employee_profile.recurring_earnings.start_date_label'
                    )}
                    readOnly={
                      !(
                        initialValues.start_date === undefined ||
                        (initialValues.start_date &&
                          moment(initialValues.start_date) >= currentDate)
                      )
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box w={16} />
            <Box grow>
              <Box>
                <Box>
                  <FDBDatePickerField
                    name="end_date"
                    onMonthChange={handleChangeMonth}
                    shouldDisableDates={handleIsOutsideRangeEndDate}
                    label={toI18n(
                      'team.employee_profile.recurring_earnings.end_date_optional_label'
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {initialValues.frequency_type === undefined && (
            <Box>
              <Text
                fs14
                fw400
                lh={1.25}
                navy
                i18n="team.employee_profile.recurring_earnings.frequency_type_description_label"
              />
            </Box>
          )}
        </Box>
      </Box>

      <Box row mb32>
        <Box grow>
          <Box maxw={190}>
            <NumberField
              name="amount"
              label={toI18n(
                'team.employee_profile.recurring_earnings.amount_label'
              )}
              prefix="$"
            />
          </Box>
        </Box>
        <Box vbottom hright grow>
          {canDelete && (
            <Box className={cxEl('remove-recurring-earning-button')}>
              <Button
                size={showFormWithSteps ? 'small' : 'medium'}
                variant="secondaryDestructive"
                onClick={onClickRemove}
                isLoading={isRemoveButtonLoading}
                startIcon="Delete"
              >
                {toI18n(
                  'team.employee_profile.recurring_earnings.delete_recurring_earning',
                  {
                    props: {
                      earning_type:
                        EARNING_TYPE_MAP[initialValues.earning_type].name,
                    },
                  }
                )}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

RecurringEarningFormFields.propTypes = {
  values: PropTypes.object,
  onClickRemove: PropTypes.func,
  canDelete: PropTypes.bool,
};

export default RecurringEarningFormFields;
