import React, { memo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Dialog from 'fe-design-base/organisms/Dialog';
import PropTypes from 'prop-types';

export const ExitModal = ({
  isOpen,
  title,
  description,
  continueButton,
  discardButton,
  onClose,
  onDiscard,
}) =>
  isOpen ? (
    <Dialog
      open={isOpen}
      width={500}
      title={title}
      onClose={onClose}
      buttons={[
        {
          label: continueButton,
          onClick: onClose,
        },
        {
          label: discardButton,
          onClick: onDiscard,
          variant: 'secondary',
        },
      ]}
    >
      {() => (
        <Box pb={22}>
          <Text variant="body" color="mono700">
            {description}
          </Text>
        </Box>
      )}
    </Dialog>
  ) : null;

ExitModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
};

export default memo(ExitModal);
