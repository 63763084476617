import createCachedSelector from 're-reselect';

import { getAllColumns } from './timesheets';

export const getColumnVisibility = createCachedSelector(
  getAllColumns,
  (state, key) => key,
  (columnsData, key) => {
    if (columnsData && columnsData.size > 0) {
      return columnsData
        .find(columns => columns.get('key') === key)
        .get('visible');
    }

    return true;
  }
)((state, key) => key);
