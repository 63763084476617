export const SLICE_NAME = 'stripe';
export const SINGLE_TRANSACTION = 'single_transaction';

export const partnerMap = {
  craigslist: 'Craigslist',
  zip_recruiter: 'ZipRecruiter',
};

export const STATUS_REQUIRES_ACTION = 'requires_action';
export const STATUS_SUCCEEDED = 'succeeded';
