import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCamera = ({
  alt = 'Camera Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M9.122 1.5h5.756c.97 0 1.828.62 2.133 1.538l.487 1.462H21c1.655 0 3 1.345 3 3v12c0 1.655-1.345 3-3 3H3c-1.657 0-3-1.345-3-3v-12c0-1.655 1.343-3 3-3h3.502l.487-1.462A2.245 2.245 0 0 1 9.122 1.5ZM12 18c2.484 0 4.5-2.016 4.5-4.5 0-2.527-2.016-4.5-4.5-4.5-2.527 0-4.5 1.973-4.5 4.5 0 2.484 1.973 4.5 4.5 4.5Z"
    />
  </svg>
);
export default SvgCamera;
