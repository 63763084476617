import React from 'react';
import Icon from 'react-icon-base';

const Martini = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M23.321 10.393c.625-.625.179-1.727-.714-1.727H9.363c-.893 0-1.34 1.102-.714 1.727l6.399 6.369v5.714H13.38c-.685 0-1.19.536-1.19 1.19 0 .15.089.239.237.239h7.143c.12 0 .238-.09.238-.239 0-.654-.535-1.19-1.19-1.19h-1.667v-5.714l6.37-6.37zm-1.756-.298l-1.428 1.429h-8.304l-1.428-1.429h11.16z"
    />
  </Icon>
);

export default Martini;
