import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgWarehouse = ({
  alt = 'Warehouse Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M.415 20.569V8.816c0-.968.627-1.844 1.582-2.204l10.535-3.976c.3-.114.637-.114.936 0l10.537 3.976c.955.36 1.58 1.236 1.58 2.204V20.57c0 .493-.42.89-.943.89h-1.888c-.523 0-.944-.397-.944-.89v-9.83c0-.624-.562-1.188-1.258-1.188H5.449c-.696 0-1.258.564-1.258 1.187v9.83c0 .494-.423.891-.944.891H1.359c-.521 0-.944-.397-.944-.89Zm19.193-3.86c.523 0 .944.397.944.89v2.97c0 .493-.421.89-.944.89h-4.405c-.523 0-.944-.397-.944-.89v-2.97c0-.493.42-.89.944-.89h4.405ZM5.449 11.63c0-.46.421-.89.944-.89h5.663c.524 0 .944.43.944.89v3.002c0 .494-.42.89-.944.89H6.393c-.523 0-.944-.396-.944-.89V11.63Zm6.607 5.08c.524 0 .944.397.944.89v2.97c0 .493-.42.89-.944.89H6.393c-.523 0-.944-.397-.944-.89V17.6c0-.493.421-.89.944-.89h5.663Z"
    />
  </svg>
);
export default SvgWarehouse;
