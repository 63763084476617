import { OrderedMap } from 'immutable';

import { getIsFetching } from 'selectors/fetching';

const getItemsSubset = (state, key) =>
  state.getIn(['notifications', 'items', key], OrderedMap());

export const getAlertsItems = state => getItemsSubset(state, 'alertsItems');

export const getRequestsItems = state => getItemsSubset(state, 'requestsItems');

export const getRequestItem = (id, type) => state =>
  state.getIn(['notifications', 'items', 'requestItems', `${type}__${id}`]);

export const getIsFetchingNotifications = state =>
  getIsFetching(state, { requestId: 'FETCH_NOTIFICATIONS' });

export const getHasMoreRequests = state =>
  state.getIn(['notifications', 'items', 'hasMoreRequests']);

export const getHasMoreAlerts = state =>
  state.getIn(['notifications', 'items', 'hasMoreAlerts']);
