import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorFigma = ({
  alt = 'Figma Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M11.417 29.499A4.585 4.585 0 0 0 16 24.915v-4.583h-4.583a4.585 4.585 0 0 0-4.583 4.583 4.585 4.585 0 0 0 4.583 4.584Z"
      fill="#0ACF83"
    />
    <path
      d="M6.833 15.751a4.585 4.585 0 0 1 4.584-4.583H16v9.167h-4.583a4.585 4.585 0 0 1-4.583-4.584Z"
      fill="#A259FF"
    />
    <path
      d="M6.833 6.583A4.585 4.585 0 0 1 11.418 2H16v9.167h-4.583a4.585 4.585 0 0 1-4.583-4.584Z"
      fill="#F24E1E"
    />
    <path
      d="M16 2h4.583a4.585 4.585 0 0 1 4.584 4.583 4.585 4.585 0 0 1-4.584 4.584H16V2Z"
      fill="#FF7262"
    />
    <path
      d="M25.167 15.751a4.585 4.585 0 0 1-4.584 4.584A4.585 4.585 0 0 1 16 15.75a4.585 4.585 0 0 1 4.583-4.583 4.585 4.585 0 0 1 4.584 4.583Z"
      fill="#1ABCFE"
    />
  </svg>
);
export default SvgColorFigma;
