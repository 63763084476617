import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isInteger } from 'lodash';

import { showAllInOneInformationModal } from 'actions/modals';

import { getCurrentLocationAioRetrialDaysLeft } from 'selectors/session';

import Box from 'components/Box';
import { Mobile } from 'components/MediaQuery';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('AllInOneNavItem');

// This Calendly URL is deprecated. Use Calendly Enterprise instead.
const CALENDLY_URL =
  'https://calendly.com/homebaseteam/explore-homebase-aio/?utm_medium=app&utm_source=product&utm_campaign=aio_retrial_modal';

const AllInOneNavItem = ({ onShowAllInOneInformationModal, daysLeft }) => {
  const renderWebView = () => (
    <Box
      bg="rgba(255, 255, 255, 0.1)"
      w={222}
      h={32}
      bradius="4px"
      vcenter
      hcenter
    >
      <Text
        white
        fs14
        fw600
        i18n="all_in_one_trial.nav_item"
        i18nProps={{ count: daysLeft }}
      />
    </Box>
  );

  const renderMobileView = () => (
    <Text fs16>
      <FontAwesomeIcon icon={faStar} color="white" />
    </Text>
  );

  const handleScheduleDemo = useCallback(
    () => window.open(CALENDLY_URL, '_blank'),
    []
  );

  const handleShowAllInOneInformationModal = useCallback(
    () =>
      onShowAllInOneInformationModal({
        daysLeft,
        onScheduleDemo: handleScheduleDemo,
      }),
    [onShowAllInOneInformationModal, handleScheduleDemo, daysLeft]
  );

  if (isInteger(daysLeft) && daysLeft >= 0) {
    return (
      <Box
        onClick={handleShowAllInOneInformationModal}
        pointer
        className={cx()}
      >
        <Mobile>
          {mobile => (mobile ? renderMobileView() : renderWebView())}
        </Mobile>
      </Box>
    );
  }
  return null;
};

export default connect(
  state => ({
    daysLeft: getCurrentLocationAioRetrialDaysLeft(state),
  }),
  { onShowAllInOneInformationModal: showAllInOneInformationModal }
)(AllInOneNavItem);
