import React from 'react';
import Icon from 'react-icon-base';

const CalendarV2 = props => (
  <Icon viewBox="0 0 17 17" {...props}>
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9484 15.6749H10.7381L15.1752 11.7518V13.3096C15.1752 14.614 14.1763 15.6749 12.9484 15.6749ZM14.4989 11.2491L10.0618 15.1723V12.3365C10.0618 11.7367 10.5427 11.2491 11.1339 11.2491H14.4989ZM0.824778 13.3097V6.60287H15.1753V10.4244H11.1341C10.0883 10.4244 9.23715 11.2821 9.23715 12.3365V15.675H3.05158C1.82371 15.675 0.824778 14.6141 0.824778 13.3097ZM3.05158 3.30394H3.29901V4.21116C3.29901 4.80234 3.77999 5.28333 4.37117 5.28333C4.96235 5.28333 5.44334 4.80234 5.44334 4.21116V3.30394H10.5567V4.21116C10.5567 4.80234 11.0377 5.28333 11.6289 5.28333C12.2201 5.28333 12.7011 4.80234 12.7011 4.21116V3.30394H12.9485C14.1764 3.30394 15.1753 4.36489 15.1753 5.66931V5.77817H0.824778V5.66931C0.824778 4.36489 1.82371 3.30394 3.05158 3.30394ZM4.12389 1.57206C4.12389 1.43812 4.23737 1.32464 4.37131 1.32464C4.50525 1.32464 4.61873 1.43812 4.61873 1.57206V4.21124C4.61873 4.34517 4.50525 4.45866 4.37131 4.45866C4.23737 4.45866 4.12389 4.34517 4.12389 4.21124V1.57206ZM11.3813 1.57206C11.3813 1.43812 11.4948 1.32464 11.6287 1.32464C11.7627 1.32464 11.8762 1.43812 11.8762 1.57206V4.21124C11.8762 4.34517 11.7627 4.45866 11.6287 4.45866C11.4948 4.45866 11.3813 4.34517 11.3813 4.21124V1.57206ZM12.9485 2.47938H12.701V1.57216C12.701 0.98099 12.22 0.5 11.6289 0.5C11.0377 0.5 10.5567 0.98099 10.5567 1.57216V2.47938H5.4433V1.57216C5.4433 0.98099 4.96231 0.5 4.37113 0.5C3.77996 0.5 3.29897 0.98099 3.29897 1.57216V2.47938H3.05155C1.36907 2.47938 0 3.91047 0 5.66948V13.3099C0 15.0689 1.36907 16.5 3.05155 16.5H12.9485C14.6309 16.5 16 15.0689 16 13.3099V5.66948C16 3.91047 14.6309 2.47938 12.9485 2.47938Z"
        fill="#969BA0"
      />
    </svg>
  </Icon>
);

export default CalendarV2;
