import './GeosuggestInput.scss';

import React, { PureComponent } from 'react';
import ReactGeosuggest from 'react-geosuggest-old';
import loadGoogleMaps from 'fe-core/util/loadGoogleMaps';
import { keys, omit } from 'lodash';
import PropTypes from 'prop-types';

import cxHelpers from 'util/className';

const PROP_TYPES = {
  onSuggestSelect: PropTypes.func.isRequired,
  onInputLoaded: PropTypes.func,
  getRef: PropTypes.func,
  maxHeight: PropTypes.number,
  marginTop: PropTypes.number,
  inputMarginTop: PropTypes.number,
  isGoogleMapsApiLoaded: PropTypes.bool,
};

const PROP_TYPE_KEYS = keys(PROP_TYPES);

@cxHelpers('GeosuggestInput')
export default class GeosuggestInput extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    initialValue: PropTypes.string,
    onSuggestSelect: PropTypes.func.isRequired,
    onInputLoaded: PropTypes.func,
    getRef: PropTypes.func,
    maxHeight: PropTypes.number,
    marginTop: PropTypes.number,
    inputMarginTop: PropTypes.number,
  };

  state = { mapsApi: null };

  componentDidMount() {
    loadGoogleMaps(api => {
      this.setState({ mapsApi: api });
      if (this.props.onInputLoaded) this.props.onInputLoaded();
    });
  }

  // apparently used in textfield.js
  getInputNode() {
    return this.input;
  }

  handleChange = value => {
    const e = { target: { name: this.props.name, value } };
    this.props.onChange(e);
  };

  handleSuggestSelect = suggest => {
    this.props.onSuggestSelect(suggest);
  };

  handleRef = input => {
    this.input = input;
    if (this.props.getRef) {
      this.props.getRef(input);
    }
  };

  styles = () => {
    const { maxHeight, marginTop } = this.props;
    const styles = {};

    if (maxHeight !== undefined) {
      styles.suggests = { maxHeight };
    }
    if (marginTop !== undefined) {
      styles.suggests = { ...styles.suggests, marginTop };
    }

    return styles;
  };

  render() {
    const geosuggestProps = omit(this.props, PROP_TYPE_KEYS);

    return (
      (this.state.mapsApi && (
        <ReactGeosuggest
          {...geosuggestProps}
          className={this.cx()}
          ref={this.handleRef}
          onChange={this.handleChange}
          onSuggestSelect={this.handleSuggestSelect}
          googleMaps={this.state.mapsApi}
          style={this.styles()}
        />
      )) || (
        // While waiting for google maps to load, we want to render a regular input for MUI to use.
        // Without this, MUI seems to not finish rendering the input and it doesn't seem to work
        // until the component is mounted again.
        <input
          className={this.cx()}
          name={name}
          onChange={this.handleChange}
          ref={this.handleRef}
          style={this.styles()}
        />
      )
    );
  }
}
