import { trim } from 'lodash';

export const validatePtoPolicyDetailsForm = form => {
  const name = form.get('name');
  if (!name || !trim(name)) {
    return false;
  }

  const accrualMethod = form.get('accrual_method');

  if (accrualMethod === 'annual') {
    const totalHours = Number(form.get('total_hours'));

    if (!totalHours) {
      return false;
    }
  } else {
    const hoursEarned = Number(form.get('hours_earned'));
    const hoursWorked = Number(form.get('hours_worked'));

    if (!hoursEarned || !hoursWorked) {
      return false;
    }
  }

  return true;
};

// list items must have a user_id attribute
export const mergeAndSortByAvatarData = (list, users) =>
  list
    .valueSeq()
    .filter(item => users.has(item.get('user_id').toString()))
    .map(item => {
      const user = users.get(item.get('user_id').toString());

      return item.merge({
        avatar: user.get('avatar'),
        name: user.get('name'),
      });
    })
    .sortBy(item => item.get('name').split(' ').pop())
    .toList();
