import React from 'react';
import Icon from 'react-icon-base';

const TradeShift = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <use fill="#313A43" xlinkHref="#a-trade" />
    <defs>
      <path
        id="a-trade"
        d="M4.333 16c-.333 0-.6-.139-.8-.486L.267 11.282c-.2-.277-.267-.555-.267-.902 0-.346.067-.624.267-.901L3.6 5.316c.2-.347.6-.555.933-.416.4.07.734.485.8 1.249v1.526h6v2.15c0 .347.134.556.267.556.067 0 .2 0 .267-.14L15.2 6.08c.067-.139.133-.347.133-.485 0-.209-.066-.347-.133-.486l-3.267-4.093c-.133-.208-.2-.278-.333-.278s-.267.208-.267.625v2.15H7.667c-.2 0-.334-.138-.334-.347 0-.208.134-.346.334-.346h3V1.292c.066-.763.4-1.18.8-1.249.4-.138.8.07 1 .417l3.266 4.093c.2.347.267.694.267 1.04 0 .347-.067.625-.267.902L12.4 10.658c-.2.347-.6.486-1 .416-.333-.07-.733-.485-.8-1.248V8.369H4.667v-2.22c0-.347-.134-.555-.267-.555-.067 0-.2 0-.267.138L.8 9.895c-.067.139-.133.277-.133.486 0 .208.066.346.133.485l3.267 4.232c.133.208.2.278.333.208.133 0 .267-.208.267-.555v-2.22h3.666c.2 0 .334.139.334.347 0 .208-.134.347-.334.347h-3v1.526c-.066.763-.4 1.18-.8 1.249h-.2z"
      />
    </defs>
  </Icon>
);

export default TradeShift;
