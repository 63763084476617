import { fromJS } from 'immutable';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

export const HIDDEN_STATES_FOR_BUTTONS = {
  INTERVIEW: [
    'hired',
    'interview_canceled',
    'passed',
    'expired',
    'hidden',
    'fired',
  ],
  MESSAGE: ['hired', 'merchant_invite_to_apply', 'fired'],
  NOTES: ['merchant_invite_to_apply', 'fired'],
};
export const PANEL_CONTROLS_HIDDEN_STATES = [
  'hired',
  'merchant_invite_to_apply',
  'fired',
];
export const HIDE_PASS_BUTTON_STATES = ['passed', 'expired', 'hidden'];
export const INTERVIEW_REQUESTED_STATE = 'interview_requested';
export const PASS_FTU_POPUP_KEY = 'hiring_manage_applicants_pass';
export const SCHEDULE_FTU_POPUP_KEY = 'hiring_manage_applicants_schedule';
export const SHOW_INTERVIEWING_FLARE_STATES = [
  'interview_requested',
  'interview_canceled',
];
export const DECLINED_STATE = 'passed';
export const ARCHIVED_STATE = 'archived';
export const STATUS_FILTER_STATES = [
  'all_statuses',
  'active',
  'interviewing',
  'hired',
  'favorited',
  'declined',
  'archived',
];
export const SORT_RELEVANCE_STATE = 'relevance';
export const SORT_DATE_STATE = 'date';
export const SORT_UNREAD_MESSAGES_STATE = 'unread_messages';
export const SORT_NAME_STATE = 'name';
export const SORT_STATES = [
  SORT_RELEVANCE_STATE,
  SORT_DATE_STATE,
  SORT_UNREAD_MESSAGES_STATE,
  SORT_NAME_STATE,
];

export const APPLIED_TO_LOCATION_VALUE = 'applied_to_location';

export const DISTANCE_FILTER_VALUES = [
  'all',
  'unknown',
  'lte_5',
  'lte_10',
  'lte_30',
  'lte_50',
  'lte_100',
  'gt_100',
];

export const DISTANCE_FILTER_OPTIONS = DISTANCE_FILTER_VALUES.map(value => ({
  value,
  label: toI18n(`hiring.manage_applicants.filters.distance.${value}`),
}));

export const APPLICANT_DROPDOWN_APPLIED = 'applied';
export const APPLICANT_DROPDOWN_INTERVIEWING = 'interviewing';
export const APPLICANT_DROPDOWN_HIRED = 'hired';
export const APPLICANT_DROPDOWN_DECLINED = 'declined';
export const APPLICANT_DROPDOWN_ARCHIVED = 'archived';
export const APPLICANT_STATUS_DROPDOWN_VALUES = [
  APPLICANT_DROPDOWN_APPLIED,
  APPLICANT_DROPDOWN_INTERVIEWING,
  APPLICANT_DROPDOWN_HIRED,
  APPLICANT_DROPDOWN_DECLINED,
  APPLICANT_DROPDOWN_ARCHIVED,
];

export const APPLICANT_STATUS_DROPDOWN_OPTIONS =
  APPLICANT_STATUS_DROPDOWN_VALUES.map(value => ({
    value,
    label: toI18n(`hiring.manage_applicants.status_dropdown.${value}`),
  }));

export const MANAGE_APPLICANTS_LIST_ITEM_HEIGHT = 95;

export const ZERO_STATE_APPLICATIONS = fromJS([
  {
    applied_at_ago: toI18n('hiring.manage_applicants.applied_at_ago', {
      props: {
        when: toI18n('datetime.distance_in_words.about_x_hours.one'),
      },
    }),
    id: 1,
    applicant_id: 1,
    token: 'abc123',
    applicant_user: false,
    name: 'Sample Applicant',
    short_name: 'Sample Applicant',
    full_name: 'Sample Applicant',
    avatar_url: require('./ManageApplicantsZeroState/images/sample-applicant-0.png'),
    phone: '123 456 7890',
    email: 'applicant@example.com',
    resume_url: 'https://www.joinhomebase.com',
    distance: 1,
    state: 'pending',
    liked: false,
    summary:
      'Hire me! I am a hard worker with lots of experience. Looking forward to connecting!',
    experiences: [
      {
        start_date: '05/01/2017',
        end_date: moment().format('MM/DD/YYYY'),
        duration_in_months: '',
        imported_from_user: false,
        role: 'Barista',
        company: 'Coffee Shop',
      },
      {
        start_date: '05/01/2016',
        end_date: '05/01/2017',
        duration_in_months: '',
        imported_from_user: false,
        role: 'Bartender',
        company: 'Local Bar',
      },
    ],
    desired_rate: '14 - $16/ hr',
    tranasportation_method: 'Vehicle',
    has_week_availabilities: true,
    week_availabilities: {
      monday: ['morning', 'evening'],
      tuesday: ['morning', 'evening'],
      wednesday: ['morning', 'evening'],
      thursday: ['morning', 'evening'],
      friday: ['morning', 'evening'],
      saturday: [],
      sunday: [],
    },
    i_am: 'Enthusiastic',
    i_like: 'Punctual',
    i_love: 'Hardworking',
  },
  {
    applied_at_ago: toI18n('hiring.manage_applicants.applied_at_ago', {
      props: {
        when: toI18n('datetime.distance_in_words.x_days.one'),
      },
    }),
    avatar_url: require('./ManageApplicantsZeroState/images/sample-applicant-1.png'),
    short_name: 'Sample Applicant',
  },
  {
    applied_at_ago: toI18n('hiring.manage_applicants.applied_at_ago', {
      props: {
        when: toI18n('datetime.distance_in_words.x_days.other', {
          props: { count: 3 },
        }),
      },
    }),
    avatar_url: require('./ManageApplicantsZeroState/images/sample-applicant-2.png'),
    short_name: 'Sample Applicant',
  },
  {
    applied_at_ago: toI18n('hiring.manage_applicants.applied_at_ago', {
      props: {
        when: toI18n('datetime.distance_in_words.x_days.other', {
          props: { count: 3 },
        }),
      },
    }),
    avatar_url: require('./ManageApplicantsZeroState/images/sample-applicant-3.png'),
    short_name: 'Sample Applicant',
  },
]);

export const ZERO_STATE_OWNER = fromJS({
  location_interview_notes: 'Sample Applicant',
  location_id: 1,
});

export const APPLICATION_QUALITY_FILTER_BODY = {
  [toI18n(
    'hiring.manage_applicants.filters.application_quality.contact_information'
  )]: false,
  [toI18n(
    'hiring.manage_applicants.filters.application_quality.homebase_verified'
  )]: false,
  [toI18n(
    'hiring.manage_applicants.filters.application_quality.application_questions'
  )]: false,
  [toI18n(
    'hiring.manage_applicants.filters.application_quality.experience'
  )]: false,
  [toI18n(
    'hiring.manage_applicants.filters.application_quality.summary'
  )]: false,
  [toI18n(
    'hiring.manage_applicants.filters.application_quality.availability'
  )]: false,
};

export const SOURCE_FILTER_BODY = {
  [toI18n('hiring.manage_applicants.filters.source.groups.job_boards')]: {
    [toI18n('hiring.manage_applicants.filters.source.indeed')]: false,
    [toI18n('hiring.manage_applicants.filters.source.facebook_jobs')]: false,
    [toI18n('hiring.manage_applicants.filters.source.zip_recruiter')]: false,
  },
  [toI18n('hiring.manage_applicants.filters.source.groups.homebase')]: {
    [toI18n('hiring.manage_applicants.filters.source.walk_ins')]: false,
    [toI18n('hiring.manage_applicants.filters.source.referrals')]: false,
    [toI18n('hiring.manage_applicants.filters.source.applied_directly')]: false,
  },
};

export const SOURCE_FILTER_MAP = {
  [toI18n('hiring.manage_applicants.filters.source.indeed')]: 'Indeed',
  [toI18n('hiring.manage_applicants.filters.source.facebook_jobs')]: 'Facebook',
  [toI18n('hiring.manage_applicants.filters.source.zip_recruiter')]:
    'ZipRecruiter',
  [toI18n('hiring.manage_applicants.filters.source.walk_ins')]:
    'homebase_manual_walk_in',
  [toI18n('hiring.manage_applicants.filters.source.referrals')]:
    'homebase_manual_referral',
  [toI18n('hiring.manage_applicants.filters.source.applied_directly')]:
    'Homebase',
};

export const WEEK_AVAILABILITIES_DAYS = [
  toI18n('hiring.manage_applicants.filters.week_availabilities.monday'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.tuesday'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.wednesday'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.thursday'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.friday'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.saturday'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.sunday'),
];

export const WEEK_AVAILABILITIES_SHIFTS = [
  toI18n('hiring.manage_applicants.filters.week_availabilities.morning'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.afternoon'),
  toI18n('hiring.manage_applicants.filters.week_availabilities.evening'),
];

export const LOCATIONS_OWNER_TYPE = 'locations';

export const STATUS = 'status';
export const DISTANCE = 'distance';
export const QUALITY = 'quality';
export const SOURCE = 'source';
export const WEEK_AVAILABILITIES = 'weekAvailabilities';
export const SORT = 'sort';

export const FILTER_LIST_PARAM_KEYS = [QUALITY, SOURCE, WEEK_AVAILABILITIES];

export const FILTER_QUERY_PARAM_KEYS = [
  STATUS,
  DISTANCE,
  QUALITY,
  SOURCE,
  WEEK_AVAILABILITIES,
  SORT,
];
