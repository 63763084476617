import { Overrides } from '../../types';
import breakpoints from '../breakpoints';

export const toastOverrides: Overrides = {
  MuiSnackbar: {
    styleOverrides: {
      root: {
        margin: 'auto',
        maxWidth: '500px',

        '&.MuiSnackbar-anchorOriginBottomCenter, &.MuiSnackbar-anchorOriginBottomLeft, &.MuiSnackbar-anchorOriginBottomRight':
          {
            bottom: '30px',
          },

        '& > div': {
          width: '100%',

          [`@media (min-width: ${breakpoints.mediumBreakpoint})`]: {
            width: 'auto',
          },
        },

        '& .MuiAlert-root': {
          padding: 0,
        },

        '& .MuiAlert-root .MuiAlert-message': {
          padding: '12px 32px',

          [`@media (min-width: ${breakpoints.mediumBreakpoint})`]: {
            minWidth: '600px',
          },
        },
      },
    },
  },
};
