import React from 'react';
import Icon from 'react-icon-base';

const GenericRole = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M16 16.286c2.083 0 3.81-1.697 3.81-3.81 0-2.083-1.727-3.81-3.81-3.81-2.113 0-3.81 1.727-3.81 3.81 0 2.113 1.697 3.81 3.81 3.81zm2.648.952h-.506c-.654.327-1.369.476-2.142.476-.774 0-1.518-.149-2.173-.476h-.506c-2.202 0-3.988 1.815-3.988 4.018v1.22c0 .804.625 1.429 1.429 1.429h10.476c.774 0 1.428-.625 1.428-1.429v-1.22c0-2.203-1.815-4.018-4.018-4.018z"
    />
  </Icon>
);

export default GenericRole;
