import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon/Icon';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';

import {
  getPredictedFailurePaydayChanged,
  getPredictedFailureWireIntention,
  getPredictedFundingFailureRequirement,
} from 'selectors/session';

import { actions as bottomDrawerActions } from 'features/bottomDrawers/slice';
import {
  PAYROLL_DRAWER_COMPONENTS_NAMES,
  PAYROLL_TRACKING,
} from 'features/payroll/constants';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';

const { cx } = cxHelpers('PredictedFundingFailureBanner');

const PredictedFundingFailureBanner = ({
  openBottomDrawer,
  predictedFailurePaydayChanged,
  predictedFundingFailureRequirement,
  predictedFailureWireIntention,
}) => {
  const { PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER } =
    PAYROLL_DRAWER_COMPONENTS_NAMES;

  const linkText = toI18n(
    'payroll.dashboard.alert.funding_failure_requirement.cta'
  );

  const handleOpenPredictedFundingFailureDrawer = useCallback(() => {
    trackUxEvent({
      productArea: PAYROLL_TRACKING['payroll.productArea'],
      eventCategory:
        PAYROLL_TRACKING['payroll.event_category.nsf_prediction_resolution'],
      eventAction: EVENT_ACTIONS.LINK_CLICKED,
      actionType: TRACK_ACTION_TYPES.CLICK,
      properties: {
        link_text: linkText,
      },
    });
    openBottomDrawer({
      drawerType: PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER,
      choseWireOption: predictedFailureWireIntention,
    });
  }, [
    PAYROLL_PREDICTED_FUNDING_FAILURE_DRAWER,
    linkText,
    openBottomDrawer,
    predictedFailureWireIntention,
  ]);

  const resolved =
    predictedFundingFailureRequirement?.get('requirementStatus') === 'resolved';

  if (
    !predictedFundingFailureRequirement ||
    resolved ||
    predictedFailureWireIntention ||
    predictedFailurePaydayChanged
  ) {
    return null;
  }

  return (
    <Box h={36} className={cx()} bgcolor="red100" vcenter hcenter>
      <Box row vcenter hcenter>
        <Icon size="xsmall" iconName="Critical" color="error300" mr={8} />
        <Box mr={8}>
          <Text
            color="mono900"
            variant="bodySmBoldTight"
            i18n="payroll.dashboard.alert.funding_failure_requirement.text"
          />
        </Box>
        <Box mr={8}>
          <Text
            color="mono900"
            variant="bodySmTight"
            i18n="payroll.dashboard.alert.funding_failure_requirement.body"
          />
        </Box>
        <Box mb={6}>
          <Link size="small" onClick={handleOpenPredictedFundingFailureDrawer}>
            {linkText}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    predictedFundingFailureRequirement:
      getPredictedFundingFailureRequirement(state),
    predictedFailurePaydayChanged: getPredictedFailurePaydayChanged(state),
    predictedFailureWireIntention: getPredictedFailureWireIntention(state),
  }),
  {
    openBottomDrawer: bottomDrawerActions.openBottomDrawer,
  }
)(PredictedFundingFailureBanner);
