import { IllustrationProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';

import shifts_bars from './imageFiles/shifts_bars.png';

const ShiftsBars = (props: IllustrationProps) => (
  <Box
    w={72}
    {...props}
    minw={72}
    className={`FDBIllustration${
      props?.className ? ` ${props.className}` : ''
    }`}
  >
    <img
      src={shifts_bars}
      style={{ width: '100%' }}
      alt="Shift Bars Illustration"
    />
  </Box>
);
export default ShiftsBars;
