import React, { Children, Component } from 'react';
import { noop, omit, partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Tab from 'components/Tab';

import cxHelpers from 'util/className';

@cxHelpers('TabbedNav')
export default class TabbedNav extends Component {
  static propTypes = {
    initialIndex: PropTypes.number,
    headers: PropTypes.array,
    large: PropTypes.bool,
    renderHeaderRight: PropTypes.func,
    noBorder: PropTypes.bool,
    fixedHeader: PropTypes.bool,
    getRef: PropTypes.func,
    onNav: PropTypes.func,
  };

  static defaultProps = {
    initialIndex: 0,
    noBorder: false,
    getRef: noop,
    onNav: noop,
  };

  state = {
    currentIndex: this.props.initialIndex,
  };

  handleClick = index => {
    this.setState({ currentIndex: index });
    this.props.onNav(index);
  };

  render() {
    const {
      headers,
      noBorder,
      renderHeaderRight,
      children,
      fixedHeader,
      large,
      getRef,
      onNav,
      ...props
    } = omit(this.props, 'initialIndex');

    return (
      <Box
        {...props}
        className={this.cx()}
        bt={!noBorder}
        br={!noBorder}
        bl={!noBorder}
        column={fixedHeader}
      >
        <Box vcenter shrink={fixedHeader && 0}>
          {headers.map(({ content, className }, i) => (
            <Tab
              key={i}
              large={large}
              content={content}
              className={className}
              onClick={partial(this.handleClick, i)}
              active={i === this.state.currentIndex}
            />
          ))}
          {renderHeaderRight && (
            <Box grow h={large ? 59 : 38} bb prm vcenter hright>
              {renderHeaderRight()}
            </Box>
          )}
        </Box>

        <Box
          className={this.cxEl('main')}
          grow={fixedHeader}
          scrollY={fixedHeader}
          getRef={getRef}
        >
          {Children.toArray(children).map((child, i) => (
            <Box key={i} hide={i !== this.state.currentIndex}>
              {child}
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}
