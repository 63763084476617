import React from 'react';
import Box from 'fe-components/Box';
import Text from 'fe-design-base/atoms/Text/Text';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { I18N_TITLE_PATH } from '../constants';
const { cx } = cxHelpers('SectionTitle');

const SectionTitle = ({ section }) => (
  <Box data-testid="onboarding-section-title" className={cx()}>
    <Text color="mono900" variant="heading4">
      {toI18n(`${I18N_TITLE_PATH}.${section}`)}
    </Text>
  </Box>
);

SectionTitle.propTypes = {
  section: PropTypes.string,
};

export default SectionTitle;
