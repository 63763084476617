import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgLove = ({
  alt = 'Love Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="m2.231 14.081 8.47 7.908a1.902 1.902 0 0 0 2.597 0l8.47-7.908A7.012 7.012 0 0 0 24 8.948v-.272a6.7 6.7 0 0 0-11.438-4.739L12 4.5l-.563-.563A6.702 6.702 0 0 0 0 8.677v.271c0 1.945.806 3.806 2.231 5.133Z"
    />
  </svg>
);
export default SvgLove;
