import './RadioGroup.scss';

import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import { colors, dimensions, typography } from 'theme/constants';

import Box from 'components/Box';

import cxHelpers from 'util/className';

@cxHelpers('RadioGroup')
class RadioGroup extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.array]),
    onChange: PropTypes.func,
    theme: PropTypes.string,
    row: PropTypes.bool,
    labelStyle: PropTypes.object,
    radioStyle: PropTypes.object,
    radioGroupStyle: PropTypes.object,
    boxProps: PropTypes.object,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    theme: 'medium',
    row: false,
    labelStyle: {},
    disabled: false,
  };

  handleChange = (event, value) => {
    this.props.onChange(value, event);
  };

  renderRadio = (inputProps, i) => (
    <FormControlLabel
      key={i}
      classes={{ root: this.cxEl('radioLabel') }}
      control={
        <Radio
          classes={{ root: this.cxEl('radio') }}
          color="default"
          style={{
            marginLeft: this.props.row ? dimensions.lg : dimensions.none,
            ...this.props.radioStyle,
          }}
          disabled={this.props.disabled}
        />
      }
      value={inputProps.value}
      label={
        <Box className={this.cxEl('radioLabelText')}>
          {inputProps.description || inputProps.label}
        </Box>
      }
      style={{
        color: colors.navyLight,
        fontSize: typography.fs16,
        fontWeight: typography.fw400,
        ...this.props.labelStyle,
      }}
      disabled={inputProps.disabled}
    />
  );

  render() {
    const { name, options, value, theme, row, boxProps, radioGroupStyle } =
      this.props;
    return (
      <MuiRadioGroup name={name} value={value} onChange={this.handleChange}>
        <Box
          className={this.cx({ [theme]: theme })}
          row={row}
          {...boxProps}
          style={{ ...radioGroupStyle }}
        >
          {options.map(this.renderRadio)}
        </Box>
      </MuiRadioGroup>
    );
  }
}

export default RadioGroup;
