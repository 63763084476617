import {
  showEnforcementDialog,
  showMobileTimeClockTrialEndDialog,
  showMultilocationEnforcementDialog,
} from 'actions/modals';

import { selectCanCloseEnforcementDialog } from 'features/enforcement/EnforcementBanner/selectors';
import { shouldShowMobileTimeClockEnforcementModal } from 'features/enforcement/MobileTimeClockTrialEndDialog/selectors';
import {
  selectCanCloseMultilocationEnforcementDialog,
  selectDisplayMultilocationEnforcementDialog,
  selectShouldShowEnforcementDialogOnInitialLoad,
} from 'features/enforcement/selectors';

import { PRODUCT_AREAS } from 'util/tracking_constants';

const initalPageLoadModal = state => {
  switch (true) {
    case shouldShowMobileTimeClockEnforcementModal(state):
      return showMobileTimeClockTrialEndDialog();
    case selectShouldShowEnforcementDialogOnInitialLoad(state):
      return showEnforcementDialog({
        productArea: PRODUCT_AREAS.SIGN_IN,
        canCloseDialog: selectCanCloseEnforcementDialog(state),
      });
    case selectDisplayMultilocationEnforcementDialog(state):
      return showMultilocationEnforcementDialog({
        productArea: PRODUCT_AREAS.SIGN_IN,
        canCloseMultilocationEnforcementDialog:
          selectCanCloseMultilocationEnforcementDialog(state),
      });
    default:
      return null;
  }
};

const handleOpenModalOnInitalPageLoad = store => {
  const modalToBeShown = initalPageLoadModal(store.getState());

  if (modalToBeShown) store.dispatch(modalToBeShown);
};

export default handleOpenModalOnInitalPageLoad;
