import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { ACTION_UPDATE } from 'fe-core/module-constants';
import { createResource } from 'fe-core/Resources';
import { update } from 'fe-core/Resources/actions';

const Job = createResource({
  name: 'job',
  schema: {
    attributes: [
      'level',
      'defaultRoleId',
      'wageRate',
      'scheduleConfirmation',
      'ptoCategories',
    ],
    relationships: {
      shifts: {
        defaultType: 'shift',
        relationshipType: HAS_MANY,
        inverseOf: 'owner',
      },
      scheduleAvailabilities: {
        defaultType: 'scheduleAvailability',
        relationshipType: HAS_MANY,
        inverseOf: 'job',
      },
      timeOffs: {
        defaultType: 'timeOff',
        relationshipType: HAS_MANY,
        inverseOf: 'job',
      },
      user: {
        defaultType: 'user',
        relationshipType: BELONGS_TO,
        inverseOf: 'jobs',
      },
      roleLaborTotals: {
        relationshipType: HAS_MANY,
        inverseOf: 'job',
      },
      claimRequests: {
        relationshipType: HAS_MANY,
        inverseOf: 'job',
      },
    },
  },
});

Job.batchUpdate = payload =>
  update({
    path: '/schedule_builder/employees/batch_update.json',
    name: 'job',
    action: ACTION_UPDATE,
    payload,
  });

export default Job;
