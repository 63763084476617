import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { List } from 'immutable';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';

import { UpgradeToAccessLocations } from './UpgradeToAccessLocations';

const { cx, cxEl } = cxHelpers('LockedLocations');

export const LockedLocations = ({ locations }) => (
  <Box className={cx()}>
    {locations.entrySeq().map(([key, location]) => (
      <MenuItem key={key} className={cxEl('location')}>
        <Box>
          <Box pvxs vcenter>
            <Text fw600 capitalize>
              {location.get('name')}
            </Text>
          </Box>
          <Box pbxs vcenter>
            <Text fs10 red i18n="nav_links.locked_location_upgrade" />
          </Box>
        </Box>
      </MenuItem>
    ))}

    <UpgradeToAccessLocations />
  </Box>
);

LockedLocations.propTypes = {
  locations: PropTypes.instanceOf(List),
};
