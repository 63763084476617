import React from 'react';
import Icon from 'react-icon-base';

const Calendar = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <use fill="#313A43" xlinkHref="#a-calendar" />
    <use
      fill="#313A43"
      transform="translate(5.37 8.633)"
      xlinkHref="#b-calendar"
    />
    <defs>
      <path
        id="a-availability"
        d="M13 2h-.333V1c0-.533-.467-1-1-1-.534 0-1 .467-1 1v1H5.333V1c0-.533-.466-1-1-1-.533 0-1 .467-1 1v1H3C1.333 2 0 3.4 0 5.133v7.734C0 14.6 1.333 16 3 16h10c1.667 0 3-1.4 3-3.133V5.133C16 3.4 14.667 2 13 2zm-1.667-1c0-.2.134-.333.334-.333S12 .8 12 1v2.667c0 .2-.133.333-.333.333s-.334-.133-.334-.333V1zM4 1c0-.2.133-.333.333-.333S4.667.8 4.667 1v2.667c0 .2-.134.333-.334.333S4 3.867 4 3.667V1zM3 2.667h.333v1c0 .533.467 1 1 1 .534 0 1-.467 1-1v-1h5.334v1c0 .533.466 1 1 1 .533 0 1-.467 1-1v-1H13c1.267 0 2.333 1.133 2.333 2.466v.2H.667v-.2C.667 3.8 1.733 2.667 3 2.667zm10 12.666H3c-1.267 0-2.333-1.133-2.333-2.466V6h14.666v6.867c0 1.333-1.066 2.466-2.333 2.466z"
      />
      <path
        id="b-availability"
        d="M5.433.1L1.967 3.567l-1.4-1.4a.322.322 0 0 0-.467 0 .322.322 0 0 0 0 .466L1.967 4.5 5.9.567A.322.322 0 0 0 5.9.1a.322.322 0 0 0-.467 0z"
      />
    </defs>
  </Icon>
);

export default Calendar;
