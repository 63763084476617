import React from 'react';
import Icon from 'react-icon-base';

const CheckCircle = props => (
  <Icon viewBox="0 0 69 69" {...props}>
    <path
      fill="#8A5EA6"
      stroke="#F3EEF6"
      strokeWidth="4.385"
      d="M34.5 66.788c17.832 0 32.288-14.456 32.288-32.288 0-17.832-14.456-32.288-32.288-32.288-17.832 0-32.288 14.456-32.288 32.288 0 17.832 14.456 32.288 32.288 32.288z"
    />
    <path
      fill="#fff"
      d="M45.964 25.232a1.52 1.52 0 0 1 2.095 0 1.395 1.395 0 0 1 0 2.02L29.894 44.187l-8.948-8.664a1.395 1.395 0 0 1-.007-2.02 1.521 1.521 0 0 1 2.095-.006l6.854 6.654 16.076-14.918z"
    />
  </Icon>
);

export default CheckCircle;
