import React from 'react';
import Icon from 'react-icon-base';

const Interview = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M12.948 1.979h-.247v-.907a1.073 1.073 0 0 0-2.144 0v.907H5.443v-.907a1.073 1.073 0 0 0-2.144 0v.907h-.247C1.369 1.979 0 3.41 0 5.169v7.641C0 14.569 1.37 16 3.052 16h9.896C14.631 16 16 14.568 16 12.81V5.17c0-1.76-1.37-3.191-3.052-3.191zm-8.824-.907A.25.25 0 0 1 4.37.824a.25.25 0 0 1 .247.248v2.639a.25.25 0 0 1-.247.248.25.25 0 0 1-.247-.248V1.072zm-.825 1.733h-.247C1.824 2.805.825 3.865.825 5.17v.11h14.35v-.11c0-1.305-.998-2.365-2.226-2.365h-.247v.906a1.073 1.073 0 0 1-2.145 0v-.906H5.444v.906a1.073 1.073 0 0 1-2.145 0v-.906zM.825 6.104v6.706c0 1.305 1 2.366 2.227 2.366h9.926c.889 0 2.198-.963 2.198-2.223v-6.85H.826zM11.628.824a.25.25 0 0 0-.247.248v2.639a.25.25 0 0 0 .247.248.25.25 0 0 0 .248-.248V1.072a.25.25 0 0 0-.248-.248zm-7.006 9.61c0-1.987 1.591-3.598 3.556-3.598 1.964 0 3.555 1.611 3.555 3.598 0 1.988-1.591 3.6-3.555 3.6-1.965 0-3.556-1.612-3.556-3.6zm3.556 3.134c1.718 0 3.096-1.412 3.096-3.134 0-1.739-1.395-3.133-3.096-3.133-1.72 0-3.097 1.412-3.097 3.133 0 1.74 1.395 3.134 3.097 3.134zm-.202-2.709l1.164.856c.077.056.185.039.24-.037l.118-.164a.176.176 0 0 0-.037-.244l-1.01-.745V8.402a.174.174 0 0 0-.173-.173h-.2a.173.173 0 0 0-.173.173v2.317c0 .054.026.107.07.14z"
      clipRule="evenodd"
    />
  </Icon>
);

export default Interview;
