import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMagicWand = ({
  alt = 'Magic Wand Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="m9.78 3.079-1.572.587a.32.32 0 0 0 0 .6l1.571.588.588 1.57a.32.32 0 0 0 .6 0l.587-1.57 1.571-.588a.32.32 0 0 0 0-.6l-1.57-.587-.588-1.57a.32.32 0 0 0-.6 0l-.588 1.57ZM1.92 17.775a2.006 2.006 0 0 0 0 2.829l1.442 1.442c.78.779 2.046.779 2.83 0L22.079 6.154c.78-.78.78-2.046 0-2.83l-1.442-1.437a2.004 2.004 0 0 0-2.829 0L1.921 17.775ZM20.193 4.74l-4.375 4.375-.971-.97L19.22 3.77l.97.97ZM.312 6.183a.482.482 0 0 0-.312.45c0 .2.125.38.313.45l2.354.883.883 2.355a.482.482 0 0 0 .9 0l.883-2.355 2.354-.883A.482.482 0 0 0 8 6.633c0-.2-.125-.379-.313-.45L5.333 5.3 4.45 2.946A.482.482 0 0 0 4 2.633c-.2 0-.38.125-.45.313L2.667 5.3l-2.354.883ZM14.98 16.85a.482.482 0 0 0 0 .9l2.354.883.884 2.354a.482.482 0 0 0 .9 0L20 18.633l2.354-.883a.482.482 0 0 0 0-.9L20 15.966l-.883-2.354a.482.482 0 0 0-.9 0l-.884 2.354-2.354.884Z"
    />
  </svg>
);
export default SvgMagicWand;
