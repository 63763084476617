import './ViewContainer.scss';

import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import { useIsEmbedded } from 'hooks/useIsEmbedded';

import { cxHelpers } from 'util/className';

const { cx, cxEl } = cxHelpers('ViewContainer');

const ViewContainer = ({ children }) => {
  const isEmbedded = useIsEmbedded();

  return (
    <div className={cx({ isEmbedded })}>
      <Box className={cxEl('inner')} bgcolor={isEmbedded ? 'mono0' : 'mono100'}>
        {children}
      </Box>
    </div>
  );
};

export default ViewContainer;
