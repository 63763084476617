import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import EditableSection from '../../EditableSection.jsx';

import { INITIAL_VALUES } from './constants.js';
import RecurringEarningFormFields from './RecurringEarningFormFields';

const RecurringEarningForm = ({
  onCancel,
  onSave,
  onSaveCompleted,
  isSaveButtonDisabled,
  isRemoveButtonLoading,
  onClickRemove,
  canDelete,
  user,
  updateUserInfo,
  recurring_earning,
  showFormWithSteps,
}) => {
  const validationSchema = yup.object().shape({
    earning_type: yup
      .string()
      .required(
        toI18n(
          'team.employee_profile.recurring_earnings.validations.earning_type_required'
        )
      ),
    amount: yup
      .number()
      .transform((value, originalValue) => parseFloat(originalValue))
      .required(
        toI18n(
          'team.employee_profile.recurring_earnings.validations.amount_required'
        )
      )
      .moreThan(
        0,
        toI18n(
          'team.employee_profile.recurring_earnings.validations.amount_required'
        )
      ),
    start_date: yup
      .date()
      .required(
        toI18n(
          'team.employee_profile.recurring_earnings.validations.start_date_required'
        )
      ),
    frequency_type: yup
      .string()
      .required(
        toI18n(
          'team.employee_profile.recurring_earnings.validations.frequency_type_required'
        )
      ),
    end_date: yup
      .date()
      .nullable()
      .min(
        yup.ref('start_date'),
        toI18n(
          'team.employee_profile.recurring_earnings.validations.end_date_greater_than_start_date'
        )
      ),
  });

  return (
    <EditableSection
      initEditMode
      onCancel={onCancel}
      onSave={onSave}
      onSaveCompleted={onSaveCompleted}
      updateUserInfo={updateUserInfo}
      validationSchema={validationSchema}
      isSaveButtonDisabled={isSaveButtonDisabled}
      initialValues={
        recurring_earning ? recurring_earning.toObject() : INITIAL_VALUES
      }
    >
      {({ editMode, initialValues }) =>
        editMode ? (
          <RecurringEarningFormFields
            initialValues={initialValues}
            onClickRemove={onClickRemove}
            isRemoveButtonLoading={isRemoveButtonLoading}
            canDelete={canDelete}
            isContractor={user.get('tax_classification') === '1099'}
            payPeriodStart={moment(
              user
                .get('recurring_earnings_and_reimbursements')
                .get('pay_period_start')
            )}
            currentDate={moment(
              user
                .get('recurring_earnings_and_reimbursements')
                .get('current_date')
            )}
            showFormWithSteps={showFormWithSteps}
          />
        ) : null
      }
    </EditableSection>
  );
};

RecurringEarningForm.propTypes = {
  onCancel: PropTypes.func,
  user: ImmutablePropTypes.map.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  onSaveCompleted: PropTypes.func.isRequired,
  isSaveButtonDisabled: PropTypes.bool,
  isRemoveButtonLoading: PropTypes.bool,
  onSave: PropTypes.func,
  onClickRemove: PropTypes.func,
  recurring_earning: PropTypes.object,
  canDelete: PropTypes.bool,
};
export default RecurringEarningForm;
