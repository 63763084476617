import React from 'react';
import Icon from 'react-icon-base';

const CalendarO = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9375 1.75H9.625V0.328125C9.625 0.164062 9.46094 0 9.29688 0H8.20312C8.01172 0 7.875 0.164062 7.875 0.328125V1.75H4.375V0.328125C4.375 0.164062 4.21094 0 4.04688 0H2.95312C2.76172 0 2.625 0.164062 2.625 0.328125V1.75H1.3125C0.574219 1.75 0 2.35156 0 3.0625V12.6875C0 13.4258 0.574219 14 1.3125 14H10.9375C11.6484 14 12.25 13.4258 12.25 12.6875V3.0625C12.25 2.35156 11.6484 1.75 10.9375 1.75ZM10.7734 12.6875H1.47656C1.36719 12.6875 1.3125 12.6328 1.3125 12.5234V4.375H10.9375V12.5234C10.9375 12.6328 10.8555 12.6875 10.7734 12.6875Z"
        fill="#969BA0"
      />
    </svg>
  </Icon>
);

export default CalendarO;
