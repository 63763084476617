import { toI18n } from 'util/i18n';

export const NO_HOURS = `0 hr`;

export const BLANK_HOURS = `--`;

export const NO_DURATION = '0 hr 0 min';

export const TIME_FORMAT = 'h:mm a';

export const TIME_FORMAT_MOBILE = 'HH:mm';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const LATE_DURATION = '0 hr';

export const NO_SHOW_REASONS_LIST = [
  ...toI18n('timecard_modal_v3.issues.no_show.resolve.reasons'),
];
