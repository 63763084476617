import './LaunchWeekStep.scss';

import React, { useEffect } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import {
  ELEMENTS,
  EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

const LaunchWeekStepOamName = ({
  currentUserFirstName,
  onNextStep,
  trackUx,
}) => {
  const setTimeoutId = onNextStep();

  useEffect(() => {
    trackUx(EVENT_ACTIONS.PAGE_VIEWED, TRACK_ACTION_TYPES.VIEW, {
      step_label: ELEMENTS.LAUNCH_WEEK_STEP_LABELS.OAM_NAME,
    });
    return () => {
      clearTimeout(setTimeoutId);
    };
  }, [setTimeoutId, trackUx]);

  return (
    <Box tcenter className="content-fade-in-and-out">
      <Text variant="display1" color="mono0" asHTML>
        {currentUserFirstName}
      </Text>
    </Box>
  );
};

export default LaunchWeekStepOamName;
