import React from 'react';
import { connect } from 'react-redux';

import {
  selectShopifyTierDowngradeError,
  selectShopifyUrl,
} from 'features/biller/implementations/shopify/selectors';
import ShopifySubscriptionChangeBanner from 'features/biller/implementations/shopify/ShopifySubscriptionChangeBanner';

import {
  selectDecidedToStayOnBasic,
  selectShouldShowPastDueSubscriptionDialog,
  selectShowCreditCardExpiryBanner,
  selectShowSubscriptionPastDueBanner,
} from '../selectors';

import ExpiringCreditCardView from './ExpiringCreditCardView';
import PastDueSubscriptionView from './PastDueSubscriptionView';

export type BillingStateBannerProps = {
  showSubscriptionPastDueBanner: boolean | null;
  showCreditCardExpiryBanner: boolean | null;
  shopifyUrl: string | null;
  changeTierError: string | null;
  shouldShowPastDueSubscriptionDialog: boolean;
  decidedToStayOnBasic: boolean;
};

const BillingStateBanner = ({
  showSubscriptionPastDueBanner,
  showCreditCardExpiryBanner,
  shopifyUrl,
  changeTierError,
  shouldShowPastDueSubscriptionDialog,
  decidedToStayOnBasic,
}: BillingStateBannerProps) => {
  if (
    !decidedToStayOnBasic &&
    showSubscriptionPastDueBanner &&
    !shouldShowPastDueSubscriptionDialog
  ) {
    return <PastDueSubscriptionView />;
  }

  if (showCreditCardExpiryBanner) {
    return <ExpiringCreditCardView />;
  }

  if (shopifyUrl || changeTierError) {
    return (
      <ShopifySubscriptionChangeBanner
        shopifyUrl={shopifyUrl}
        changeTierError={changeTierError}
      />
    );
  }

  return null;
};

export default connect(
  state => ({
    shopifyUrl: selectShopifyUrl(state),
    decidedToStayOnBasic: selectDecidedToStayOnBasic(state),
    changeTierError: selectShopifyTierDowngradeError(state),
    showSubscriptionPastDueBanner: selectShowSubscriptionPastDueBanner(state),
    showCreditCardExpiryBanner: selectShowCreditCardExpiryBanner(state),
    shouldShowPastDueSubscriptionDialog:
      selectShouldShowPastDueSubscriptionDialog(state),
  }),
  {}
)(BillingStateBanner);
