import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { dimensions } from 'theme/constants';

const WINDOW_EVENTS = ['scroll', 'resize'];

export const NAV_HEIGHT = parseInt(`${dimensions.navHeight}`, 10);

const Drawer = ({
  open,
  containerStyle = {},
  zIndex = 110,
  top = NAV_HEIGHT,
  right = 0,
  width = '350px',
  maxWidth,
  alignLeft,
  children,
  absolute,
  overflow = 'auto',
  className,
}) => {
  const [isRequestingAF, setIsRequestingAF] = useState(false);

  const drawerEl = useRef(null);
  const anchor = alignLeft ? 'left' : 'right';
  const position = absolute ? 'absolute' : 'fixed';

  const calculatedTop = useMemo(
    () => (Math.abs(document.body.getBoundingClientRect()?.y) > 0 ? 0 : top),
    [top]
  );

  const height = absolute ? 'auto' : `calc(100vh - ${calculatedTop}px)`;

  const computedContainerStyle = useMemo(
    () => ({
      style: {
        zIndex,
        top: calculatedTop,
        right,
        maxWidth,
        width: maxWidth ? 'auto' : width,
        position,
        height,
        overflow,
        ...containerStyle,
      },
    }),
    [
      zIndex,
      calculatedTop,
      right,
      maxWidth,
      width,
      position,
      height,
      overflow,
      containerStyle,
    ]
  );

  const scrollDrawerTop = useCallback(() => {
    setIsRequestingAF(false);

    const y = window.scrollY;
    const newTop = Math.max(0, calculatedTop - y);
    const newHeight = window.innerHeight - newTop;

    if (drawerEl.current) {
      drawerEl.current.firstElementChild.style.top = `${newTop}px`;
      drawerEl.current.firstElementChild.style.height = `${newHeight}px`;
    }
  }, [calculatedTop]);

  const onWindowEvent = useCallback(() => {
    if (absolute) return;

    if (!isRequestingAF) {
      requestAnimationFrame(scrollDrawerTop);
    }
    setIsRequestingAF(true);
  }, [absolute, isRequestingAF, scrollDrawerTop]);

  useEffect(() => {
    if (open) {
      WINDOW_EVENTS.forEach(event =>
        window.addEventListener(event, onWindowEvent)
      );
    }

    return () => {
      WINDOW_EVENTS.forEach(event =>
        window.removeEventListener(event, onWindowEvent)
      );
    };
  }, [open, onWindowEvent]);

  return (
    <MuiDrawer
      ref={drawerEl}
      transitionDuration={window.testEnv ? 0 : 500}
      variant="persistent"
      anchor={anchor}
      open={open}
      PaperProps={computedContainerStyle}
      className={className}
    >
      {children}
    </MuiDrawer>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool.isRequired,
  containerStyle: PropTypes.object,
  zIndex: PropTypes.number,
  top: PropTypes.number,
  right: PropTypes.number,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  overflow: PropTypes.string,
  alignLeft: PropTypes.bool,
  children: PropTypes.node,
  absolute: PropTypes.bool,
};

export default memo(Drawer);
