import React from 'react';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';

const RequiredMessage = () => (
  <Text color="error500" variant="bodyBold">
    {toI18n('team.employee_profile.required_field')}
  </Text>
);

export default RequiredMessage;
