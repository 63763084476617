import './Button.scss';

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { keys, omit } from 'lodash';
import PropTypes from 'prop-types';

import AnimatedSuccessIcon, {
  SUCCESS_ANIMATION_DURATION,
} from 'components/AnimatedSuccessIcon';
import Icon, { ICON_SIZES } from 'components/Icon';
import LoadingIndicator from 'components/LoadingIndicator';
import Text from 'components/Text';

import cxHelpers from 'util/className';

export const BUTTON_SIZES = ['large', 'medium', 'small', 'mini', 'auto', 'xs'];

export const THEMES = [
  'link',
  'link-red',
  'link-purple',
  'link-purple400',
  'link-gray',
  'link-light-gray',
  'link-navy-light',
  'link-white',
  'link-green',
  'link-navy',
  'primary',
  'primary-purple',
  'primary-red',
  'primary-blue',
  'primary-violet',
  'primary-black',
  'primary-gray',
  'secondary-blue',
  'secondary-gray',
  'secondary-purple',
  'secondary-purple-500',
  'secondary-purple-gray',
  'secondary-red',
  'secondary-black',
  'default',
  'card',
  'card-action',
  'primary-violet-generic',
  'secondary-purple-gray-medium-light',
  'secondary-primary-050-gray',
  'secondary-black-gray',
];

const PROP_TYPES = {
  children: PropTypes.node,
  inline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClick: PropTypes.func,
  size: PropTypes.oneOf(BUTTON_SIZES),
  loading: PropTypes.bool,
  unclickable: PropTypes.bool,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  isIconPositionLeft: PropTypes.bool,
  iconSize: PropTypes.oneOfType([
    PropTypes.oneOf(ICON_SIZES),
    PropTypes.number,
  ]),
  iconClassName: PropTypes.string,
  href: PropTypes.string,
  linkTo: PropTypes.string,
  type: PropTypes.string,
  i18n: PropTypes.string,
  i18nProps: PropTypes.object,
  theme: PropTypes.oneOf(THEMES),
  successAnimation: PropTypes.bool,
  noRightBorderRadius: PropTypes.bool,
  noLeftBorderRadius: PropTypes.bool,
  noBorder: PropTypes.bool,
  noHoverBorder: PropTypes.bool,
  square: PropTypes.bool,
  pill: PropTypes.bool,
  noPreloader: PropTypes.bool,
  target: PropTypes.string,
  selected: PropTypes.bool,
  withoutLoadingIndicator: PropTypes.bool,
};

const PROP_TYPE_KEYS = keys(PROP_TYPES);

@cxHelpers('Button')
class Button extends PureComponent {
  static propTypes = PROP_TYPES;

  static defaultProps = {
    type: 'button',
    successAnimation: false,
  };

  state = {
    justSucceeded: false,
    disabled: false,
  };

  // TODO: https://joinhomebase.atlassian.net/browse/HIRING-441
  UNSAFE_componentWillUpdate = nextProps => {
    if (
      nextProps.successAnimation &&
      this.props.loading &&
      !nextProps.loading
    ) {
      this.setState({ justSucceeded: true }, () =>
        setTimeout(
          () => this.setState({ justSucceeded: false }),
          SUCCESS_ANIMATION_DURATION
        )
      );
    }
  };

  handleClick = e => {
    if (this.props.unclickable) {
      return;
    }

    if (this.props.onClick) {
      const handler = this.props.onClick(e);
      if (handler && Promise.resolve(handler)) {
        this.setState({ disabled: true });
        if (handler.hasOwnProperty('finally')) {
          handler.finally(() => this.setState({ disabled: false }));
        } else {
          this.setState({ disabled: false });
        }
      }
    }

    if (
      this.props.href &&
      !this.props.noPreloader &&
      this.props.target !== '_blank'
    ) {
      window.Homebase.showPreloader();
    }
  };

  render() {
    const {
      fullWidth,
      disabled,
      size,
      loading,
      unclickable,
      href,
      linkTo,
      type,
      theme,
      children,
      i18n,
      i18nProps,
      noRightBorderRadius,
      noLeftBorderRadius,
      noBorder,
      noHoverBorder,
      square,
      pill,
      target,
      inline,
      selected,
      icon,
      iconColor,
      iconClassName,
      iconSize,
      withoutLoadingIndicator,
      isIconPositionLeft = true,
    } = this.props;
    const { justSucceeded } = this.state;

    const className = this.cx({
      disabled: !justSucceeded && (this.state.disabled || disabled),
      'full-width': fullWidth,
      unclickable,
      [size]: size,
      loading: !justSucceeded && loading,
      link: href || linkTo,
      [`theme-${theme}`]: theme,
      'no-right-border-radius': noRightBorderRadius,
      'no-left-border-radius': noLeftBorderRadius,
      'no-border': noBorder,
      'no-hover-border': noHoverBorder,
      square,
      pill,
      inline,
      selected,
    });

    let Component = 'button';

    const props = {
      className,
      disabled: !justSucceeded && disabled,
      onClick: this.handleClick,
    };

    if (linkTo) {
      Component = Link;
      props.to = linkTo;
    } else if (href) {
      Component = 'a';
      props.href = href;
      props.target = target;
      if (target === '_blank') {
        props.rel = 'noopener noreferrer';
      }
    } else {
      props.type = type;
    }

    const passThroughProps = omit(this.props, PROP_TYPE_KEYS);

    const getIcon = () => (
      <Icon
        type={icon}
        data-testid={`button-${isIconPositionLeft ? 'left' : 'right'}`}
        className={this.cxEl(
          isIconPositionLeft ? 'icon--left' : 'icon--right',
          {},
          iconClassName
        )}
        color={iconColor}
        size={iconSize}
      />
    );

    return (
      <Component {...passThroughProps} {...props}>
        {icon && isIconPositionLeft && getIcon()}
        <span
          className={this.cxEl('content', { show: !loading && !justSucceeded })}
        >
          {children}
          {i18n && <Text i18n={i18n} i18nProps={i18nProps} />}
        </span>
        {icon && !isIconPositionLeft && getIcon()}
        {!withoutLoadingIndicator && (
          <LoadingIndicator
            size={16}
            type="threeDots"
            className={this.cxEl('loading-indicator', { show: loading })}
          />
        )}
        {justSucceeded ? (
          <AnimatedSuccessIcon className={this.cxEl('success-icon')} />
        ) : null}
      </Component>
    );
  }
}

export default Button;
