import React from 'react';
import Box from 'fe-components/Box';
import NumberField from 'fe-design-base/molecules/NumberField';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

const { cxEl } = cxHelpers('JobDetailsSection');

const WageRateInput = ({ name }) => (
  <Box className={cxEl('wage-rate-input')}>
    <NumberField
      disabled={false}
      leftAlign
      name={name}
      places={2}
      prefix={toI18n('number.currency.format.unit')}
      size="medium"
    />
  </Box>
);

WageRateInput.propTypes = {
  name: PropTypes.string.isRequired,
};

export default WageRateInput;
