import { fetchJSON, patchJSON, postJSON, putJSON } from 'api/fetch';

import { resolveError } from 'util/error';

import { locationRoute, userPath } from '../../../api';

import { ROUTES } from './constants';

export const fetchListData = () =>
  fetchJSON(ROUTES.FETCH_DATA).catch(resolveError);

export const postAddEmployee = ({ name, email, accessLevel, locationId }) => {
  const [first_name, ...last_name] = name.split(' ');
  const payload = {
    user: {
      first_name,
      last_name: last_name.join(' '),
      email,
      all_jobs_attributes: [
        {
          level: accessLevel,
          location_id: locationId,
        },
      ],
    },
  };

  return postJSON(ROUTES.ADD_EMPLOYEE, payload).catch(resolveError);
};

export const patchUpdateLocationSettings = ({ locationId, ...formValues }) =>
  patchJSON(locationRoute(locationId), formValues).catch(resolveError);

export const putUpdateLocationSettings = formValues =>
  putJSON(userPath(), formValues).catch(resolveError);
