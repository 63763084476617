import './OverlayModal.scss';

import React from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { connect } from 'react-redux';

import { createOneTimeEvent } from 'actions/session';

type CalendlyModalProps = {
  url: string;
  open: boolean;
  handleCloseModal: () => void;
  isImplementationDashboard?: boolean;
  onCreateOneTimeEvent?: (event: string, company: string) => void;
};

export const CalendlyModal: React.FC<CalendlyModalProps> = ({
  url,
  open,
  handleCloseModal,
  onCreateOneTimeEvent,
  isImplementationDashboard = false,
}) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      if (isImplementationDashboard) {
        onCreateOneTimeEvent?.('schedule_call_finish', 'Company');
      }
    },
  });

  const root = document.getElementById('react-app-root') as HTMLElement;

  return (
    <PopupModal
      url={url}
      open={open}
      rootElement={root}
      data-testid="calendly-modal"
      onModalClose={handleCloseModal}
    />
  );
};

export default connect(() => ({}), {
  onCreateOneTimeEvent: createOneTimeEvent,
})(CalendlyModal);
