import { createSelector } from 'reselect';

import {
  getCurrentLocationTierName,
  getCurrentUserIsManagerial,
} from 'selectors/session';

import { getUrlParamValue } from 'util/urlParams';

import { TIER_NAMES } from '../tiers/constants';

export const selectShowC2CDialogFromURL = createSelector(
  getCurrentLocationTierName,
  getCurrentUserIsManagerial,
  (currentLocationTierName, currentUserIsManagerial) =>
    currentUserIsManagerial &&
    currentLocationTierName !== TIER_NAMES.BASIC &&
    getUrlParamValue('show-c2c') === 'true'
);
