import './LaunchWeekStoryPaginationStep.scss';

import React from 'react';
import Box from 'fe-design-base/atoms/Box';

import { cxHelpers } from 'util/className';
const { cx, cxEl } = cxHelpers('LaunchWeekStoryPaginationStep');

const LaunchWeekStoryPaginationStep = ({
  step,
  currentStep,
  awardStepsDelayMs,
}) => {
  const isCurrentStep = step === currentStep;
  const isPastStep = step < currentStep;

  const awardStepsDelayStyle = isCurrentStep
    ? {
        '--award-steps-delay-ms': `${awardStepsDelayMs}ms`,
      }
    : {};

  return (
    <Box
      className={`${cx()} ${isCurrentStep ? cxEl('current') : ''}`}
      key={step}
      style={awardStepsDelayStyle}
      w="100%"
      h={8}
      bgcolor={isPastStep ? 'accent500' : 'mono0'}
    />
  );
};

export default LaunchWeekStoryPaginationStep;
