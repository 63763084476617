import React, { PureComponent } from 'react';
import { postJSON } from 'api/fetch';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import { Mobile } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';

@cxHelpers('DemoNav')
export default class DemoNav extends PureComponent {
  handleClick() {
    postJSON('/payroll/demo/leave', {})
      .then(() => {
        window.location.href = '/timesheets';
      })
      .catch(() => {
        window.location.href = '/accounts/sign-in';
      });
  }

  renderText(mobile) {
    const i18n = mobile
      ? 'nav_links.timesheets_demo.title.mobile'
      : 'nav_links.timesheets_demo.title.desktop';

    return <Text white fs16 i18n={i18n} />;
  }

  render() {
    return (
      <Box className={this.cx({})} mls row vcenter grow>
        <Mobile>{mobile => this.renderText(mobile)}</Mobile>
        <Box mlm>
          <Button
            size="medium"
            i18n="nav_links.timesheets_demo.button"
            onClick={this.handleClick}
          />
        </Box>
      </Box>
    );
  }
}
