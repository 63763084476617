import { capitalize as toCaps } from 'lodash';

const capitalizeMessage = message => {
  if (typeof message === 'string') {
    return toCaps(message);
  }

  const result = {};
  Object.keys(message).forEach(key => {
    result[key] = toCaps(message[key]);
  });
  return result;
};

const interpolate = (message, props) => {
  if (!message) return message;

  if (typeof message === 'string')
    return window.I18n.interpolate(message, props);

  if (Array.isArray(message)) return message.map(m => interpolate(m, props));

  const result = {};
  Object.keys(message).forEach(key => {
    result[key] = interpolate(message[key], props);
  });
  return result;
};

export const toI18n = (id, options = {}) => {
  const { props = {}, capitalize, fallback } = options;
  let message = window.I18n.t(id, props) || '';

  if (typeof message === 'string') {
    if (message.startsWith('[missing')) {
      message = fallback ? toI18n(fallback) : '';
    }
  } else if (Array.isArray(message)) {
    message = message.map(m => interpolate(m, props));
  }

  if (capitalize) {
    message = capitalizeMessage(message);
  }

  return message;
};
