import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';
import { fromJS } from 'immutable';
import { sortBy } from 'lodash';

import { getCurrentLocationId } from 'selectors/session';

export const fetchQuestions = createAsyncThunk(
  'healthQuestions/fetchQuestions',
  (_, { getState }) =>
    fetchJSON(
      `/timeclock/health_questions.json?location_id=${getCurrentLocationId(
        getState()
      )}`
    )
);

export const updateQuestionsCollection = createAsyncThunk(
  'healthQuestions/updateQuestionsCollection',
  (props, { getState }) =>
    postJSON(`/timeclock/health_questions`, {
      location_id: getCurrentLocationId(getState()),
      questions: getState().getIn(['healthQuestions', 'questions']),
    })
);

const updateQuestion = (state, action) =>
  state.mergeIn(['questions', action.payload.index], action.payload.data);

const deleteQuestion = (state, action) => {
  if (state.getIn(['questions', action.payload.index, 'id'])) {
    return state.mergeIn(['questions', action.payload.index], {
      deleted: true,
    });
  }
  return state.deleteIn(['questions', action.payload.index]);
};

const addQuestion = (state, action) =>
  state.set('questions', state.get('questions').push(fromJS(action.payload)));

const markChanges = state => state.set('changed', true);

const clearChanges = state => state.set('changed', false);

const healthQuestionsSlice = createSlice({
  name: 'healthQuestions',
  initialState: fromJS({
    changed: false,
    questions: [],
  }),
  reducers: {
    addQuestion,
    markChanges,
    clearChanges,
    deleteQuestion,
    updateQuestion,
  },
  extraReducers: {
    [fetchQuestions.fulfilled]: (state, action) =>
      state.set('questions', fromJS(sortBy(action.payload, q => q.position))),
    [updateQuestionsCollection.fulfilled]: (state, action) =>
      state.set('questions', fromJS(sortBy(action.payload, q => q.position))),
  },
});

export const healthQuestionsReducer = healthQuestionsSlice.reducer;
export const healthQuestionsActions = healthQuestionsSlice.actions;
