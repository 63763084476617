import './RolesSelect.scss';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import SelectInput from 'components/forms/SelectInput';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';

@cxHelpers('RolesSelect')
export default class RolesSelect extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
  };

  newRoleText = name => (
    <Text asHTML i18n="roles_select.add_new" i18nProps={{ name }} />
  );

  render() {
    return (
      <SelectInput
        disabled={this.props.disabled}
        creatable
        newOptionText={this.newRoleText}
        clearable
        multi={false}
        options={this.props.options}
        placeholder={toI18n('add_team.roles_select.placeholder')}
        {...this.props}
        className={this.cx()}
      />
    );
  }
}
