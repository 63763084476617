import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';

import FieldRenderer from '../../FieldRenderer';

const { cx } = cxHelpers('CalSaversFormFieldRows');

const CalSaversFormFieldRows = ({ children }) => (
  <Box className={cx()} pb20 mb20>
    <Box row mb32>
      <Box grow basis="40%">
        <Box mb12>
          <Text
            fs16
            fw600
            lh={1.25}
            navy
            i18n="team.employee_profile.tax_deductions.employee_contribution_label"
          />
        </Box>

        <Box row>
          <Box>
            <FieldRenderer
              editMode
              name="amount"
              component="TextInput"
              placeholder="0.0%"
              type="percent"
            />
          </Box>
          <Box vcenter ml12>
            <Text
              fs16
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.per_pay_period_label"
            />
          </Box>
        </Box>
      </Box>

      <Box grow basis="30%" vbottom>
        <Box>
          <Box mb12>
            <Text
              fs16
              fw600
              navy
              i18n="team.employee_profile.tax_deductions.annual_limit_label"
            />
          </Box>
          <Box w={150}>
            <FieldRenderer
              editMode
              name="annual_limit"
              component="TextInput"
              placeholder="$0.00"
              type="currency"
            />
          </Box>
        </Box>
      </Box>

      <Box vbottom hright grow basis="30%">
        {children}
      </Box>
    </Box>
  </Box>
);

CalSaversFormFieldRows.propTypes = {
  values: PropTypes.object,
  isEditing: PropTypes.bool,
};

export default CalSaversFormFieldRows;
