import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCreditCard = ({
  alt = 'Credit Card Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M21.333 2.65A2.668 2.668 0 0 1 24 5.317V6.65H0V5.317A2.666 2.666 0 0 1 2.667 2.65h18.666Zm2.667 16a2.67 2.67 0 0 1-2.667 2.667H2.667A2.668 2.668 0 0 1 0 18.65v-8h24v8ZM4.667 15.984c-.367 0-.667.3-.667.666 0 .367.3.667.667.667h2.666c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666H4.667ZM10 17.317h5.333c.367 0 .667-.3.667-.667 0-.366-.3-.666-.667-.666H10c-.367 0-.667.3-.667.666 0 .367.3.667.667.667Z"
    />
  </svg>
);
export default SvgCreditCard;
