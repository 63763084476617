import colors from 'fe-design-base/styles/colors';

import { toI18n } from 'util/i18n';

import { springConfig } from './util';
export const SLICE_NAME = 'TEAM_MEMBER_VIEW';

export const TAX_ENTRY_I18N_PATH = 'new_team_drawer.tax_entry_form';

export const TAX_ENTRY_PAYMENT_METHOD_PATH = `${TAX_ENTRY_I18N_PATH}.payment_method_section`;

export const TAX_ENTRY_PAYMENT_METHOD_ERROR_PATH = `${TAX_ENTRY_PAYMENT_METHOD_PATH}.errors`;
export const PRESENT_VALID = 'present_valid';

export const PRESENT_INVALID = 'present_invalid';

export const NOT_PRESENT = 'not_present';

export const SEND_INVITE = 'send_invite';

export const DO_NOTHING = 'no_packet_no_invite';

export const RESULT = {
  FAIL: 'fail',
  SUCCESS: 'success',
};

export const EMPLOYEE_CLASSIFICATIONS = {
  tax_classification: 'w2',
  classification: 'employee',
};

export const CONTRACTOR_CLASSIFICATIONS = {
  tax_classification: '1099',
  classification: 'contractor',
};

export const CONTRACTOR_TYPES = {
  INDIVIDUAL: 'individual',
  BUSINESS: 'business',
};

export const TRANSITION_PROPS = {
  from: { cardTranslateY: '100%', bgOpacity: 0, bgPointerEvents: 'none' },
  enter: { cardTranslateY: '0%', bgOpacity: 1, bgPointerEvents: 'all' },
  leave: { cardTranslateY: '100%', bgOpacity: 0, bgPointerEvents: 'none' },
  config: springConfig,
};

export const ACTION_TYPES = {
  RESEND_EMPLOYEE_INVITE_REQUEST: 'TEAM_VIEW/RESEND_EMPLOYEE_INVITE_REQUEST',
  RESEND_EMPLOYEE_INVITE_SUCCESS: 'TEAM_VIEW/RESEND_EMPLOYEE_INVITE_SUCCESS',
  RESEND_EMPLOYEE_INVITE_FAILURE: 'TEAM_VIEW/RESEND_EMPLOYEE_INVITE_FAILURE',
  CREATE_PAYROLL_CHECK_ENTITY: 'CREATE_PAYROLL_CHECK_ENTITY',
  ADD_TEAM_MEMBER: 'ADD_TEAM_MEMBER',
  UPDATE_TEAM_MEMBER: 'UPDATE_TEAM_MEMBER',
  REHIRE_TEAM_MEMBER: 'REHIRE_TEAM_MEMBER',
  CREATE_EMPLOYEE_SUCCESS: 'ADD_TEAM/CREATE_EMPLOYEE_SUCCESS',
};

export const TAX_ENTRY_STEPS = [
  toI18n(`${TAX_ENTRY_I18N_PATH}.personal_information`),
  toI18n(`${TAX_ENTRY_I18N_PATH}.payment_method`),
];

export const HR_STYLE = {
  backgroundColor: colors.mono400,
  height: 1,
  border: 0,
  margin: 0,
};

export const EMPTY_ROSTER_OPTIONS = {
  ADD_TEAM_MEMBER: 'add_team_member',
  BULK_ADD_MEMBERS: 'bulk_add_team_members',
  POS_IMPORT_MEMBERS: 'import_team_members',
};

export const EMPTY_ROSTER_OPTIONS_PATH =
  'employees_roster.empty_roster_options';

const I18N_ACCESS_LEVELS_PATH = 'team.access_levels';
export const MANAGERIAL = [
  toI18n(`${I18N_ACCESS_LEVELS_PATH}.manager`),
  toI18n(`${I18N_ACCESS_LEVELS_PATH}.general_manager`),
];

export const ACCESS_LEVEL_OPTIONS = [
  {
    label: toI18n(`${I18N_ACCESS_LEVELS_PATH}.employee`),
    value: toI18n(`${I18N_ACCESS_LEVELS_PATH}.employee`),
  },
  {
    label: toI18n(`${I18N_ACCESS_LEVELS_PATH}.manager`),
    value: toI18n(`${I18N_ACCESS_LEVELS_PATH}.manager`),
  },
  {
    label: toI18n(`${I18N_ACCESS_LEVELS_PATH}.general_manager`),
    value: toI18n(`${I18N_ACCESS_LEVELS_PATH}.general_manager`),
  },
];
