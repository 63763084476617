import React, { PureComponent } from 'react';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import withCurrentRoute from 'components/hocs/withCurrentRoute';
import Text from 'components/Text';

import cxHelpers from 'util/className';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@withCurrentRoute
@withNavItemData('insurance')
@cxHelpers('InsuranceNavItem')
export default class InsuranceNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    currentRoute: PropTypes.string.isRequired,
  };

  render() {
    const { url, currentRoute, ...props } = omit(this.props, 'whiteText');

    return (
      <NavItem
        {...props}
        href={url}
        active={currentRoute === url}
        className={this.cx({})}
      >
        <Text fs14 i18n="nav_links.insurance.top_level" />
      </NavItem>
    );
  }
}
