import destroy from './destroy';
import destroyEntityRelationship from './destroyEntityRelationship';
import { HAS_MANY } from './relationshipTypes';
import { getSchema } from './schema';

const getOrInitEntity = (state, type, id) => {
  state[type] ||= {};
  state[type][id] ||= {
    attributes: {},
    relationships: {},
  };
  return state[type][id];
};

const updatedRelationshipData = (schema, inverseOf, id, type, data) => {
  if (schema.relationships[inverseOf].relationshipType === HAS_MANY) {
    const list = data || [];

    // If already in relationship list, ignore
    if (list.some(v => v.id === id && v.type === type)) return list;

    list.push({ id, type });

    return list;
  }

  return { type, id };
};

const updateRelationship = (state, fromType, fromId, type, id, inverseOf) => {
  const schema = getSchema(type);

  if (schema === undefined) return;

  if (!state[type]?.[id]) return;

  if (!schema.relationships[inverseOf]) {
    throw new Error(
      `ENTITY SCHEMA ERROR: missing relationship ${inverseOf} on ${type}`
    );
  }

  const currentData = state[type][id].relationships[inverseOf]?.data;

  getOrInitEntity(state, type, id).relationships[inverseOf] = {
    data: updatedRelationshipData(
      schema,
      inverseOf,
      fromId,
      fromType,
      currentData
    ),
  };
};

const retainedMapKey = (type, id) => [type, id].join(':');

const clearOldRelationshipData = (
  state,
  fromType,
  fromId,
  name,
  relationshipType,
  relationshipData
) => {
  const oldRelationshipData =
    state[fromType]?.[fromId]?.relationships?.[name]?.data;

  if (!oldRelationshipData) return;

  if (relationshipType === HAS_MANY) {
    const retainedMap = (relationshipData || []).reduce((map, relationship) => {
      map[retainedMapKey(relationship.type, relationship.id)] = true;
      return map;
    }, {});
    return oldRelationshipData.forEach(item => {
      if (retainedMap[retainedMapKey(item.type, item.id)]) return;

      destroyEntityRelationship(
        state,
        fromType,
        fromId,
        name,
        item.type,
        item.id
      );
    });
  }

  if (
    oldRelationshipData.type === relationshipData?.type &&
    oldRelationshipData.id === relationshipData?.id
  )
    return;

  destroyEntityRelationship(
    state,
    fromType,
    fromId,
    name,
    oldRelationshipData.type,
    oldRelationshipData.id
  );
};

const updateRelationships = (state, fromType, fromId, relationships) => {
  const schema = getSchema(fromType);

  if (schema === undefined) return;

  Object.entries(schema.relationships).forEach(
    ([name, { relationshipType, inverseOf }]) => {
      const relationshipData = relationships[name]?.data;

      if (relationshipData === undefined) return;

      clearOldRelationshipData(
        state,
        fromType,
        fromId,
        name,
        relationshipType,
        relationshipData
      );

      if (relationshipType === HAS_MANY) {
        relationshipData.forEach(data => {
          updateRelationship(
            state,
            fromType,
            fromId,
            data.type,
            data.id,
            inverseOf
          );
        });
        return;
      }

      if (!relationshipData) return;

      updateRelationship(
        state,
        fromType,
        fromId,
        relationshipData.type,
        relationshipData.id,
        inverseOf
      );
    }
  );
};

const updateRelationshipsAndMerge = (
  state,
  fromType,
  fromId,
  relationships = {}
) => {
  updateRelationships(state, fromType, fromId, relationships);
  Object.assign(state[fromType][fromId].relationships, relationships);
};

const digestEntity = (state, { id, type, attributes }) => {
  Object.assign(getOrInitEntity(state, type, id), { id, type, attributes });
};

const digestEntityArray = (state, entities) => {
  entities.forEach(entity => {
    digestEntity(state, entity);
  });
};

const digestEntityRelationships = (state, { id, type, relationships = {} }) => {
  updateRelationshipsAndMerge(state, type, id, relationships);
};

const digestEntityArrayRelationships = (state, entities) => {
  entities.forEach(entity => {
    digestEntityRelationships(state, entity);
  });
};

const digestData = (state, data) => {
  if (!data) return;

  if (Array.isArray(data)) {
    digestEntityArray(state, data);
    return;
  }
  digestEntity(state, data);
};

const digestRelationships = (state, data) => {
  if (!data) return;

  if (Array.isArray(data)) {
    digestEntityArrayRelationships(state, data);
    return;
  }
  digestEntityRelationships(state, data);
};

const digestPayloadData = (state, { data, included }) => {
  digestData(state, data);
  digestData(state, included);
};

const digestPayloadRelationships = (state, { data, included }) => {
  digestRelationships(state, data);
  digestRelationships(state, included);
};

const digestDeletes = (state, payload) => {
  if (!payload.meta?.deleted) return;

  payload.meta.deleted.forEach(({ type, id }) => {
    destroy(state, type, id);
  });
};

const digest = (state, payload) => {
  digestPayloadData(state, payload);
  digestPayloadRelationships(state, payload);
  digestDeletes(state, payload);
};

export default digest;
