export const PAYROLL_RUN_TYPES = {
  standard: 'standard',
  off_cycle: 'off_cycle',
};

export const STEPS = {
  earningsStep: 1,
  netPayStep: 2,
  submitStep: 3,
};

export const OFF_CYCLE_STEPS = {
  setupStep: 1,
  earningsStep: 2,
  netPayStep: 3,
  submitStep: 4,
};
