import { IllustrationProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';

import month_calendar from './imageFiles/month_calendar.png';

const MonthCalendar = (props: IllustrationProps) => (
  <Box
    w={72}
    {...props}
    minw={72}
    className={`FDBIllustration${
      props?.className ? ` ${props.className}` : ''
    }`}
  >
    <img
      src={month_calendar}
      style={{ width: '100%' }}
      alt="Month Calendar Illustration"
    />
  </Box>
);
export default MonthCalendar;
