import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';

import { showModal, showNewLocationsModal } from 'actions/modals';

import { downgradeTierThunk } from 'features/biller/implementations/homebase_biller_support';
import {
  SINGLE_TRANSACTION,
  SLICE_NAME,
} from 'features/biller/implementations/stripe/constants';
import {
  fetchAdminBillerLocations,
  fetchAdminProducts,
  fetchAdminTiers,
  fetchBillerLocations,
  showAdminChangeTierModal,
  showChangeTierModal,
} from 'features/biller/supportActions';
import {
  STRIPE_ADD_HIRING_BOOST_MODAL,
  STRIPE_CHANGE_PAYMENT_METHOD_MODAL,
} from 'features/modals/constants';

import { hiringBoostAdded } from '../../noticeActions';

import { getPaymentMethod } from './stripeClient';

export const asyncAction = createAsyncThunk(
  `${SLICE_NAME}/asyncAction`,
  (action, { dispatch }) => dispatch(action)
);

export const fetchCreditCards = createAsyncThunk(
  `${SLICE_NAME}/fetchCreditCards`,
  (_, { rejectWithValue }) =>
    fetchJSON('/biller/credit_cards.json').catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const fetchAdminCreditCards = createAsyncThunk(
  `${SLICE_NAME}/fetchAdminCreditCards`,
  (companyId, { rejectWithValue }) =>
    fetchJSON(`/admin/companies/${companyId}/biller_credit_cards.json`).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    )
);

export const adminChangeTier = createAsyncThunk(
  `${SLICE_NAME}/adminChangeTier`,
  ({ biller, companyId, location }, { dispatch }) =>
    Promise.all([
      dispatch(
        fetchAdminProducts({ companyId, locationId: location.get('id') })
      ).then(unwrapResult),
      dispatch(fetchAdminBillerLocations(companyId)).then(unwrapResult),
      dispatch(fetchAdminCreditCards(companyId)).then(unwrapResult),
      dispatch(fetchAdminTiers(companyId)).then(unwrapResult),
      dispatch(showAdminChangeTierModal({ biller, location, companyId })),
    ])
);

export const changeTier = createAsyncThunk(
  `${SLICE_NAME}/changeTier`,
  (
    { tier, billingCycle, biller, newLocations, payrollLocationAdded },
    { dispatch }
  ) =>
    Promise.all([
      dispatch(fetchBillerLocations()),
      dispatch(fetchCreditCards()),
    ]).then(() =>
      dispatch(
        showChangeTierModal({
          tier,
          billingCycle,
          biller,
          newLocations,
          payrollLocationAdded,
        })
      )
    )
);

export const downgradeTier = downgradeTierThunk(SLICE_NAME);

export const requestPaymentMethod = createAsyncThunk(
  `${SLICE_NAME}/requestPaymentMethod`,
  () => getPaymentMethod()
);

export const updatePaymentMethod = createAsyncThunk(
  `${SLICE_NAME}/updatePaymentMethod`,
  (
    { locationId, companyId, finishUpdate, subscriptionType },
    { dispatch, rejectWithValue }
  ) =>
    dispatch(requestPaymentMethod())
      .then(unwrapResult)
      .then(({ paymentMethodId, newPaymentMethod = false }) => {
        const params = {
          location_id: locationId,
          company_id: companyId,
          payment_method_id: paymentMethodId,
          new_payment_method: newPaymentMethod,
          subscription_type: subscriptionType,
        };
        return putJSON('/biller/payment_methods.json', params)
          .then(finishUpdate)
          .catch(err =>
            err.response.json().then(body => rejectWithValue(body))
          );
      })
      .catch(err => rejectWithValue(err))
);

export const moduleUpdatePaymentMethod = createAsyncThunk(
  `${SLICE_NAME}/moduleUpdatePaymentMethod`,
  (
    { locationId, companyId, paymentMethodId, retried = false },
    { rejectWithValue }
  ) => {
    const params = {
      location_id: locationId,
      company_id: companyId,
      payment_method_id: paymentMethodId,
      new_payment_method: false,
      vetted_payment_method: true,
      charge_already_retried: retried,
      subscription_type: 'tier',
    };
    return putJSON('/biller/payment_methods.json', params).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    );
  }
);

export const changePaymentMethod = createAsyncThunk(
  `${SLICE_NAME}/changePaymentMethod`,
  ({ locationId, subscriptionType }, { dispatch }) =>
    dispatch(fetchCreditCards()).then(() =>
      dispatch(
        showModal(STRIPE_CHANGE_PAYMENT_METHOD_MODAL, {
          deprecatedModal: true,
          auto: true,
          locationId,
          subscriptionType,
        })
      )
    )
);

export const createHiringBoost = createAsyncThunk(
  `${SLICE_NAME}/createHiringBoost`,
  (
    { biller, partnerName, jobRequestId, companyId, discountCode = null },
    { dispatch, rejectWithValue }
  ) => {
    const i18nKey = `hiring.job_post.success_modal.${partnerName}_boost.single_transaction`;

    return dispatch(requestPaymentMethod())
      .then(unwrapResult)
      .then(({ paymentMethodId, newPaymentMethod = false }) => {
        const params = {
          biller,
          isCraigslist: partnerName === 'craigslist',
          job_request_id: jobRequestId,
          partner_name: partnerName,
          claimed_via: SINGLE_TRANSACTION,
          token: paymentMethodId,
          new_payment_method: newPaymentMethod,
          company_id: companyId,
          discount_code: discountCode,
        };

        return postJSON('/hiring/billing/paid_posting.json', params)
          .then(() => postJSON('/hiring/job_request_boosts.json', params))
          .then(result =>
            dispatch(
              hiringBoostAdded({
                i18nKey,
                company_id: companyId,
                ...result,
              })
            )
          )
          .catch(err =>
            err.response.json().then(body => rejectWithValue(body))
          );
      })
      .catch(err => rejectWithValue(err));
  }
);

export const addHiringBoost = createAsyncThunk(
  `${SLICE_NAME}/addHiringBoost`,
  ({ biller, partnerName, price, boostDuration, jobRequestId }, { dispatch }) =>
    dispatch(fetchCreditCards()).then(() =>
      dispatch(
        showModal(STRIPE_ADD_HIRING_BOOST_MODAL, {
          deprecatedModal: true,
          auto: true,
          biller,
          partnerName,
          price,
          boostDuration,
          jobRequestId,
        })
      )
    )
);

export const newLocations = createAsyncThunk(
  `${SLICE_NAME}/newLocations`,
  ({ biller }, { dispatch }) => {
    dispatch(showNewLocationsModal(biller));
  }
);

export const createSetupIntent = createAsyncThunk(
  `${SLICE_NAME}/createSetupIntent`,
  ({ paymentMethodId }, { rejectWithValue }) =>
    postJSON('/biller/stripe/setup_intents.json', {
      payment_method_id: paymentMethodId,
    }).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const fetchPaymentIntent = createAsyncThunk(
  `${SLICE_NAME}/fetchPaymentIntent`,
  ({ payroll }, { rejectWithValue }) =>
    fetchJSON(`/biller/stripe/payment_intents.json?payroll=${payroll}`).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    )
);
