import React, { useCallback, useMemo } from 'react';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'fe-design-base/molecules/Button';
import FDBDatePickerField from 'fe-design-base/organisms/FDBDatePickerField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
import { datePickerFormat, df, formatDate, today } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import FieldRenderer from '../../FieldRenderer';

import {
  BENEFIT_TYPE_MAP,
  BENEFIT_TYPE_OPTIONS,
  HSA_LIMIT_OPTIONS,
} from './constants.js';
import PercentToolTip from './PercentToolTip';

const { cxEl } = cxHelpers('BenefitFormFields');

const RADIO_GROUP_STYLE = {
  marginLeft: 0,
  marginRight: 8,
};

const BenefitFormFields = ({
  values,
  isGmod,
  initialValues,
  onClickRemove,
  canDelete = false,
  isRemoveButtonLoading = false,
  showFormWithSteps,
}) => {
  const contributionGroupOptions = useMemo(
    () => [
      {
        value: 'amount',
        description: (
          <Text
            fs={showFormWithSteps ? 14 : 16}
            fw400
            lh={1.25}
            navy
            i18n="team.employee_profile.tax_deductions.fixed_amount_label"
          />
        ),
      },
      {
        value: 'percent',
        description: (
          <PercentToolTip
            content={toI18n(
              'team.employee_profile.tax_deductions.percent_tooltip_content'
            )}
            i18n="team.employee_profile.tax_deductions.percent_label"
            showFormWithSteps={showFormWithSteps}
          />
        ),
      },
    ],
    [showFormWithSteps]
  );

  const blockDatesBefore = useMemo(() => {
    if (!isGmod) {
      return today(df('parsable_reversed'));
    }
  }, [isGmod]);

  const { setFieldValue } = useFormikContext();

  const handleStartDateBlur = useCallback(() => {
    setFieldValue('start_date', formatDate(values.start_date, 'parsable_date'));
  }, [setFieldValue, values.start_date]);

  const handleEndDateBlur = useCallback(() => {
    setFieldValue('end_date', formatDate(values.end_date, 'parsable_date'));
  }, [setFieldValue, values.end_date]);

  return (
    <Box pb20 bb mb20>
      <Box row mb32>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.type_label"
            />
            :
          </Box>
          <Box w={242}>
            <FieldRenderer
              className={cxEl('benefit_type')}
              editMode={!initialValues.benefit_type}
              name="benefit_type"
              component="SelectInput"
              options={BENEFIT_TYPE_OPTIONS}
              hideClear
              savedValue={
                initialValues.benefit_type
                  ? BENEFIT_TYPE_MAP[initialValues.benefit_type].name
                  : null
              }
            />
          </Box>
        </Box>

        <Box grow basis={150}>
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.name_label"
            />
            :
          </Box>
          <Box w={242}>
            <FieldRenderer editMode name="benefit_name" component="TextInput" />
          </Box>
          <Text
            fs14
            fw400
            lh={1.25}
            navy
            i18n="team.employee_profile.tax_deductions.name_description_label"
          />
        </Box>
      </Box>
      <Box column mb32>
        {values.benefit_type === 'hsa' && (
          <Box grow basis={0} mb32>
            <Box mb12>
              <Text
                fs={showFormWithSteps ? 14 : 16}
                fw600
                lh={1.25}
                navy
                i18n="team.employee_profile.tax_deductions.hsa.title"
              />
              :
            </Box>
            <Box w={242}>
              <FieldRenderer
                className={cxEl('benefit_type')}
                editMode
                name="hsa_contribution_limit"
                component="SelectInput"
                options={HSA_LIMIT_OPTIONS}
                hideClear
              />
            </Box>
          </Box>
        )}
        {values.benefit_type && (
          <Box grow bradius bg="blueLightest" pa20>
            <Text
              fs16
              fw400
              lh={1.25}
              navy
              i18n={BENEFIT_TYPE_MAP[values.benefit_type].description}
            />
          </Box>
        )}
      </Box>
      <Box row mb32>
        <Box grow basis={0}>
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.employee_contribution_label"
            />
            :
          </Box>
          <FieldRenderer
            editMode
            name="employee_contribution_type"
            component="RadioGroup"
            row
            options={contributionGroupOptions}
            radioStyle={RADIO_GROUP_STYLE}
            labelStyle={{
              marginLeft: 0,
              width: 160,
            }}
            inputProps={{ description: 'other' }}
          />
          <Box row>
            <FieldRenderer
              editMode
              name="employee_contribution"
              component="TextInput"
              inputProps={{
                decimalScale: 2,
              }}
              placeholder={
                values.employee_contribution_type === 'amount'
                  ? '$0.00'
                  : '0.00%'
              }
              type={
                values.employee_contribution_type === 'amount'
                  ? 'currency'
                  : 'percent'
              }
            />
            <Box vcenter ml12>
              <Text
                fs={showFormWithSteps ? 14 : 16}
                fw600
                lh={1.25}
                navy
                i18n="team.employee_profile.tax_deductions.per_pay_period_label"
              />
            </Box>
          </Box>
        </Box>

        <Box grow basis={150}>
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.company_contribution_label"
            />
            :
          </Box>
          <FieldRenderer
            editMode
            name="company_contribution_type"
            component="RadioGroup"
            row
            options={contributionGroupOptions}
            radioStyle={RADIO_GROUP_STYLE}
            labelStyle={{ width: 160 }}
          />
          <Box row>
            <FieldRenderer
              editMode
              name="company_contribution"
              component="TextInput"
              inputProps={{
                decimalScale: 2,
              }}
              placeholder={
                values.company_contribution_type === 'amount'
                  ? '$0.00'
                  : '0.00%'
              }
              type={
                values.company_contribution_type === 'amount'
                  ? 'currency'
                  : 'percent'
              }
            />
            <Box vcenter ml12>
              <Text
                fs={showFormWithSteps ? 14 : 16}
                fw600
                lh={1.25}
                navy
                i18n="team.employee_profile.tax_deductions.per_pay_period_label"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box row mb32>
        <Box grow={2} basis={0}>
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.start_date_label"
            />
            :
          </Box>
          <Box maxw={220} className={cxEl('start-date')}>
            <FDBDatePickerField
              hideClearIcon
              name="start_date"
              blockDatesBefore={blockDatesBefore}
              value={
                values.start_date ? datePickerFormat(values.start_date) : null
              }
              onBlur={handleStartDateBlur}
            />
          </Box>
        </Box>
        <Box grow={1} basis={150}>
          <Box mb12>
            <Text
              fs={showFormWithSteps ? 14 : 16}
              fw600
              lh={1.25}
              navy
              i18n="team.employee_profile.tax_deductions.end_date_optional_label"
            />
            :
          </Box>
          <Box maxw={220} className={cxEl('end-date')}>
            <FDBDatePickerField
              hideClearIcon
              name="end_date"
              blockDatesBefore={values.start_date}
              value={values.end_date ? datePickerFormat(values.end_date) : null}
              onBlur={handleEndDateBlur}
            />
          </Box>
        </Box>
        <Box vbottom hright grow={1} basis={0}>
          {canDelete && (
            <Box className={cxEl('remove-benefit-button')}>
              <Button
                size={showFormWithSteps ? 'small' : 'medium'}
                variant="secondaryDestructive"
                onClick={onClickRemove}
                isLoading={isRemoveButtonLoading}
              >
                {toI18n('team.employee_profile.tax_deductions.remove_benefit')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
      <Box row mb12>
        <Box row grow basis={0} bradius bg="grayLight" pa20 vcenter>
          <FontAwesomeIcon icon={faExclamationCircle} size="lg" />
          <Text
            ml8
            fs16
            fw400
            lh={1.25}
            navy
            i18n="team.employee_profile.tax_deductions.reminder"
            asHTML
          />
        </Box>
      </Box>
    </Box>
  );
};

BenefitFormFields.propTypes = {
  values: PropTypes.object,
  onClickRemove: PropTypes.func,
  canDelete: PropTypes.bool,
};

export default BenefitFormFields;
