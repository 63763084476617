import {
  ACTION_FETCH,
  ACTION_POST,
  ACTION_UPDATE,
} from 'fe-core/module-constants';
import { createResource } from 'fe-core/Resources';
import { create, get, post } from 'fe-core/Resources/actions';
import windowSentry from 'fe-core/util/windowSentry';

const Schedule = createResource({
  name: 'schedule',
  path: '/schedule_builder/schedule',
});

Schedule.fetch = ({ payload, id, beforeDigestCallback }) => {
  if ((!payload.start_date || !payload.end_date) && window.Sentry) {
    windowSentry().captureMessage(
      'Schedule.fetch was called without a date range',
      'debug'
    );
  }

  return get({
    name: Schedule.name,
    action: ACTION_FETCH,
    id,
    path: '/schedule_builder/schedule',
    payload,
    beforeDigestCallback,
  });
};

Schedule.publish = ({ beforeDigestCallback, ...payload }) =>
  create({
    path: '/schedule_builder/schedule/publish',
    name: Schedule.name,
    action: ACTION_UPDATE,
    payload,
    beforeDigestCallback,
  });

Schedule.resendNotifications = payload =>
  post({
    path: '/schedule_builder/schedule/resend_notifications',
    name: 'schedule',
    action: ACTION_POST,
    payload,
  });

Schedule.copy = payload =>
  post({
    path: '/schedule_builder/schedule/copy',
    name: 'schedule',
    action: ACTION_POST,
    payload,
  });

Schedule.revert = payload =>
  post({
    path: '/schedule_builder/schedule/revert',
    name,
    action: ACTION_POST,
    payload,
  });

Schedule.clear = payload =>
  post({
    path: '/schedule_builder/schedule/clear',
    name,
    action: ACTION_POST,
    payload,
  });

export default Schedule;
