import { createSelector } from 'reselect';

const buildTeamEmailsSelector = key => state =>
  state.getIn(['teamEmails', key]);

export const getTeamAnnouncements = buildTeamEmailsSelector('announcements');
export const getCurrentEmailId = buildTeamEmailsSelector('currentEmailId');
export const getShowNewEmailView = buildTeamEmailsSelector('showNewEmailView');

export const getCurrentEmail = createSelector(
  getCurrentEmailId,
  getTeamAnnouncements,
  (id, emails) => id && emails && emails.find(e => id === e.get('id'))
);

export const showingIndexView = state =>
  !(getCurrentEmailId(state) || getShowNewEmailView(state));
