import * as routes from 'api';

import {
  getApplications,
  getCurrentConversationId,
} from 'selectors/applicantMessenger';

import { MESSENGER_VIEW_DRAWER_KEY } from 'features/drawers/keys';
import { MESSAGE_DRAWER_TABS } from 'features/messengerv2/constants';

import {
  fetchingInitialMessagesRequestId,
  fetchingMoreMessagesRequestId,
} from 'util/applicantMessenger';
import {
  createAsyncGetAction,
  createAsyncPostAction,
  createAsyncPutAction,
} from 'util/redux';

export const actionTypes = {
  SHOW_APPLICANT_MESSENGER: 'APPLICANT_MESSENGER/SHOW_APPLICANT_MESSENGER',
  FETCH_APPLICATION_FOR_MESSENGER_REQUEST:
    'APPLICANT_MESSENGER/FETCH_APPLICATION_FOR_MESSENGER_REQUEST',
  FETCH_APPLICATION_FOR_MESSENGER_SUCCESS:
    'APPLICANT_MESSENGER/FETCH_APPLICATION_FOR_MESSENGER_SUCCESS',
  FETCH_APPLICATION_FOR_MESSENGER_FAILURE:
    'APPLICANT_MESSENGER/FETCH_APPLICATION_FOR_MESSENGER_FAILURE',
  HIDE_APPLICANT_MESSENGER: 'APPLICANT_MESSENGER/HIDE_APPLICANT_MESSENGER',
  RECEIVE_NEW_MESSAGE: 'APPLICANT_MESSENGER/RECEIVE_NEW_MESSAGE',
  FETCH_CONVERSATION_MESSAGES_REQUEST:
    'APPLICANT_MESSENGER/FETCH_CONVERSATION_MESSAGES_REQUEST',
  FETCH_CONVERSATION_MESSAGES_SUCCESS:
    'APPLICANT_MESSENGER/FETCH_CONVERSATION_MESSAGES_SUCCESS',
  FETCH_CONVERSATION_MESSAGES_FAILURE:
    'APPLICANT_MESSENGER/FETCH_CONVERSATION_MESSAGES_FAILURE',
  FETCH_CONVERSATIONS_REQUEST:
    'APPLICANT_MESSENGER/FETCH_CONVERSATIONS_REQUEST',
  FETCH_CONVERSATIONS_SUCCESS:
    'APPLICANT_MESSENGER/FETCH_CONVERSATIONS_SUCCESS',
  FETCH_CONVERSATIONS_FAILURE:
    'APPLICANT_MESSENGER/FETCH_CONVERSATIONS_FAILURE',
  SEND_MESSAGE_REQUEST: 'APPLICANT_MESSENGER/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: 'APPLICANT_MESSENGER/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE: 'APPLICANT_MESSENGER/SEND_MESSAGE_FAILURE',
  READ_MESSAGE_REQUEST: 'APPLICANT_MESSENGER/READ_MESSAGE_REQUEST',
  READ_MESSAGE_SUCCESS: 'APPLICANT_MESSENGER/READ_MESSAGE_SUCCESS',
  READ_MESSAGE_FAILURE: 'APPLICANT_MESSENGER/READ_MESSAGE_FAILURE',
};

const fetchApplicationForMessenger = applicationId =>
  createAsyncGetAction(
    routes.applicationConversationApplicationRoute({
      job_application_id: applicationId,
    }),
    [
      { type: actionTypes.FETCH_APPLICATION_FOR_MESSENGER_REQUEST },
      { type: actionTypes.FETCH_APPLICATION_FOR_MESSENGER_SUCCESS },
      { type: actionTypes.FETCH_APPLICATION_FOR_MESSENGER_FAILURE },
    ],
    { bailout: state => getApplications(state).has(applicationId) }
  );

// re-use this method and pass in additional opts for message drawer
export const showApplicantMessenger =
  (applicationId, useLinkInHeader = false) =>
  dispatch => {
    dispatch({
      type: actionTypes.SHOW_APPLICANT_MESSENGER,
      payload: {
        applicationId,
        useLinkInHeader,
        drawerOpts: { tab: MESSAGE_DRAWER_TABS.APPLICANTS },
        drawerKey: MESSENGER_VIEW_DRAWER_KEY,
        drawerOpen: true,
      },
    });

    if (applicationId) {
      dispatch(fetchApplicationForMessenger(applicationId));
    }
  };

export const hideApplicantMessenger = () => ({
  type: actionTypes.HIDE_APPLICANT_MESSENGER,
  payload: {
    drawerKey: MESSENGER_VIEW_DRAWER_KEY,
    drawerOpen: false,
  },
});

export const receiveNewMessage = message => (dispatch, getState) => {
  // If message received for a conversation that's not currently being viewed, set that
  // conversation's has_unread attribute to false.
  const setConversationHasUnread =
    getCurrentConversationId(getState()) !==
    message.application_conversation_id;

  dispatch({
    type: actionTypes.RECEIVE_NEW_MESSAGE,
    payload: { message, setConversationHasUnread },
  });
};

export const fetchConversations = lastConversationId =>
  createAsyncGetAction(
    routes.applicationConversationsRoute({
      last_conversation_id: lastConversationId,
    }),
    [
      {
        type: actionTypes.FETCH_CONVERSATIONS_REQUEST,
        meta: { requestId: 'FETCH_APPLICATION_CONVERSATIONS' },
      },
      {
        type: actionTypes.FETCH_CONVERSATIONS_SUCCESS,
        meta: { requestId: 'FETCH_APPLICATION_CONVERSATIONS' },
      },
      {
        type: actionTypes.FETCH_CONVERSATIONS_FAILURE,
        meta: { requestId: 'FETCH_APPLICATION_CONVERSATIONS' },
      },
    ]
  );

export const fetchConversationMessages = (conversationId, lastMessageId) => {
  const requestId = lastMessageId
    ? fetchingMoreMessagesRequestId(conversationId)
    : fetchingInitialMessagesRequestId(conversationId);

  return createAsyncGetAction(
    routes.applicationConversationMessagesRoute(conversationId, {
      last_message_id: lastMessageId,
    }),
    [
      {
        type: actionTypes.FETCH_CONVERSATION_MESSAGES_REQUEST,
        meta: { requestId, conversationId },
      },
      {
        type: actionTypes.FETCH_CONVERSATION_MESSAGES_SUCCESS,
        meta: { requestId, conversationId },
      },
      {
        type: actionTypes.FETCH_CONVERSATION_MESSAGES_FAILURE,
        meta: { requestId, conversationId },
      },
    ]
  );
};

export const sendMessage = (applicationId, content) =>
  createAsyncPostAction(
    routes.applicationConversationCreateMessageRoute({
      job_application_id: applicationId,
      content,
    }),
    [
      { type: actionTypes.SEND_MESSAGE_REQUEST },
      { type: actionTypes.SEND_MESSAGE_SUCCESS },
      { type: actionTypes.SEND_MESSAGE_FAILURE },
    ]
  );

export const readMessage = (conversationId, messageId) =>
  createAsyncPutAction(
    routes.applicationMessagesMarkAsReadRoute(conversationId, messageId),
    [
      { type: actionTypes.READ_MESSAGE_REQUEST, meta: { conversationId } },
      { type: actionTypes.READ_MESSAGE_SUCCESS, meta: { conversationId } },
      { type: actionTypes.READ_MESSAGE_FAILURE, meta: { conversationId } },
    ]
  );
