import { createTheme } from '@material-ui/core/styles';

import { colors, typography } from './constants';

// All options: https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js
const muiTheme = createTheme({
  palette: {
    primary: {
      light: colors.blue,
      main: colors.purple,
      dark: colors.navy,
      contrastText: colors.white,
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: typography.baseFontFamily,
  },
  overrides: {
    MuiList: {
      padding: {
        'padding-top': 0,
        'padding-bottom': 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: 4,
        '&$checked': {
          color: colors.green,
        },
      },
      checked: {},
    },
    MuiRadio: {
      root: {
        padding: 0,
        'margin-right': 16,
        '&$checked': {
          color: colors.purple,
        },
      },
      checked: {},
    },
    MuiFormControlLabel: {
      root: {
        color: colors.navy,
        'margin-left': 0,
        'margin-right': 0,
        width: '100%',
      },
    },
  },
});

export default muiTheme;
