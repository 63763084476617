import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import { compose } from 'redux';

import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUxHandler } from 'util/uxEvents';

import MultiLocationView from './MultiLocationView';
import PreDeadlineV2View from './PreDeadlineV2View';
import PreDeadlineView from './PreDeadlineView';
import { selectEnforcementBannerVariant } from './selectors';

const productAreaMap = {
  multiLocation: PRODUCT_AREAS.BILLING,
  preDeadline: PRODUCT_AREAS.GHOST_PACKAGING,
  preDeadlineV2: PRODUCT_AREAS.GHOST_PACKAGING,
};

const eventCategoryMap = {
  preDeadline: EVENT_CATEGORIES.EMPLOYEE_CAP,
  preDeadlineV2: EVENT_CATEGORIES.EMPLOYEE_CAP,
  multiLocation: EVENT_CATEGORIES.MULTILOCATION_ENFORCEMENT,
};

const ENFORCMENT_COMPONENT_MAP = {
  multiLocation: MultiLocationView,
  preDeadlineV2: PreDeadlineV2View,
  preDeadline: PreDeadlineView,
};

export const EnforcementBanner = ({ bannerVariant }) => {
  const [showBanner, setShowBanner] = useState(Boolean(bannerVariant));

  const uxTrackingProperties = useMemo(
    () => ({
      productArea: productAreaMap[bannerVariant],
      eventCategory: eventCategoryMap[bannerVariant],
    }),
    [bannerVariant]
  );

  const trackViewEvent = useTrackUxHandler(
    EVENT_ACTIONS.BANNER_VIEWED,
    TRACK_ACTION_TYPES.VIEW,
    { banner_version: bannerVariant, ...uxTrackingProperties }
  );

  const trackCloseEvent = useTrackUxHandler(
    EVENT_ACTIONS.DISMISS_CLICKED,
    TRACK_ACTION_TYPES.CLICK,
    uxTrackingProperties
  );

  const handleCTAClickTracking = useTrackUxHandler(
    EVENT_ACTIONS.CLICK_CTA,
    TRACK_ACTION_TYPES.CLICK,
    uxTrackingProperties
  );

  useEffect(() => {
    if (bannerVariant) trackViewEvent();
  }, [bannerVariant, trackViewEvent]);

  const handleClose = useCallback(() => {
    trackCloseEvent();
    setShowBanner(false);
  }, [trackCloseEvent, setShowBanner]);

  if (!showBanner) return null;

  const EnforcementComponent = ENFORCMENT_COMPONENT_MAP[bannerVariant];

  return (
    <Box h={58}>
      <EnforcementComponent
        onClose={handleClose}
        onClickCTATracking={handleCTAClickTracking}
      />
    </Box>
  );
};

export default compose(
  connect(
    state => ({
      bannerVariant: selectEnforcementBannerVariant(state),
    }),
    {}
  )
)(EnforcementBanner);
