import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { SLICE_NAME } from './constants';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: fromJS({}),
});

export const actions = slice.actions;
export const reducer = slice.reducer;
