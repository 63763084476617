import * as routes from 'api';

import { toI18n } from 'util/i18n';
import { createAsyncPostAction, withAlerts } from 'util/redux';
import { logHiringEvent } from 'util/tracking';

export const actionTypes = {
  SUBMIT_APPLICATION_FORM_REQUEST:
    'HIRING/ADD_APPLICANT_MANUALLY/SUBMIT_APPLICATION_FORM_REQUEST',
  SUBMIT_APPLICATION_FORM_SUCCESS:
    'HIRING/ADD_APPLICANT_MANUALLY/SUBMIT_APPLICATION_FORM_SUCCESS',
  SUBMIT_APPLICATION_FORM_FAILURE:
    'HIRING/ADD_APPLICANT_MANUALLY/SUBMIT_APPLICATION_FORM_FAILURE',
};

export const submitApplicationForm =
  (payload, onClickConfirm, { onSuccess, onDone }) =>
  dispatch => {
    const data = {
      hiring_applicant: payload.form,
      location_id: payload.location_id,
      job_request_id: payload.job_request_id,
      source: payload.application_source,
      existing_applicant_confirmed: payload.existing_applicant_confirmed,
    };

    return dispatch(
      withAlerts(
        createAsyncPostAction(
          routes.applicantsCreateRoute(),
          [
            actionTypes.SUBMIT_APPLICATION_FORM_REQUEST,
            actionTypes.SUBMIT_APPLICATION_FORM_SUCCESS,
            actionTypes.SUBMIT_APPLICATION_FORM_FAILURE,
          ],
          { body: data }
        ),
        {
          success: toI18n('hiring.add_applicant_manually.application_sent'),
          onSuccess: res => {
            logHiringEvent({
              product_location: 'review_applicants',
              action: 'add_applicant_manually_success',
            });

            window.Homebase.RailsReactBridge.navigateToReactView(
              res.payload[0].job_application_profile_path
            );

            if (onSuccess) onSuccess(res);
          },
          onError: res => res,
          onDone: res => {
            if (onDone) onDone(res);
          },
        }
      )
    );
  };
