import { useCallback } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import { compose } from 'redux';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { changeToEssentialsTier } from '../../biller/slice';

import { selectGhostEmployeeCapV1ExperimentValue } from './selectors';
import { toMonthDayOrdinal } from './util';

const { cx, cxEl } = cxHelpers('PreDeadlineView');

export const PreDeadlineView = ({
  onChangeToEssentialsTier,
  employeeCapDate,
  onClose,
  onClickCTATracking,
}) => {
  const bannerText = toI18n('enforcement.employee_cap.banner_text', {
    props: { date: toMonthDayOrdinal(employeeCapDate) },
  });

  const handleUpgrade = useCallback(() => {
    onClickCTATracking();
    onChangeToEssentialsTier({
      billingFrequency: 'monthly',
      workflowSource: 'employee_cap_experiment',
    });
  }, [onClickCTATracking, onChangeToEssentialsTier]);

  return (
    <Box row vcenter spacebetween ph={16} className={cx()} bgcolor="orange300">
      <Text variant="body" color="mono0">
        {bannerText}
      </Text>

      <Box row vcenter>
        <Box mr={50}>
          <Button variant="tertiary" size="small" onClick={handleUpgrade}>
            <Box row>
              <Text variant="bodyBold" color="mono0" underline>
                {toI18n('enforcement.banner_cta')}
              </Text>
              <Icon iconName="ArrowRight" size="medium" color="mono0" />
            </Box>
          </Button>
        </Box>

        <Icon
          className={cxEl('close-button')}
          iconName="Close"
          size="medium"
          color="mono0"
          onClick={onClose}
          dataTestId={cxEl('close-button')}
        />
      </Box>
    </Box>
  );
};

export default compose(
  connect(
    state => ({
      employeeCapDate: selectGhostEmployeeCapV1ExperimentValue(state),
    }),
    { onChangeToEssentialsTier: changeToEssentialsTier }
  )
)(PreDeadlineView);
