import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCollapseLeft = ({
  alt = 'Collapse Left Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M0 3.414C0 2.466.766 1.7 1.714 1.7c.949 0 1.715.766 1.715 1.714v17.143c0 .949-.766 1.715-1.715 1.715A1.712 1.712 0 0 1 0 20.557V3.414Zm7.36 9.783a1.717 1.717 0 0 1 0-2.427l6.858-6.857a1.717 1.717 0 0 1 2.427 2.426l-3.932 3.933h9.573c.948 0 1.714.766 1.714 1.714s-.766 1.714-1.714 1.714h-9.574l3.933 3.932a1.717 1.717 0 0 1-2.427 2.427L7.36 13.202v-.005Z"
    />
  </svg>
);
export default SvgCollapseLeft;
