import React, {
  FC,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  useCallback,
} from 'react';
import cx from 'classnames';

// Interpolates base & mod into a BEM string
function getCxMod(base: string, mod: string) {
  return `${base}--${mod}`;
}

// Accepts single or plural mods. If single, returns the mod appended
// to the base. If plural, returns an array of each mod appended to
// the base.
function cxMods(base: string, mods: object | string | undefined) {
  if (mods && typeof mods === 'object') {
    return Object.entries(mods).map(m => (m[1] ? getCxMod(base, m[0]) : null));
  } else if (typeof mods === 'string' && mods.length > 0) {
    return getCxMod(base, mods);
  }
  return null;
}

function cxEl(
  block: string,
  el: string,
  mods: object | string | undefined,
  ...rest: any
) {
  if (typeof el !== 'string') {
    throw new Error('Called this.cxEl without an element string!');
  }
  const className = `${block}__${el}`;

  return cx(className, cxMods(className, mods), ...rest);
}

type CxFunc = (mods?: object | string, ...rest: any[]) => string;
type CxElFunc = (el: string, mods?: object | string, ...rest: any[]) => string;

export interface CxProps {
  className?: string;
  cx: CxFunc;
  cxEl: CxElFunc;
}

function withCx<T>(block: string) {
  return (Component: FC<any> | ForwardRefExoticComponent<any>) =>
    // TODO: https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    forwardRef(({ className, ...props }: T & CxProps, ref) => {
      const cxWrapper = useCallback<CxFunc>(
        (mods, ...rest) =>
          cx(block, cxMods(block, mods), className || '', ...rest),
        [className]
      );
      const cxElWrapper = useCallback<CxElFunc>(
        (el, mods, ...rest) => cxEl(block, el, mods, ...rest),
        []
      );

      return (
        <Component {...props} ref={ref} cx={cxWrapper} cxEl={cxElWrapper} />
      );
    }) as ForwardRefExoticComponent<
      Omit<T & CxProps, 'cx' | 'cxEl'> & { ref?: ForwardedRef<any> }
    >;
}

export default withCx;
