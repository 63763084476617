import React from 'react';
import Box from 'fe-components/Box';
import Button from 'fe-design-base/molecules/Button/Button';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

const { cxEl } = cxHelpers('BottomDrawer');

const FooterBar = ({ buttonText, onClickAction, actionIsLoading, type }) =>
  buttonText ? (
    <Box hright vcenter className={cxEl('FooterBar')}>
      <Button isLoading={actionIsLoading} onClick={onClickAction} type={type}>
        {toI18n(buttonText)}
      </Button>
    </Box>
  ) : null;

FooterBar.propTypes = {
  buttonText: PropTypes.string,
  type: PropTypes.string,
  onClickAction: PropTypes.func,
  actionIsLoading: PropTypes.bool,
};

export default FooterBar;
