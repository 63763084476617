import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react';
import { Link as RRLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import MUIButton from '@mui/material/Button';
import { SxProps } from '@mui/material/styles';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Icon, { IconName, sizes as iconSizes } from 'fe-design-base/atoms/Icon';
import { ButtonDataMethod, ButtonTarget } from 'fe-design-base/enums';
import CircularInfinite from 'fe-design-base/molecules/Loaders/CircularInfinite';
import { Variant as LoaderVariant } from 'fe-design-base/molecules/Loaders/constants';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export const BUTTON_VARIANTS = [
  'primary',
  'secondary',
  'tertiary',
  'primaryDestructive',
  'secondaryDestructive',
] as const;
export const BUTTON_SIZES = ['small', 'medium'] as const;
export const BUTTON_TYPES = ['button', 'reset', 'submit'] as const;

export type Variant = (typeof BUTTON_VARIANTS)[number];
type Size = (typeof BUTTON_SIZES)[number];

export interface ButtonProps {
  id?: string;
  children: string;
  disabled?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  size?: Size;
  startIcon?: IconName;
  endIcon?: IconName;
  sx?: SxProps;
  variant?: Variant;
  href?: string;
  dataMethod?: ButtonDataMethod;
  target?: ButtonTarget;
  linkTo?: string;
  dataTestId?: string;
  grow?: boolean;
  type?: (typeof BUTTON_TYPES)[number];
  uxElement?: string;
}

const classNames = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  primaryDestructive: 'primaryDestructive',
  secondaryDestructive: 'secondaryDestructive',
  mono: 'mono',
  blue: 'blue',
  purple: 'purple',
  purpleInverted: 'purpleInverted',
};

const Button = forwardRef(
  (
    {
      id,
      children,
      disabled,
      endIcon,
      fullWidth,
      isLoading,
      onClick,
      size = 'medium',
      startIcon,
      variant = 'primary',
      href,
      dataMethod,
      target,
      linkTo,
      dataTestId,
      grow,
      type,
      uxElement,
      cx,
    }: ButtonProps & CxProps,
    ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>
  ) => {
    const iconSize = 'small';
    const isPrimary = variant === 'primary';
    const isPrimaryDestructive = variant === 'primaryDestructive';

    let loaderVariant: LoaderVariant =
      isPrimary || isPrimaryDestructive ? 'light' : 'dark';
    if (variant === 'secondaryDestructive') loaderVariant = 'destructive';

    const trackUx = useTrackUx(
      useMemo(
        () => ({
          buttonText:
            typeof children === 'string' ? (children as string) : undefined,
          element: uxElement,
          size,
          url: href || linkTo,
        }),
        [children, uxElement, size, href, linkTo]
      ) as any
    );

    const emptyWrapper = useMemo(
      () => (
        <Box sx={{ width: iconSizes[iconSize], height: iconSizes[iconSize] }}>
          &nbsp;
        </Box>
      ),
      [iconSize]
    );

    const buttonSx = useMemo(
      () => ({
        flexGrow: grow ? 1 : undefined,
      }),
      [grow]
    );

    const handleClick = useCallback(
      (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (disabled || isLoading) {
          e.preventDefault();
          return;
        }

        if (uxElement) {
          trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK);
        }
        onClick?.(e);
      },
      [disabled, isLoading, onClick, trackUx, uxElement]
    );

    const buttonComponent = (
      <MUIButton
        ref={ref}
        id={id}
        className={cx(
          { [classNames[variant]]: true, loading: isLoading },
          { 'aria-disabled': disabled }
        )}
        onClick={handleClick}
        disableRipple
        focusVisibleClassName="focus-visible"
        color="primary700"
        fullWidth={fullWidth}
        aria-disabled={disabled}
        size={size}
        variant="contained"
        href={target ? undefined : href}
        data-testid={dataTestId}
        component={linkTo && RRLink}
        to={linkTo}
        startIcon={
          startIcon &&
          (isLoading ? (
            emptyWrapper
          ) : (
            <Icon size={iconSize} iconName={startIcon} />
          ))
        }
        endIcon={
          endIcon &&
          (isLoading ? (
            emptyWrapper
          ) : (
            <Icon
              size={endIcon.includes('Caret') ? 'xsmall' : iconSize}
              iconName={endIcon}
            />
          ))
        }
        sx={buttonSx}
        type={type}
      >
        <Box
          component="span"
          sx={{
            position: 'relative',
            visibility: isLoading ? 'hidden' : 'visible',
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            position: 'absolute',
            display: isLoading ? 'flex' : 'none',
          }}
        >
          <CircularInfinite
            size={size === 'small' ? 'small' : 'medium'}
            variant={loaderVariant}
          />
        </Box>
      </MUIButton>
    );

    // target prop is not supported in MUI
    if (target && href) {
      return (
        <a
          target={target}
          rel="noopener noreferrer"
          href={href}
          data-method={dataMethod}
        >
          {buttonComponent}
        </a>
      );
    }

    return buttonComponent;
  }
);

export default withCx<ButtonProps>('FDBButton')(Button);
