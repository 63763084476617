import './ProgressBar.scss';

import React from 'react';
import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('ProgressBar');
const ProgressBar = ({
  startValue,
  variant,
  value,
  role,
  height,
  duration,
  bgColor,
}) => {
  const barAnimation = useSpring({
    from: { width: startValue, height },
    to: { width: value },
    config: {
      mass: 3.0,
      tension: 80,
      friction: 35,
      clamp: true,
      duration: duration || undefined,
    },
  });

  return (
    <div
      className={cx({
        [variant]: variant,
        [bgColor]: bgColor,
      })}
    >
      <animated.div
        role={role}
        title="Progress Bar"
        className={`bar-inner-${variant}`}
        style={barAnimation}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  /**
   * Optional start value for progress animation. Default: '0%'
   */
  startValue: PropTypes.string,
  /**
   * The current progress value.
   */
  value: PropTypes.string.isRequired,
  /**
   * A label to be read by assistive technology. Meant to give context about the `Progress.Bar`.
   */
  a11yLabel: PropTypes.string,
  /**
   * The style of the `Progress.Bar`.
   */
  variant: PropTypes.oneOf([
    'success',
    'error',
    'b500',
    'teal300',
    'purple',
    'accent500',
  ]),
  /**
   * The role type for the component for accessibility.
   */
  role: PropTypes.string,
  /**
   * The height of the progress bar
   */
  height: PropTypes.number,
  /**
   * The milliseconds the progress bar runs
   */
  duration: PropTypes.number,
  /**
   * The background color of the progress bar
   */
  bgColor: PropTypes.oneOf(['default', 'green100']),
};

ProgressBar.defaultProps = {
  a11yLabel: 'Progress bar',
  variant: 'success',
  role: 'progressbar',
  startValue: '0%',
  height: 8,
  bgColor: 'default',
};

export default ProgressBar;
