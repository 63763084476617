import { List, Map } from 'immutable';

const DEFAULT_EMPLOYEE = { name: '', default_role_name: '', level: 'Employee' };

export const SEGMENTED_ONBOARDING_DEFAULTS = {
  employees: {
    collection: List([DEFAULT_EMPLOYEE, DEFAULT_EMPLOYEE]),
    roles: [],
  },
  departments: Map(),
  schedule: {
    startOfWeek: 1,
    openTime: 7,
    closingTime: 22,
    smsNotifySchedule: true,
  },
  scheduleEnforcement: {
    notifyLateShifts: true,
    notifyLateShiftsAt: 19,
    overtimeNotifications: true,
    preventEarlyClockIn: true,
    earlyInMin: 5,
  },
  getMobileApp: {
    sendSms: true,
    phone: null,
  },
  freeTrial: {},
  scheduleSettings: {
    resetTime: 0,
    startOfWeek: 1,
  },
  saveMoney: {
    notifyLateShifts: true,
    notifyLateShiftsAt: 5,
    preventEarlyClockIn: true,
    preventEarlyBreakClockIn: false,
    earlyInMin: 5,
  },
  roles: null,
  timeTrackingAlerts: {
    overtimeNotifications: true,
    preventEarlyBreakClockIn: true,
    preventLateClockIn: true,
    notifyLateShifts: true,
    notifyLateShiftsAt: 8,
  },
};
