import { fromJS, Map, Set } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes as reportsActionTypes } from 'actions/reports';

import * as constants from 'features/reports/constants';
import { INITIAL_PAGE_STATE } from 'features/reports/constants';

const INITIAL_STATES = {
  isFetching: Set([constants.REQUEST_KEYS.reportsData]),
  reportsList: Map(),
};

const isFetching = (state = INITIAL_STATES.isFetching, action) => {
  switch (action.type) {
    case reportsActionTypes.FETCH_REPORTS_DATA_REQUEST:
      return state.add(action.meta.requestId);

    case reportsActionTypes.FETCH_REPORTS_DATA_SUCCESS:
    case reportsActionTypes.FETCH_REPORTS_DATA_FAILURE:
      return state.remove(action.meta.requestId);

    default:
      return state;
  }
};

const reportsList = (state = INITIAL_STATES.reportsList, action) => {
  switch (action.type) {
    case reportsActionTypes.FETCH_REPORTS_DATA_SUCCESS:
      return fromJS(action.payload.reports_list);

    default:
      return state;
  }
};

const page = (state = INITIAL_PAGE_STATE, action) => {
  switch (action.type) {
    case reportsActionTypes.UPDATE_SEARCH_PERIOD: {
      const newState = state.merge({
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      });
      return newState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  page,
  isFetching,
  reportsList,
});
