import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgFolder = ({
  alt = 'Folder Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M3 22.5h18c1.655 0 3-1.345 3-3v-12c0-1.655-1.345-3-3-3h-7.008a3.001 3.001 0 0 1-2.123-.877l-1.242-1.246A3.001 3.001 0 0 0 8.503 1.5H3c-1.655 0-3 1.345-3 3v15c0 1.655 1.345 3 3 3Z"
    />
  </svg>
);
export default SvgFolder;
