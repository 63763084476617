import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMonoFigma = ({
  alt = 'Mono Figma Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.918 2.5C9.1 2.5 6.83 4.83 6.83 7.68c0 1.702.808 3.215 2.057 4.16A5.205 5.205 0 0 0 6.83 16c0 1.702.808 3.215 2.057 4.16a5.204 5.204 0 0 0-2.057 4.16c0 2.85 2.27 5.18 5.088 5.18 2.819 0 5.089-2.33 5.089-5.18v-4.188a5.008 5.008 0 0 0 3.068 1.048c2.818 0 5.088-2.33 5.088-5.18a5.205 5.205 0 0 0-2.056-4.16 5.205 5.205 0 0 0 2.056-4.16c0-2.85-2.27-5.18-5.088-5.18h-8.157ZM8.85 7.68c0-1.742 1.384-3.14 3.068-3.14h3.069v6.28H11.907c-1.679-.006-3.057-1.401-3.057-3.14Zm8.157 3.14V4.54h3.068c1.684 0 3.069 1.398 3.069 3.14 0 1.74-1.38 3.135-3.06 3.14h-3.077Zm-5.098 2.04h3.078V19.14h-3.069c-1.684 0-3.068-1.398-3.068-3.14 0-1.74 1.379-3.135 3.059-3.14Zm8.166 0h.01c1.68.006 3.059 1.401 3.059 3.14 0 1.742-1.385 3.14-3.069 3.14-1.671 0-3.047-1.376-3.068-3.1v-.08c.021-1.72 1.39-3.093 3.055-3.1h.013ZM8.85 24.32c0-1.742 1.384-3.14 3.068-3.14h3.07v3.14c0 1.742-1.385 3.14-3.07 3.14-1.684 0-3.068-1.398-3.068-3.14Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMonoFigma;
