import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { findKey } from 'lodash';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

import PayrollCountdownMobileBanner from 'features/payroll/components/PayrollCountdownBanner/PayrollCountdownMobileBanner';
import { hidePayrollCountdownBannerLocalStorageKey } from 'features/payroll/constants';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import withUxTracking from 'components/hocs/withUxTracking';
import { TabletAndBelow } from 'components/MediaQuery';
import Text from 'components/Text';

import { moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, PRODUCT_AREAS } from 'util/tracking_constants';

import PayrollCountdownBannerNumbers from './PayrollCountdownBannerNumbers';

const currentDate = new Date();
const thisYear = currentDate.getFullYear();
const nextYear = thisYear + 1;

const PayrollCountdownBanner = ({ trackUxEvent }) => {
  const newYear = moment([nextYear]);

  const [timeLeft, setTimeLeft] = useState();
  const [hidePayrollCountdown, setHidePayrollCountdown] = useLocalStorage(
    hidePayrollCountdownBannerLocalStorageKey,
    false
  );

  const onClickGetStarted = () => {
    trackUxEvent({ eventAction: EVENT_ACTIONS.GET_STARTED_CLICKED });
    window.Homebase.RailsReactBridge.navigateToReactView('/payroll');
  };

  const displayCountdownTimer = useMemo(
    () => timeLeft && !hidePayrollCountdown,
    [timeLeft, hidePayrollCountdown]
  );

  // This function determines if current month is eligible EOY months (oct, nov, dec)
  const inEOYMonth = thisMonth => [9, 10, 11].includes(thisMonth);

  const startCountdownTimer = useCallback(() => {
    if (!hidePayrollCountdown) {
      const intervalId = setInterval(() => {
        const timeNow = moment();
        const currentMonth = timeNow.month();

        if (!inEOYMonth(currentMonth)) {
          setHidePayrollCountdown(true);
          clearInterval(intervalId);
          return;
        }

        const days = newYear.diff(timeNow, 'days');
        timeNow.add(days, 'days');
        const hours = newYear.diff(timeNow, 'hours');
        timeNow.add(hours, 'hours');
        const minutes = newYear.diff(timeNow, 'minutes');
        timeNow.add(minutes, 'minutes');
        const seconds = newYear.diff(timeNow, 'seconds');
        timeNow.add(minutes, 'seconds');
        setTimeLeft({ days, hours, minutes, seconds });
      }, 1000);
      return intervalId;
    }
  }, [hidePayrollCountdown, newYear, setHidePayrollCountdown]);

  useEffect(
    () => {
      trackUxEvent({
        eventAction: EVENT_ACTIONS.BANNER_SHOWN,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const intervalId = startCountdownTimer();

    return () => intervalId && clearInterval(intervalId);
  }, [startCountdownTimer, hidePayrollCountdown]);

  const onClose = () => {
    trackUxEvent({
      eventAction: EVENT_ACTIONS.BANNER_CLOSED,
    });
    setHidePayrollCountdown(true);
  };

  const formattedMobileTimeLeft = useCallback(() => {
    const keyName = findKey(timeLeft, value => value > 0);
    const i18nKeyName = toI18n(`nav_links.payroll_countdown_timer.${keyName}`, {
      props: { count: timeLeft[keyName] },
    });
    return `${timeLeft[keyName]} ${i18nKeyName}`;
  }, [timeLeft]);

  if (!displayCountdownTimer) {
    return null;
  }

  return (
    <Box row bg={colors.purpleXdark}>
      <TabletAndBelow>
        {mobile => (
          <>
            <Box
              borderbox
              w="100%"
              tcenter
              vcenter
              hcenter
              h={mobile ? 52 : 67}
              row
            >
              {mobile ? (
                <PayrollCountdownMobileBanner
                  onClickGetStarted={onClickGetStarted}
                  timeLeft={formattedMobileTimeLeft()}
                />
              ) : (
                <>
                  <PayrollCountdownBannerNumbers
                    timeLeft={timeLeft.days}
                    label="days"
                  />
                  <PayrollCountdownBannerNumbers
                    timeLeft={timeLeft.hours}
                    label="hours"
                  />
                  <PayrollCountdownBannerNumbers
                    timeLeft={timeLeft.minutes}
                    label="minutes"
                  />
                  <PayrollCountdownBannerNumbers
                    timeLeft={timeLeft.seconds}
                    label="seconds"
                  />
                  <Box vcenter>
                    <Text
                      white
                      fw400
                      fs16
                      lh="19px"
                      i18n="nav_links.payroll_countdown_timer.text"
                    />
                    &nbsp;
                    <Button theme="link" onClick={onClickGetStarted}>
                      <Text
                        underline
                        white
                        fw600
                        fs16
                        lh="25px"
                        i18n="nav_links.payroll_countdown_timer.link"
                      />
                    </Button>
                  </Box>
                </>
              )}
            </Box>
            <Box mt={mobile ? 16 : 24} tright pr16>
              <Button theme="link" onClick={onClose}>
                <FontAwesomeIcon
                  icon={faTimes}
                  color={colors.white}
                  size="med"
                />
              </Button>
            </Box>
          </>
        )}
      </TabletAndBelow>
    </Box>
  );
};

PayrollCountdownBanner.propTypes = {
  trackUxEvent: PropTypes.func.isRequired,
};

export default withUxTracking(
  PRODUCT_AREAS.PAYROLL,
  `${nextYear}_countdown_timer`
)(PayrollCountdownBanner);
