import React from 'react';
import Icon from 'react-icon-base';

const Approve = props => (
  <Icon viewBox="0 0 36 36" {...props}>
    <g id="Canvas" transform="translate(-3724 -1283)">
      <g id="check mark icon">
        <g id="Clip 2">
          <use
            xlinkHref="#approve_path0_fill"
            transform="translate(3724 1283)"
            fill="#60B86B"
          />
        </g>
        <g id="Vector">
          <use
            xlinkHref="#approve_path1_fill"
            transform="translate(3733 1294)"
            fill="#60B86B"
          />
          <use
            xlinkHref="#approve_path2_stroke"
            transform="translate(3733 1294)"
            fill="#60B86B"
          />
        </g>
      </g>
    </g>
    <defs>
      <path
        id="approve_path0_fill"
        d="M 14.9538 28.75C 22.5118 28.75 28.6615 22.5813 28.6615 15C 28.6615 7.41875 22.5118 1.25 14.9538 1.25C 7.39592 1.25 1.24615 7.41875 1.24615 15C 1.24615 22.5813 7.39592 28.75 14.9538 28.75ZM 14.9538 0C 23.1996 0 29.9077 6.72875 29.9077 15C 29.9077 23.2713 23.1996 30 14.9538 30C 6.70805 30 0 23.2713 0 15C 0 6.72875 6.70805 0 14.9538 0Z"
      />
      <path
        id="approve_path1_fill"
        d="M 10.7054 0.182861C 10.9484 -0.0608886 11.3434 -0.0608886 11.5864 0.182861C 11.8294 0.426611 11.8294 0.822861 11.5864 1.06661L 3.94711 8.47143L 0.183497 4.6828C -0.0607488 4.43905 -0.0607488 4.04405 0.181005 3.79905C 0.425251 3.5528 0.819036 3.5553 1.06204 3.79655L 3.94461 6.70643L 10.7054 0.182861Z"
      />
      <path
        id="approve_path2_stroke"
        d="M 10.7054 0.182861L 10.879 0.3628L 10.8824 0.359365L 10.7054 0.182861ZM 11.5864 1.06661L 11.7605 1.24615L 11.7635 1.24312L 11.5864 1.06661ZM 3.94711 8.47143L 3.76975 8.64762L 3.94379 8.82282L 4.12111 8.65094L 3.94711 8.47143ZM 0.183497 4.6828L 0.36086 4.50661L 0.360094 4.50584L 0.183497 4.6828ZM 0.181005 3.79905L 0.00350705 3.62299L 0.0030534 3.62345L 0.181005 3.79905ZM 1.06204 3.79655L 1.23965 3.6206L 1.23817 3.61913L 1.06204 3.79655ZM 3.94461 6.70643L 3.76701 6.88237L 3.94065 7.05766L 4.11821 6.88634L 3.94461 6.70643ZM 10.8824 0.359365C 11.0278 0.21361 11.2641 0.21361 11.4094 0.359365L 11.7635 0.00635722C 11.4228 -0.335387 10.869 -0.335387 10.5283 0.00635722L 10.8824 0.359365ZM 11.4094 0.359365C 11.5551 0.505547 11.5551 0.743926 11.4094 0.890107L 11.7635 1.24312C 12.1037 0.901797 12.1037 0.347676 11.7635 0.00635722L 11.4094 0.359365ZM 11.4124 0.887101L 3.77311 8.29192L 4.12111 8.65094L 11.7604 1.24612L 11.4124 0.887101ZM 4.12447 8.29524L 0.360859 4.50661L 0.00613589 4.85899L 3.76975 8.64762L 4.12447 8.29524ZM 0.360094 4.50584C 0.213818 4.35986 0.213167 4.12239 0.358957 3.97464L 0.0030534 3.62345C -0.334665 3.96571 -0.335315 4.51823 0.00690049 4.85975L 0.360094 4.50584ZM 0.358502 3.9751C 0.504209 3.8282 0.739624 3.82874 0.885899 3.97396L 1.23817 3.61913C 0.898448 3.28185 0.346294 3.2774 0.00350764 3.62299L 0.358502 3.9751ZM 0.884428 3.97249L 3.76701 6.88237L 4.12222 6.53049L 1.23964 3.62061L 0.884428 3.97249ZM 4.11821 6.88634L 10.879 0.362766L 10.5318 0.00295673L 3.77102 6.52653L 4.11821 6.88634Z"
      />
    </defs>
  </Icon>
);

export default Approve;
