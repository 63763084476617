import React, { useCallback, useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useDispatch } from 'react-redux';
import * as routes from 'api';
import { deleteJSON, postJSON, putJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import { fromJS } from 'immutable';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import { showConfirmModal } from 'actions/modals';

import AddButton from 'components/clickables/AddButton';

import { cxHelpers } from 'util/className';
import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';

import { EARNING_TYPE_MAP } from './constants.js';
import RecurringEarning from './RecurringEarning';
import RecurringEarningForm from './RecurringEarningForm';

const { cx } = cxHelpers('RecurringEarningsList');

const RecurringEarningsList = ({
  user,
  updateUserInfo,
  onSaveCompleted,
  showFormWithSteps,
}) => {
  const [userObj, setUserObj] = useState(user);

  useEffect(() => {
    setUserObj(fromJS(user));
  }, [user]);

  const [isNewFormVisible, setIsNewFormVisible] = useState(false);
  const [visibleFormIndex, setVisibleFormIndex] = useState(-1);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isRemoveButtonLoading, setIsRemoveButtonLoading] = useState(false);

  const handleOnCancel = useCallback(() => {
    setVisibleFormIndex(-1);
  }, []);

  const handleOnSaveCompleted = handleOnCancel;

  const handleOnDeleteSuccess = handleOnCancel;

  const handleOnClickEdit = useCallback(index => {
    setVisibleFormIndex(index);
  }, []);

  const dispatch = useDispatch();

  const handleOnSave = useCallback(
    values => {
      let recurringEarningApiCall;
      let route;
      let i18nErrorMessage;
      const isReimbursement = values.earning_type === 'reimbursement';

      if (values.id) {
        recurringEarningApiCall = putJSON;
        if (isReimbursement) {
          route = routes.recurringReimbursementRoute(
            userObj.get('id'),
            values.id
          );
        } else {
          route = routes.recurringEarningRoute(userObj.get('id'), values.id);
        }
        i18nErrorMessage =
          'team.employee_profile.recurring_earnings.validations.update_recurring_earning_error';
      } else {
        recurringEarningApiCall = postJSON;
        if (isReimbursement) {
          route = routes.recurringReimbursementsRoute(userObj.get('id'));
        } else {
          route = routes.recurringEarningsRoute(userObj.get('id'));
        }
        i18nErrorMessage =
          'team.employee_profile.recurring_earnings.validations.create_recurring_earning_error';
      }

      return recurringEarningApiCall(route, values)
        .then(response => onSaveCompleted(response))
        .catch(error => {
          error.response
            .json()
            .then(data => {
              if (data.errors && data.errors.length > 0) {
                flashNotice.error(data.errors.join(', '));
              } else {
                flashNotice.error(toI18n(i18nErrorMessage));
              }
            })
            .catch(() => {
              flashNotice.error(toI18n(i18nErrorMessage));
            });
        });
    },
    [onSaveCompleted, userObj]
  );

  const handleRecurringEarningRemove = useCallback(
    recurring_earning => {
      const isReimbursement =
        recurring_earning.get('earning_type') === 'reimbursement';

      let route;

      if (isReimbursement) {
        route = routes.recurringReimbursementRoute(
          userObj.get('id'),
          recurring_earning.get('id')
        );
      } else {
        route = routes.recurringEarningRoute(
          userObj.get('id'),
          recurring_earning.get('id')
        );
      }

      dispatch(
        showConfirmModal({
          title: toI18n(
            'team.employee_profile.recurring_earnings.delete_confirmation_modal.title',
            {
              props: {
                name: userObj.get('first_name'),
                earning_type:
                  EARNING_TYPE_MAP[recurring_earning.get('earning_type')].name,
              },
            }
          ),
          destructive: true,
          message: toI18n(
            'team.employee_profile.recurring_earnings.delete_confirmation_modal.message'
          ),
          onConfirm: () => {
            setIsRemoveButtonLoading(true);
            setIsSaveButtonDisabled(true);
            deleteJSON(route)
              .then(response => {
                updateUserInfo(response);
                onSaveCompleted(response);
              })
              .then(handleOnDeleteSuccess)
              .catch(error => {
                error.response
                  .json()
                  .then(data => {
                    if (data.errors && data.errors.length > 0) {
                      flashNotice.error(data.errors.join(', '));
                    } else {
                      flashNotice.error(
                        toI18n(
                          'team.employee_profile.recurring_earnings.validations.delete_recurring_earning_error'
                        )
                      );
                    }
                  })
                  .catch(() => {
                    flashNotice.error(
                      toI18n(
                        'team.employee_profile.recurring_earnings.validations.delete_recurring_earning_error'
                      )
                    );
                  });
              })
              .finally(() => {
                setIsRemoveButtonLoading(false);
                setIsSaveButtonDisabled(false);
              });
          },
          altType: true,
          submitText: toI18n(
            'team.employee_profile.recurring_earnings.delete_confirmation_modal.submit_text'
          ),
          cancelText: toI18n(
            'team.employee_profile.recurring_earnings.delete_confirmation_modal.cancel_text'
          ),
        })
      );
    },
    [dispatch, userObj, handleOnDeleteSuccess, updateUserInfo, onSaveCompleted]
  );

  return (
    <Box className={cx()}>
      {userObj
        .get('recurring_earnings_and_reimbursements')
        .get('items')
        .map((recurring_earning, i) =>
          visibleFormIndex === i ? (
            <RecurringEarningForm
              key={`recurring_earning-form-${i}`}
              recurring_earning={recurring_earning}
              onCancel={handleOnCancel}
              onSave={handleOnSave}
              onClickRemove={partial(
                handleRecurringEarningRemove,
                recurring_earning
              )}
              onSaveCompleted={handleOnSaveCompleted}
              isSaveButtonDisabled={isSaveButtonDisabled}
              isRemoveButtonLoading={isRemoveButtonLoading}
              updateUserInfo={updateUserInfo}
              user={userObj}
              canDelete
              showFormWithSteps={showFormWithSteps}
            />
          ) : (
            <RecurringEarning
              key={`recurring_earning-${i}`}
              onClickEdit={partial(handleOnClickEdit, i)}
              recurring_earning={recurring_earning}
              onClickRemove={partial(
                handleRecurringEarningRemove,
                recurring_earning
              )}
              canEdit={
                !(
                  recurring_earning.get('end_date') &&
                  recurring_earning.get('end_date') <
                    user
                      .get('recurring_earnings_and_reimbursements')
                      .get('current_date')
                )
              }
              showFormWithSteps={showFormWithSteps}
            />
          )
        )}

      {isNewFormVisible ? (
        <RecurringEarningForm
          onCancel={partial(setIsNewFormVisible, false)}
          onSave={handleOnSave}
          onSaveCompleted={partial(setIsNewFormVisible, false)}
          isSaveButtonDisabled={isSaveButtonDisabled}
          isRemoveButtonLoading={isRemoveButtonLoading}
          user={userObj}
          updateUserInfo={updateUserInfo}
          showFormWithSteps={showFormWithSteps}
        />
      ) : (
        <Box mt={8}>
          {showFormWithSteps ? (
            <Button
              startIcon="Add"
              variant="tertiary"
              onClick={partial(setIsNewFormVisible, true)}
            >
              <Text
                variant="bodyBold"
                i18n="team.employee_profile.recurring_earnings.add_recurring_earning"
                color="purple500"
              />
            </Button>
          ) : (
            <AddButton
              onClick={partial(setIsNewFormVisible, true)}
              i18n="team.employee_profile.recurring_earnings.add_recurring_earning"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

RecurringEarningsList.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  onSaveCompleted: PropTypes.func,
  showFormWithSteps: PropTypes.bool,
};

export default RecurringEarningsList;
