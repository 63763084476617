import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgLaptop = ({
  alt = 'Laptop Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M4.8 3.6A2.402 2.402 0 0 0 2.4 6v9.6h2.4V6h14.4v9.6h2.4V6c0-1.324-1.076-2.4-2.4-2.4H4.8ZM.72 16.8a.72.72 0 0 0-.72.72 2.88 2.88 0 0 0 2.88 2.88h18.24A2.88 2.88 0 0 0 24 17.52a.72.72 0 0 0-.72-.72H.72Z"
    />
  </svg>
);
export default SvgLaptop;
