import React from 'react';
import { config as springConfig, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';

import AnimatedBox from 'components/AnimatedBox';

const FadeIn = ({ children, config, onRest, delay, ...props }) => {
  const fadeInAnim = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config,
    onRest,
    delay,
  });

  return (
    <AnimatedBox {...props} style={fadeInAnim}>
      {children}
    </AnimatedBox>
  );
};

FadeIn.propTypes = {
  config: PropTypes.object,
  onRest: PropTypes.func,
  delay: PropTypes.number,
};

FadeIn.defaultProps = {
  onRest: () => {},
  config: { ...springConfig.default, clamp: true },
  delay: 0,
};

export default FadeIn;
