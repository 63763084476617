import { SyntheticEvent } from 'react';
import { InputChangeEvent } from 'fe-design-base/baseTypes';

// Utility to tranform target name and value associated
// with a change event while retaining the shape
// of the original event.
const transformChangeEvent = (
  event: SyntheticEvent,
  name: string,
  value: string | number | undefined
): InputChangeEvent => {
  const nativeEvent = event.nativeEvent || event;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const transformedEvent = new nativeEvent.constructor(
    nativeEvent.type,
    nativeEvent
  );
  Object.defineProperty(transformedEvent, 'target', {
    writable: true,
    value: {
      value,
      name,
    },
  });
  return transformedEvent;
};

export default transformChangeEvent;
