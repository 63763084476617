import { moment } from 'util/dateTime';

export const buildSortByDateDesc = key => (a, b) => {
  const aDate = moment(a.get(key));
  const bDate = moment(b.get(key));

  if (aDate.isAfter(bDate)) return 1;
  if (bDate.isAfter(aDate)) return -1;
  return 0;
};
