import { fromJS } from 'immutable';

import { actionTypes } from 'actions/hiring/dashboardWidget';

const INITIAL_STATE = fromJS({
  isFetchingMessages: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_REQUEST:
      return state.merge({ isFetchingMessages: true });

    case actionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_SUCCESS:
      return state.merge({ isFetchingMessages: false });

    case actionTypes.FETCH_UNREAD_MESSAGES_FROM_APPLICANT_FAILURE:
      return state.merge({ isFetchingMessages: false });

    default:
      return state;
  }
};
