import React from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import { iconNamesMap } from 'fe-design-base/molecules/utils';

export const VARIANTS = ['info', 'success', 'warning', 'error'] as const;
export type Variant = (typeof VARIANTS)[number];
export interface AlertTextProps {
  message: string;
  variant: Variant;
}

const AlertText = ({ cx, message, variant }: AlertTextProps & CxProps) =>
  message ? (
    <Box
      flex
      className={cx()}
      vcenter
      alignItemsStart
      gap="8px"
      component="span"
    >
      <Icon
        component="span"
        iconName={iconNamesMap[variant]}
        color={`${variant}300`}
        size="small"
      />
      <Text
        component="span"
        textAlign="left"
        variant="captionBold"
        color={variant === 'warning' ? 'warning500' : `${variant}300`}
      >
        {message}
      </Text>
    </Box>
  ) : null;

export default withCx<AlertTextProps>('FDBAlertText')(AlertText);
