import React from 'react';
import Icon from 'react-icon-base';

const CheckFilled = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 14l5.107 4L18 6"
    />
    <path
      fill="inherit"
      d="M24 12c0-6.617-5.383-12-12-12S0 5.383 0 12s5.383 12 12 12 12-5.383 12-12z"
    />
    <path
      fill="#fff"
      d="M15.811 8.947a.5.5 0 0 1 .707.707l-6.13 5.924-3.02-3.031a.5.5 0 0 1 .705-.709l2.313 2.328 5.425-5.219z"
    />
    <path
      fill="#fff"
      d="M15.813 8.944l.14.144.002-.003-.142-.141zm.707.707l.14.144.002-.003-.142-.141zm-6.13 5.924l-.143.14.14.14.142-.137-.14-.143zm-3.02-3.031l.142-.141-.143.14zm-.003-.707l-.142-.141.142.14zm.707-.002l.143-.14-.001-.002-.142.142zm2.314 2.328l-.143.14.14.14.142-.136-.14-.144zm5.567-5.078a.3.3 0 0 1 .423 0l.284-.282a.701.701 0 0 0-.991 0l.284.282zm.423 0a.3.3 0 0 1 0 .425l.284.282a.7.7 0 0 0 0-.99l-.284.283zm.002.422l-6.13 5.924.28.287 6.13-5.923-.28-.288zm-5.848 5.927l-3.02-3.031-.285.282 3.02 3.03.285-.281zm-3.02-3.032a.3.3 0 0 1-.002-.425l-.285-.28a.7.7 0 0 0 .003.988l.283-.283zm-.002-.424a.3.3 0 0 1 .423-.001l.283-.284a.701.701 0 0 0-.991.003l.285.282zm.422-.002l2.313 2.328.285-.282-2.313-2.328-.285.282zm2.595 2.33l5.425-5.218-.278-.288-5.426 5.219.279.288z"
    />
  </Icon>
);

export default CheckFilled;
