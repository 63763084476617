const DEFAULT_ROUTE = '/lightspeed/sign-up';

export const SLICE_NAME = 'lightspeedSignUp';

export const ROUTES = {
  LOADING: `${DEFAULT_ROUTE}/loading`,
  WELCOME: `${DEFAULT_ROUTE}/welcome`,
  BUSINESS_DETAILS: `${DEFAULT_ROUTE}/business-details`,
  INVITE_TEAM: `${DEFAULT_ROUTE}/invite-team`,
  CONGRATULATIONS: `${DEFAULT_ROUTE}/congratulations`,
};

export const ENDPOINTS = {
  FETCH_EMPLOYEES: '/employees.json',
};
