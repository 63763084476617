import * as routes from 'api';
import { fromJS } from 'immutable';
import { mapKeys, pickBy, snakeCase } from 'lodash';

import { fetchPageData } from 'actions/timeOffsDashboard';

import * as timeOffSelectors from 'selectors/timeOff';

import { df, moment } from 'util/dateTime';
import { toI18n } from 'util/i18n';
import * as reduxUtil from 'util/redux';
import * as routerUtil from 'util/router';

export const actionTypes = {
  FETCH_TIME_OFFS_REQUEST: 'TIME_OFF/FETCH_TIME_OFFS_REQUEST',
  FETCH_TIME_OFFS_SUCCESS: 'TIME_OFF/FETCH_TIME_OFFS_SUCCESS',
  FETCH_TIME_OFFS_FAILURE: 'TIME_OFF/FETCH_TIME_OFFS_FAILURE',
  FETCH_TIME_OFF_SUMMARY_REQUEST: 'TIME_OFF/FETCH_TIME_OFF_SUMMARY_REQUEST',
  FETCH_TIME_OFF_SUMMARY_SUCCESS: 'TIME_OFF/FETCH_TIME_OFF_SUMMARY_SUCCESS',
  FETCH_TIME_OFF_SUMMARY_FAILURE: 'TIME_OFF/FETCH_TIME_OFF_SUMMARY_FAILURE',
  FETCH_TIME_OFF_SUMMARY_FOR_USER_REQUEST:
    'TIME_OFF/FETCH_TIME_OFF_SUMMARY_FOR_USER_REQUEST',
  FETCH_TIME_OFF_SUMMARY_FOR_USER_SUCCESS:
    'TIME_OFF/FETCH_TIME_OFF_SUMMARY_FOR_USER_SUCCESS',
  FETCH_TIME_OFF_SUMMARY_FOR_USER_FAILURE:
    'TIME_OFF/FETCH_TIME_OFF_SUMMARY_FOR_USER_FAILURE',
  UPDATE_TIME_OFF_REQUEST: 'TIME_OFF/UPDATE_TIME_OFF_REQUEST',
  UPDATE_TIME_OFF_SUCCESS: 'TIME_OFF/UPDATE_TIME_OFF_SUCCESS',
  UPDATE_TIME_OFF_FAILURE: 'TIME_OFF/UPDATE_TIME_OFF_FAILURE',
  APPROVE_TIME_OFF_REQUEST: 'TIME_OFF/APPROVE_TIME_OFF_REQUEST',
  APPROVE_TIME_OFF_SUCCESS: 'TIME_OFF/APPROVE_TIME_OFF_SUCCESS',
  APPROVE_TIME_OFF_FAILURE: 'TIME_OFF/APPROVE_TIME_OFF_FAILURE',
  DECLINE_TIME_OFF_REQUEST: 'TIME_OFF/DECLINE_TIME_OFF_REQUEST',
  DECLINE_TIME_OFF_SUCCESS: 'TIME_OFF/DECLINE_TIME_OFF_SUCCESS',
  DECLINE_TIME_OFF_FAILURE: 'TIME_OFF/DECLINE_TIME_OFF_FAILURE',
  DELETE_TIME_OFF_REQUEST: 'TIME_OFF/DELETE_TIME_OFF_REQUEST',
  DELETE_TIME_OFF_SUCCESS: 'TIME_OFF/DELETE_TIME_OFF_SUCCESS',
  DELETE_TIME_OFF_FAILURE: 'TIME_OFF/DELETE_TIME_OFF_FAILURE',
  ADD_TIME_OFF_REQUEST: 'TIME_OFF/ADD_TIME_OFF_REQUEST',
  ADD_TIME_OFF_SUCCESS: 'TIME_OFF/ADD_TIME_OFF_SUCCESS',
  ADD_TIME_OFF_FAILURE: 'TIME_OFF/ADD_TIME_OFF_FAILURE',
  UPDATE_SORT_FILTER: 'TIME_OFF/UPDATE_SORT_FILTER',
  FETCH_PTO_POLICIES_REQUEST: 'TIME_OFF/FETCH_PTO_POLICIES_REQUEST',
  FETCH_PTO_POLICIES_SUCCESS: 'TIME_OFF/FETCH_PTO_POLICIES_SUCCESS',
  FETCH_PTO_POLICIES_FAILURE: 'TIME_OFF/FETCH_PTO_POLICIES_FAILURE',
  UPDATE_PTO_POLICY_FOR_USER_REQUEST:
    'PTO_POLICY/UPDATE_PTO_POLICY_FOR_USER_REQUEST',
  UPDATE_PTO_POLICY_FOR_USER_SUCCESS:
    'PTO_POLICY/UPDATE_PTO_POLICY_FOR_USER_SUCCESS',
  UPDATE_PTO_POLICY_FOR_USER_FAILURE:
    'PTO_POLICY/UPDATE_PTO_POLICY_FOR_USER_FAILURE',
};

const TIME_OFF_QUERY_PARAM_KEYS = [
  'startDate',
  'endDate',
  'includeJobs',
  'status',
  'jobId',
];

// Picks all query params in TIME_OFF_QUERY_PARAM_KEYS with truthy values
export const parseQueryParams = params => {
  if (params.month) {
    const value = moment(params.month, 'MM-YYYY');
    params.startDate = value.startOf('month').format(df('parsable_date'));
    params.endDate = value.clone().endOf('month').format(df('parsable_date'));
  } else if (params.year) {
    const value = moment(params.year, 'YYYY');
    params.startDate = value.startOf('year').format(df('parsable_date'));
    params.endDate = value.clone().endOf('year').format(df('parsable_date'));
  }

  return pickBy(params, (v, k) => v && TIME_OFF_QUERY_PARAM_KEYS.includes(k));
};

export const fetchTimeOffs = filters => {
  const params = parseQueryParams(filters);
  const meta = { requestId: 'FETCH_TIME_OFFS' };

  return reduxUtil.createAsyncGetAction(
    routes.timeOffsRoute({
      ...mapKeys(params, (v, k) => snakeCase(k)),
      with_object_roots: true,
    }),
    [
      { type: actionTypes.FETCH_TIME_OFFS_REQUEST, meta },
      { type: actionTypes.FETCH_TIME_OFFS_SUCCESS, meta },
      { type: actionTypes.FETCH_TIME_OFFS_FAILURE, meta },
    ]
  );
};

export const fetchTimeOffSummary = params =>
  reduxUtil.createAsyncGetAction(routes.timeOffSummaryRoute(params), [
    {
      type: actionTypes.FETCH_TIME_OFF_SUMMARY_REQUEST,
      meta: { requestId: 'FETCH_TIME_OFF_SUMMARY' },
    },
    {
      type: actionTypes.FETCH_TIME_OFF_SUMMARY_SUCCESS,
      meta: { requestId: 'FETCH_TIME_OFF_SUMMARY' },
    },
    {
      type: actionTypes.FETCH_TIME_OFF_SUMMARY_FAILURE,
      meta: { requestId: 'FETCH_TIME_OFF_SUMMARY' },
    },
  ]);

export const updateTimeOffFilters = newFilters => {
  routerUtil.updateUrlFilterParams(newFilters);
  return fetchTimeOffs(routerUtil.parseQueryString());
};

export const updateTimeOffSummaryFilters = params => {
  routerUtil.updateUrlFilterParams(params);
  return fetchTimeOffSummary(params);
};

export const fetchTimeOffSummaryForUser =
  (id, opts = { force: false }) =>
  dispatch => {
    if (id === 'new') {
      return;
    }
    const meta = { requestId: `FETCH_TIME_OFF_SUMMARY_FOR_USER_${id}` };

    dispatch(
      reduxUtil.createAsyncGetAction(
        routes.timeOffSummaryRoute({ user_id: id }),
        [
          { type: actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_REQUEST, meta },
          { type: actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_SUCCESS, meta },
          { type: actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_FAILURE, meta },
        ],
        {
          bailout: state =>
            !opts.force &&
            timeOffSelectors.getBailoutFetchTimeOffSummaryForUser(state, {
              userId: id,
            }),
        }
      )
    );
  };

export const updateTimeOff = (jsTimeOff, attrs) => dispatch => {
  const timeOff = fromJS(jsTimeOff);
  const meta = { timeOff, attrs };

  reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(
      routes.timeOffRoute(timeOff.get('id')),
      [
        { type: actionTypes.UPDATE_TIME_OFF_REQUEST, meta },
        { type: actionTypes.UPDATE_TIME_OFF_SUCCESS, meta },
        { type: actionTypes.UPDATE_TIME_OFF_FAILURE, meta },
      ],
      { body: attrs }
    ),
    {
      success: toI18n('schedule_builder.time_off.update_success'),
      onSuccess: () => {
        dispatch(fetchPageData());
      },
    }
  )(dispatch);
};

export const approveTimeOff = id => (dispatch, getState) => {
  const requestId = `STATUS/${id}`;
  const meta = { requestId };
  const userId = timeOffSelectors.getUserIdForTimeOffId(getState(), { id });

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(
      routes.approveTimeOffRoute(id),
      [
        { type: actionTypes.APPROVE_TIME_OFF_REQUEST, meta },
        { type: actionTypes.APPROVE_TIME_OFF_SUCCESS, meta },
        { type: actionTypes.APPROVE_TIME_OFF_FAILURE, meta },
      ],
      {
        bailout: state =>
          timeOffSelectors.getIsFetching(state, { id: requestId }),
      }
    ),
    {
      success: toI18n('schedule_builder.time_off.approve_success'),
      onSuccess: () => {
        dispatch(fetchTimeOffSummaryForUser(userId, { force: true }));
      },
    }
  )(dispatch);
};

export const deleteTimeOff = timeOff => (dispatch, getState) => {
  const userId = timeOffSelectors.getUserIdForTimeOffId(getState(), {
    id: timeOff.get('id'),
  });
  const meta = { timeOff };

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncDeleteAction(routes.timeOffRoute(timeOff.get('id')), [
      { type: actionTypes.DELETE_TIME_OFF_REQUEST, meta },
      { type: actionTypes.DELETE_TIME_OFF_SUCCESS, meta },
      { type: actionTypes.DELETE_TIME_OFF_FAILURE, meta },
    ]),
    {
      success: toI18n('schedule_builder.time_off.delete_success'),
      onSuccess: () => {
        dispatch(fetchTimeOffSummaryForUser(userId, { force: true }));
      },
    }
  )(dispatch);
};

export const declineTimeOff = id => (dispatch, getState) => {
  const requestId = `STATUS/${id}`;
  const meta = { requestId };
  const userId = timeOffSelectors.getUserIdForTimeOffId(getState(), { id });

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(routes.declineTimeOffRoute(id), [
      { type: actionTypes.DECLINE_TIME_OFF_REQUEST, meta },
      { type: actionTypes.DECLINE_TIME_OFF_SUCCESS, meta },
      { type: actionTypes.DECLINE_TIME_OFF_FAILURE, meta },
    ]),
    {
      success: toI18n('schedule_builder.time_off.decline_success'),
      onSuccess: () => {
        dispatch(fetchTimeOffSummaryForUser(userId, { force: true }));
      },
    }
  )(dispatch);
};

export const addTimeOff = (body, onSuccess) => dispatch =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.timeOffsRoute(),
      [
        actionTypes.ADD_TIME_OFF_REQUEST,
        actionTypes.ADD_TIME_OFF_SUCCESS,
        actionTypes.ADD_TIME_OFF_FAILURE,
      ],
      { body }
    ),
    {
      success: toI18n('schedule_builder.time_off.add_success'),
      onSuccess: ({ payload }) => {
        if (onSuccess) return onSuccess(payload);

        // Call dispatch again to get the state created *after* the `addTimeOff`
        // payload has been consumed; otherwise, wouldn't be able to look up
        // the time off by id in entities state.
        dispatch((newDispatch, getState) => {
          const userId = timeOffSelectors.getUserIdForTimeOffId(getState(), {
            id: payload.id,
          });

          newDispatch(fetchTimeOffSummaryForUser(userId, { force: true }));
          newDispatch(fetchPageData());
        });
      },
    }
  )(dispatch);

export const updateSortFilter = (key, sortType) => ({
  type: actionTypes.UPDATE_SORT_FILTER,
  payload: { key, sortType },
});

export const fetchPTOPolicies = (opts = { force: false }) =>
  reduxUtil.createAsyncGetAction(
    routes.ptoPoliciesRoute(),
    [
      {
        type: actionTypes.FETCH_PTO_POLICIES_REQUEST,
        meta: { requestId: 'FETCH_PTO_POLICIES' },
      },
      {
        type: actionTypes.FETCH_PTO_POLICIES_SUCCESS,
        meta: { requestId: 'FETCH_PTO_POLICIES' },
      },
      {
        type: actionTypes.FETCH_PTO_POLICIES_FAILURE,
        meta: { requestId: 'FETCH_PTO_POLICIES' },
      },
    ],
    {
      bailout: state =>
        !opts.force && timeOffSelectors.getBailoutFetchPTOPolicies(state),
    }
  );
