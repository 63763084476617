import React from 'react';
import Icon from 'react-icon-base';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

const CoffeeBreakOut = ({ color, width, height, ...props }) => (
  <Icon
    width={width || '36'}
    height={height || '36'}
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill={color || colors.purple}
      fillRule="evenodd"
      d="M30.043 10.828a.71.71 0 0 0 .708-.708V8.703a.71.71 0 0 0-.708-.708H3.125a.71.71 0 0 0-.708.708v1.417c0 .39.319.708.708.708h26.918zM16 33.582H8.791c-.384 0-.708-.361-.714-.875L5.662 12.243h21.843L27.18 15h1.425l.326-2.755h1.112a2.128 2.128 0 0 0 2.125-2.125V8.703a2.128 2.128 0 0 0-2.125-2.125h-1.176l-1.002-3.28-.146-.363C27.361 2.027 26.954 1 25.793 1H7.375C5.901 1 5.494 2.549 5.281 3.357l-.001.003L4.3 6.578H3.125A2.128 2.128 0 0 0 1 8.703v1.417c0 1.171.953 2.125 2.125 2.125h1.112l2.43 20.546C6.667 34.01 7.62 35 8.792 35H16v-1.418zM7.375 2.417c-.135 0-.384 0-.732 1.33l-.862 2.83h21.597l-.976-3.122c-.248-.627-.424-1.038-.61-1.038H7.375z"
      clipRule="evenodd"
    />
    <path
      stroke={color || colors.purple}
      strokeLinecap="round"
      strokeWidth="1.5"
      fill="transparent"
      d="M24.5 33a7.5 7.5 0 1 1 6.497-3.75"
    />
    <path
      fill={color || colors.purple}
      d="M29.421 30.226l.25-.434.866.498-.249.434-.867-.498zm-.236 1.415l-.011-5.774 5.005 2.877-4.994 2.897z"
    />
  </Icon>
);

CoffeeBreakOut.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default CoffeeBreakOut;
