import { StyleProp } from './interface';

/**
 * In Typescript we cannot iterate over the type since there are no types in the runtime.
 * Hence we still need to keep this CLASS_PROPS array which contains same properties as ClassProps.
 */
export const CLASS_PROPS = [
  'absolute',
  'absoluteCover',
  'block',
  'borderbox',
  'column',
  'columnreverse',
  'disabled',
  'ellipsis',
  'evenwidth',
  'fixed',
  'hcenter',
  'hide',
  'hleft',
  'hright',
  'inline',
  'inlineBlock',
  'justify-content-space-around',
  'justify-content-space-evenly',
  'muiShadow',
  'noWrap',
  'pointer',
  'pointerEventsNone',
  'relative',
  'row',
  'rowreverse',
  'scrollX',
  'scrollY',
  'shadow',
  'spacearound',
  'spacebetween',
  'spaceevenly',
  'sticky',
  'tcenter',
  'tleft',
  'transitionAll',
  'tright',
  'vcenter',
  'vtop',
  'vbottom',
  'wrap',
  'wrapreverse',
  'bb',
  'bt',
  'bv',
  'bh',
  'b',
];

export const CLASS_PROP_SET = new Set(CLASS_PROPS);

export const EXCLUDED_PROPS: Record<string, boolean> = {
  jobId: true,
  dispatch: true,
};

export const STYLE_PROP_MAPPING: Record<
  string,
  string | ((props: Record<string, StyleProp>, value: StyleProp) => void)
> = {
  b: 'border',
  bbw: 'borderBottomWidth',
  bcolor: 'borderColor',
  bg: 'background',
  blw: 'borderLeftWidth',
  boxShadow: 'boxShadow',
  bradius: 'borderRadius',
  brw: 'borderRightWidth',
  bs: 'borderStyle',
  btw: 'borderTopWidth',
  bw: 'borderWidth',
  overflow: 'overflow',
  overflowX: 'overflowX',
  overflowY: 'overflowY',
  opacity: 'opacity',
  w: 'width',
  h: 'height',
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  cursor: 'cursor',
  right: 'right',
  lh: 'lineHeight',
  m: 'margin',
  maxh: 'maxHeight',
  maxw: 'maxWidth',
  minh: 'minHeight',
  minw: 'minWidth',
  ml: 'marginLeft',
  mr: 'marginRight',
  mb: 'marginBottom',
  mh: (props, value) => {
    props.marginLeft = value;
    props.marginRight = value;
  },
  mt: 'marginTop',
  mv: (props, value) => {
    props.marginTop = value;
    props.marginBottom = value;
  },
  p: 'padding',
  pt: 'paddingTop',
  pb: 'paddingBottom',
  ph: (props, value) => {
    props.paddingLeft = value;
    props.paddingRight = value;
  },
  pl: 'paddingLeft',
  pv: (props, value) => {
    props.paddingTop = value;
    props.paddingBottom = value;
  },
  pr: 'paddingRight',
  grow: 'flexGrow',
  shrink: 'flexShrink',
  basis: 'flexBasis',
  z: 'zIndex',
  zIndex: 'zIndex',
  gap: 'gap',
};
