import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMonoYouTube = ({
  alt = 'Mono Youtube Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M29.067 11.457s-.26-1.838-1.062-2.646c-1.016-1.062-2.151-1.067-2.672-1.13-3.729-.27-9.328-.27-9.328-.27h-.01s-5.6 0-9.328.27c-.521.063-1.657.068-2.672 1.13-.802.808-1.058 2.646-1.058 2.646s-.27 2.162-.27 4.318v2.02c0 2.157.265 4.318.265 4.318s.26 1.84 1.057 2.646c1.016 1.063 2.35 1.026 2.943 1.141 2.136.203 9.068.266 9.068.266s5.604-.01 9.333-.276c.521-.063 1.656-.068 2.672-1.13.802-.808 1.063-2.647 1.063-2.647s.265-2.156.265-4.317v-2.021c0-2.156-.265-4.318-.265-4.318ZM13.245 20.25v-7.495l7.203 3.76-7.203 3.735Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMonoYouTube;
