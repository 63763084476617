import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-components/Box';
import TextField from 'fe-design-base/molecules/TextField';
import Dialog from 'fe-design-base/organisms/Dialog/Dialog';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { selectNewRoleModalIsOpen } from 'features/team/selectors';
import { closeAddNewRoleModal } from 'features/team/slice';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import {
  I18N_ADD_NEW_ROLE_MODAL_PATH,
  I18N_JOB_DETAILS_PATH,
} from './constants';

const { cx } = cxHelpers('AddNewRoleModal');

const NewRoleModalForm = ({
  newRoleModalIsOpen,
  onAddNewRole,
  onCloseAddNewRoleModal,
  resetForm,
}) => {
  const { values } = useFormikContext();

  const handleClose = useCallback(() => {
    onCloseAddNewRoleModal();
    resetForm();
  }, [onCloseAddNewRoleModal, resetForm]);

  return (
    <Dialog
      title={toI18n(`${I18N_JOB_DETAILS_PATH}.add_new_role`)}
      buttons={[
        {
          label: toI18n(`${I18N_JOB_DETAILS_PATH}.add_new_role`),
          variant: 'primary',
          onClick: () => onAddNewRole(values.newRoleName, resetForm),
        },
        {
          label: toI18n(`${I18N_ADD_NEW_ROLE_MODAL_PATH}.cancel`),
          variant: 'secondary',
          onClick: handleClose,
        },
      ]}
      className={cx()}
      width={605}
      open={newRoleModalIsOpen}
      onClose={handleClose}
    >
      {() => (
        <Box mt={28}>
          <TextField
            autoFocus
            hasAsterisk
            helperText={toI18n(`${I18N_ADD_NEW_ROLE_MODAL_PATH}.helper_text`)}
            name="newRoleName"
            label={toI18n(`${I18N_ADD_NEW_ROLE_MODAL_PATH}.new_role_name`)}
          />
        </Box>
      )}
    </Dialog>
  );
};

NewRoleModalForm.propTypes = {
  newRoleModalIsOpen: PropTypes.bool,
  onAddNewRole: PropTypes.func,
  onCloseAddNewRoleModal: PropTypes.func,
  resetForm: PropTypes.func,
};

export default connect(
  state => ({
    newRoleModalIsOpen: selectNewRoleModalIsOpen(state),
  }),
  {
    onCloseAddNewRoleModal: closeAddNewRoleModal,
  }
)(NewRoleModalForm);
