import React from 'react';
import { connect } from 'react-redux';
import Box from 'fe-components/Box';
import PropTypes from 'prop-types';

import { getCurrentLocationAccessLevelOptions } from 'selectors/addTeam';

import { I18N_JOB_DETAILS_PATH } from 'features/team/components/AddEmployeeForm/constants';
import ChipGroupField from 'features/team/components/ChipGroup/ChipGroupField';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
const { cxEl } = cxHelpers('JobDetailsSection');

const AccessLevel = ({ accessLevelOptions }) => (
  <Box className={cxEl('access-level')} mt={8}>
    <ChipGroupField
      name="level"
      options={accessLevelOptions}
      label={toI18n(`${I18N_JOB_DETAILS_PATH}.access_level.title`)}
      infoTooltip={toI18n(`${I18N_JOB_DETAILS_PATH}.access_level.tooltip`)}
    />
  </Box>
);

AccessLevel.propTypes = {
  accessLevelOptions: PropTypes.array,
};

export default connect(state => ({
  accessLevelOptions: getCurrentLocationAccessLevelOptions(state),
}))(AccessLevel);
