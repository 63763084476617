import baseColors from '../colors';

export const loadersOverrides = {
  MuiSkeleton: {
    styleOverrides: {
      root: {
        '&.FDBSkeletonLoader': {
          background: `linear-gradient(90deg, ${baseColors.mono300} 0%, ${baseColors.mono0} 100%)`,
          '&.bradiusl': {
            borderRadius: '16px',
          },
          '&.bradiusm': {
            borderRadius: '8px',
          },
          '&.bradiuss': {
            borderRadius: '4px',
          },
          '&.bradiusfull': {
            borderRadius: '9999px',
          },
        },
      },
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      root: {
        '&.FDBCircularProgressLoader': {
          color: 'inherit',
        },
      },
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        '&.FDBProgress .MuiLinearProgress-bar': {
          backgroundColor: baseColors.purple700,
        },
      },
    },
  },
};
