import React from 'react';
import Icon from 'react-icon-base';

const Resume = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <path d="M12.826 11.996l-2.83 2.86V12.33c0-.184.15-.334.334-.334h2.496zm-10.16 2.337V1.666c0-.55.49-1 1.09-1h8.446c.6 0 1.09.45 1.09 1v9.664H10.33c-.552 0-1 .448-1 1v3.003H3.756c-.6 0-1.09-.448-1.09-1zm11.292-2.67V1.666C13.958.748 13.171 0 12.202 0H3.756C2.788 0 2 .748 2 1.666v12.667C2 15.253 2.788 16 3.756 16h5.907A.33.33 0 0 0 9.9 15.9l3.96-4.002.002-.001c.008-.009.012-.02.019-.028.02-.025.04-.05.052-.079a.332.332 0 0 0 .025-.128z" />
      <path d="M4.312 2.667h7.334a.333.333 0 0 0 0-.667H4.312a.333.333 0 0 0 0 .667M4.312 4.667h7.334a.333.333 0 0 0 0-.667H4.312a.333.333 0 0 0 0 .667M4.312 6.667h7.334a.333.333 0 0 0 0-.667H4.312a.333.333 0 0 0 0 .667M8.985 8H4.313a.333.333 0 1 0 0 .667h4.672a.333.333 0 0 0 0-.667" />
    </g>
  </Icon>
);

export default Resume;
