import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';

const SvgAddressBook = ({
  alt = 'Address Book Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M18 0H4.5a3 3 0 0 0-3 3v18a3 3 0 0 0 3 3H18a3 3 0 0 0 3-3V3c0-1.657-1.345-3-3-3Zm-6.75 6a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm4.5 12h-9a.752.752 0 0 1-.75-.75 3.749 3.749 0 0 1 3.75-3.75h3a3.75 3.75 0 0 1 3.75 3.75c0 .413-.337.75-.75.75Zm7.5-15h-.75v4.5h.75c.413 0 .75-.338.75-.75v-3a.751.751 0 0 0-.75-.75Zm0 6h-.75v4.5h.75c.413 0 .75-.338.75-.75v-3a.752.752 0 0 0-.75-.75Zm0 6h-.75v4.5h.75a.75.75 0 0 0 .75-.75v-3a.752.752 0 0 0-.75-.75Z"
    />
  </svg>
);
export default SvgAddressBook;
