import './LaunchWeekSharingDialog.scss';

import React, { useCallback, useEffect, useState } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import Dialog from 'fe-design-base/organisms/Dialog';

import LaunchWeekSharingOptionsDialog from 'features/Growth/LaunchWeek/LaunchWeekSharingOptionsDialog';

import { cxHelpers } from 'util/className';
import {
  ELEMENTS,
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';
const { cx, cxEl } = cxHelpers('LaunchWeekSharingDialog');
import { toI18n } from 'util/i18n';

const CONTENT_STYLE_CENTER = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const IMAGE_NAMES = [
  'lw_video_transition_arrows',
  'lw_video_transition_bolt',
  'lw_video_transition_exclamation_mark',
];

const LaunchWeekSharingDialog = ({ open, onClose }) => {
  const trackLaunchWeekStoryUxEvent = useTrackUx({
    productArea: PRODUCT_AREAS.LAUNCH_WEEK,
    eventCategory: EVENT_CATEGORIES.LAUNCH_WEEK_STORY,
  });
  const trackLaunchWeekShareUxEvent = useTrackUx({
    productArea: PRODUCT_AREAS.LAUNCH_WEEK,
    eventCategory: EVENT_CATEGORIES.LAUNCH_WEEK_SHARE,
  });

  const [showInitialAnimation, setShowInitialAnimation] = useState(undefined);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const cycleImages = () => {
    const newCurrentImageIndex = currentImageIndex + 1;

    if (newCurrentImageIndex === IMAGE_NAMES.length) {
      return setCurrentImageIndex(0);
    }

    return setCurrentImageIndex(newCurrentImageIndex);
  };

  const [isSharingOptionsDialogOpen, setIsSharingOptionsDialogOpen] =
    useState(false);
  const [currentClassName, setCurrentClassName] = useState(cxEl('default'));

  useEffect(() => {
    if (open) {
      setShowInitialAnimation(true);

      trackLaunchWeekStoryUxEvent(
        EVENT_ACTIONS.LAUNCH_WEEK_AWARD_COMPLETED,
        TRACK_ACTION_TYPES.VIEW
      );
      trackLaunchWeekShareUxEvent(
        EVENT_ACTIONS.LAUNCH_WEEK_SHARE_STARTED,
        TRACK_ACTION_TYPES.VIEW
      );

      setTimeout(() => {
        setShowInitialAnimation(false);
        setCurrentClassName(cx());
      }, 2_000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleOpenSharingOptionsDialog = useCallback(() => {
    trackLaunchWeekShareUxEvent(
      EVENT_ACTIONS.BUTTON_CLICKED,
      TRACK_ACTION_TYPES.CLICK,
      { element: ELEMENTS.LAUNCH_WEEK_SHARE_YOUR_AWARD }
    );
    setIsSharingOptionsDialogOpen(true);
  }, [trackLaunchWeekShareUxEvent]);

  const handleOnClose = useCallback(() => {
    setIsSharingOptionsDialogOpen(false);
    setShowInitialAnimation(false);
    onClose();
  }, [onClose]);

  const handleCloseSharingOptionsDialog = useCallback(() => {
    setIsSharingOptionsDialogOpen(false);
  }, []);

  if (open && showInitialAnimation) {
    setTimeout(cycleImages, 180);
  }

  const imagesSequence = useCallback(
    () => (
      <Box minh="100%" column spacebetween className={currentClassName}>
        <img
          className={cxEl('image-sequence')}
          src={require(`assets/${IMAGE_NAMES[currentImageIndex]}.png`)}
        />
      </Box>
    ),
    [currentClassName, currentImageIndex]
  );

  return (
    <Dialog
      className={currentClassName}
      disableBackdropClick
      fullScreen
      noPadding
      onClose={handleOnClose}
      open={open}
    >
      {() =>
        showInitialAnimation ? (
          imagesSequence()
        ) : (
          <Box minh="100%" column spacebetween className={currentClassName}>
            <Box p={20} className="appears-in-place">
              <Text variant="heading3" color="purple500">
                homebase
              </Text>
            </Box>
            <Box pl={20} column>
              <Box style={CONTENT_STYLE_CENTER} column hcenter vcenter>
                <Box
                  justifyContentCenter
                  className="appears-in-place-increase-decrease"
                >
                  <img
                    width={240}
                    height={240}
                    src={require('assets/launch_week_top_workplace_award_3d.svg')}
                  />
                </Box>
                <Box mt={42} className="appearsFromBottom">
                  <Text variant="heading1" color="purple900">
                    {toI18n('growth.launch_week.sharing.title')}
                  </Text>
                </Box>
                <Box mt={16} className="appearsFromBottom">
                  <Text variant="heading2" color="mono700">
                    {toI18n('growth.launch_week.sharing.subtitle')}
                  </Text>
                </Box>
                <Box mt={24} className="appearsFromBottom">
                  <Button
                    variant="primary"
                    onClick={handleOpenSharingOptionsDialog}
                  >
                    {toI18n('growth.launch_week.sharing.share_cta')}
                  </Button>
                </Box>
              </Box>
            </Box>
            <LaunchWeekSharingOptionsDialog
              open={isSharingOptionsDialogOpen}
              onClose={handleCloseSharingOptionsDialog}
              i18n={toI18n('growth.launch_week.sharing.dialog')}
              trackUx={trackLaunchWeekShareUxEvent}
            />
          </Box>
        )
      }
    </Dialog>
  );
};

export default LaunchWeekSharingDialog;
