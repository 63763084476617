import * as routes from 'api';

import { showModal } from 'actions/modals';

import {
  TIME_OFF_EMPLOYEE_BALANCE_HISTORY_MODAL,
  TIME_OFF_REQUESTS_DETAILS_MODAL,
} from 'features/timeOff/constants';

import { createAsyncGetAction } from 'util/redux';

export const actionTypes = {
  FETCH_PAGE_DATA_REQUEST: 'TIME_OFFS_DASHBOARD/FETCH_PAGE_DATA_REQUEST',
  FETCH_PAGE_DATA_SUCCESS: 'TIME_OFFS_DASHBOARD/FETCH_PAGE_DATA_SUCCESS',
  FETCH_PAGE_DATA_FAILURE: 'TIME_OFFS_DASHBOARD/FETCH_PAGE_DATA_FAILURE',
};

export const fetchPageData = () =>
  createAsyncGetAction(routes.timeOffsDashboardRoute(), [
    actionTypes.FETCH_PAGE_DATA_REQUEST,
    actionTypes.FETCH_PAGE_DATA_SUCCESS,
    actionTypes.FETCH_PAGE_DATA_FAILURE,
  ]);

export const showTimeOffRequestDetailsModal = (
  timeOff,
  handleClickApprove,
  handleClickReject
) =>
  showModal(TIME_OFF_REQUESTS_DETAILS_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    timeOff,
    handleClickApprove,
    handleClickReject,
  });

export const showEmployeeBalanceHistoryModal = (balance, history, activeTab) =>
  showModal(TIME_OFF_EMPLOYEE_BALANCE_HISTORY_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    balance,
    history,
    activeTab,
  });
