export const HOMEBASE_CASH_OUT_FLYER_URL =
  'https://homebase-public.s3.us-west-2.amazonaws.com/homebase1/homebase_cash_out_flyer.pdf';

export const HOMEBASE_CASH_OUT_HELP_CENTER_URL =
  'https://support.joinhomebase.com/s/topic/0TOHp000000lcxhOAA/homebase-money';

export const SOURCE_TEXT_URL =
  'https://www.pwc.com/us/en/services/consulting/business-transformation/library/employee-financial-wellness-survey.html';

/**
 * Any changes to the values here must be made to the
 * same values defined in get_employer_type.rb
 * */
export const EMPLOYER_TYPE = {
  OWNER_SHIFT_PAY_ENABLED: 'OWNER_SHIFT_PAY_ENABLED',
  OWNER_SHIFT_PAY_DISABLED: 'OWNER_SHIFT_PAY_DISABLED',
  NOT_OWNER_SHIFT_PAY_ENABLED: 'NOT_OWNER_SHIFT_PAY_ENABLED',
  NOT_OWNER_SHIFT_PAY_DISABLED: 'NOT_OWNER_SHIFT_PAY_DISABLED',
};

export const SHIFT_PAY_PROMOTIONS_DISMISS_PAY_ANY_DAY_BANNER_PATH =
  '/shift_pay/promotions/dismiss_pay_any_day_banner';

export const SHIFT_PAY_PROMOTIONS_SEND_PAY_ANY_DAY_TEAM_MESSAGE_PATH =
  '/shift_pay/promotions/send_pay_any_day_team_message';

export const SHIFT_PAY_PROMOTIONS_SEND_PAY_ANY_DAY_FEEDBACK_PATH =
  '/shift_pay/promotions/submit_pay_any_day_feedback';
