import { createSelector } from 'reselect';

import { getHasManagersFeatureAccess } from 'selectors/addTeam';
import {
  getCurrentLocation,
  getCurrentLocationTierId,
} from 'selectors/session';

import { SLICE_NAME } from './constants';

export const selectDrawerIsOpen = state =>
  state.getIn([SLICE_NAME, 'addTeamDrawerIsOpen']);

export const selectCalledFrom = state =>
  state.getIn([SLICE_NAME, 'calledFrom']);

export const selectOnCloseDrawerCallback = state =>
  state.getIn([SLICE_NAME, 'onCloseDrawerCallback']);

export const selectCurrentTeamMember = state =>
  state.getIn([SLICE_NAME, 'currentTeamMember']);

export const selectTaxEntryDrawerIsOpen = state =>
  state.getIn([SLICE_NAME, 'taxEntryDrawerIsOpen']);

export const selectTaxEntryUserId = state =>
  state.getIn([SLICE_NAME, 'taxEntryUserId']);

export const bulkActionDrawerIsOpen = state =>
  state.getIn([SLICE_NAME, 'bulkActionDrawerIsOpen']);

export const selectNewRoleModalIsOpen = state =>
  state.getIn([SLICE_NAME, 'newRoleModalIsOpen']);

export const selectToastIsOpen = state =>
  state.getIn([SLICE_NAME, 'toastIsOpen']);

export const selectToastProps = state =>
  state.getIn([SLICE_NAME, 'toastProps']);

export const selectSuccessModalProps = state =>
  state.getIn([SLICE_NAME, 'successModalProps']);

export const selectSuccessModalIsOpen = state =>
  state.getIn([SLICE_NAME, 'successModalIsOpen']);

export const selectCanViewOtherManagerWages = state =>
  !state.getIn([
    'entities',
    'locations',
    String(getCurrentLocation(state).get('id')),
    'hide_manager_wages',
  ]);

export const selectCurrentLocationCountryCode = state =>
  state.getIn([
    'entities',
    'locations',
    String(getCurrentLocation(state).get('id')),
    'country_code',
  ]);

export const selectTaxEntryUserData = createSelector(
  state =>
    state.getIn(['entities', 'users', String(selectTaxEntryUserId(state))]),
  result => result?.toJS()
);

export const getIsDefaultManager = createSelector(
  state => selectCalledFrom(state),
  state => getCurrentLocationTierId(state),
  state => getHasManagersFeatureAccess(state),
  (calledFrom, currentLocationTierId, managersCanAccess) =>
    calledFrom === 'QSG' && currentLocationTierId !== 1 && managersCanAccess
);
