import React, { useCallback, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';

import PostTaxDeduction from './PostTaxDeduction';
import ConnectedPostTaxDeductionForm from './PostTaxDeductionForm';

const { cx } = cxHelpers('PostTaxDeductionsList');

const PostTaxDeductionsList = ({
  user,
  updateUserInfo,
  onClickEdit,
  onCancel,
  onSaveCompleted,
  showFormWithSteps,
}) => {
  const [visibleFormIndex, setVisibleFormIndex] = useState(-1);

  const handleOnCancel = useCallback(() => {
    setVisibleFormIndex(-1);
    onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSaveCompleted = useCallback(
    response => {
      onSaveCompleted(response);
      handleOnCancel();
    },
    [handleOnCancel, onSaveCompleted]
  );

  const handleOnDeleteSuccess = handleOnCancel;

  const handleOnClickEdit = useCallback(index => {
    setVisibleFormIndex(index);
    onClickEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={cx()}>
      {user
        .get('post_tax_deductions')
        .map((deduction, index) =>
          visibleFormIndex === index ? (
            <ConnectedPostTaxDeductionForm
              key={`post-tax-deduction-form-${index}`}
              deduction={deduction}
              onCancel={handleOnCancel}
              onSaveCompleted={handleOnSaveCompleted}
              onDeleteSuccess={handleOnDeleteSuccess}
              updateUserInfo={updateUserInfo}
              user={user}
              isEditing
              showFormWithSteps={showFormWithSteps}
            />
          ) : (
            <PostTaxDeduction
              deduction={deduction}
              key={`post-tax-deduction-${index}`}
              onClickEdit={partial(handleOnClickEdit, index)}
              showFormWithSteps={showFormWithSteps}
            />
          )
        )}
    </Box>
  );
};

PostTaxDeductionsList.propTypes = {
  updateUserInfo: PropTypes.func.isRequired,
  user: ImmutablePropTypes.map.isRequired,
  onCancel: PropTypes.func,
  onClickEdit: PropTypes.func,
};

export default PostTaxDeductionsList;
