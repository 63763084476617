import { createAsyncThunk } from '@reduxjs/toolkit';

import { toI18n } from 'util/i18n';

import { SLICE_NAME } from './constants';

export const changeTier = createAsyncThunk(`${SLICE_NAME}/changeTier`, () => {
  window.Alert.show(toI18n('tiers.billing.carbon'), 10000);
  return null;
});

export const downgradeTier = changeTier;
