import { useSelector } from 'react-redux';
import { Map } from 'immutable';

import { getPayrollFeatureEnabled } from 'selectors/payroll';

export const usePayrollEligibility = (user = Map()) => {
  const canVerifyEligibility = useSelector(state =>
    getPayrollFeatureEnabled(state, 'verify_user_eligibility')
  );

  return (
    user &&
    !user.isEmpty?.() &&
    canVerifyEligibility &&
    user.get('included_in_payroll')
  );
};
