export const DOWNGRADE_DRAWER_VIEW_KEY = {
  survey: 'survey',
  success: 'success',
  ctc_support: 'ctc_support',
  secure_trial: 'secure_trial',
  confirmation: 'confirmation',
  offer_discount: 'offer_discount',
  success_scheduled: 'success_scheduled',
  discount_accepted: 'discount_accepted',
  ctc_support_success: 'ctc_support_success',
  multilocation_enforcement: 'multilocation_enforcement',
  already_accepted: 'already_accepted',
};

export const DOWNGRADE_BOTTOM_DRAWER = 'DOWNGRADE_BOTTOM_DRAWER';

export const MODULE_NAME = 'downgradeBottomDrawer';
