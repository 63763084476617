import React, { useCallback } from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Icon, { IconName } from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import baseColors from 'fe-design-base/styles/colors';

import { Variant } from './List';

interface ListItemProps {
  primary: string;
  secondary?: React.ReactNode | string;
  iconName?: IconName;
  illustration?: React.ReactNode;
  index: number;
  variant: Variant;
  quiet?: boolean;
  isMedium: boolean;
  asHTML?: boolean;
  success?: boolean;
}

const ListItem = ({
  asHTML,
  cx,
  cxEl,
  primary,
  secondary,
  iconName,
  illustration,
  index,
  variant,
  quiet,
  isMedium,
  success,
}: ListItemProps & CxProps) => {
  const primaryTextStyles = isMedium ? 'body' : 'bodySm';
  const secondaryTextStyles = isMedium ? 'bodySm' : 'captions';

  const marginAdjust = useCallback(() => {
    if (variant === 'icon') {
      return isMedium ? '4px' : '2px';
    }
    return undefined;
  }, [variant, isMedium]);

  const leftContent = {
    bullet: <Box mt={isMedium ? '1.5px' : ''}>•</Box>,
    check: (
      <Icon
        size="small"
        color="accent500"
        iconName="Success"
        mt={isMedium ? '4px' : '2px'}
      />
    ),
    checklist: (
      <Icon
        size={isMedium ? 'medium' : 'small'}
        color={success ? 'success300' : 'mono400'}
        iconName="Complete"
        mt={isMedium ? '2.5px' : '3px'}
      />
    ),
    number: (
      <Box
        vcenter
        hcenter
        w={20}
        h={20}
        bradiusfull
        b={`solid 1px ${quiet ? baseColors.purple700 : 'transparent'}`}
        bgcolor={quiet ? 'mono0' : 'purple700'}
        mt={isMedium ? '2px' : '1px'}
      >
        <Text color={quiet ? 'mono900' : 'mono0'} variant="captionBold">
          {index + 1}
        </Text>
      </Box>
    ),
    icon: (
      <Box
        vcenter
        hcenter
        w={isMedium ? 32 : 26}
        h={isMedium ? 32 : 26}
        bradiusfull
        bgcolor="purple700"
      >
        <Icon
          size={isMedium ? 'small' : 'xsmall'}
          color="mono0"
          iconName={iconName || 'Success'}
        />
      </Box>
    ),
    illustration: (
      <Box w={isMedium ? 24 : 20} h={isMedium ? 24 : 20}>
        {illustration}
      </Box>
    ),
  };

  return (
    <>
      <Box component="li" className={cx()}>
        <Box alignItemsStart>
          <Box className={cxEl('left-content')} mr={16}>
            {leftContent[variant]}
          </Box>
          <Box column gap="6px" mt={marginAdjust()}>
            <Text variant={primaryTextStyles} color="mono900" asHTML={asHTML}>
              {primary}
            </Text>
            <Text variant={secondaryTextStyles} color="mono700" asHTML={asHTML}>
              {secondary}
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withCx<ListItemProps>('FDBListItem')(ListItem);
