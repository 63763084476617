import React, { useCallback, useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';
import Box from 'fe-design-base/atoms/Box';
import { useIsMediumScreen } from 'fe-design-base/utils/useMediaQuery';

import {
  ELEMENTS,
  EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

const VIDEO_STATES = {
  ENDED: 0,
  PAUSED: 2,
  UNPAUSED: 3,
};

const LaunchWeekStepJohnVideo = ({
  handleNextStep,
  launchWeekSettings,
  onLastStep,
  trackUx,
}) => {
  const [className, setClassName] = useState('');

  const percentageOf = useCallback(
    (measure, percent) => (measure * percent) / 100,
    []
  );

  const isSmallScreen = !useIsMediumScreen();
  const setTimeoutIdRef = useRef(onLastStep());

  useEffect(() => {
    trackUx(EVENT_ACTIONS.PAGE_VIEWED, TRACK_ACTION_TYPES.VIEW, {
      step_label: ELEMENTS.LAUNCH_WEEK_STEP_LABELS.JOHN_VIDEO,
    });
    return () => {
      clearTimeout(setTimeoutIdRef.current);
    };
  }, [trackUx]);

  const videoId = launchWeekSettings.get('award_video_id');

  const videoPlayerOptions = {
    height: percentageOf(window.innerHeight, 70),
    width: percentageOf(window.innerWidth, 35),
    // https://developers.google.com/youtube/player_parameters
    playerVars: {
      // Need to pass the videoId to the player here too, so we can use the loop option
      playlist: videoId,
      loop: 0,
      autoplay: 1,
      // default language that the player will use to display captions
      cc_lang_pref: 'en',
      // causes closed captions to be shown by default, even if the user has turned captions off
      cc_load_policy: 1,
      // player will show related videos from the same channel as the current video
      rel: 0,
      mute: 1,
    },
  };

  const handlePlayerChanges = useCallback(
    event => {
      // For any of those 3 video events (ended, paused, or unpaused)
      // we'll make sure to remove any automatic transition that was scheduled before
      if (event.data === VIDEO_STATES.ENDED) {
        clearTimeout(setTimeoutIdRef.current);
        // Manually go to next step
        handleNextStep();
        setClassName('john-video-fade-out');
      } else if (event.data === VIDEO_STATES.PAUSED) {
        clearTimeout(setTimeoutIdRef.current);
      } else if (event.data === VIDEO_STATES.UNPAUSED) {
        clearTimeout(setTimeoutIdRef.current);
        // Whenever the video is unpaused, we'll schedule the next step
        // as it has just begun. If it ends before the schedule action runs,
        // the first condition will make sure to go to the next step.
        setTimeoutIdRef.current = onLastStep();
      }
    },
    [handleNextStep, onLastStep]
  );

  return (
    <Box row vcenter justifyContentCenter className={className}>
      {!isSmallScreen && (
        <Box column mr={24} ml={-200}>
          <Box w={200} h={200} ml={40}>
            <img src={require('assets/curved-arrow.svg')} />
          </Box>
          <Box w={200} h={100} mt={-50}>
            <img src={require('assets/jw-legend.svg')} />
          </Box>
        </Box>
      )}
      <Box mt={60}>
        <YouTube
          muted
          showCaptions
          videoId={videoId}
          id="jw_launch_week_award"
          opts={videoPlayerOptions}
          onStateChange={handlePlayerChanges}
        />
      </Box>
    </Box>
  );
};

export default LaunchWeekStepJohnVideo;
