import React, { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { object, string } from 'yup';

import { toI18n } from 'util/i18n';

import { I18N_ERROR_PATH, INITIAL_VALUES_NEW_ROLE } from './constants';
import NewRoleModalForm from './NewRoleModalForm';

const AddNewRoleModal = ({ inRolesArray, onAddNewRole }) => {
  const newRoleFormSchema = useMemo(
    () =>
      object().shape({
        newRoleName: string()
          .required(toI18n(`${I18N_ERROR_PATH}.add_new_role`))
          .test(
            'unique',
            toI18n(`${I18N_ERROR_PATH}.role_exists`),
            value => !inRolesArray(value)
          ),
      }),
    [inRolesArray]
  );

  return (
    <Formik
      validationSchema={newRoleFormSchema}
      initialValues={INITIAL_VALUES_NEW_ROLE}
      validateOnBlur={false}
    >
      {({ resetForm }) => (
        <NewRoleModalForm onAddNewRole={onAddNewRole} resetForm={resetForm} />
      )}
    </Formik>
  );
};

AddNewRoleModal.propTypes = {
  inRolesArray: PropTypes.func,
  onAddNewRole: PropTypes.func,
};

export default AddNewRoleModal;
