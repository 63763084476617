import React from 'react';
import Icon from 'react-icon-base';
import PropTypes from 'prop-types';

const ClockIn = ({ color, width, height, props }) => (
  <Icon
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill={color || 'inherit'}
      id="a-clockin"
      d="M8 0h-.333a.367.367 0 0 0-.334.2v8.067c0 .2.134.333.334.333H12.2l-1.733 1.733a.322.322 0 0 0 0 .467c.066.067.133.067.266.067.067 0 .2 0 .267-.067l2.333-2.333c.067-.067.067-.134.067-.267 0-.067-.067-.2-.067-.267L11 5.6a.322.322 0 0 0-.467 0 .322.322 0 0 0 0 .467L12.267 7.8H8V.667A7.312 7.312 0 0 1 15.333 8 7.312 7.312 0 0 1 8 15.333 7.312 7.312 0 0 1 .667 8c0-2.8 1.6-5.4 4.133-6.6.2-.067.267-.267.133-.467a.32.32 0 0 0-.4-.133C1.8 2.133 0 4.933 0 8c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8z"
    />
  </Icon>
);

ClockIn.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ClockIn;
