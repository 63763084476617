import { useHistory } from 'react-router-dom';

import { useRegisterCommandBarRouter } from 'util/commandBar';

export const CommandBarRouter = () => {
  // https://www.command.ai/docs/sdk/lifecycle/addrouter/#react-router-v5
  const history = useHistory();
  useRegisterCommandBarRouter(history);
  return null;
};

export default CommandBarRouter;
