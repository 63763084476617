import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getHoursProvidedExperimentEnabled } from 'selectors/session';

import HoursProvidedDialog from '../HoursProvidedDialog';

export interface DisplayHoursProvidedDialogFromUrlProps {
  hoursProvidedExperimentEnabled: boolean;
}

export const DisplayHoursProvidedDialogFromUrl = ({
  hoursProvidedExperimentEnabled,
}: DisplayHoursProvidedDialogFromUrlProps) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const hoursProvided = queryParams.get('hours-provided');

  const [openSharingDialog, setOpenSharingDialog] = useState(false);

  useEffect(() => {
    if (hoursProvided === 'true') {
      queryParams.delete('hours-provided');

      history.replace({
        search: queryParams.toString(),
      });

      if (hoursProvidedExperimentEnabled) setOpenSharingDialog(true);
    }
  }, [history, hoursProvided, hoursProvidedExperimentEnabled, queryParams]);

  const handleClose = useCallback(() => {
    setOpenSharingDialog(false);
  }, []);

  return (
    <>
      {openSharingDialog && (
        <HoursProvidedDialog fromUrl onClose={handleClose} />
      )}
    </>
  );
};

export default connect(state => ({
  hoursProvidedExperimentEnabled: getHoursProvidedExperimentEnabled(state),
}))(DisplayHoursProvidedDialogFromUrl);
