import React from 'react';
import Icon from 'react-icon-base';

const ContactBook = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M22.31 13.428c.178 0 .356-.148.356-.357v-1.19c0-.179-.178-.357-.357-.357h-.595v-1.429c0-.774-.655-1.429-1.429-1.429h-9.523c-.804 0-1.429.655-1.429 1.43v12.38c0 .804.625 1.429 1.429 1.429h9.523c.774 0 1.429-.625 1.429-1.429v-1.428h.595c.179 0 .357-.15.357-.358V19.5c0-.179-.178-.357-.357-.357h-.595v-1.905h.595c.179 0 .357-.149.357-.357v-1.19c0-.18-.178-.358-.357-.358h-.595v-1.905h.595zm-6.786-.952c1.041 0 1.904.863 1.904 1.905 0 1.071-.863 1.905-1.905 1.905-1.07 0-1.904-.834-1.904-1.905 0-1.042.833-1.905 1.905-1.905zm3.333 7.054c0 .327-.298.565-.685.565h-5.327c-.357 0-.655-.238-.655-.565v-.566c0-.952.893-1.726 1.994-1.726h.149c.357.178.744.238 1.19.238.417 0 .804-.06 1.161-.238h.149c1.101 0 2.024.774 2.024 1.726v.566z"
    />
  </Icon>
);

export default ContactBook;
