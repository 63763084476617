import { Map } from 'immutable';

import { toI18n } from 'util/i18n';

export const MESSAGING_V2_SLICE_NAME = 'messagingV2';
export const DEFAULT_ITEMS_PER_PAGE = 20;
export const DEFAULT_READ_RECEIPTS_PER_PAGE = 2000;
export const DEFAULT_PARTICIPANTS_PER_PAGE = 200;
export const DEFAULT_EXISTING_PARTICIPANTS_PER_PAGE = 45;
export const FIRST_PAGE = 1;
export const ADHOC = 'adhoc';
export const ENTIRE_TEAM = 'Entire Team';
export const CELEBRATION = 'Celebration';
export const ALL_MANAGERS = 'All Managers';
export const EVERYBODY_WORKING_TODAY = 'Everyone Working Today';
export const LOCATION = 'location';
export const CHANNEL_SCROLL_GAP = 50;
export const PRODUCTION = 'production';

export const CHANNEL_TYPES = {
  ENTIRE_TEAM: 'entire_team',
  ALL_MANAGERS: 'all_managers',
  EVERYBODY_WORKING_TODAY: 'everyone_working_today',
  CREATE_CUSTOM_GROUP: 'create_custom_group',
};

export const VIEWS = {
  INDEX: 'MESSENGER_INDEX_VIEW',
  ADD: 'MESSENGER_ADD_VIEW',
  SHOW: 'MESSENGER_SHOW_VIEW',
  GROUP: 'MESSENGER_GROUP_VIEW',
  EMAIL: 'MESSENGER_EMAIL_VIEW',
};

export const GENERIC_ERROR_MESSAGE = toI18n('errors.generic');

export const READ_RECEIPT_STATUS = {
  READ: 'read',
  SENT: 'sent',
};

export const TEST_CHANNELS = {
  ENTIRE: {
    company_id: 473,
    description: 'Voluptatem at autem ut.',
    id: 'OWeNvUhyoL2i8a1E3sdeijmt8jWaPd4xzUyqqKqTsCMgsdasJa26gzAfQ1ZPSEyWJy',
    images: [
      'https://cdn.joinhomebase.com/avatars/av_medium.png',
      'https://cdn.joinhomebase.com/avatars/bu_medium.png',
    ],
    is_hidden: false,
    is_high_traffic: true,
    is_muted: false,
    is_system: false,
    is_unread: true,
    is_starred: false,
    location_id: 406,
    name: 'Entire Team',
    last_message_date_secs: 1692033881,
    last_message_date_formatted: '2023-08-14 17:24:41 +0000',
    type: 'entire_team',
    total_recipients: 2,
    unread_count: 1,
  },
  CELEBRATION: {
    company_id: 452,
    description: 'Voluptatem at autem ut.',
    id: 'CTeNvUhyoL2i8a1E3cRVijmt8jWaPd4xzUyqqKqTsCMg6sJa26gzAfQ1ZPSEyWJy',
    images: [
      'https://cdn.joinhomebase.com/avatars/av_medium.png',
      'https://cdn.joinhomebase.com/avatars/bu_medium.png',
    ],
    is_hidden: false,
    is_high_traffic: true,
    is_muted: false,
    is_system: false,
    is_unread: true,
    is_starred: false,
    location_id: 406,
    name: 'Celebration',
    last_message_date_secs: 1692033881,
    last_message_date_formatted: '2023-08-14 17:24:41 +0000',
    type: 'celebration',
    total_recipients: 2,
    unread_count: 1,
  },
  ADHOC: {
    company_id: 452,
    description: 'Voluptatem at autem ut.',
    id: 'Vyue4KqPhvx3EBSZcXNwYbPmuYxUC5LF2o4BcrvJKDz8gjzSZiSEmsmSk7QMyicA',
    images: [
      'https://cdn.joinhomebase.com/avatars/av_medium.png',
      'https://cdn.joinhomebase.com/avatars/bu_medium.png',
    ],
    is_hidden: false,
    is_high_traffic: true,
    is_muted: false,
    is_system: false,
    is_unread: true,
    is_starred: false,
    location_id: null,
    name: 'Alice V., Ben U.',
    last_message_date_secs: 1692033881,
    last_message_date_formatted: '2023-08-14 17:24:41 +0000',
    type: 'adhoc',
    total_recipients: 2,
    unread_count: 2,
  },
  SYSTEM: {
    company_id: null,
    description: 'Voluptatem at autem ut.',
    id: 'oXKAqrenRm5bZ5UQBLxGpATg85Vz6M16SsuWHY4Fb69sRS5PQWEm2MAjv5t7EL1w',
    images: ['https://cdn.joinhomebase.com/assets/homebase_bot_avatar.png'],
    is_hidden: false,
    is_high_traffic: true,
    is_muted: false,
    is_system: true,
    is_unread: false,
    is_starred: false,
    location_id: null,
    name: 'Homebase',
    last_message_date_secs: 1692033881,
    last_message_date_formatted: '2023-08-14 17:24:41 +0000',
    type: 'system',
    total_recipients: 0,
    unread_count: 2,
  },
  ALL_MANAGERS: {
    company_id: null,
    description: 'Voluptatem at autem ut.',
    id: 'EhbseyibsenRm5bZ5UQBLxGpATg85Vz6M16SsuWHY4Fb69sRS5PQWEm2MsEsv5t7EL1w',
    images: ['https://cdn.joinhomebase.com/assets/homebase_bot_avatar.png'],
    is_hidden: false,
    is_high_traffic: true,
    is_muted: false,
    is_system: true,
    is_unread: true,
    is_starred: false,
    location_id: null,
    name: 'All Managers',
    last_message_date_secs: 1692033881,
    last_message_date_formatted: '2023-08-14 17:24:41 +0000',
    type: 'all_managers',
    total_recipients: 0,
    unread_count: 2,
  },
  HIDDEN: {
    company_id: null,
    description: 'Voluptatem at autem ut.',
    id: 'ShbseyibsenRm5bZ5UQBLxGpATg85Vz6Mwrer6SsuWHY4Fb69sRS5PQWEm2M45Esv5t7EL1w',
    images: ['https://cdn.joinhomebase.com/assets/homebase_bot_avatar.png'],
    is_hidden: true,
    is_high_traffic: true,
    is_muted: false,
    is_system: true,
    is_unread: true,
    is_starred: false,
    location_id: null,
    name: 'All Managers',
    last_message_date_secs: 1692033881,
    last_message_date_formatted: '2023-08-14 17:24:41 +0000',
    type: 'all_managers',
    total_recipients: 0,
    unread_count: 2,
  },
};

export const TEST_MESSAGES = {
  MINE: {
    id: 'SdhSshyu3yvduvjhs',
    body: 'Voluptatem at autem ut.',
    is_system: false,
    is_archived: false,
    is_mine: true,
    is_unread: true,
    attachment: null,
    coordinates: null,
    sending_user: {
      id: 1941,
      name: 'Ivana Yolo',
      avatar: 'https://cdn.joinhomebase.com/avatars/iy_medium.png',
    },
    created_at_secs: 1693343408,
    created_at_formatted: '2023-08-29 21:10:08 +0000',
    shoutout: null,
    channel_id:
      'CTeNvUhyoL2i8a1E3cRVijmt8jWaPd4xzUyqqKqTsCMg6sJa26gzAfQ1ZPSEyWJy',
    cover_request: null,
    total_recipients: null,
    reactions: [],
    total_reads: null,
    can_delete: false,
  },
  NOT_MINE: {
    id: 1123,
    body: 'Voluptatem at autem ut.',
    is_system: false,
    is_archived: false,
    is_mine: false,
    is_unread: true,
    attachment: null,
    coordinates: null,
    sending_user: {
      id: 1941,
      name: 'Ivana Yolo',
      avatar: 'https://cdn.joinhomebase.com/avatars/iy_medium.png',
    },
    created_at_secs: 1693343408,
    created_at_formatted: '2023-08-29 21:10:08 +0000',
    shoutout: null,
    channel_id:
      'CTeNvUhyoL2i8a1E3cRVijmt8jWaPd4xzUyqqKqTsCMg6sJa26gzAfQ1ZPSEyWJy',
    cover_request: null,
    total_recipients: null,
    reactions: [],
    total_reads: null,
    can_delete: false,
  },
};

export const ACTION_TYPES = {
  TOGGLE_MESSENGER: `${MESSAGING_V2_SLICE_NAME}/toggleMessenger`,
  FETCH_CHANNELS: `${MESSAGING_V2_SLICE_NAME}/fetchChannels`,
  // eslint-disable-next-line max-len
  FETCH_EXISTING_CHANNEL_PARTICIPANTS: `${MESSAGING_V2_SLICE_NAME}/fetchExistingChannelParticipants`,
  FETCH_CHANNEL_MESSAGES: `${MESSAGING_V2_SLICE_NAME}/fetchChannelMessages`,
  FETCH_MESSAGE_READ_LIST: `${MESSAGING_V2_SLICE_NAME}/fetchMessageReadList`,
  FETCH_LOCATION_PARTICIPANTS: `${MESSAGING_V2_SLICE_NAME}/fetchLocationParticipants`,
  FETCH_CHANNEL_PARTICIPANTS: `${MESSAGING_V2_SLICE_NAME}/fetchChannelParticipants`,
  CREATE_CHANNEL: `${MESSAGING_V2_SLICE_NAME}/createChannel`,
  UPDATE_CHANNEL: `${MESSAGING_V2_SLICE_NAME}/updateChannel`,
  MARK_MESSAGE_AS_READ: `${MESSAGING_V2_SLICE_NAME}/markMessageAsRead`,
  CREATE_MESSAGE: `${MESSAGING_V2_SLICE_NAME}/createMessage`,
  RESEND_MESSAGE: `${MESSAGING_V2_SLICE_NAME}/resendMessage`,
  DELETE_MESSAGE: `${MESSAGING_V2_SLICE_NAME}/deleteMessage`,
};

export const MESSAGING_V2_EVENTS = {
  MESSAGE_CREATED: 'messaging_v2_message_created',
  MESSAGE_UPDATED: 'messaging_v2_message_updated',
  CHANNEL_UPDATED: 'messaging_v2_channel_updated',
  CHANNEL_UPDATED_WITH_INDEX: 'messaging_v2_channel_updated_with_index',
  UNREAD_COUNT_UPDATED: 'messaging_v2_unread_count_updated',
  CHANNEL_DELETED: 'messaging_v2_channel_deleted',
};

export const OTHER_EVENTS = {
  HIRING_NEW_MESSAGE: 'hiring_new_message',
  CURRENT_LOCATION_CHANGED: 'current_location_changed',
  LEFT_NAV_CHANGED: 'left_nav_changed',
};

export const AUTOPAYROLL_EVENTS = {
  AUTOPAYROLL_FIVE_MINUTE_WARNING: 'autopayroll_five_minute_warning',
  AUTOPAYROLL_SUBMITTED_REFRESH: 'autopayroll_submitted_refresh',
};

export const MESSAGE_DRAWER_TABS = {
  TEAM: 'team',
  APPLICANTS: 'applicants',
  EMAILS: 'emails',
  ANNOUNCEMENTS: 'announcements',
};

export const MESSENGER_ACTIVATION_DEFAULT_OPTIONS = [
  {
    name: 'message-group',
    title: 'message_drawer.empty_state.link1',
    desc: 'message_drawer.empty_state.link1_desc',
    tab: MESSAGE_DRAWER_TABS.TEAM,
    view: VIEWS.GROUP,
  },
  {
    name: 'message-team-member',
    title: 'message_drawer.empty_state.link2',
    desc: 'message_drawer.empty_state.link2_desc',
    tab: MESSAGE_DRAWER_TABS.TEAM,
    view: VIEWS.ADD,
  },
];

export const EDIT_FORM = {
  ADD: 'ADD_PARTICIPANTS',
  RENAME: 'RENAME_CHANNEL',
};

export const TRACKING_BUTTON_TEXTS = {
  DOWNLOAD_ALL_MESSAGES: 'Download All Messages',
  PARTICIPANTS_ADDED: 'Participants Added',
  START_MESSAGE: 'Start Message',
  VIEW_READ_RECEIPTS: 'View Read Receipts',
  CONVERSATION: 'Conversation',
  CONVERSATION_RENAMED: 'Conversation Renamed',
  MESSAGING_MENU: 'Messaging Menu',
  ADD_PARTICIPANTS: 'Add Participants',
  RENAME_CHANNEL: 'Rename Channel',
};

export const APPLICATION_MESSENGER_VIEWS = {
  INDEX: 'APPLICATION_MESSENGER_INDEX_VIEW',
  SHOW: 'APPLICATION_MESSENGER_SHOW_VIEW',
};

export const READ_RECEIPTS_TABS = {
  READ: 'read',
  UNREACHABLE: 'unreachable',
};

export const INITIAL_APPLICATION_MESSENGER_ROUTER_STATE = Map({
  view: APPLICATION_MESSENGER_VIEWS.INDEX,
});

export const MESSAGE_DRAWER_STATE = Map({
  tab: MESSAGE_DRAWER_TABS.TEAM,
  readReceiptTab: READ_RECEIPTS_TABS.READ,
});

export const INITIAL_ROUTER_STATE = Map({
  view: VIEWS.INDEX,
  channelId: null,
  canDeleteMessages: false,
  prefilledMessage: null,
  prefilledData: null,
});
