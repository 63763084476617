import { List, Map } from 'immutable';
import { createSelector } from 'reselect';

import * as applicantMessengerSelectors from 'selectors/applicantMessenger';
import * as entitiesSelectors from 'selectors/entities';
import * as fetchingSelectors from 'selectors/fetching';
import * as interviewsSelector from 'selectors/hiring/interviews';
import * as routeSelectors from 'selectors/route';
import * as sessionSelectors from 'selectors/session';

import {
  HIRING_FEEDBACK_MODAL_FIRST_TIME_POPUP_KEY,
  HIRING_WIDGET,
  HIRING_WIDGET_MESSAGES_LIMIT,
} from 'features/hiring/constants';
import {
  APPLIED_TO_LOCATION_VALUE,
  SORT_NAME_STATE,
  SORT_RELEVANCE_STATE,
  SORT_UNREAD_MESSAGES_STATE,
} from 'features/hiring/manageApplicants/constants';
import {
  applicantFilterToStates,
  filterByAvailability,
  filterByQuality,
  filterBySource,
} from 'features/hiring/manageApplicants/util';
import { findLocationById } from 'features/hiring/util';

import { moment } from 'util/dateTime';
import { toEntityId } from 'util/entities';
import { buildFormSelectors } from 'util/forms';
import {
  buildHiringRoleOptions,
  formatPrefillLastMessageBody,
  leftHiringToNonHiringReact,
} from 'util/hiring';
import { toI18n } from 'util/i18n';

export const getCompanyProfilePath = state =>
  state.getIn(['hiring', 'dashboard', 'companyProfilePath']);
export const getShiftPayEnabled = state =>
  state.getIn(['hiring', 'dashboard', 'shiftPayEnabled']);
export const getHiringDashboardIsFetching = state =>
  state.getIn(['hiring', 'dashboard', 'isFetching']);
export const getHiringCompanyProfileIsFetching = state =>
  state.getIn(['hiring', 'companyProfile', 'isFetching']);
export const getHiringCompanyProfileIsEditing = state =>
  state.getIn(['hiring', 'companyProfile', 'isEditing']);
export const getHiringCompanyProfileFormIsSubmitting = state =>
  state.getIn(['hiring', 'companyProfileForm', 'isSubmitting']);
export const getHiringSettingsVerificationComplete = state =>
  state.getIn(['hiring', 'settings', 'verification_complete']);
export const getCompanyHiringSettings = state =>
  state.getIn(['hiring', 'settings', 'hiring_settings']);
export const getStatusOfAbilityToAddApplicantAccess = state =>
  state.getIn([
    'hiring',
    'settings',
    'hiring_settings',
    'add_applicant_manually_state',
  ]);
export const getCompanyBusinessAddress = state =>
  state.getIn(['hiring', 'settings', 'business_address']);

export const getJobRequestBoostPartners = state =>
  state.getIn(['hiring', 'boostUpsell', 'partners']);

export const getSingleTransactionBoostPrices = state =>
  state.getIn(['hiring', 'boostUpsell', 'boost_base_price']);

export const getSingleTransactionBoostDurations = state =>
  state.getIn(['hiring', 'boostUpsell', 'boost_durations']);

export const getSingleTransactionBoostPrice = (partner, state) =>
  state.getIn(['hiring', 'boostUpsell', 'boost_base_price', partner]);

export const getSingleTransactionBoostDuration = (partner, state) =>
  state.getIn(['hiring', 'boostUpsell', 'boost_durations', partner]);

export const getShowIndeedHiringWhitelisting = createSelector(
  getCompanyBusinessAddress,
  getCompanyHiringSettings,
  (businessAddress, companyHiringSettings) => {
    const companyMissingInformation =
      !businessAddress ||
      !companyHiringSettings?.get('website') ||
      !companyHiringSettings?.get('syndication_email');

    return (
      !companyHiringSettings?.get('indeed_syndication') &&
      companyMissingInformation
    );
  }
);

export const getJobRequestApplicationFormSubmitting = state =>
  state.getIn(['hiring', 'jobRequestApplicationForm', 'isSubmitting']);

export const getAddApplicantManuallyRole = state =>
  state.getIn(['hiring', 'addApplicantsManuallyDrawer', 'defaultRole']);

export const getAddApplicantManuallyLocation = state =>
  state.getIn(['hiring', 'addApplicantsManuallyDrawer', 'defaultLocation']);

export const getLocationApplicationFormSubmitting = state =>
  state.getIn(['hiring', 'locationApplicationForm', 'isSubmitting']);

export const getHiringDashboardWidgetIsFetchingMessages = state =>
  state.getIn(['hiring', 'dashboardWidget', 'isFetching']);

export const getHiringManageApplicantsPrefillLastMessageId = state =>
  state.getIn(['hiring', 'manageApplicants', 'prefillLastMessageId']);

export const getHiringStandardizedRoles = createSelector(
  entitiesSelectors.getSortedStandardizedRoles,
  roles => roles.filter(role => role.get('show_on_rodeo'))
);

export const getCustomRoles = state =>
  state.getIn(['hiring', 'dashboard', 'customRoles']);
export const getHiringRoleOptions = createSelector(
  getHiringStandardizedRoles,
  getCustomRoles,
  buildHiringRoleOptions
);

export const getApplicantCustomRoles = state =>
  state.getIn(['hiring', 'applicantProfile', 'customRoles']);
export const getHiringApplicantRoleOptions = createSelector(
  getHiringStandardizedRoles,
  getApplicantCustomRoles,
  buildHiringRoleOptions
);

export const getLocations =
  entitiesSelectors.createLocationsForViewSelector('hiring');

export const getPopularRoleOptionsForCurrentLocation = createSelector(
  getHiringStandardizedRoles,
  getLocations,
  sessionSelectors.getCurrentLocationId,
  (hiringStandardizedRoles, locations, id) => {
    function getRoleByName(name) {
      return (
        hiringStandardizedRoles.find(role => role.get('name') === name) || Map()
      );
    }

    return findLocationById(locations, id)
      .get('popular_roles', List())
      .map(getRoleByName)
      .filter(role => !role.isEmpty())
      .sort((a, b) => a.get('name').localeCompare(b.get('name')));
  }
);

export const getCanEditCompanyProfile = state =>
  state.getIn(['hiring', 'companyProfile', 'canEdit']);
export const getJobRequestsLoaded = entitiesSelectors.createLoadedSelector(
  'jobRequests',
  'HIRING'
);
export const getCompanyProfilesLoaded = entitiesSelectors.createLoadedSelector(
  'companyProfiles',
  'HIRING'
);

export const getApplicantProfileIsFetching =
  fetchingSelectors.buildIsFetchingSelector('fetch_applicant_profile');
export const getApplicantProfileIsLoaded =
  entitiesSelectors.createLoadedSelector('applicantProfile', 'HIRING');
export const getApplicantProfileIsSubmitting =
  fetchingSelectors.buildIsFetchingSelector('submit_applicant_profile');
export const getApplicantProfileServerErrors = state =>
  state.getIn(['hiring', 'applicantProfile', 'serverErrors']);

export const getApplicantProfileMobileTabView = state =>
  state.getIn(['hiring', 'applicantProfile', 'mobileTabView']);

const isLoading = (isFetching, isLoaded) => isFetching || !isLoaded;

export const getApplicantProfileLoading = createSelector(
  getApplicantProfileIsFetching,
  getApplicantProfileIsLoaded,
  isLoading
);

export const getHiringDashboardIsLoading = createSelector(
  getHiringDashboardIsFetching,
  getJobRequestsLoaded,
  isLoading
);

export const getHiringCompanyProfileIsLoading = createSelector(
  getHiringCompanyProfileIsFetching,
  getCompanyProfilesLoaded,
  isLoading
);

export const getCompanyProfileFromRoute = createSelector(
  entitiesSelectors.getCompanyProfiles,
  routeSelectors.getRouteParam,
  (companyProfiles, url) =>
    companyProfiles.find(companyProfile => companyProfile.get('url') === url)
);

export const getLocationProfilesFromRoute = createSelector(
  getCompanyProfileFromRoute,
  entitiesSelectors.getLocationProfiles,
  (companyProfile, locationProfiles) =>
    locationProfiles.filter(locationProfile =>
      companyProfile
        .get('location_profiles')
        .includes(locationProfile.get('id'))
    )
);

export const companyProfileFormSelectors = buildFormSelectors([
  'hiring',
  'companyProfileForm',
]);

// Job Post Page 3.0
export const getLocationOptions = createSelector(getLocations, locations =>
  locations
    .valueSeq()
    .map(location => ({
      value: location.get('id'),
      label: location.get('name'),
    }))
    .toList()
);

// Manage Applicants
export const getStatus = (state, props) => props.status;
export const getDistance = (state, props) => props.distance;
export const getQuality = (state, props) => props.quality;
export const getSource = (state, props) => props.source;
export const getWeekAvailabilities = (state, props) => props.weekAvailabilities;
export const getSort = (state, props) => props.sort;
const getApplicationsRaw = entitiesSelectors.createEntitiesForViewSelector(
  'applications',
  'manageApplicants'
);
const getApplicationsWithPanelDataRaw =
  entitiesSelectors.createEntitiesForViewSelector(
    'applications',
    'applicantPanel'
  );

const decorateApplicationsWithHasUnread = (
  applications,
  applicationConversations
) =>
  applications.map(application => {
    const conversationId = application.get('application_conversation');
    const conversation =
      conversationId && applicationConversations.get(conversationId.toString());

    return application.set(
      'hasUnreadMessages',
      conversation && conversation.get('has_unread')
    );
  });

export const getApplications = createSelector(
  getApplicationsRaw,
  entitiesSelectors.getApplicationConversations,
  decorateApplicationsWithHasUnread
);

export const getApplicationsWithPanelData = createSelector(
  getApplicationsWithPanelDataRaw,
  entitiesSelectors.getApplicationConversations,
  decorateApplicationsWithHasUnread
);

export const getManageApplicantsOwner = createSelector(
  entitiesSelectors.getLocationsIndex,
  entitiesSelectors.getJobRequests,
  (_state, { ownerId, ownerType }) => ({ ownerId, ownerType }),
  (locations, jobRequests, { ownerId, ownerType }) => {
    let owner;
    let location;

    if (ownerType === 'locations') {
      owner = locations.get(ownerId, Map());
      location = owner;
    } else {
      owner = jobRequests.get(ownerId, Map());
      const locationId = owner.get('location_id') || owner.get('location');
      location = locations.get(locationId && locationId.toString(), Map());
    }

    // Normalize values to avoid duplicate logic in components
    return owner.merge({
      location_name: location.get('name'),
      location_id: location.get('id'),
      location_interview_notes: location.get('interview_notes'),
    });
  }
);

export const getManageApplicantsLoading = createSelector(
  getManageApplicantsOwner,
  owner => !owner.get('applications_fetched_at')
);

export const getManageApplicantsOwnerUrl = createSelector(
  getManageApplicantsOwner,
  owner => owner.get('full_url')
);

export const getManageApplicantsHeaderLoading = createSelector(
  getHiringDashboardIsLoading,
  getManageApplicantsLoading,
  (dashboardLoading, manageApplicantsLoading) =>
    dashboardLoading && manageApplicantsLoading
);

export const getManageApplicantsApplications = createSelector(
  getManageApplicantsOwner,
  getApplications,
  (owner, applications) =>
    owner
      .get('applications', List())
      .map(id => applications.get(toEntityId(id)))
);

export const getManageApplicantsApplicationsTotal = createSelector(
  getManageApplicantsApplications,
  applications => applications.size
);

export const getIsFetchingApplicantData = (state, { token }) =>
  fetchingSelectors.getIsFetching(state, { requestId: `applicant-${token}` });

const getApplicationRouteParams = (
  _state,
  { applicantToken, ownerType, ownerId }
) => ({ applicantToken, ownerType, ownerId });

const findApplicationByRouteParams = (
  applications,
  { applicantToken, ownerType, ownerId }
) =>
  applications.find(
    application =>
      application.get('token') === applicantToken &&
      application.get('owner_type') === ownerType &&
      application.get('owner_id').toString() === ownerId
  ) || Map();

// Includes applications without ApplicantPanel data (full applicant data)
export const getApplicantDataFromRoute = createSelector(
  getApplications,
  getApplicationRouteParams,
  findApplicationByRouteParams
);

export const getApplicantPanelDataFromRoute = createSelector(
  getApplicationsWithPanelData,
  getApplicationRouteParams,
  findApplicationByRouteParams
);

const findApplicationById = (applications, id) =>
  applications.get(toEntityId(id), Map());

export const getApplicantDataById = createSelector(
  getApplications,
  (_state, { id }) => id,
  findApplicationById
);

export const getApplicationIsHired = createSelector(
  getApplicantDataById,
  application => application.get('state') === 'hired'
);

export const getApplicantPanelDataById = createSelector(
  getApplicationsWithPanelData,
  (_state, { id }) => id,
  findApplicationById
);

export const getApplicationIdFromRoute = createSelector(
  getApplications,
  getApplicationRouteParams,
  (applications, routeParams) =>
    findApplicationByRouteParams(applications, routeParams).get('id')
);

export const getApplicantIdFromRoute = createSelector(
  getApplications,
  getApplicationRouteParams,
  (applications, routeParams) =>
    findApplicationByRouteParams(applications, routeParams).get('applicant_id')
);

export const getHireModalIsOpen = state =>
  state.getIn(['hiring', 'manageApplicants', 'hireModalIsOpen']);
export const getHireModalApplicant = state =>
  state.getIn(['hiring', 'manageApplicants', 'hireModalApplicant'], Map());
export const getHiredUserId = state =>
  state.getIn(['hiring', 'manageApplicants', 'hiredUserId']);

export const getBailoutFetchApplicantData = createSelector(
  getApplicantPanelDataById,
  getIsFetchingApplicantData,
  (data, isFetching) => !!(isFetching || data.size)
);

const statusFilterMatch = (applicationStates, application, statusFilter) => {
  if (statusFilter === 'favorited') {
    return (
      application.get('liked') &&
      applicationStates.includes(application.get('state'))
    );
  }
  if (statusFilter === 'all_statuses') {
    return true;
  }

  return applicationStates.includes(application.get('state'));
};

const distanceFilterMatch = (application, distanceFilter) => {
  if (!distanceFilter || distanceFilter === 'all') {
    return true;
  }

  const distance = parseInt(application.get('distance'), 10);
  if (distanceFilter === 'unknown') {
    return isNaN(distance);
  }

  if (isNaN(distance)) {
    return false;
  }

  const [mod, range] = distanceFilter.split('_');
  const rangeInt = parseInt(range, 10);

  if (mod === 'lte') {
    return distance <= rangeInt;
  }
  if (mod === 'gt') {
    return distance > rangeInt;
  }

  return true;
};

const filterApplicationsWithoutSort = (
  applications,
  statusFilter,
  distanceFilter,
  qualityFilter,
  sourceFilter,
  weekAvailabilitiesFilter,
  skipFilter
) => {
  const applicationStates = applicantFilterToStates(statusFilter);

  return applications.filter(application => {
    // FILTER BY STATUS
    if (!statusFilterMatch(applicationStates, application, statusFilter)) {
      return false;
    }

    // FILTER BY DISTANCE
    if (!distanceFilterMatch(application, distanceFilter)) {
      return false;
    }

    // FILTER BY SOURCE
    if (
      skipFilter !== 'source' &&
      sourceFilter &&
      Object.keys(sourceFilter).length &&
      !filterBySource(application, sourceFilter)
    ) {
      return false;
    }

    // FILTER BY QUALITY
    if (
      skipFilter !== 'quality' &&
      qualityFilter &&
      Object.keys(qualityFilter).length &&
      !filterByQuality(application, qualityFilter)
    ) {
      return false;
    }

    // FILTER BY AVAILABILITY
    if (
      skipFilter !== 'weekAvailabilities' &&
      weekAvailabilitiesFilter &&
      Object.keys(weekAvailabilitiesFilter).length &&
      !filterByAvailability(application, weekAvailabilitiesFilter)
    ) {
      return false;
    }

    return true;
  });
};

export const filterApplications = (
  applications,
  statusFilter,
  distanceFilter,
  qualityFilter,
  sourceFilter,
  weekAvailabilitiesFilter,
  sort
) => {
  const applicationsFiltered = filterApplicationsWithoutSort(
    applications,
    statusFilter,
    distanceFilter,
    qualityFilter,
    sourceFilter,
    weekAvailabilitiesFilter
  );

  const hasUnreadMessages = applicationsFiltered.some(application =>
    application.get('hasUnreadMessages')
  );

  /*
   * Sort logic is little bit complicated, there are some scenarios:
   * 1. Sort by date: HB Verified first ordered by most recently applied first, then
   * non-HB Verified ordered by most recently applied.
   * 2. Sort by relevance: HB Verified first ordered by greater match_score first, then
   * non-HB Verified ordered by greater match_score first.
   * 3. Sort by unread messages: Applicants that have unread messages first, no matter whether
   * it's HB Verified or not, ordered by greater match_score first, then ordered by relevance
   * (eg #2 above)
   * 4. When no sort selected and there are no unread messages, the default order is by relevance
   * (eg #2 above)
   * 5. When no sort selected and there are unread messages, the default order is by
   * unread messages (eg #3 above)
   */

  return applicationsFiltered.sort((appA, appB) => {
    // To make this a bit more readable, perform the relevant comparisons
    // ahead of applying them.  Also... boolean subtraction does exactly what you
    // want it to do.
    const hbvComp = appB.get('hb_verified') - appA.get('hb_verified');
    const unreadComp =
      appB.get('hasUnreadMessages') - appA.get('hasUnreadMessages');
    const scoreComp = appB.get('match_score') - appA.get('match_score');
    const dateComp =
      moment(appB.get('applied_at'), 'full').unix() -
      moment(appA.get('applied_at'), 'full').unix();
    const relevanceComp = hbvComp || scoreComp;
    const nameComp =
      appA.get('first_name').localeCompare(appB.get('first_name')) ||
      appA.get('last_name').localeCompare(appB.get('last_name'));

    // default sort will depend on whether there are unread messages
    const sortByUnreadMessages =
      (!sort && hasUnreadMessages) || sort === SORT_UNREAD_MESSAGES_STATE;
    const sortByRelevance = !sort || sort === SORT_RELEVANCE_STATE;
    const sortByName = sort === SORT_NAME_STATE;

    if (sortByUnreadMessages) {
      return unreadComp || dateComp || relevanceComp;
    } else if (sortByRelevance) {
      return relevanceComp || dateComp;
    } else if (sortByName) {
      return nameComp || dateComp || relevanceComp;
    }

    // in case where the date is equal, go ahead and use relevance
    return dateComp || relevanceComp;
  });
};

export const getManageApplicantsFilteredApplicationsWithSkip = createSelector(
  getManageApplicantsApplications,
  getStatus,
  getDistance,
  getQuality,
  getSource,
  getWeekAvailabilities,
  (_, props) => props.name,
  filterApplicationsWithoutSort
);

export const getManageApplicantsFilteredApplications = createSelector(
  getManageApplicantsApplications,
  getStatus,
  getDistance,
  getQuality,
  getSource,
  getWeekAvailabilities,
  getSort,
  filterApplications
);

export const getManageApplicantsFilteredApplicationsTotal = createSelector(
  getManageApplicantsFilteredApplications,
  applications => applications.size
);

export const getFirstApplicantToken = createSelector(
  getManageApplicantsFilteredApplications,
  applications =>
    applications.first() ? applications.first().get('token') : ''
);

export const getApplicantPanelDataLoading = createSelector(
  getManageApplicantsLoading,
  getIsFetchingApplicantData,
  getApplicantDataFromRoute,
  (loadingMain, loadingPanelData, applicant) =>
    !!(
      loadingMain ||
      loadingPanelData ||
      (applicant.size && !applicant.getIn(['forViews', 'applicantPanel']))
    )
);

export const getApplicantPanelEmpty = createSelector(
  getApplicantPanelDataLoading,
  getFirstApplicantToken,
  (loading, firstApplicantToken) => !loading && !firstApplicantToken
);

const getManageApplicantsRouteParams = (
  _state,
  { ownerType, ownerId, applicantToken }
) => ({ ownerType, ownerId, applicantToken });

export const getNextApplicantUrl = createSelector(
  getManageApplicantsRouteParams,
  getManageApplicantsFilteredApplications,
  ({ ownerType, ownerId, applicantToken }, applications) => {
    const currentIndex = applications.findIndex(
      a => a.get('token') === applicantToken
    );
    const rootUrl = `/hiring/${ownerType}/${ownerId}/manage_applicants`;

    let nextApplication = applications.get(currentIndex + 1);
    if (!nextApplication) {
      nextApplication = applications.first();
    }

    return nextApplication
      ? `${rootUrl}/${nextApplication.get('token')}`
      : rootUrl;
  }
);

export const getIsHiringApplication = (state, { id }) =>
  fetchingSelectors.getIsFetching(state, {
    requestId: `hire-application-${id}`,
  });

export const getNextApplicantToken = createSelector(
  getManageApplicantsRouteParams,
  getManageApplicantsFilteredApplications,
  ({ applicantToken }, applications) => {
    const currentIndex = applications.findIndex(
      a => a.get('token') === applicantToken
    );
    let nextApplication = applications.get(currentIndex + 1);
    if (!nextApplication) {
      nextApplication = applications.first();
    }

    return nextApplication ? nextApplication.get('token') : '';
  }
);

export const getPreviousApplicantToken = createSelector(
  getManageApplicantsRouteParams,
  getManageApplicantsFilteredApplications,
  ({ applicantToken }, applications) => {
    const currentIndex = applications.findIndex(
      a => a.get('token') === applicantToken
    );
    let prevApplication = applications.get(currentIndex - 1);
    if (!prevApplication) {
      prevApplication = applications.last();
    }

    return prevApplication ? prevApplication.get('token') : '';
  }
);

export const getManageApplicantsCurrentFilterCount = createSelector(
  getManageApplicantsFilteredApplications,
  applications => applications.size
);

export const getSortedApplicationsForApplicantProfile = createSelector(
  sessionSelectors.getCurrentApplicantId,
  entitiesSelectors.getApplications,
  (currentApplicantId, applications) =>
    applications
      .toSeq()
      .filter(
        application =>
          application.get('hiring_applicant_id') === currentApplicantId
      )
      .sort((a, b) => {
        const aAppliedAt = a.get('applied_at_unix');
        const bAppliedAt = b.get('applied_at_unix');

        if (aAppliedAt > bAppliedAt) return -1;
        if (aAppliedAt < bAppliedAt) return 1;
        return 0;
      })
      .toMap()
);

export const getHasLeftHiringWithNoJobs = createSelector(
  sessionSelectors.getCurrentUserAndLocationExists,
  sessionSelectors.getCurrentCompanyHasNoJobRequests,
  sessionSelectors.getLeftHiringToNonHiringRails,
  leftHiringToNonHiringReact,
  (userAndLocationExists, hasNoJobRequests, userLeftToRails, userLeftToReact) =>
    userAndLocationExists &&
    hasNoJobRequests &&
    (userLeftToRails || userLeftToReact)
);

export const getShowHiringFeedbackModal = createSelector(
  getHasLeftHiringWithNoJobs,
  state =>
    sessionSelectors.getFirstTimePopupShown(state, {
      popupKey: HIRING_FEEDBACK_MODAL_FIRST_TIME_POPUP_KEY,
    }),
  sessionSelectors.getHasVisitedHiringBefore,
  (hasLeftHiringWithNoJobs, feedbackModalShown, hasVisitedHiringBefore) =>
    hasLeftHiringWithNoJobs && !feedbackModalShown && hasVisitedHiringBefore
);

export const getNotesForApplication = createSelector(
  entitiesSelectors.getApplications,
  (_state, { applicationId }) => applicationId,
  (applications, applicationId) => {
    if (!applicationId) return List();
    return applications
      .get(toEntityId(applicationId), Map())
      .get('notes', Map())
      .toList()
      .sort((a, b) => {
        const mA = moment(a.get('created_at'));
        const mB = moment(b.get('created_at'));

        if (mA.isSame(mB)) return 0;

        if (mA.isBefore(mB)) return 1;

        return -1;
      });
  }
);

export const applicationNoteIsSubmitting = (state, { applicationId }) =>
  fetchingSelectors.getIsFetching(state, {
    requestId: `create-job-application-note-${applicationId}`,
  });

export const applicationNoteIsDeleting = (state, { note }) =>
  fetchingSelectors.getIsFetching(state, {
    requestId: `delete-job-application-note-${note.get('id')}`,
  });

const formatRoleOptions = jobRequests =>
  jobRequests
    .map(jr => ({
      label: jr.get('role_name'),
      value: jr.get('id'),
      locationId: jr.get('location_id'),
    }))
    .toList()
    .push({
      label: toI18n('hiring.manage_applicants.any_role'),
      value: APPLIED_TO_LOCATION_VALUE,
    });

export const getManageApplicantsHeaderRoleOptions = createSelector(
  entitiesSelectors.getJobRequests,
  getManageApplicantsOwner,
  (jobRequests, owner) => {
    const locationId = owner.get('location_id');
    jobRequests = jobRequests.filter(
      jr => jr.get('location_id') === locationId
    );

    return formatRoleOptions(jobRequests);
  }
);

export const getRoleOptionsForRoleLocationPicker = createSelector(
  entitiesSelectors.getJobRequests,
  jobRequests => formatRoleOptions(jobRequests.filter(j => j.get('active')))
);

export const getLocationOptionsForRoleLocationPicker = createSelector(
  getLocations,
  locations =>
    locations
      .map(loc => ({
        label: loc.get('name'),
        value: loc.get('id'),
        disabled: !!loc.get('locked'),
      }))
      .toList()
);

// Adds location card data and sorts job_request + location cards by their active state
const sortJobCardGroup = jobCardGroup =>
  jobCardGroup.toList().sort((jobCardEntityA, jobCardEntityB) => {
    const activeA = jobCardEntityA.get('active');
    const activeB = jobCardEntityB.get('active');

    if (activeA && !activeB) {
      return -1;
    }
    if (!activeA && activeB) {
      return 1;
    }

    const activeAPostedAt = jobCardEntityA.get('posted_at');
    const activeBPostedAt = jobCardEntityB.get('posted_at');

    // handle case where one card is a location (denoted by lack of a posted_at value)
    if (!activeAPostedAt) {
      return 1;
    }
    if (!activeBPostedAt) {
      return -1;
    }

    return activeAPostedAt < activeBPostedAt ? 1 : -1;
  });

const sortJobCardGroups = (groupA, groupB) => {
  const groupATopJobCard = groupA.get(0);
  const groupBTopJobCard = groupB.get(0);

  // Primary sort: active state
  const groupATopJobCardActive = groupATopJobCard.get('active');
  const groupBTopJobCardActive = groupBTopJobCard.get('active');

  if (groupATopJobCardActive && !groupBTopJobCardActive) {
    return -1;
  }
  if (!groupATopJobCardActive && groupBTopJobCardActive) {
    return 1;
  }

  // Secondary sort: alphabetical
  const groupALocationName = groupATopJobCard.get('location_name');
  const groupBLocationName = groupBTopJobCard.get('location_name');

  if (groupALocationName < groupBLocationName) {
    return -1;
  }
  if (groupALocationName > groupBLocationName) {
    return 1;
  }

  return 0;
};

export const getJobCardsDataForDashboard = createSelector(
  getLocations,
  entitiesSelectors.getJobRequests,
  (locations, jobRequests) => {
    const locationJobCardGroups = locations
      .groupBy(loc => loc.get('id'))
      .map(jobCardGroup => jobCardGroup.toList());
    const jobRequestJobCardGroups = jobRequests
      .groupBy(jr => jr.get('location_id'))
      .map(jobCardGroup => jobCardGroup.toList());

    // Combine the two
    const jobCardGroups = locationJobCardGroups.map((jobCards, locationId) =>
      jobCards.concat(jobRequestJobCardGroups.get(locationId, List()))
    );

    const jobCardGroupsSorted = jobCardGroups
      .map(sortJobCardGroup)
      .sort(sortJobCardGroups);

    const firstKey = jobCardGroupsSorted.keySeq().first();

    return jobCardGroupsSorted.update(firstKey, collection =>
      collection.update(0, item => item.set('showBoostPopup', true))
    );
  }
);

export const getHiringWidgetJobRequests = createSelector(
  entitiesSelectors.getJobRequests,
  sessionSelectors.getCurrentLocationId,
  (jobRequests, currentLocationId) =>
    jobRequests
      .filter(
        jobRequest =>
          jobRequest.get('location_id') === currentLocationId &&
          (jobRequest.get('unread_applications_count') > 0 ||
            jobRequest.get('wasReposted') ||
            !jobRequest.get('active'))
      )
      .sort((a, b) => {
        const aActive = a.get('active');
        const bActive = b.get('active');
        const aReposted = a.get('wasReposted');
        const bReposted = b.get('wasReposted');
        const aApplicationsCount = a.get('unread_applications_count');
        const bApplicationsCount = b.get('unread_applications_count');

        if (aActive && !bActive) return -1;
        if (!aActive && bActive) return 1;
        if (!aReposted && bReposted && bApplicationsCount === 0) return -1;
        if (aReposted && !bReposted && aApplicationsCount === 0) return 1;

        return 0;
      })
      .slice(0, 5)
);

export const getHiringWidgetNewApplicationsTotal = createSelector(
  getHiringWidgetJobRequests,
  jobRequests =>
    jobRequests.reduce((sum, jobRequest) => {
      if (!jobRequest.get('active')) {
        return sum;
      }

      return (
        sum + parseInt(jobRequest.get('unread_applications_count'), 10) || 0
      );
    }, 0)
);

export const getHiringWidgetNewApplicationsWithInactiveTotal = createSelector(
  getHiringWidgetJobRequests,
  jobRequests =>
    jobRequests.reduce(
      (sum, jobRequest) =>
        sum + parseInt(jobRequest.get('unread_applications_count'), 10) || 0,
      0
    )
);

export const getHiringWidgetApplicationMessages =
  entitiesSelectors.createEntitiesForViewSelector(
    'applicationMessages',
    'hiringWidget'
  );

export const getIsFetchingHiringWidget = createSelector(
  getHiringDashboardIsFetching,
  getHiringDashboardWidgetIsFetchingMessages,
  interviewsSelector.getIsFetching,
  (isFetchingApplicants, isFetchingMessages, isFetchingInterviews) =>
    isFetchingApplicants || isFetchingMessages || isFetchingInterviews
);

export const getHiringWidgetAllApplicationUnreadMessages = createSelector(
  getHiringWidgetApplicationMessages,
  messages => messages.filter(message => !message.get('read'))
);

export const getHiringWidgetApplicationUnreadMessages = createSelector(
  getHiringWidgetAllApplicationUnreadMessages,
  messages =>
    messages
      .sort((a, b) => b.get('created_at_sec') - a.get('created_at_sec'))
      .slice(0, HIRING_WIDGET_MESSAGES_LIMIT)
);

export const getHiringWidgetApplicationUnreadMessagesTotal = createSelector(
  getHiringWidgetAllApplicationUnreadMessages,
  messages => messages.size
);

export const getHiringWidgetDefaultActiveTab = createSelector(
  interviewsSelector.getHiringWidgetUpcomingInterviews,
  getHiringWidgetApplicationUnreadMessagesTotal,
  getIsFetchingHiringWidget,
  (interviews, unreadMessagesTotal, isFetchingHiringWidget) => {
    if (isFetchingHiringWidget) return null;

    const hasInterviewInNext48Hours = interviews.some(
      interview =>
        moment(interview.get('start_at')).diff(moment(), 'hours') <= 48
    );

    if (hasInterviewInNext48Hours) return HIRING_WIDGET.INTERVIEWS_VIEW;
    if (unreadMessagesTotal > 0) return HIRING_WIDGET.MESSAGES_VIEW;

    return HIRING_WIDGET.APPLICANTS_VIEW;
  }
);

export const getPrefillLastMessage = createSelector(
  applicantMessengerSelectors.getCurrentApplication,
  getHiringManageApplicantsPrefillLastMessageId,
  entitiesSelectors.getApplicationMessages,
  (application, messageId, messages) => {
    const lastMessage =
      messages.find(msg => messageId === msg.get('id')) || Map();

    if (lastMessage.isEmpty() || application.isEmpty()) return lastMessage;

    return lastMessage.set(
      'body',
      formatPrefillLastMessageBody(lastMessage, application)
    );
  }
);

export const getActiveJobRequestBoosts = createSelector(
  entitiesSelectors.getAllJobRequestBoosts,
  (state, props) => props.jobRequestId,
  (allBoosts, id) =>
    allBoosts.filter(
      // eslint-disable-next-line eqeqeq
      boost => boost.get('hiring_job_request_id') == id && boost.get('active')
    )
);

export const getUnusedPartners = createSelector(
  getActiveJobRequestBoosts,
  getJobRequestBoostPartners,
  (activeBoosts, partners) =>
    partners.filter(
      partner =>
        !activeBoosts.find(
          jobRequestBoost => jobRequestBoost.get('partner_name') === partner
        )
    )
);

export const getApplicationQuestionsIsSubmitting = state =>
  state.getIn(['hiring', 'applicationQuestions', 'isSubmitting']);
