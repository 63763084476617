import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getCurrentCompanyPayrollPaymentAttemptsInfo } from 'selectors/session';

import { useHandleFailedPaymentModal } from 'features/payroll/hooks/useHandleFailedPaymentModal';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';
const { cx, cxEl } = cxHelpers('PayrollFailedPaymentBanner');

const PayrollFailedPaymentBanner = ({ payrollPaymentAttemptsInfoMap }) => {
  const payrollPaymentAttemptsInfo =
    payrollPaymentAttemptsInfoMap?.toJS() || {};

  const handleOnClick = useHandleFailedPaymentModal();

  if (payrollPaymentAttemptsInfo.show_failed_payment_banner) {
    return (
      <Box className={cx()}>
        <Box
          row
          h={44}
          tcenter
          vcenter
          hcenter
          bg="red"
          className={cxEl('text')}
        >
          <Text
            white
            i18n="payroll.failed_payment.dashboard.title"
            style={{
              fontWeight: 'bold',
              paddingRight: 20,
            }}
          />
          &nbsp;
          <Text
            white
            i18n="payroll.failed_payment.dashboard.text"
            style={{
              paddingRight: 20,
            }}
          />
          &nbsp;
          <Button
            theme="link-white"
            onClick={handleOnClick}
            className={cxEl('button')}
            i18n={`payroll.failed_payment.dashboard.cta.${payrollPaymentAttemptsInfo.button_text}`}
          />
        </Box>
      </Box>
    );
  }

  return null;
};

PayrollFailedPaymentBanner.propTypes = {
  payrollPaymentAttemptsInfoMap: PropTypes.object.isRequired,
};

export default connect(
  state => ({
    payrollPaymentAttemptsInfoMap:
      getCurrentCompanyPayrollPaymentAttemptsInfo(state),
  }),
  {}
)(PayrollFailedPaymentBanner);
