import { IconName } from 'fe-design-base/atoms/Icon';
import { blue, green, orange, purple, red } from 'fe-design-base/styles/colors';

import { AlertVariant, Variant } from './Alert/types';

interface ColorScale {
  100: string;
  300: string;
  500: string;
}

export const colorsMap: { [K in Variant]: ColorScale } = {
  info: blue,
  success: green,
  warning: orange,
  error: red,
  illustrated: purple,
};

export const iconNamesMap: {
  [K in Exclude<AlertVariant, 'plain'>]: IconName;
} = {
  info: 'Info',
  success: 'Success',
  warning: 'Warning',
  error: 'Critical',
};

export const getBoxWrapperNote = (name: string) =>
  `${name} components are set up to allow Box props to be passed to the built-in Box wrapper`;
