import * as routes from 'api';

import { createAsyncGetAction, withAlerts } from 'util/redux';

export const actionTypes = {
  FETCH_MANAGERS_REQUEST: 'BILLING/FETCH_MANAGERS_REQUEST',
  FETCH_MANAGERS_SUCCESS: 'BILLING/FETCH_MANAGERS_SUCCESS',
  FETCH_MANAGERS_FAILURE: 'BILLING/FETCH_MANAGERS_FAILURE',
};

export const fetchManagers = () =>
  withAlerts(
    createAsyncGetAction(routes.managersRoute(), [
      actionTypes.FETCH_MANAGERS_REQUEST,
      actionTypes.FETCH_MANAGERS_SUCCESS,
      actionTypes.FETCH_MANAGERS_FAILURE,
    ])
  );
