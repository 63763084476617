import Chip from '@mui/material/Chip';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Icon from 'fe-design-base/atoms/Icon';

export const PILL_VARIANTS = [
  'mono',
  'primary',
  'highlight',
  'info',
  'success',
  'warning',
  'error',
] as const;

type PillVariant = (typeof PILL_VARIANTS)[number];

export interface PillProps {
  variant?: PillVariant;
  children: string;
  icon?: string;
}

const Pill = ({
  variant = 'primary',
  icon,
  children,
  cx,
}: PillProps & CxProps) => (
  <Chip
    title={children}
    label={children}
    className={cx({ [variant]: true })}
    icon={icon ? <Icon iconName={icon} size="xsmall" /> : undefined}
  />
);

export default withCx<PillProps>('FDBPill')(Pill);
