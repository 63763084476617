import { useCallback, useMemo } from 'react';

import { MANAGE_PLANS_PATH } from 'features/managePlan/constants';

const useGoToManagePlanPage = ({
  loadFullPage,
  pageUrl,
  onClick,
  planName,
  focusedFeature,
  linkTo,
}: {
  loadFullPage?: boolean;
  pageUrl?: string;
  onClick?: () => void;
  planName?: string;
  focusedFeature?: string;
  linkTo?: string;
}) => {
  const extraProps = useMemo(() => {
    const result = {
      href: '',
    };

    if (loadFullPage) result.href = pageUrl || MANAGE_PLANS_PATH;

    return result;
  }, [loadFullPage, pageUrl]);

  const handleOnClick = useCallback(() => {
    onClick?.();

    if (extraProps.href) return;

    const paywallLinkUrl =
      Boolean(planName && focusedFeature) &&
      MANAGE_PLANS_PATH +
        `?view=focus&plan=${planName}&origin=paywall&feature_key=${focusedFeature}`;

    const linkUrl = paywallLinkUrl || linkTo || MANAGE_PLANS_PATH;

    window.Homebase.RailsReactBridge.navigateToReactView(linkUrl);
  }, [onClick, extraProps.href, planName, focusedFeature, linkTo]);

  return { extraProps, handleOnClick };
};

export default useGoToManagePlanPage;
