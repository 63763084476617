import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { SLICE_NAME } from './constants';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: fromJS({
    addTeamDrawerIsOpen: false,
    newRoleModalIsOpen: false,
    taxEntryDrawerIsOpen: false,
    taxEntryUserId: null,
    toastIsOpen: false,
    toastProps: {},
    successModalIsOpen: false,
    successModalProps: {},
  }),
  reducers: {
    openAddTeamDrawer: (
      state,
      {
        payload: {
          calledFrom = '',
          onCloseDrawerCallback = null,
          currentTeamMember = {},
        } = {},
      }
    ) =>
      state
        .set('addTeamDrawerIsOpen', true)
        .set('calledFrom', calledFrom)
        .set('onCloseDrawerCallback', onCloseDrawerCallback)
        .set('currentTeamMember', currentTeamMember),
    closeAddTeamDrawer: state => state.set('addTeamDrawerIsOpen', false),
    openTaxEntryDrawer: (state, { payload: taxEntryUserId }) =>
      state
        .set('taxEntryDrawerIsOpen', true)
        .set('taxEntryUserId', taxEntryUserId),
    closeTaxEntryDrawer: state =>
      state.set('taxEntryDrawerIsOpen', false).set('taxEntryUserId', null),
    openBulkActionDrawer: state => state.set('bulkActionDrawerIsOpen', true),
    closeBulkActionDrawer: state => state.set('bulkActionDrawerIsOpen', false),
    openAddNewRoleModal: state => state.set('newRoleModalIsOpen', true),
    closeAddNewRoleModal: state => state.set('newRoleModalIsOpen', false),
    openToast: (state, { payload: toastProps }) =>
      state.set('toastProps', toastProps).set('toastIsOpen', true),
    closeToast: state => state.set('toastIsOpen', false).set('toastProps', {}),
    openSuccessModal: (state, { payload: successModalProps }) =>
      state
        .set('successModalProps', successModalProps)
        .set('successModalIsOpen', true),
    closeSuccessModal: state =>
      state.set('successModalIsOpen', false).set('successModalProps', {}),
  },
});

const { actions, reducer } = slice;

export const {
  openAddTeamDrawer,
  closeAddTeamDrawer,
  openBulkActionDrawer,
  closeBulkActionDrawer,
  openAddNewRoleModal,
  closeAddNewRoleModal,
  openToast,
  closeToast,
  openSuccessModal,
  closeSuccessModal,
  openTaxEntryDrawer,
  closeTaxEntryDrawer,
} = actions;

export default reducer;
