import { reportsRoute } from 'api';

import { REQUEST_KEYS } from 'features/reports/constants';

import { isValidDate } from 'util/dateTime';
import { createAsyncGetAction } from 'util/redux';
import { addQuery } from 'util/router';
const requestActionTypes = id =>
  ['REQUEST', 'SUCCESS', 'FAILURE'].map(state => ({
    type: `${id}/${state}`,
    meta: { requestId: id },
  }));

export const fetchReportsData = () =>
  createAsyncGetAction(
    reportsRoute(),
    requestActionTypes(REQUEST_KEYS.reportsData)
  );

export const actionTypes = {
  FETCH_REPORTS_DATA_REQUEST: `${REQUEST_KEYS.reportsData}/REQUEST`,
  FETCH_REPORTS_DATA_SUCCESS: `${REQUEST_KEYS.reportsData}/SUCCESS`,
  FETCH_REPORTS_DATA_FAILURE: `${REQUEST_KEYS.reportsData}/FAILURE`,

  UPDATE_SEARCH_PERIOD: 'REPORTS/UPDATE_SEARCH_PERIOD',
};

export const updateSearchPeriod = payload => ({
  type: actionTypes.UPDATE_SEARCH_PERIOD,
  payload,
});

export const updateReportsDateRange = payload => dispatch => {
  if (!isValidDate(payload.startDate) || !isValidDate(payload.endDate)) {
    return;
  }
  dispatch(updateSearchPeriod(payload));
  addQuery({ startDate: payload.startDate, endDate: payload.endDate });
};
