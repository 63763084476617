import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgBoost = ({
  alt = 'Boost Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="m7.34 18.042-1.448-1.448a1.466 1.466 0 0 1-.36-1.51c.14-.417.327-.96.552-1.584H1.125a1.13 1.13 0 0 1-.98-.567 1.12 1.12 0 0 1 .01-1.13l2.46-4.148A3.374 3.374 0 0 1 5.518 6h3.858c.113-.188.225-.361.338-.53C13.552-.192 19.27-.38 22.683.248c.544.099.965.525 1.069 1.069.628 3.417.436 9.131-5.222 12.97a10.41 10.41 0 0 1-.53.338v3.858c0 1.19-.628 2.296-1.655 2.901l-4.148 2.461a1.125 1.125 0 0 1-1.697-.97V17.85c-.66.23-1.237.417-1.673.558a1.478 1.478 0 0 1-1.491-.366h.005ZM18 7.875a1.874 1.874 0 1 0 .001-3.75A1.874 1.874 0 0 0 18 7.876Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBoost;
