import { fromJS } from 'immutable';

import { actionTypes } from 'actions/hiring/interviews';

const INITIAL_STATE = fromJS({
  interviews: [],
  isFetching: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DATA_REQUEST:
      return state.merge({ isFetching: true });

    case actionTypes.FETCH_DATA_FAILURE:
      return state.merge({ isFetching: false });

    case actionTypes.FETCH_DATA_SUCCESS:
      return state.merge({
        interviews: fromJS(action.payload),
        isFetching: false,
      });

    case actionTypes.CANCEL_INTERVIEW_REQUEST:
      return state.merge({ isFetching: true });

    case actionTypes.CANCEL_INTERVIEW_SUCCESS:
      return state.merge({
        interviews: state
          .get('interviews')
          .filter(interview => interview.get('id') !== action.meta.id),
        isFetching: false,
      });

    case actionTypes.CANCEL_INTERVIEW_FAILURE:
      return state.merge({ isFetching: false });

    default:
      return state;
  }
};
