import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

import { showModal } from 'actions/modals';

import { MODULE_NAME } from 'features/biller/constants';
import {
  BILLER_ADMIN_CHANGE_TIER_MODAL,
  BILLER_CHANGE_TIER_MODAL,
} from 'features/modals/constants';

import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

export const fetchBillerLocations = createAsyncThunk(
  `${MODULE_NAME}/fetchBillerLocations`,
  (_, { rejectWithValue }) =>
    fetchJSON('/biller/locations.json').catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const fetchAdminBillerLocations = createAsyncThunk(
  `${MODULE_NAME}/fetchAdminBillerLocations`,
  (companyId, { rejectWithValue }) =>
    fetchJSON(`/admin/companies/${companyId}/biller_locations.json`).catch(
      err => err.response.json().then(body => rejectWithValue(body))
    )
);

export const fetchAdminTiers = createAsyncThunk(
  `${MODULE_NAME}/fetchAdminTiers`,
  companyId => fetchJSON(`/admin/companies/${companyId}/biller_tiers.json`)
);

export const fetchAdminProducts = createAsyncThunk(
  `${MODULE_NAME}/fetchAdminProducts`,
  ({ companyId, locationId }) =>
    fetchJSON(
      `/admin/companies/${companyId}/tier_products.json?location_id=${locationId}`
    )
);

export const showChangeTierModal = ({
  tier,
  billingCycle,
  biller,
  newLocations,
  payrollLocationAdded = false,
}) =>
  showModal(BILLER_CHANGE_TIER_MODAL, {
    deprecatedModal: true,
    fullScreen: true,
    hideCloseIcon: true,
    tier,
    billingCycle,
    biller,
    newLocations,
    payrollLocationAdded,
    onDismiss: () => {
      trackUxEvent({
        productArea: PRODUCT_AREAS.BILLING_PAGE,
        eventCategory: EVENT_CATEGORIES.BILLING_PAGE,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
        properties: { escape_key: true },
      });
    },
  });

export const showAdminChangeTierModal = ({ biller, location, companyId }) =>
  showModal(BILLER_ADMIN_CHANGE_TIER_MODAL, {
    deprecatedModal: true,
    fullScreen: true,
    biller,
    location,
    companyId,
  });

export const tierUpdated = createAction(`${MODULE_NAME}/tierUpdated`);
