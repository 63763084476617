export const PRODUCT_AREA = 'Navigation';

export const EVENT_CATEGORIES = {
  DASHBOARD: 'Dashboard',
  LEFT_NAVIGATION: 'Left Navigation',
};

export const EVENT_ACTIONS = {
  EXPAND_CLICKED: 'Expand Clicked',
  COLLAPSE_CLICKED: 'Collapse Clicked',
};
