// It's not obvious due to the @withUpgradeModal decorator,
// but this component only requires the "feature" prop
// when gating for the current location.

import './UpgradeWrapper.scss';

import React, { cloneElement, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import * as sessionSelectors from 'selectors/session';

import Box from 'components/Box';

import cxHelpers from 'util/className';
import { heapAddEventProps, trackEvent } from 'util/tracking';

import withUpgradeModal from '../withUpgradeModal';

@withUpgradeModal
@connect((state, { feature, report }) => ({
  currentLocationCanAccess: sessionSelectors.getHasFeature(state, {
    feature,
  }),
  currentLocationCanReportAccess: sessionSelectors.getHasReport(state, {
    report,
  }),
}))
@cxHelpers('UpgradeWrapper')
export default class UpgradeWrapper extends PureComponent {
  static propTypes = {
    location: PropTypes.instanceOf(Map),
    onShowUpgradeModal: PropTypes.func,
    feature: PropTypes.string.isRequired,
    report: PropTypes.string,
    // a version of the paywall which we will show to user
    // (a locale key from js.yml and a css class from feature_popup.scss)
    // by default it's eq to `feature` prop
    paywallVersion: PropTypes.string,
    currentLocationCanAccess: PropTypes.bool,
    currentLocationCanReportAccess: PropTypes.bool,
    onClick: PropTypes.func,
    heapPaywallTrigger: PropTypes.string,
    locationProperty: PropTypes.string,
    serverKey: PropTypes.string,
    upgradeOffer: PropTypes.bool,
    showAsActive: PropTypes.bool,
    disabled: PropTypes.bool,
    focusedFeature: PropTypes.string,
  };

  handleShowModal = e => {
    trackEvent('Clicked Upgrade Barrier', { page: window.location.href });
    this.props.onShowUpgradeModal?.(e.target, {
      focusedFeature: this.props.focusedFeature,
    });
  };

  handleClick = e => {
    if (this.props.disabled) {
      return;
    }

    if (this.props.heapPaywallTrigger) {
      heapAddEventProps({ paywall_trigger: this.props.heapPaywallTrigger });
    }

    this.handleShowModal(e);
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const {
      children,
      location,
      feature,
      currentLocationCanAccess,
      currentLocationCanReportAccess,
      upgradeOffer,
      report,
      showAsActive,
      disabled,
      ...boxProps
    } = omit(
      this.props,
      'paywallVersion',
      'dispatch',
      'onShowUpgradeModal',
      'onClick',
      'heapPaywallTrigger',
      'serverKey',
      'locationProperty'
    );

    let canAccess;

    if (report) {
      canAccess = currentLocationCanReportAccess;
    } else {
      canAccess = location
        ? location.getIn(['feature_access', feature])
        : currentLocationCanAccess;
    }
    canAccess = disabled || canAccess;

    const childClassName = this.cxEl(
      'child',
      upgradeOffer || showAsActive ? null : { disabled: !canAccess },
      children.props.className
    );

    return (
      <Box {...boxProps} className={this.cx()}>
        {cloneElement(children, { className: childClassName })}
        {canAccess ? null : (
          <a className={this.cxEl('barrier')} onClick={this.handleClick} />
        )}
      </Box>
    );
  }
}
