import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import Dialog from 'fe-design-base/organisms/Dialog';

import { getCurrentLocationLaunchWeekSettings } from 'selectors/session';

import LaunchWeekShareInstagramDialog from 'features/Growth/LaunchWeek/LaunchWeekShareInstagramDialog';
import LaunchWeekShareWithTeamDialog from 'features/Growth/LaunchWeek/LaunchWeekShareWithTeamDialog';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('LaunchWeekSharingOptionsDialog');
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';

const LaunchWeekSharingOptionsDialog = ({
  i18n,
  launchWeekSettings,
  onClose,
  open,
  trackUx,
}) => {
  const [isShareWithTeamOpen, setIsShareWithTeamOpen] = useState(false);
  const [isShareInstagramOpen, setIsShareInstagramOpen] = useState(false);

  const handleTrackUx = useCallback(
    linkText => {
      trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK, {
        link_text: linkText,
      });
    },
    [trackUx]
  );

  const handleOpenShareWithTeam = useCallback(() => {
    handleTrackUx('message_your_team');
    setIsShareWithTeamOpen(true);
  }, [handleTrackUx]);

  const handleCloseShareWithTeam = useCallback(() => {
    setIsShareWithTeamOpen(false);
    onClose();
  }, [onClose]);

  const handleOnClickDownload = useCallback(() => {
    handleTrackUx('download_an_image');
    onClose();
  }, [handleTrackUx, onClose]);

  const handleOpenShareInstagram = useCallback(() => {
    handleTrackUx('instagram');
    setIsShareInstagramOpen(true);
  }, [handleTrackUx]);

  const handleCloseShareInstagram = useCallback(() => {
    setIsShareInstagramOpen(false);
    onClose();
  }, [onClose]);

  const handleShareOnFacebook = useCallback(
    ({ trackFacebookLinkClicked = true }) => {
      handleCloseShareInstagram();

      if (trackFacebookLinkClicked) handleTrackUx('facebook');

      const awardFacebooPrefilledMessageUrl = launchWeekSettings.get(
        'award_fb_prefilled_message_url'
      );
      const facebookMessage = toI18n(
        'growth.launch_week.sharing.dialog.share_facebook.default_message',
        { props: { awardFacebooPrefilledMessageUrl } }
      );

      window.open(
        `http://www.facebook.com/sharer/sharer.php?u=${launchWeekSettings.get(
          'award_image_public_url'
        )}&hashtag=${facebookMessage}`,
        '_blank'
      );
    },
    [handleCloseShareInstagram, handleTrackUx, launchWeekSettings]
  );

  const awardGoogleDriveUrl = launchWeekSettings.get('award_google_drive_url');

  return (
    <>
      <Dialog
        className={cx()}
        disableBackdropClick
        noPadding
        onClose={onClose}
        open={open}
      >
        {() => (
          <Box className={cx()} pl={24} pr={24} pt={16} pb={16} w={580}>
            <Box>
              <Text variant="heading2" color="mono900">
                {i18n.title}
              </Text>
            </Box>
            <Box column>
              <Box mt={24} hleft vcenter>
                <Text variant="heading4" color="mono900">
                  {i18n.tell_customers}
                </Text>
              </Box>
              <Box column>
                <Box
                  p={20}
                  mt={16}
                  hleft
                  vcenter
                  bw={1}
                  bradiusm
                  bcolor="mono300"
                  spacebetween
                  onClick={handleOpenShareInstagram}
                  pointer
                >
                  <Box row vcenter>
                    <Icon
                      iconName="MonoInstagram"
                      size="xlarge"
                      color="mono900"
                      mr={16}
                    />
                    <Text variant="heading4" color="mono900">
                      Instagram
                    </Text>
                  </Box>
                  <Box>
                    <Icon
                      iconName="ChevronRight"
                      size="small"
                      color="purple500"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                p={20}
                mt={16}
                hleft
                vcenter
                bw={1}
                bradiusm
                bcolor="mono300"
                spacebetween
                onClick={handleShareOnFacebook}
                pointer
              >
                <Box row vcenter>
                  <Icon
                    iconName="MonoFacebook"
                    size="xlarge"
                    color="mono900"
                    mr={16}
                  />
                  <Text variant="heading4" color="mono900">
                    Facebook
                  </Text>
                </Box>
                <Box>
                  <Icon
                    iconName="ExternalLink"
                    size="small"
                    color="purple500"
                  />
                </Box>
              </Box>
              <Box mt={32} hleft vcenter>
                <Text variant="heading4" color="mono900">
                  {i18n.tell_your_team}
                </Text>
              </Box>
              <Box
                row
                p={20}
                mt={16}
                hleft
                vcenter
                bw={1}
                bradiusm
                bcolor="mono300"
                spacebetween
                onClick={handleOpenShareWithTeam}
                pointer
              >
                <Box row vcenter>
                  <Icon iconName="Chat" size="xlarge" color="mono900" mr={16} />
                  <Box>
                    <Text variant="heading4" color="mono900">
                      {i18n.message_your_team}
                    </Text>
                  </Box>
                </Box>
              </Box>
              <Box mt={32} hleft vcenter>
                <Text variant="bodySm" color="mono900">
                  {i18n.print}
                </Text>
                &nbsp;
                <Link
                  href={awardGoogleDriveUrl}
                  onClick={handleOnClickDownload}
                  target="_blank"
                  size="small"
                  endIcon="ExternalLink"
                >
                  {i18n.download_link}
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Dialog>
      <LaunchWeekShareWithTeamDialog
        i18n={i18n}
        open={isShareWithTeamOpen}
        onClose={handleCloseShareWithTeam}
        trackUx={trackUx}
      />
      <LaunchWeekShareInstagramDialog
        i18n={i18n}
        open={isShareInstagramOpen}
        onClose={handleCloseShareInstagram}
        onShareOnFacebook={handleShareOnFacebook}
        trackUx={trackUx}
      />
    </>
  );
};

export default connect(state => ({
  launchWeekSettings: getCurrentLocationLaunchWeekSettings(state),
}))(LaunchWeekSharingOptionsDialog);
