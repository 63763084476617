import { Map } from 'immutable';

import { actionTypes } from 'actions/userWebTimeclock';

import * as constants from 'features/timeClock/modals/UserWebTimeclockModal/constants';

const INITIAL_STATE = Map({
  status: null,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_STATUS_SUCCESS: {
      let status = constants.CLOCKED_IN_BREAK;
      if (action.payload.type === constants.SHIFTS) {
        status = constants.CLOCKED_OUT;
      } else if (action.payload.type === constants.TIMECARDS) {
        status = constants.CLOCKED_IN;
      }
      return state.merge({ status });
    }
    case actionTypes.CLOCK_IN_SUCCESS:
    case actionTypes.CLOCK_IN_UNSCHEDULED_SUCCESS:
    case actionTypes.CLOCK_OUT_BREAK_SUCCESS:
      return state.merge({
        status: constants.CLOCKED_IN,
      });
    case actionTypes.CLOCK_OUT_SUCCESS:
      return state.merge({
        status: constants.CLOCKED_OUT,
      });
    case actionTypes.CLOCK_IN_BREAK_SUCCESS:
      return state.merge({
        status: constants.CLOCKED_IN_BREAK,
      });
    default:
      return state;
  }
};
