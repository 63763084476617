import React, { useCallback, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import * as routes from 'api';
import { deleteJSON, postJSON, putJSON } from 'api/fetch';
import PropTypes from 'prop-types';

import { showConfirmModal as getShowConfirmModal } from 'actions/modals';

import { getIsGodModing } from 'selectors/session';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';
import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';

import EditableSection from '../../EditableSection';

import { INITIAL_VALUES } from './constants.js';
import PostTaxDeductionFormFields from './PostTaxDeductionFormFields';
import { handleValidate as handleValidateFN } from './utils';
const { cx } = cxHelpers('PostTaxDeductionForm');

export const PostTaxDeductionForm = ({
  user,
  isGmod,
  onCancel,
  isEditing = false,
  updateUserInfo,
  onSaveCompleted,
  deduction,
  onDeleteSuccess,
  showConfirmModal,
  showFormWithSteps,
}) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const handleOnSave = useCallback(
    values => {
      let ptdAPICall;
      let route;
      let i18nErrorMessage;

      if (values.post_tax_deduction_id) {
        ptdAPICall = putJSON;
        route = routes.updatePTDRoute;
        i18nErrorMessage =
          'team.employee_profile.tax_deductions.validations.update_ptd_error';
      } else {
        ptdAPICall = postJSON;
        route = routes.createPTDRoute;
        i18nErrorMessage =
          'team.employee_profile.tax_deductions.validations.create_ptd_error';
      }

      return ptdAPICall(route(user.get('id')), values).catch(error => {
        error.response
          .json()
          .then(error_response => {
            flashNotice.error(error_response.errors.join(', '));
          })
          .catch(() => {
            flashNotice.error(toI18n(i18nErrorMessage));
          });
      });
    },
    [user]
  );

  const handleValidate = useCallback(handleValidateFN, []);
  const handleBenefitRemove = useCallback(() => {
    showConfirmModal({
      title: toI18n(
        'team.employee_profile.tax_deductions.ptd_delete_confirmation_modal_title'
      ),
      message: toI18n(
        'team.employee_profile.tax_deductions.delete_confirmation_modal.message',
        {
          props: { name: user.get('first_name') },
        }
      ),
      onConfirm: () => {
        setIsSaveButtonDisabled(true);
        deleteJSON(routes.deletePTDRoute(user.get('id')), {
          post_tax_deduction_id: deduction.get('post_tax_deduction_id'),
        })
          .then(response => {
            updateUserInfo(response);
            onSaveCompleted(response);
          })
          .then(onDeleteSuccess)
          .catch(() => {
            flashNotice.error(
              toI18n(
                'team.employee_profile.tax_deductions.validations.delete_benefit_error'
              )
            );
          })
          .finally(() => setIsSaveButtonDisabled(false));
      },
      altType: true,
      submitText: toI18n(
        'team.employee_profile.tax_deductions.delete_confirmation_modal.submit_text'
      ),
      cancelText: toI18n(
        'team.employee_profile.tax_deductions.delete_confirmation_modal.cancel_text'
      ),
    });
  }, [
    deduction,
    onDeleteSuccess,
    onSaveCompleted,
    showConfirmModal,
    updateUserInfo,
    user,
  ]);

  return (
    <Box className={cx()}>
      <EditableSection
        initEditMode
        onCancel={onCancel}
        onSave={handleOnSave}
        initialValues={deduction ? deduction.toObject() : INITIAL_VALUES}
        onValidate={handleValidate}
        updateUserInfo={updateUserInfo}
        onSaveCompleted={onSaveCompleted}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {({ editMode, values, setFieldValue }) =>
          editMode ? (
            <PostTaxDeductionFormFields
              isEditing={isEditing}
              values={values}
              isGmod={!!isGmod}
              onClickRemove={handleBenefitRemove}
              isSaveButtonDisabled={isSaveButtonDisabled}
              setFieldValue={setFieldValue}
              user={user}
              showFormWithSteps={showFormWithSteps}
            />
          ) : null
        }
      </EditableSection>
    </Box>
  );
};

PostTaxDeductionForm.propTypes = {
  onCancel: PropTypes.func,
  isEditing: PropTypes.bool,
  user: ImmutablePropTypes.map.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  onSaveCompleted: PropTypes.func.isRequired,
  onDeleteSuccess: PropTypes.func,
  deduction: PropTypes.object,
  showConfirmModal: PropTypes.func,
  showFormWithSteps: PropTypes.bool,
};
export default connect(state => ({ isGmod: getIsGodModing(state) }), {
  showConfirmModal: getShowConfirmModal,
})(PostTaxDeductionForm);
