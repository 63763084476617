import './BasicTextField.scss';

import React, { useCallback, useMemo, useState } from 'react';
import MUITextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';

import { DEFAULT_STYLES, THEME_OPTIONS, THEME_STYLES } from './themes';

const { cx } = cxHelpers('BasicTextField');
const BasicTextField = ({
  value,
  theme,
  centered,
  disabled,
  errorBorder,
  errorText,
  successText,
  onFocus,
  onBlur,
  ...otherProps
}) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = useCallback(
    e => {
      if (onFocus) onFocus(e);
      setFocused(true);
    },
    [onFocus]
  );
  const handleBlur = useCallback(
    e => {
      // eslint-disable-next-line no-unused-expressions
      if (onBlur) onBlur(e);
      setFocused(false);
    },
    [onBlur]
  );
  const helperText =
    (errorText && <span className="js-inline-error">{errorText}</span>) ||
    successText;

  const errorProps = useMemo(() => {
    if (!errorText) return {};

    return {
      FormHelperTextProps: { error: true },
      error: true,
    };
  }, [errorText]);

  const styles = theme ? THEME_STYLES[theme] : DEFAULT_STYLES;

  styles.InputLabelProps.shrink = focused || !!value;

  if (focused) {
    styles.InputLabelProps.focused = true;
  }

  return (
    <MUITextField
      value={value}
      className={cx({
        centered,
        [theme]: !!theme,
        disabled,
        errorborder: errorBorder,
      })}
      helperText={helperText}
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...styles}
      {...errorProps}
      {...otherProps}
    />
  );
};

BasicTextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  theme: PropTypes.oneOf(THEME_OPTIONS),
  label: PropTypes.string,
  errorText: PropTypes.string,
  successText: PropTypes.string,
  centered: PropTypes.bool,
  disabled: PropTypes.bool,
  errorBorder: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default BasicTextField;
