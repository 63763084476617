import baseColors from 'fe-design-base/styles/colors';
import { rgba } from 'polished';

import { Overrides } from '../../types';

export const dialogOverrides: Overrides = {
  MuiDialog: {
    styleOverrides: {
      root: {
        '&.FDBDialog': {
          '& .MuiBackdrop-root': {
            backgroundColor: rgba(baseColors.mono900, 0.6),
          },

          '& .MuiPaper-root': {
            borderRadius: '16px',
          },
        },
      },
      paper: {
        borderRadius: '16px',
        border: 'none',
        width: 500,
      },
      paperFullScreen: {
        width: '100%',
        borderRadius: 0,
      },
    },
  },
};
