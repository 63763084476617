import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { actionTypes as sessionActionTypes } from 'actions/session';

import { actionTypes, enrollEntityInExperiment } from './actions';
import { SLICE_NAME } from './constants';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: fromJS({
    submitPending: false,
    serverErrors: null,
    viewLogic: {},
    experimentReload: {},
    apiStatuses: {},
  }),
  reducers: {
    setViewLogic: (state, { payload }) => state.mergeIn(['viewLogic'], payload),
  },
  extraReducers: {
    [enrollEntityInExperiment.pending]: state =>
      state.merge({
        submitPending: true,
        serverErrors: null,
      }),
    [enrollEntityInExperiment.fulfilled]: (state, { payload }) => {
      const newState = state.merge({
        submitPending: false,
      });
      return newState.mergeIn(['viewLogic'], payload);
    },
    [enrollEntityInExperiment.rejected]: (state, { payload }) => {
      if (payload.ineligible) {
        const newState = state.merge({
          submitPending: false,
        });
        return newState.mergeIn(['viewLogic'], payload.values);
      }
      return state.merge({
        submitPending: false,
        serverErrors: payload,
      });
    },
    [actionTypes.EXPERIMENT_RELOAD]: (state, { payload }) =>
      state.mergeIn(['experimentReload'], payload),
    [sessionActionTypes.UPDATE_SESSION]: (state, { payload }) =>
      state.mergeIn(['apiStatuses'], payload.api_experiment_status),
  },
});

export const { actions, reducer } = slice;
