import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import PropTypes from 'prop-types';

import * as modalsActions from 'actions/modals';
import * as sessionActions from 'actions/session';

import * as hiringSelectors from 'selectors/hiring';

import {
  HIRING_FEEDBACK_MODAL_FEATURE_NAME,
  HIRING_FEEDBACK_MODAL_FIRST_TIME_POPUP_KEY,
  HIRING_FEEDBACK_MODAL_TYPE,
} from 'features/hiring/constants';

import FeatureFeedbackModal from 'components/modals/FeatureFeedbackModal';

import cxHelpers from 'util/className';

@withLastLocation
@connect(
  (state, props) => ({
    isOpen: hiringSelectors.getShowHiringFeedbackModal(state, props),
  }),
  {
    disableFirstTimePopup: sessionActions.disableFirstTimePopup,
    showModal: modalsActions.showModal,
    hideModal: modalsActions.hideModal,
  }
)
@cxHelpers('HiringFeedbackModal')
export default class HiringFeedbackModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    disableFirstTimePopup: PropTypes.func,
    showModal: PropTypes.func,
    hideModal: PropTypes.func,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      this.props.showModal(HIRING_FEEDBACK_MODAL_TYPE, {
        deprecatedModal: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.showModal(HIRING_FEEDBACK_MODAL_TYPE, {
        deprecatedModal: true,
      });
    }
  }

  onRequestClose = () => {
    this.props.disableFirstTimePopup(
      HIRING_FEEDBACK_MODAL_FIRST_TIME_POPUP_KEY
    );
    this.props.hideModal();
  };

  render() {
    return (
      <FeatureFeedbackModal
        showCloseIcon
        modalType={HIRING_FEEDBACK_MODAL_TYPE}
        featureName={HIRING_FEEDBACK_MODAL_FEATURE_NAME}
        showRatingsButtons={false}
        i18nTitle="hiring.feedback_modal.title"
        i18nDesc="hiring.feedback_modal.desc"
        onRequestClose={this.onRequestClose}
      />
    );
  }
}
