import { postJSON } from 'api/fetch';

import { omit } from 'util/objectMethods';

import { CREATE_EVENT_PROPERTY_EXCLUDE_LIST } from './constants';

const INGORE_STATUSES = [0, 401, 422, 429];

function sendEvent(payload) {
  const filteredPayload = omit(payload, CREATE_EVENT_PROPERTY_EXCLUDE_LIST);

  return postJSON('/events/create_event', filteredPayload).catch(e => {
    const status = e?.response?.status;

    // Authentication, network failures, and rate limiting errors can
    // be expected, so ignoring rather than generating sentry noise.
    if (INGORE_STATUSES.includes(status)) return;

    // In safari, tracking may get canceled if page is changing at the same
    // time.  Just ignore this for now.
    if (e.toString() === 'TypeError: Load failed') return;

    throw e;
  });
}

export default {
  sendEvent,
};
