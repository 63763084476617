import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgChevronDown = ({
  alt = 'Chevron Down Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M23.638 7.902 12.79 17.72a1.095 1.095 0 0 1-1.461 0L.36 7.902c-.448-.464-.484-1.09-.081-1.54a1.094 1.094 0 0 1 1.542-.083L12 15.443l10.179-9.164c.45-.4 1.138-.365 1.542.081.4.451.367 1.078-.083 1.542Z"
    />
  </svg>
);
export default SvgChevronDown;
