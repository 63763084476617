import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorGoogle = ({
  alt = 'Google Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M28.875 16.312c0-.906-.072-1.818-.225-2.71H16.322v5.136h7.06a6.181 6.181 0 0 1-2.613 4.052v3.332h4.212c2.473-2.323 3.894-5.753 3.894-9.81Z"
      fill="#4285F4"
    />
    <path
      d="M16.322 29.341c3.525 0 6.497-1.18 8.663-3.219l-4.212-3.332c-1.171.814-2.684 1.274-4.447 1.274-3.41 0-6.3-2.347-7.338-5.503H4.642v3.435c2.219 4.503 6.738 7.345 11.68 7.345Z"
      fill="#34A853"
    />
    <path
      d="M8.983 18.561a8.141 8.141 0 0 1 0-5.106v-3.434H4.642a13.59 13.59 0 0 0 0 11.975l4.341-3.435Z"
      fill="#FBBC04"
    />
    <path
      d="M16.322 7.948a7.026 7.026 0 0 1 5.013 2l3.732-3.808a12.425 12.425 0 0 0-8.745-3.47c-4.942 0-9.462 2.843-11.68 7.35l4.341 3.435c1.033-3.16 3.929-5.507 7.339-5.507Z"
      fill="#EA4335"
    />
  </svg>
);
export default SvgColorGoogle;
