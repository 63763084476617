import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { createFreeTrial } from './actions';
import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  submitPending: false,
  error: {},
  success: false,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: {
    [createFreeTrial.pending]: state =>
      state.set('submitPending', true).set('error', fromJS({})),
    [createFreeTrial.fulfilled]: state =>
      state.mergeDeep({
        submitPending: false,
        success: true,
        error: {},
      }),
    [createFreeTrial.rejected]: (state, { payload }) =>
      state.set('submitPending', false).set('error', fromJS(payload)),
  },
});

export const { actions, reducer } = slice;
