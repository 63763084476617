export const MODULE_NAME = 'biller';
export const SLICE_NAME = 'main';

export const TIER_NAMES = {
  BASIC: 'basic',
  ESSENTIALS: 'essentials',
  PLUS: 'plus',
  ENTERPRISE: 'enterprise',
};

export const OLD_PRICING_TIER_NAMES = {
  BASIC: 'basic (Old Prices)',
  ESSENTIALS: 'essentials (Old Prices)',
  PLUS: 'plus (Old Prices)',
  ENTERPRISE: 'enterprise (Old Prices)',
};

export const BILLER_BUTTON_TEXT = {
  UPGRADE_NOW: 'Upgrade Now',
  COUPON_APPLY: 'Coupon Apply',
  UPGRADE: 'Upgrade',
};

export const FORM_TEXTS = {
  CHOOSE_YOUR_BILLING_CYCLE: 'choose your billing cycle',
};

export const FIELDS = {
  SUPPORT_CODE: 'support_code',
  CARDHOLDER_NAME: 'cardholder_name',
  CARD_NUMBER: 'card_number',
  EXP_DATE: 'exp_date',
  ZIP_CODE: 'zip_code',
  CVV: 'cvv',
};

export const MODAL_SELECTION = {
  YES: 'yes',
  NO: 'no',
};

export const MODAL_TYPES = {
  DOWNGRADE: 'downgrade',
};

export const KINESIS_EVENT_TYPE = 'cc_modal_bt';

export const DISABLED_ADD_CARD_LEARN_MORE_URL =
  'https://support.joinhomebase.com/hc/en-us/articles/8353209619981';

export const BILLING_CYCLES = {
  monthly: 'monthly',
  annual: 'annual',
};

export const CLICK_SUCCESS = 'click_success';

export const DEFAULT_WORKFLOW_SOURCE = 'tier_change';

export const MANAGE_PLANS_PATH = '/manage_plans';
