import { List } from 'immutable';
import { createSelector } from 'reselect';

import { mergeAndSortByAvatarData } from 'util/ptoPolicy';

import * as entitiesSelectors from './entities';

export const getUsers = entitiesSelectors.createEntitiesForViewSelector(
  'users',
  'ptoPolicy'
);
export const getCompanyUsersLoaded = entitiesSelectors.createLoadedSelector(
  'users',
  'COMPANY_USERS'
);
export const getPolicies = entitiesSelectors.createEntitiesForViewSelector(
  'ptoPolicies',
  'ptoPolicy'
);

export const getIsFetching = (state, { id }) =>
  state.getIn(['ptoPolicy', 'isFetching']).has(id);
export const getIsFetchingIds = state =>
  state.getIn(['ptoPolicy', 'isFetching']);
export const getIsFetchingCompanyUsers = state =>
  getIsFetching(state, { id: 'companyUsers' });
export const getIsFetchingDetails = state =>
  getIsFetching(state, { id: 'details' });
export const getIsFetchingEmployees = state =>
  getIsFetching(state, { id: 'employees' });
export const getIsFetchingBalances = state =>
  getIsFetching(state, { id: 'balances' });

export const getCurrentPolicyId = (state, props = {}) => props.policyId;
export const getNewPolicyId = state =>
  state.getIn(['ptoPolicy', 'newPolicy', 'id']);
export const getPolicyDetailsForm = state =>
  state.getIn(['ptoPolicy', 'detailsForm']);
export const getPolicyEmployeesFormIds = state =>
  state.getIn(['ptoPolicy', 'employeesFormIds']);
export const getPolicyBalancesForm = state =>
  state.getIn(['ptoPolicy', 'balancesForm']);
export const getEditingDetails = state =>
  state.getIn(['ptoPolicy', 'policy', 'editingDetails']);
export const getEditingEmployees = state =>
  state.getIn(['ptoPolicy', 'policy', 'editingEmployees']);
export const getEditEmployeesStepIndex = state =>
  state.getIn(['ptoPolicy', 'policy', 'editEmployeesStepIndex']);

export const getUsersList = createSelector(getUsers, users =>
  users.sortBy(user => user.get('first_name')).toList()
);

export const getUsersMap = createSelector(getUsers, users => users);

export const getAddedEmployees = createSelector(
  getUsers,
  getPolicyEmployeesFormIds,
  (users, formIds) =>
    users
      .filter(employee => formIds.includes(employee.get('id').toString()))
      .sortBy(employee => employee.get('first_name'))
      .toList()
);

export const getPendingEmployees = createSelector(
  getUsers,
  getPolicyEmployeesFormIds,
  (users, formIds) =>
    users
      .filter(employee => !formIds.includes(employee.get('id').toString()))
      .sortBy(employee => employee.get('first_name'))
      .toList()
);

export const getCurrentPolicy = createSelector(
  getPolicies,
  getCurrentPolicyId,
  getNewPolicyId,
  (policies, currentId, newId) => {
    if (!currentId && !newId) {
      return null;
    }

    return currentId
      ? policies.get(currentId.toString())
      : policies.get(newId.toString());
  }
);

export const getCurrentPolicyUsers = createSelector(getCurrentPolicy, policy =>
  policy ? policy.get('users') : List()
);

export const getSortedPolicyBalancesFormData = createSelector(
  getPolicyBalancesForm,
  getUsers,
  mergeAndSortByAvatarData
);

export const getCurrentPolicyLoading = createSelector(
  getCurrentPolicyId,
  getIsFetchingIds,
  getPolicies,
  (id, isFetchingIds, policies) =>
    isFetchingIds.has(`policy${id}`) || !policies.has(id.toString())
);

export const getIsDeletingCurrentPolicy = createSelector(
  getCurrentPolicyId,
  getIsFetchingIds,
  (id, isFetchingIds) => isFetchingIds.has(`deletingPolicy${id}`)
);

export const getBailoutFetchPolicy = createSelector(
  getCurrentPolicyId,
  getIsFetchingIds,
  getPolicies,
  (id, isFetchingIds, policies) =>
    isFetchingIds.has(`policy${id}`) || policies.has(id.toString())
);

export const getSortedEmployeesTableData = createSelector(
  getCurrentPolicyUsers,
  getUsers,
  getCompanyUsersLoaded,
  (policyEmployees, users, loaded) => {
    if (!loaded) {
      return List();
    }
    return mergeAndSortByAvatarData(policyEmployees, users);
  }
);

export const getBailoutFetchCompanyUsers = createSelector(
  getCompanyUsersLoaded,
  getIsFetchingCompanyUsers,
  (loaded, loading) => loaded || loading
);
