import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';

const SvgAddTime = ({
  alt = 'Add Time Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M5.333 1.33C4.596 1.33 4 1.926 4 2.663v1.334H2a2 2 0 0 0-2 2v2h18.667v-2a2 2 0 0 0-2-2h-2V2.663c0-.737-.596-1.333-1.334-1.333-.737 0-1.333.596-1.333 1.333v1.334H6.667V2.663c0-.737-.596-1.333-1.334-1.333Zm5.334 15.333a7.336 7.336 0 0 1 6.666-7.304V9.33H0v11.333a2 2 0 0 0 2 2h11.783a7.333 7.333 0 0 1-3.116-6Zm7.333 6a6 6 0 0 0 0-12c-3.313 0-6 2.688-6 6 0 3.313 2.688 6 6 6Zm.667-8.666v2h2c.366 0 .666.3.666.666 0 .367-.3.667-.666.667h-2v2c0 .367-.3.667-.667.667a.669.669 0 0 1-.667-.667v-2h-2a.669.669 0 0 1-.666-.667c0-.366.3-.666.666-.666h2v-2c0-.367.3-.667.667-.667.367 0 .667.3.667.667Z"
    />
  </svg>
);
export default SvgAddTime;
