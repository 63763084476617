import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgInvoice = ({
  alt = 'Invoice Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M21 6h-6V0l6 6Zm-6 1.5h6v14.25A2.25 2.25 0 0 1 18.75 24H5.25A2.25 2.25 0 0 1 3 21.75V2.25A2.25 2.25 0 0 1 5.25 0h8.25v6c0 .83.67 1.5 1.5 1.5ZM6 4.125c0 .205.17.375.375.375h3.75c.206 0 .375-.17.375-.375v-.75A.377.377 0 0 0 10.125 3h-3.75A.378.378 0 0 0 6 3.375v.75ZM6.375 7.5h3.75a.376.376 0 0 0 .375-.375v-.75A.376.376 0 0 0 10.125 6h-3.75A.377.377 0 0 0 6 6.375v.75c0 .206.17.375.375.375Zm5.883 7.35-.3-.075c-1.069-.328-1.046-.52-1.008-.69.065-.365.778-.455 1.419-.357.262.04.55.132.824.228a.938.938 0 0 0 .615-1.772 7.648 7.648 0 0 0-.87-.248v-.311a.938.938 0 0 0-1.875 0v.28c-1.05.226-1.791.883-1.96 1.862-.347 2.008 1.556 2.567 2.368 2.805l.273.08c1.372.392 1.35.524 1.308.762-.064.367-.777.457-1.42.358-.326-.048-.741-.198-1.11-.33l-.209-.076a.938.938 0 0 0-.624 1.77l.2.07c.363.13.768.262 1.174.352v.317a.938.938 0 0 0 1.875 0v-.293c1.048-.225 1.791-.869 1.961-1.848.35-2.03-1.596-2.589-2.641-2.884Z"
    />
  </svg>
);
export default SvgInvoice;
