import { fromJS } from 'immutable';

import { actionTypes as meteringActionTypes } from 'actions/metering';

const INITIAL_STATE = fromJS({
  events: {},
  shiftNotes: {},
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case meteringActionTypes.FETCH_EVENTS_SUCCESS:
      return state.merge(fromJS({ events: action.payload.events }));
    case meteringActionTypes.FETCH_SHIFT_NOTES_FOR_DATE_COUNT_SUCCESS:
      return state.merge(fromJS({ shiftNotes: action.payload.shift_notes }));
    default:
      return state;
  }
};
