import React from 'react';
import Box from 'fe-components/Box';
import Text from 'fe-design-base/atoms/Text';
import CheckboxField from 'fe-design-base/molecules/CheckboxField';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import ContactInfoRequired from 'features/team/components/AddEmployeeForm/components/ContactInfoRequired';
import { I18N_ONBOARDING_PATH } from 'features/team/components/AddEmployeeForm/constants';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

const { cx } = cxHelpers('InviteOnlyCheckbox');

const InviteOnlyCheckbox = ({
  disabled,
  contactInfoRequired,
  showContactInfoRequired,
}) => {
  const { values } = useFormikContext();
  return (
    <CheckboxField
      className={cx()}
      disabled={disabled}
      name="sendSms"
      data-testid="invite-checkbox"
      label={
        <Box column gap={8}>
          <Text color={disabled ? 'mono500' : 'mono900'} variant="body">
            {values.firstName
              ? toI18n(`${I18N_ONBOARDING_PATH}.invite_only_name`, {
                  props: { firstName: values.firstName },
                })
              : toI18n(`${I18N_ONBOARDING_PATH}.invite_only`)}
          </Text>
          <Text
            color={disabled ? 'mono500' : 'mono700'}
            variant="bodySm"
            i18n={`${I18N_ONBOARDING_PATH}.invite_only_blurb`}
          />
          {showContactInfoRequired && values.sendSms && (
            <ContactInfoRequired contactInfoRequired={contactInfoRequired} />
          )}
        </Box>
      }
      style={{ alignSelf: 'flex-start' }}
    />
  );
};

InviteOnlyCheckbox.propTypes = {
  disabled: PropTypes.bool,
};

InviteOnlyCheckbox.defaultProps = {
  disabled: false,
};

export default InviteOnlyCheckbox;
