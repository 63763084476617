import { v4 } from 'uuid';

import { momentInZone } from 'util/dateTime';

export const getDisplayTime = unixTimestamp => {
  const mDate = momentInZone(unixTimestamp, 'unix');
  const today = momentInZone().startOf('day');

  // If same day, show timestamp as time
  if (mDate.isSame(today, 'd')) {
    return mDate.format('h:mm A');
  }

  // Otherwise, show as date
  return mDate.format('ddd D MMM');
};

export const hasQueryParam = route => /\?\w+=/.test(route);

export const addQuery = (route, query) =>
  route + `${hasQueryParam(route) ? `&` : `?`}${query}`;

export const createTempMessage = ({ body, attachment, channel_id }) => ({
  id: v4(),
  body,
  channel_id,
  attachment: attachment
    ? {
        preview_url: attachment,
        origin_url: attachment,
      }
    : null,
  created_at_secs: Math.round(Date.now() / 1000),
  created_at_formatted: new Date().toDateString(),
  is_system: false,
  is_archived: false,
  is_mine: true,
  is_unread: false,
  coordinates: null,
  sending_user: {},
  shoutout: null,
  cover_request: null,
  total_reads: 0,
  total_recipients: 0,
  can_delete: false,
  reactions: [],
  is_temp: true,
});
