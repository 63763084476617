import { colors, typography } from 'theme/constants';

export const DEFAULT_STYLES = Object.freeze({
  FormHelperTextProps: {
    error: false,
  },
  InputLabelProps: {
    style: {
      color: colors.grayDark,
      fontSize: typography.fs18,
      fontWeight: typography.fw400,
    },
    classes: {
      focused: 'TextField--label-default-focused',
    },
    shrink: false,
    focused: false,
  },
  InputProps: {
    disableUnderline: true,
  },
  inputProps: {
    style: {
      color: colors.navy,
      fontSize: typography.fs16,
    },
  },
  style: {
    height: 35,
  },
});

const HIRING_BASE_STYLE = Object.freeze({
  FormHelperTextProps: {
    error: false,
  },
  InputLabelProps: {
    style: {
      color: colors.grayDark,
      fontSize: typography.fs18,
      fontWeight: typography.fw400,
      top: 0,
    },
    classes: {
      focused: 'TextField--label-hiring-focused',
    },
    shrink: false,
    focused: false,
  },
  InputProps: {
    disableUnderline: false,
  },
  inputProps: {
    style: {
      color: colors.navy,
      fontSize: typography.fs16,
      marginTop: -6,
      borderWidth: 0,
      padding: 0,
      background: 'transparent',
    },
  },
  style: {
    height: 65,
  },
});

export const THEME_STYLES = Object.freeze({
  dark: HIRING_BASE_STYLE,
  darkInline: {
    ...HIRING_BASE_STYLE,
    InputProps: {
      disableUnderline: false,
    },
    inputProps: {
      style: {
        ...HIRING_BASE_STYLE.inputProps.style,
        marginTop: -9,
      },
    },
  },
  lg: {
    inputProps: {
      style: {
        fontSize: typography.fs18,
      },
    },
    style: {
      height: 55,
    },
  },
  bordered: {
    inputProps: {
      style: {
        height: 40,
      },
    },
    style: {
      height: 40,
    },
  },
  fancy: {
    InputProps: {
      disableUnderline: false,
    },
    inputProps: {
      style: {
        color: colors.navy,
        fontSize: typography.fs18,
        borderWidth: 0,
        padding: 0,
        background: 'transparent',
      },
    },
    style: {
      height: 78,
    },
  },
  required: {
    inputProps: {
      style: {
        borderColor: 'red',
      },
    },
  },
});

export const THEME_OPTIONS = Object.freeze(Object.keys(THEME_STYLES));

export const MASK_MAP = {
  zip: [/\d/, /\d/, /\d/, /\d/, /\d/],
  canadianZip: [/[A-Za-z]/, /\d/, /[A-Za-z]/, ' ', /\d/, /[A-Za-z]/, /\d/],
  date: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  ssn: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  dateMY: [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  phoneUS: [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  ein: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
};

export const SAFARI_MASK_MAP = Object.freeze({
  zip: '99999',
  dateMY: '99/9999',
  phoneUS: '(999) 999-9999',
});

export const SAFARI_MASK_KEYS = Object.freeze(Object.keys(SAFARI_MASK_MAP));

export const NUMERIC_MASK_TYPES = [
  'zip',
  'phone',
  'phoneUS',
  'phoneOther',
  'currencyUS',
  'currency',
  'numeric',
];

export const DEBOUNCE_DELAY = 10;
