import React from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Text from 'components/Text';

const PayrollCountdownMobileBanner = ({ timeLeft, onClickGetStarted }) => (
  <Box>
    <Text
      fw400
      fs14
      white
      lh="17px"
      i18n="nav_links.payroll_countdown_timer.mobile.text"
      i18nProps={{ time_left: timeLeft }}
    />
    &nbsp;
    <Button theme="link" onClick={onClickGetStarted}>
      <Text
        underline
        white
        fw600
        fs14
        lh="25px"
        i18n="nav_links.payroll_countdown_timer.mobile.link"
      />
    </Button>
  </Box>
);

PayrollCountdownMobileBanner.propTypes = {
  onClickGetStarted: PropTypes.func.isRequired,
  timeLeft: PropTypes.string.isRequired,
};

export default PayrollCountdownMobileBanner;
