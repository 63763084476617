import PlusJakartaSans from 'fe-design-base/assets/fonts/PlusJakartaSans-Variable.ttf';

/* eslint-disable max-len */
export const essentials = {
  MuiCssBaseline: {
    styleOverrides: `
        @font-face {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('PlusJakartaSans'), url(${PlusJakartaSans}) format('truetype');
          unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
        }
        @font-face {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('PlusJakartaSans'), url(${PlusJakartaSans}) format('truetype');
          unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0300-0301,U+0303-0304,U+0308-0309,U+0323,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
        }
      `,
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        display1: 'h1',
        display2: 'h1',
        display3: 'h1',
        heading1: 'h1',
        heading2: 'h2',
        heading3: 'h3',
        heading4: 'h4',
        body: 'p',
        bodyTight: 'p',
        bodyBold: 'p',
        bodyBoldTight: 'p',
        bodySm: 'p',
        bodySmTight: 'p',
        bodySmBold: 'p',
        bodySmBoldTight: 'p',
        // plural name captions is intentional to avoid clash with MUI variant caption
        captions: 'p',
        captionBold: 'p',
        label: 'p',
      },
    },
  },
};
