import React, { memo, useEffect } from 'react';
import Alert from 'fe-design-base/molecules/Alert';
import Link from 'fe-design-base/molecules/Link';
import PropTypes from 'prop-types';

import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'features/teamView/tracking';

import { cxHelpers } from 'util/className';
import { trackUxEvent } from 'util/tracking';

import SecondaryAction from './SecondaryAction';
const { cx } = cxHelpers('InputError');
export const InputError = ({
  variant,
  error,
  inviteStatus,
  title,
  primaryActionText,
  onPrimaryAction,
  secondaryActionText,
  onSecondaryAction,
  secondaryIsLoading,
  secondaryIsDone,
}) => {
  useEffect(() => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.TEAM,
      eventCategory: EVENT_CATEGORIES.ADD_TEAM_ERROR,
      eventAction: EVENT_ACTIONS.ERROR_MESSAGE_SHOWN,
      properties: {
        invite_status: inviteStatus,
      },
    });
  }, [inviteStatus]);

  return error ? (
    <Alert
      className={cx()}
      forceStacked
      title={title}
      variant={variant}
      action={
        primaryActionText ? (
          <Link onClick={onPrimaryAction} size="small">
            {primaryActionText}
          </Link>
        ) : null
      }
      secondaryAction={
        secondaryActionText ? (
          <SecondaryAction
            secondaryActionText={secondaryActionText}
            onSecondaryAction={onSecondaryAction}
            isDone={secondaryIsDone}
            secondaryIsLoading={secondaryIsLoading}
          />
        ) : null
      }
    >
      {error}
    </Alert>
  ) : null;
};

InputError.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  inviteStatus: PropTypes.string,
  variant: PropTypes.string.isRequired,
  primaryActionText: PropTypes.string.isRequired,
  onPrimaryAction: PropTypes.func.isRequired,
  secondaryActionText: PropTypes.string,
  onSecondaryAction: PropTypes.func,
  secondaryIsLoading: PropTypes.bool,
  secondaryIsDone: PropTypes.bool,
};

InputError.defaultProps = {
  variant: 'error',
};

export default memo(InputError);
