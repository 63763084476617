import { MASK_MAP, NUMERIC_MASK_TYPES } from './constants';

export const formatValueFromMask = (value, maskType) => {
  if (NUMERIC_MASK_TYPES.includes(maskType)) {
    return value.replace(/[^0-9.]/g, '');
  }

  return value;
};

export const isInUS = () => window.navigator.language === 'en-US';

export const getMask = mask => {
  if (mask === 'phone') {
    mask = isInUS() ? 'phoneUS' : 'phoneOther';
  }

  return MASK_MAP[mask] || mask;
};
