import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import * as navActions from 'actions/nav';

export default WrappedComponent => {
  @connect(() => ({}), { toggleMobileNav: navActions.toggleMobileNav })
  class WithCloseMobileNav extends PureComponent {
    static propTypes = {
      toggleMobileNav: PropTypes.func.isRequired,
    };

    handleCloseMobileNav = () => this.props.toggleMobileNav(false);

    render() {
      return (
        <WrappedComponent
          {...omit(this.props, 'toggleMobileNav', 'dispatch')}
          onCloseMobileNav={this.handleCloseMobileNav}
        />
      );
    }
  }

  return WithCloseMobileNav;
};
