import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgBusinessAddress = ({
  alt = 'Business Address Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M18.75 0A2.25 2.25 0 0 1 21 2.25v19.5A2.25 2.25 0 0 1 18.75 24h-4.5v-3.75a2.25 2.25 0 0 0-4.5 0V24h-4.5A2.25 2.25 0 0 1 3 21.75V2.25A2.25 2.25 0 0 1 5.25 0h13.5ZM6 12.75c0 .412.336.75.75.75h1.5c.412 0 .75-.338.75-.75v-1.5a.752.752 0 0 0-.75-.75h-1.5a.751.751 0 0 0-.75.75v1.5Zm5.25-2.25a.752.752 0 0 0-.75.75v1.5c0 .412.338.75.75.75h1.5c.412 0 .75-.338.75-.75v-1.5a.752.752 0 0 0-.75-.75h-1.5ZM15 12.75c0 .412.338.75.75.75h1.5c.413 0 .75-.338.75-.75v-1.5a.752.752 0 0 0-.75-.75h-1.5a.752.752 0 0 0-.75.75v1.5ZM6.75 4.5a.751.751 0 0 0-.75.75v1.5c0 .412.336.75.75.75h1.5c.412 0 .75-.338.75-.75v-1.5a.752.752 0 0 0-.75-.75h-1.5Zm3.75 2.25c0 .412.338.75.75.75h1.5c.412 0 .75-.338.75-.75v-1.5a.752.752 0 0 0-.75-.75h-1.5a.752.752 0 0 0-.75.75v1.5Zm5.25-2.25a.752.752 0 0 0-.75.75v1.5c0 .412.338.75.75.75h1.5c.413 0 .75-.338.75-.75v-1.5a.752.752 0 0 0-.75-.75h-1.5Z"
    />
  </svg>
);
export default SvgBusinessAddress;
