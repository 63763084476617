import { fromJS } from 'immutable';
import { omit } from 'lodash';

import { actionTypes as billingActionTypes } from 'actions/billing';
import { actionTypes as hrProActionTypes } from 'actions/hrPro';

const INITIAL_MODAL_STATE = {
  modalIsOpen: false,
  modalTierName: '',
  modalTierId: null,
  modalBillingCycle: '',
  modalIsSubmitting: false,
  appliedDiscountInfo: null,
  discountIsLoading: false,
  // This bucket is to store multiple location Ids when the billing multi-location
  // widget is rendered. If it is not rendered, we fallback to use the current location id
  selectedLocationIdsForUpgrade: [],
  preloadedDiscountCode: null,
  locationsToBeCreated: [],
  zipRecruiterBillingFlow: false,
  jobRequestId: null,
};

const INITIAL_STATE = fromJS({
  ...INITIAL_MODAL_STATE,
  data: {},
  managers: [],
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case billingActionTypes.FETCH_MANAGERS_SUCCESS:
      return state.merge({
        managers: fromJS(action.payload),
      });
    case hrProActionTypes.OPEN_MODAL: {
      return state.merge({
        preloadedDiscountCode: action.payload.preloadedDiscountCode,
      });
    }
    case billingActionTypes.OPEN_MODAL: {
      return state.merge({
        modalIsOpen: true,
        modalTierName: action.payload.modalTierName,
        modalTierId: action.payload.modalTierId,
        modalBillingCycle: action.payload.modalBillingCycle,
        preloadedDiscountCode: action.payload.preloadedDiscountCode,
        zipRecruiterBillingFlow: action.payload.zipRecruiterBillingFlow,
        jobRequestId: action.payload.jobRequestId,
      });
    }

    case billingActionTypes.CLOSE_MODAL: {
      // retain 'data' so it's still present after subsequent closings/openings of the modal
      return state.merge(omit(INITIAL_MODAL_STATE, 'locationsToBeCreated'));
    }

    case billingActionTypes.BILLING_SUBMIT_FAILURE: {
      return state.merge({ modalIsSubmitting: false });
    }

    default:
      return state;
  }
};
