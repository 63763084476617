import {
  BOX_SHADOW_DIRECTIONS,
  DEFAULT_BOX_SHADOW_COLOR,
  SHADOW_DIRECTION_MAP,
  SHADOW_OPACITY_DEFAULT,
  SHADOW_OPACITY_Z1,
} from 'fe-design-base/atoms/Box/constants';

import { hexToRGBA } from 'util/hexToRGBA';

// FOR USE WITH STYLE OVERRIDES OR OTHER CUSTOM CSS
// calling shadows.z1-z4() will default to bottom direction
// call with optional 'bottom', 'top', 'right', or 'left'
// example: with no direction passed....
// boxShadow: shadows.z3()
// example: with direction passed....
// boxShadow: shadows.z3('right')

export const shadows = {
  z1: (direction: (typeof BOX_SHADOW_DIRECTIONS)[number] = 'bottom') =>
    `${SHADOW_DIRECTION_MAP[direction].z1} ${hexToRGBA(
      DEFAULT_BOX_SHADOW_COLOR,
      SHADOW_OPACITY_Z1
    )}`,
  z2: (direction: (typeof BOX_SHADOW_DIRECTIONS)[number] = 'bottom') =>
    `${SHADOW_DIRECTION_MAP[direction].z2} ${hexToRGBA(
      DEFAULT_BOX_SHADOW_COLOR,
      SHADOW_OPACITY_DEFAULT
    )}`,
  z3: (direction: (typeof BOX_SHADOW_DIRECTIONS)[number] = 'bottom') =>
    `${SHADOW_DIRECTION_MAP[direction].z3} ${hexToRGBA(
      DEFAULT_BOX_SHADOW_COLOR,
      SHADOW_OPACITY_DEFAULT
    )}`,
  z4: (direction: (typeof BOX_SHADOW_DIRECTIONS)[number] = 'bottom') =>
    `${SHADOW_DIRECTION_MAP[direction].z4} ${hexToRGBA(
      DEFAULT_BOX_SHADOW_COLOR,
      SHADOW_OPACITY_DEFAULT
    )}`,
};
