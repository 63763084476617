import React from 'react';
import Icon from 'react-icon-base';

const LongArrowRight = ({ color, props }) => (
  <Icon {...props} viewBox="3 0 15 15">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9697 0.860966C11.2626 0.568073 11.7374 0.568073 12.0303 0.860966L16.0303 4.86097C16.3232 5.15386 16.3232 5.62873 16.0303 5.92163L12.0303 9.92163C11.7374 10.2145 11.2626 10.2145 10.9697 9.92163C10.6768 9.62873 10.6768 9.15386 10.9697 8.86097L13.6893 6.1413H1.5C1.08579 6.1413 0.75 5.80551 0.75 5.3913C0.75 4.97708 1.08579 4.6413 1.5 4.6413H13.6893L10.9697 1.92163C10.6768 1.62873 10.6768 1.15386 10.9697 0.860966Z"
      fill={color || 'inherit'}
    />
  </Icon>
);

export default LongArrowRight;
