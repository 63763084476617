import React, { ForwardedRef } from 'react';

import { TooltipProps } from './types';

const Wrapper = React.forwardRef(
  (
    { children, ...props }: Omit<TooltipProps, 'content'>,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
);

export default Wrapper;
