import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgCaretRight = ({
  alt = 'Caret Right Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.817 13.695a2.451 2.451 0 0 0 0-3.396L9.487.703A2.276 2.276 0 0 0 6.943.186 2.402 2.402 0 0 0 5.5 2.405v19.192a2.41 2.41 0 0 0 1.443 2.219 2.288 2.288 0 0 0 2.544-.517l9.33-9.596v-.008Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCaretRight;
