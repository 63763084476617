import React, { memo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Button, { ButtonProps } from 'fe-design-base/molecules/Button';
import { useIsMediumScreen } from 'fe-design-base/utils/useMediaQuery';

export interface ActionButtonProps extends ButtonProps {
  label: string;
}

export interface ActionButtonsProps {
  forceStack?: boolean;
  forceHorizontal?: boolean;
  buttons?: Array<ActionButtonProps>;
}

const ActionButtons = ({
  forceStack,
  forceHorizontal,
  buttons,
}: ActionButtonsProps) => {
  const isMediumScreen = useIsMediumScreen();
  const stack = !forceHorizontal && (forceStack || !isMediumScreen);
  const horizontal = !forceStack && (forceHorizontal || isMediumScreen);
  const gap = stack ? '16px 0' : '0 16px';
  return buttons ? (
    <Box
      column={stack}
      rowreverse={isMediumScreen && horizontal}
      row={forceHorizontal && !isMediumScreen}
      spaceevenly={forceHorizontal && !isMediumScreen}
      gap={gap}
    >
      {buttons.map(({ label, variant, ...buttonProps }) => (
        <Button
          key={label}
          variant={variant || 'primary'}
          grow={forceHorizontal && !isMediumScreen}
          {...buttonProps}
        >
          {label}
        </Button>
      ))}
    </Box>
  ) : null;
};

export default memo(ActionButtons);
