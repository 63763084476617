import { Overrides } from '../../types';
import baseColors from '../colors';

export const checkboxOverrides: Overrides = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '&.FDBCheckbox': {
          color: baseColors.purple500,
          padding: 0,
          height: '32px',
          width: '32px',
          // Negative margin needed here to vertically align left edge of checkbox
          marginLeft: '-7px',
          '& svg': {
            height: '18px',
            width: '18px',
          },
          '&.Mui-checked': {
            color: baseColors.purple500,

            '&[aria-disabled="true"]': {
              color: baseColors.mono500,

              '& input': {
                cursor: 'not-allowed !important',

                '&:focus-visible + svg': {
                  color: baseColors.mono500,
                },
              },
            },
          },
          '&[aria-disabled="true"]:not(.Mui-checked)': {
            color: baseColors.mono500,

            '& input': {
              cursor: 'not-allowed !important',

              '&:focus-visible + svg': {
                color: baseColors.mono500,
              },
            },

            '& svg': {
              fill: baseColors.mono300,
            },
          },
          '&:hover:not([aria-disabled="true"])': {
            color: baseColors.purple700,
            backgroundColor: baseColors.purple100,
            borderRadius: '50%',
          },
          '&:active:not([aria-disabled="true"])': {
            color: baseColors.purple700,
            backgroundColor: baseColors.purple300,
            borderRadius: '50%',

            '& input': {
              '&:focus + svg': {
                outline: 'none',
              },
            },
          },
          '&:focus-visible': {
            backgroundColor: `${baseColors.mono0} !important`,
          },

          '& input': {
            height: '32px',
            width: '32px',
            position: 'absolute',
            padding: 0,
            margin: 0,
            borderRadius: 0,
            border: 0,
            verticalAlign: 'baseline',

            '&:focus-visible + svg': {
              outline: `solid 2px ${baseColors.accent500}`,
              borderRadius: '4px',
              outlineOffset: '2px',
              color: baseColors.purple700,
            },
          },
          '&--error': {
            color: baseColors.red300,
          },
        },
      },
    },
  },
};
