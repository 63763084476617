import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgDownload = ({
  alt = 'Download Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="m19.06 10.06-6 6a1.502 1.502 0 0 1-2.124 0l-6-6a1.502 1.502 0 0 1 2.123-2.124l3.441 3.44V1.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v9.877l3.44-3.441a1.502 1.502 0 0 1 2.124 2.123h-.005ZM4.5 16.5v3c0 .83.67 1.5 1.5 1.5h12c.83 0 1.5-.67 1.5-1.5v-3c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v3c0 2.484-2.016 4.5-4.5 4.5H6a4.501 4.501 0 0 1-4.5-4.5v-3c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5Z"
    />
  </svg>
);
export default SvgDownload;
