import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useTransition } from '@react-spring/web';
import PropTypes from 'prop-types';

import { TRANSITION_PROPS } from 'features/team/constants';
import { selectDrawerIsOpen } from 'features/team/selectors';

import AddTeamDrawerContainer from './AddTeamDrawerContainer';

const AddTeamDrawerRoot = ({ isOpen }) => {
  const transitions = useTransition(isOpen, TRANSITION_PROPS);

  return transitions((animations, isDrawerOpen) =>
    isDrawerOpen ? <AddTeamDrawerContainer animations={animations} /> : null
  );
};

AddTeamDrawerRoot.propTypes = {
  isOpen: PropTypes.bool,
};

export default connect(state => ({
  isOpen: selectDrawerIsOpen(state),
}))(memo(AddTeamDrawerRoot));
