// Local module to retrieve the window.Sentry object, primarily for
// ease of mocking it in tests.

type SentryType = {
  captureException: (error: any) => void;
  captureMessage: (...args: string[]) => void;
};

declare global {
  interface Window {
    Sentry: SentryType;
  }
}

const windowSentry: () => SentryType = () => window.Sentry;

export default windowSentry;
