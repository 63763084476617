import windowLocation from 'fe-core/util/windowLocation';
import Cookies from 'js-cookie';
import { merge } from 'lodash';
import queryString from 'query-string';

// TODO: These dependencies need to be moved as lib utilities should not
// be referencing anything from client/src.  This will happen as we plan
// to move more utilities out of the client application and into the lib
// folder.
import { NAVIGATION } from 'util/navigation';

const WORKER_STATUS_ROUTE = '/workers/status';
const WORKER_RETRY_DELAY = 1000;
const WORKER_RETRY_SLOWDOWN_THRESHOLD = 9;
const WORKER_STATUS_DONE = 'done';
const WORKER_STATUS_FAIL = 'fail';

const parseJSON = response => response?.json();

const moduleState = {
  config: {
    apiRoot: '/api/fe',
    newApiRoot: '',
  },
};

const apiRoot = () => moduleState.config.apiRoot;
const newApiRoot = () => moduleState.config.newApiRoot;

const fullUrl = path => `${apiRoot()}${path}`;
const newFullUrl = path => `${newApiRoot()}${path}`;

const workerStatusUrl = jobId =>
  `${WORKER_STATUS_ROUTE}?${queryString.stringify({ job_id: jobId })}`;

export const config = newConfig => {
  moduleState.config = newConfig;
};

const checkStatus = async response => {
  if (response.ok) return response;

  if (response.status === 401) {
    windowLocation().href = `${
      NAVIGATION.ACCOUNT_SIGN_IN
    }?destination=${encodeURIComponent(windowLocation().href)}`;
    return;
  }

  const { errors } = (await response?.json()) || {};
  /** Setting the first error from the backend as the default error message */
  throw new Error(errors ? errors[0]?.detail : response.statusText);
};

const csrfToken = () => Cookies.get('CSRF-TOKEN') || '';

const headers = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-CSRF-Token': csrfToken(),
});

const pause = delay => new Promise(resolve => setTimeout(resolve, delay));

const baseApiFetch = (url, options = {}) =>
  fetch(
    url,
    merge(
      {
        method: 'GET',
        headers: headers(),
        credentials: 'same-origin',
      },
      options
    )
  )
    .then(checkStatus)
    .then(parseJSON);

const waitForSidekiq = (
  { jobId, next },
  retry = 0,
  delay = WORKER_RETRY_DELAY
) =>
  baseApiFetch(workerStatusUrl(jobId)).then(response => {
    if (!response) return;

    if (response.status === WORKER_STATUS_DONE) {
      if (next) return baseApiFetch(next);

      return response;
    } else if (response.status === WORKER_STATUS_FAIL) {
      throw new Error('Worker Failed');
    }

    return pause(delay).then(() =>
      waitForSidekiq(
        { jobId, next },
        retry + 1,
        retry > WORKER_RETRY_SLOWDOWN_THRESHOLD ? delay * 2 : delay
      )
    );
  });

const oldApiFetch = (path, options = {}) =>
  baseApiFetch(fullUrl(path), options).then(response => {
    if (!response) return;

    if (response.data?.type === 'sidekiqJob') {
      return waitForSidekiq({
        jobId: response.data.id,
        next: response.links.next,
      });
    }

    return response;
  });

const newApiFetch = (path, options = {}) =>
  baseApiFetch(newFullUrl(path), options).then(response => {
    if (!response) return;

    if (response.data?.type === 'sidekiqJob') {
      return waitForSidekiq({
        jobId: response.data.id,
        next: response.links.next,
      });
    }

    return response;
  });

const apiFetch = (path, options = {}) => {
  const useNewApiRoot = localStorage.getItem('useNewApiRoot');
  if (useNewApiRoot === null) {
    return oldApiFetch(path, options);
  }
  return newApiFetch(path, options);
};

export const get = path => apiFetch(path);

export const post = (path, payload) =>
  apiFetch(path, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const put = (path, payload) =>
  apiFetch(path, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const del = path =>
  apiFetch(path, {
    method: 'DELETE',
  });
