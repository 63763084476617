import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgLink = ({
  alt = 'Link Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M5.423 4.8A5.42 5.42 0 0 0 0 10.222a5.42 5.42 0 0 0 4.53 5.348l.071.011a1.2 1.2 0 0 0 .394-2.366l-.071-.011A3.024 3.024 0 0 1 5.422 7.2h5.955a3.023 3.023 0 0 1 .495 6.004l-.07.01a1.2 1.2 0 0 0 .393 2.367l.071-.011a5.423 5.423 0 0 0 4.53-5.348A5.415 5.415 0 0 0 11.378 4.8H5.422Zm13.155 14.4A5.42 5.42 0 0 0 24 13.777a5.42 5.42 0 0 0-4.53-5.347l-.071-.011a1.2 1.2 0 0 0-.394 2.366l.071.011a3.023 3.023 0 0 1-.495 6.004h-5.958a3.023 3.023 0 0 1-.495-6.004l.07-.011a1.2 1.2 0 0 0-.393-2.366l-.071.01a5.42 5.42 0 0 0 .889 10.77h5.954Z"
    />
  </svg>
);
export default SvgLink;
