import React from 'react';
import Icon from 'react-icon-base';

const RocketLaunch = props => (
  <Icon
    viewBox="0 0 12 12"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.8398 0.452271C11.8122 0.324234 11.676 0.188232 11.548 0.160858C10.7957 0 10.2062 0 9.61921 0C7.20003 0 5.74926 1.29382 4.66621 3H2.22396C1.84199 3 1.3896 3.27946 1.21874 3.62105L0.0604291 5.93665C0.0246178 6.01562 0.00458616 6.10084 0.00146033 6.1875C0.00151003 6.33668 0.0608006 6.47974 0.166297 6.58522C0.271793 6.6907 0.41486 6.74997 0.564043 6.75H3.00044C3.29593 6.74998 3.58854 6.80816 3.86155 6.92122C4.13456 7.03428 4.38263 7.20001 4.59159 7.40894C4.80056 7.61787 4.96632 7.86592 5.07942 8.13891C5.19252 8.41191 5.25075 8.7045 5.25077 9V11.4375C5.25082 11.5867 5.31011 11.7297 5.41561 11.8352C5.5211 11.9407 5.66417 12 5.81335 12C5.90001 11.9968 5.98522 11.9768 6.06424 11.941L8.37863 10.7831C8.71989 10.6123 8.99912 10.1605 8.99912 9.77893V7.33118C10.7004 6.24573 11.9996 4.79041 11.9996 2.38367C12.0018 1.7942 12.0018 1.20474 11.8398 0.452271ZM9.00132 3.9375C8.81591 3.93747 8.63466 3.88247 8.48051 3.77944C8.32636 3.67641 8.20622 3.52998 8.13528 3.35867C8.06435 3.18736 8.0458 2.99887 8.08199 2.81702C8.11818 2.63517 8.20748 2.46814 8.3386 2.33704C8.46971 2.20594 8.63676 2.11667 8.81861 2.0805C9.00047 2.04434 9.18896 2.06291 9.36026 2.13388C9.53156 2.20484 9.67796 2.325 9.78097 2.47917C9.88398 2.63334 9.93896 2.81459 9.93896 3C9.93884 3.24863 9.84 3.48703 9.66419 3.66282C9.48837 3.83862 9.24995 3.93741 9.00132 3.9375ZM0.836361 8.25156C0.23033 8.8575 -0.0691372 10.3733 0.0135013 11.9862C1.6334 12.0696 3.14502 11.767 3.74862 11.1634C4.6929 10.2193 4.75356 8.96059 3.89655 8.10365C3.03954 7.24681 1.78055 7.30746 0.836361 8.25156ZM2.74964 10.221C2.54842 10.4222 2.04458 10.5231 1.50466 10.4952C1.47714 9.95769 1.57695 9.45245 1.77894 9.25049C2.09366 8.93582 2.51326 8.91559 2.7989 9.20119C3.08454 9.48683 3.06435 9.90637 2.74964 10.221Z"
        fill="#04A2BD"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default RocketLaunch;
