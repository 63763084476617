import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgClear = ({
  alt = 'Clear Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={32}
    height={32}
    viewBox="0 0 32 32"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M0 16C0 7.162 7.162 0 16 0c8.837 0 16 7.162 16 16 0 8.837-7.163 16-16 16-8.838 0-16-7.163-16-16Zm10.938-2.994 2.943 2.938-2.944 2.993c-.58.588-.58 1.538 0 2.07.588.637 1.538.637 2.07 0l2.937-2.888 2.993 2.887c.588.638 1.538.638 2.07 0 .637-.531.637-1.481 0-2.069l-2.888-2.993 2.887-2.938c.638-.531.638-1.481 0-2.069-.531-.58-1.481-.58-2.069 0l-2.993 2.944-2.938-2.944c-.531-.58-1.481-.58-2.069 0-.58.588-.58 1.538 0 2.07Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClear;
