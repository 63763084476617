import React from 'react';
import Icon from 'react-icon-base';

const ClockOut = ({ color, height, width, ...props }) => (
  <Icon
    height={height || '16'}
    width={width || '16'}
    viewBox="0 0 16 16"
    {...props}
  >
    <use
      fill={color || '#313A43'}
      transform="translate(6.3)"
      xlinkHref="#a-clockout"
    />
    <use
      fill={color || '#313A43'}
      transform="translate(0 2.667)"
      xlinkHref="#b-clockout"
    />
    <defs>
      <path
        id="a-clockout"
        d="M9.367 0h-4c-.2 0-.334.133-.334.333s.134.334.334.334h3.2L.1 9.067a.322.322 0 0 0 0 .466c.067.067.133.067.267.067.133 0 .2 0 .266-.067l8.4-8.4v3.2c0 .2.134.334.334.334s.333-.134.333-.334v-4C9.7.133 9.567 0 9.367 0z"
      />
      <path
        id="b-clockout"
        d="M13 3.267c-.2 0-.333.133-.333.333v8.067c0 .533-.467 1-1 1h-10c-.534 0-1-.467-1-1v-10c0-.534.466-1 1-1H9.6c.2 0 .333-.134.333-.334S9.8 0 9.6 0H1.667A1.65 1.65 0 0 0 0 1.667v10a1.65 1.65 0 0 0 1.667 1.666h10a1.65 1.65 0 0 0 1.666-1.666V3.6c0-.133-.133-.333-.333-.333z"
      />
    </defs>
  </Icon>
);

export default ClockOut;
