import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import CheckboxField from 'fe-design-base/molecules/CheckboxField';
import FDBDatePickerField from 'fe-design-base/organisms/FDBDatePickerField';
import { useFormikContext } from 'formik';

import {
  FORM_WITH_STEPS_BOX_STYLE,
  I18N_PAYROLL_PATH,
} from 'features/team/components/AddEmployeeForm/constants';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import SectionTitle from '../../components/SectionTitle';
import TaxClassification from '../../components/TaxClassification';
const { cx } = cxHelpers('PayrollSection');
const PayrollSection = ({ showFormWithSteps }) => {
  const { values } = useFormikContext();

  return (
    <Box
      className={cx()}
      mv={28}
      {...(showFormWithSteps && FORM_WITH_STEPS_BOX_STYLE)}
    >
      <SectionTitle section="payroll" topBorder />
      <Box mt={24}>
        <CheckboxField
          name="includedInPayroll"
          label={toI18n(`${I18N_PAYROLL_PATH}.include_in_payroll`)}
        />
        <Box mt={24} w={288}>
          <FDBDatePickerField
            hasAsterisk={values.jobTaxClassification === 'w2-employee'}
            name="hireDate"
            label={toI18n(`${I18N_PAYROLL_PATH}.start_date`)}
          />
        </Box>
      </Box>
      <TaxClassification />
    </Box>
  );
};

export default PayrollSection;
