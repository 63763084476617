import React, { useCallback, useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { announcementReadsCreateRoute } from 'api';
import { postJSON } from 'api/fetch';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Modal from 'components/modals/Modal';
import Text, { H3 } from 'components/Text';

import { cxFunction } from 'util/className';
import { trackAnnouncementsEvent } from 'util/tracking';
import { TRACK_ACTIONS, TRACK_SOURCES } from 'util/tracking_constants';

const AnnouncementModal = ({
  announcement,
  setAnnouncementCanShowToFalse,
  cx,
}) => {
  const announcementId = announcement.get('id');
  const isOpen = !announcement.isEmpty() && !!announcementId;

  useEffect(() => {
    if (isOpen) {
      trackAnnouncementsEvent({
        announcementType: TRACK_SOURCES.MODAL,
        eventAction: TRACK_ACTIONS.VIEW,
        properties: {
          announcementName: announcement.get('name'),
        },
      });
    }
  }, [isOpen, announcement]);

  const handleClose = useCallback(() => {
    postJSON(announcementReadsCreateRoute(), {
      announcement_id: announcementId,
    });

    setAnnouncementCanShowToFalse(`${announcementId}`);
  }, [announcementId, setAnnouncementCanShowToFalse]);

  return (
    <Modal isOpen={isOpen} onRequestClose={handleClose} key={announcementId}>
      <Box className={cx()} pa16>
        <Box>
          <H3 asHTML>{announcement.get('header')}</H3>
        </Box>
        <Box>
          <Text asHTML>{announcement.get('body')}</Text>
        </Box>
      </Box>
    </Modal>
  );
};

AnnouncementModal.propTypes = {
  announcement: ImmutablePropTypes.map.isRequired,
  setAnnouncementCanShowToFalse: PropTypes.func.isRequired,
};

export default cxFunction('AnnouncementModal', AnnouncementModal);
