import { fromJS, OrderedMap, Set } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes as notificationActionTypes } from 'actions/notifications';
import { actionTypes } from 'actions/timeOff';

const INITIAL_STATES = {
  isFetching: Set(),
  summary: OrderedMap(),
};

const isFetching = (state = INITIAL_STATES.isFetching, action) => {
  switch (action.type) {
    case actionTypes.APPROVE_TIME_OFF_REQUEST:
    case actionTypes.DECLINE_TIME_OFF_REQUEST:
    case actionTypes.FETCH_TIME_OFFS_REQUEST:
    case actionTypes.FETCH_PTO_POLICIES_REQUEST:
    case actionTypes.FETCH_TIME_OFF_SUMMARY_REQUEST:
    case actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_REQUEST:
    case actionTypes.UPDATE_PTO_POLICY_FOR_USER_REQUEST:
    case notificationActionTypes.MANAGER_APPROVE_TIMEOFF_REQUEST:
    case notificationActionTypes.MANAGER_REJECT_TIMEOFF_REQUEST:
      return state.add(action.meta.requestId);

    case actionTypes.APPROVE_TIME_OFF_SUCCESS:
    case actionTypes.APPROVE_TIME_OFF_FAILURE:
    case actionTypes.DECLINE_TIME_OFF_SUCCESS:
    case actionTypes.DECLINE_TIME_OFF_FAILURE:
    case actionTypes.FETCH_TIME_OFFS_SUCCESS:
    case actionTypes.FETCH_TIME_OFFS_FAILURE:
    case actionTypes.FETCH_PTO_POLICIES_SUCCESS:
    case actionTypes.FETCH_PTO_POLICIES_FAILURE:
    case actionTypes.FETCH_TIME_OFF_SUMMARY_SUCCESS:
    case actionTypes.FETCH_TIME_OFF_SUMMARY_FAILURE:
    case actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_SUCCESS:
    case actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_FAILURE:
    case actionTypes.UPDATE_PTO_POLICY_FOR_USER_SUCCESS:
    case actionTypes.UPDATE_PTO_POLICY_FOR_USER_FAILURE:
    case notificationActionTypes.MANAGER_APPROVE_TIMEOFF_SUCCESS:
    case notificationActionTypes.MANAGER_APPROVE_TIMEOFF_FAILURE:
    case notificationActionTypes.MANAGER_REJECT_TIMEOFF_SUCCESS:
    case notificationActionTypes.MANAGER_REJECT_TIMEOFF_FAILURE:
      return state.remove(action.meta.requestId);

    default:
      return state;
  }
};

const summary = (state = INITIAL_STATES.summary, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TIME_OFF_SUMMARY_SUCCESS:
      return action.payload.reduce(
        (memo, userSummary) =>
          memo.set(userSummary.user_id, fromJS(userSummary)),
        OrderedMap()
      );

    case actionTypes.FETCH_TIME_OFF_SUMMARY_FOR_USER_SUCCESS:
      return state.set(action.payload[0].user_id, fromJS(action.payload[0]));

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  summary,
});
