import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSettingsOutline = ({
  alt = 'Settings Outline Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M7.5 12c0-2.527 1.973-4.5 4.5-4.5 2.484 0 4.5 1.973 4.5 4.5 0 2.484-2.016 4.5-4.5 4.5A4.474 4.474 0 0 1 7.5 12ZM12 9.75a2.25 2.25 0 1 0 0 4.501 2.25 2.25 0 0 0 0-4.501ZM13.739 0c1.04 0 1.945.712 2.189 1.722l.366 1.522c.398.195.778.416 1.139.66l1.504-.443a2.253 2.253 0 0 1 2.588 1.033l1.739 3.01c.516.9.352 2.04-.398 2.757l-1.14 1.04c.015.258.024.479.024.699 0 .22-.01.44-.023.656l1.139 1.083a2.26 2.26 0 0 1 .398 2.756l-1.74 3.01a2.259 2.259 0 0 1-2.587 1.036l-1.504-.446a9.73 9.73 0 0 1-1.14.661l-.365 1.524A2.252 2.252 0 0 1 13.739 24h-3.478a2.252 2.252 0 0 1-2.19-1.72l-.365-1.524a9.73 9.73 0 0 1-1.139-.66l-1.547.445c-.955.29-2.023-.136-2.543-1.036l-1.739-3.01a2.25 2.25 0 0 1 .398-2.756l1.136-1.083a10.149 10.149 0 0 1 0-1.354L1.136 10.26a2.251 2.251 0 0 1-.398-2.756l1.74-3.011c.52-.9 1.587-1.327 2.542-1.033l1.547.443c.361-.244.74-.465 1.14-.66l.365-1.522A2.25 2.25 0 0 1 10.26 0h3.478ZM9.633 4.856l-.525.22a7.395 7.395 0 0 0-1.65.957l-.455.347-2.577-.76-1.74 3.01 1.946 1.851-.072.563a7.677 7.677 0 0 0 0 1.912l.072.563-1.945 1.851 1.739 3.01 2.577-.76.455.347a7.392 7.392 0 0 0 1.65.956l.525.22.628 2.607h3.478l.628-2.606.525-.22a7.393 7.393 0 0 0 1.65-.957l.455-.347 2.578.76 1.739-3.01-1.945-1.851.07-.563c.042-.314.06-.633.06-.956 0-.323-.018-.642-.06-.956l-.07-.563 1.945-1.851-1.74-3.01-2.577.76-.455-.347a7.396 7.396 0 0 0-1.65-.956l-.525-.22-.628-2.607h-3.478l-.628 2.606Z"
    />
  </svg>
);
export default SvgSettingsOutline;
