// Open Announcements on initial page load.
// Note: we open Announcements when navigating the React app via BrowserHistory.jsx

import { refreshAnnouncementsTargetPathMatch } from 'actions/session';

import { getAnnouncementsForCurrentPath } from 'selectors/session';

const handleAnnouncements = store => {
  const matchingAnnouncements = getAnnouncementsForCurrentPath(
    store.getState(),
    {
      pathname: window.location.pathname,
    }
  );

  if (matchingAnnouncements) {
    store.dispatch(refreshAnnouncementsTargetPathMatch(matchingAnnouncements));
  }
};

export default handleAnnouncements;
