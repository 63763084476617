import { createSelector } from 'reselect';

import {
  getCanManageTier,
  getCurrentLocationActiveTrialEndDate,
  getCurrentLocationMtcDiscountEligible,
  getHasFeature,
  getMobileTimeClockEnabled,
  getMobileTimeClockEnforcementEnabled,
} from 'selectors/session';

import { daysDiffFromToday } from 'util/dateTime';

import { mtcEnforcementModalShown } from './util';

export const shouldShowMobileTimeClockEnforcementModal = createSelector(
  getCanManageTier,
  getMobileTimeClockEnabled,
  getMobileTimeClockEnforcementEnabled,
  getCurrentLocationActiveTrialEndDate,
  state => getHasFeature(state, { feature: 'mobile_timeclock' }),
  (
    canManageTier,
    mobileTimeClockEnabled,
    mobileTimeClockEnforcementEnabled,
    activeTrialEndDate,
    mobileTimeclockFeatureEnabled
  ) =>
    Boolean(
      !mtcEnforcementModalShown() &&
        canManageTier &&
        mobileTimeClockEnabled &&
        mobileTimeClockEnforcementEnabled &&
        mobileTimeclockFeatureEnabled &&
        activeTrialEndDate &&
        daysDiffFromToday(activeTrialEndDate) >= -3 &&
        daysDiffFromToday(activeTrialEndDate) < 0
    )
);

export const mobileTimeClockDiscountEligible = createSelector(
  getCanManageTier,
  getCurrentLocationMtcDiscountEligible,
  getCurrentLocationActiveTrialEndDate,
  (canManageTier, currentLocationMtcDiscountEligible, activeTrialEndDate) =>
    Boolean(
      currentLocationMtcDiscountEligible &&
        canManageTier &&
        activeTrialEndDate &&
        daysDiffFromToday(activeTrialEndDate) <= 0
    )
);
