import { Map } from 'immutable';

import { actionTypes } from 'actions/hrPro';

const INITIAL_STATE = Map({
  loading: true,
  hrDemandLoading: false,
  price: '',
  isPaid: false,
  // TODO: remove and rely on RootModal infrastructure
  // https://joinhomebase.atlassian.net/browse/HIRING-331
  modalIsOpen: false,
  // TODO: store this locally, or use the Fetching infrastructure
  // https://joinhomebase.atlassian.net/browse/HIRING-331
  modalIsSubmitting: false,
  canAccessHrProViaTier: false,
  hasActiveTrialPeriod: false,
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PAGE_DATA_REQUEST:
      return state.merge({
        loading: true,
      });
    case actionTypes.FETCH_PAGE_DATA_FAILURE:
      return state.merge({
        loading: false,
      });
    case actionTypes.FETCH_PAGE_DATA_SUCCESS:
      return state.merge({
        loading: false,
        price: action.payload.hr_pro_price,
        priceNumeric: action.payload.hr_pro_price_numeric,
        isPaid: action.payload.is_hr_pro_paid,
        ssoCardData: action.payload.sso_card_data,
        canAccessHrProViaTier: action.payload.hr_pro_tier_access,
        hasActiveTrialPeriod: action.payload.hr_pro_trial_period,
      });
    case actionTypes.ENSURE_HR_ON_DEMAND_REQUEST:
      return state.merge({
        hrDemandLoading: true,
      });
    case actionTypes.ENSURE_HR_ON_DEMAND_FAILURE:
      return state.merge({
        hrDemandLoading: false,
      });
    case actionTypes.ENSURE_HR_ON_DEMAND_SUCCESS:
      return state.merge({
        hrDemandLoading: false,
      });
    case actionTypes.OPEN_MODAL: {
      return state.merge({
        modalIsOpen: true,
      });
    }
    // TODO: remove and rely on RootModal infrastructure
    // https://joinhomebase.atlassian.net/browse/HIRING-331
    case actionTypes.CLOSE_MODAL: {
      return state.merge({ modalIsOpen: false });
    }
    case actionTypes.SUBSCRIBE_REQUEST: {
      return state.merge({ modalIsSubmitting: true });
    }
    case actionTypes.SUBSCRIBE_SUCCESS: {
      return state.merge({
        isPaid: true,
        modalIsOpen: false,
        ssoCardData: action.payload.sso_card_data,
      });
    }
    case actionTypes.SUBSCRIBE_FAILURE: {
      return state.merge({ modalIsSubmitting: false });
    }
    default:
      return state;
  }
};
