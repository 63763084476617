const mtcEnforcementModalSeen = 'mtcEnforcementModalSeen';

export const mtcEnforcementModalShown = () =>
  window.localStorage.getItem(mtcEnforcementModalSeen);

export const setMtcEnforcementModalShown = () =>
  window.localStorage.setItem(mtcEnforcementModalSeen, true);

export const removeMtcEnforcementModalShown = () =>
  window.localStorage.removeItem(mtcEnforcementModalSeen);
