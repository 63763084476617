import { omit } from 'util/objectMethods';
import { AMPLITUDE_PROPERTY_EXCLUDE_LIST } from 'util/trackingModules/constants';

import { formatAmplitudeData } from './helpers';

const waitCommandBar = () =>
  new Promise(res => setTimeout(() => res(false), 30000));

async function sendEvent({
  properties,
  event_action,
  event_name,
  product_area,
  event_category,
  ...otherCoreAttributes
}) {
  const payload = {
    ...formatAmplitudeData({
      event_name,
      product_area,
      event_category,
      properties,
    }),
    ...omit(otherCoreAttributes, AMPLITUDE_PROPERTY_EXCLUDE_LIST),
  };

  if (!window.CommandBar && !(await waitCommandBar())) return;

  // This won't fire any requests to CommandBar, it talks to the SDK
  // and stores the event in memory. Eventually the SDK will talk to
  // CommandBar via http request to load the nudge.
  if (event_action) window.CommandBar.trackEvent(event_action, payload);
}

export default {
  sendEvent,
};
