import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { toI18n } from 'util/i18n';

import {
  getListData,
  ownerAddEmployee,
  updateLocationSettings,
  updateUserSettings,
} from './actions';
import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  getListData: {
    isPending: false,
    error: {},
    success: {},
  },
  ownerAddEmployee: {
    isPending: false,
    error: {},
    success: {},
  },
  updateLocationSettings: {
    isPending: false,
    error: {},
    success: {},
  },
  updateUserSettings: {
    isPending: false,
    error: {},
    success: {},
  },
  snackbarMessage: '',
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSnackbarMessage: (state, { payload }) =>
      state.set('snackbarMessage', payload),
    resetSnackbarMessage: state => state.set('snackbarMessage', ''),
  },
  extraReducers: {
    [getListData.pending]: state =>
      state.mergeDeep({
        getListData: {
          isPending: true,
          success: {},
          error: {},
        },
      }),
    [getListData.fulfilled]: (state, { payload }) =>
      state.mergeDeep({
        getListData: {
          isPending: false,
          success: {
            listData: payload.list,
            showSMSColumn: payload.show_sms_column,
            currentLocation: payload.currentLocation,
            isQsgRetrial: payload.qsg_retrial,
            nextStep: payload.next_task,
            showTooltipFirstLand: payload.show_get_help_first_land_tooltip,
          },
          error: {},
        },
      }),
    [getListData.rejected]: (state, { payload }) =>
      state.mergeDeep({
        getListData: {
          isPending: false,
          success: {},
          error: payload,
        },
      }),
    [ownerAddEmployee.pending]: state =>
      state.mergeDeep({
        ownerAddEmployee: {
          isPending: true,
          success: {},
          error: {},
        },
      }),
    [ownerAddEmployee.fulfilled]: state =>
      state.mergeDeep({
        ownerAddEmployee: {
          isPending: false,
          success: {
            snackbarMessage: '',
          },
          error: {},
        },
        snackbarMessage: toI18n(
          'quick_start_guide.guide_cards.invite_teammate.snackbar_success_text'
        ),
      }),
    [ownerAddEmployee.rejected]: (state, { payload }) =>
      state.mergeDeep({
        ownerAddEmployee: {
          isPending: false,
          success: {},
          error: payload,
        },
      }),
    [updateLocationSettings.pending]: state =>
      state.mergeDeep({
        updateLocationSettings: {
          isPending: true,
          success: {},
          error: {},
        },
      }),
    [updateLocationSettings.fulfilled]: (state, { payload }) =>
      state.mergeDeep({
        updateLocationSettings: {
          isPending: false,
          success: payload,
          error: {},
        },
      }),
    [updateLocationSettings.rejected]: (state, { payload }) =>
      state.mergeDeep({
        updateLocationSettings: {
          isPending: false,
          success: {},
          error: payload,
        },
      }),
    [updateUserSettings.pending]: state =>
      state.mergeDeep({
        updateUserSettings: {
          isPending: true,
          success: {},
          error: {},
        },
      }),
    [updateUserSettings.fulfilled]: (state, { payload }) =>
      state.mergeDeep({
        updateUserSettings: {
          isPending: false,
          success: payload,
          error: {},
        },
      }),
    [updateUserSettings.rejected]: (state, { payload }) =>
      state.mergeDeep({
        updateUserSettings: {
          isPending: false,
          success: {},
          error: payload,
        },
      }),
  },
});

export const { actions, reducer } = slice;
