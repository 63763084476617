import Icon from 'react-icon-base';

const ProductivityOutlined = props => (
  <Icon
    viewBox="0 0 23 22"
    fill="transparent"
    style={{ color: props.color || '#605F56' }}
    {...props}
  >
    <path
      d="M19.8344 4.5929C20.3422 6.62415 19.7172 8.81165 18.2328 10.296C16.7484 11.7804 14.639 12.3663 12.6078 11.8976L5.45935 19.046C4.83435 19.671 4.01404 19.9835 3.19373 19.9835C2.37341 19.9835 1.47498 19.632 0.928101 19.046C0.342163 18.421 0.0296631 17.6007 0.0296631 16.7804C0.0296631 15.9601 0.342163 15.1398 0.928101 14.5148L8.1156 7.36633C7.64685 5.33508 8.23279 3.22571 9.71716 1.7804C11.2406 0.256958 13.35 -0.32898 15.4203 0.178833C15.9281 0.29602 16.2797 0.686645 16.4359 1.1554C16.5531 1.62415 16.4359 2.13196 16.0844 2.48352L13.7797 4.74915L13.975 6.03821L15.264 6.23352L17.5687 3.92883C17.8812 3.61633 18.389 3.46008 18.8969 3.57727C19.3656 3.73352 19.7562 4.08508 19.8344 4.5929ZM16.9047 8.9679C17.725 8.18665 18.1547 7.0929 18.1547 5.99915L15.889 8.26477L12.3734 7.63977L11.7875 4.12415L13.975 1.85852H13.9359C12.8812 1.85852 11.8265 2.28821 11.0453 3.10852C9.91248 4.20227 9.56091 5.8429 10.1469 7.36633L10.3031 7.91321L2.33435 15.8429C2.09998 16.1163 1.94373 16.4288 1.94373 16.7804C1.94373 17.171 2.09998 17.4835 2.33435 17.7179C2.8031 18.2257 3.70154 18.2257 4.17029 17.7179L12.139 9.78821L12.6859 9.98352C14.1703 10.4913 15.8109 10.1007 16.9047 8.9679ZM3.4281 15.921C3.77966 15.921 4.0531 16.2335 4.01404 16.546C4.01404 16.8585 3.7406 17.171 3.4281 17.171C3.1156 17.171 2.84216 16.8585 2.84216 16.546C2.84216 16.1945 3.07654 15.921 3.4281 15.921Z"
      fill="currentColor"
    />
  </Icon>
);

export default ProductivityOutlined;
