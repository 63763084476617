import React from 'react';
import { ErrorMessage } from 'formik';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('FormikErrorMessage');

interface Props {
  name: string;
  fs?: number;
  withoutIcon?: boolean;
  [x: string]: unknown;
}
const FormikErrorMessage: React.FC<Props> = ({
  name,
  children,
  fs,
  withoutIcon,
  ...boxProps
}) => (
  <ErrorMessage name={name}>
    {msg => (
      <Box className={cx()} mt4 {...boxProps}>
        {children || (
          <Text red fs={fs || 14}>
            {!withoutIcon && <Icon color="red" type="infoCircle" />} {msg}
          </Text>
        )}
      </Box>
    )}
  </ErrorMessage>
);

export default FormikErrorMessage;
