import { createSelector } from 'reselect';

import {
  getCurrentUserIsManagerial,
  getCurrentUserIsOwner,
} from 'selectors/session';

export const getShowDownloadAppWidgetInMyWeek = createSelector(
  state => getCurrentUserIsOwner(state),
  state => getCurrentUserIsManagerial(state),
  (userIsOwner, userIsManager) => !userIsManager && !userIsOwner
);
