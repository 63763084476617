import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import * as notificationsActions from 'actions/notifications';

import { buildGetDrawerOpen } from 'selectors/drawers';

import { NOTIFICATIONS_DRAWER_KEY } from 'features/drawers/keys';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@connect(
  (state, props) => ({
    notificationShown: buildGetDrawerOpen(NOTIFICATIONS_DRAWER_KEY)(
      state,
      props
    ),
  }),
  {
    onToggleNotifications: notificationsActions.toggleNotificationsShown,
  }
)
@withNavItemData('alerts')
@cxHelpers('NotificationsNavItem')
export default class NotificationsNavItem extends PureComponent {
  static propTypes = {
    alertsExists: PropTypes.bool.isRequired,
    onToggleNotifications: PropTypes.func.isRequired,
  };

  handleToggle = () => {
    trackUxEvent({
      actionType: TRACK_ACTION_TYPES.CLICK,
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.ALERT_ICON_CLICKED,
    });

    this.props.onToggleNotifications();
  };

  render() {
    return (
      <Box>
        <NavItem
          className={this.cx({}, 'heap--navbar-15')}
          onClick={this.handleToggle}
          indicator={this.props.alertsExists}
          persistMobileNavOnClick
          active={this.props.notificationShown}
        >
          {this.props.newHeader ? (
            <Text fs24>
              <FontAwesomeIcon icon={faBell} color="white" />
            </Text>
          ) : (
            <Icon size={16} type="bell" />
          )}
        </NavItem>
      </Box>
    );
  }
}
