import { Set } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'actions/tiers';

const INITIAL_STATES = {
  isFetching: Set(),
};

const isFetching = (state = INITIAL_STATES.isFetching, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TIERS_DATA_REQUEST:
      return state.add(action.meta.requestId);
    case actionTypes.FETCH_TIERS_DATA_SUCCESS:
    case actionTypes.FETCH_TIERS_DATA_FAILURE:
      return state.remove(action.meta.requestId);

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
});
