import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON } from 'api/fetch';
import { pollWorkersStatus } from 'api/workerStatus';
import { fromJS, List } from 'immutable';
import queryString from 'query-string';

import { fromJSOrdered } from 'features/dashboard/util';
import { submitTimecardData } from 'features/timesheets/TimesheetsPage/TimecardModal/slices';

import { momentInZone } from 'util/dateTime';
import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';

const DEFAULT_STATE = {
  isLoading: false,
  overtimeFetched: true,
  openingTime: null,
  closingTime: null,
  useCase: 'both',
  shifts: [],
  dirtyShifts: new List(),
};

const updateShiftsData = (state, action) => {
  let newState = state.set('openingTime', action.payload.opening_time);
  newState = newState.set('closingTime', action.payload.closing_time);
  newState = newState.set('useCase', action.payload.use_case);

  newState = newState.set('shifts', fromJSOrdered(action.payload.shifts));
  newState = newState.set('overtimeFetched', false);
  newState = newState.set('updatedAt', momentInZone().format('h:mm A'));
  return newState.set('isLoading', false);
};

const updateShiftData = (state, action) => {
  const shift = action.payload;
  const index = state.get('shifts').findIndex(s => s.get('id') === shift.id);
  const shiftState = state.getIn(['shifts', index]);

  if (!shiftState) return state;
  return state.setIn(['shifts', index], fromJS(shift));
};

const updateShiftIssue = (state, issue) => {
  const index = state
    .get('shifts')
    .findIndex(s => s.get('id') === issue.shift_id);

  const overtimeIssue = fromJSOrdered(issue.issue);

  return state.setIn(
    ['shifts', index, 'issues'],
    state.getIn(['shifts', index, 'issues']).unshift(overtimeIssue)
  );
};

const clearDirtyShifts = state => state.set('dirtyShifts', new List());

export const fetchShiftsData = createAsyncThunk(
  'shiftsWidget/fetchShiftsData',
  () => fetchJSON(`/dashboard/shifts_widget_data`)
);

export const fetchShiftData = createAsyncThunk(
  '/dashboard/shifts_widget_shift',
  id =>
    fetchJSON(`/dashboard/shifts_widget_shift?${queryString.stringify({ id })}`)
);

export const fetchOvertimeData = createAsyncThunk(
  'shiftsWidget/fetchOvertimeData',
  () => {
    let cacheKey;
    return fetchJSON(routes.dashboardGenerateOvertimeDataRoute())
      .then(({ cache_key, job_ids }) => {
        cacheKey = cache_key;
        return pollWorkersStatus(job_ids).catch(() => {
          flashError(toI18n('errors.generic'));
        });
      })
      .then(() =>
        fetchJSON(
          routes.dashboardFetchOvertimeDataRoute({
            cache_key: cacheKey,
          })
        )
      );
  }
);

export const fetchAvailableJobs = createAsyncThunk(
  'shiftsWidget/fetchAvailableJobs',
  () => fetchJSON('/dashboard/available_jobs')
);

const shiftsWidgetSlice = createSlice({
  name: 'shiftsWidget',
  initialState: fromJS(DEFAULT_STATE),
  reducers: {
    updateShiftsData,
    updateShiftIssue,
    clearDirtyShifts,
  },
  extraReducers: {
    [fetchShiftsData.pending]: state => state.merge({ isLoading: true }),
    [fetchShiftsData.rejected]: state => state.merge({ isLoading: false }),
    [fetchShiftsData.fulfilled]: updateShiftsData,
    [fetchShiftData.fulfilled]: updateShiftData,
    [fetchOvertimeData.pending]: state =>
      state.merge({ overtimeFetched: false }),
    [fetchOvertimeData.rejected]: state =>
      state.merge({ overtimeFetched: true }),
    [fetchOvertimeData.fulfilled]: (state, action) => {
      let newState = state.merge({ overtimeFetched: true });

      action.payload.forEach(issue => {
        const index = state
          .get('shifts')
          .findIndex(s => s.get('id') === issue.shift_id);

        const overtimeIssue = fromJSOrdered(issue.issue);

        newState = newState.setIn(
          ['shifts', index, 'issues'],
          state.getIn(['shifts', index, 'issues']).unshift(overtimeIssue)
        );
      });

      return newState;
    },
    [submitTimecardData.fulfilled]: (state, action) => {
      const shift = action.payload;
      const dirtyShifts = state.get('dirtyShifts').push(shift.id);
      return state.set('dirtyShifts', dirtyShifts);
    },
  },
});

export const shiftsWidgetReducer = shiftsWidgetSlice.reducer;
export const shiftsWidgetActions = shiftsWidgetSlice.actions;
