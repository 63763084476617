import React from 'react';
import Icon from 'react-icon-base';

const NotSent = props => (
  <Icon viewBox="0 0 20 20" {...props}>
    <mask id="a" fill="#fff">
      <path
        fillRule="evenodd"
        d="M10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z"
        clipRule="evenodd"
      />
    </mask>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10 20C4.486 20 0 15.514 0 10S4.486 0 10 0s10 4.486 10 10-4.486 10-10 10z"
      clipRule="evenodd"
    />
    <path
      fill="#CBCDCF"
      fillRule="evenodd"
      d="M10 19c-4.962 0-9-4.039-9-9h-2c0 6.066 4.934 11 11 11v-2zm-9-9c0-4.961 4.038-9 9-9v-2C3.933-1-1 3.934-1 10h2zm9-9c4.962 0 9 4.039 9 9h2c0-6.066-4.933-11-11-11v2zm9 9c0 4.961-4.038 9-9 9v2c6.067 0 11-4.934 11-11h-2z"
      mask="url(#a)"
    />
    <path
      fill="#DE5541"
      fillRule="evenodd"
      d="M10.708 12H9.271L9 5h2l-.292 7zM10 15c.542 0 1-.456 1-.996C11 13.46 10.542 13 10 13s-1 .46-1 1.004c0 .54.458.996 1 .996z"
      clipRule="evenodd"
    />
  </Icon>
);

export default NotSent;
