import { useCallback, useEffect, useMemo, useState } from 'react';
import ChipBase from '@mui/material/Chip';
import withCx, { CxProps } from 'fe-core/util/withCx';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export type CallbackParamType = { value: string | number };
export type ChipSize = 'small' | 'medium';

export interface ChipProps {
  children: string;
  id?: string;
  dataTestId?: string;
  onClick?: (event: CallbackParamType) => void;
  selected?: boolean;
  size?: ChipSize;
  value: string | number;
  uxElement?: string;
  avatar?: React.ReactElement;
}

const Chip = ({
  children,
  id,
  dataTestId,
  cx,
  onClick,
  selected = false,
  size = 'small',
  value,
  uxElement,
  avatar,
}: ChipProps & CxProps) => {
  const [isPressed, setIsPressed] = useState(false);
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const trackUx = useTrackUx(
    useMemo(
      () => ({
        chipText: children,
        element: uxElement,
      }),
      [children, uxElement]
    ) as any
  );

  const handleClick = useCallback(() => {
    if (uxElement)
      trackUx(EVENT_ACTIONS.CHIP_CLICKED, TRACK_ACTION_TYPES.CLICK, {
        isSelected: !isSelected,
      });

    onClick?.({ value });
  }, [isSelected, onClick, trackUx, uxElement, value]);

  const handleMouseDown = useCallback(() => setIsPressed(true), []);
  const handleMouseUp = useCallback(() => setIsPressed(false), []);

  return (
    <ChipBase
      clickable
      onClick={handleClick}
      id={id}
      data-testid={dataTestId}
      component="button"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      label={children}
      variant="filled"
      title={children}
      avatar={avatar}
      className={cx(
        { selected, unselected: !selected, [size]: true },
        { active: isPressed }
      )}
    />
  );
};

export default withCx<ChipProps>('FDBChip')(Chip);
