import { isEmpty } from 'lodash';
import { array, boolean, date, number, object, string } from 'yup';

import { toI18n } from 'util/i18n';

import {
  CONTRACTOR_CLASSIFICATIONS,
  CONTRACTOR_TYPES,
  EMPLOYEE_CLASSIFICATIONS,
} from '../../constants';

const I18N_PATH = 'new_team_drawer.add_team_form';

export const I18N_ERROR_PATH = `${I18N_PATH}.errors`;
export const I18N_LABEL_PATH = `${I18N_PATH}.labels`;
export const I18N_TITLE_PATH = `${I18N_PATH}.titles`;
export const I18N_IMPORT_CONTAINER_PATH = `${I18N_PATH}.import_container`;
export const I18N_JOB_DETAILS_PATH = `${I18N_PATH}.job_details`;
export const I18N_PAYROLL_PATH = `${I18N_PATH}.payroll`;
export const I18N_ONBOARDING_PATH = `${I18N_PATH}.onboarding`;
export const I18N_ONBOARDING_AIO_PATH = `${I18N_PATH}.onboarding.aio`;
export const I18N_ONBOARDING_PAYROLL_PATH = `${I18N_PATH}.onboarding.payroll`;
export const GM = 'general manager';
export const MANAGER = 'manager';

export const I18N_ADD_NEW_ROLE_MODAL_PATH = `${I18N_PATH}.add_new_role_modal`;

export const ADD_NEW_ROLE = 'addNewRole';

export const ADD_EMPLOYEE_FORM_SCHEMA = object().shape({
  firstName: string().required(toI18n(`${I18N_ERROR_PATH}.first_name`)),
  lastName: string().when('includedInPayroll', {
    is: true,
    then: string().required(toI18n(`${I18N_ERROR_PATH}.last_name`)),
  }),
  email: string()
    .optional()
    .email(toI18n(`${I18N_ERROR_PATH}.email`)),
  phone: string()
    .optional()
    .matches(/^\d{10}$/, toI18n(`${I18N_ERROR_PATH}.phone`)),
  sendSms: boolean(),
  defaultRoleName: string().optional(),
  wageRate: string().when('includedInPayroll', {
    is: true,
    then: string().required(toI18n(`${I18N_ERROR_PATH}.required`)),
  }),
  wageType: number().optional(),
  wageAttributes: array().optional(),
  onboarding: string().optional(),
  jobTaxClassification: string()
    .when('includedInPayroll', {
      is: true,
      then: string().required(toI18n(`${I18N_ERROR_PATH}.required`)),
    })
    .nullable(),
  level: string().required(),
  hireDate: date()
    .typeError(toI18n(`${I18N_ERROR_PATH}.invalid_date`))
    .when(['includedInPayroll', 'jobTaxClassification'], {
      is: (includedInPayroll, jobTaxClassification) =>
        includedInPayroll === true && jobTaxClassification === 'w2-employee',
      then: date()
        .typeError(toI18n(`${I18N_ERROR_PATH}.invalid_date`))
        .required(toI18n(`${I18N_ERROR_PATH}.required`)),
    }),
});

export const JOB_TAX_CLASSIFICATIONS = [
  {
    taxClassification: EMPLOYEE_CLASSIFICATIONS.tax_classification,
    classification: EMPLOYEE_CLASSIFICATIONS.classification,
    type: null,
    label: 'W-2 Employee',
    value: 'w2-employee',
  },
  {
    taxClassification: CONTRACTOR_CLASSIFICATIONS.tax_classification,
    classification: CONTRACTOR_CLASSIFICATIONS.classification,
    type: CONTRACTOR_TYPES.INDIVIDUAL,
    label: '1099 Contractor, Individual',
    value: '1099-individual',
  },
  {
    taxClassification: CONTRACTOR_CLASSIFICATIONS.tax_classification,
    classification: CONTRACTOR_CLASSIFICATIONS.classification,
    type: CONTRACTOR_TYPES.BUSINESS,
    label: '1099 Contractor, Business',
    value: '1099-business',
  },
];

export const PACKET_TYPE_LABEL = {
  w2: toI18n(`${I18N_ONBOARDING_PATH}.packet_type.employee`),
  1099: toI18n(`${I18N_ONBOARDING_PATH}.packet_type.contractor`),
};

export const US_TAX_CLASSIFICATION_OPTIONS = JOB_TAX_CLASSIFICATIONS.map(
  tcOption => ({
    label: tcOption.label,
    value: tcOption.value,
  })
);

export const TAX_CLASSIFICATION_OPTIONS = JOB_TAX_CLASSIFICATIONS.map(
  tcOption => ({
    label: tcOption.label.split(' ').slice(1).join(' '),
    value: tcOption.value,
  })
);

export const WAGE_TYPES = {
  hourly: 0,
  annual: 1,
};

export const WAGE_TYPE_OPTIONS = [
  {
    value: 0,
    label: toI18n(`${I18N_JOB_DETAILS_PATH}.hourly`),
  },
  {
    value: 1,
    label: toI18n(`${I18N_JOB_DETAILS_PATH}.annually`),
  },
];

export const ONBOARDING_OPTIONS = {
  NOTHING: 'no_packet_no_invite',
  NOTHING_RIGHT_NOW: 'no_packet_no_invite_no_manual',
  INVITE_ONLY: 'invite_only',
  INVITE_AND_PACKET: 'invite_and_packet',
};

const parseCurrentTaxClassification = currentTeamMember => {
  if (isEmpty(currentTeamMember?.tax_classification)) return null;

  if (
    EMPLOYEE_CLASSIFICATIONS.tax_classification ===
    currentTeamMember.tax_classification
  )
    return `${currentTeamMember.tax_classification}-${EMPLOYEE_CLASSIFICATIONS.classification}`;

  return `${currentTeamMember.tax_classification}-${
    currentTeamMember.contractor_type || 'individual'
  }`;
};

const currentWageType = currentTeamMember => {
  if (currentTeamMember.wage_type === WAGE_TYPES.annual) return 1;
  if (currentTeamMember.wage_type === WAGE_TYPES.hourly) return 0;
  return WAGE_TYPES.hourly; // default
};

export const initialValues = (
  isPayrollEnabled,
  isDefaultManager,
  currentTeamMember = {}
) => ({
  firstName:
    currentTeamMember.legal_first_name || currentTeamMember.first_name || '',
  lastName:
    currentTeamMember.legal_last_name || currentTeamMember.last_name || '',
  namesMatchLegalNames: currentTeamMember.namesMatchLegalNames || false,
  email: currentTeamMember.email || '',
  phone: currentTeamMember.phone || '',
  sendSms: true,
  jobTaxClassification: parseCurrentTaxClassification(currentTeamMember),
  level: isDefaultManager
    ? 'Manager'
    : currentTeamMember.level ||
      EMPLOYEE_CLASSIFICATIONS.classification /** ACCESS_LEVELS */,
  defaultRoleName: currentTeamMember.role_name || '',
  wageRate: currentTeamMember.wage_rate || '',
  wageType: currentWageType(currentTeamMember),
  wageAttributes: [],
  onboarding: ONBOARDING_OPTIONS.INVITE_ONLY,
  includedInPayroll: currentTeamMember.included_in_payroll || isPayrollEnabled,
  hireDate: currentTeamMember.hire_date || '',
});

export const INITIAL_VALUES_NEW_ROLE = {
  newRoleName: '',
};

export const INPUT_ERROR_TYPE = {
  ACTIVE: 'active',
  RESEND_INVITE: 'resend_invite',
  REHIRE: 'rehire',
};

export const INVITE_STATUS = {
  MERGE_REQUEST_PENDING: 'merge_request_pending',
  MERGE_REQUEST_DECLINED: 'merge_request_declined',
  PENDING: 'pending',
};

export const SCROLL_BEHAVIOR = {
  behavior: 'smooth',
  block: 'center',
};

export const FORM_WITH_STEPS_BOX_STYLE = {
  bgcolor: 'mono0',
  bradiusm: true,
  bcolor: 'mono300',
  bw: 1,
  p: 20,
};
