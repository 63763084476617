import colors from '../colors';

export const paginationItemOverrides = {
  MuiPaginationItem: {
    styleOverrides: {
      root: {
        '&.FDBPaginationItem': {
          fontSize: '12px',
          fontWeight: 600,
          color: colors.mono500,
          '& span': {
            display: 'none',
          },
          '&:hover': {
            backgroundColor: colors.purple100,
            border: 0,
            color: colors.mono500,
          },
          '&.Mui-selected': {
            backgroundColor: colors.purple100,
            color: colors.purple500,
            '&:hover': { backgroundColor: colors.purple100 },
          },
        },
        icon: {
          path: {
            width: '15px',
            height: '9.5px',
            fill: `${colors.purple500} !important`,
          },
        },
      },
    },
  },
};
