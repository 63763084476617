import React from 'react';
import Icon from 'react-icon-base';

const Message = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M9.633 5.254H3.166c-.203 0-.367.181-.367.404 0 .223.164.404.367.404h6.467c.203 0 .366-.18.366-.404 0-.223-.163-.404-.366-.404zM9.511 7.275H3.166c-.203 0-.367.181-.367.405 0 .223.164.404.367.404H9.51c.203 0 .366-.181.366-.404 0-.224-.163-.405-.366-.405zM6.433 9.297H3.165c-.202 0-.366.181-.366.404 0 .223.164.404.366.404h3.268c.202 0 .366-.18.366-.404 0-.223-.164-.404-.366-.404z"
      clipRule="evenodd"
    />
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M15.227 11.167c0 .173-.18.178-.18.178a.156.156 0 0 1-.117-.054L12.8 9.32V5.958c0-1.941-1.304-3.533-3.224-3.533H4.608A2.715 2.715 0 0 1 7.092.78h5.417c1.499 0 2.718 1.232 2.718 2.746v7.64zm-3.2-1.782c0 1.511-1.22 2.741-2.718 2.741h-4.61c-.702 0-1.394.29-1.9.794L1.09 14.506l-.01.01-.01.01a.157.157 0 0 1-.116.053c-.002 0-.18-.005-.18-.178V5.958c0-1.517 1.219-2.751 2.717-2.751h5.818c1.498 0 2.718 1.234 2.718 2.751v3.427zM12.509 0H7.09C5.552 0 4.241 1.021 3.78 2.425H3.49C1.571 2.425 0 4.017 0 5.958v8.443c0 .577.468.959.954.959.233 0 .47-.087.664-.282l1.718-1.595a1.922 1.922 0 0 1 1.364-.575h4.609c1.617 0 2.982-1.124 3.374-2.637l1.7 1.573c.193.195.43.282.663.282a.951.951 0 0 0 .954-.959V3.528C16 1.588 14.43 0 12.509 0z"
      clipRule="evenodd"
    />
  </Icon>
);

export default Message;
