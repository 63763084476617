import React, { useMemo } from 'react';
import FDBButton from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import useGoToManagePlanPage from 'features/productGrowth/hooks/useGoToManagePlanPage';

import Button from 'components/clickables/Button';

import { cxHelpers } from 'util/className';
const { cx } = cxHelpers('GoToManagePlanPageButton');

export const GoToManagePlanPageButton = ({
  baseComponent,
  loadFullPage,
  focusedFeature,
  planName,
  pageUrl,
  onClick,
  children,
  ...props
}) => {
  const Component = useMemo(
    () => (baseComponent === 'designbase' ? FDBButton : Button),
    [baseComponent]
  );

  const { extraProps, handleOnClick } = useGoToManagePlanPage({
    loadFullPage,
    pageUrl,
    onClick,
    planName,
    focusedFeature,
    linkTo: props.linkTo,
  });

  return (
    <Component
      onClick={handleOnClick}
      {...props}
      className={cx()}
      {...extraProps}
    >
      {children}
    </Component>
  );
};

GoToManagePlanPageButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  pageUrl: PropTypes.string,
  focusedFeature: PropTypes.string,
  planName: PropTypes.string,
  loadFullPage: PropTypes.bool,
  baseComponent: PropTypes.string,
};

GoToManagePlanPageButton.defaultProps = {
  baseComponent: 'clickables',
  loadFullPage: false,
};

export default GoToManagePlanPageButton;
