/**
 * Determines if the current window is embedded within another window.
 *
 * This function checks two conditions to determine if the current window is embedded:
 * 1. If an element with the ID 'storybook-preview-iframe' exists in the document.
 * 2. If the environment variable `STORYBOOK` is set.
 *
 * If either of these conditions is true,
 * the function returns `false`, indicating that the window is not embedded.
 * Otherwise, it checks if the current window (`window.self`) is different
 * from the topmost window (`window.top`).
 * If they are different, it returns `true`, indicating that the window is embedded.
 *
 * @returns {boolean} - `true` if the current window is embedded, `false` otherwise.
 */
const getIsEmbedded = (): boolean => {
  const storybookElement = document.getElementById('storybook-preview-iframe');
  const storybookEnv =
    (typeof process !== 'undefined' && process.env.STORYBOOK) ?? false;

  if (storybookElement !== null || storybookEnv) {
    return false;
  }

  return window.self !== window.top;
};

export default getIsEmbedded;
