import React from 'react';
import Icon from 'react-icon-base';

const Phone = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <path d="M14.808 12.504l-1.269 1.947a.965.965 0 0 1-.851.434c-.701-.038-1.269-.113-1.629-.208-2.063-.529-4.222-1.833-6.04-3.666-1.836-1.833-3.123-3.968-3.672-6.028-.095-.359-.17-.925-.208-1.625a.946.946 0 0 1 .416-.85l1.931-1.285a.459.459 0 0 1 .247-.075.24.24 0 0 1 .113.018.434.434 0 0 1 .284.227l1.571 3.024a.46.46 0 0 1-.113.548L3.789 6.363l.228.416C4.603 7.875 5.17 8.763 6.25 9.82c1.04 1.04 1.95 1.625 3.01 2.192l.416.227 1.42-1.814a.438.438 0 0 1 .55-.114l3.047 1.569c.114.056.19.15.228.283-.02.113-.039.246-.114.34m.36-1.625L12.12 9.31a1.588 1.588 0 0 0-1.97.434l-.814 1.04a9.96 9.96 0 0 1-2.31-1.777 10.294 10.294 0 0 1-1.798-2.362l1.041-.812a1.599 1.599 0 0 0 .436-1.965L5.133.845A1.57 1.57 0 0 0 4.13.052a1.584 1.584 0 0 0-1.269.207L.931 1.544a2.09 2.09 0 0 0-.928 1.871c.057.794.132 1.398.246 1.833.587 2.268 1.988 4.592 3.957 6.557 1.969 1.965 4.297 3.364 6.57 3.95.435.113 1.06.188 1.836.245h.132c.7 0 1.363-.359 1.742-.945l1.269-1.946c.246-.378.302-.832.189-1.247a1.553 1.553 0 0 0-.777-.983" />
    </g>
  </Icon>
);

export default Phone;
