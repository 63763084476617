import React from 'react';
import Icon from 'react-icon-base';

const CircleCheck = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M11.8495 21C16.7578 21 20.7367 16.9706 20.7367 12C20.7367 7.02944 16.7578 3 11.8495 3C6.94131 3 2.9624 7.02944 2.9624 12C2.9624 16.9706 6.94131 21 11.8495 21Z"
      stroke="#8A5EA6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.9229 9.75L10.4888 15L7.77637 12.375"
      stroke="#8A5EA6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
);

export default CircleCheck;
