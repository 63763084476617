import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { dismissWageRateAlert } from 'actions/notifications';

import { toI18n } from 'util/i18n';

import AlertsListItem from '../AlertsListItem';

@connect(() => ({}), { dismissWageRateAlert })
export default class MissedWageRate extends PureComponent {
  static propTypes = {
    dismissWageRateAlert: PropTypes.func.isRequired,
  };

  handleEdit = () => {
    this.props.closeDrawer();
    const url = `/team/${this.props.item.get(
      'user_id'
    )}/job_details?wageAlert=true`;
    return window.Homebase.RailsReactBridge.navigateToReactView(url);
  };

  handleDismiss = () => this.props.dismissWageRateAlert(this.props.item);

  formattedFullName() {
    const { item } = this.props;
    return `${item.get('first_name')} ${item.get('last_name')}`;
  }

  render() {
    return (
      <AlertsListItem
        desc={toI18n('notifications.alerts.wage_rate.desc', {
          props: { name: this.formattedFullName() },
        })}
        onEdit={this.handleEdit}
        onDismiss={this.handleDismiss}
        {...this.props}
      />
    );
  }
}
