import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { fromJS, OrderedMap } from 'immutable';
import { isEqual, isFunction, partial, uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import {
  getCurrentLocationPartner,
  getRoundClockInsIncrements,
} from 'selectors/session';
import * as settingsSelectors from 'selectors/settings';

import { omit, pick } from 'util/objectMethods';
import { trackKinesisEvent, trackUxEvent } from 'util/tracking';

const NEW_ID = '__NEW__';

export const defaultGetInitialState = (props, formKeys, fieldArrayFormKeys) => {
  const newState = {};
  formKeys.forEach(key => {
    newState[key] = props.location.get(key);
  });
  fieldArrayFormKeys.forEach(key => {
    newState[key] = props.location
      .get(key)
      .reduce(
        (fields, field) => fields.set(field.get('id').toString(), field),
        OrderedMap()
      );
  });
  return newState;
};

export const defaultGetFormData = (
  state,
  _props,
  formKeys,
  fieldArrayFormKeys
) => {
  const basicData = pick(state, formKeys);
  const fieldArrayData = {};

  fieldArrayFormKeys.forEach(key => {
    const models = state[key]
      .toList()
      .map(model =>
        model.get('id').toString().startsWith(NEW_ID)
          ? model.delete('id')
          : model
      );

    fieldArrayData[key] = models.toJS();
  });

  return { ...basicData, ...fieldArrayData };
};

export default ({
    formKeys,
    fieldArrayFormKeys = [],
    getInitialState = defaultGetInitialState,
    getFormData = defaultGetFormData,
  }) =>
  WrappedComponent => {
    @connect((state, props) => ({
      lastSaved: settingsSelectors.getLastSaved(state, props),
      partner: getCurrentLocationPartner(state, props),
      roundClockInsIncrements: getRoundClockInsIncrements(state, props),
    }))
    class WithLocationSettingsForm extends Component {
      static propTypes = {
        lastSaved: PropTypes.string,
        location: ImmutablePropTypes.map.isRequired,
        partner: PropTypes.string,
      };

      state = this.getInitialState();

      getInitialState() {
        return {
          ...getInitialState(
            this.props,
            this.formKeys(),
            this.fieldArrayFormKeys()
          ),
          hasErrors: false,
        };
      }

      // TODO: https://joinhomebase.atlassian.net/browse/HIRING-441
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.lastSaved !== nextProps.lastSaved) {
          this.setState(
            getInitialState(
              nextProps,
              this.formKeys(),
              this.fieldArrayFormKeys()
            )
          );
        }
      }

      UNSAFE_componentWillUpdate(nextProps) {
        if (this.props.location && this.props.location !== nextProps.location) {
          this.setState(
            getInitialState(
              nextProps,
              this.formKeys(),
              this.fieldArrayFormKeys()
            )
          );
        }
      }

      setError = value => this.setState({ hasErrors: value });

      setCommonBreakType = (newState, model) => {
        const modelId = model.get('id').toString();
        const newRecord = modelId.startsWith(NEW_ID);

        if (newRecord) {
          let trackErrors;

          if (newState.isEmpty()) {
            trackErrors = true;
          } else {
            trackErrors = newState.first().get('track_errors');
          }

          model = model.set('track_errors', trackErrors);
        }

        newState = newState.set(modelId, model);

        // All toast breaks should have the same `track_errors` field
        newState = newState.map(mb =>
          mb.set('track_errors', model.get('track_errors'))
        );

        return newState;
      };

      formKeys() {
        if (this._formKeys) {
          return this._formKeys;
        }

        this._formKeys = isFunction(formKeys) ? formKeys(this.props) : formKeys;

        return this._formKeys;
      }

      fieldArrayFormKeys() {
        if (this._fieldArrayFormKeys) {
          return this._fieldArrayFormKeys;
        }

        this._fieldArrayFormKeys = isFunction(fieldArrayFormKeys)
          ? fieldArrayFormKeys(this.props)
          : fieldArrayFormKeys;

        return this._fieldArrayFormKeys;
      }

      handleChange = (name, value) => {
        // This is needed to support both the Select input and Designbase's Select
        const correctValue = value && value.target ? value.target.value : value;
        this.setState({ [name]: correctValue });
      };
      handleChangeMulti = newState => this.setState(newState);

      hasChanges = () =>
        !isEqual(this.state, this.getInitialState()) && !this.state.hasErrors;

      getChangedFields = () =>
        this.formKeys().filter(
          key => !isEqual(this.getInitialState()[key], this.state[key])
        );

      handleGetFormData = () =>
        getFormData(
          this.state,
          this.props,
          this.formKeys(),
          this.fieldArrayFormKeys()
        );

      handleSaveInFieldArray = (name, params) => {
        let model = fromJS(params);

        // Track new models by tagging them with a unique id prepended with
        // '__NEW__'. This will be removed by getFormData.
        if (!model.get('id')) {
          model = model.set('id', uniqueId(NEW_ID));
        }

        let newState = this.state[name];

        if (name === 'mandated_breaks' && this.props.partner === 'toast') {
          newState = this.setCommonBreakType(newState, model);
        } else {
          newState = newState.set(model.get('id').toString(), model);
        }

        this.handleChange(name, newState);
      };

      handleRemoveFromFieldArray = (name, model, archive) => {
        const id = model.get('id').toString();
        const key = archive ? 'archive' : '_destroy';
        let newModels;

        if (id.startsWith(NEW_ID)) {
          newModels = this.state[name].delete(id);
        } else {
          newModels = this.state[name].set(id, model.merge({ [key]: '1' }));
        }

        this.handleChange(name, newModels);
      };

      inputEventHandlers() {
        if (this._inputEventHandlers) {
          return this._inputEventHandlers;
        }

        this._inputEventHandlers = {};

        this.formKeys().forEach(key => {
          this._inputEventHandlers[key] = {
            onChange: partial(this.handleChange, key),
          };
        });

        this.fieldArrayFormKeys().forEach(key => {
          this._inputEventHandlers[key] = {
            onSave: partial(this.handleSaveInFieldArray, key),
            onRemove: partial(this.handleRemoveFromFieldArray, key),
          };
        });

        return this._inputEventHandlers;
      }

      handleChangeWithTracking = (
        name,
        onChange,
        trackEventName,
        uxEvent,
        value
      ) => {
        onChange(value);

        if (this.getInitialState()[name].toString() !== value) {
          if (trackEventName) {
            trackKinesisEvent('feature_usage', trackEventName);
          }
          if (uxEvent?.properties?.is_checked !== undefined) {
            trackUxEvent({
              ...uxEvent,
              properties: { ...uxEvent.properties, is_checked: value },
            });
            return;
          }
          if (uxEvent) {
            trackUxEvent({ ...uxEvent, properties: { value } });
          }
        }
      };

      handleGetInputProps = (name, options) => {
        const attrs = this.inputEventHandlers()[name];

        if (
          options &&
          (options.trackEventName || options.uxEvent) &&
          !attrs.alreadyChanged
        ) {
          attrs.onChange = partial(
            this.handleChangeWithTracking,
            name,
            attrs.onChange,
            options.trackEventName,
            options.uxEvent
          );
          attrs.alreadyChanged = true;
        }

        return {
          name,
          value: this.state[name],
          ...omit(attrs, 'alreadyChanged'),
        };
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            getChangedFields={this.getChangedFields}
            hasChanges={this.hasChanges()}
            formState={this.state}
            onChange={this.handleChange}
            onChangeMulti={this.handleChangeMulti}
            getFormData={this.handleGetFormData}
            onSaveInFieldArray={this.handleSaveInFieldArray}
            onRemoveFromFieldArray={this.handleRemoveFromFieldArray}
            getInputProps={this.handleGetInputProps}
            setError={this.setError}
          />
        );
      }
    }

    return WithLocationSettingsForm;
  };
