import React, { RefObject } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Button from 'fe-design-base/molecules/Button';

interface ApplySectionProps {
  onApply: () => void;
  onCancel: () => void;
  buttonRef: RefObject<HTMLButtonElement>;
  uxElement?: string;
}

export const ApplySection = ({
  onApply,
  onCancel,
  buttonRef,
  uxElement,
}: ApplySectionProps) => (
  <Box hcenter gap={10} btw={1} bcolor="mono300" p={12}>
    <Button
      ref={buttonRef}
      variant="tertiary"
      size="small"
      onClick={onCancel}
      uxElement={uxElement ? `${uxElement} cancel button` : undefined}
    >
      Cancel
    </Button>
    <Button
      size="small"
      onClick={onApply}
      uxElement={uxElement ? `${uxElement} apply button` : undefined}
    >
      Apply
    </Button>
  </Box>
);
