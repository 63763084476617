import windowLocation from 'fe-core/util/windowLocation';
import queryString from 'query-string';

let params = {
  fullName: '',
};
let utmParams = {};

export const storeParams = (hamlQueryParams = {}) => {
  const {
    email,
    fullname: fullName,
    phone,
    company_zip_code: companyZipCode,
    onboard_source: onboardSource,
    desired_tier_id,
    ...rest
  } = queryString.parse(windowLocation().search);
  const allQueryParams = { ...rest, ...hamlQueryParams };
  utmParams = Object.keys(allQueryParams)
    .filter(key => key.includes('utm_'))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: allQueryParams[key],
      }),
      {}
    );
  const { utm_campaign: utmCampaign, utm_source: utmSource } = utmParams;

  const arrivedViaReferralPartner =
    onboardSource === 'partnerstack' && !!utmSource;

  params = {
    email,
    fullName,
    phone,
    companyZipCode,
    onboardSource,
    utmCampaign,
    utmSource,
    arrivedViaReferralPartner,
    desired_tier_id,
  };
};

export const getParams = () => params;
export const getUtmParams = () => utmParams;

export const referralPayload = () => {
  const { utmCampaign, onboardSource, desired_tier_id } = getParams();
  return {
    onboard_source: onboardSource,
    utm_campaign: utmCampaign,
    desired_tier_id: desired_tier_id && parseInt(desired_tier_id, 10),
  };
};

const pattern = /^[A-z0-9]*((-)*[A-z0-9])*$/;

export const formattedUtmCampaign = utmCampaign =>
  // eslint-disable-next-line no-nested-ternary
  utmCampaign !== null
    ? typeof utmCampaign === 'string'
      ? utmCampaign
      : utmCampaign.find(val => pattern.exec(val))
    : utmCampaign;
