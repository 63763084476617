import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { partial } from 'lodash';

import * as navSelectors from 'selectors/nav';
import {
  getCurrentLocationId,
  getCurrentLocationName,
} from 'selectors/session';

import Box from 'components/Box';

import GenericMenu from './GenericMenu';

const getLocationsData = navSelectors.buildItemDataSelector('locationsMenu');

const Header = ({
  sizes,
  cxEl,
  collapsedState,
  collapseLeftNav,
  currentLocationId,
  currentLocationName,
  locationsData,
}) => {
  const [locationPickerActive, setLocationPickerActive] = useState(false);

  const handleLocationPickerClick = e => {
    e.preventDefault();
    setLocationPickerActive(!locationPickerActive);
  };

  const locations = locationsData?.get('locations') || [];

  const locationPickerSubItems = useMemo(() => {
    const handleChangeLocation = (id, e) => {
      e.preventDefault();
      window.Homebase.RailsReactBridge.changeLocation(id);
      setLocationPickerActive(false);
      collapseLeftNav();
    };

    return locations
      .filter(
        location =>
          location.get('id') !== currentLocationId &&
          !location.get('show_locked_state')
      )
      .map(
        location =>
          new Map([
            ['id', location.get('id')],
            ['name', location.get('name')],
            ['on_click', partial(handleChangeLocation, location.get('id'))],
          ])
      );
  }, [currentLocationId, locations, collapseLeftNav]);

  const locationPickerSecIcon = () =>
    locationPickerActive ? 'caretUp' : 'caretDown';

  const item = new Map([
    ['active', locationPickerActive],
    ['name', currentLocationName],
    ['sub_items', locationPickerSubItems],
    ['secondary_icon', locationPickerSecIcon()],
    ['on_click', handleLocationPickerClick],
  ]);

  return (
    <Box className={cxEl('header')}>
      <GenericMenu
        key={item.get('name')}
        item={item}
        sizes={sizes}
        cxEl={cxEl}
        collapsedState={collapsedState}
        collapseLeftNav={collapseLeftNav}
      />
    </Box>
  );
};

export default connect(state => ({
  currentLocationId: getCurrentLocationId(state),
  currentLocationName: getCurrentLocationName(state),
  locationsData: getLocationsData(state),
}))(Header);
