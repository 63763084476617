import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgGrid = ({
  alt = 'Grid Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M10.286 7.714a2.572 2.572 0 0 1-2.572 2.572H2.571A2.572 2.572 0 0 1 0 7.714V2.571A2.571 2.571 0 0 1 2.571 0h5.143a2.572 2.572 0 0 1 2.572 2.571v5.143Zm0 13.715A2.572 2.572 0 0 1 7.714 24H2.571A2.572 2.572 0 0 1 0 21.429v-5.143a2.572 2.572 0 0 1 2.571-2.572h5.143a2.572 2.572 0 0 1 2.572 2.572v5.143ZM13.714 2.57A2.572 2.572 0 0 1 16.286 0h5.143A2.572 2.572 0 0 1 24 2.571v5.143a2.572 2.572 0 0 1-2.571 2.572h-5.143a2.572 2.572 0 0 1-2.572-2.572V2.571ZM24 21.43A2.572 2.572 0 0 1 21.429 24h-5.143a2.572 2.572 0 0 1-2.572-2.571v-5.143a2.572 2.572 0 0 1 2.572-2.572h5.143A2.572 2.572 0 0 1 24 16.286v5.143Z"
    />
  </svg>
);
export default SvgGrid;
