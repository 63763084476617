import { createSelector } from 'reselect';

import { getCurrentLocationId } from 'selectors/session';

import { moment } from 'util/dateTime';
import { buildOptions } from 'util/hiring';
import { buildSortByDateDesc } from 'util/sort';

const buildHiringInterviewsSelector =
  (...path) =>
  state =>
    state.getIn(['hiring', 'interviews', ...path]);

export const getInterviews = buildHiringInterviewsSelector('interviews');
export const getIsFetching = buildHiringInterviewsSelector('isFetching');

const getFilterParams = (_state, { role, location, scheduledBy }) => ({
  role,
  location,
  scheduledBy,
});

const filterInterviewByParams = (
  interview,
  { role, location, scheduledBy },
  upcoming
) => {
  if (interview.get('upcoming') !== upcoming) {
    return false;
  }
  if (role && interview.get('role_name') !== role) {
    return false;
  }
  if (location && interview.get('location_name') !== location) {
    return false;
  }
  if (scheduledBy && interview.get('scheduled_by_short_name') !== scheduledBy) {
    return false;
  }

  return true;
};

export const sortByMostRecent = buildSortByDateDesc('start_at');

export const getFilteredUpcomingInterviews = createSelector(
  getInterviews,
  getFilterParams,
  (interviews, filterParams) =>
    interviews
      .filter(interview =>
        filterInterviewByParams(interview, filterParams, true)
      )
      .sort(sortByMostRecent)
);

export const getHiringWidgetUpcomingInterviews = createSelector(
  getFilteredUpcomingInterviews,
  getCurrentLocationId,
  (interviews, locationId) =>
    interviews
      .filter(interview => interview.get('location_id') === locationId)
      .sort(sortByMostRecent)
      .splice(3)
);

export const getHiringWidgetUpcomingInterviewsTotal = createSelector(
  getHiringWidgetUpcomingInterviews,
  interviews => interviews.size
);

export const getFilteredPastInterviews = createSelector(
  getInterviews,
  getFilterParams,
  (interviews, filterParams) =>
    interviews
      .filter(interview =>
        filterInterviewByParams(interview, filterParams, false)
      )
      .sort((a, b) => {
        const aDate = moment(a.get('start_at'));
        const bDate = moment(b.get('start_at'));

        if (aDate.isAfter(bDate)) return -1;
        if (bDate.isAfter(aDate)) return 1;
        return 0;
      })
);

export const getRoleOptions = createSelector(getInterviews, interviews =>
  buildOptions(interviews, 'role_picker', 'role_name')
);

export const getLocationOptions = createSelector(getInterviews, interviews =>
  buildOptions(interviews, 'location_picker', 'location_name')
);

export const getScheduledByOptions = createSelector(getInterviews, interviews =>
  buildOptions(interviews, 'scheduled_by_picker', 'scheduled_by_short_name')
);
