import * as React from 'react';
const Svg = () => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" rx="4" fill="currentColor" />
    <path
      d="M4.33301 8.99996L7.66634 12.3333L14.333 5.66663"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Svg;
