import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { showEnforcementDialog } from 'actions/modals';
import { refreshAnnouncementsTargetPathMatch } from 'actions/session';

import { getAnnouncementsForCurrentPath } from 'selectors/session';

import {
  selectCanCloseEnforcementDialog,
  selectShouldShowEnforcementDialogOnNavigation,
} from 'features/enforcement/EnforcementBanner/selectors';

import { PRODUCT_AREAS } from 'util/tracking_constants';

export const isEnforcedPathname = pathname =>
  !pathname.startsWith('/team') && !pathname.startsWith('/manage_plan');

@withRouter
@connect(
  (state, props) => ({
    announcementsForCurrentPath: getAnnouncementsForCurrentPath(state, {
      pathname: props.location.pathname,
    }),
    shouldShowEnforcementDialog: selectShouldShowEnforcementDialogOnNavigation(
      state,
      props
    ),
    canCloseEnforcementDialog: selectCanCloseEnforcementDialog(state),
  }),
  {
    refreshAnnouncementsTargetPathMatch,
    onShowEnforcementDialog: showEnforcementDialog,
  }
)
export default class BrowserHistory extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    shouldShowEnforcementDialog: PropTypes.bool.isRequired,
    canCloseEnforcementDialog: PropTypes.bool.isRequired,
    onShowEnforcementDialog: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleRouteChange();
    }

    this.handleShowEnforcementDialog();
  }

  handleShowEnforcementDialog = () => {
    if (
      isEnforcedPathname(this.props.location.pathname) &&
      this.props.shouldShowEnforcementDialog
    ) {
      this.props.onShowEnforcementDialog({
        productArea: PRODUCT_AREAS.NAVIGATION,
        canCloseDialog: this.props.canCloseEnforcementDialog,
      });
    }
  };

  handleRouteChange = () => {
    // Open Announcements when navigating the React app.
    // Note: we open Announcements on initial page load via appRegistration.jsx
    const matchingAnnouncements = this.props.announcementsForCurrentPath;

    this.props.refreshAnnouncementsTargetPathMatch(matchingAnnouncements);
  };

  render() {
    return this.props.children;
  }
}
