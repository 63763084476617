import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';

export const fetchPayrollTaxReturns = createAsyncThunk(
  'payroll/fetchPayrollTaxReturns',
  ({ year, quarter }) =>
    fetchJSON(`/payroll/tax_returns.json?year=${year}&quarter=${quarter}`)
);

export const fetchPayrollTaxPayments = createAsyncThunk(
  'payroll/fetchPayrollTaxPayments',
  ({ year, quarter }, { rejectWithValue }) =>
    fetchJSON(
      `/payroll/tax_payments.json?year=${year}&quarter=${quarter}`
    ).catch(err => err.response.json().then(body => rejectWithValue(body)))
);

const payrollTaxReturnsSlice = createSlice({
  name: 'payrollTaxReturns',
  initialState: {
    isFetching: false,
    errors: [],
    taxList: [],
    taxPayments: [],
  },
  extraReducers: {
    [fetchPayrollTaxReturns.pending]: state => {
      state.isFetching = true;
    },
    [fetchPayrollTaxReturns.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.taxList = action.payload;
    },
    [fetchPayrollTaxReturns.rejected]: state => {
      state.isFetching = false;
    },
    [fetchPayrollTaxPayments.pending]: state => {
      state.isFetching = true;
    },
    [fetchPayrollTaxPayments.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.taxPayments = action.payload;
    },
    [fetchPayrollTaxPayments.rejected]: state => {
      state.isFetching = false;
    },
  },
});

export const payrollTaxReturnsReducer = payrollTaxReturnsSlice.reducer;
export const payrollTaxReturnsActions = payrollTaxReturnsSlice.actions;
