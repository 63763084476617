import './NumberInput.scss';

import React, { PureComponent } from 'react';
import TextMask from 'react-text-mask-old';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import Box from 'components/Box';
import Text from 'components/Text';

import cxHelpers from 'util/className';

const createHoursMask = (allowDecimal = true) =>
  createNumberMask({
    prefix: '',
    allowDecimal,
    includeThousandsSeparator: false,
  });

@cxHelpers('NumberInput')
export default class NumberInput extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    square: PropTypes.bool,
    name: PropTypes.string,
    type: PropTypes.string,
    tabIndex: PropTypes.string,
    numberSuffix: PropTypes.string,
    maxInput: PropTypes.number,
    maxDecimals: PropTypes.number,
  };

  static defaultProps = {
    placeholder: 0,
  };

  // simulate maxLength for number input type
  validateMaxInput = input => String(input).slice(0, this.props.maxInput);

  validateInput = input => {
    let validated_input = input;

    if (this.props.maxInput) {
      validated_input = this.validateMaxInput(validated_input);
    }

    return validated_input;
  };

  validateMaxDecimals = number => {
    const index = `${number}`.indexOf('.');
    const decimals = index === -1 ? 0 : number.length - index - 1;
    if (decimals > this.props.maxDecimals) {
      return (
        Math.floor(number * 10 ** this.props.maxDecimals) /
        10 ** this.props.maxDecimals
      ).toFixed(this.props.maxDecimals);
    }
    return number;
  };

  handleChange = e => {
    const { onChange } = this.props;
    if (!onChange) {
      return;
    }

    let targetValue = e.target.value;
    if (this.props.maxDecimals) {
      targetValue = this.validateMaxDecimals(targetValue);
      e.target.value = targetValue;
    }

    // this change is needed to power the
    // client/src/features/payroll/PayrollRunView/EarningsView/EarningsView.jsx
    // component since it sends extra args to handleUpdateValue
    // spec/features/payroll/main_spec.rb:499 can now pass as a result

    if (this.props.isFormik) {
      onChange(this.validateInput(targetValue), e);
    } else {
      onChange(this.validateInput(targetValue));
    }
  };

  handleBlur = e => {
    if (this.props.onBlur) {
      this.props.onBlur(e.target.value, e);
    }
  };

  renderInput = inputProps => {
    if (inputProps.type === 'hours') {
      return (
        <TextMask
          className={this.cxEl('input')}
          {...inputProps}
          mask={createHoursMask(this.props.inputProps?.allowDecimal)}
        />
      );
    }

    // Fixes a warning from React when value is null or undefined
    inputProps.value ??= '';

    return (
      <input className={this.cxEl('input')} type="number" {...inputProps} />
    );
  };

  render() {
    const {
      disabled,
      inline,
      placeholder,
      value,
      square,
      style,
      name,
      type,
      tabIndex,
      numberSuffix,
    } = this.props;

    const inputProps = {
      value,
      placeholder,
      onChange: this.handleChange,
      name,
      type,
      onBlur: this.handleBlur,
      tabIndex,
      disabled,
    };

    if (numberSuffix) {
      return (
        <Box className={this.cx('numberWithSuffix')}>
          <Box row mr8>
            <Box>{this.renderInput(inputProps)}</Box>
            <Box tright vcenter>
              <Text grayMediumDark fs14 fw600>
                {numberSuffix}
              </Text>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <div className={this.cx({ disabled, inline, square })} style={style}>
        {this.renderInput(inputProps)}
      </div>
    );
  }
}
