import * as routes from 'api';

import {
  createAsyncGetAction,
  createAsyncPostAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';

export const actionTypes = {
  FETCH_STATUS_REQUEST: 'USER_WEB_TIMECLOCK/FETCH_STATUS_REQUEST',
  FETCH_STATUS_SUCCESS: 'USER_WEB_TIMECLOCK/FETCH_STATUS_SUCCESS',
  FETCH_STATUS_FAILURE: 'USER_WEB_TIMECLOCK/FETCH_STATUS_FAILURE',
  CLOCK_IN_REQUEST: 'USER_WEB_TIMECLOCK/CLOCK_IN_REQUEST',
  CLOCK_IN_SUCCESS: 'USER_WEB_TIMECLOCK/CLOCK_IN_SUCCESS',
  CLOCK_IN_FAILURE: 'USER_WEB_TIMECLOCK/CLOCK_IN_FAILURE',
  CLOCK_IN_UNSCHEDULED_REQUEST:
    'USER_WEB_TIMECLOCK/CLOCK_IN_UNSCHEDULED_REQUEST',
  CLOCK_IN_UNSCHEDULED_SUCCESS:
    'USER_WEB_TIMECLOCK/CLOCK_IN_UNSCHEDULED_SUCCESS',
  CLOCK_IN_UNSCHEDULED_FAILURE:
    'USER_WEB_TIMECLOCK/CLOCK_IN_UNSCHEDULED_FAILURE',
  CLOCK_OUT_REQUEST: 'USER_WEB_TIMECLOCK/CLOCK_OUT_REQUEST',
  CLOCK_OUT_SUCCESS: 'USER_WEB_TIMECLOCK/CLOCK_OUT_SUCCESS',
  CLOCK_OUT_FAILURE: 'USER_WEB_TIMECLOCK/CLOCK_OUT_FAILURE',
  CLOCK_IN_BREAK_REQUEST: 'USER_WEB_TIMECLOCK/CLOCK_IN_BREAK_REQUEST',
  CLOCK_IN_BREAK_SUCCESS: 'USER_WEB_TIMECLOCK/CLOCK_IN_BREAK_SUCCESS',
  CLOCK_IN_BREAK_FAILURE: 'USER_WEB_TIMECLOCK/CLOCK_IN_BREAK_FAILURE',
  CLOCK_OUT_BREAK_REQUEST: 'USER_WEB_TIMECLOCK/CLOCK_OUT_BREAK_REQUEST',
  CLOCK_OUT_BREAK_SUCCESS: 'USER_WEB_TIMECLOCK/CLOCK_OUT_BREAK_SUCCESS',
  CLOCK_OUT_BREAK_FAILURE: 'USER_WEB_TIMECLOCK/CLOCK_OUT_BREAK_FAILURE',
};

export const fetchStatus = onSuccess =>
  withAlerts(
    createAsyncGetAction(routes.userWebTimeclockStatusRoute(), [
      actionTypes.FETCH_STATUS_REQUEST,
      actionTypes.FETCH_STATUS_SUCCESS,
      actionTypes.FETCH_STATUS_FAILURE,
    ]),
    {
      onSuccess,
    }
  );

export const clockIn = (shiftId, onSuccess) => dispatch =>
  dispatch(
    withAlerts(
      createAsyncPutAction(routes.userWebTimeclockClockInRoute(shiftId), [
        actionTypes.CLOCK_IN_REQUEST,
        actionTypes.CLOCK_IN_SUCCESS,
        actionTypes.CLOCK_IN_FAILURE,
      ]),
      {
        onSuccess: async response => onSuccess(response),
      }
    )
  );

export const clockInUnscheduled = onSuccess => dispatch =>
  dispatch(
    withAlerts(
      createAsyncPostAction(routes.userWebTimeclockClockInUnscheduleRoute(), [
        actionTypes.CLOCK_IN_UNSCHEDULED_REQUEST,
        actionTypes.CLOCK_IN_UNSCHEDULED_SUCCESS,
        actionTypes.CLOCK_IN_UNSCHEDULED_FAILURE,
      ]),
      {
        onSuccess: async response => onSuccess(response),
      }
    )
  );

export const clockOut = (shiftId, onSuccess) =>
  withAlerts(
    createAsyncPutAction(routes.userWebTimeclockClockOutRoute(shiftId), [
      actionTypes.CLOCK_OUT_REQUEST,
      actionTypes.CLOCK_OUT_SUCCESS,
      actionTypes.CLOCK_OUT_FAILURE,
    ]),
    {
      onSuccess,
    }
  );

export const clockInBreak = (shiftId, breakId, onSuccess) =>
  withAlerts(
    createAsyncPutAction(
      routes.userWebTimeclockClockInBreakRoute(shiftId, breakId),
      [
        actionTypes.CLOCK_IN_BREAK_REQUEST,
        actionTypes.CLOCK_IN_BREAK_SUCCESS,
        actionTypes.CLOCK_IN_BREAK_FAILURE,
      ]
    ),
    {
      onSuccess,
    }
  );

export const clockOutBreak = (shiftId, onSuccess) =>
  withAlerts(
    createAsyncPutAction(routes.userWebTimeclockClockOutBreakRoute(shiftId), [
      actionTypes.CLOCK_OUT_BREAK_REQUEST,
      actionTypes.CLOCK_OUT_BREAK_SUCCESS,
      actionTypes.CLOCK_OUT_BREAK_FAILURE,
    ]),
    {
      onSuccess,
    }
  );
