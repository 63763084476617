import { createSlice } from '@reduxjs/toolkit';
import {
  PENDING_INDEX,
  PENDING_STACK,
  PENDING_STATE,
} from 'fe-core/Entities/constants';
import { get, setWith } from 'lodash';

import { ENTITIES_SLICE } from '../module-constants';

import destroy from './destroy';
import digest from './digest';
import { addPending, clearPending } from './pending';

const slice = createSlice({
  name: ENTITIES_SLICE,
  initialState: {
    [PENDING_STATE]: {
      [PENDING_STACK]: [],
      [PENDING_INDEX]: {},
    },
  },
  reducers: {
    addPending: (state, { payload: { pendingId, entities } }) => {
      addPending({ state, pendingId, entities });
    },
    clearPending: (state, { payload: pendingId }) => {
      clearPending({ state, pendingId });
    },
    digest: (state, { payload: jsonApiData }) => {
      digest(state, jsonApiData);
    },
    clearAllEntities: (state, { payload: type }) => {
      delete state[type];
    },
    destroy: (state, { payload: { type, id } }) => {
      destroy(state, type, id);
    },
    assignAttributes: (state, { payload: { type, id, attributes } }) => {
      const path = [type, id, 'attributes'];
      const existingAttributes = get(state, path);
      setWith(
        state,
        path,
        {
          ...existingAttributes,
          ...attributes,
        },
        Object
      );
    },
  },
});

export default slice;
