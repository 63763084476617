import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface LastPageContextProps {
  previousPathname: string | undefined;
}

const LastPageContext = createContext<LastPageContextProps | undefined>(
  undefined
);

interface LastPageProps {
  children: React.ReactNode;
}

const LastPageProvider = ({ children }: LastPageProps) => {
  const [currentPathname, setCurrentPathname] = useState<string | undefined>(
    undefined
  );
  const [previousPathname, setPreviousPathname] = useState<string | undefined>(
    undefined
  );
  const { pathname: pathnameState } = useLocation();

  useEffect(() => {
    if (currentPathname !== window.location.pathname) {
      if (currentPathname !== undefined) {
        setPreviousPathname(currentPathname);
      }
      setCurrentPathname(window.location.pathname);
    }
    // This dependency is to ensure that we udpate based on the current path state
    // even though we aren't manipulating it directly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathnameState]);

  return (
    <LastPageContext.Provider value={{ previousPathname }}>
      {children}
    </LastPageContext.Provider>
  );
};
export { LastPageContext, LastPageProvider };
