import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  showBulkAddModal,
  showConnectPosModal,
  showIndividualDrawer,
  showSyncEmployeesModal,
  toggleChooseHowDrawer,
} from 'actions/addTeam';
import { createOneTimeEvent } from 'actions/session';

import {
  getCurrentLocationIsIntegratedPayrollPartner,
  getCurrentLocationIsIntegratedPOSPartner,
  getCurrentLocationIsPayrollForSyncPage,
  getCurrentLocationIsPOSForSyncPage,
} from 'selectors/addTeam';
import { buildGetDrawerOpen } from 'selectors/drawers';
import {
  getCurrentLocationPartner,
  getCurrentLocationPayrollProvider,
} from 'selectors/session';

import { CHOOSE_HOW_DRAWER_KEY } from 'features/drawers/keys';

import Box from 'components/Box';
import IconButton from 'components/clickables/IconButton';
import Drawer from 'components/Drawer';
import withDispatchOnNavigation from 'components/hocs/withDispatchOnNavigation';
import withUxTracking from 'components/hocs/withUxTracking';
import { MobileAndTablet } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { EVENT_ACTIONS } from 'util/tracking_constants';

import { importEmployeesRoute } from '../../../util/router';

@withUxTracking('Team', 'Add Team Drawer')
@withDispatchOnNavigation(toggleChooseHowDrawer(false))
@connect(
  (state, props) => ({
    isOpen: buildGetDrawerOpen(CHOOSE_HOW_DRAWER_KEY)(state, props),
    isIntegratedPOSPartner: getCurrentLocationIsIntegratedPOSPartner(
      state,
      props
    ),
    isIntegratedPayrollPartner: getCurrentLocationIsIntegratedPayrollPartner(
      state,
      props
    ),
    isSyncPOSPartner: getCurrentLocationIsPOSForSyncPage(state, props),
    isSyncPayrollPartner: getCurrentLocationIsPayrollForSyncPage(state, props),
    payrollProvider: getCurrentLocationPayrollProvider(state, props),
    partner: getCurrentLocationPartner(state, props),
  }),
  {
    toggleChooseHowDrawer,
    showConnectPosModal,
    showIndividualDrawer,
    showSyncEmployeesModal,
    showBulkAddModal,
    createOneTimeEvent,
  }
)
@cxHelpers('ChooseHowDrawer')
export default class ChooseHowDrawer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isIntegratedPOSPartner: PropTypes.bool.isRequired,
    isSyncPOSPartner: PropTypes.bool.isRequired,
    isSyncPayrollPartner: PropTypes.bool.isRequired,
    isIntegratedPayrollPartner: PropTypes.bool.isRequired,
    payrollProvider: ImmutablePropTypes.map,
    partner: PropTypes.string,
    toggleChooseHowDrawer: PropTypes.func.isRequired,
    showConnectPosModal: PropTypes.func.isRequired,
    showIndividualDrawer: PropTypes.func.isRequired,
    showSyncEmployeesModal: PropTypes.func.isRequired,
    showBulkAddModal: PropTypes.func.isRequired,
    trackUxEvent: PropTypes.func.isRequired,
  };

  componentDidUpdate() {
    if (this.props.isOpen) {
      this.props.trackUxEvent({
        eventAction: EVENT_ACTIONS.ADD_TEAM_DRAWER_SHOWN,
      });
    }
  }

  handleRequestClose = () => this.props.toggleChooseHowDrawer(false);

  handleClickIndividually = () => {
    this.props.trackUxEvent({
      eventAction: EVENT_ACTIONS.MANUALLY_ADD_CLICKED,
    });
    this.props.createOneTimeEvent('Opened Add Team Modal');
    this.props.toggleChooseHowDrawer(false);
    this.props.showIndividualDrawer();
  };

  handleClickBulk = () => {
    this.props.toggleChooseHowDrawer(false);
    this.props.trackUxEvent({ eventAction: EVENT_ACTIONS.BULK_ADD_CLICKED });
    this.props.showBulkAddModal();
  };

  handleClickPos = () => {
    this.props.trackUxEvent({ eventAction: EVENT_ACTIONS.PARTNER_ADD_CLICKED });
    this.props.toggleChooseHowDrawer(false);
    return this.props.showConnectPosModal();
  };

  handleClickPosImport = () => {
    this.props.trackUxEvent({
      eventAction: EVENT_ACTIONS.PARTNER_POS_IMPORT_CLICKED,
    });
    this.props.toggleChooseHowDrawer(false);
    if (this.props.isSyncPOSPartner) {
      return window.Homebase.RailsReactBridge.navigateToReactView(
        importEmployeesRoute('pos')
      );
    }
    return this.syncEmployees(this.props.partner, true);
  };

  handleClickPayrollImport = () => {
    this.props.trackUxEvent({
      eventAction: EVENT_ACTIONS.PARTNER_PAYROLL_IMPORT_CLICKED,
    });
    this.props.toggleChooseHowDrawer(false);
    if (this.props.isSyncPayrollPartner) {
      return window.Homebase.RailsReactBridge.navigateToReactView(
        importEmployeesRoute('payroll')
      );
    }
    return this.syncEmployees(this.props.payrollProvider.get('key'), false);
  };

  syncEmployees = (key, isPOS) => {
    this.props.showSyncEmployeesModal(key, isPOS);
  };

  render() {
    return (
      <MobileAndTablet>
        {(mobile, tablet) => (
          <Drawer
            width={mobile || tablet ? '100%' : '546px'}
            open={this.props.isOpen}
          >
            <Box className={this.cx({ isOpen: this.props.isOpen })} h="100%">
              <Box absolute top={5} right={5} z={2}>
                <IconButton
                  iconColor="grayMediumDark"
                  size="md"
                  type="mdClose"
                  onClick={this.handleRequestClose}
                />
              </Box>
              <Box ml={28} mr={32} mt={40}>
                <Box mb={24}>
                  <Text fs24 fw600 i18n="add_team.add_team_drawer.title" />
                </Box>

                <Box mb={30}>
                  <Text i18n="add_team.add_team_drawer.subtitle" />
                </Box>

                <Box grow>
                  <Box
                    grow
                    onClick={this.handleClickIndividually}
                    bb="grayMediumLight"
                    mb={24}
                    pointer
                    className={this.cxEl('option')}
                  >
                    <Box>
                      <Text
                        purple
                        fs16
                        fw600
                        i18n="add_team.add_team_drawer.individually.header"
                      />
                    </Box>
                    <Box mb={24}>
                      <Text
                        fs14
                        fw300
                        i18n="add_team.add_team_drawer.individually.subheader"
                      />
                    </Box>
                  </Box>

                  <Box
                    className={this.cxEl('option')}
                    pointer
                    grow
                    bb="grayMediumLight"
                    mb={24}
                    onClick={this.handleClickBulk}
                  >
                    <Box>
                      <Text
                        purple
                        fs16
                        fw600
                        i18n="add_team.add_team_drawer.bulk_add.header"
                      />
                    </Box>
                    <Box mb={24}>
                      <Text
                        fs14
                        fw300
                        i18n="add_team.add_team_drawer.bulk_add.subheader"
                      />
                    </Box>
                  </Box>
                  {!(
                    this.props.isIntegratedPOSPartner ||
                    this.props.isIntegratedPayrollPartner
                  ) && (
                    <Box
                      className={this.cxEl('option')}
                      pointer
                      grow
                      onClick={this.handleClickPos}
                    >
                      <Box>
                        <Text
                          purple
                          fs16
                          fw600
                          i18n="add_team.add_team_drawer.pos.header"
                        />
                      </Box>
                      <Box mb={24}>
                        <Text
                          fs14
                          fw300
                          i18n="add_team.add_team_drawer.pos.subheader"
                        />
                      </Box>
                    </Box>
                  )}
                  {this.props.isIntegratedPOSPartner && (
                    <Box
                      className={this.cxEl('option')}
                      pointer
                      grow
                      bb={
                        this.props.isIntegratedPayrollPartner &&
                        'grayMediumLight'
                      }
                      mb={24}
                      onClick={this.handleClickPosImport}
                    >
                      <Box>
                        <Text
                          purple
                          fs16
                          fw600
                          i18n="add_team.add_team_drawer.pos_import.header"
                        />
                      </Box>
                      <Box mb={24}>
                        <Text
                          fs14
                          fw300
                          i18n="add_team.add_team_drawer.pos_import.subheader"
                        />
                      </Box>
                    </Box>
                  )}
                  {this.props.isIntegratedPayrollPartner && (
                    <Box
                      className={this.cxEl('option')}
                      pointer
                      grow
                      onClick={this.handleClickPayrollImport}
                    >
                      <Box>
                        <Text
                          purple
                          fs16
                          fw600
                          i18n="add_team.add_team_drawer.payroll_import.header"
                        />
                      </Box>
                      <Box mb={24}>
                        <Text
                          fs14
                          fw300
                          i18n="add_team.add_team_drawer.payroll_import.subheader"
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}
      </MobileAndTablet>
    );
  }
}
