import React from 'react';
import Icon from 'react-icon-base';

const LinkBlue = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g>
      <circle cx="24" cy="24" r="24" fill="#E9F8FC" />
      <path
        fill="#70CAD9"
        d="M25.6 32c0 .884-.715 1.6-1.6 1.6-.885 0-1.6-.716-1.6-1.6 0-.886.715-1.6 1.6-1.6.885 0 1.6.714 1.6 1.6zM32 13.6v20.8a2.4 2.4 0 0 1-2.4 2.4H18.4a2.4 2.4 0 0 1-2.4-2.4V13.6a2.4 2.4 0 0 1 2.4-2.4h11.2a2.4 2.4 0 0 1 2.4 2.4zm-1.6 0c0-.44-.36-.8-.8-.8H18.4c-.44 0-.8.36-.8.8v20.8c0 .44.36.8.8.8h11.2c.44 0 .8-.36.8-.8V13.6z"
      />
    </g>
  </Icon>
);

export default LinkBlue;
