import { toI18n } from 'util/i18n';

import { NAME_FIELD_CHAR_LIMIT, PTD_TYPE_OPTIONS } from './constants';

export const formatContributionValue = deduction => {
  if (!deduction.get('amount_type')) {
    return '--';
  }

  if (deduction.get('amount_type') === 'amount') {
    return `$${deduction.get(`amount`)}`;
  }

  return `${deduction.get(`amount`)}%`;
};

export const getDeductionType = deduction =>
  PTD_TYPE_OPTIONS.find(option => option.value === deduction.get('type'));

export const handleValidate = values => {
  const errors = {};
  if (!values.type) {
    errors.type = toI18n(
      'team.employee_profile.tax_deductions.validations.ptd_type_required'
    );
  }

  if (!values.name) {
    errors.name = toI18n(
      'team.employee_profile.tax_deductions.validations.ptd_name_required'
    );
  }

  if (values.name && values.name.length > NAME_FIELD_CHAR_LIMIT) {
    errors.name = toI18n(
      'team.employee_profile.tax_deductions.validations.char_max_limit',
      {
        props: {
          limit: NAME_FIELD_CHAR_LIMIT,
        },
      }
    );
  }

  if (!values.start_date) {
    errors.start_date = toI18n(
      'team.employee_profile.tax_deductions.validations.start_date_required'
    );
  }

  if (!values.amount) {
    errors.amount = toI18n(
      'team.employee_profile.tax_deductions.validations.fixed_amount_required'
    );
  }

  if (values.type === 'miscellaneous') {
    if (
      +values.contribution_amount > 0 &&
      +values.contribution_amount < +values.amount
    ) {
      errors.contribution_amount = toI18n(
        'team.employee_profile.tax_deductions.validations.contribution_amount_validation'
      );
    }
  }

  if (values.type === 'child_support') {
    if (!values.external_id) {
      errors.external_id = toI18n(
        'team.employee_profile.tax_deductions.validations.external_id_required'
      );
    }

    if (
      values.external_id &&
      values.external_id.length > NAME_FIELD_CHAR_LIMIT
    ) {
      errors.external_id = toI18n(
        'team.employee_profile.tax_deductions.validations.char_max_limit',
        {
          props: {
            limit: NAME_FIELD_CHAR_LIMIT,
          },
        }
      );
    }

    if (!values.state_issued) {
      errors.state_issued = toI18n(
        'team.employee_profile.tax_deductions.validations.state_issued_required'
      );
    }

    if (!values.issue_date) {
      errors.issue_date = toI18n(
        'team.employee_profile.tax_deductions.validations.issue_date_required'
      );
    }

    if (!values.maximum_percent) {
      errors.maximum_percent = toI18n(
        'team.employee_profile.tax_deductions.validations.max_percent_required'
      );
    }

    if (
      parseInt(values.maximum_percent, 10) < 0 ||
      parseInt(values.maximum_percent, 10) > 100
    ) {
      errors.maximum_percent = toI18n(
        'team.employee_profile.tax_deductions.validations.max_percent_range'
      );
    }
  }

  if (values.name === 'CalSavers') {
    if (!values.annual_limit) {
      errors.annual_limit = toI18n(
        'team.employee_profile.tax_deductions.validations.annual_limit_required'
      );
    }

    if (values.annual_limit && parseInt(values.annual_limit, 10) < 1) {
      errors.annual_limit = toI18n(
        'team.employee_profile.tax_deductions.validations.min_annual_limit'
      );
    }

    if (
      !values.amount ||
      !(parseFloat(values.amount) > 0) ||
      parseFloat(values.amount) > 100
    ) {
      errors.amount = toI18n(
        'team.employee_profile.tax_deductions.validations.employee_contribution_required'
      );
    }
  }

  return errors;
};
