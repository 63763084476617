// IMPORTANT: All look-ups on entities (`users.get()`, `users.update()`, etc.) must convert the
// lookup key to a string. Immutable's `fromJS()` converts object keys to strings and requires
// exact matching, so unlike a POJO immutableMapInstance[12] !== immutableMapInstance["12"]. We run
// into this issue since normalizr stores entities as an Object, which implicitly converts all keys
// to strings.
export const toEntityId = id => id.toString();

// Conversely, the keys coming over from our backend are originally integers, so the association
// arrays generated by normalizr are all ints.
export const toAssociationId = id => parseInt(id, 10);
