import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgBan = ({
  alt = 'Ban Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M24 12c0 6.628-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0s12 5.372 12 12ZM4.664 6.787A8.94 8.94 0 0 0 3 12c0 4.969 3.99 9 9 9a8.923 8.923 0 0 0 5.212-1.664L4.664 6.788ZM21 12c0-5.01-4.031-9-9-9a8.94 8.94 0 0 0-5.213 1.664l12.549 12.549A8.923 8.923 0 0 0 21 12Z"
    />
  </svg>
);
export default SvgBan;
