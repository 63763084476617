import React from 'react';
import Icon from 'react-icon-base';

const Notes = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <path
      fill="inherit"
      d="M7.467 3.795h2.716c.204 0 .34-.135.34-.339 0-.203-.136-.339-.34-.339H7.467c-.203 0-.34.204-.34.34 0 .135.137.338.34.338zM3.4 5.83h6.857c.204 0 .34-.135.34-.339 0-.203-.136-.339-.34-.339H3.4c-.204 0-.34.136-.34.34 0 .203.136.338.34.338zM3.4 7.864h6.857c.204 0 .34-.136.34-.34 0-.203-.136-.338-.34-.338H3.4c-.204 0-.34.135-.34.339 0 .203.136.339.34.339z"
    />
    <path
      fill="inherit"
      d="M12.478 6.848V1.559C12.478.678 11.732 0 10.781 0H5.214h-.136L1.072 3.39l-.068.068v9.695c-.068 1.017.68 1.762 1.63 1.762h8.223c1.078 0 1.548-.948 1.621-1.627.074-.678 0-6.44 0-6.44zM4.942 1.085v2.101c0 .136-.136.204-.34.204H2.227l2.716-2.305zM2.634 14.238c-.543 0-1.019-.407-1.019-.95v-9.22h2.988c.543 0 1.018-.407 1.018-.882V.678h5.16c.543 0 1.018.407 1.018.881v5.22s.075 5.153 0 6.17c-.074 1.017-.622 1.289-1.175 1.289h-7.99z"
    />
    <path
      fill="#fff"
      d="M11.264 6.474c.827-.783 2.068-.875 2.803-.138.735.736.69 1.979-.138 2.808l-4.16 4.135-1.906 1.894c-.046 0-.046.046-.092.046l-3.079.783h-.046c-.092 0-.138 0-.184-.046-.045-.046-.045-.138-.045-.23l.78-3.084c0-.046 0-.046.047-.092l2.022-2.041 3.998-4.035z"
    />
    <path
      fill="inherit"
      d="M14.067 6.336c-.735-.737-1.976-.645-2.803.138l-3.998 4.035-2.022 2.04c-.046.047-.046.047-.046.093l-.782 3.084c0 .092 0 .184.046.23.046.046.092.046.184.046h.046l3.08-.783c.045 0 .045-.046.091-.046L9.77 13.28l4.16-4.135c.827-.829.873-2.072.138-2.808zm-.322.322c.506.506.46 1.427-.091 2.071l-1.93-1.979c.597-.552 1.516-.598 2.021-.092zm-6.02 8.009L5.75 12.734l5.607-5.662 2.022 1.98-5.653 5.615zM5.52 13.148l1.747 1.749-2.298.598.551-2.347z"
    />
  </Icon>
);

export default Notes;
