import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { getRolesWagesEnabled, getSalaryEnabled } from 'selectors/addTeam';
import {
  getCanViewWages,
  getCurrentUserIsGM,
  getCurrentUserIsManagerial,
  getCurrentUserIsOwner,
} from 'selectors/session';

import { selectCanViewOtherManagerWages } from 'features/team/selectors';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import {
  GM,
  I18N_JOB_DETAILS_PATH,
  MANAGER,
  WAGE_TYPES,
} from '../../constants';

import JobRolesPlaceholder from './JobRolesPlaceholder';
import RateTypeSelector from './RateTypeSelector';
import RoleAutocomplete from './RoleAutocomplete';
import WageRateInput from './WageRateInput';

const { cxEl } = cxHelpers('JobDetailsSection');

const ROLE_COL_WIDTH = 342;
const WAGE_COL_WIDTH = 100;
const WAGE_RATE_COL_WIDTH = 120;

const JobRoles = ({
  canViewOtherManagerWages,
  canViewWages,
  companyAndSelectedRoles,
  currentUserIsGM,
  currentUserIsManagerial,
  currentUserIsOwner,
  numAdditionalRoleWage,
  onAddRoleWage,
  onSetNumAdditionalRoleWage,
  onUpdateSelectedRoles,
  rehireLevel,
  roleWagesEnabled,
  salaryEnabled,
  selectedRoles,
  setRoleFieldValue,
}) => {
  const { setFieldValue, values } = useFormikContext();

  const isHourlyWageType = values.wageType === WAGE_TYPES.hourly;
  const isRehiring = Boolean(rehireLevel);
  const rehireIsGM = rehireLevel === GM;
  const rehireIsManager = rehireLevel === MANAGER;

  const showGM =
    currentUserIsGM &&
    canViewWages &&
    (canViewOtherManagerWages || (!canViewOtherManagerWages && !rehireIsGM));
  const showManager =
    currentUserIsManagerial &&
    canViewWages &&
    (canViewOtherManagerWages ||
      (!canViewOtherManagerWages && !rehireIsGM && !rehireIsManager));

  const showForm = !isRehiring || currentUserIsOwner || showGM || showManager;

  return showForm ? (
    <>
      <Box className={cxEl('role-wage-headers')}>
        <Box row spacebetween style={{ marginBottom: '8px', gap: 20 }}>
          <Box w={ROLE_COL_WIDTH}>
            <Text variant="bodySmBold" i18n={`${I18N_JOB_DETAILS_PATH}.role`} />
          </Box>
          <Box w={WAGE_COL_WIDTH}>
            <Text
              variant="bodySmBold"
              className={values.includedInPayroll ? 'required' : ''}
              i18n={`${I18N_JOB_DETAILS_PATH}.wage`}
            />
          </Box>
          <Box w={WAGE_RATE_COL_WIDTH}>
            <Text variant="bodySmBold" i18n={`${I18N_JOB_DETAILS_PATH}.rate`} />
          </Box>
        </Box>
      </Box>

      <Box className={cxEl('default-role-wage')}>
        <Box row spacebetween style={{ gap: 20 }}>
          <Box w={ROLE_COL_WIDTH}>
            <RoleAutocomplete
              autoCompleteValue={values.defaultRoleName}
              fieldValue="defaultRoleName"
              setRoleFieldValue={setRoleFieldValue}
              companyAndSelectedRoles={companyAndSelectedRoles}
              selectedRoles={selectedRoles}
              setFieldValue={setFieldValue}
              updateSelectedRoles={onUpdateSelectedRoles}
            />
          </Box>

          <Box w={WAGE_COL_WIDTH}>
            <WageRateInput name="wageRate" />
          </Box>

          <Box w={WAGE_RATE_COL_WIDTH}>
            <RateTypeSelector
              cxEl={cxEl}
              isDefaultRole
              salaryEnabled={salaryEnabled}
              numAdditionalRoleWage={numAdditionalRoleWage}
              setFieldValue={setFieldValue}
              values={values}
              onSetNumAdditionalRoleWage={onSetNumAdditionalRoleWage}
              onUpdateSelectedRoles={onUpdateSelectedRoles}
            />
          </Box>
        </Box>
      </Box>

      <Box className={cxEl('additional-role-wages')}>
        {[...Array(values.wageAttributes.length).keys()].map(i => (
          <Box
            key={`additional-role-wage-row-${i}`}
            row
            spacebetween
            style={{ gap: 20 }}
          >
            <Box w={ROLE_COL_WIDTH}>
              <RoleAutocomplete
                autoCompleteValue={values.wageAttributes[i].role_name}
                fieldValue={`wageAttributes.${i}.role_name`}
                setFieldValue={setFieldValue}
                companyAndSelectedRoles={companyAndSelectedRoles}
                selectedRoles={selectedRoles}
                setRoleFieldValue={setRoleFieldValue}
                updateSelectedRoles={onUpdateSelectedRoles}
              />
            </Box>

            <Box w={WAGE_COL_WIDTH}>
              <WageRateInput name={`wageAttributes.${i}.wage_rate`} />
            </Box>

            <Box w={WAGE_RATE_COL_WIDTH}>
              <RateTypeSelector
                idx={i}
                isDefaultRole={false}
                cxEl={cxEl}
                salaryEnabled={salaryEnabled}
                numAdditionalRoleWage={numAdditionalRoleWage}
                setFieldValue={setFieldValue}
                values={values}
                onSetNumAdditionalRoleWage={onSetNumAdditionalRoleWage}
                onUpdateSelectedRoles={onUpdateSelectedRoles}
              />
            </Box>
          </Box>
        ))}
      </Box>

      {roleWagesEnabled && (
        <>
          {isHourlyWageType ? (
            <Box className={cxEl('assign-role-wage')} inlineBlock>
              <Button size="small" variant="tertiary" onClick={onAddRoleWage}>
                {toI18n(`${I18N_JOB_DETAILS_PATH}.add_another_role_cta`)}
              </Button>
            </Box>
          ) : (
            <Text
              variant="captions"
              color="mono700"
              i18n={`${I18N_JOB_DETAILS_PATH}.anually_rate_text`}
            />
          )}
        </>
      )}
    </>
  ) : (
    <JobRolesPlaceholder />
  );
};

JobRoles.propTypes = {
  canViewOtherManagerWages: PropTypes.bool,
  canViewWages: PropTypes.bool,
  companyAndSelectedRoles: ImmutablePropTypes.list,
  currentUserIsGM: PropTypes.bool,
  currentUserIsManagerial: PropTypes.bool,
  currentUserIsOwner: PropTypes.bool,
  numAdditionalRoleWage: PropTypes.number,
  onAddRoleWage: PropTypes.func.isRequired,
  onSetNumAdditionalRoleWage: PropTypes.func.isRequired,
  onUpdateSelectedRoles: PropTypes.func.isRequired,
  rehireLevel: PropTypes.string,
  roleWagesEnabled: PropTypes.bool,
  salaryEnabled: PropTypes.bool,
  selectedRoles: PropTypes.array.isRequired,
  setRoleFieldValue: PropTypes.func,
};

export default connect(state => ({
  canViewOtherManagerWages: selectCanViewOtherManagerWages(state),
  canViewWages: getCanViewWages(state),
  currentUserIsGM: getCurrentUserIsGM(state),
  currentUserIsManagerial: getCurrentUserIsManagerial(state),
  currentUserIsOwner: getCurrentUserIsOwner(state),
  roleWagesEnabled: getRolesWagesEnabled(state),
  salaryEnabled: getSalaryEnabled(state),
}))(JobRoles);
