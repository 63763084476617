import React, { PureComponent } from 'react';

import ShiftAlert from './ShiftAlert';

export default class MissedClockOut extends PureComponent {
  render() {
    return (
      <ShiftAlert
        descI18n="notifications.alerts.clock_out.desc"
        {...this.props}
      />
    );
  }
}
