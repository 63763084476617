import { SxProps, Theme } from '@mui/material/styles';
import { colorsMap } from 'fe-design-base/molecules/utils';

import { AlertVariant } from './types';

export const alertStyles = ({
  variant,
}: {
  variant: AlertVariant;
}): SxProps<Theme> => ({
  '&.FDBAlert.MuiPaper-root': {
    bgcolor: `${colorsMap[variant][100]} !important`,
    borderColor: colorsMap[variant][300],
    borderWidth: '1px 1px 1px 8px',

    '& .icon-wrapper': {
      color: colorsMap[variant][300],
    },
  },
});
