import React from 'react';

import Box from 'components/Box';

import SubMenu from './SubMenu';
import TopMenu from './TopMenu';

const GenericMenu = ({
  item,
  sizes,
  cxEl,
  collapsedState,
  collapseLeftNav,
  avatarUrl,
  shouldShowEnforcementDialog,
  onShowEnforcementDialog,
  canCloseEnforcementDialog,
}) => (
  <Box key={item.get('name')}>
    {item.get('name') === 'Settings' ? (
      <TopMenu
        item={item}
        sizes={sizes}
        cxEl={cxEl}
        collapsedState={collapsedState}
        collapseLeftNav={collapseLeftNav}
        onClickFunction={item.get('on_click')}
        avatarUrl={avatarUrl}
        shouldShowEnforcementDialog={shouldShowEnforcementDialog}
        canCloseEnforcementDialog={canCloseEnforcementDialog}
        onShowEnforcementDialog={onShowEnforcementDialog}
      />
    ) : (
      <TopMenu
        item={item}
        sizes={sizes}
        cxEl={cxEl}
        collapsedState={collapsedState}
        collapseLeftNav={collapseLeftNav}
        onClickFunction={item.get('on_click')}
        avatarUrl={avatarUrl}
        shouldShowEnforcementDialog={shouldShowEnforcementDialog}
        canCloseEnforcementDialog={canCloseEnforcementDialog}
        onShowEnforcementDialog={onShowEnforcementDialog}
      />
    )}

    {!collapsedState && item.get('sub_items') && (
      <Box
        maxh={item.get('active') ? 500 : 0}
        overflow="hidden"
        style={
          item.get('active')
            ? { transition: 'max-height 1s ease-out' }
            : { transition: 'max-height 0.25s ease-in' }
        }
      >
        {item.get('sub_items').map(subItem => (
          <SubMenu
            key={subItem.get('name')}
            item={subItem}
            sizes={sizes}
            cxEl={cxEl}
            collapseLeftNav={collapseLeftNav}
            onClickFunction={subItem.get('on_click')}
          />
        ))}
      </Box>
    )}
  </Box>
);

export default GenericMenu;
