export const APPROVED_ITEM_HEIGHT = 30;
export const APPROVED_PADDING = 30;
export const MONTH_FORMAT = 'MM-YYYY';

export const TIME_OFF_CATEGORY_COLOR_MAP = {
  vacation: 'blue',
  vacation_paid: 'violet',
  holiday: 'green',
  jury_duty: 'red',
  sick_leave: 'orange',
  personal_medical_emergency: 'yellow',
  family_medical_emergency: 'blueSteel',
  other: 'grayDark',
};

export const UNPAID_TIME_OFF = 'unpaid_time_off';
export const PTO = 'pto';
export const PAID_SICK_LEAVE = 'paid_sick_leave';
export const FFCRA = 'ffcra';
const FFCRA_PAID_SICK_LEAVE = 'ffcra_paid_sick_leave';
const FFCRA_CARE_FOR_OTHERS = 'ffcra_care_for_others';
const FFCRA_CARE_FOR_CHILD = 'ffcra_care_for_child';

export const FFCRA_CATEGORY_NAMES = [
  FFCRA_PAID_SICK_LEAVE,
  FFCRA_CARE_FOR_OTHERS,
  FFCRA_CARE_FOR_CHILD,
];

export const TIME_OFF_CATEGORY_NAMES = {
  unpaid_time_off: UNPAID_TIME_OFF,
  pto: PTO,
  paid_sick_leave: PAID_SICK_LEAVE,
};

export const TIME_OFF_LOCATION_SETTINGS_MODAL =
  'TIME_OFF_LOCATION_SETTINGS_MODAL';

export const TIME_OFF_REQUESTS_DETAILS_MODAL =
  'TIME_OFF_REQUESTS_DETAILS_MODAL';

export const TIME_OFF_EMPLOYEE_BALANCE_HISTORY_MODAL =
  'TIME_OFF_EMPLOYEE_BALANCE_HISTORY_MODAL';

export const PTO_DASHBOARD = 'PTO Dashboard';
export const PTO_REQUEST_WIDGET = 'PTO Requests Widget';
export const PTO_BALANCES_WIDGET = 'PTO Balances Widget';
export const PTO_POLICIES_WIDGET = 'PTO Policies Widget';
