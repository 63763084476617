export const scheduleBuilderRoute = () => '/schedule_builder';

export const timesheetsRoute = () => '/timesheets';

export const teamRoute = () => '/team';

export const settingsRoute = () => '/settings';

export const generalSettingsRoute = () => '/settings/location/basic_info';

export const webTimeclockRoute = () => '/web_timeclock';

export const employeesRoute = () => '/employees';
