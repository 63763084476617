import './ActiveTrialNavItem.scss';

import React, { PureComponent } from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

import { MANAGE_PLANS_PATH } from 'features/managePlan/constants';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';
import TooltipWrapper from 'components/TooltipWrapper';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@withNavItemData('activeTrial')
@cxHelpers('ActiveTrialNavItem')
export default class ActiveTrialNavItem extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    showTooltip: PropTypes.bool,
    hidden: PropTypes.bool,
    showTrialCounter: PropTypes.bool,
    fullText: PropTypes.bool,
  };

  handleClick = () => {
    trackUxEvent({
      productArea: PRODUCT_AREAS.NAVIGATION,
      eventCategory: EVENT_CATEGORIES.NAV,
      eventAction: EVENT_ACTIONS.ALL_ACCESS_PASS_CLICKED,
    });

    window.Homebase.RailsReactBridge.navigateToReactView(MANAGE_PLANS_PATH);
  };

  showTooltip = () => this.props.showTooltip;

  render() {
    const { hidden, fullText, text } = this.props;

    if (hidden) {
      return null;
    }

    return (
      <NavItem
        mlxs
        whiteText
        className={this.cx({}, 'heap--navbar-13')}
        onClick={this.handleClick}
      >
        <TooltipWrapper
          enabled={this.showTooltip()}
          content={toI18n('nav_links.tiers.all_access_tooltip')}
        >
          <Box vcenter className={this.cxEl('link')}>
            <Box mrxxxs mbxxxs>
              <Icon type="gift" size={22} />
            </Box>
            <MediaQuery minWidth={fullText ? 480 : 1025}>
              <Text fs14 className={this.cxEl('text')}>
                {text}
              </Text>
            </MediaQuery>
          </Box>
        </TooltipWrapper>
      </NavItem>
    );
  }
}
