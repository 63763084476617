import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSend = ({
  alt = 'Send Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0Zm5.934 11.934a1.5 1.5 0 0 1-2.12 0L13.5 9.624V18a1.5 1.5 0 1 1-3 0V9.623l-2.316 2.311a1.5 1.5 0 1 1-2.12-2.12l4.832-4.834A1.57 1.57 0 0 1 12 4.5c.229 0 .687.065 1.062.44l4.833 4.833c.625.629.625 1.575.04 2.161Z"
    />
  </svg>
);
export default SvgSend;
