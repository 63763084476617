import { Overrides } from '../../types';
import baseColors from '../colors';

export const radioOverrides: Overrides = {
  MuiRadio: {
    styleOverrides: {
      root: {
        '&.FDBRadioButton': {
          color: baseColors.purple500,
          padding: 0,
          height: '32px',
          width: '32px',
          borderRadius: '50%',
          '& svg': {
            height: '20px',
            width: '20px',
          },
          '&.Mui-checked': {
            color: baseColors.purple500,
          },
          '&[aria-disabled="true"]': {
            pointerEvents: 'none',
            color: baseColors.mono500,
            backgroundColor: baseColors.mono300,
            outline: `1px solid ${baseColors.mono0}`,
            boxShadow: `inset 0 0 0 8px ${baseColors.mono0}`,

            '&:hover': {
              backgroundColor: 'transparent',
            },
            '& input': {
              cursor: 'not-allowed',
            },
          },
          '&:hover:not([aria-disabled="true"])': {
            color: baseColors.purple700,
            backgroundColor: baseColors.purple100,
          },
          '&:active:not([aria-disabled="true"])': {
            color: baseColors.purple700,
            backgroundColor: baseColors.purple300,

            '& input': {
              '&:focus-visible + span svg:nth-of-type(1)': {
                outline: 'none',
              },
            },
          },
          '&:focus-visible': {
            backgroundColor: baseColors.mono0,
            Color: baseColors.purple700,
          },

          '& input': {
            width: '32px',
            height: '32px',
            '&:focus-visible + span svg:nth-of-type(1)': {
              outline: `solid 2px ${baseColors.accent500}`,
              borderRadius: '50%',
            },
          },
        },
      },
    },
  },
};
