import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteJSON, fetchJSON, postJSON, putJSON } from 'api/fetch';

import { buildGetDrawerOpen } from 'selectors/drawers';

import { toI18n } from 'util/i18n';

import { MESSENGER_VIEW_DRAWER_KEY } from '../drawers/keys';

import {
  createChannelApiRoute,
  createMessageApiRoute,
  deleteMessageApiRoute,
  fetchChannelParticipantsApiRoute,
  getChannelMessagesApiRoute,
  getChannelParticipantsApiRoute,
  getChannelsApiRoute,
  getLocationParticipantsApiRoute,
  getMessageReadReceiptsApiRoute,
  markMessageAsReadApiRoute,
  updateChannelApiRoute,
} from './apiRoutes';
import {
  ACTION_TYPES,
  DEFAULT_EXISTING_PARTICIPANTS_PER_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
  DEFAULT_PARTICIPANTS_PER_PAGE,
  DEFAULT_READ_RECEIPTS_PER_PAGE,
  FIRST_PAGE,
  VIEWS,
} from './constants';
import {
  addChannel,
  addNewMessage,
  replaceMessage,
  setChannelParticipants,
  setChannels,
  setCurrentChatView,
  setExistingChannelParticipants,
  setLocationParticipants,
  setMessageReadList,
  setMessages,
  updateChannel as updateChannelAction,
  updateMessage,
} from './slice';
import { createTempMessage } from './util';

const {
  FETCH_CHANNELS,
  UPDATE_CHANNEL,
  CREATE_MESSAGE,
  RESEND_MESSAGE,
  FETCH_EXISTING_CHANNEL_PARTICIPANTS,
  FETCH_CHANNEL_MESSAGES,
  FETCH_MESSAGE_READ_LIST,
  FETCH_LOCATION_PARTICIPANTS,
  FETCH_CHANNEL_PARTICIPANTS,
  MARK_MESSAGE_AS_READ,
  CREATE_CHANNEL,
  DELETE_MESSAGE,
  TOGGLE_MESSENGER,
} = ACTION_TYPES;

export const fetchChannels = createAsyncThunk(
  FETCH_CHANNELS,
  ({ itemsPerPage = DEFAULT_ITEMS_PER_PAGE, lastItemId }, { dispatch }) =>
    fetchJSON(getChannelsApiRoute({ itemsPerPage, lastItemId })).then(
      response => {
        dispatch(setChannels(response));
      }
    )
);

export const fetchExistingChannelParticipants = createAsyncThunk(
  FETCH_EXISTING_CHANNEL_PARTICIPANTS,
  (channelId, { dispatch }) =>
    fetchJSON(
      fetchChannelParticipantsApiRoute({
        channelId,
        page: FIRST_PAGE,
        perPage: DEFAULT_EXISTING_PARTICIPANTS_PER_PAGE,
      })
    ).then(({ participants, total_items: totalItems }) => {
      if (totalItems > participants.length) {
        participants.push({
          name: toI18n('message_drawer.remaining_participants', {
            props: { remainingParticipants: totalItems - participants.length },
          }),
        });
      }

      dispatch(setExistingChannelParticipants({ channelId, participants }));
    })
);

export const fetchChannelMessages = createAsyncThunk(
  FETCH_CHANNEL_MESSAGES,
  (
    { channelId, page = FIRST_PAGE, perPage = DEFAULT_ITEMS_PER_PAGE },
    { dispatch }
  ) =>
    fetchJSON(getChannelMessagesApiRoute({ channelId, page, perPage })).then(
      response => {
        dispatch(setMessages({ channelId, ...response }));
      }
    )
);

export const fetchMessageReadList = createAsyncThunk(
  FETCH_MESSAGE_READ_LIST,
  (
    { channelId, messageId, page, perPage = DEFAULT_READ_RECEIPTS_PER_PAGE },
    { dispatch }
  ) =>
    fetchJSON(
      getMessageReadReceiptsApiRoute({ messageId, page, perPage })
    ).then(response => {
      dispatch(setMessageReadList({ ...response, channelId, messageId }));
    })
);

export const fetchLocationParticipants = createAsyncThunk(
  FETCH_LOCATION_PARTICIPANTS,
  (
    {
      locationId,
      page = FIRST_PAGE,
      perPage = DEFAULT_PARTICIPANTS_PER_PAGE,
      filter,
    },
    { dispatch }
  ) =>
    fetchJSON(
      getLocationParticipantsApiRoute({ page, filter, perPage, locationId })
    ).then(response => {
      dispatch(setLocationParticipants({ ...response, locationId, filter }));
    })
);

export const fetchChannelParticipants = createAsyncThunk(
  FETCH_CHANNEL_PARTICIPANTS,
  (
    {
      channelId,
      page = FIRST_PAGE,
      perPage = DEFAULT_PARTICIPANTS_PER_PAGE,
      filter,
      locationId,
    },
    { dispatch }
  ) =>
    fetchJSON(
      getChannelParticipantsApiRoute({
        channelId,
        page,
        perPage,
        filter,
        locationId,
      })
    ).then(response => {
      dispatch(
        setChannelParticipants({ ...response, channelId, filter, locationId })
      );
    })
);

export const toggleMessengerShown = isShown => (dispatch, getState) =>
  dispatch({
    type: TOGGLE_MESSENGER,
    payload: {
      drawerKey: MESSENGER_VIEW_DRAWER_KEY,
      drawerOpen:
        isShown === undefined
          ? !buildGetDrawerOpen(MESSENGER_VIEW_DRAWER_KEY)(getState())
          : !!isShown,
    },
  });

export const createChannel = createAsyncThunk(
  CREATE_CHANNEL,
  ({ prefilledMessage, ...channelData }, { dispatch }) =>
    postJSON(createChannelApiRoute(), channelData).then(channel => {
      const addedChannel = dispatch(addChannel(channel));

      if (prefilledMessage)
        dispatch(
          setCurrentChatView({
            prefilledMessage,
            view: VIEWS.SHOW,
            channelId: channel.id,
          })
        );

      return addedChannel;
    })
);

export const updateChannel = createAsyncThunk(
  UPDATE_CHANNEL,
  ({ channel, channelId: id }, { dispatch }) =>
    putJSON(updateChannelApiRoute(id), channel).then(() => {
      if (channel.name)
        dispatch(updateChannelAction({ id, name: channel.name }));
    })
);

export const markMessageAsRead = createAsyncThunk(
  MARK_MESSAGE_AS_READ,
  ({ messageId: id, channelId: channel_id }, { dispatch }) =>
    postJSON(markMessageAsReadApiRoute(id)).then(() => {
      dispatch(updateMessage({ id, channel_id, is_unread: false }));
    })
);

export const createMessage = createAsyncThunk(
  CREATE_MESSAGE,
  ({ message, channelId }, { dispatch }) => {
    const { attachment_attributes, body } = message.message;

    const temporaryMessage = createTempMessage({
      body,
      attachment: attachment_attributes?.file,
      channel_id: channelId,
    });

    dispatch(addNewMessage(temporaryMessage));

    return postJSON(createMessageApiRoute(channelId), message)
      .then(response =>
        dispatch(
          replaceMessage({
            message: response,
            replacementId: temporaryMessage.id,
          })
        )
      )
      .catch(() => {
        dispatch(
          updateMessage({
            failed: true,
            channel_id: channelId,
            id: temporaryMessage.id,
          })
        );
      });
  }
);

export const resendMessage = createAsyncThunk(
  RESEND_MESSAGE,
  ({ message, channelId }, { dispatch }) => {
    const { attachment, body } = message;

    const requestBody = {
      message: {
        [attachment ? 'attachment_attributes' : 'body']: attachment
          ? { file: attachment.origin_url }
          : body,
      },
    };

    dispatch(updateMessage({ ...message, failed: false }));

    return postJSON(createMessageApiRoute(channelId), requestBody)
      .then(response => {
        dispatch(
          replaceMessage({
            message: response,
            replacementId: message.id,
          })
        );
      })
      .catch(() => {
        dispatch(
          updateMessage({
            failed: true,
            id: message.id,
            channel_id: channelId,
          })
        );
      });
  }
);

export const deleteMessage = createAsyncThunk(
  DELETE_MESSAGE,
  ({ messageId: id, channelId: channel_id }, { dispatch }) => {
    const message = { id, channel_id, is_temp: false };

    dispatch(updateMessage({ ...message, is_temp: true }));

    return deleteJSON(deleteMessageApiRoute(id))
      .then(() =>
        dispatch(
          updateMessage({ ...message, body: '[archived]', is_archived: true })
        )
      )
      .catch(() => {
        dispatch(updateMessage(message));
      });
  }
);
