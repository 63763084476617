import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorTikTok = ({
  alt = 'Tiktok Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M21.787 12.2A10.622 10.622 0 0 0 28 14.197V9.722c-.439 0-.876-.046-1.305-.138v3.522c-2.317 0-4.462-.739-6.213-1.995v9.13c0 4.569-3.69 8.271-8.241 8.271a8.18 8.18 0 0 1-4.588-1.399 8.201 8.201 0 0 0 5.893 2.489c4.551 0 8.24-3.703 8.24-8.271v-9.13Zm1.61-4.514a6.247 6.247 0 0 1-1.61-3.65V3.46H20.55a6.265 6.265 0 0 0 2.846 4.226ZM10.531 23.608a3.776 3.776 0 0 1-.769-2.29 3.776 3.776 0 0 1 4.913-3.606v-4.574a8.282 8.282 0 0 0-1.304-.075v3.56a3.776 3.776 0 0 0-4.914 3.606c0 1.477.844 2.756 2.074 3.38Z"
      fill="#FF004F"
    />
    <path
      d="M20.482 11.111a10.624 10.624 0 0 0 6.213 1.995V9.584a6.231 6.231 0 0 1-3.299-1.898A6.265 6.265 0 0 1 20.55 3.46h-3.248v17.87a3.777 3.777 0 0 1-3.77 3.771 3.76 3.76 0 0 1-3-1.493 3.784 3.784 0 0 1-2.074-3.38 3.776 3.776 0 0 1 4.914-3.605v-3.56c-4.471.092-8.067 3.759-8.067 8.268 0 2.25.895 4.291 2.349 5.782a8.18 8.18 0 0 0 4.587 1.4c4.551 0 8.241-3.704 8.241-8.271v-9.13Z"
      fill="#000"
    />
    <path
      d="M26.695 9.584V8.63a6.196 6.196 0 0 1-3.299-.945 6.226 6.226 0 0 0 3.3 1.898ZM20.55 3.459a6.392 6.392 0 0 1-.068-.513V2.37h-4.485v17.871a3.776 3.776 0 0 1-3.77 3.77c-.61 0-1.185-.145-1.695-.403a3.76 3.76 0 0 0 3 1.492 3.777 3.777 0 0 0 3.77-3.77V3.46h3.248Zm-7.178 9.603v-1.014a8.305 8.305 0 0 0-1.13-.077C7.69 11.971 4 15.674 4 20.241a8.27 8.27 0 0 0 3.654 6.872 8.257 8.257 0 0 1-2.35-5.783c0-4.509 3.597-8.175 8.068-8.268Z"
      fill="#00F2EA"
    />
  </svg>
);
export default SvgColorTikTok;
