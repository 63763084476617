import { List } from 'immutable';
import { uniqBy } from 'lodash';

import {
  ROLE_HEADER_OPTION_NAMES,
  ZIP_RECRUITER,
} from 'features/hiring/constants';

import { toI18n } from 'util/i18n';

const HIRING_MANAGER_PATHS = ['/hiring', '/cmp/', '/loc/'];

export const leftHiringToNonHiringReact = (_state, { lastLocation }) => {
  // When a user navigates from a Hiring page to a non-Hiring, React-based page.
  // Please reflect any changes made to the logic here in hiring_helper.rb
  if (lastLocation) {
    const lastPathname = lastLocation.pathname;
    const currentPathname = window.location.href;

    return (
      HIRING_MANAGER_PATHS.some(path => lastPathname.includes(path)) &&
      !HIRING_MANAGER_PATHS.some(path => currentPathname.includes(path))
    );
  }
};

export const buildHiringRoleOptions = (
  standardizedRoles,
  customRoles = List()
) => {
  const options = [];

  const STANDARDIZED_ROLES_HEADER_OPTION = {
    label: ROLE_HEADER_OPTION_NAMES.STANDARDIZED_ROLES,
    value: ROLE_HEADER_OPTION_NAMES.STANDARDIZED_ROLES,
    disabled: true,
  };

  const CUSTOM_ROLES_HEADER_OPTION = {
    label: ROLE_HEADER_OPTION_NAMES.CUSTOM_ROLES,
    value: ROLE_HEADER_OPTION_NAMES.CUSTOM_ROLES,
    disabled: true,
  };

  const formattedStandardizedRoles = standardizedRoles.map(role => ({
    value: role.get('id'),
    label: role.get('name'),
  }));

  const formattedCustomRoles = customRoles.map(role => {
    const value = role.get('name');
    return { value, label: value };
  });

  options.push(
    STANDARDIZED_ROLES_HEADER_OPTION,
    ...formattedStandardizedRoles,
    CUSTOM_ROLES_HEADER_OPTION,
    ...formattedCustomRoles
  );

  return List(options);
};

export const buildOptions = (interviews, i18nKey, interviewAttr) => {
  const options = [
    { value: '', label: toI18n(`hiring.interviews.${i18nKey}.default`) },
  ];

  interviews.forEach(interview => {
    const filter = interview.get(interviewAttr);

    options.push({ value: filter, label: filter });
  });

  return List(uniqBy(options, 'value'));
};

export const formatPrefillLastMessageBody = (lastMessage, application) => {
  const lastMessageBody = lastMessage.get('body');
  const lastMessageApplicantFullName = lastMessage.get('applicant_full_name');
  const lastMessageApplicantShortName = lastMessage.get('applicant_short_name');
  const lastMessageApplicantFirstName = lastMessage.get('applicant_first_name');
  const lastMessageApplicantLastName = lastMessage.get('applicant_last_name');
  let newLastMessageBody = lastMessageBody;

  if (lastMessageBody.includes(lastMessageApplicantFullName)) {
    newLastMessageBody = lastMessageBody.replace(
      lastMessageApplicantFullName,
      application.get('full_name')
    );
  } else if (lastMessageBody.includes(lastMessageApplicantShortName)) {
    newLastMessageBody = lastMessageBody.replace(
      lastMessageApplicantShortName,
      application.get('short_name')
    );
  } else if (lastMessageBody.includes(lastMessageApplicantFirstName)) {
    newLastMessageBody = lastMessageBody.replace(
      lastMessageApplicantFirstName,
      application.get('first_name')
    );
  } else if (lastMessageBody.includes(lastMessageApplicantLastName)) {
    newLastMessageBody = lastMessageBody.replace(
      lastMessageApplicantLastName,
      application.get('last_name')
    );
  }

  return newLastMessageBody;
};

export const isPremiumBoostsNotAvailable = (jobRequest, partnerName) =>
  !jobRequest.get('company_syndicatable') ||
  (partnerName === ZIP_RECRUITER && !jobRequest.get('company_zr_syndicatable'));
