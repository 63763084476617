import React from 'react';
import Icon from 'react-icon-base';

const ThumbsDown = props => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M21.572 11.484l.76.393c.502.26.814.775.814 1.346 0 .832-.657 1.508-1.465 1.508l-6.212.081.527 4.935c-.001.967-.552 2.453-1.383 2.453h-.744l-.658-3.547-3.444-5.49c-.69-.996-.952-1.587-1.052-2.213V3.527c.214-.872.98-1.52 1.892-1.52h8.736c.808 0 1.465.676 1.465 1.507 0 .34-.11.662-.319.933l-.55.713h.936c.807 0 1.464.676 1.464 1.508 0 .35-.121.693-.342.964l-.392.481.572.216c.58.217.968.786.968 1.415 0 .57-.311 1.087-.813 1.346l-.76.394zm-13.711-.34c0 .49-.387.888-.862.888H1.717a.876.876 0 0 1-.862-.888V2.767c0-.49.387-.887.862-.887H7c.475 0 .862.398.862.887v8.377zm15.405.34c.468-.45.734-1.074.734-1.74 0-.806-.402-1.56-1.058-2 .165-.333.252-.702.252-1.076 0-1.07-.7-2.009-1.687-2.297a2.41 2.41 0 0 0 .155-.857c0-1.316-1.04-2.387-2.319-2.387h-8.735c-.807 0-1.532.354-2.045.916A1.716 1.716 0 0 0 6.999 1H1.717C.771 1 0 1.793 0 2.767v8.377c0 .975.77 1.767 1.717 1.767H7a1.69 1.69 0 0 0 1.269-.581c.183.384.436.813.793 1.327l3.357 5.352.743 4.071h1.453c1.364 0 2.235-1.983 2.235-3.35l-.49-4.042 5.324-.077c1.276 0 2.317-1.07 2.317-2.388a2.4 2.4 0 0 0-.734-1.74z" />
    </g>
  </Icon>
);

export default ThumbsDown;
