import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgReportDocument = ({
  alt = 'Report Document Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M6 0C4.345 0 3 1.345 3 3v18c0 1.655 1.345 3 3 3h12c1.655 0 3-1.345 3-3V7.5h-6c-.83 0-1.5-.67-1.5-1.5V0H6Zm9 0v6h6l-6-6ZM7.125 10.5h9.75c.83 0 1.5.67 1.5 1.5v7.5c0 .83-.67 1.5-1.5 1.5h-9.75c-.83 0-1.5-.67-1.5-1.5V12c0-.83.67-1.5 1.5-1.5Zm0 5.25h2.25V13.5h-2.25v2.25Zm3.75 0h2.25V13.5h-2.25v2.25Zm3.75 0h2.25V13.5h-2.25v2.25Zm0 1.5v2.25h2.25v-2.25h-2.25Zm-1.5 0h-2.25v2.25h2.25v-2.25Zm-3.75 0h-2.25v2.25h2.25v-2.25Z"
    />
  </svg>
);
export default SvgReportDocument;
