import './ModalHeader.scss';

import React, { PureComponent } from 'react';

import Box from 'components/Box';
import Text from 'components/Text';

import cxHelpers from 'util/className';

@cxHelpers('ModalHeader')
export default class ModalHeader extends PureComponent {
  render() {
    return (
      <Box mbs>
        <Text fs24 navy fw600 {...this.props} className={this.cx()} />
      </Box>
    );
  }
}
