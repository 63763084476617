import React from 'react';
import Icon from 'react-icon-base';

const MoneyBillTransfer = props => (
  <Icon width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
    <path
      d="M17.4375 3L16.7188 2.28125C16.4062 2 16.4062 1.53125 16.7188 1.25C17 0.9375 17.4688 0.9375 17.75 1.25L19.75 3.25C19.9062 3.375 20 3.5625 20 3.75C20 3.96875 19.9062 4.15625 19.75 4.28125L17.75 6.28125C17.4688 6.59375 17 6.59375 16.7188 6.28125C16.4062 6 16.4062 5.53125 16.7188 5.21875L17.4375 4.5H5V5C5 6.125 4.09375 7 3 7H2.5V9.9375L1 11.4375V5C1 3.90625 1.875 3 3 3H11.9688H12H17.4375ZM17.5 11V8.09375L19 6.59375V13C19 14.125 18.0938 15 17 15H2.53125L3.25 15.7188C3.5625 16.0312 3.5625 16.5 3.25 16.7812C2.96875 17.0938 2.5 17.0938 2.21875 16.7812L0.21875 14.7812C0.0625 14.6562 0 14.4688 0 14.25C0 14.0625 0.0625 13.875 0.21875 13.7188L2.21875 11.7188C2.5 11.4375 2.96875 11.4375 3.25 11.7188C3.5625 12.0312 3.5625 12.5 3.25 12.7812L2.53125 13.5H7.96875C8 13.5 8 13.5 8 13.5H15V13C15 11.9062 15.875 11 17 11H17.5ZM7 9C7 7.34375 8.3125 6 10 6C11.6562 6 13 7.34375 13 9C13 10.6562 11.6562 12 10 12C8.3125 12 7 10.6562 7 9Z"
      fill="black"
    />
  </Icon>
);

export default MoneyBillTransfer;
