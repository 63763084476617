import React, { useMemo } from 'react';
import { padStart } from 'lodash';

import Box from 'components/Box';
import Text from 'components/Text';

const PayrollCountdownBannerNumbers = ({ timeLeft, label }) => {
  // pads a "0" to the beginning of timeLeft if less than two digits
  const formattedTimeLeft = useMemo(
    () => padStart(timeLeft.toString(), 2, '0'),
    [timeLeft]
  );

  return (
    <Box column pr16>
      <Box row>
        {[...formattedTimeLeft].map((number, i) => (
          <Box
            key={`${number}_${i}`}
            w={26}
            h={32}
            bg="#57416B"
            bradius="4px"
            vcenter
            hcenter
            tcenter
            mh={2}
          >
            <Text fw600 fs16 white lh={1.5}>
              {number}
            </Text>
          </Box>
        ))}
      </Box>
      <Text
        white
        fw600
        fs10
        lh="16px"
        i18n={`nav_links.payroll_countdown_timer.${label}`}
        i18nProps={{ count: 0 }} // always use plural
      />
    </Box>
  );
};

export default PayrollCountdownBannerNumbers;
