import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgDesktop = ({
  alt = 'Desktop Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M2.667 1.3A2.67 2.67 0 0 0 0 3.966v12a2.67 2.67 0 0 0 2.667 2.667H10l-.446 1.334H6.667a1.332 1.332 0 1 0 0 2.666h10.666a1.332 1.332 0 1 0 0-2.667h-2.887L14 18.634h7.333A2.67 2.67 0 0 0 24 15.967v-12A2.67 2.67 0 0 0 21.333 1.3H2.667Zm18.666 2.666V13.3H2.667V3.966h18.666Z"
    />
  </svg>
);
export default SvgDesktop;
