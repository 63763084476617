import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { readRequest } from 'actions/notifications';

import { toI18n } from 'util/i18n';

import RequestsListItem from '../RequestsListItem';

@connect(() => ({}), { readRequest })
export default class Personal extends PureComponent {
  static propTypes = {
    readRequest: PropTypes.func.isRequired,
  };

  handleApprove = () => this.props.readRequest(this.props.item);

  render() {
    return (
      <RequestsListItem
        showDeclineButton={false}
        acceptButtonCopy={toI18n('actions.accept')}
        body={this.props.item.get('body')}
        onApprove={this.handleApprove}
        {...this.props}
      />
    );
  }
}
