import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import * as thunks from './thunks';

const SLICE_NAME = 'calculateTipOutsDrawer';

export const initialState = fromJS({
  selectedPayPeriod: [],
  tipInsLoading: false,
  tipOutsLoading: false,
  tipInsData: {
    tips_by_day: [],
    total_tips: 0,
    errors: [],
  },
  tipOutsData: {},
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: builder => {
    builder
      .addCase(thunks.fetchTipInsData.pending, state =>
        state.set(
          'tipInsData',
          fromJS({
            tips_by_day: [],
            total_tips: 0,
          }).set('tipOutsData', fromJS({}))
        )
      )
      .addCase(thunks.fetchTipInsData.fulfilled, (state, action) =>
        state.set('selectedPayPeriod', [
          action.meta.arg.startDate,
          action.meta.arg.endDate,
        ])
      )
      .addCase(thunks.fetchTipInsPOSData.pending, state =>
        state.set('tipInsLoading', true)
      )
      .addCase(thunks.fetchTipInsPOSData.fulfilled, (state, action) =>
        state
          .set('tipInsLoading', false)
          .mergeDeepIn(
            ['tipInsData', 'tips_by_day'],
            action.payload.tips_by_day.map(day => ({
              date: day.date,
              imported_pos_tips: !isNaN(day.total_tips)
                ? Number(day.total_tips)
                : day.total_tips,
            }))
          )
          .setIn(['tipInsData', 'total_tips'], action.payload.total_tips)
          .setIn(['tipInsData', 'errors'], fromJS(action.payload.errors))
      )
      .addCase(thunks.fetchTipInsPOSData.rejected, state =>
        state.set('tipInsLoading', false)
      )
      .addCase(thunks.fetchTipInsEditableData.fulfilled, (state, action) =>
        state
          .set('tipInsLoading', false)
          .mergeDeepIn(['tipInsData', 'tips_by_day'], action.payload)
      )
      .addCase(thunks.fetchTipOutsData.pending, (state, action) =>
        state
          .set('tipOutsLoading', true)
          .set('selectedPayPeriod', [
            action.meta.arg.startDate,
            action.meta.arg.endDate,
          ])
          .set('tipOutsData', fromJS({}))
      )
      .addCase(thunks.fetchTipOutsData.fulfilled, (state, action) =>
        state
          .set('tipOutsLoading', false)
          .set('tipOutsData', fromJS(action.payload))
      )
      .addCase(thunks.fetchTipOutsData.rejected, (state, action) =>
        state
          .set('tipOutsLoading', false)
          .set('tipOutsData', fromJS(action.payload))
      )
      .addCase(thunks.updateTipsByDay.fulfilled, (state, action) =>
        state.mergeDeepIn(['tipInsData', 'tips_by_day'], action.payload)
      );
  },
});

export const { actions, reducer } = slice;
