import React from 'react';
import Icon from 'react-icon-base';

const ForkKnife = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M15.97 9.143c-.089-.625-1.339-.625-1.428.03v4.196c-.03.09-.447.09-.476 0-.03-.744-.209-4.137-.239-4.226-.089-.625-1.31-.625-1.398 0-.03.089-.209 3.482-.239 4.226-.03.09-.446.09-.476 0V9.172c-.089-.654-1.339-.654-1.428-.03-.03.12-.476 2.798-.476 3.81 0 1.578.803 2.679 2.023 3.125l-.357 7.083c-.03.417.298.745.714.745h1.905c.387 0 .715-.328.715-.745l-.387-7.083c1.22-.446 2.053-1.547 2.053-3.125 0-1.012-.476-3.69-.506-3.81zm3.572 8.482l-.447 5.506c-.06.416.298.774.715.774h1.666c.387 0 .715-.298.715-.715V9.38c0-.386-.328-.714-.715-.714-2.47 0-6.607 5.328-1.934 8.959z"
    />
  </Icon>
);

export default ForkKnife;
