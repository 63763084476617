import createCachedSelector from 're-reselect';

import { MODULE_NAME, RESOURCES_SLICE } from '../module-constants';

const selectSlice = state =>
  state.getIn([MODULE_NAME])?.[RESOURCES_SLICE] || {};

// is entity pending
export const selectIsPending = createCachedSelector(
  selectSlice,
  (_, props) => props.name,
  (_, props) => props.action,
  (_, props) => props.id?.toString() || '1',
  (slice, name, action, id) => {
    const pending = slice[name]?.[action]?.[id]?.pending;
    // For fetch, if the resource has never been fetched, it is considered
    // pending.
    return action === 'fetch' && pending === undefined ? true : !!pending;
  }
)(
  (_, { name, action, id }) =>
    `fe-core.Resources.selectIsPending(${name}, ${action}, ${id})`
);

export const selectError = createCachedSelector(
  selectSlice,
  (_, props) => props.name,
  (_, props) => props.action,
  (_, props) => props.id?.toString() || '1',
  (slice, name, action, id) => slice[name]?.[action]?.[id]?.error || ''
)(
  (_, { name, action, id }) =>
    `fe-core.Resources.selectError(${name}, ${action}, ${id})`
);
