import { getOriginContextStartingPath, trackUxEvent } from 'util/tracking';
import {
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
  TRACK_STARTING_PATHS,
} from 'util/tracking_constants';

export const EVENT_ACTIONS = {
  DECLINE_TIME_OFF_CLICKED: 'Clicked Decline Time Off',
  APPROVE_TIME_OFF_CLICKED: 'Clicked Approve Time Off',
};

const ptoRequestStartingPath = v3Enabled => {
  const startingPath = getOriginContextStartingPath();
  let pathUrl = TRACK_STARTING_PATHS.TIME_OFFS;

  if (!!startingPath) {
    if (startingPath === TRACK_STARTING_PATHS.DASHBOARD) {
      if (v3Enabled) {
        pathUrl = TRACK_STARTING_PATHS.DASHBOARD_V3;
      } else {
        pathUrl = TRACK_STARTING_PATHS.DASHBOARD_V1;
      }
    } else {
      pathUrl = startingPath;
    }
  }

  return {
    starting_path: pathUrl,
  };
};

export const trackPTORequestEvents = ({ approved, dashboardV3Enabled }) => {
  const eventAction = approved
    ? EVENT_ACTIONS.APPROVE_TIME_OFF_CLICKED
    : EVENT_ACTIONS.DECLINE_TIME_OFF_CLICKED;

  const properties = ptoRequestStartingPath(dashboardV3Enabled);

  trackUxEvent({
    eventAction,
    eventCategory: EVENT_CATEGORIES.PTO_REQUESTS_WIDGET,
    productArea: PRODUCT_AREAS.PTO,
    actionType: TRACK_ACTION_TYPES.CLICK,
    properties,
  });
};
