import React from 'react';
import Icon from 'react-icon-base';

const AddCircle = props => (
  <Icon
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="transparent"
    {...props}
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="48"
      height="48"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M47 24C47 36.7026 36.7026 47 24 47C11.2975 47 1 36.7026 1 24C1 11.2975 11.2975 1 24 1C36.7026 1 47 11.2975 47 24Z"
        stroke="#219653"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7371 23.4158H25.2004V14.8967C25.2004 14.4017 24.8026 14 24.3125 14C23.8224 14 23.4246 14.4017 23.4246 14.8967V23.4158H14.8879C14.396 23.4158 14 23.8175 14 24.3125C14 24.8075 14.396 25.2092 14.8879 25.2092H23.4246V33.7283C23.4246 34.2233 23.8224 34.625 24.3125 34.625C24.8026 34.625 25.2004 34.2233 25.2004 33.7283V25.2092H33.7371C34.2272 25.2092 34.625 24.8075 34.625 24.3125C34.625 23.8175 34.2272 23.4158 33.7371 23.4158Z"
        fill="#219653"
      />
    </g>
  </Icon>
);

export default AddCircle;
