import React from 'react';
import { connect } from 'react-redux';
import Box from 'fe-components/Box';
import { useFormikContext } from 'formik';

import {
  I18N_JOB_DETAILS_PATH,
  TAX_CLASSIFICATION_OPTIONS,
  US_TAX_CLASSIFICATION_OPTIONS,
} from 'features/team/components/AddEmployeeForm/constants';
import { selectCurrentLocationCountryCode } from 'features/team/selectors';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import ChipGroupField from '../../ChipGroup/ChipGroupField';
const { cxEl } = cxHelpers('JobDetailsSection');
export const TaxClassification = ({ isCountryCodeUS }) => {
  const { values } = useFormikContext();

  return (
    <Box className={cxEl('tax-classification')} mv={8}>
      <ChipGroupField
        name="jobTaxClassification"
        options={
          isCountryCodeUS
            ? US_TAX_CLASSIFICATION_OPTIONS
            : TAX_CLASSIFICATION_OPTIONS
        }
        hasAsterisk={values.includedInPayroll}
        label={toI18n(`${I18N_JOB_DETAILS_PATH}.tax_classification.title`)}
        helperText={toI18n(`${I18N_JOB_DETAILS_PATH}.tax_classification.blurb`)}
        infoTooltip={toI18n(
          `${I18N_JOB_DETAILS_PATH}.tax_classification.tooltip`
        )}
      />
    </Box>
  );
};

export default connect(state => ({
  isCountryCodeUS: selectCurrentLocationCountryCode(state) === 'US',
}))(TaxClassification);
