import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { closeMobileSubscriptionBillingModal } from 'actions/modals';

import {
  getIsMobileSubscriptionBillingModalOpen,
  getMobileSubscriptionBillingSource,
} from 'selectors/modals';

import Box from 'components/Box';
import Modal from 'components/modals/Modal';
import Text from 'components/Text';

import cxHelpers from 'util/className';

const IMAGE_STYLE = { width: '320px' };

@connect(
  (state, props) => ({
    isOpen: getIsMobileSubscriptionBillingModalOpen(state, props),
    billingSource: getMobileSubscriptionBillingSource(state, props),
  }),
  {
    closeMobileSubscriptionBillingModal,
  }
)
@cxHelpers('MobileSubscriptionBillingModal')
export default class MobileSubscriptionBillingModal extends PureComponent {
  static propTypes = {
    closeMobileSubscriptionBillingModal: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    billingSource: PropTypes.string,
  };

  handleClose = () => this.props.closeMobileSubscriptionBillingModal();

  renderLeftColumn = () => (
    <Box w={320} vcenter hcenter column borderbox bg="grayLight" ph32>
      <img
        style={IMAGE_STYLE}
        src={require('./images/face.svg')}
        alt="CS Face"
      />
    </Box>
  );

  renderRightColumn = () => (
    <Box borderbox pt32 pb32 ph36>
      <Box>
        <Text color="black" fw600 fs24 i18n="mobile_subscription_modal.title" />
      </Box>
      <Box mt16 mb30="true">
        <Text
          color="black"
          fs16
          i18n="mobile_subscription_modal.top_content"
          i18nProps={{ billing_source: this.props.billingSource }}
        />
      </Box>
    </Box>
  );

  render() {
    return (
      <Modal
        auto
        noPadding
        isOpen={this.props.isOpen}
        onRequestClose={this.handleClose}
      >
        <Box w="100%" className={this.cx()} row>
          {this.renderLeftColumn()}
          {this.renderRightColumn()}
        </Box>
      </Modal>
    );
  }
}
