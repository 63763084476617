import React from 'react';
import Icon from 'react-icon-base';

const EmptyInterviewState = props => (
  <Icon viewBox="0 0 42 42" {...props}>
    <path
      fill="inherit"
      fillRule="evenodd"
      d="M6.552 6.55c6.31-6.31 16.545-6.31 22.856 0 6.313 6.315 6.313 16.547 0 22.857-6.31 6.314-16.545 6.314-22.856 0-6.313-6.31-6.313-16.542 0-22.856zm24.142-1.284c-7.022-7.021-18.406-7.021-25.428 0-7.021 7.022-7.021 18.408 0 25.426 6.806 6.806 17.712 7.017 24.77.627l10.412 10.413a.908.908 0 1 0 1.285-1.284L31.32 30.033c6.389-7.056 6.18-17.962-.626-24.768zm-8 6.526h.226c.715 0 1.367.28 1.863.742.577.538.941 1.319.941 2.188v7.022c0 1.615-1.258 2.93-2.804 2.93h-9.093c-.367 0-.717-.073-1.038-.208a2.807 2.807 0 0 1-.837-.546 2.988 2.988 0 0 1-.928-2.176v-7.022c0-.9.389-1.704 1-2.242a2.714 2.714 0 0 1 1.803-.688h.228v-.835a.986.986 0 0 1 1.97 0v.835h4.698v-.835a.978.978 0 0 1 .382-.776.987.987 0 0 1 1.588.777v.834zm-7.817-.996a.228.228 0 0 0-.064.158v2.426a.23.23 0 0 0 .227.227.23.23 0 0 0 .228-.227v-2.427a.23.23 0 0 0-.228-.226.23.23 0 0 0-.163.069zm-1.05 1.75h.228v.834a.986.986 0 0 0 1.97 0v-.835h4.698v.835a.986.986 0 0 0 1.97 0v-.835h.227c.117 0 .231.008.342.027a2.08 2.08 0 0 1 .97.48 2.18 2.18 0 0 1 .697 1.25l.023.159c.01.084.014.169.014.257v.1H11.781v-.1c0-.554.195-1.057.515-1.442.174-.207.384-.38.62-.504.275-.146.584-.226.911-.226zM11.78 21.74v-6.16h13.185v6.294c0 1.158-1.203 2.042-2.019 2.042h-9.12c-1.128 0-2.046-.977-2.046-2.176zm9.698-10.786a.23.23 0 0 1 .227-.227.23.23 0 0 1 .228.226v2.427a.23.23 0 0 1-.228.227.224.224 0 0 1-.219-.17l-.008-.057v-2.427zm-2.942 5.295c-.71 0-1.367.227-1.902.615a3.32 3.32 0 0 0-1.365 2.692c0 1.826 1.462 3.307 3.267 3.307a3.24 3.24 0 0 0 2.435-1.104 3.315 3.315 0 0 0 .831-2.203c0-1.17-.6-2.2-1.505-2.785a3.216 3.216 0 0 0-1.761-.522zm2.076 5.272a2.817 2.817 0 0 1-2.076.915c-1.564 0-2.846-1.285-2.846-2.88 0-1.585 1.266-2.88 2.846-2.88.865 0 1.644.392 2.167 1.015.422.5.678 1.15.678 1.865 0 .757-.291 1.45-.769 1.965zm-1.192-.789l-1.07-.788a.15.15 0 0 1-.057-.08l-.007-.047v-2.13a.16.16 0 0 1 .157-.158h.185a.16.16 0 0 1 .159.158v1.953l.928.68a.164.164 0 0 1 .034.228l-.108.15a.144.144 0 0 1-.084.057.134.134 0 0 1-.078 0l-.059-.023z"
      clipRule="evenodd"
    />
  </Icon>
);

export default EmptyInterviewState;
