import { toI18n } from 'util/i18n';

export const ADD_EMPLOYEE_MODAL_TYPE = 'ADD_EMPLOYEE_MODAL';
export const SYNC_EMPLOYEES_MODAL_TYPE = 'SYNC_EMPLOYEES_MODAL';
export const CONNECT_POS_MODAL_TYPE = 'CONNECT_POS_MODAL';
export const SYNC_WARNINGS_MODAL_TYPE = 'SYNC_WARNINGS_MODAL';
export const BULK_ADD_TEAM_MODAL = 'BULK_ADD_MODAL';
export const EMPLOYEE = 'w2';
export const CONTRACTOR = '1099';
export const INDIVIDUAL = 'individual';
export const BUSINESS = 'business';

export const JOB_TAX_CLASSIFICATION_OPTIONS = [
  {
    value: EMPLOYEE,
    label: 'Employee',
  },
  {
    value: CONTRACTOR,
    label: 'Contractor',
  },
];

export const CONTRACTOR_TYPE_OPTIONS = [
  {
    value: INDIVIDUAL,
    label: 'Individual',
  },
  {
    value: BUSINESS,
    label: 'Business',
  },
];

export const PAY_ANYWHERE_ADD_TEAM_LINK =
  'https://inside.payanywhere.com/business/employees';

export const WAGE_TYPE_OPTIONS = [
  {
    value: 0,
    label: toI18n('add_team.individual_drawer.wage_type_options.hour'),
  },
  {
    value: 1,
    label: toI18n('add_team.individual_drawer.wage_type_options.year'),
  },
];
