import { snakeCase } from 'lodash';

export const HOTJAR_ACCEPT_LIST = ['ux'];
const HOTJAR_ACCEPT_LIST_MAP = HOTJAR_ACCEPT_LIST.reduce(
  (result, name) => Object.assign(result, { [name]: true }),
  {}
);

const waitHotjarEvent = new Promise(res =>
  document.addEventListener('hotjar-loaded', () => res(true))
);
const waitHotjarTimeout = new Promise(res =>
  setTimeout(() => res(false), 30000)
);
const waitHotjar = () => Promise.race([waitHotjarEvent, waitHotjarTimeout]);

async function sendEvent({ event_name, event_category, event_action }) {
  if (!HOTJAR_ACCEPT_LIST_MAP[event_name]) return;

  if (!window.hj && !(await waitHotjar())) return;
  window.hj('event', snakeCase(`${event_category} ${event_action}`));
}

export async function hjEvent(type, param) {
  if (!window.hj && !(await waitHotjar())) return;
  window.hj(type, param);
}

export default {
  sendEvent,
};
