import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon/Icon';
import Text from 'fe-design-base/atoms/Text/Text';
import CardContainer from 'fe-design-base/molecules/CardContainer/CardContainer';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';
const { cxEl } = cxHelpers('EmptyRosterOption');
const EmptyRosterOption = ({ title, subtitle, onClick, className }) => (
  <CardContainer onClick={onClick} className={cxEl(className)}>
    <Box p={20} row vcenter spacebetween>
      <Box column gap={8}>
        <Text variant="bodyBold" color="mono900">
          {title}
        </Text>
        <Text variant="bodySm">{subtitle}</Text>
      </Box>
      <Icon iconName="ChevronRight" color="primary500" />
    </Box>
  </CardContainer>
);

EmptyRosterOption.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
};

export default EmptyRosterOption;
