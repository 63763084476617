import './LeftNavigation.scss';

import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Divider from '@material-ui/core/Divider';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';

import Footer from './Footer';
import GenericMenu from './GenericMenu';
import Header from './Header';

const { cx, cxEl } = cxHelpers('LeftNavigation');

const LeftNavigation = ({
  menu,
  sizes,
  collapsedState,
  setCollapsedState,
  mobile,
  setEmployeeBreakWaiverTooltip,
  employeeBreakWaiverTooltipOpen,
  shouldShowEnforcementDialog,
  onShowEnforcementDialog,
  canCloseEnforcementDialog,
}) => {
  const maxWidth =
    window.document.getElementsByClassName('left-nav-container')[0]?.style[
      'min-width'
    ];

  return (
    <>
      <Box
        pb={10}
        pt={10}
        pr={sizes.rightPadding}
        borderbox
        fixed={!mobile}
        className={cx()}
        maxw={maxWidth}
        w={maxWidth}
      >
        {mobile && (
          <Header
            sizes={sizes}
            cxEl={cxEl}
            collapsedState={collapsedState}
            collapseLeftNav={partial(setCollapsedState, true)}
          />
        )}
        <Box className={cxEl('wrapper')}>
          {menu.map(item => (
            <GenericMenu
              key={item.get('name')}
              item={item}
              sizes={sizes}
              cxEl={cxEl}
              collapsedState={collapsedState}
              collapseLeftNav={partial(setCollapsedState, true)}
              employeeBreakWaiverTooltipOpen={employeeBreakWaiverTooltipOpen}
              setEmployeeBreakWaiverTooltip={setEmployeeBreakWaiverTooltip}
              shouldShowEnforcementDialog={shouldShowEnforcementDialog}
              canCloseEnforcementDialog={canCloseEnforcementDialog}
              onShowEnforcementDialog={onShowEnforcementDialog}
            />
          ))}

          <Divider />
          <Footer
            sizes={sizes}
            cxEl={cxEl}
            collapsedState={collapsedState}
            collapseLeftNav={setCollapsedState}
            mobile={mobile}
          />
        </Box>
      </Box>
    </>
  );
};

LeftNavigation.propTypes = {
  menu: ImmutablePropTypes.list.isRequired,
  sizes: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
  employeeBreakWaiverTooltipOpen: PropTypes.bool.isRequired,
  setEmployeeBreakWaiverTooltip: PropTypes.func.isRequired,
  shouldShowEnforcementDialog: PropTypes.bool.isRequired,
  onShowEnforcementDialog: PropTypes.func.isRequired,
};

export default LeftNavigation;
