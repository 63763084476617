let lastRequestId = 1;

const requestTrackers = {};

const requestIds = {};

const requestKey = (resource, id) => `${resource}-${id}`;

// to track get requests for entity
export const trackRequest = (resourceName, id) => {
  const requestId = lastRequestId++;
  const key = requestKey(resourceName, id);

  // If there was a previous request for this resource,
  // clear it.
  const prevRequestId = requestIds[key];
  if (prevRequestId) delete requestTrackers[prevRequestId];

  requestTrackers[requestId] = key;
  requestIds[key] = requestId;
  return requestId;
};

// to make sure if multiple requests are send, we read only last ones response
export const checkAndClearRequest = id => {
  const key = requestTrackers[id];
  const canceled = !key;
  delete requestTrackers[id];

  // If the current request for this path matches ID
  // remove mapping... otherwise another request has since
  // been made.
  if (requestIds[key] === id) delete requestIds[key];

  return !canceled;
};
