export const typography = {
  fontFamily: "'Plus Jakarta Sans', sans-serif",
  fontWeight: 500,
  fontSize: 16,
  display1: {
    fontSize: 56,
    lineHeight: '66px',
    letterSpacing: '0.01em',
    fontWeight: 800,
  },
  display2: {
    fontSize: 48,
    lineHeight: '58px',
    letterSpacing: '0.01em',
    fontWeight: 800,
  },
  display3: {
    fontSize: 40,
    lineHeight: '50px',
    letterSpacing: '0.01em',
    fontWeight: 800,
  },
  heading1: {
    fontSize: 28,
    lineHeight: '38px',
    letterSpacing: '0.01em',
    fontWeight: 700,
  },
  heading2: {
    fontSize: 24,
    lineHeight: '34px',
    letterSpacing: '0.01em',
    fontWeight: 700,
  },
  heading3: {
    fontSize: 20,
    lineHeight: '30px',
    letterSpacing: '0.01em',
    fontWeight: 700,
  },
  heading4: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.01em',
    fontWeight: 700,
  },
  body: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 500,
  },
  bodyTight: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 500,
  },
  bodyBold: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 700,
  },
  bodyBoldTight: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 700,
  },
  bodySm: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 500,
  },
  bodySmTight: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
  },
  bodySmBold: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 700,
  },
  bodySmBoldTight: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 700,
  },
  // plural name captions is intentional to avoid clash with MUI variant caption
  captions: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500,
  },
  captionBold: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
  },
  label: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 700,
    textTransform: 'uppercase' as const,
  },
};

export type TypographyCustom = typeof typography;
