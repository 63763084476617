import { BELONGS_TO, HAS_MANY } from 'fe-core/Entities/relationshipTypes';
import { ACTION_UPDATE } from 'fe-core/module-constants';
import { createResource } from 'fe-core/Resources';
import { update } from 'fe-core/Resources/actions';
import Job from 'resources/Job';

const TimeOff = createResource({
  name: 'timeOff',
  path: '/schedule_builder/time_offs',
  schema: {
    attributes: [
      'category',
      'startAt',
      'endAt',
      'notes',
      'reason',
      'wholeDay',
      'dayDurations',
      'ptoTimeOff',
    ],
    relationships: {
      createsConflicts: {
        inverseOf: 'conflict',
        relationshipType: HAS_MANY,
      },
      job: {
        relationshipType: BELONGS_TO,
        defaultType: Job.name,
        inverseOf: 'timeOffs',
      },
      ptoTimeOffs: {
        inverseOf: 'timeOff',
        // TODO: Resource library currently does not have HAS_ONE relationship.
        // Update this once library supports it.
        // timeOff should only have 1-to-1 relationship with ptoTimeOff
        relationshipType: HAS_MANY,
      },
    },
  },
});

TimeOff.approve = id =>
  update({
    path: `/schedule_builder/time_offs/${id}/approve`,
    name,
    action: ACTION_UPDATE,
  });

TimeOff.decline = id =>
  update({
    path: `/schedule_builder/time_offs/${id}/reject`,
    name,
    action: ACTION_UPDATE,
  });

export default TimeOff;
