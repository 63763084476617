import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';

import { I18N_IMPORT_CONTAINER_PATH } from 'features/team/components/AddEmployeeForm/constants';
import { HR_STYLE } from 'features/team/constants';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

const { cx, cxEl } = cxHelpers('ImportContainerCTA');

const ImportContainerCTA = ({
  className,
  handleClick,
  importModal,
  partner,
}) => (
  <Box className={`${cx('wrapper')} ${cxEl(className)}`}>
    <Text variant="bodySmBold" color="mono700">
      {toI18n('add_team.new_team_drawer.recommended')}
    </Text>
    <Box
      h={152}
      mt={12}
      mb={32}
      bw={1}
      p="24px 28px"
      bcolor="mono300"
      bgcolor="mono0"
      bradiusl
      column
      spacebetween
    >
      <Box>
        <Text variant="heading4" color="mono900">
          {toI18n(`${I18N_IMPORT_CONTAINER_PATH}.title`, {
            props: { partner: capitalize(partner) },
          })}
        </Text>
        <Box mt={12}>
          <Text variant="body" color="mono900">
            {toI18n(`${I18N_IMPORT_CONTAINER_PATH}.${importModal}.content`)}
          </Text>
        </Box>
      </Box>
      <Box hright vcenter>
        <Button
          className={`${cxEl('import-team-members-button')} ${cxEl(className)}`}
          onClick={handleClick}
        >
          {toI18n(`${I18N_IMPORT_CONTAINER_PATH}.button_text`)}
        </Button>
      </Box>
    </Box>
    <hr style={HR_STYLE} />
    <Box mt={32}>
      <Text variant="bodySmBold" color="mono700">
        {toI18n(`${I18N_IMPORT_CONTAINER_PATH}.not_recommended`)}
      </Text>
    </Box>
  </Box>
);

ImportContainerCTA.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
  importModal: PropTypes.string,
  partner: PropTypes.string,
};

export default ImportContainerCTA;
