import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import Dialog from 'fe-design-base/organisms/Dialog';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('LaunchWeekShareInstagramDialog');
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';

const LaunchWeekShareInstagramDialog = ({
  i18n,
  onClose,
  onShareOnFacebook,
  open,
  trackUx,
}) => {
  const handleShareOnFacebook = useCallback(() => {
    trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      share_type: 'instagram',
      link_text: 'share_on_facebook',
    });
    onShareOnFacebook({ trackFacebookLinkClicked: false });
    onClose();
  }, [onClose, onShareOnFacebook, trackUx]);

  return (
    <Dialog
      className={cx()}
      disableBackdropClick
      noPadding
      onClose={onClose}
      open={open}
    >
      {() => (
        <Box className={cx()} pl={24} pr={24} pt={16} pb={16} w={580}>
          <Box row hcenter vcenter>
            <Text variant="heading2" color="mono900">
              {i18n.share_instagram.title}
            </Text>
          </Box>
          <Box column hcenter>
            <Box mt={24} tcenter vcenter maxw={400}>
              <Text variant="body" color="mono900">
                {i18n.share_instagram.subtitle}
              </Text>
            </Box>
            <Box mt={32} hleft vcenter>
              <img
                width={280}
                height={280}
                src={require('assets/launch_week_qr_code_award.png')}
                alt="Launch Week QR Code Award"
              />
            </Box>
            <Box hright mt={16} mb={24}>
              <Text variant="bodySm" color="mono900">
                {i18n.share_instagram.next}
              </Text>
              &nbsp;
              <Link
                onClick={handleShareOnFacebook}
                target="_blank"
                size="small"
                endIcon="ExternalLink"
              >
                {i18n.share_instagram.next_link}
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </Dialog>
  );
};

export default LaunchWeekShareInstagramDialog;
