import 'theme/base.scss';

import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import ViewContainer from 'features/layout';

import Routes from './Routes';

const App = ({ store }) => (
  <Provider store={store}>
    <ViewContainer>
      <Routes key={Math.random()} />
    </ViewContainer>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
