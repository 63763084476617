import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgGroup = ({
  alt = 'Group Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M10.898.243a2.622 2.622 0 0 1 2.204 0l10.246 4.734C23.747 5.16 24 5.56 24 6c0 .441-.253.84-.652 1.022l-10.246 4.735a2.622 2.622 0 0 1-2.204 0L.652 7.021a1.126 1.126 0 0 1 0-2.044L10.898.243Zm9.957 9.582 2.493 1.153c.399.183.652.581.652 1.022 0 .44-.253.84-.652 1.022l-10.246 4.735a2.622 2.622 0 0 1-2.204 0L.652 13.022a1.126 1.126 0 0 1 0-2.044l2.493-1.153 7.125 3.29a4.13 4.13 0 0 0 3.46 0l7.125-3.29Zm-7.125 9.291 7.125-3.29 2.493 1.153c.399.182.652.58.652 1.022 0 .44-.253.839-.652 1.022l-10.246 4.734a2.622 2.622 0 0 1-2.204 0L.652 19.023a1.126 1.126 0 0 1 0-2.044l2.493-1.154 7.125 3.291a4.13 4.13 0 0 0 3.46 0Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGroup;
