import { isArray } from 'lodash';

import { actions as entityActions } from '../Entities';
import { getSchema, registerSchema } from '../Entities/schema';
import {
  selectAllEntities,
  selectAttribute,
  selectEntity,
  selectEntityMap,
  selectPendingEntity,
  selectRelationship,
  selectWhereAttribute,
} from '../Entities/selectors';
import {
  ACTION_CREATE,
  ACTION_DESTROY,
  ACTION_FETCH,
  ACTION_POST,
  ACTION_UPDATE,
} from '../module-constants';

import * as actions from './actions';
import entityFromProperties from './entityFromProperties';
import { selectError, selectIsPending } from './selectors';

const selectRelationshipIds = (state, name, relationship, id = '1') =>
  selectRelationship(state, { type: name, id, relationship })?.map(r => r.id) ||
  [];

// build request payload
const payloadForEntity = (schema, data) => {
  if (!schema) return data;

  return {
    /** When getting the data, we do not want the non-existent relationships
     * So we set savePending to false
     * */
    data: entityFromProperties({ ...schema, savePending: false }, data),
    meta: data.meta,
  };
};

// build request payload as Array or Object
export const buildPayload = (name, data) => {
  const schema = getSchema(name);

  if (isArray(data)) {
    return data.map(entity => payloadForEntity(schema, entity));
  }

  return payloadForEntity(schema, data);
};

export const createResource = ({ name, path, schema }) => {
  registerSchema(name, schema);
  return {
    name,
    path,
    schema,
    selectEntity: (state, id = '1') => selectEntity(state, { type: name, id }),
    isLoaded: (state, id = '1') => !!selectEntity(state, { type: name, id }),
    isPending: (state, id = '1') =>
      !!selectPendingEntity(state, { type: name, id }),
    selectEntities: (state, ids) =>
      ids.reduce((memo, id) => {
        const entity = selectEntity(state, { type: name, id });
        if (entity) {
          memo.push(entity);
        }
        return memo;
      }, []),
    selectEntityMap: state => selectEntityMap(state, { type: name }),
    selectAllEntities: state => selectAllEntities(state, { type: name }),
    selectWhereAttribute: (state, attribute, value) =>
      selectWhereAttribute(state, { type: name, attribute, value }),
    selectAttribute: (state, attribute, id = '1') =>
      selectAttribute(state, { type: name, id, attribute }),
    // Relationships selectors
    selectRelatedEntities: (state, relationship, entity, id = '1') => {
      const ids = selectRelationshipIds(state, name, relationship, id);
      return entity.selectEntities(state, ids);
    },
    selectRelatedEntity: (state, relationship, entity, id = '1') => {
      const relatedId = selectRelationship(state, {
        id,
        relationship,
        type: name,
      })?.id;
      return relatedId && entity.selectEntity(state, relatedId);
    },
    selectRelationshipType: (state, relationship, id = '1') =>
      selectRelationship(state, { type: name, id, relationship })?.type,
    selectRelationshipId: (state, relationship, id = '1') =>
      selectRelationship(state, { type: name, id, relationship })?.id,
    selectRelationshipIds: (state, relationship, id = '1') =>
      selectRelationshipIds(state, name, relationship, id),
    // isPending selectors
    selectFetchIsPending: (state, id = '1') =>
      selectIsPending(state, { name, action: ACTION_FETCH, id }),
    selectCreateIsPending: (state, id = '1') =>
      selectIsPending(state, { name, action: ACTION_CREATE, id }),
    selectUpdateIsPending: (state, id = '1') =>
      selectIsPending(state, { name, action: ACTION_UPDATE, id }),
    selectPostIsPending: (state, id = '1') =>
      selectIsPending(state, { name, action: ACTION_POST, id }),
    selectDestroyIsPending: (state, id = '1') =>
      selectIsPending(state, { name, action: ACTION_DESTROY, id }),
    // error selectors
    selectFetchError: (state, id = '1') =>
      selectError(state, { name, action: ACTION_FETCH, id }),
    selectCreateError: (state, id = '1') =>
      selectError(state, { name, action: ACTION_CREATE, id }),
    selectUpdateError: (state, id = '1') =>
      selectError(state, { name, action: ACTION_UPDATE, id }),
    selectPostError: (state, id = '1') =>
      selectError(state, { name, action: ACTION_POST, id }),
    selectDestroyError: (state, id = '1') =>
      selectError(state, { name, action: ACTION_DESTROY, id }),
    resourceActionEvent: action => actions.resourceEventName(name, action),
    // actions
    fetch: ({ payload, id, beforeDigestCallback } = {}) =>
      actions.get({
        name,
        action: ACTION_FETCH,
        id,
        path,
        payload,
        beforeDigestCallback,
      }),
    create: ({ payload, pendingId }) =>
      actions.create({
        name,
        action: ACTION_CREATE,
        payload: buildPayload(name, payload),
        path,
        pendingId,
      }),
    post: ({ payload }) =>
      actions.post({
        name,
        path,
        action: ACTION_POST,
        payload: buildPayload(name, payload),
      }),
    update: ({ payload, id, beforeDigestCallback, pendingId }) =>
      actions.update({
        name,
        action: ACTION_UPDATE,
        id,
        payload: buildPayload(name, payload),
        beforeDigestCallback,
        path,
        pendingId,
      }),
    destroy: ({ id } = {}) =>
      actions.destroy({
        name,
        action: ACTION_DESTROY,
        id,
        path,
      }),
    clearAllEntities: () => entityActions.clearAllEntities(name),
    destroyEntity: ({ id = '1' }) => entityActions.destroy({ type: name, id }),
    assignAttributes: ({ id = '1', attributes }) =>
      entityActions.assignAttributes({ type: name, id, attributes }),
    addPending: entities =>
      actions.addPending({
        name,
        entities,
      }),
    clearPending: pendingId => entityActions.clearPending(pendingId),
  };
};
