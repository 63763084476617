import { flatten } from 'lodash';

// Use instead of lodash's omit function for better performance
export const omit = (object, ...keys) => {
  if (!object) return {};

  const clonedObject = { ...object };

  flatten(keys).forEach(key => delete clonedObject[key]);

  return clonedObject;
};

// Use instead of lodash's pick function for better performance
export const pick = (object, ...keys) =>
  flatten(keys).reduce((memo, key) => {
    if (
      object &&
      ((object.hasOwnProperty && object.hasOwnProperty(key)) || object[key])
    ) {
      memo[key] = object[key];
    }
    return memo;
  }, {});
