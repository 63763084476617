import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { fromJS } from 'immutable';

import { getCurrentLocationId } from 'selectors/session';

import { DEMO_ANSWERS, DEMO_QUESTIONS } from './constants';

export const fetchQuestionsExists = createAsyncThunk(
  'timeclock/fetchPacketTemplate',
  (_, { getState }) =>
    fetchJSON(
      `/timeclock/health_questions/exists.json?location_id=${getCurrentLocationId(
        getState()
      )}`
    )
);

export const fetchAnswers = createAsyncThunk(
  'timeclock/fetchAnswers',
  ({ day }) =>
    fetchJSON(`/timeclock/health_answers.json?day=${day.format('MM/DD/YYYY')}`)
);

const healthReportSlice = createSlice({
  name: 'healthReport',
  initialState: fromJS({
    showFTU: false,
    fetching: true,
    answers: [],
    questions: {},
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [fetchQuestionsExists.pending]: state => state.set('fetching', true),
    [fetchQuestionsExists.fulfilled]: (state, action) => {
      let newState = state
        .set('fetching', false)
        .set('timeclockEnabled', action.payload.mobile_timeclock_enabled)
        .set('showFTU', !action.payload.exists);
      if (!action.payload.exists) {
        newState = newState
          .mergeIn(['questions'], DEMO_QUESTIONS)
          .mergeIn(['answers'], DEMO_ANSWERS);
      }
      return newState;
    },
    [fetchAnswers.pending]: state => state.set('loading', true),
    [fetchAnswers.fulfilled]: (state, action) => {
      let newState = state.set('loading', false);

      if (!state.get('showFTU')) {
        newState = newState.set('answers', fromJS(action.payload.answers)).set(
          'questions',
          fromJS(
            action.payload.questions.reduce((memo, q) => {
              memo[q.id] = q;
              return memo;
            }, {})
          )
        );
      }

      return newState;
    },
  },
});

export const healthReportReducer = healthReportSlice.reducer;
export const healthReportActions = healthReportSlice.actions;
