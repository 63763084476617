import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgChartColumn = ({
  alt = 'Chart Column Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path fill="#E5E5E5" d="M0 0h24v24H0z" />
    <rect width={2964} height={4036} x={-1372} y={-2249} fill="#fff" rx={24} />
    <path
      fill="currentColor"
      d="M1.5 1.5C2.33 1.5 3 2.17 3 3v15.75c0 .413.337.75.75.75H22.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5H3.75A3.749 3.749 0 0 1 0 18.75V3c0-.83.67-1.5 1.5-1.5Zm6 9c.83 0 1.5.67 1.5 1.5v3c0 .83-.67 1.5-1.5 1.5S6 15.83 6 15v-3c0-.83.67-1.5 1.5-1.5Zm6-3V15c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V7.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5Zm3 1.5c.83 0 1.5.67 1.5 1.5V15c0 .83-.67 1.5-1.5 1.5S15 15.83 15 15v-4.5c0-.83.67-1.5 1.5-1.5Zm6-4.5V15c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5V4.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5Z"
    />
  </svg>
);
export default SvgChartColumn;
