import baseColors from '../colors';
import { shadows } from '../shadows';
import { gutters } from '../utils';

export const dropdownOverrides = {
  MuiMenu: {
    styleOverrides: {
      paper: {
        transform: `translateY(${gutters[12]}) !important`,
        boxShadow: `${shadows.z2()} !important`,
        border: `1px solid ${baseColors.mono300}`,
      },
      list: {
        padding: 0,
        borderRadius: '4px',
        '&& .Mui-selected': {
          backgroundColor: baseColors.mono100,
          ':hover': {
            backgroundColor: baseColors.mono100,
          },
          ':focus': {
            backgroundColor: baseColors.mono100,
          },
        },

        '& .FDBSelect__group-label': {
          cursor: 'default',
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        minHeight: '48px',
        minWidth: '100px',
        padding: '8px 12px',
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        backgroundColor: baseColors.mono0,

        ':hover': {
          backgroundColor: baseColors.mono100,
        },

        '&:active': {
          backgroundColor: baseColors.mono300,
        },

        '&.Mui-selected': {
          backgroundColor: baseColors.mono100,
        },

        '&.Mui-selected:hover': {
          backgroundColor: baseColors.mono100,
        },

        '&.disabled': {
          backgroundColor: baseColors.mono300,
          color: baseColors.mono500,
          pointerEvents: 'none',
          opacity: 1,
        },

        '& .MuiTouchRipple-root': {
          display: 'none',
        },

        '&.FDBSelect__menu-item.MuiButtonBase-root': {
          backgroundColor: baseColors.mono0,
          padding: '2px 12px',
          justifyContent: 'flex-start',
          marginBottom: '3px',
          marginTop: '3px',

          '&[aria-disabled="true"]': {
            opacity: 1,
            backgroundColor: baseColors.mono0,
            color: baseColors.mono500,
          },

          '&.FDBSelect__menu-item--hasSubLabel': {
            alignItems: 'start',
            paddingTop: '12px',
            paddingBottom: '12px',
          },

          '&.FDBSelect__menu-item--isSelected.Mui-selected, &[aria-selected="true"]':
            {
              backgroundColor: baseColors.purple100,

              '&:active, &:focus-visible': {
                backgroundColor: baseColors.purple300,
              },
            },

          '&:hover': {
            backgroundColor: baseColors.purple100,
          },
          '&:focus-visible': {
            backgroundColor: baseColors.purple300,
          },
          '&:active': {
            backgroundColor: baseColors.purple300,
          },
          // remove these checkbox styles once checkbox component gets style update
          '& .FDBCheckbox:hover': {
            backgroundColor: 'transparent !important',
          },
          '& .FDBCheckbox:active': {
            backgroundColor: 'transparent !important',
          },
        },
      },
    },
  },
  MuiModal: {
    styleOverrides: {
      root: {
        '&.FDBContextMenu': {
          ul: {
            paddingTop: '12px',
            paddingBottom: '12px',
          },

          li: {
            minWidth: '240px',
            maxWidth: '340px',
          },

          '& .FDBSimpleMenuItem, & .FDBNestedMenu': {
            whiteSpace: 'normal',
            backgroundColor: baseColors.mono0,
            minHeight: '48px',
            display: 'flex',
            gap: '8px',
            padding: '8px 12px',
            alignItems: 'center',
            justifyContent: 'flex-start',

            '&--disabled': {
              backgroundColor: baseColors.mono300,
              color: baseColors.mono500,
              pointerEvents: 'none',
              opacity: 1,

              '& .FDBIcon': {
                color: baseColors.mono500,
              },
            },

            '& .FDBIcon': {
              color: baseColors.mono700,
            },

            '&:focus-visible': {
              backgroundColor: baseColors.purple100,
              outline: `2px solid ${baseColors.teal300}`,
              outlineOffset: '-2px',
              borderRadius: '4px',
            },

            '&.redText': {
              color: baseColors.red300,
            },

            '&:hover': {
              backgroundColor: baseColors.purple100,
            },

            '&:active': {
              backgroundColor: baseColors.purple300,
            },

            '&.FDBNestedMenu--open': {
              backgroundColor: baseColors.purple100,
            },
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        backgroundColor: baseColors.mono0,

        '& .MuiSelect-icon': {
          color: baseColors.mono700,
        },

        '&.Mui-disabled .MuiSelect-icon': {
          color: baseColors.mono500,
          opacity: 0.5,
        },
      },
      select: {
        paddingLeft: gutters[12],
        display: 'flex',
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        height: '100%',
        boxSizing: 'border-box',
      },
      nativeInput: {
        opacity: 0,
        border: 'none',
        ':disabled': {
          backgroundColor: baseColors.mono100,
          border: `solid 1px ${baseColors.mono300}`,
        },
      },
    },
  },
};
