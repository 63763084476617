import React from 'react';
import Icon from 'react-icon-base';

const RocketSolid = props => (
  <Icon
    viewBox="0 0 12 12"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.8388 0.44751C11.8109 0.317871 11.6827 0.189697 11.5527 0.161865C10.798 0 10.2072 0 9.61893 0C7.19942 0 5.74849 1.29382 4.66529 3H2.22276C1.83961 3.00037 1.3894 3.27832 1.2176 3.62073L0.0589598 5.93665C0.0231537 6.01562 0.00312516 6.10084 0 6.1875C5.38749e-05 6.33669 0.0593531 6.47975 0.16486 6.58524C0.270367 6.69072 0.413445 6.74998 0.562636 6.75H2.99569L2.46894 7.27661C2.20257 7.54289 2.16435 8.03265 2.46894 8.33716L3.66201 9.53027C3.92349 9.79248 4.41567 9.83862 4.7232 9.53027L5.24994 9.00366V11.4375C5.25 11.5867 5.3093 11.7298 5.4148 11.8352C5.52031 11.9407 5.66339 12 5.81258 12C5.89926 11.9968 5.98449 11.9768 6.06353 11.941L8.37747 10.7834C8.72034 10.6124 8.99875 10.1624 8.99875 9.77893V7.33118C10.7003 6.24573 11.9996 4.79041 11.9996 2.38367C12.0018 1.79297 12.0018 1.20227 11.8388 0.44751ZM9.00095 3.9375C8.81554 3.93746 8.6343 3.88243 8.48016 3.77939C8.32602 3.67635 8.20589 3.52992 8.13497 3.35861C8.06405 3.1873 8.04551 2.99881 8.08171 2.81696C8.11791 2.63512 8.20722 2.4681 8.33834 2.33701C8.46946 2.20592 8.63651 2.11665 8.81836 2.0805C9.00021 2.04434 9.1887 2.06292 9.35999 2.13388C9.53128 2.20485 9.67769 2.32501 9.78069 2.47918C9.88369 2.63334 9.93867 2.81459 9.93867 3C9.93853 3.24864 9.83968 3.48704 9.66385 3.66283C9.48801 3.83862 9.24958 3.93742 9.00095 3.9375Z"
        fill="#04A2BD"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default RocketSolid;
