import React from 'react';
import Icon from 'react-icon-base';

const CalendarCheck = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g>
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M7.726 6.076h.785v3.536c0 1.422 1.224 2.579 2.73 2.579h.935c1.504 0 2.728-1.157 2.728-2.58V6.077h10.624v3.536c0 1.422 1.224 2.579 2.73 2.579h.92c1.506 0 2.73-1.157 2.73-2.58V6.077h.797c3.086 0 5.596 2.697 5.596 6.012v1.909H2.128v-2.16c0-3.177 2.513-5.761 5.598-5.761zM10.64 2.58c0-.31.277-.57.603-.57h.934c.325 0 .6.26.6.57v7.032c0 .308-.275.568-.6.568h-.934c-.326 0-.603-.26-.603-.568V2.581zm17.017 0c0-.31.276-.57.602-.57h.919c.326 0 .602.26.602.57v7.032c0 .308-.276.568-.602.568h-.92c-.325 0-.601-.26-.601-.568V2.581zM13.84 36.208H7.726c-3.085 0-5.598-2.585-5.598-5.761v-14.44H38.3v1.007c0 .557.477 1.005 1.064 1.005.59 0 1.064-.448 1.064-1.005v-4.925c0-4.423-3.464-8.024-7.724-8.024h-.798V2.581c0-1.422-1.223-2.581-2.73-2.581h-.92c-1.506 0-2.73 1.159-2.73 2.58v1.485H14.904V2.581C14.903 1.159 13.68 0 12.176 0h-.934c-1.507 0-2.73 1.159-2.73 2.58v1.485h-.786C3.466 4.065 0 7.551 0 11.838v18.609c0 4.286 3.466 7.772 7.726 7.772h6.114c.587 0 1.063-.448 1.063-1.005 0-.556-.476-1.006-1.063-1.006z"
        clipRule="evenodd"
      />
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M34.368 45.988c-6.341 0-11.501-4.878-11.501-10.872 0-5.998 5.16-10.876 11.5-10.876 6.344 0 11.504 4.878 11.504 10.876 0 5.994-5.16 10.872-11.503 10.872zm0-23.76c-7.516 0-13.629 5.78-13.629 12.887C20.739 42.22 26.852 48 34.368 48c7.515 0 13.631-5.779 13.631-12.884 0-7.106-6.116-12.888-13.631-12.888z"
        clipRule="evenodd"
      />
      <path
        fill="inherit"
        fillRule="evenodd"
        d="M39.615 31.535l-7.754 7.278-3.554-3.263a1.107 1.107 0 0 0-1.504.02.966.966 0 0 0 .019 1.423l5.056 4.638 9.237-8.67a.968.968 0 0 0 .004-1.422 1.109 1.109 0 0 0-1.504-.004z"
        clipRule="evenodd"
      />
    </g>
  </Icon>
);

export default CalendarCheck;
