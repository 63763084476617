export const getModalType = state => state.getIn(['modals', 'modalType']);
export const getModalProps = state => state.getIn(['modals', 'modalProps']);
export const getModalSource = state => state.getIn(['modals', 'modalSource']);
export const getModalPromoCode = state =>
  state.getIn(['modals', 'modalPromoCode']);

export const getIsMobileSubscriptionBillingModalOpen = state =>
  state.getIn(['modals', 'isMobileSubscriptionBillingModal']);

export const getMobileSubscriptionBillingSource = state =>
  state.getIn(['modals', 'mobileSubscriptionBillingSource']);
