import {
  DEFAULT_SHIFT_COLOR_V1,
  DEFAULT_SHIFT_COLOR_V2,
} from 'features/scheduleBuilder/constants';
import { DEFAULT_OPTION_STATE } from 'features/scheduleBuilder/ScheduleBuilderView/constants';

import { monthFromDate } from 'util/dateTime';
import { getObjectFromLocalStorage } from 'util/localStorageWrapper';

export const getDefaultShiftColor = isColorMappingV1 =>
  isColorMappingV1 ? DEFAULT_SHIFT_COLOR_V1 : DEFAULT_SHIFT_COLOR_V2;
export const formatTimeOffHours = hours =>
  `${parseFloat(Number(hours).toFixed(2))} hrs`;

// ShiftNoteQuota is ID'd by the month
export const getShiftNoteQuotaId = date => monthFromDate(date).toString();

export const getInitialStateFromLS = localStorageKey =>
  getObjectFromLocalStorage(localStorageKey) === null
    ? DEFAULT_OPTION_STATE
    : getObjectFromLocalStorage(localStorageKey);

export const employeeFirstCellProps = ({
  isScheduledAtOtherLocations,
  isHoursForecastVisible,
  hasOvertimeAllLocations,
  hasOvertimeHours,
  hasOvertime,
  isHovered,
  isManager,
}) => {
  const overTimeText = 'red300';
  const defaultTextColor = 'mono700';
  let otherLocationsTextColor = defaultTextColor;
  let forecastTextColor = defaultTextColor;
  let userNameTextColor = 'mono900';
  let tag = 'div';

  if (
    isScheduledAtOtherLocations &&
    (hasOvertimeAllLocations || hasOvertimeHours)
  ) {
    otherLocationsTextColor = overTimeText;
  }

  if (isHoursForecastVisible && hasOvertime) {
    forecastTextColor = overTimeText;
  }

  if (isManager && isHovered) {
    userNameTextColor = 'purple500';
  }

  if (isManager) {
    tag = 'a';
  }

  return {
    otherLocationsTextColor,
    forecastTextColor,
    userNameTextColor,
    tag,
  };
};
