import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { clickQuickbooksAuthUrl, closeQuickbooksAuthModal } from 'actions/ftu';

import * as ftuSelectors from 'selectors/ftu';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Modal from 'components/modals/Modal';
import Text from 'components/Text';

import cxHelpers from 'util/className';

@connect(
  (state, props) => ({
    isOpen: ftuSelectors.getShowQuickbooksAuthModal(state, props),
    quickbooksAuthUrl: ftuSelectors.getShowQuickbooksAuthUrl(state, props),
  }),
  {
    onQuickbooksAuthUrlClick: clickQuickbooksAuthUrl,
    onClose: closeQuickbooksAuthModal,
  }
)
@cxHelpers('QuickbooksAuthModal')
export default class QuickbooksAuthModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    quickbooksAuthUrl: PropTypes.string.isRequired,
    onQuickbooksAuthUrlClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} onRequestClose={this.props.onClose}>
        <Box row hcenter p={10}>
          <img src={require('theme/images/logos/quickbooks_x_homebase.png')} />
        </Box>

        <Box row hcenter tcenter p={10} m={9}>
          <Text fs24 fw600 i18n="ftu.quickbooks.auth_modal.title" />
        </Box>

        <Box row hcenter tcenter p={10}>
          <Text fs16 i18n="ftu.quickbooks.auth_modal.body" />
        </Box>

        <Box row hcenter p={10}>
          <Button
            theme="primary"
            onClick={this.props.onQuickbooksAuthUrlClick}
            href={this.props.quickbooksAuthUrl}
          >
            <Text i18n="ftu.quickbooks.auth_modal.cta" />
          </Button>
        </Box>
      </Modal>
    );
  }
}
