import queryString from 'query-string';

const GOOGLE_STATIC_MAP_URL = 'https://maps.googleapis.com/maps/api/staticmap';
const GOOGLE_EXTERNAL_MAP_URL = 'https://www.google.com/maps/search/';

export default class GoogleMaps {
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  // Creates a URL that embeds a static map image in PNG format.
  createMapImageURL({ lat, lng, w = 300, h = 300, zoom = 15 }) {
    const params = queryString.stringify({
      key: this.apiKey,
      zoom,
      size: `${w}x${h}`,
      markers: `size:mid|color:red|${lat},${lng}`,
    });

    return `${GOOGLE_STATIC_MAP_URL}?${params}`;
  }

  // Creates a URL that opens Google Maps in a new page.
  createExternalMapURL({ lat, lng }) {
    const params = queryString.stringify({
      api: 1,
      query: `${lat},${lng}`,
    });

    return `${GOOGLE_EXTERNAL_MAP_URL}?${params}`;
  }
}
