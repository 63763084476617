import React from 'react';
import {
  faBook,
  faBriefcaseMedical,
  faCalendarAlt,
  faChartLine,
  faCog,
  faFlag,
  faQuestionCircle,
  faTachometerSlow,
  faUsdCircle,
  faUser,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'theme/constants';

import CustomIcon from 'components/Icon';

const FONT_AWESOME_ICONS = {
  'tachometer-slow': faTachometerSlow,
  'calendar-alt': faCalendarAlt,
  'usd-circle': faUsdCircle,
  book: faBook,
  flag: faFlag,
  user: faUser,
  users: faUsers,
  'chart-line': faChartLine,
  'briefcase-medical': faBriefcaseMedical,
  cog: faCog,
  'question-circle': faQuestionCircle,
};

const Icon = ({ type, color }) => {
  if (FONT_AWESOME_ICONS[type]) {
    return (
      <FontAwesomeIcon
        style={{ width: 20, height: 20 }}
        icon={FONT_AWESOME_ICONS[type]}
        color={colors[color]}
      />
    );
  }

  return <CustomIcon size={20} type={type} color={color} />;
};

export default Icon;
