import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { get } from 'lodash';

const DEFAULT_STATE = {
  events: {},
  range: 'this_week',
  loading: false,
};

export const fetchUpcomingWidgetData = createAsyncThunk(
  'DASHBOARD/FETCH_UPCOMING_WIDGET',
  ({ range }, { rejectWithValue }) =>
    fetchJSON(`/dashboard/upcoming_widget_data?range=${range}`).catch(err =>
      err.response.json().then(body => rejectWithValue(body))
    )
);

export const setRange = (state, action) => ({
  ...state,
  range: action.payload,
});

const upcomingWidgetSlice = createSlice({
  name: 'upcomingWidget',
  initialState: DEFAULT_STATE,
  reducers: {
    setRange,
  },
  extraReducers: {
    [fetchUpcomingWidgetData.pending]: state => ({ ...state, loading: true }),
    [fetchUpcomingWidgetData.fulfilled]: (state, action) => {
      const range = get(action, 'meta.arg.range', 'this_week');
      return {
        ...state,
        range,
        events: { ...state.events, [range]: action.payload },
        loading: false,
      };
    },
    [fetchUpcomingWidgetData.rejected]: state => ({ ...state, loading: false }),
  },
});

export const upcomingWidgetReducer = upcomingWidgetSlice.reducer;
export const upcomingWidgetActions = upcomingWidgetSlice.actions;
