import React from 'react';
import { connect } from 'react-redux';
import AnnounceKitReact from 'announcekit-react';
import PropTypes from 'prop-types';

import { getCurrentLocationId, getCurrentUserId } from 'selectors/session';

const AnnounceKit = ({
  widget,
  widgetStyle = { fontSize: '16px' },
  children,
  currentUserId,
  currentLocationId,
  onWidgetOpen,
}) => (
  <AnnounceKitReact
    widget={widget}
    widgetStyle={widgetStyle}
    user={{ id: currentUserId }}
    data={{ location_id: currentLocationId }}
    onWidgetOpen={onWidgetOpen}
  >
    {children}
  </AnnounceKitReact>
);

AnnounceKit.propTypes = {
  widget: PropTypes.string.isRequired,
  widgetStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  currentUserId: PropTypes.number.isRequired,
  currentLocationId: PropTypes.number.isRequired,
  onWidgetOpen: PropTypes.func,
};

export default connect(state => ({
  currentUserId: getCurrentUserId(state),
  currentLocationId: getCurrentLocationId(state),
}))(AnnounceKit);
