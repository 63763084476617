import * as routes from 'api';
import { postFiles, putJSON } from 'api/fetch';

import { getLocationNameFromLocationIdInProps } from 'selectors/entities';

import { VIEWS } from 'features/messengerv2/constants';
import { setCurrentChatView as changeChatView } from 'features/messengerv2/slice';

import { momentInZone } from 'util/dateTime';
import {
  createAsyncGetAction,
  createAsyncPostAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';

export const actionTypes = {
  CREATE_ACKNOWLEDGMENT_REQUEST: 'TEAM_EMAILS/CREATE_ACKNOWLEDGMENT_REQUEST',
  CREATE_ACKNOWLEDGMENT_SUCCESS: 'TEAM_EMAILS/CREATE_ACKNOWLEDGMENT_SUCCESS',
  CREATE_ACKNOWLEDGMENT_FAILURE: 'TEAM_EMAILS/CREATE_ACKNOWLEDGMENT_FAILURE',

  FETCH_TEAM_EMAILS_REQUEST: 'TEAM_EMAILS/FETCH_TEAM_EMAILS_REQUEST',
  FETCH_TEAM_EMAILS_SUCCESS: 'TEAM_EMAILS/FETCH_TEAM_EMAILS_SUCCESS',
  FETCH_TEAM_EMAILS_FAILURE: 'TEAM_EMAILS/FETCH_TEAM_EMAILS_FAILURE',
  FETCH_TEAM_ANNOUNCEMENTS_REQUEST:
    'TEAM_EMAILS/FETCH_TEAM_ANNOUNCEMENTS_REQUEST',
  FETCH_TEAM_ANNOUNCEMENTS_SUCCESS:
    'TEAM_EMAILS/FETCH_TEAM_ANNOUNCEMENTS_SUCCESS',
  FETCH_TEAM_ANNOUNCEMENTS_FAILURE:
    'TEAM_EMAILS/FETCH_TEAM_ANNOUNCEMENTS_FAILURE',
  SEND_TEAM_EMAILS_REQUEST: 'TEAM_EMAILS/SEND_TEAM_EMAILS_REQUEST',
  SEND_TEAM_EMAILS_SUCCESS: 'TEAM_EMAILS/SEND_TEAM_EMAILS_SUCCESS',
  SEND_TEAM_EMAILS_FAILURE: 'TEAM_EMAILS/SEND_TEAM_EMAILS_FAILURE',
  UNPIN_TEAM_ANNOUNCEMENT_REQUEST:
    'TEAM_EMAILS/UNPIN_TEAM_ANNOUNCEMENT_REQUEST',
  UNPIN_TEAM_ANNOUNCEMENT_SUCCESS:
    'TEAM_EMAILS/UNPIN_TEAM_ANNOUNCEMENT_SUCCESS',
  UNPIN_TEAM_ANNOUNCEMENT_FAILURE:
    'TEAM_EMAILS/UNPIN_TEAM_ANNOUNCEMENT_FAILURE',
  SET_CURRENT_EMAIL_ID: 'TEAM_EMAILS/SET_CURRENT_EMAIL_ID',
  SHOW_NEW_EMAIL_VIEW: 'TEAM_EMAILS/SHOW_NEW_EMAIL_VIEW',
  ADD_NEW_EMAILS: 'TEAM_EMAILS/ADD_NEW_EMAILS',
  ADD_NEW_ANNOUNCEMENT: 'TEAM_EMAILS/ADD_NEW_ANNOUNCEMENT',
  CLEAR_TEAM_EMAILS: 'TEAM_EMAILS/CLEAR_TEAM_EMAILS',
  UPDATE_ANNOUNCEMENT: 'TEAM_EMAILS/UPDATE_ANNOUNCEMENT',
};

const addNewEmailsAction = (
  state,
  { locationIds, audience, subject, message, pinned },
  response
) => {
  // if announcements_v2 is on
  if (response.id) {
    return {
      type: actionTypes.ADD_NEW_ANNOUNCEMENT,
      payload: response,
    };
  }

  const emails = locationIds.map(locationId => {
    const emailParams = {
      id: JSON.parse(response.location_team_email_ids)[locationId],
      location_id: locationId,
      location_name: getLocationNameFromLocationIdInProps(state, {
        locationId,
      }),
      audience,
      subject,
      pinned,
      body: message,
      created_at: momentInZone().format('MM/DD/YYYY HH:mm'),
    };

    if (response.team_announcement_id) {
      emailParams.team_announcement_id = response.team_announcement_id;
    }

    return emailParams;
  });

  return {
    type: actionTypes.ADD_NEW_EMAILS,
    payload: { emails },
  };
};

export const setShowNewEmailView = showNewEmailView => ({
  type: actionTypes.SHOW_NEW_EMAIL_VIEW,
  payload: { showNewEmailView },
});

export const sendTeamEmail = props => (dispatch, getState) => {
  const body = {
    location_ids: props.locationIds,
    audience: props.audience,
    subject: props.subject,
    msg: props.message,
    pinned: props.pinned,
    requires_acknowledgment: props.requireAcknowledgement,
  };

  postFiles(routes.blastTeamRoute(), body, props.files).then(response => {
    dispatch(changeChatView({ view: VIEWS.INDEX }));
    dispatch(setShowNewEmailView(false));
    dispatch(addNewEmailsAction(getState(), props, response));
  });
};

export const unpinTeamAnnouncement = ({ id, team_announcement_id }) => {
  const meta = { requestId: actionTypes.UNPIN_TEAM_ANNOUNCEMENT_SUCCESS, id };

  return withAlerts(
    createAsyncPutAction(
      routes.teamAnnouncementsArchiveRoute(team_announcement_id),
      [
        { type: actionTypes.UNPIN_TEAM_ANNOUNCEMENT_REQUEST, meta },
        { type: actionTypes.UNPIN_TEAM_ANNOUNCEMENT_SUCCESS, meta },
        { type: actionTypes.UNPIN_TEAM_ANNOUNCEMENT_FAILURE, meta },
      ]
    )
  );
};

export const fetchTeamEmails = lastEmailId => {
  const meta = {
    requestId: actionTypes.FETCH_TEAM_EMAILS_REQUEST,
    append: true,
  };

  return createAsyncGetAction(
    routes.teamEmailsRoute({ last_email_id: lastEmailId }),
    [
      { type: actionTypes.FETCH_TEAM_EMAILS_REQUEST, meta },
      { type: actionTypes.FETCH_TEAM_EMAILS_SUCCESS, meta },
      { type: actionTypes.FETCH_TEAM_EMAILS_FAILURE, meta },
    ]
  );
};

export const fetchTeamAnnouncements = () => {
  const meta = {
    requestId: actionTypes.FETCH_TEAM_ANNOUNCEMENTS_REQUEST,
  };

  return createAsyncGetAction(routes.teamAnnouncementsListRoute(), [
    { type: actionTypes.FETCH_TEAM_ANNOUNCEMENTS_REQUEST, meta },
    { type: actionTypes.FETCH_TEAM_ANNOUNCEMENTS_SUCCESS, meta },
    { type: actionTypes.FETCH_TEAM_ANNOUNCEMENTS_FAILURE, meta },
  ]);
};

export const setCurrentEmailId = id => ({
  type: actionTypes.SET_CURRENT_EMAIL_ID,
  payload: { id },
});

export const clearTeamEmails = () => ({
  type: actionTypes.CLEAR_TEAM_EMAILS,
});

export const acknowledgeAnnouncement = (id, cb) =>
  withAlerts(
    createAsyncPostAction(routes.teamAnnouncementsAcknowledgeRoute(id), [
      { type: actionTypes.CREATE_ACKNOWLEDGMENT_REQUEST },
      { type: actionTypes.CREATE_ACKNOWLEDGMENT_SUCCESS, meta: { id } },
      { type: actionTypes.CREATE_ACKNOWLEDGMENT_FAILURE },
    ]),
    {
      onSuccess: () => {
        if (cb) cb();
      },
    }
  );

export const updateTeamAnnouncement = (id, params) => dispatch =>
  putJSON(routes.teamAnnouncementRoute(id), { team_announcement: params }).then(
    () =>
      dispatch({
        type: actionTypes.UPDATE_ANNOUNCEMENT,
        payload: { id, params },
      })
  );
