import './NotificationsList.scss';

import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import InfiniteScroll from 'components/InfiniteScroll';
import LoadingIndicator from 'components/LoadingIndicator';

import cxHelpers from 'util/className';

import ApprovedUserMergeRequest from '../ListItems/alerts/ApprovedUserMergeRequest';
import ClockOutApproval from '../ListItems/alerts/ClockOutApproval';
import DeclinedUserMergeRequest from '../ListItems/alerts/DeclinedUserMergeRequest';
import EarlyClockIn from '../ListItems/alerts/EarlyClockIn';
import ExpiredCertificate from '../ListItems/alerts/ExpiredCertificate';
import HealthAlert from '../ListItems/alerts/HealthAlert';
import HiringJobApplication from '../ListItems/alerts/HiringJobApplication';
import LateClockIn from '../ListItems/alerts/LateClockIn';
import MissedClockOut from '../ListItems/alerts/MissedClockOut';
import MissedWageRate from '../ListItems/alerts/MissedWageRate';
import EmptyListItem from '../ListItems/EmptyListItem';
import Availability from '../ListItems/requests/Availability';
import OpenShift from '../ListItems/requests/OpenShift';
import PendingJob from '../ListItems/requests/PendingJob';
import Personal from '../ListItems/requests/Personal';
import TimeOff from '../ListItems/requests/TimeOff';
import Trade from '../ListItems/requests/Trade';

const VIEW_CLASSES = {
  clock_out: MissedClockOut,
  early_clock_in: EarlyClockIn,
  late_clock_in: LateClockIn,
  wage_rate: MissedWageRate,
  job_application: HiringJobApplication,
  clock_out_approval: ClockOutApproval,
  expired_certificate: ExpiredCertificate,
  trade: Trade,
  time_off: TimeOff,
  personal_alert: Personal,
  availability: Availability,
  pending_job: PendingJob,
  open_shift: OpenShift,
  health_alert: HealthAlert,
  approved_user_merge_request: ApprovedUserMergeRequest,
  declined_user_merge_request: DeclinedUserMergeRequest,
};

const BOX_STYLE = { boxShadow: '0 -20px 20px white' };

@cxHelpers('NotificationsList')
export default class NotificationsList extends PureComponent {
  static propTypes = {
    notificationType: PropTypes.oneOf(['requests', 'alerts']).isRequired,
    notificationsItems: ImmutablePropTypes.orderedMap.isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    hasMore: PropTypes.bool.isRequired,
    getScrollContainer: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.fetchPage(1);
  }

  fetchPage = page => {
    this.props.fetchNotifications(this.props.notificationType, page);
  };

  renderItems(type, items) {
    const { isFetching, onClose, isReactSbWeekViewEnabled } = this.props;

    if (items.size === 0) {
      if (isFetching) {
        return this.renderLoader();
      }
      return <EmptyListItem type={type} />;
    }
    return (
      <TransitionGroup>
        {items.valueSeq().map(item => {
          const ItemComponent = VIEW_CLASSES[item.get('type')];

          return (
            <CSSTransition
              key={item.get('unique_id')}
              classNames={this.cxEl('fade')}
              timeout={{ enter: 700, exit: 700 }}
            >
              <ItemComponent
                isReactSbWeekViewEnabled={isReactSbWeekViewEnabled}
                key={item.get('unique_id')}
                item={item}
                closeDrawer={onClose}
              />
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  }

  renderLoader() {
    const positionStyle =
      this.props.notificationsItems.size === 0
        ? { top: '100px' }
        : { bottom: 0 };

    return (
      <div>
        <Box
          pv8
          h={24}
          w="100%"
          absolute
          bottom={0}
          bg="white"
          opacity={0.8}
          style={BOX_STYLE}
        />
        <Box pb8 absolute {...positionStyle} w="100%" opacity={1.0}>
          <LoadingIndicator centered pb8 />
        </Box>
      </div>
    );
  }

  render() {
    const {
      isFetching,
      hasMore,
      notificationsItems,
      notificationType,
      getScrollContainer,
    } = this.props;

    return (
      <Box key={notificationType}>
        <InfiniteScroll
          hasMore={hasMore}
          isFetching={isFetching}
          loadMore={this.fetchPage}
          pageStart={1}
          getScrollParent={getScrollContainer}
        >
          {this.renderItems(notificationType, notificationsItems)}
        </InfiniteScroll>

        {isFetching && this.renderLoader()}
      </Box>
    );
  }
}
