import * as routes from 'api';

import { getUserReferralsDollarAmount } from 'selectors/session';

import {
  createAsyncGetAction,
  createAsyncPostAction,
  withAlerts,
} from 'util/redux';

export const actionTypes = {
  SEND_USER_REFERRALS_REQUEST: 'USER_REFERRALS/SEND_USER_REFERRALS_REQUEST',
  SEND_USER_REFERRALS_SUCCESS: 'USER_REFERRALS/SEND_USER_REFERRALS_SUCCESS',
  SEND_USER_REFERRALS_FAILURE: 'USER_REFERRALS/SEND_USER_REFERRALS_FAILURE',
  FETCH_USER_REFERRALS_REQUEST: 'USER_REFERRALS/FETCH_USER_REFERRALS_REQUEST',
  FETCH_USER_REFERRALS_SUCCESS: 'USER_REFERRALS/FETCH_USER_REFERRALS_SUCCESS',
  FETCH_USER_REFERRALS_FAILURE: 'USER_REFERRALS/FETCH_USER_REFERRALS_FAILURE',
  ADD_EMAIL_ADDRESSES: 'USER_REFERRALS/ADD_EMAIL_ADDRESSES',
};

const addEmailAddresses = (emailAddresses, referralAmount) => ({
  type: actionTypes.ADD_EMAIL_ADDRESSES,
  payload: {
    emailAddresses,
    status: 'sent',
    amount: referralAmount,
  },
});

const sendUserReferralsRequest = emailAddresses => {
  const meta = { requestId: actionTypes.SEND_USER_REFERRALS_REQUEST };

  return createAsyncPostAction(
    routes.userReferralsRoute(),
    [
      { type: actionTypes.SEND_USER_REFERRALS_REQUEST, meta },
      { type: actionTypes.SEND_USER_REFERRALS_SUCCESS, meta },
      { type: actionTypes.SEND_USER_REFERRALS_FAILURE, meta },
    ],
    {
      body: { email_addresses: emailAddresses },
    }
  );
};

export const fetchReferrals = () => {
  const meta = {
    requestId: actionTypes.FETCH_USER_REFERRALS_REQUEST,
  };

  return withAlerts(
    createAsyncGetAction(routes.userReferralsRoute(), [
      { type: actionTypes.FETCH_USER_REFERRALS_REQUEST, meta },
      { type: actionTypes.FETCH_USER_REFERRALS_SUCCESS, meta },
      { type: actionTypes.FETCH_USER_REFERRALS_FAILURE, meta },
    ])
  );
};

export const submitUserReferrals = emailAddresses => (dispatch, getState) => {
  const referralAmount = getUserReferralsDollarAmount(getState());
  dispatch(sendUserReferralsRequest(emailAddresses));
  return dispatch(addEmailAddresses(emailAddresses, referralAmount));
};
