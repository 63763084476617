import * as routes from 'api';

import {
  createAsyncGetAction,
  createAsyncPostAction,
  withAlerts,
} from 'util/redux';

import { getModalIsSubmitting } from '../selectors/hrPro';
import { toI18n } from '../util/i18n';

export const actionTypes = {
  ENSURE_HR_ON_DEMAND_REQUEST: 'HR/ENSURE_HR_ON_DEMAND_REQUEST',
  ENSURE_HR_ON_DEMAND_SUCCESS: 'HR/ENSURE_HR_ON_SUCCESS',
  ENSURE_HR_ON_DEMAND_FAILURE: 'HR/ENSURE_HR_ON_DEMAND_FAILURE',
  FETCH_PAGE_DATA_REQUEST: 'HR_PRO/FETCH_PAGE_DATA_REQUEST',
  FETCH_PAGE_DATA_SUCCESS: 'HR_PRO/FETCH_PAGE_DATA_SUCCESS',
  FETCH_PAGE_DATA_FAILURE: 'HR_PRO/FETCH_PAGE_DATA_FAILURE',
  OPEN_MODAL: 'HR_PRO/OPEN_MODAL',
  CLOSE_MODAL: 'HR_PRO/CLOSE_MODAL',
  SUBSCRIBE_REQUEST: 'HR_PRO/SUBSCRIBE_REQUEST',
  SUBSCRIBE_SUCCESS: 'HR_PRO/SUBSCRIBE_SUCCESS',
  SUBSCRIBE_FAILURE: 'HR_PRO/SUBSCRIBE_FAILURE',
};

export const fetchInitialData = () =>
  createAsyncGetAction(
    routes.hrProRoute(),
    [
      actionTypes.FETCH_PAGE_DATA_REQUEST,
      actionTypes.FETCH_PAGE_DATA_SUCCESS,
      actionTypes.FETCH_PAGE_DATA_FAILURE,
    ],
    {}
  );

export const ensureHrOnDemand = () =>
  createAsyncPostAction(
    routes.hrProEnsureHrOnDemandRoute(),
    [
      actionTypes.ENSURE_HR_ON_DEMAND_REQUEST,
      actionTypes.ENSURE_HR_ON_DEMAND_SUCCESS,
      actionTypes.ENSURE_HR_ON_DEMAND_FAILURE,
    ],
    {}
  );

// MODAL
export const subscribe = ({ payload }) =>
  withAlerts(
    createAsyncPostAction(
      routes.hrProSubscribeRoute(),
      [
        actionTypes.SUBSCRIBE_REQUEST,
        actionTypes.SUBSCRIBE_SUCCESS,
        actionTypes.SUBSCRIBE_FAILURE,
      ],
      {
        body: {
          ...payload,
        },
        bailout: state => getModalIsSubmitting(state),
      }
    ),
    {
      success: toI18n('hr_pro.subscribe.success'),
    }
  );
