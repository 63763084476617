import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MUITooltip from '@mui/material/Tooltip';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import IconButton from 'fe-design-base/molecules/IconButton';
import baseColors from 'fe-design-base/styles/colors';
import zIndex from 'fe-design-base/styles/zIndex';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { TooltipProps } from './types';
import Wrapper from './Wrapper';

const variantToBackgroundColor = {
  mono: 'mono900',
  primary: 'purple700',
} as const;

const TEXT_COLOR = baseColors.mono0;

const Tooltip = ({
  buttonTitle,
  children,
  content,
  count,
  cx,
  cxEl,
  onButtonClick,
  onDismissClick,
  placement = 'bottom',
  title,
  uxElement,
  open,
  variant = 'mono',
  overrideZIndex,
}: TooltipProps & CxProps) => {
  const backgroundColor = baseColors[variantToBackgroundColor[variant]];
  const isPrimary = variant === 'primary';
  const [renderTooltip, setRenderTooltip] = useState(open || false);
  const timer: React.MutableRefObject<
    NodeJS.Timeout | number | null | undefined
  > = useRef();

  useEffect(() => {
    setRenderTooltip(!!open);
  }, [open]);

  useEffect(() => () => {
    if (timer.current) clearTimeout(timer.current);
  });

  const handleMouseEnter = useCallback(() => {
    if (!renderTooltip) setRenderTooltip(true);
  }, [renderTooltip]);

  const handleMouseLeave = useCallback(() => {
    if (renderTooltip)
      // Timeout is to allow the animations to finish before unmounting
      timer.current = setTimeout(() => setRenderTooltip(false), 200);
  }, [renderTooltip]);

  const trackUx = useTrackUx({
    element: uxElement,
  } as any);

  const handleIsOpen = useCallback(() => {
    // when tooltip is triggered from hover
    if (uxElement && open === undefined)
      trackUx(EVENT_ACTIONS.TOOLTIP_VIEWED, TRACK_ACTION_TYPES.VIEW);
  }, [open, trackUx, uxElement]);

  useEffect(() => {
    // when tooltip is triggered from open prop
    if (uxElement && open)
      trackUx(EVENT_ACTIONS.TOOLTIP_VIEWED, TRACK_ACTION_TYPES.VIEW);
  }, [open, trackUx, uxElement]);

  const handleDismissClick = useCallback(() => {
    if (uxElement) {
      trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK);
    }
    onDismissClick?.();
  }, [onDismissClick, trackUx, uxElement]);

  const popperProps = useMemo(
    () => ({
      className: cx(),
      sx: {
        '&.FDBTooltip': {
          zIndex: overrideZIndex || zIndex.zindexFdbTooltip,
          '& .MuiTooltip-tooltip': {
            padding: '16px',
            // border gets rid of the appearance of a border with the arrow
            border: `1px solid ${backgroundColor}`,
            borderRadius: '8px',
            background: backgroundColor,
            color: TEXT_COLOR,
          },
          '& .MuiTooltip-arrow': {
            color: backgroundColor,
            fontSize: 18,
          },
          '& .FDBButton': {
            // overriding purple500 border on secondary button
            borderColor: 'transparent',
          },
          '& .FDBTooltip__dismiss': {
            // overriding standard IconButton color
            color: baseColors.mono0,
            alignSelf: 'start',

            '&:hover, &:focus': {
              color: baseColors.mono0,
              background: baseColors.purple900,
            },
          },
        },
      },
    }),
    [backgroundColor, cx, overrideZIndex]
  );

  if (!title && !content) return <>{children}</>;

  return (
    <div
      onMouseEnter={open ? undefined : handleMouseEnter}
      onMouseLeave={open ? undefined : handleMouseLeave}
    >
      {renderTooltip ? (
        <MUITooltip
          PopperProps={popperProps}
          arrow
          placement={placement}
          onOpen={handleIsOpen}
          open={open}
          title={
            <Box maxw={isPrimary ? 268 : 208}>
              {title && isPrimary && (
                <Box spacebetween vcenter mb={16}>
                  <Text variant="heading4">{title}</Text>
                  <IconButton
                    className={cxEl('dismiss')}
                    size="small"
                    icon="Close"
                    onClick={handleDismissClick}
                  />
                </Box>
              )}
              <Box>
                <Text variant="bodySm">{content}</Text>
              </Box>

              {!!onButtonClick && isPrimary && buttonTitle && (
                <Box pt={16} w="100%">
                  <Box spacebetween vcenter>
                    <Text color="primary300" variant="bodySmTight">
                      {count && count}
                    </Text>

                    <Button
                      size="small"
                      variant="secondary"
                      uxElement={uxElement}
                      onClick={onButtonClick}
                    >
                      {buttonTitle}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          }
        >
          <Wrapper>{children}</Wrapper>
        </MUITooltip>
      ) : (
        children
      )}
    </div>
  );
};

export default withCx<TooltipProps>('FDBTooltip')(Tooltip);
