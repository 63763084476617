import baseColors from 'fe-design-base/styles/colors';

import { Overrides } from '../../types';

export const badgeOverrides: Overrides = {
  MuiBadge: {
    styleOverrides: {
      root: {
        '& .FDBBadge': {
          width: '12px',
          height: '12px',
          backgroundColor: baseColors.purple500,

          '&.FDBBadge--text': {
            height: '18px',
            width: 'fit-content',
            paddingLeft: '6px',
            paddingRight: '6px',
            position: 'relative',
            transform: 'translateX(50%)',
            right: '10px',
          },

          '&.FDBBadge--disabled': {
            backgroundColor: baseColors.purple300,
          },

          '&.FDBBadge--loud': {
            backgroundColor: baseColors.red300,
            '&.FDBBadge--disabled': {
              backgroundColor: baseColors.red100,
            },
          },
        },
        '& .FDBBadge--accent': {
          backgroundColor: `${baseColors.accent900}`,

          '&.FDBBadge--disabled': {
            backgroundColor: 'transparent', // Non specified,
            border: 'none', // Removed to match the background,
          },
        },
      },
    },
  },
};
