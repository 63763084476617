import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Chip from 'fe-design-base/molecules/Chip';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';

const { cx } = cxHelpers('SettingsSelection');

const RecurringEarningChipGroup = ({ chipOptions, selectedValue, onClick }) => (
  <Box row className={cx()}>
    {chipOptions.map((option, i) => (
      <Box key={`option-${i}`} mr={8}>
        <Chip
          value={option.value}
          selected={option.value === selectedValue}
          onClick={onClick}
        >
          {option.label}
        </Chip>
      </Box>
    ))}
  </Box>
);

RecurringEarningChipGroup.propTypes = {
  chipOptions: PropTypes.array.isRequired,
  selectedValue: PropTypes.string,
  onClick: PropTypes.func,
};

export default RecurringEarningChipGroup;
