import colors from 'fe-design-base/styles/colors';
import { shadows } from 'fe-design-base/styles/shadows';
import { typography } from 'fe-design-base/styles/typography';

import { Overrides } from '../../types';

export const autocompleteOverrides: Overrides = {
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        '&.FDBAutocomplete': {
          backgroundColor: colors.mono0,

          '&[aria-disabled="true"]': {
            cursor: 'not-allowed',

            '& .MuiFormControl-root .MuiOutlinedInput-root > fieldset': {
              border: 'none !important',
            },
            '& .FDBTextInput': {
              pointerEvents: 'none',
            },
          },
          '& .MuiTextField-root': {
            width: '100%',
          },
          '& .MuiAutocomplete-endAdornment': {
            '& .MuiAutocomplete-clearIndicator.MuiButtonBase-root, & .MuiAutocomplete-popupIndicator.MuiButtonBase-root':
              {
                marginRight: '-2px',
                verticalAlign: 'middle',
                alignItems: 'center',
              },

            '& .MuiAutocomplete-clearIndicator.MuiButtonBase-root': {
              padding: '4px',
            },
            '& .MuiAutocomplete-popupIndicator.MuiButtonBase-root': {
              padding: '2px',

              svg: {
                width: '24px',
                height: '24px',
              },
            },
          },

          // eslint-disable-next-line max-len
          '&.MuiAutocomplete-hasClearIcon:not(.FDBAutocomplete--showPopupIcon) .MuiAutocomplete-endAdornment':
            {
              top: 'calc(50% - 14px)',
            },

          '&.MuiAutocomplete-hasPopupIcon.FDBAutocomplete--showPopupIcon': {
            '& .MuiAutocomplete-popupIndicator': {
              display: 'inline-flex',
            },
          },
          '&.MuiAutocomplete-hasPopupIcon': {
            '& .MuiAutocomplete-popupIndicator': {
              display: 'none',
              color: colors.mono700,
            },
            '& .Mui-disabled .MuiAutocomplete-popupIndicator': {
              color: colors.mono500,
              opacity: 0.5,
            },
            '& .MuiAutocomplete-popupIndicator:hover': {
              border: 'none',
              backgroundColor: 'initial',
              color: colors.mono700,
            },
            '& .MuiAutocomplete-popupIndicator .MuiTouchRipple-root': {
              display: 'none',
            },
          },
          '&.MuiAutocomplete-hasClearIcon': {
            '& .MuiAutocomplete-clearIndicator': {
              color: colors.purple500,

              '&:hover': {
                border: 'initial',
                backgroundColor: 'initial',
              },
            },

            // Padding for the input controls how much of the label text shows
            // Variation with caret not showing
            '& .MuiOutlinedInput-root': {
              paddingRight: '20px',
            },
            '&.Mui-focused .MuiOutlinedInput-root': {
              paddingRight: '30px',
            },

            // Variation with caret showing
            '&.FDBAutocomplete--showPopupIcon': {
              '& .MuiOutlinedInput-root': {
                paddingRight: '48px',
              },
              '&.Mui-focused .MuiOutlinedInput-root': {
                paddingRight: '54px',
              },
            },
          },
        },
      },
      inputRoot: {
        padding: 0,

        '&.MuiOutlinedInput-root': {
          paddingRight: 0,
        },
      },
      input: {
        color: colors.mono900,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: `${typography.body.fontSize}px`,
      },
      noOptions: {
        color: colors.mono900,
        fontSize: `${typography.body.fontSize}px`,
      },
      paper: {
        boxShadow: shadows.z2(),
      },
      listbox: {
        padding: 0,

        '& .MuiAutocomplete-option': {
          color: colors.mono900,

          '&.Mui-focusVisible': {
            backgroundColor: colors.purple300,
          },
        },
        '& .MuiAutocomplete-option:hover': {
          backgroundColor: colors.purple100,
        },
        '& .MuiAutocomplete-option:active': {
          backgroundColor: colors.purple300,
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          backgroundColor: colors.purple100,

          '&.Mui-focusVisible[aria-selected="true"]': {
            backgroundColor: colors.purple300,
          },

          '&:hover, &.Mui-focused': {
            backgroundColor: colors.purple100,
          },
          '&:active': {
            backgroundColor: colors.purple300,
          },
        },
        '& .MuiAutocomplete-option[aria-disabled="true"]': {
          backgroundColor: colors.mono300,
          color: colors.mono500,
          opacity: 1,
        },
        '& .FDBButton.FDBButton--tertiary': {
          padding: 0,
          minWidth: 'initial',

          '&:hover, &:focus-visible, &:active': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
  },
};
