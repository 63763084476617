import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import CircularInfinite from 'fe-design-base/molecules/Loaders/CircularInfinite';

import BottomDrawer from '../BottomDrawer';

const LoadingDrawer = ({ animations }) => (
  <BottomDrawer animations={animations} hideProgressBar>
    {() => (
      <Box w="100%" h="100%" hcenter vcenter>
        <CircularInfinite variant="dark" size="large" />
      </Box>
    )}
  </BottomDrawer>
);

export default LoadingDrawer;
