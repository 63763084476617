import React from 'react';
import Icon from 'react-icon-base';

const Timeclock = props => (
  <Icon viewBox="0 0 17 17" fill="transparent" {...props}>
    <g>
      <path
        stroke={props.color || '#D0B6E0'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth=".889"
        d="M5.43 1.08A8.03 8.03 0 0 0 8.5 16.528 8.028 8.028 0 1 0 8.5.472v6.042c0 1.227.995 2.222 2.222 2.222h1.792"
      />
      <path
        fill={props.color || '#D0B6E0'}
        fillRule="evenodd"
        stroke={props.color || '#D0B6E0'}
        strokeLinecap="square"
        strokeMiterlimit="16"
        strokeWidth=".667"
        d="M12.043 9.53l1.424-.822-1.424-.822V9.53z"
        clipRule="evenodd"
      />
    </g>
  </Icon>
);

export default Timeclock;
