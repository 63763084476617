import { createSlice } from '@reduxjs/toolkit';

import { setCheckCompanyId } from './actions';
import { verifyFein } from './thunks';

const slice = createSlice({
  name: 'linearFlow',
  initialState: {
    businessLegalName: null,
    fein: null,
    checkCompanyId: null,
    verificationStatus: null,
    payrollCompanyType: null,
    previousPayrollProvider: null,
    unstructuredSwitcherTaxes: null,
    accountantCooperation: null,
  },
  reducers: {
    setBusinessLegalName: (state, { payload }) => {
      state.businessLegalName = payload;
    },
    setFein: (state, { payload }) => {
      state.fein = payload;
    },
    setVerificationStatus: (state, { payload }) => {
      state.verificationStatus = payload;
    },
    setPayrollCompanyType: (state, { payload }) => {
      state.payrollCompanyType = payload;
    },
    setPreviousPayrollProvider: (state, { payload }) => {
      state.previousPayrollProvider = payload;
    },
    setUnstructuredSwitcherTaxes: (state, { payload }) => {
      state.unstructuredSwitcherTaxes = payload;
    },
    setAccountantCooperation: (state, { payload }) => {
      state.accountantCooperation = payload;
    },
  },
  extraReducers: {
    [setCheckCompanyId]: (state, { payload }) => {
      state.checkCompanyId = payload;
    },
    [verifyFein.pending]: state => {
      state.isVerificationPending = true;
    },
    [verifyFein.fulfilled]: (state, action) => {
      const { status, federal_ein, legal_name } = action.payload;
      state.isVerificationPending = false;
      state.verificationStatus = status ?? state.verificationStatus;
      state.fein = federal_ein ?? state.fein;
      state.businessLegalName = legal_name ?? state.businessLegalName;
    },
    [verifyFein.rejected]: state => {
      state.isVerificationPending = false;
    },
  },
});

export const { actions, reducer } = slice;
