import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import MUIStepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Icon from 'fe-design-base/atoms/Icon';
import { ExtendedStepIconProps } from 'fe-design-base/molecules/StepIndicator/types';
import baseColors from 'fe-design-base/styles/colors';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

const ColorLibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: baseColors.mono300,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  fontWeight: 700,
  color: baseColors.mono500,
  zIndex: 1,
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  borderColor: baseColors.mono300,
  borderWidth: 2,
  borderStyle: 'solid',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    borderColor: baseColors.purple500,
    backgroundColor: baseColors.mono0,
    color: baseColors.purple500,
  }),
  ...(ownerState.completed && {
    background: baseColors.purple500,
    color: baseColors.mono0,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: baseColors.purple500,
  }),
}));

const ColorLibStepIcon = ({
  active,
  completed,
  className,
  icon,
}: ExtendedStepIconProps) => (
  <ColorLibStepIconRoot
    ownerState={{ completed, active }}
    className={className}
  >
    {completed ? <Icon iconName="Success" size="small" /> : <span>{icon}</span>}
  </ColorLibStepIconRoot>
);

type Props = {
  onStepClick?: (index: number) => void;
  label: string;
  index: number;
  uxElement?: string;
};

const StepLabel = ({ onStepClick, label, index, uxElement }: Props) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!onStepClick) ref.current?.blur();
  }, [onStepClick]);

  const trackUx = useTrackUx(
    useMemo(
      () => ({
        labelText: label,
        element: uxElement,
        index,
      }),
      [uxElement, label, index]
    ) as any
  );

  const handleStepClick = useCallback(() => {
    if (onStepClick) {
      onStepClick(index);
      if (uxElement) {
        trackUx(EVENT_ACTIONS.STEP_CLICKED, TRACK_ACTION_TYPES.CLICK);
      }
    }
  }, [index, onStepClick, trackUx, uxElement]);

  return (
    <div onClick={handleStepClick}>
      <MUIStepLabel
        StepIconComponent={ColorLibStepIcon}
        sx={{
          ...{
            [`& .${stepLabelClasses.label}`]: {
              display: 'block',
              marginTop: '8px !important',

              '& .label-item': {
                border: 0,
                fontSize: 14,
                fontWeight: 700,
                padding: '0 8px',
                lineHeight: '24px',
                borderRadius: '8px',
                background: 'transparent',
                color: baseColors.purple500,

                '&:focus-visible': {
                  textDecoration: 'none',
                  color: `${baseColors.accent900}`,
                  outline: `2px solid ${baseColors.accent500}`,
                },
              },
            },
            [`& .${stepLabelClasses.completed} .label-item`]: {
              textDecoration: 'underline',
              color: `${baseColors.purple500}`,
              textDecorationColor: baseColors.purple500,

              '&:hover': {
                color: baseColors.accent900,
                cursor: Boolean(onStepClick) ? 'pointer' : 'initial',
              },
              '&:active': {
                textDecoration: 'none',
                color: baseColors.purple700,
              },
            },
            [`& .${stepLabelClasses.disabled} .label-item`]: {
              color: baseColors.mono500,
            },
          },
        }}
      >
        <button
          ref={ref}
          className="label-item"
          tabIndex={onStepClick ? 0 : -1}
        >
          {label}
        </button>
      </MUIStepLabel>
    </div>
  );
};

export default StepLabel;
