import { Globals } from '@react-spring/web';
import configureStore from 'initializers/configureStore';
import handleOpenModalOnInitalPageLoad from 'initializers/handleOpenModalOnInitalPageLoad';
import RootMounter from 'initializers/RootMounter';
import initRailsBridge from 'rails/bridge';

import { updateSession } from 'actions/session';

import { initialHeapIdentify } from 'util/tracking';
import * as TRACKING from 'util/tracking_constants';

import handleAnnouncements from './announcements';
import registerFetchIntercept from './fetchIntercept';

// Load react stuff ONLY when jQuery gets loaded
window.$(() => {
  if (window.Homebase.initialize) {
    window.Homebase.initialize(); // Load the main jquery/backbone stuff
  }

  registerFetchIntercept();

  // Initiate store with session data
  const store = configureStore();
  const session = window.HOMEBASE_REACT_SESSION;

  store.dispatch(updateSession(session));

  initialHeapIdentify(session.analytics);

  const stytchPublicToken = window.STYTCH_PUBLIC_TOKEN;
  const rootMounter = new RootMounter(store, stytchPublicToken);

  // Initiate rails bridge
  window.Homebase = window.Homebase || {};
  window.Homebase.RailsReactBridge = initRailsBridge(
    store,
    session.reactRoutes,
    rootMounter,
    window.$
  );
  window.Homebase.TRACKING = TRACKING;

  handleAnnouncements(store);

  rootMounter.mountAll();
  // ------------

  if (window.initHomebase) {
    window.initHomebase();
  }

  if (window.Homebase.loadPages) {
    window.Homebase.loadPages(); // Render backbone pages
  }

  if (window.renderBackbonePage) {
    window.renderBackbonePage();
  }

  if (window.Homebase.env === 'test') Globals.assign({ skipAnimation: true });
  window.dispatchEvent(new Event('RailsReactBridgeLoaded'));

  handleOpenModalOnInitalPageLoad(store);
});
