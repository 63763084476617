import React from 'react';
import Icon from 'react-icon-base';

const ChefHat = props => (
  <Icon viewBox="0 0 32 32" fill="transparent" {...props}>
    <path
      fill="#fff"
      d="M20.762 9.619c-.535.03-1.31.268-1.726.595-.714-.923-1.786-1.548-3.036-1.548s-2.35.625-3.065 1.548c-.417-.327-1.19-.595-1.726-.595-1.578 0-2.857 1.28-2.857 2.857h.03c0 1.25 1.904 5.714 1.904 5.714h1.786l-.357-4.464c-.03 0-.03-.03-.03-.03 0-.119.119-.238.238-.238l.476-.03c.149 0 .238.09.238.209l.387 4.553h2.47v-4.524c0-.119.09-.238.239-.238h.476c.119 0 .238.12.238.238v4.524h2.5l.387-4.553c0-.12.089-.209.238-.209l.476.03c.119 0 .238.12.238.238v.03L19.9 18.19h1.816s1.904-4.464 1.904-5.714c0-1.548-1.31-2.857-2.857-2.857zM10.286 22.952c0 .536.417.953.953.953h9.523c.506 0 .953-.417.953-.953v-3.81H10.286v3.81z"
    />
  </Icon>
);

export default ChefHat;
