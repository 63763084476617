import { createSelector } from 'reselect';

import { hasUnreadApplicationConversations } from 'selectors/applicantMessenger';
import {
  getMessagingActivationEnabled,
  getMessagingEnabled,
} from 'selectors/session';
import { getTeamAnnouncements, showingIndexView } from 'selectors/teamEmails';

import { isToday, isYesterday, momentInZone } from 'util/dateTime';

import {
  ADHOC,
  APPLICATION_MESSENGER_VIEWS,
  MESSAGING_V2_SLICE_NAME,
  VIEWS,
} from './constants';

const selectApplicationMessageRouter = state =>
  state.getIn(['messenger', 'applicationMessageRouter']);

export const selectCurrentApplicationMessageView = state =>
  selectApplicationMessageRouter(state).get('view');

export const selectPrefilledMessage = state =>
  state.get(MESSAGING_V2_SLICE_NAME).prefilledMessage;

export const selectPrefilledUserIds = state =>
  state.get(MESSAGING_V2_SLICE_NAME).prefilledUserIds || [];

export const selectIsFetchingChannels = state =>
  state.get(MESSAGING_V2_SLICE_NAME).isFetchingChannels;

export const selectIsMarkingMessageAsRead = (state, { messageId }) =>
  state.get(MESSAGING_V2_SLICE_NAME).isMarkingMessageAsRead[messageId];

export const selectIsDeletingMessage = (state, { messageId }) =>
  state.get(MESSAGING_V2_SLICE_NAME).isDeletingMessage[messageId];

export const selectCurrentTab = state =>
  state.get(MESSAGING_V2_SLICE_NAME).currentTab;

export const selectCurrentReadReceiptTab = state =>
  state.get(MESSAGING_V2_SLICE_NAME).currentReadReceiptTab;

export const selectCurrentChatView = state =>
  state.get(MESSAGING_V2_SLICE_NAME).currentChatView;

export const selectChannelViewScrollPosition = state =>
  state.get(MESSAGING_V2_SLICE_NAME).channelViewScrollPosition;

export const selectLocationParticipants = state =>
  state.get(MESSAGING_V2_SLICE_NAME).locationParticipants;

export const selectChannelParticipants = state =>
  state.get(MESSAGING_V2_SLICE_NAME).channelParticipants;

export const selectIsFetchingLocationParticipants = state =>
  state.get(MESSAGING_V2_SLICE_NAME).isFetchingLocationParticipants;

export const selectIsFetchingChannelParticipants = state =>
  state.get(MESSAGING_V2_SLICE_NAME).isFetchingChannelParticipants;

export const selectIsCreatingChannel = state =>
  state.get(MESSAGING_V2_SLICE_NAME).isCreatingChannel;

export const selectLocationQuickAccess = state =>
  state.get(MESSAGING_V2_SLICE_NAME).locationQuickAccess;

export const selectLocationParticipantsPagination = state =>
  state.get(MESSAGING_V2_SLICE_NAME).locationParticipantsPagination;

export const selectChannelParticipantsPagination = state =>
  state.get(MESSAGING_V2_SLICE_NAME).channelParticipantsPagination;

export const selectCurrentChannelId = state =>
  state.get(MESSAGING_V2_SLICE_NAME).currentChannelId;

export const selectIsReadReceiptsDrawerOpen = state =>
  state.get(MESSAGING_V2_SLICE_NAME).isReadReceiptsDrawerOpen;

export const selectCurrentReadReceiptsMessageId = state =>
  state.get(MESSAGING_V2_SLICE_NAME).currentReadReceiptsMessageId;

export const selectMessageReadReceiptsAreLoading = createSelector(
  state => state.get(MESSAGING_V2_SLICE_NAME).isFetchingReadReceipts,
  selectCurrentReadReceiptsMessageId,
  (isFetchingReadReceipts, messageId) => isFetchingReadReceipts[messageId]
);

export const selectIsFetchingMessages = createSelector(
  selectCurrentChannelId,
  state => state.get(MESSAGING_V2_SLICE_NAME).isFetchingMessages,
  (channelId, isFetchingMessages) => isFetchingMessages[channelId]
);
export const selectIsUpdatingChannel = createSelector(
  selectCurrentChannelId,
  state => state.get(MESSAGING_V2_SLICE_NAME).isUpdatingChannel,
  (channelId, isUpdatingChannel) => isUpdatingChannel[channelId]
);

export const selectExistingChannelParticipants = createSelector(
  selectCurrentChannelId,
  state => state.get(MESSAGING_V2_SLICE_NAME).existingChannelParticipants,
  (channelId, existingChannelParticipants) =>
    existingChannelParticipants[channelId]
);

export const selectAfterTerminus = state =>
  state.get(MESSAGING_V2_SLICE_NAME).afterTerminus;

export const selectBeforeTerminus = state =>
  state.get(MESSAGING_V2_SLICE_NAME).beforeTerminus;

export const selectChannelsListNotCompletelyFetched = createSelector(
  selectAfterTerminus,
  selectBeforeTerminus,
  (afterTerminus, beforeTerminus) =>
    !((afterTerminus && !beforeTerminus) || (afterTerminus && beforeTerminus))
);

export const selectLastItemId = state => {
  const keys = Object.keys(state.get(MESSAGING_V2_SLICE_NAME).channels);

  return keys.length ? keys[keys.length - 1] : null;
};

export const selectChannels = state =>
  Object.values(state.get(MESSAGING_V2_SLICE_NAME).channels);

export const selectMessages = state =>
  state.get(MESSAGING_V2_SLICE_NAME).messages;

export const selectMessagesByCurrentChannelId = createSelector(
  selectMessages,
  selectCurrentChannelId,
  (messages, channelId) => messages[channelId] || []
);

export const selectChannelMessages = createSelector(
  selectMessagesByCurrentChannelId,
  messagesByChannelId => {
    const decoratedMessages = [];
    const existingDateTitles = [];

    messagesByChannelId.forEach((message, index) => {
      const currentMessage = { ...message };
      const prevMessage = decoratedMessages[index - 1];
      const nextMessage = { ...(messagesByChannelId[index + 1] || {}) };
      const createdDate = currentMessage.created_at_formatted;
      const dayMonthDay = createdDate.split(' ')[0];

      if (!existingDateTitles.includes(dayMonthDay)) {
        existingDateTitles.push(dayMonthDay);
        if (isToday(createdDate)) {
          /** Display Today before first message of today */
          currentMessage.showDayTitle = true;
        } else if (isYesterday(createdDate)) {
          /** Display Yesterday before first message of yesterday */
          currentMessage.showDayTitle = true;
        } else {
          currentMessage.showDayTitle = true;
        }
      }

      const nextMessageCreatedDate = momentInZone(
        nextMessage?.created_at_secs,
        'unix'
      );
      const currentMessageCreatedDate = momentInZone(
        currentMessage.created_at_secs,
        'unix'
      );

      const differentSenders =
        nextMessage.sending_user?.id !== currentMessage.sending_user.id;
      const differentDays = !nextMessageCreatedDate.isSame(
        currentMessageCreatedDate,
        'day'
      );
      const differentMinutes = !nextMessageCreatedDate.isSame(
        currentMessageCreatedDate,
        'minute'
      );

      /**
       * Display summary of message sender after senders last message
       * e.g. FirstName LastName | Mon, Aug 24, 10:43 AM
       * */
      currentMessage.showDetails =
        differentSenders || differentDays || differentMinutes;

      if (
        !prevMessage ||
        prevMessage?.sending_user.id !== currentMessage.sending_user.id
      ) {
        /** Only show the avatar for the first message in a set for a user */
        currentMessage.showAvatar = true;
      }

      decoratedMessages.push(currentMessage);
    });

    return decoratedMessages;
  }
);

export const selectMessageReceipts = createSelector(
  selectMessagesByCurrentChannelId,
  selectCurrentReadReceiptsMessageId,
  (messagesByChannelId, messageId) =>
    messagesByChannelId?.find(message => message.id === messageId)?.receipts ||
    {}
);

export const selectMessageReadReceipts = createSelector(
  selectMessageReceipts,
  messagesReceipts => messagesReceipts?.readReceipts || []
);

export const selectMessageUnreadReceipts = createSelector(
  selectMessageReceipts,
  messagesReceipts => messagesReceipts?.unreadReceipts || []
);

export const selectMessageUnreachableReceipts = createSelector(
  selectMessageReceipts,
  messagesReceipts => messagesReceipts?.unsentReceipts || []
);

export const selectMessageReadReceiptsPagination = createSelector(
  selectMessagesByCurrentChannelId,
  selectCurrentReadReceiptsMessageId,
  (messagesByChannelId, messageId) =>
    messagesByChannelId?.find(message => message.id === messageId)
      ?.readReceiptsPagination || {}
);

export const selectChannelMessagePagination = createSelector(
  selectCurrentChannelId,
  state => state.get(MESSAGING_V2_SLICE_NAME).messagePagination,
  (currentChannelId, messagePagination) => messagePagination[currentChannelId]
);

export const selectHasChannels = createSelector(
  selectChannels,
  channels => channels.length > 0
);

export const selectEntireTeamChannel = createSelector(
  selectChannels,
  channels => channels.find(channel => channel.type === 'entire_team')
);

export const selectAllManagersChannel = createSelector(
  selectChannels,
  channels => channels.find(channel => channel.type === 'all_managers')
);

export const selectedFilteredChannels = createSelector(
  selectChannels,
  channels => channels.filter(c => !c.is_hidden)
);

export const selectTeamHasUnreadMessages = createSelector(
  state => state.get(MESSAGING_V2_SLICE_NAME).hasUnreadMessages,
  selectChannels,
  (hasUnreadMessages, channels) => {
    if (hasUnreadMessages !== null) return Boolean(hasUnreadMessages);

    return channels.some(channel => channel.is_unread);
  }
);

export const selectTeamHasUnreadMessagesOrConvos = createSelector(
  selectTeamHasUnreadMessages,
  hasUnreadApplicationConversations,
  (msgUnread, convoUnread) => msgUnread || convoUnread
);

export const selectCurrentChannel = createSelector(
  selectChannels,
  selectCurrentChannelId,
  (channels, channelId) => channels.find(c => c.id === channelId)
);

export const selectShouldHideMessageDrawerTabs = createSelector(
  selectCurrentChatView,
  selectCurrentApplicationMessageView,
  showingIndexView,
  (chatView, applicantMessengerView, showingTeamIndex) =>
    !(
      (chatView === VIEWS.INDEX || chatView === VIEWS.EMAIL) &&
      applicantMessengerView === APPLICATION_MESSENGER_VIEWS.INDEX &&
      showingTeamIndex
    )
);

export const selectDisplayMessengerActivationView = createSelector(
  getTeamAnnouncements,
  selectChannels,
  getMessagingEnabled,
  getMessagingActivationEnabled,
  (teamAnnouncements, channels, messagingEnabled, messagingActivationEnabled) =>
    messagingActivationEnabled &&
    !channels.some(
      channel =>
        channel.type === ADHOC ||
        (channel.type !== ADHOC && Boolean(channel.description))
    ) &&
    teamAnnouncements.size < 1 &&
    messagingEnabled
);
