import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { SESSION_STORAGE_KEYS } from 'features/onboarding/constants';

import Text from 'components/Text';

import cxHelpers from 'util/className';

import withNavItemData from '../../hocs/withNavItemData';
import NavItem from '../NavItem';

@withNavItemData('signOut')
@cxHelpers('SignOutNavItem')
export default class SignOutNavItem extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
  };

  handleCLick = () => {
    window.sessionStorage.removeItem(
      SESSION_STORAGE_KEYS.TIMESHEETS_GUIDED_SETUP_MAYBE_LATER
    );
  };

  render() {
    const { url, ...props } = this.props;

    return (
      <NavItem
        {...props}
        href={url}
        method="delete"
        rel="nofollow"
        onClick={this.handleCLick}
      >
        <Text fs14 i18n="nav_links.devise.sign_out" />
      </NavItem>
    );
  }
}
