import React from 'react';

import RequiredMessage from 'features/employeeProfile/components/RequiredMessage';
import { usePayrollRequiredAttribute } from 'features/employeeProfile/hooks/usePayrollRequiredAttribute';

const RequiredAttribute = ({ user, targetObject, attribute, children }) => {
  const payrollRequiredAttribute = usePayrollRequiredAttribute({
    user,
    targetObject,
    attribute,
  });

  if (payrollRequiredAttribute) {
    return <RequiredMessage />;
  }

  return <>{children}</>;
};

export default RequiredAttribute;
