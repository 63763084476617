import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import { sendFeatureFeedbackRequest } from 'actions/featureFeedback';
import * as modalsActions from 'actions/modals';

import { getModalProps, getModalType } from 'selectors/modals';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import TextInput from 'components/forms/TextInput';
import { Mobile } from 'components/MediaQuery';
import Modal from 'components/modals/Modal';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';

@connect(
  state => ({
    modalTypeShowing: getModalType(state),
    modalProps: getModalProps(state),
  }),
  {
    hideModal: modalsActions.hideModal,
  }
)
@cxHelpers('FeatureFeedbackModal')
export default class FeatureFeedbackModal extends PureComponent {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    modalType: PropTypes.string.isRequired,
    featureName: PropTypes.string.isRequired,
    modalTypeShowing: PropTypes.string,
    modalProps: PropTypes.object,
    showCloseIcon: PropTypes.bool,
    showRatingsButtons: PropTypes.bool,
    i18nTitle: PropTypes.string,
    i18nDesc: PropTypes.string,
    onRequestClose: PropTypes.func,
  };

  static defaultProps = {
    showRatingsButtons: true,
    i18nTitle: 'hiring.feedback_modal.title',
    i18nDesc: 'hiring.feedback_modal.desc',
  };

  // Not all versions of this modal display the ratings button. However
  // the rating field has a non-null constraint in the DB. Here we manually
  // set it to 5 in the absence of a user being able to set it.
  state = {
    rating: this.props.showRatingsButtons ? null : 5,
  };

  handleSubmit = () => {
    const { rating, comments } = this.state;

    sendFeatureFeedbackRequest({
      featureName: this.props.featureName,
      rating,
      comments,
    });

    this.setState({
      isLoading: true,
      rating: undefined,
      comments: undefined,
    });

    setTimeout(() => {
      this.setState({
        isLoading: false,
        finished: true,
      });
    }, 1000);

    setTimeout(() => {
      const withReload = this.props.modalProps.withReload;

      this.setState({ finished: false });

      if (this.props.onRequestClose) {
        this.props.onRequestClose();
      }

      this.props.hideModal();

      if (withReload) window.location.reload();
    }, 2000);
  };

  handleCommentsChange = comments => this.setState({ comments });

  handleRatingClick = rating => this.setState({ rating });

  renderRatingsButtons() {
    const images = [
      require('./feedback-rating-1.png'),
      require('./feedback-rating-2.png'),
      require('./feedback-rating-3.png'),
      require('./feedback-rating-4.png'),
      require('./feedback-rating-5.png'),
    ];

    const { rating } = this.state;

    return (
      <Box spacebetween w={211}>
        {images.map((img, i) => (
          <Box
            key={i}
            onClick={partial(this.handleRatingClick, i + 1)}
            cursor="pointer"
            opacity={!rating || rating === i + 1 ? 1 : 0.5}
          >
            <img src={img} alt={`rating-${i + 1}`} />
          </Box>
        ))}
      </Box>
    );
  }

  onRequestClose = () => this.onRequestClose && this.props.onRequestClose();

  render() {
    if (this.props.modalType !== this.props.modalTypeShowing) return null;

    const buttonI18n = this.state.finished
      ? 'simple_form.feedback.sent'
      : 'simple_form.feedback.submit';

    return (
      <Mobile>
        {mobile => (
          <Modal
            noPadding
            forceOpen={!this.props.showCloseIcon}
            isOpen
            auto
            onRequestClose={this.onRequestClose}
          >
            <Box
              className={this.cx({ bootstrap: true }, 'bootstrap')}
              pa32
              borderbox
              column
              hcenter
              w={mobile ? 'auto' : 700}
            >
              <Box tcenter>
                <Text fs24 fw600 i18n={this.props.i18nTitle} />
              </Box>

              <Box pt16 tcenter>
                <Text fs18 i18n={this.props.i18nDesc} />
              </Box>

              {this.props.showRatingsButtons && (
                <Box pt24 hcenter>
                  {this.renderRatingsButtons()}
                </Box>
              )}

              <Box pt24 mh32={!mobile} w={!mobile && 460}>
                <Box>
                  <TextInput
                    type="textarea"
                    size={!mobile && 'tall'}
                    name="comments"
                    value={this.state.comments}
                    onChange={this.handleCommentsChange}
                    placeholder={toI18n('simple_form.feedback.placeholder')}
                    valueOnChange
                  />
                </Box>

                <Box pt24>
                  <Button
                    fullWidth
                    size="large"
                    theme="primary-blue"
                    onClick={this.handleSubmit}
                    loading={this.state.isLoading}
                    disabled={
                      this.props.showRatingsButtons && !this.state.rating
                    }
                  >
                    <Text fs18 fw600 i18n={buttonI18n} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        )}
      </Mobile>
    );
  }
}
