import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgBank = ({
  alt = 'Bank Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M11.41.121a1.499 1.499 0 0 1 1.18 0l10.5 4.5c.657.282 1.023.985.877 1.679-.14.698-.754 1.158-1.509 1.2v.375c0 .623-.46 1.125-1.125 1.125H2.583c-.58 0-1.125-.502-1.125-1.125V7.5C.788 7.458.174 6.998.031 6.3a1.501 1.501 0 0 1 .878-1.679l10.5-4.5ZM12 6a1.5 1.5 0 1 0 .001-3.001 1.5 1.5 0 0 0 0 3.001ZM5.958 19.5h1.875v-9h3v9h2.292v-9h3v9H18v-9h3v9.202c.028.014.056-.01.085.051l2.25 1.5A1.5 1.5 0 0 1 22.5 24H1.458c-.619 0-1.202-.431-1.394-1.064a1.5 1.5 0 0 1 .604-1.683l2.25-1.5c.027-.06.055-.037.04-.051V10.5h3v9Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBank;
