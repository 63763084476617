import './LeftPanelPayrollEoy.scss';

import HomebaseLogo from 'fe-design-base/assets/Logo/logos/HomebaseLogo';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Pill from 'fe-design-base/molecules/Pill';
import { useCountDownTimer } from 'hooks/useCountDownTimer';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

const { cx, cxEl } = cxHelpers('LeftPanelPayrollEoy');

export const LeftPanelPayrollEoy = () => {
  const timeLeft = useCountDownTimer();

  return (
    <Box className={cx()} h="100%" color="mono0">
      <Box ml={64} mt={64} h={17} w={113}>
        <HomebaseLogo />
      </Box>

      <Box className={cxEl('content')} maxw={520}>
        <Pill variant="highlight">
          {toI18n('splash_page.payroll_eoy.time_left', {
            props: {
              count: timeLeft?.days,
            },
          })}
        </Pill>
        <Box mt={8}>
          <Text
            variant="heading1"
            i18n="splash_page.payroll_eoy.header"
            inline
          />
        </Box>
        <Text
          className={cxEl('custom_h1')}
          variant="heading1"
          i18n="splash_page.payroll_eoy.description"
          inline
        />{' '}
        <Text variant="heading1" i18n="splash_page.payroll_eoy.offer" inline />
        <Box
          mv={24}
          gap={24}
          row
          spacebetween
          bradiusm
          justifyContentCenter
          bgcolor="mono0"
        >
          <Box w={248} pv={12} ph={24} gap={4} column alignItemsCenter>
            <Text
              variant="bodyBold"
              color="primary500"
              i18n="splash_page.payroll_eoy.hourly_teams"
            />
            <Box>
              <img src={require('../images/USA-today.png')} />
            </Box>
          </Box>
          <Box w={248} pv={12} ph={24} gap={4} column alignItemsCenter>
            <Text
              variant="bodyBold"
              color="primary500"
              i18n="splash_page.payroll_eoy.small_business"
            />
            <Box>
              <img src={require('../images/CNN.png')} />
            </Box>
          </Box>
        </Box>
        <Text variant="captions" i18n="splash_page.payroll_eoy.availability" />
      </Box>
    </Box>
  );
};

export default LeftPanelPayrollEoy;
