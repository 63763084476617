import './ToggleInput.scss';

import React, { PureComponent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { animations, colors, dimensions, typography } from 'theme/constants';

import Box from 'components/Box';

import cxHelpers from 'util/className';

@cxHelpers('ToggleInput')
export default class ToggleInput extends PureComponent {
  static propTypes = {
    labelPosition: PropTypes.oneOf(['end', 'start', 'top', 'bottom']),
    value: PropTypes.bool,
    uppercase: PropTypes.bool,
    color: PropTypes.string,
    labelLineHeight: PropTypes.string,
    theme: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    labelPosition: 'end',
    value: false,
    uppercase: true,
    theme: 'default',
  };

  handleToggle = event => this.props.onChange(event.target.checked, event);

  labelColor = () => {
    if (!this.props.value) {
      return colors.grayDark;
    }
    if (!this.props.color) {
      return colors.navy;
    }

    return colors[this.props.color];
  };

  render() {
    const {
      theme,
      uppercase,
      labelLineHeight,
      value,
      label,
      labelPosition,
      ...props
    } = this.props;

    const labelStyle = {
      marginLeft: dimensions.xs,
      textTransform: uppercase ? 'uppercase' : 'none',
      color: this.labelColor(),
      transition: animations.duration,
      fontSize: typography.fs16,
      fontWeight: typography.fw600,
    };

    if (labelLineHeight) {
      labelStyle.lineHeight = labelLineHeight;
    }

    return (
      <FormControlLabel
        control={
          <Switch
            classes={{ root: this.cx({ [`theme-${theme}`]: !!theme }) }}
            checked={value}
            onChange={this.handleToggle}
            {...omit(props, 'onChange')}
          />
        }
        label={
          label && (
            <Box tag="span" style={{ ...labelStyle }}>
              {label}
            </Box>
          )
        }
        labelPlacement={labelPosition}
      />
    );
  }
}
