import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchJSON, putJSON } from 'api/fetch';
import * as routes from 'api/index';

import { createOneTimeEvent } from 'actions/session';
import * as sessionActions from 'actions/session';

export const actionTypes = {
  SKIP_FTU_CHECKLIST_STEP: 'FTU/SKIP_FTU_CHECKLIST_STEP',
  CLICKED_FTU_CHECKLIST_STEP: 'FTU/CLICKED_FTU_CHECKLIST_STEP',
  ON_FTU_QUESTIONS_ANSWERED: 'FTU/ON_FTU_QUESTIONS_ANSWERED',
  UPDATE_FTU_ONBOARDING_PROPERTIES: 'FTU/UPDATE_FTU_ONBOARDING_PROPERTIES',
  SHOW_DELAYED_FREE_TRIAL_MODAL: 'FTU/SHOW_DELAYED_FREE_TRIAL_MODAL',
  TOGGLE_SHOW_PAY_ANYWHERE_TIMESHEETS_MODAL:
    'FTU/TOGGLE_SHOW_PAYANYWHERE_TIMESHEETS_MODAL',
  TOGGLE_SHOW_PAY_ANYWHERE_TEAM_MODAL: 'FTU/TOGGLE_SHOW_PAYANYWHERE_TEAM_MODAL',
  TOGGLE_IS_SHOWING_SCHEDULE_TOUR: 'FTU/TOGGLE_IS_SHOWING_SCHEDULE_TOUR',
  TOGGLE_SHOW_QUICKBOOKS_OAUTH2_AUTH_MODAL:
    'FTU/TOGGLE_SHOW_QUICKBOOKS_OAUTH2_AUTH_MODAL',
};

export const togglePayAnywhereTimesheetsModal =
  showPayAnywhereTimesheetsModal => ({
    type: actionTypes.TOGGLE_SHOW_PAY_ANYWHERE_TIMESHEETS_MODAL,
    payload: { showPayAnywhereTimesheetsModal },
  });

export const togglePayAnywhereTeamModal = showPayAnywhereTeamModal => ({
  type: actionTypes.TOGGLE_SHOW_PAY_ANYWHERE_TEAM_MODAL,
  payload: { showPayAnywhereTeamModal },
});

export const toggleQuickbooksAuthModal = showQuickbooksAuthModal => ({
  type: actionTypes.TOGGLE_SHOW_QUICKBOOKS_OAUTH2_AUTH_MODAL,
  payload: { showQuickbooksAuthModal },
});

export const clickQuickbooksAuthUrl = () => dispatch => {
  dispatch(toggleQuickbooksAuthModal(false));
};

export const closeQuickbooksAuthModal = () => dispatch => {
  dispatch(sessionActions.disableQuickbooksAuthModal());
  dispatch(toggleQuickbooksAuthModal(false));
};

export const closePayAnywhereTimesheetsModal = () => dispatch => {
  dispatch(
    sessionActions.disableFirstTimePopup('pay_anywhere_timesheets_modal')
  );
  dispatch(togglePayAnywhereTimesheetsModal(false));
};

export const closePayAnywhereTeamModal = () => dispatch => {
  dispatch(sessionActions.disableFirstTimePopup('pay_anywhere_team_modal'));
  dispatch(togglePayAnywhereTeamModal(false));
};

export const toggleIsShowingScheduleTour = isShowingScheduleTour => ({
  type: actionTypes.TOGGLE_IS_SHOWING_SCHEDULE_TOUR,
  payload: { isShowingScheduleTour },
});

export const skipFTUChecklistStep = stepKey => {
  createOneTimeEvent(`Skip FTU Checklist Step - ${stepKey}`);

  return {
    type: actionTypes.SKIP_FTU_CHECKLIST_STEP,
    payload: { stepKey },
  };
};

export const clickFTUChecklistStep = stepKey => {
  createOneTimeEvent(`Click FTU Checklist Step - ${stepKey}`);

  return {
    type: actionTypes.CLICKED_FTU_CHECKLIST_STEP,
    payload: { stepKey },
  };
};

export const fetchEULAId = createAsyncThunk('/fetchEULAId', () =>
  fetchJSON(routes.userAcceptEULA()).then(({ id }) => id)
);

export const updateEULAAgreement = eulaId => {
  putJSON(routes.acceptUserAcceptEULA(), {
    id: eulaId,
  });
};

export const updateFTUOnboardingProperties = (
  onboardingProperties,
  callback,
  eulaId
) => {
  putJSON(routes.companyOnboardingPropertiesRoute(), {
    company_onboarding_properties: onboardingProperties,
  })
    .then(() => {
      if (eulaId) updateEULAAgreement(eulaId);
    })
    .then(callback);

  return {
    type: actionTypes.UPDATE_FTU_ONBOARDING_PROPERTIES,
    payload: { onboardingProperties },
  };
};

export const onFTUQuestionsAnswered = () => ({
  type: actionTypes.ON_FTU_QUESTIONS_ANSWERED,
});
