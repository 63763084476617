import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { parsedCreatedAt } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import BaseListItem from '../BaseListItem';
import * as constants from '../constants';

@cxHelpers('BaseListItem')
export default class AlertsListItem extends PureComponent {
  static propTypes = {
    item: ImmutablePropTypes.map.isRequired,
    desc: PropTypes.string,
    onEdit: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    icon: PropTypes.string,
    dismissButton: PropTypes.string,
    editButton: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    dismissButton: toI18n('notifications.alerts.buttons.dismiss'),
    editButton: toI18n('notifications.alerts.buttons.edit'),
  };

  createdAtDate = () => parsedCreatedAt(this.props.item);

  type() {
    return this.props.item.get('type');
  }

  title() {
    return this.props.item.get('title');
  }

  isJobApplication() {
    return this.type() === 'job_application';
  }

  renderButtons() {
    return (
      <Box row spacebetween>
        <Box>
          {this.type() !== 'clock_out' && (
            <Button
              className={this.cxEl('dismiss')}
              onClick={this.props.onDismiss}
              theme="link"
            >
              <Text fs16>{this.props.dismissButton}</Text>
            </Button>
          )}
        </Box>
        <Button
          className={this.cxEl('edit')}
          onClick={this.props.onEdit}
          theme="primary"
          size="small"
        >
          <Text fs16 ptxxs pbxxs plxs prxs>
            {this.props.editButton}
          </Text>
        </Button>
      </Box>
    );
  }

  render() {
    const leftBasis = this.props.icon ? constants.LEFT_COLUMN_WIDTH : 0;

    return (
      <BaseListItem
        icon={this.props.icon}
        titleI18n={`notifications.alerts.${this.type()}.title`}
        date={this.createdAtDate()}
        title={(this.isJobApplication() && this.title()) || undefined}
      >
        <Box vtop>
          <Box basis={leftBasis} shrink={0} />

          <Box grow>
            <Text fs14>{this.props.desc}</Text>
          </Box>
        </Box>
        <Box mts>{this.renderButtons()}</Box>
      </BaseListItem>
    );
  }
}
