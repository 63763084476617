import { Map, Set } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'actions/ptoPolicy';

import * as constants from 'features/ptoPolicy/constants';

const INITIAL_STATES = {
  isFetching: Set(),
  newPolicy: Map({
    stepIndex: 0,
    id: null,
  }),
  policy: Map({
    editingDetails: false,
    editingEmployees: false,
    editEmployeesStepIndex: 0,
  }),
  detailsForm: Map(constants.DETAILS_FORM_DEFAULTS),
  employeesFormIds: Set(),
  balancesForm: Map(),
};

const isFetching = (state = INITIAL_STATES.isFetching, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PTO_POLICY_REQUEST:
    case actionTypes.UPDATE_PTO_POLICY_REQUEST:
    case actionTypes.FETCH_COMPANY_USERS_REQUEST:
    case actionTypes.FETCH_PTO_POLICY_REQUEST:
    case actionTypes.DELETE_PTO_POLICY_REQUEST:
      return state.add(action.meta.requestId);

    case actionTypes.CREATE_PTO_POLICY_SUCCESS:
    case actionTypes.CREATE_PTO_POLICY_FAILURE:
    case actionTypes.UPDATE_PTO_POLICY_SUCCESS:
    case actionTypes.UPDATE_PTO_POLICY_FAILURE:
    case actionTypes.FETCH_COMPANY_USERS_SUCCESS:
    case actionTypes.FETCH_COMPANY_USERS_FAILURE:
    case actionTypes.FETCH_PTO_POLICY_SUCCESS:
    case actionTypes.FETCH_PTO_POLICY_FAILURE:
    case actionTypes.DELETE_PTO_POLICY_SUCCESS:
    case actionTypes.DELETE_PTO_POLICY_FAILURE:
      return state.remove(action.meta.requestId);

    default:
      return state;
  }
};

const newPolicy = (state = INITIAL_STATES.newPolicy, action) => {
  switch (action.type) {
    case actionTypes.CREATE_PTO_POLICY_SUCCESS:
      return state.merge({ stepIndex: 1, id: action.payload.id });

    case actionTypes.UPDATE_PTO_POLICY_SUCCESS: {
      const {
        meta,
        payload: { users },
      } = action;

      if (meta.new) {
        if (meta.form === 'details') {
          return state.merge({ stepIndex: 1 });
        }
        if (meta.form === 'employees' && users && users.length) {
          // Only continue to next step if users have been added
          return state.merge({ stepIndex: 2 });
        }
      }

      return state;
    }

    default:
      return state;
  }
};

const policy = (state = INITIAL_STATES.policy, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_EDITING_DETAILS:
      return state.merge({ editingDetails: action.payload.editing });

    case actionTypes.TOGGLE_EDITING_EMPLOYEES:
      return state.merge({ editingEmployees: action.payload.editing });

    case actionTypes.UPDATE_PTO_POLICY_SUCCESS: {
      if (!action.meta.new) {
        if (action.meta.form === 'details') {
          return state.merge({ editingDetails: false });
        }
      }

      return state;
    }

    case actionTypes.SET_EDIT_EMPLOYEES_STEP:
      return state.merge({ editEmployeesStepIndex: action.payload.stepIndex });

    case actionTypes.INIT_POLICY:
      return INITIAL_STATES.policy;

    default:
      return state;
  }
};

const detailsForm = (state = INITIAL_STATES.detailsForm, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DETAILS_FORM_FIELD:
      return state.set(action.payload.name, action.payload.value);

    case actionTypes.INIT_POLICY:
      return INITIAL_STATES.detailsForm.map((_, key) =>
        action.payload.has(key)
          ? action.payload.get(key)
          : INITIAL_STATES.detailsForm.get(key)
      );

    default:
      return state;
  }
};

const employeesFormIds = (state = INITIAL_STATES.employeesFormIds, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_EMPLOYEES_FORM_IDS:
      return Set(action.payload.ids);

    case actionTypes.INIT_POLICY:
      return action.payload
        .get('users')
        .map(user => user.get('user_id').toString())
        .toSet();

    default:
      return state;
  }
};

const balancesForm = (state = INITIAL_STATES.balancesForm, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_BALANCES_FORM_BALANCE:
      return state.update(action.payload.id, balance =>
        balance.set('accrued_hours', action.payload.value)
      );

    case actionTypes.INITIALIZE_POLICY_BALANCES_FORM: {
      const { users } = action.payload;

      if (!users || !users.size) {
        return state;
      }

      return users.reduce(
        (balances, policyEmployee) =>
          balances.set(
            policyEmployee.get('id'),
            policyEmployee.merge({
              accrued_hours: policyEmployee.get('accrued_hours') || '',
            })
          ),
        Map()
      );
    }

    case actionTypes.UPDATE_PTO_POLICY_SUCCESS: {
      if (action.meta.form === 'employees') {
        const { users } = action.payload;
        if (!users || !users.length) {
          return state;
        }

        return users.reduce(
          (balances, policyEmployee) =>
            balances.set(
              policyEmployee.id,
              Map({
                ...policyEmployee,
                accrued_hours: policyEmployee.accrued_hours || '',
              })
            ),
          Map()
        );
      }

      return state;
    }

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  newPolicy,
  policy,
  detailsForm,
  employeesFormIds,
  balancesForm,
});
