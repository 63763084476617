import React from 'react';
import Icon from 'react-icon-base';

const Headphones = props => (
  <Icon viewBox="0 0 32 33" {...props}>
    <path
      fillRule="evenodd"
      d="M27.64 11.375v1.28c2.437.154 4.367 2.146 4.36 4.565v2c0 2.433-1.944 4.426-4.396 4.572-.243 3.65-2.08 5.46-3.63 6.349-1.102.629-2.21.874-2.91.972a3.006 3.006 0 0 1-2.902 2.167h-2.151c-1.659 0-3.01-1.328-3.01-2.95s1.351-2.944 3.01-2.944h2.144c1.337 0 2.473.853 2.866 2.035 1.43-.231 4.51-1.245 4.86-5.622h-1.2a.851.851 0 0 1-.858-.839v-9.48c0-.462.386-.84.858-.84h1.23v-1.265c0-5.53-4.025-9.697-9.371-9.697h-1.08c-5.339 0-9.37 4.167-9.37 9.697v1.266h1.23c.47 0 .857.377.857.839v9.487a.851.851 0 0 1-.858.84H4.682C2.102 23.806 0 21.75 0 19.226v-2c0-2.426 1.937-4.411 4.374-4.572v-1.28c0-3.062 1.108-5.908 3.117-8.019C9.549 1.189 12.38 0 15.467 0h1.08c3.088 0 5.918 1.189 7.977 3.356 2.008 2.111 3.116 4.957 3.116 8.02zM4.48 21.761H6.4v-7.68H4.48c-1.766 0-3.2 1.28-3.2 2.855v1.969c0 1.575 1.434 2.855 3.2 2.855zM17.8 32h-2.325c-.772 0-1.397-.573-1.397-1.28 0-.708.625-1.28 1.397-1.28H17.8c.772 0 1.398.572 1.398 1.28 0 .707-.626 1.28-1.398 1.28zm9.721-10.24c1.766 0 3.2-1.28 3.2-2.856v-1.969c0-1.575-1.434-2.855-3.2-2.855h-1.92v7.68h1.92z"
      clipRule="evenodd"
    />
  </Icon>
);

export default Headphones;
