import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgChevronUp = ({
  alt = 'Chevron Up Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M23.73 17.639a1.094 1.094 0 0 1-1.542.081L12.005 8.557 1.821 17.721c-.45.4-1.138.365-1.543-.081a1.094 1.094 0 0 1 .083-1.542L11.213 6.28a1.096 1.096 0 0 1 1.463 0l10.852 9.818c.571.402.605 1.091.203 1.541Z"
    />
  </svg>
);
export default SvgChevronUp;
