import React from 'react';
import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { MuiThemeProvider as MuiNextThemeProvider } from '@material-ui/core/styles';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import { cloverThemeVars } from 'fe-design-base/styles/clover/vars';
import designBaseTheme from 'fe-design-base/styles/theme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from 'theme/mui';
import muiNextTheme from 'theme/muiNext';

import LeftPanelPayrollEoy from 'features/authentication/SignIn/LeftPanel/LeftPanelPayrollEoy';
import LeftPanelReferralBanner from 'features/authentication/SignIn/LeftPanel/LeftPanelReferralBanner';
import LeftNavigation from 'features/LeftNavigationView';

import Loader from 'components/Loader';

import getIsEmbedded from 'util/embedded';

import { createApolloClient } from './apolloClient';
import App from './App';
import CommandBar from './CommandBar';
import { MUI_PRO_X_KEY } from './constants';
import Nav from './Nav';

const RootModal = React.lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "MainComp-RootModal" */ '../features/modals/RootModal'
  )
);
const HiringWidgetView = React.lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "MainComp-HiringWidgetView" */ '../features/hiring/widget/HiringWidgetView'
  )
);
const DashboardTeamAnnouncementsView = React.lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "MainComp-DashboardTeamAnnouncementsView" */ '../features/dashboard/DashboardTeamAnnouncementsView'
  )
);
const PurpleStarWrapperBackbone = React.lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "MainComp-PurpleStarWrapperBackbone" */ './PurpleStarWrapperBackbone'
  )
);
const ProductUsageView = React.lazy(() =>
  import(
    /* webpackPreload: true, webpackChunkName: "MainComp-ProductUsageView" */ '../features/dashboard/widgets/ProductUsageView'
  )
);

const EnforcementTooltipWrapperBackbone = React.lazy(() =>
  import('./EnforcementTooltipWrapperBackbone')
);

const ComponentCallbacks = React.lazy(() => import('./ComponentCallbacks'));

export default class RootMounter {
  constructor(store, stytchPublicToken) {
    LicenseInfo.setLicenseKey(MUI_PRO_X_KEY);

    this.store = store;
    this.stytch = new StytchUIClient(stytchPublicToken);
    this.apolloClient = createApolloClient();
  }

  mountAll = () => {
    this.renderRootIfPresent(App, 'react-app-root');
    if (module.hot) {
      module.hot.accept('./App', () =>
        this.renderRootIfPresent(App, 'react-app-root')
      );
    }
    this.renderRootIfPresent(Nav, 'react-nav-root');
    this.renderRootIfPresent(LeftNavigation, 'react-left-nav-root');
    this.renderRootIfPresent(RootModal, 'react-modal-root');
    this.renderRootIfPresent(
      ComponentCallbacks,
      'react-component-callback-root'
    );
    this.renderRootIfPresent(CommandBar, 'react-command-bar-root');
    this.renderRootIfPresent(
      LeftPanelReferralBanner,
      'left-panael-referral-banner'
    );
    this.renderRootIfPresent(LeftPanelPayrollEoy, 'left-panel-payroll-eoy');
  };

  renderRootIfPresent = (Component, rootId, props = {}) => {
    if (document.getElementById(rootId)) {
      this.renderRoot(Component, rootId, props);
    }
  };

  mountApp = () => {
    this.renderRootIfPresent(App, 'react-app-root');
  };

  mountHiringWidget = () => {
    this.renderRootIfPresent(HiringWidgetView, 'react-hiring-widget-root');
  };

  mountProfileDashboardTeamAnnouncementsView = () => {
    const DOM_ID = 'js-profile-react-dashboard-team-announcements-view-root';

    if (document.getElementById(DOM_ID)) {
      this.renderRoot(DashboardTeamAnnouncementsView, DOM_ID);

      if (module.hot) {
        module.hot.accept(
          'features/dashboard/DashboardTeamAnnouncementsView',
          () => this.renderRoot(DashboardTeamAnnouncementsView, DOM_ID)
        );
      }
    }
  };

  mountPurpleStarWrapperBackbone = (elementId, props) => {
    this.renderRootIfPresent(PurpleStarWrapperBackbone, elementId, props);
  };

  mountEnforcementTooltipWrapper = (elementId, props) => {
    this.renderRootIfPresent(
      EnforcementTooltipWrapperBackbone,
      elementId,
      props
    );
  };

  mountProductUsageWidgetOnDashboard = () => {
    this.renderRootIfPresent(
      ProductUsageView,
      'react-dashboard-product-widget-root'
    );
  };

  renderRoot = (Component, rootId, props) => {
    const container = document.getElementById(rootId);
    const root = createRoot(container);
    const isEmbedded = getIsEmbedded();

    if (isEmbedded) {
      const cssRoot = document.querySelector(':root');

      for (const [cssVarKey, cssVarvalue] of Object.entries(cloverThemeVars)) {
        cssRoot?.style.setProperty(cssVarKey, cssVarvalue);
      }

      const bodyContainer = document.querySelector('.body-container');
      if (bodyContainer) {
        // Prevents the possible flash of white background when the app is embedded
        bodyContainer.style.backgroundColor = cloverThemeVars['--mono0'];
      }
    }

    root.render(
      <React.Suspense fallback={<Loader isLoading centered />}>
        <StytchProvider stytch={this.stytch}>
          <ApolloProvider client={this.apolloClient}>
            <MuiNextThemeProvider theme={muiNextTheme}>
              <MuiThemeProvider muiTheme={muiTheme}>
                <ThemeProvider theme={designBaseTheme}>
                  <StyledEngineProvider>
                    <Component store={this.store} {...props} />
                  </StyledEngineProvider>
                </ThemeProvider>
              </MuiThemeProvider>
            </MuiNextThemeProvider>
          </ApolloProvider>
        </StytchProvider>
      </React.Suspense>
    );
  };
}
