import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  employeesResendInviteRoute,
  teamCheckEmployeeEmailAndPhoneRoute,
  teamCreateNewTeamMemberRoute,
  teamCreatePayrollCheckEntityRoute,
  teamRehireTeamMemberRoute,
  teamUpdateTeamMemberRoute,
} from 'api';
import { postJSON, putJSON } from 'api/fetch';

import { createAsyncGetAction } from 'util/redux';
import { checkForServerErrors } from 'util/serverError';

import { ACTION_TYPES, SLICE_NAME } from './constants';

const resendEmployeeInviteAction = userId =>
  createAsyncGetAction(employeesResendInviteRoute(userId), [
    { type: ACTION_TYPES.RESEND_EMPLOYEE_INVITE_REQUEST, meta: { userId } },
    { type: ACTION_TYPES.RESEND_EMPLOYEE_INVITE_SUCCESS, meta: { userId } },
    { type: ACTION_TYPES.RESEND_EMPLOYEE_INVITE_FAILURE, meta: { userId } },
  ]);

export const resendEmployeeInvite = userId => dispatch =>
  checkForServerErrors(
    resendEmployeeInviteAction(userId),
    "Couldn't resend invite."
  )(dispatch);

export const checkEmailAndPhoneStatus = body =>
  postJSON(teamCheckEmployeeEmailAndPhoneRoute(), body);

export const updateTeamMemberInfo = payload => ({
  type: ACTION_TYPES.CREATE_EMPLOYEE_SUCCESS,
  payload,
});

export const createPayrollCheckEntity = createAsyncThunk(
  `${SLICE_NAME}/${ACTION_TYPES.CREATE_PAYROLL_CHECK_ENTITY}`,
  ({ userId, entityType }, { rejectWithValue }) =>
    postJSON(teamCreatePayrollCheckEntityRoute(userId), {
      entity_type: entityType,
    })
      .then(response => response)
      .catch(err => err.response.json().then(body => rejectWithValue(body)))
);

export const addTeamMember = createAsyncThunk(
  `${SLICE_NAME}/${ACTION_TYPES.ADD_TEAM_MEMBER}`,
  async (payload, { dispatch }) => {
    const response = await postJSON(teamCreateNewTeamMemberRoute(), payload);
    dispatch(updateTeamMemberInfo(response));

    return Promise.resolve(response);
  }
);

export const updateTeamMember = createAsyncThunk(
  `${SLICE_NAME}/${ACTION_TYPES.UPDATE_TEAM_MEMBER}`,
  async ({ id, payload }, { dispatch }) => {
    const response = await putJSON(teamUpdateTeamMemberRoute(id), payload);

    dispatch(updateTeamMemberInfo(response));

    return Promise.resolve(response);
  }
);

export const rehireTeamMember = createAsyncThunk(
  `${SLICE_NAME}/${ACTION_TYPES.REHIRE_TEAM_MEMBER}`,
  async ({ id, payload }, { dispatch }) => {
    const response = await putJSON(teamRehireTeamMemberRoute(id), payload);

    dispatch(updateTeamMemberInfo(response));

    return Promise.resolve(response);
  }
);
