import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';

import { actionTypes } from 'actions/roles';

import { NEW_ROLE_ID, SKIP_ROLE_ID } from 'features/importRoles/constants';

const IMPORT_ROLES_INITIAL_STATE = Map({
  roles: List(),
  roleOptions: List(),
  partnerName: null,
  isImporting: false,
  isFetching: false,
});

const importRoles = (state = IMPORT_ROLES_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES_FOR_IMPORT_REQUEST:
      return state.merge({ isLoading: true });

    case actionTypes.FETCH_ROLES_FOR_IMPORT_SUCCESS: {
      const { roles, role_options, partner_name } = action.payload;

      return state.merge({
        isLoading: false,
        roles: fromJS(roles),
        roleOptions: fromJS(role_options),
        partnerName: partner_name,
      });
    }

    case actionTypes.FETCH_ROLES_FOR_IMPORT_FAILURE:
      return state.merge({ isLoading: false });

    case actionTypes.INITIATE_IMPORT_ROLES_REQUEST:
      return state.merge({ isImporting: true });

    case actionTypes.IMPORT_ROLES_FINISHED:
      return state.merge({ roles: List(), isImporting: false });

    case actionTypes.INITIATE_IMPORT_ROLES_FAILURE:
      return state.merge({ isImporting: false });

    case actionTypes.UPDATE_MATCHED_ROLE: {
      const { id, role } = action.payload;
      let roles = state.get('roles');

      // Ensure that only a single role can be matched to the same role id
      if (id !== NEW_ROLE_ID && id !== SKIP_ROLE_ID) {
        const existingRoleIndex = roles.findIndex(e => e.get('role_id') === id);

        if (existingRoleIndex > -1) {
          roles = roles.setIn([existingRoleIndex, 'role_id'], 'new');
        }
      }

      roles = roles.setIn([roles.indexOf(role), 'role_id'], id);

      return state.merge({ roles });
    }

    default:
      return state;
  }
};

export default combineReducers({
  importRoles,
});
