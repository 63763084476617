import './ActionsListDrawer.scss';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { partial } from 'lodash';
import PropTypes from 'prop-types';

import { toggleActionsListDrawer } from 'actions/drawers';

import { buildGetDrawerOpen, buildGetDrawerProps } from 'selectors/drawers';

import { ACTIONS_LIST_DRAWER_KEY } from 'features/drawers/keys';
import { selectShouldShowEnforcementDialog } from 'features/enforcement/EnforcementBanner/selectors';
import EnforcementTooltip from 'features/enforcement/EnforcementTooltip';

import Box from 'components/Box';
import IconButton from 'components/clickables/IconButton';
import Drawer from 'components/Drawer';
import withDispatchOnNavigation from 'components/hocs/withDispatchOnNavigation';
import { MobileAndTablet } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';
import { trackUxEvent } from 'util/tracking';

@withDispatchOnNavigation(toggleActionsListDrawer(false))
@connect(
  (state, props) => ({
    isOpen: buildGetDrawerOpen(ACTIONS_LIST_DRAWER_KEY)(state, props),
    drawerProps: buildGetDrawerProps(state, props),
    showEnforcementDialog: selectShouldShowEnforcementDialog(state),
  }),
  {
    toggleActionsListDrawer,
  }
)
@cxHelpers('ActionsListDrawer')
export default class ActionsListDrawer extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleActionsListDrawer: PropTypes.func.isRequired,
    drawerProps: PropTypes.object.isRequired,
    showEnforcementDialog: PropTypes.bool.isRequired,
  };

  trackEvent = eventAction => {
    const { productArea, eventCategory } = this.props.drawerProps;
    if (productArea && eventCategory && eventAction) {
      trackUxEvent({ productArea, eventCategory, eventAction });
    }
  };

  componentDidUpdate() {
    const { shownEvent } = this.props.drawerProps;
    this.trackEvent(shownEvent);
  }

  handleRequestClose = () => this.props.toggleActionsListDrawer(false);

  handleActionClick = (onClick, onClickClose, eventAction) => {
    if (onClickClose) {
      this.props.toggleActionsListDrawer(false);
    }
    this.trackEvent(eventAction);
    onClick();
  };

  renderAction = (action, i) => {
    const {
      header,
      subheader,
      onClick,
      onClickClose,
      eventAction,
      actionName,
    } = action;
    const showTooltip =
      this.props.showEnforcementDialog &&
      header === 'employees_roster.bulk_actions.actions.add.header';

    return (
      <Box
        key={i}
        grow
        onClick={partial(
          this.handleActionClick,
          onClick,
          onClickClose,
          eventAction
        )}
        mb={24}
        pointer
        className={`${this.cxEl('option')} ${
          actionName && this.cxEl(actionName)
        }`}
      >
        <Box vcenter>
          <Text purple fs16 fw600 i18n={header} />
          {showTooltip && (
            <EnforcementTooltip
              content={toI18n('enforcement.employee_cap.tooltip')}
            />
          )}
        </Box>
        <Box mb={24}>
          <Text fs14 fw300 i18n={subheader} />
        </Box>
      </Box>
    );
  };

  renderActionsList = actionsList => (
    <Box grow className={this.cxEl('options')}>
      {actionsList.map(this.renderAction)}
    </Box>
  );

  render() {
    const { i18nTitle, i18nSubtitle, actionsList } = this.props.drawerProps;

    if (!i18nTitle || !actionsList) {
      return null;
    }

    return (
      <MobileAndTablet>
        {(mobile, tablet) => (
          <Drawer
            width={mobile || tablet ? '100%' : '546px'}
            open={this.props.isOpen}
          >
            <Box className={this.cx()} h="100%">
              <Box absolute top={5} right={5} z={2}>
                <IconButton
                  iconColor="grayMediumDark"
                  size="md"
                  type="mdClose"
                  onClick={this.handleRequestClose}
                />
              </Box>

              <Box ml={28} mr={32} mt={40}>
                <Box mb={24}>
                  <Text fs24 fw600 i18n={i18nTitle} />
                </Box>

                {i18nSubtitle && (
                  <Box mb={30}>
                    <Text i18n={i18nSubtitle} />
                  </Box>
                )}

                {this.renderActionsList(actionsList)}
              </Box>
            </Box>
          </Drawer>
        )}
      </MobileAndTablet>
    );
  }
}
