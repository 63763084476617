import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgMonoGoogle = ({
  alt = 'Mono Google Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.434 9.941a7.245 7.245 0 0 0-5.114-1.999c-3.479 0-6.433 2.347-7.486 5.507a7.987 7.987 0 0 0 0 5.105h.005c1.058 3.155 4.007 5.502 7.486 5.502 1.795 0 3.337-.46 4.531-1.27v-.004a6.172 6.172 0 0 0 2.665-4.052H16.32v-5.134h12.576c.156.892.23 1.803.23 2.71 0 4.054-1.45 7.483-3.971 9.805l.003.002c-2.21 2.038-5.242 3.219-8.838 3.219a13.335 13.335 0 0 1-11.915-7.344 13.344 13.344 0 0 1 0-11.973A13.33 13.33 0 0 1 16.32 2.666a12.814 12.814 0 0 1 8.92 3.469L21.435 9.94Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMonoGoogle;
