import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAccountIsHub, getCurrentLocationId } from 'selectors/session';

import { getExperimentsEligibleForApi } from 'features/experiments/selectors';

import { amplitudeIdentify } from 'util/trackingModules/amplitude';

import { putEnrollEntityInExperiment } from './api';
import { SLICE_NAME } from './constants';

export const actionTypes = {
  EXPERIMENT_RELOAD: 'EXPERIMENT/EXPERIMENT_RELOAD',
};

export const enrollEntityInExperiment = createAsyncThunk(
  `${SLICE_NAME}/entityEnroll`,
  (experiments, { rejectWithValue, getState }) => {
    const eligibleExperiments = getExperimentsEligibleForApi(
      getState(),
      experiments
    );
    const currentLocationId = getCurrentLocationId(getState());
    const isHub = getAccountIsHub(getState());
    if (isHub || !currentLocationId || eligibleExperiments.length === 0) {
      return experiments.reduce((obj, v) => ({ ...obj, [v]: 0 }), {});
    }

    return putEnrollEntityInExperiment(experiments)
      .then(({ amplitudeGroupIdentifyData, viewLogic }) => {
        if (amplitudeGroupIdentifyData) {
          amplitudeIdentify({ ...amplitudeGroupIdentifyData });
        }
        return viewLogic;
      })
      .catch(error => rejectWithValue(error.body || error.message));
  }
);

export const updateReloadExperiment = reloadExperiment => ({
  type: actionTypes.EXPERIMENT_RELOAD,
  payload: reloadExperiment,
});
