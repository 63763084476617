import download from 'downloadjs';
import windowLocation from 'fe-core/util/windowLocation';

import { datadogLogError, datadogLogInfo } from 'util/datadogLogs';
import { NAVIGATION } from 'util/navigation';
import { getCSRFToken } from 'util/redux';

const DEFAULT_OPTIONS = {
  credentials: 'include',
};

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (
    response.status === 403 &&
    response.headers.get('content-type').match(/text/) &&
    response.headers.get('server').match(/awselb/)
  ) {
    datadogLogInfo({
      messages: '403: /analyze_file',
      context: {
        body: response.body,
        headers: response.headers,
      },
    });

    return response;
  }
  //  Without excluding the admin path, reactive admin biller_spec fails
  if (response.status === 401 && !window.location.pathname.includes('/admin')) {
    windowLocation().href = `${
      NAVIGATION.ACCOUNT_SIGN_IN
    }?destination=${encodeURIComponent(windowLocation().href)}`;
    return response;
  }

  // Access response json in .catch block like:
  //  error.response.json().then(data => { ... }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const parseJSON = response => response.json();
const parseText = response => response.text();
const downloadFile = (response, filename, mimeType) =>
  response.blob().then(blob => {
    download(blob, filename, mimeType);
    return response;
  });

const defaultHeaders = (method, jsonBody) => {
  if (method === 'GET') return {};
  const headers = {
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': getCSRFToken(),
  };

  if (window.amplitude && window.amplitude.getInstance().getSessionId) {
    headers['amplitude-session-id'] = window.amplitude
      .getInstance()
      .getSessionId();
    headers['amplitude-device-id'] =
      window.amplitude.getInstance().options.deviceId;
  }

  if (jsonBody) {
    // we only add this header when it is JSON so that FormData
    // can be processed properly
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

const buildFetchOptions = (method, options, jsonBody = true) => {
  // this allows headers to be passed in as options without
  // overwriting all the default headers.
  const { headers, ...restOptions } = options;

  const fetchOptions = {
    ...DEFAULT_OPTIONS,
    method,
    headers: { ...defaultHeaders(method, jsonBody), ...(headers || {}) },
    ...restOptions,
  };
  return fetchOptions;
};

export const fetchJSON = (url, options = {}) =>
  fetch(url, buildFetchOptions('GET', options))
    .then(checkStatus)
    .then(parseJSON);

export const fetchText = (url, options = {}) =>
  fetch(url, buildFetchOptions('GET', options))
    .then(checkStatus)
    .then(parseText);

export const fetchFile = (url, options = {}, filename, mimeType) =>
  fetch(url, buildFetchOptions('GET', options))
    .then(checkStatus)
    .then(response => downloadFile(response, filename, mimeType));

export const postJSON = (url, data, options = {}) =>
  fetch(
    url,
    buildFetchOptions('POST', {
      body: JSON.stringify(data),
      ...options,
    })
  )
    .then(checkStatus)
    .then(parseJSON);

export const patchJSON = (url, data, options = {}) =>
  fetch(
    url,
    buildFetchOptions('PATCH', {
      body: JSON.stringify(data),
      ...options,
    })
  )
    .then(checkStatus)
    .then(parseJSON);

export const postFormData = (url, data, options = {}) => {
  const bodyFormData = new FormData();
  Object.keys(data).forEach(key => bodyFormData.append(key, data[key]));

  return fetch(
    url,
    buildFetchOptions(
      'POST',
      {
        body: bodyFormData,
        ...options,
      },
      false
    )
  )
    .then(checkStatus)
    .then(parseJSON)
    .catch(err => {
      datadogLogError({
        message: 'postFormData error',
        context: { url, data, options, err },
      });
    });
};

export const putFormData = (url, data, options = {}) => {
  const bodyFormData = new FormData();
  Object.keys(data).forEach(key => bodyFormData.append(key, data[key]));

  return fetch(
    url,
    buildFetchOptions(
      'PUT',
      {
        body: bodyFormData,
        ...options,
      },
      false
    )
  )
    .then(checkStatus)
    .then(parseJSON);
};

export const postFiles = (url, data, files, options = {}) => {
  const bodyFormData = new FormData();
  Object.keys(data).forEach(key => {
    bodyFormData.append(key, data[key]);
  });

  files.forEach(file => bodyFormData.append('files[]', file));

  return fetch(
    url,
    buildFetchOptions(
      'POST',
      {
        body: bodyFormData,
        ...options,
      },
      false
    )
  )
    .then(checkStatus)
    .then(parseJSON);
};

export const putJSON = (url, data, options = {}) =>
  fetch(
    url,
    buildFetchOptions('PUT', {
      body: JSON.stringify(data),
      ...options,
    })
  )
    .then(checkStatus)
    .then(parseJSON);

export const deleteJSON = (url, data, options = {}) =>
  fetch(
    url,
    buildFetchOptions('DELETE', {
      body: JSON.stringify(data),
      ...options,
    })
  )
    .then(checkStatus)
    .then(parseJSON);

export const deleteJSONWithoutParsing = (url, data, options = {}) =>
  fetch(
    url,
    buildFetchOptions('DELETE', {
      body: JSON.stringify(data),
      ...options,
    })
  )
    .then(checkStatus)
    .then(res => res);
