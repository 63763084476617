import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import * as thunks from './thunks';

const slice = createSlice({
  name: 'tipSettings',
  initialState: fromJS({
    isLoading: true,
    hasChanges: false,
    showSuccessAlert: false,
    initialFormValues: {},
  }),
  reducers: {
    setHasChanges: state => state.set('hasChanges', true),
    setHasNoChanges: state => state.set('hasChanges', false),
    dismissSuccessAlert: state => state.set('showSuccessAlert', false),
    setInitialFormValues: (state, { payload }) =>
      state.set('initialFormValues', payload),
  },
  extraReducers: {
    [thunks.updateLocationSettings.pending]: state =>
      state.merge({ isSubmitting: true }),
    [thunks.updateLocationSettings.rejected]: state =>
      state.merge({ isSubmitting: false }),
    [thunks.updateLocationSettings.fulfilled]: (state, payload) =>
      state
        .merge({ isSubmitting: false })
        .set('hasChanges', false)
        .set('initialFormValues', {
          payroll_import_tips_enabled: fromJS(
            payload.payload.payroll_import_tips_enabled
          ),
          tip_shortage_enabled: fromJS(payload.payload.tip_shortage_enabled),
          state_min_wage: (
            fromJS(payload.payload.state_min_wage) || ''
          ).toString(),
        })
        .set('showSuccessAlert', true),
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
