import { createAsyncThunk } from '@reduxjs/toolkit';
import * as routes from 'api';
import { fetchJSON, postJSON } from 'api/fetch';
import { pollBatchStatus } from 'api/workerStatus';

import { hideModal, showModal } from 'actions/modals';
import { fetchTimesheetData, updatePayrollPeriods } from 'actions/timesheets';

import * as sessionSelectors from 'selectors/session';
import * as settingsSelectors from 'selectors/settings';

import {
  ADP_CREDIT_TIPS_SETTINGS_MODAL_TYPE,
  APPLY_CHANGES_MODAL_TYPE,
  COMPLETED_DOCUMENTS_SETTINGS_MODAL,
  DISCONNECT_WARNING_MODAL,
  LABOR_SETTINGS_HISTORY_MODAL,
  PAYROLL_ADMIN_CONFIRMATION_MODAL,
  SETTINGS_CONFIGS,
  TOAST_CONNECT_MODAL,
} from 'features/settings/constants';
import * as settingsUtil from 'features/settings/util';

import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import * as reduxUtil from 'util/redux';
import { browserHistory } from 'util/router';

import { updateCurrentLocation } from './session';

export const actionTypes = {
  ARCHIVE_JOB_REQUEST: 'EMPLOYEE/ARCHIVE_JOB_REQUEST',
  ARCHIVE_JOB_SUCCESS: 'EMPLOYEE/ARCHIVE_JOB_SUCCESS',
  ARCHIVE_JOB_FAILURE: 'EMPLOYEE/ARCHIVE_JOB_FAILURE',
  UPDATE_POS_PARTNER: 'SETTINGS/UPDATE_POS_PARTNER',
  UPDATE_PAYROLL_PARTNER: 'SETTINGS/UPDATE_PAYROLL_PARTNER',
  CREATE_API_KEY_REQUEST: 'SETTINGS/CREATE_API_KEY_REQUEST',
  CREATE_API_KEY_SUCCESS: 'SETTINGS/CREATE_API_KEY_SUCCESS',
  CREATE_API_KEY_FAILURE: 'SETTINGS/CREATE_API_KEY_FAILURE',
  UPDATE_CURRENT_USER_REQUEST: 'SETTINGS/UPDATE_CURRENT_USER_REQUEST',
  UPDATE_CURRENT_USER_SUCCESS: 'SETTINGS/UPDATE_CURRENT_USER_SUCCESS',
  UPDATE_CURRENT_USER_FAILURE: 'SETTINGS/UPDATE_CURRENT_USER_FAILURE',
  FETCH_LOCATIONS_DATA_REQUEST: 'SETTINGS/FETCH_LOCATIONS_DATA_REQUEST',
  FETCH_LOCATIONS_DATA_SUCCESS: 'SETTINGS/FETCH_LOCATIONS_DATA_SUCCESS',
  FETCH_LOCATIONS_DATA_FAILURE: 'SETTINGS/FETCH_LOCATIONS_DATA_FAILURE',
  IMPORT_PARTNER_LOCATIONS_REQUEST: 'SETTINGS/IMPORT_PARTNER_LOCATIONS_REQUEST',
  IMPORT_PARTNER_LOCATIONS_SUCCESS: 'SETTINGS/IMPORT_PARTNER_LOCATIONS_SUCCESS',
  IMPORT_PARTNER_LOCATIONS_FAILURE: 'SETTINGS/IMPORT_PARTNER_LOCATIONS_FAILURE',
  UPDATE_APPLY_TO_ALL_LOCATIONS: 'UPDATE_APPLY_TO_ALL_LOCATIONS',
  UPDATE_LOCATION_SETTINGS_REQUEST: 'SETTINGS/UPDATE_LOCATION_SETTINGS_REQUEST',
  UPDATE_LOCATION_SETTINGS_SUCCESS: 'SETTINGS/UPDATE_LOCATION_SETTINGS_SUCCESS',
  UPDATE_LOCATION_SETTINGS_FAILURE: 'SETTINGS/UPDATE_LOCATION_SETTINGS_FAILURE',
  SAVE_LOCATION_SETTINGS_REQUEST: 'SETTINGS/SAVE_LOCATION_SETTINGS_REQUEST',
  SAVE_LOCATION_SETTINGS_SUCCESS: 'SETTINGS/SAVE_LOCATION_SETTINGS_SUCCESS',
  SAVE_LOCATION_SETTINGS_FAILURE: 'SETTINGS/SAVE_LOCATION_SETTINGS_FAILURE',
  FETCH_USER_SETTINGS_DATA_REQUEST: 'SETTINGS/FETCH_USER_SETTINGS_DATA_REQUEST',
  FETCH_USER_SETTINGS_DATA_SUCCESS: 'SETTINGS/FETCH_USER_SETTINGS_DATA_SUCCESS',
  FETCH_USER_SETTINGS_DATA_FAILURE: 'SETTINGS/FETCH_USER_SETTINGS_DATA_FAILURE',
  FETCH_SETTINGS_PAYROLL_ADMINS_DATA_REQUEST:
    'SETTINGS/FETCH_SETTINGS_PAYROLL_ADMINS_DATA_REQUEST',
  FETCH_SETTINGS_PAYROLL_ADMINS_DATA_SUCCESS:
    'SETTINGS/FETCH_SETTINGS_PAYROLL_ADMINS_DATA_SUCCESS',
  FETCH_SETTINGS_PAYROLL_ADMINS_DATA_FAILURE:
    'SETTINGS/FETCH_SETTINGS_PAYROLL_ADMINS_DATA_FAILURE',
  FETCH_SETTINGS_TAX_SETUP_DATA: 'SETTINGS/FETCH_SETTINGS_TAX_SETUP_DATA',
  FETCH_TIME_ZONES_REQUEST: 'SETTINGS/FETCH_TIME_ZONES_REQUEST',
  FETCH_TIME_ZONES_SUCCESS: 'SETTINGS/FETCH_TIME_ZONES_SUCCESS',
  FETCH_TIME_ZONES_FAILURE: 'SETTINGS/FETCH_TIME_ZONES_FAILURE',
  FETCH_COMPANY_USERS_REQUEST: 'SETTINGS/FETCH_COMPANY_USERS_REQUEST',
  FETCH_COMPANY_USERS_SUCCESS: 'SETTINGS/FETCH_COMPANY_USERS_SUCCESS',
  FETCH_COMPANY_USERS_FAILURE: 'SETTINGS/FETCH_COMPANY_USERS_FAILURE',
  SAVE_HOLIDAY: 'SETTINGS/SAVE_HOLIDAY',
  CLOSE_SELF_LOCATION_CREATE_MODAL: 'CLOSE_SELF_LOCATION_CREATE_MODAL',
  FETCH_COMPANY_CHANNELS_DATA_REQUEST:
    'SETTINGS/FETCH_COMPANY_CHANNELS_DATA_REQUEST',
  FETCH_COMPANY_CHANNELS_DATA_SUCCESS:
    'SETTINGS/FETCH_COMPANY_CHANNELS_DATA_SUCCESS',
  FETCH_COMPANY_CHANNELS_DATA_FAILURE:
    'SETTINGS/FETCH_COMPANY_CHANNELS_DATA_FAILURE',
  ADP_CREDIT_TIPS_TYPE_UPDATE_REQUEST:
    'SETTINGS/ADP_CREDIT_TIPS_TYPE_UPDATE_REQUEST',
  ADP_CREDIT_TIPS_TYPE_UPDATE_SUCCESS:
    'SETTINGS/ADP_CREDIT_TIPS_TYPE_UPDATE_SUCCESS',
  ADP_CREDIT_TIPS_TYPE_UPDATE_FAILURE:
    'SETTINGS/ADP_CREDIT_TIPS_TYPE_UPDATE_FAILURE',
  CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_REQUEST:
    'SETTINGS/CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_REQUEST',
  CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_SUCCESS:
    'SETTINGS/CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_SUCCESS',
  CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_FAILURE:
    'SETTINGS/CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_FAILURE',
  CHANGE_OWNERSHIP_REQUEST: 'SETTINGS/CHANGE_OWNERSHIP_REQUEST',
  CHANGE_OWNERSHIP_SUCCESS: 'SETTINGS/CHANGE_OWNERSHIP_SUCCESS',
  CHANGE_OWNERSHIP_FAILURE: 'SETTINGS/CHANGE_OWNERSHIP_FAILURE',
  CHANGE_OWNERSHIP_NON_OWNER_EMAIL_REQUEST:
    'SETTINGS/CHANGE_OWNERSHIP_NON_OWNER_EMAIL_REQUEST',
  CHANGE_OWNERSHIP_NON_OWNER_EMAIL_SUCCESS:
    'SETTINGS/CHANGE_OWNERSHIP_NON_OWNER_EMAIL_SUCCESS',
  CHANGE_OWNERSHIP_NON_OWNER_EMAIL_FAILURE:
    'SETTINGS/CHANGE_OWNERSHIP_NON_OWNER_EMAIL_FAILURE',
  HIGHLIGHT_BLOCK: 'SETTINGS/HIGHLIGHT_BLOCK',
  PRELOAD_BILLING_DATA: 'SETTINGS/PRELOAD_BILLING_DATA',
  FETCH_BILLING_DATA: 'SETTINGS/FETCH_BILLING_DATA',
};

const highlightBlockAction = highlightedBlock => ({
  type: actionTypes.HIGHLIGHT_BLOCK,
  payload: { highlightedBlock },
});

export const highlightBlock = (url, highlightedBlock) => dispatch => {
  window.Homebase.RailsReactBridge.navigateToReactView(url);

  setTimeout(() => dispatch(highlightBlockAction()), 5000);

  dispatch(highlightBlockAction(highlightedBlock));
};

export const showApplyChangesModal = (onSave, props = {}) =>
  showModal(APPLY_CHANGES_MODAL_TYPE, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    overflowVisible: true,
    onSave,
    ...props,
  });

export const showLaborSettingsHistoryModal = () =>
  showModal(LABOR_SETTINGS_HISTORY_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    overflowVisible: true,
  });

export const showToastConnectModal = props =>
  showModal(TOAST_CONNECT_MODAL, { deprecatedModal: true, ...props });

export const showDisconnectWarningModal = (tier, partnerName) =>
  showModal(DISCONNECT_WARNING_MODAL, {
    deprecatedModal: true,
    tier,
    partnerName,
  });

export const showAdpCreditTipsSettingsModal = props =>
  showModal(ADP_CREDIT_TIPS_SETTINGS_MODAL_TYPE, {
    deprecatedModal: true,
    ...props,
  });

export const showCompletedDocumentsSettingsModal = (
  role,
  employeeNames,
  handleChange
) =>
  showModal(COMPLETED_DOCUMENTS_SETTINGS_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    role,
    employeeNames,
    handleChange,
  });

export const showPayrollAdminConfirmationModal = (
  currentPayrollAdmins,
  activeUsers,
  onSave
) =>
  showModal(PAYROLL_ADMIN_CONFIRMATION_MODAL, {
    deprecatedModal: true,
    auto: true,
    noPadding: true,
    onSave,
    currentPayrollAdmins,
    activeUsers,
  });

export const updateAdpCreditTipsType = type =>
  reduxUtil.createAsyncPutAction(
    routes.adpCreditTipsTypeRoute(),
    [
      { type: actionTypes.ADP_CREDIT_TIPS_TYPE_UPDATE_REQUEST, meta: { type } },
      { type: actionTypes.ADP_CREDIT_TIPS_TYPE_UPDATE_SUCCESS, meta: { type } },
      { type: actionTypes.ADP_CREDIT_TIPS_TYPE_UPDATE_FAILURE, meta: { type } },
    ],
    { body: { type } }
  );

export const sendChangeOwnershipEmailConfirmation = () =>
  reduxUtil.createAsyncGetAction(routes.changeOwnershipEmailConfirmation(), [
    { type: actionTypes.CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_REQUEST },
    { type: actionTypes.CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_SUCCESS },
    { type: actionTypes.CHANGE_OWNERSHIP_EMAIL_CONFIRMATION_FAILURE },
  ]);

export const fetchLocationsData = () =>
  reduxUtil.createAsyncGetAction(routes.settingsRoute(), [
    actionTypes.FETCH_LOCATIONS_DATA_REQUEST,
    actionTypes.FETCH_LOCATIONS_DATA_SUCCESS,
    actionTypes.FETCH_LOCATIONS_DATA_FAILURE,
  ]);

export const fetchSettingsPayrollAdmins = () =>
  reduxUtil.createAsyncGetAction(routes.payrollAdminsSettingsRoute(), [
    actionTypes.FETCH_SETTINGS_PAYROLL_ADMINS_DATA_REQUEST,
    actionTypes.FETCH_SETTINGS_PAYROLL_ADMINS_DATA_SUCCESS,
    actionTypes.FETCH_SETTINGS_PAYROLL_ADMINS_DATA_FAILURE,
  ]);

export const fetchTaxSetupComponentData = createAsyncThunk(
  actionTypes.FETCH_SETTINGS_TAX_SETUP_DATA,
  () => fetchJSON(routes.taxSetupDataSettingsRoute())
);

export const updateTaxSetupComponentData = payload => ({
  type: actionTypes.FETCH_SETTINGS_TAX_SETUP_DATA,
  payload,
});

export const updatePosPartner = (partner, locationId) => ({
  type: actionTypes.UPDATE_POS_PARTNER,
  payload: { partner, locationId },
});

export const updatePayrollPartner = (partner, locationId) => ({
  type: actionTypes.UPDATE_PAYROLL_PARTNER,
  payload: { partner, locationId },
});

export const requestApiKey = () =>
  reduxUtil.createAsyncPostAction(routes.apiKeysRoute(), [
    actionTypes.CREATE_API_KEY_REQUEST,
    actionTypes.CREATE_API_KEY_SUCCESS,
    actionTypes.CREATE_API_KEY_FAILURE,
  ]);

export const updateLocationSettings =
  ({
    payload,
    requestId,
    successText,
    errorText,
    onSuccess,
    onError,
    fromTimesheets,
  }) =>
  (dispatch, getState) => {
    const currentLocationData = sessionSelectors.getCurrentLocationEntity(
      getState()
    );

    dispatch(
      reduxUtil.withAlerts(
        reduxUtil.createAsyncPatchAction(
          routes.locationRoute(currentLocationData.get('id')),
          [
            {
              type: actionTypes.UPDATE_LOCATION_SETTINGS_REQUEST,
              meta: { requestId },
            },
            {
              type: actionTypes.UPDATE_LOCATION_SETTINGS_SUCCESS,
              meta: { requestId },
            },
            {
              type: actionTypes.UPDATE_LOCATION_SETTINGS_FAILURE,
              meta: { requestId },
            },
          ],
          {
            body: { react_timesheets: fromTimesheets, location: payload },
          }
        ),
        {
          success: successText,
          error: errorText,
          onSuccess,
          onError,
        }
      )
    );
  };

export const submitSettingsForm =
  (formName, { location, formData, appliedAt, onError, onSuccess, noFlash }) =>
  (dispatch, getState) => {
    const state = getState();
    const data = settingsUtil.formatDataForServer(formData, location);
    const changedData = { ...data.location };
    const settingsConfig = SETTINGS_CONFIGS[formName];
    const canApplyToAll = settingsConfig.canApplyToAll;
    const shouldApplyToAll = settingsSelectors.getApplyToAllLocations(state);
    const locationId = location.get('id');

    data.location.id = locationId;
    data.applied_at = appliedAt;

    if (canApplyToAll && shouldApplyToAll) {
      data.location.company_attributes = {
        ...(data.location.company_attributes || {}),
        id: location.get('company_id'),
        common_settings: '1',
      };
    }

    const requestId = `save-location-settings__${formName}`;
    const meta = { requestId, formName, formData, locationId };

    reduxUtil.withAlerts(
      reduxUtil.createAsyncPatchAction(
        routes.locationRoute(location.get('id')),
        [
          { type: actionTypes.SAVE_LOCATION_SETTINGS_REQUEST, meta },
          { type: actionTypes.SAVE_LOCATION_SETTINGS_SUCCESS, meta },
          { type: actionTypes.SAVE_LOCATION_SETTINGS_FAILURE, meta },
        ],
        { body: data }
      ),
      {
        success: toI18n('settings.update_success'),
        error: toI18n('settings.update_error'),
        onError,
        onSuccess: () => {
          updateLocationSettings({
            id: data.location.id,
            payroll_period: data.location.payroll_period,
          });
          dispatch(updateCurrentLocation(data.location));

          if (settingsConfig.trackChanges) {
            const initialFormData = Object.keys(formData).reduce(
              (hash, key) => {
                hash[key] = location.get(key);
                return hash;
              },
              {}
            );

            const initialData = settingsUtil.formatDataForServer(
              initialFormData,
              location
            ).location;

            postJSON('/events/create_event', {
              event_name: 'settings_page',
              before: initialData,
              after: changedData,
              type: settingsConfig.content,
            });
          }

          if (onSuccess) {
            onSuccess();
          }
        },
        noSuccessFlash: noFlash,
        noErrorFlash: noFlash,
      }
    )(dispatch);
  };

export const importPartnerLocations = partnerId =>
  reduxUtil.createAsyncPostAction(
    routes.locationsImportRoute(),
    [
      actionTypes.IMPORT_PARTNER_LOCATIONS_REQUEST,
      actionTypes.IMPORT_PARTNER_LOCATIONS_SUCCESS,
      actionTypes.IMPORT_PARTNER_LOCATIONS_FAILURE,
    ],
    { body: { partner_id: partnerId } }
  );

export const updateApplyToAllLocations = value => ({
  type: actionTypes.UPDATE_APPLY_TO_ALL_LOCATIONS,
  payload: { value },
});

export const archiveJob = jobId =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(
      routes.employeeArchiveRoute(jobId),
      [
        actionTypes.ARCHIVE_JOB_REQUEST,
        actionTypes.ARCHIVE_JOB_SUCCESS,
        actionTypes.ARCHIVE_JOB_FAILURE,
      ],
      { body: { archive_record: { reason: 'Voluntary Resignation' } } }
    ),
    {
      error: toI18n('settings.update_error'),
      onSuccess: () => window.location.reload(),
    }
  );

export const submitBackboneMySettingsFailure = payload => ({
  type: actionTypes.SUBMIT_BACKBONE_MY_SETTINGS_FAILURE,
  payload,
});

export const updateWebTimeClockEmployeeAccess = value =>
  updateLocationSettings({
    payload: {
      properties_attributes: {
        web_timeclock_employee_access: value,
      },
    },
    requestId: 'web_timeclock_employee_access',
    successText: toI18n('web_timeclock.static_page.save_settings_success'),
    errorText: toI18n('web_timeclock.static_page.save_settings_error'),
  });

export const updateZeroStateTimesheetSettings =
  attributes => (dispatch, getState) => {
    const location = sessionSelectors.getCurrentLocation(getState());
    const data = settingsUtil.formatDataForServer(attributes, location);

    dispatch(
      updateLocationSettings({
        payload: {
          id: location.get('id'),
          ...data.location,
        },
        requestId: 'zero_state_timesheet_settings',
        errorText: toI18n('web_timeclock.static_page.save_settings_error'),
      })
    );
  };

export const fetchBillingData = createAsyncThunk(
  actionTypes.FETCH_BILLING_DATA,
  () => fetchJSON(routes.billingDataRoute())
);

export const preloadBillingData = createAsyncThunk(
  actionTypes.PRELOAD_BILLING_DATA,
  (_, { dispatch }) =>
    fetchJSON(routes.billingBatchEnqueRoute()).then(response => {
      if (response.cache_hit) {
        dispatch(fetchBillingData());
      } else {
        pollBatchStatus(response.batch_id)
          .then(() => {
            dispatch(fetchBillingData());
          })
          .catch(() => {
            flashError(toI18n('errors.generic'));
          });
      }
    })
);

export const updateMobileTimeClockEnabled = value =>
  updateLocationSettings({
    payload: {
      mobile_timeclock_enabled: value,
    },
    requestId: 'mobile_timeclock_enabled',
    successText: toI18n('web_timeclock.static_page.save_settings_success'),
    errorText: toI18n('web_timeclock.static_page.save_settings_error'),
  });

export const updateStartDayOfWeek = startDay =>
  updateLocationSettings({
    payload: { start_of_week: startDay },
    onSuccess: () =>
      window.Homebase.ScheduleBuilder.handleStartDayOfWeekChanged(startDay),
  });

export const fetchUserSettingsData = () =>
  reduxUtil.createAsyncGetAction(routes.userSettingsRoute(), [
    actionTypes.FETCH_USER_SETTINGS_DATA_REQUEST,
    actionTypes.FETCH_USER_SETTINGS_DATA_SUCCESS,
    actionTypes.FETCH_USER_SETTINGS_DATA_FAILURE,
  ]);

export const fetchCompanyUsers = () =>
  reduxUtil.createAsyncGetAction(routes.companyUsersRoute(), [
    actionTypes.FETCH_COMPANY_USERS_REQUEST,
    actionTypes.FETCH_COMPANY_USERS_SUCCESS,
    actionTypes.FETCH_COMPANY_USERS_FAILURE,
  ]);

export const sendChangeOwnershipNonOwnerEmail = () =>
  reduxUtil.createAsyncGetAction(routes.changeOwnershipNonOwnerEmailRoute(), [
    { type: actionTypes.CHANGE_OWNERSHIP_NON_OWNER_EMAIL_REQUEST },
    { type: actionTypes.CHANGE_OWNERSHIP_NON_OWNER_EMAIL_SUCCESS },
    { type: actionTypes.CHANGE_OWNERSHIP_NON_OWNER_EMAIL_FAILURE },
  ]);

export const changeOwnership = newOwnerId => dispatch =>
  dispatch(
    reduxUtil.withAlerts(
      reduxUtil.createAsyncPutAction(
        routes.changeOwnership(),
        [
          { type: actionTypes.CHANGE_OWNERSHIP_REQUEST },
          { type: actionTypes.CHANGE_OWNERSHIP_SUCCESS },
          { type: actionTypes.CHANGE_OWNERSHIP_FAILURE },
        ],
        { body: { new_owner_id: newOwnerId } }
      ),
      {
        success: toI18n(
          'new_settings.company.change_ownership.confirmation_modal.success'
        ),
        onSuccess: () => {
          browserHistory.push('/settings');
          window.location.reload();
        },
      }
    )
  );

export const fetchTimeZones = () =>
  reduxUtil.createAsyncGetAction(routes.timeZonesRoute(), [
    actionTypes.FETCH_TIME_ZONES_REQUEST,
    actionTypes.FETCH_TIME_ZONES_SUCCESS,
    actionTypes.FETCH_TIME_ZONES_FAILURE,
  ]);

export const closeSelfLocationCreateModal = () => ({
  type: actionTypes.CLOSE_SELF_LOCATION_CREATE_MODAL,
});

export const fetchCompanyChannelsData = () =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncGetAction(routes.companyChannelsRoute(), [
      actionTypes.FETCH_COMPANY_CHANNELS_DATA_REQUEST,
      actionTypes.FETCH_COMPANY_CHANNELS_DATA_SUCCESS,
      actionTypes.FETCH_COMPANY_CHANNELS_DATA_FAILURE,
    ]),
    {
      error: toI18n('settings.messaging.error'),
    }
  );

export const updateCurrentUser = (formData, locations, user) => {
  const data = settingsUtil.formatUserDataForServer(formData, locations);

  const requestId = `save-user-settings`;
  const meta = { requestId, formData };

  return reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(
      routes.userPath(),
      [
        { type: actionTypes.UPDATE_CURRENT_USER_REQUEST, meta },
        { type: actionTypes.UPDATE_CURRENT_USER_SUCCESS, meta },
        { type: actionTypes.UPDATE_CURRENT_USER_FAILURE, meta },
      ],
      { body: data }
    ),
    {
      success: toI18n('settings.update_success'),
      error: toI18n('settings.update_error'),
      onSuccess: () => {
        const settingsConfig = SETTINGS_CONFIGS.notifications;

        const initialFormData = Object.keys(formData).reduce((hash, key) => {
          hash[key] = user.get(key);
          return hash;
        }, {});

        const initialData = settingsUtil.formatUserDataForServer(
          initialFormData,
          locations
        );

        postJSON('/events/create_event', {
          event_name: 'settings_page',
          before: initialData.user,
          after: data.user,
          type: settingsConfig.content,
        });
      },
    }
  );
};

export const updateLocationPayrollInfo =
  ({ payrollPeriod, payrollInfo, reloadTimesheets }) =>
  dispatch => {
    dispatch(
      updateLocationSettings({
        requestId: 'PAYROLL_PERIOD_MODAL',
        fromTimesheets: true,
        payload: {
          payroll_period: payrollPeriod,
          payroll_info: payrollInfo,
        },
        onSuccess: response => {
          dispatch(hideModal());
          dispatch(updateCurrentLocation(response.payload));
          if (reloadTimesheets) {
            const payrollPeriods = response.payload.payrollPeriods;
            const currentPeriod = payrollPeriods.find(
              period => period.label_type === 'current'
            );
            const timesheetParams = {
              payrollPeriod: currentPeriod.label_type,
              startDate: currentPeriod.start_date,
              endDate: currentPeriod.end_date,
            };
            dispatch(updatePayrollPeriods(payrollPeriods));
            dispatch(fetchTimesheetData(timesheetParams));
          }
        },
        errorText: toI18n('settings.update_error'),
      })
    );
  };
