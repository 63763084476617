import { Map } from 'immutable';
import { createSelector } from 'reselect';

import * as constants from 'features/tiers/constants';

import * as entitiesSelectors from './entities';
import * as sessionSelectors from './session';

export const getIsFetching = (state, { id }) =>
  state.getIn(['tiers', 'isFetching']).has(id);

export const getIsFetchingTiersData = state =>
  getIsFetching(state, {
    id: constants.REQUEST_KEYS.tiersData,
  });

const getIsLoaded = entitiesSelectors.createLoadedSelector(
  'locations',
  'TIERS'
);

export const getShowLoading = createSelector(
  getIsLoaded,
  getIsFetchingTiersData,
  (isLoaded, isFetching) => !isLoaded || isFetching
);

export const getFetchTiersDataShouldBailout = createSelector(
  getIsLoaded,
  getIsFetchingTiersData,
  (isLoaded, isFetching) => isLoaded || isFetching
);

export const getAvailableTiers =
  sessionSelectors.buildCurrentLocationPropSelector('availableTiers');

export const getCanBillAnnually =
  sessionSelectors.buildCurrentLocationPropSelector('canBillAnnually');

export const getUsFoodsAccountWithoutTrial =
  sessionSelectors.buildCurrentLocationPropSelector(
    'usFoodsAccountWithoutTrial'
  );

export const getAnnualSubscriptionDefault =
  sessionSelectors.buildCurrentLocationPropSelector(
    'annualSubscriptionDefault'
  );

export const getBillsByLocation =
  sessionSelectors.buildCurrentLocationPropSelector('billsByLocation');

export const selectInTrialAndHomebaseBilled =
  sessionSelectors.buildCurrentLocationPropSelector('inTrialAndHomebaseBilled');

export const getCurrentLocationTier = createSelector(getAvailableTiers, tiers =>
  tiers ? tiers.find(tier => tier.get('current')) : Map()
);
