import './NavView.scss';

import { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { partial } from 'lodash';
import PropTypes from 'prop-types';
import { colors, dimensions } from 'theme/constants';

import * as navActions from 'actions/nav';

import * as ftuSelectors from 'selectors/ftu';
import * as navSelectors from 'selectors/nav';
import * as sessionSelectors from 'selectors/session';

import BillingStateBanner from 'features/biller/billingStateBanner/BillingStateBanner';
import EnforcementBanner from 'features/enforcement/EnforcementBanner/EnforcementBanner';
import PayrollCountdownBanner from 'features/payroll/components/PayrollCountdownBanner';
import PayrollPreNoteValidationBanner from 'features/payroll/components/PayrollPreNoteValidationBanner';
import PayrollFailedPaymentBanner from 'features/payroll/FailedPaymentViews/PayrollFailedPaymentBanner';

import Box from 'components/Box';
import Drawer from 'components/Drawer';
import withCurrentRoute from 'components/hocs/withCurrentRoute';
import Icon from 'components/Icon';
import { TabletAndBelow } from 'components/MediaQuery';
import Text from 'components/Text';

import cxHelpers from 'util/className';

import DemoNav from '../DemoNav';
import GlobalComponents from '../GlobalComponents';
import HubNav from '../HubNav';
import ActiveTrialNavItem from '../items/ActiveTrialNavItem';
import MessengerNavItem from '../items/MessengerNavItem';
import NavItem from '../items/NavItem';
import NotificationsNavItem from '../items/NotificationsNavItem';
import UpgradeButtonNavItem from '../items/UpgradeButtonNavItem';
import UserWebTimeclockNavItem from '../items/UserWebTimeclockNavItem';
import PublicNav from '../PublicNav';
import SubNav from '../SubNav';
import UserNav from '../UserNav';
import UserOnlyNav from '../UserOnlyNav';

const TOP_BAR_HEIGHT = 48;

import { FULL_SCREEN_PATHS } from 'features/payroll/constants';

@withCurrentRoute
@connect(
  (state, props) => ({
    navType: sessionSelectors.getNavType(state, props),
    mobileNavOpen: navSelectors.getMobileNavOpen(state, props),
    isDemoSession: sessionSelectors.getIsDemoSession(state, props),
    showSimpleNav: ftuSelectors.getShowSimpleNav(state, props),
    showChecklistSimpleNav: ftuSelectors.getShowChecklistSimpleNav(
      state,
      props
    ),
    isGodModing: sessionSelectors.getIsGodModing(state, props),
    currentUser: sessionSelectors.getCurrentUser(state, props),
    showPayrollCountdownBanner:
      sessionSelectors.getShowPayrollCountdownBanner(state),
  }),
  {
    toggleMobileNav: navActions.toggleMobileNav,
  }
)
@cxHelpers('NavView')
export default class NavView extends PureComponent {
  static propTypes = {
    navType: PropTypes.string.isRequired,
    mobileNavOpen: PropTypes.bool.isRequired,
    toggleMobileNav: PropTypes.func.isRequired,
    isDemoSession: PropTypes.bool,
    showSimpleNav: PropTypes.bool,
    showChecklistSimpleNav: PropTypes.bool,
    isGodModing: PropTypes.bool,
    currentUser: ImmutablePropTypes.map,
    showPayrollCountdownBanner: PropTypes.bool.isRequired,
  };

  isType(type) {
    return this.props.navType === type;
  }
  isPublic = partial(this.isType, 'public');
  isHub = partial(this.isType, 'hub');
  isUser = partial(this.isType, 'user');
  isUserOnly = partial(this.isType, 'user_only');
  isHidden = partial(this.isType, 'hidden');
  isFullscreenPath = () =>
    FULL_SCREEN_PATHS.some(path => this.props.currentRoute.includes(path));
  handleToggleMobileNav = () => this.props.toggleMobileNav();

  renderSideNav() {
    if (this.isPublic() || this.isUserOnly()) {
      return null;
    }

    const containerStyle = {
      background: colors.violetXdark,
      width: '200px',
    };

    return (
      <TabletAndBelow>
        {tablet => (
          <Drawer
            alignLeft
            open={this.props.mobileNavOpen && tablet}
            top={TOP_BAR_HEIGHT}
            absolute
            containerStyle={containerStyle}
          >
            {this.isHub() ? <HubNav mobileView /> : <UserNav mobileView />}
          </Drawer>
        )}
      </TabletAndBelow>
    );
  }

  renderMobileNav() {
    if (this.isPublic()) {
      return <PublicNav />;
    }
    if (this.isUserOnly()) {
      return <UserOnlyNav />;
    }

    return (
      <>
        <Box row>
          <NavItem onClick={this.handleToggleMobileNav} persistMobileNavOnClick>
            <Icon
              color={this.props.mobileNavOpen ? 'white' : 'violetLight'}
              type="bars"
              size="lg"
            />
          </NavItem>
          <Box vcenter mrxs>
            <a href="/" className="heap--navbar-1">
              <Text white fs24 fw700>
                homebase
              </Text>
            </a>
          </Box>
        </Box>
      </>
    );
  }

  renderDemoNav() {
    return (
      <Box>
        <Box
          className={this.cx({})}
          minh={dimensions.navHeight}
          z={11}
          phs
          vcenter
          bg="navy"
        >
          <DemoNav />
        </Box>
        {this.renderSubNav()}
      </Box>
    );
  }

  renderNav() {
    if (this.isPublic()) {
      return <PublicNav />;
    }
    if (this.isUserOnly()) {
      return <UserOnlyNav />;
    }
    if (this.isHub()) {
      return <HubNav />;
    }
    return <UserNav />;
  }

  renderSubNav() {
    return !this.props.showSimpleNav && <SubNav />;
  }

  navBackgroundColor() {
    return this.props.isGodModing ? 'black' : 'purpleXdark';
  }

  render() {
    if (this.isHidden()) {
      return <GlobalComponents />;
    }
    if (this.isFullscreenPath()) {
      // For when they are running payroll
      return null;
    }
    if (this.props.isDemoSession) return this.renderDemoNav();
    return (
      <Box>
        <EnforcementBanner />
        <BillingStateBanner />
        {this.props.showPayrollCountdownBanner && <PayrollCountdownBanner />}
        <PayrollFailedPaymentBanner />
        <PayrollPreNoteValidationBanner />
        <Box
          className={this.cx({}, 'js-nav')}
          minh={dimensions.navHeight}
          ph={10}
          vcenter
          bg={this.navBackgroundColor()}
        >
          <TabletAndBelow>
            {tablet =>
              tablet ? (
                <Box w="100%" spacebetween vcenter>
                  <Box vcenter>{this.renderMobileNav()}</Box>

                  {!this.props.showSimpleNav &&
                    !this.isPublic() &&
                    !this.isUserOnly() && (
                      <Box vcenter>
                        <ActiveTrialNavItem fullText />
                        <UpgradeButtonNavItem fullText />
                        <MessengerNavItem />
                        <UserWebTimeclockNavItem />
                        <NotificationsNavItem />
                      </Box>
                    )}
                </Box>
              ) : (
                this.renderNav()
              )
            }
          </TabletAndBelow>

          {this.renderSideNav()}
          <GlobalComponents />
        </Box>
        {this.renderSubNav()}
      </Box>
    );
  }
}
