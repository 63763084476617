import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Text, { CapsText } from 'components/Text';

import cxHelpers from 'util/className';

export const SIZES = ['xs', 'sm'];

@cxHelpers('Pill')
export default class Pill extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    inverse: PropTypes.bool,
    i18n: PropTypes.string,
    i18nProps: PropTypes.object,
    isDowncase: PropTypes.bool,
    size: PropTypes.oneOf(SIZES),
    bradius: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  };

  static defaultProps = {
    color: 'blue',
    size: 'sm',
    bradius: '999em',
    isDowncase: false,
  };

  render() {
    const {
      color,
      inverse,
      i18n,
      i18nProps,
      children,
      size,
      isDowncase,
      ...boxProps
    } = this.props;
    const textProps = { children, i18n, i18nProps };

    if (inverse) {
      boxProps.bg = 'white';
      boxProps.b = color;
      textProps[color] = true;
    } else {
      boxProps.bg = color;
      textProps.white = true;
    }

    if (size === 'xs') {
      textProps.fs10 = true;
      textProps.fw600 = true;
      textProps.fw700 = false;
      boxProps.pb = 3;
    } else {
      textProps.fs12 = true;
      boxProps.pt = 1;
      boxProps.pb = 3;
    }

    return (
      <Box className={this.cx()} phxxs {...boxProps}>
        {isDowncase ? (
          <Text fs14 fw600 {...textProps} />
        ) : (
          <CapsText {...textProps} />
        )}
      </Box>
    );
  }
}
