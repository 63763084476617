import './InstagramSharing.scss';

import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import IconButton from 'fe-design-base/molecules/IconButton';

import { cxHelpers } from 'util/className';

const { cx, cxEl } = cxHelpers('InstagramSharing');

interface InstagramSharingProps {
  handleViewSocialSharing: () => void;
}

const InstagramSharing = ({
  handleViewSocialSharing,
}: InstagramSharingProps) => (
  <>
    <Box color="purple500" absolute top={8}>
      <IconButton
        size="small"
        icon="ArrowLeft"
        uxElement="backToSocialSharing"
        onClick={handleViewSocialSharing}
      />
    </Box>

    <Box flex gap={16} className={cx()}>
      <Box w={350} h={350} className={cxEl('qr-code')} />

      <Box w={240} column justifyContentCenter>
        <Box mb={8}>
          <Text
            variant="heading2"
            i18n="growth.hours_provided.instagram.scan_to_share"
          />
        </Box>

        <Box>
          <Text
            color="mono700"
            variant="bodySmTight"
            i18n="growth.hours_provided.instagram.scan_qr_to_share"
          />
        </Box>
      </Box>
    </Box>
  </>
);

export default InstagramSharing;
