import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import { ENTITIES_SLICE, MODULE_NAME } from '../module-constants';

import { PENDING_INDEX, PENDING_STACK, PENDING_STATE } from './constants';
import mergeEntities from './mergeEntities';

// used primarily because of shallow compare, because reference is always the same
const emptyArray = [];

const selectSliceState = state =>
  state.get(MODULE_NAME)?.[ENTITIES_SLICE] || {};

const selectPendingState = state =>
  selectSliceState(state)[PENDING_STATE] || {};

const selectPendingStack = state =>
  selectPendingState(state)[PENDING_STACK] || [];

const selectPendingIndex = state =>
  selectPendingState(state)[PENDING_INDEX] || {};

const selectPendingEntityVersions = createCachedSelector(
  selectPendingStack,
  selectPendingIndex,
  (_, props) => props.type,
  (_, props) => props.id,
  (pendingStack, pendingIndex, type, id) => {
    const filtered = pendingStack
      .map(pendingId => pendingIndex[pendingId]?.[type]?.[id])
      .filter(v => !!v);
    return filtered.length > 0 ? filtered : emptyArray;
  }
)(
  (_, { type, id }) =>
    `fe-core.Entities.selectPendingEntityVersions(${type}, ${id})`
);

const selectAllPendingEntityIndexesOfType = createCachedSelector(
  selectPendingStack,
  selectPendingIndex,
  (_, props) => props.type,
  (pendingStack, pendingIndex, type) => {
    const filtered = pendingStack
      .map(pendingId => pendingIndex[pendingId]?.[type])
      .filter(e => !!e);
    return filtered.length > 0 ? filtered : emptyArray;
  }
)(
  (_, { type }) =>
    `fe-core.Entities.selectAllPendingEntityIndexesOfType(${type})`
);

export const selectPendingEntity = createCachedSelector(
  selectPendingEntityVersions,
  (_, props) => props.type,
  (_, props) => props.id,
  (entityVersions, type, id) => {
    if (entityVersions.length === 0) return null;

    return {
      type,
      id,
      ...mergeEntities(entityVersions),
    };
  }
)((_, { type, id }) => `fe-core.Entities.selectPendingEntity(${type}, ${id})`);

const selectSavedEntityIndex = createSelector(
  selectSliceState,
  (_, props) => props.type,
  (state, type) => state[type] || null
);

export const selectEntityMap = createCachedSelector(
  selectSavedEntityIndex,
  selectAllPendingEntityIndexesOfType,
  (_, props) => props.type,
  (savedIndex, pendingIndexes, type) => {
    if (pendingIndexes.length === 0) {
      return savedIndex || {};
    }

    const combineIndexes = savedIndex
      ? [savedIndex, ...pendingIndexes]
      : pendingIndexes;

    return combineIndexes.reduce((combo, index) => {
      Object.entries(index).forEach(([id, version]) => {
        if (!id) return combo;
        combo[id] = {
          id,
          type,
          ...mergeEntities([combo[id], version]),
        };
      });
      return combo;
    }, {});
  }
)((_, { type }) => `fe-core.Entities.selectEntityMap(${type})`);

export const selectAllEntities = createCachedSelector(
  selectEntityMap,
  entityMap => Object.values(entityMap)
)((_, { type }) => `fe-core.Entities.selectAllEntities(${type})`);

const selectSavedEntity = (state, props) =>
  selectSliceState(state)?.[props.type]?.[props.id];

export const selectEntity = createCachedSelector(
  selectPendingEntity,
  selectSavedEntity,
  (pendingEntity, savedEntity) => {
    if (pendingEntity) {
      return {
        id: pendingEntity.id,
        type: pendingEntity.type,
        attributes: {
          ...(savedEntity?.attributes || {}),
          ...(pendingEntity.attributes || {}),
        },
        relationships: {
          ...(savedEntity?.relationships || {}),
          ...(pendingEntity.relationships || {}),
        },
      };
    }
    return savedEntity;
  }
)((_, { type, id }) => `fe-core.Entities.selectEntity(${type}, ${id})`);

export const selectAttribute = createSelector(
  selectEntity,
  (_, props) => props.attribute,
  (entity, attribute) => entity?.attributes?.[attribute]
);

export const selectRelationship = createSelector(
  selectEntity,
  (_, props) => props.relationship,
  (entity, relationship) => entity?.relationships?.[relationship]?.data
);

export const selectWhereAttribute = createCachedSelector(
  selectAllEntities,
  (_, props) => props.type,
  (_, props) => props.attribute,
  (_, props) => props.value,
  (entities, type, attribute, value) => {
    const filtered = entities.filter(
      entity => entity.attributes[attribute] === value
    );
    return filtered.length > 0 ? filtered : emptyArray;
  }
)(
  (_, { type, attribute, value }) =>
    `fe-core.Entities.selectWhereAttribute(${type}-${attribute}-${value})`
);
