import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgHome = ({
  alt = 'Home Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M23.992 11.98c0 .738-.625 1.316-1.334 1.316h-1.333l.03 6.567c0 .114-.01.221-.022.332v.664c0 .907-.745 1.641-1.666 1.641H19c-.046 0-.092-.037-.137-.004-.059-.033-.117.004-.175.004h-2.355c-.92 0-1.666-.734-1.666-1.64v-3.61c0-.726-.596-1.313-1.334-1.313h-2.666c-.738 0-1.334.587-1.334 1.313v3.61c0 .906-.745 1.64-1.666 1.64h-2.33c-.062 0-.124-.004-.187-.008-.05.004-.1.008-.15.008h-.667c-.92 0-1.666-.734-1.666-1.64v-4.594c0-.037 0-.078.003-.115v-2.855H1.335A1.31 1.31 0 0 1 0 11.98c0-.37.125-.698.417-.985L11.1 1.83c.292-.288.625-.329.917-.329.291 0 .625.082.879.288l10.637 9.207c.334.287.505.615.459.985Z"
    />
  </svg>
);
export default SvgHome;
