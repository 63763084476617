import { Overrides } from '../../types';
import colors from '../colors';

export const formControlLabelOverrides: Overrides = {
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        '&.LeftInput .MuiFormControlLabel-label': {
          marginLeft: '6px',
        },

        '&.FDBCheckboxField': {
          alignItems: 'start',

          '& .FDBCheckbox': {
            marginTop: '-3.5px',
            flexShrink: 0,
          },
        },
      },
      label: {
        color: colors.mono900,
      },
    },
  },
};
