import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorLinkedin = ({
  alt = 'Linkedin Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M27.36 2.667H4.634c-1.088 0-1.968.86-1.968 1.922v22.817c0 1.063.88 1.927 1.968 1.927H27.36c1.089 0 1.974-.864 1.974-1.921V4.588c0-1.063-.885-1.922-1.974-1.922ZM10.577 25.39H6.62V12.66h3.958v12.73Zm-1.98-14.464a2.293 2.293 0 1 1 0-4.583 2.292 2.292 0 0 1 0 4.583ZM25.39 25.391h-3.953v-6.188c0-1.474-.026-3.375-2.057-3.375-2.057 0-2.37 1.61-2.37 3.271v6.292h-3.948V12.66h3.792v1.74h.052c.526-1 1.818-2.057 3.74-2.057 4.005 0 4.744 2.635 4.744 6.062v6.985Z"
      fill="#0A66C2"
    />
  </svg>
);
export default SvgColorLinkedin;
