import './CardContainer.scss';

import React from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import { Props } from 'fe-design-base/atoms/Box/interface';

const CardContainer = ({
  cx,
  children,
  selected,
  disabled,
  ...props
}: Props<{ selected: boolean; disabled: boolean }> & CxProps) => (
  <Box
    className={cx({ selected, disabled })}
    tabIndex={0}
    {...props}
    bradiusm
    shadow="z1"
    aria-disabled={disabled}
  >
    <Box>{children}</Box>
  </Box>
);

export default withCx<Props>('FDBCardContainer')(CardContainer);
