import React, { useCallback } from 'react';
import cx from 'classnames';
import { partial } from 'lodash';

// Interpolates base & mod into a BEM string
function getCxMod(base, mod) {
  return `${base}--${mod}`;
}

// Accepts single or plural mods. If single, returns the mod appended
// to the base. If plural, returns an array of each mod appended to
// the base.
function cxMods(base, mods) {
  if (mods && typeof mods === 'object') {
    return Object.entries(mods).map(m => (m[1] ? getCxMod(base, m[0]) : null));
  } else if (typeof mods === 'string' && mods.length > 0) {
    return getCxMod(base, mods);
  }
  return null;
}

function cxElFn(block, el, mods, ...rest) {
  if (typeof el !== 'string') {
    throw new Error('Called this.cxEl without an element string!');
  }
  const cxEl = `${block}__${el}`;

  return cx(cxEl, cxMods(cxEl, mods), ...rest);
}

// Decorator that adds helpers `this.cx()` & `this.cxEl()` on Component
// to assist with creating BEM classNames DRYly.
export default function className(block) {
  return function (Component) {
    Component.prototype.cx = function (mods, ...rest) {
      return cx(block, cxMods(block, mods), this.props.className, ...rest);
    };

    Component.prototype.cxEl = partial(cxElFn, block);

    return Component;
  };
}

// Helper function for functional components. This passes `cx()` & `cxEl()` as props.
export function cxFunction(block, Component) {
  return props => {
    const inClassName = props.className || '';
    const cxFunctional = useCallback(
      (mods, ...rest) => cx(block, cxMods(block, mods), inClassName, ...rest),
      [inClassName]
    );
    const cxEl = useCallback(
      (el, mods, ...rest) => cxElFn(block, el, mods, ...rest),
      []
    );

    return <Component cx={cxFunctional} cxEl={cxEl} {...props} />;
  };
}

export function cxHelpers(block) {
  return {
    cx: (mods, ...rest) => cx(block, cxMods(block, mods), ...rest),
    cxEl: partial(cxElFn, block),
  };
}
