import { typography } from 'fe-design-base/styles/typography';

import { Overrides } from '../../types';
import baseColors from '../colors';
import { gutters } from '../utils';

export const tabsOverrides: Overrides = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& .MuiTabs-scroller': {
          // Note: Overflow must be hidden here otherwise the scrolling UI breaks
          // Side effect is that the focus-visible outline does not show.
          // TODO: FE-831 Update focus-visible UI

          '& button:hover': {
            border: 'none',
          },
        },

        '& .MuiTouchRipple-root': {
          color: baseColors.mono300,
        },

        '&.FDBSegmentedPicker': {
          backgroundColor: baseColors.mono100,
          width: 'fit-content',
          boxShadow: `0px 0px 0px 1px ${baseColors.purple500} inset`,
          borderRadius: '8px',
          height: '40px',
          minHeight: 'unset',
          overflow: 'visible',

          '&.FDBSegmentedPicker--fullWidth, &.FDBSegmentedPicker--scrollable': {
            width: '100%',
          },

          '& button': {
            border: 'none',
            background: 'none',
          },

          [theme.breakpoints.down('large')]: {
            width: '100%',
            justifyContent: 'stretch',
          },

          '& .MuiTabs-indicator': {
            display: 'none',
          },

          '& .MuiTabs-flexContainer': {
            [theme.breakpoints.up('large')]: {
              columnGap: gutters[8],
            },

            [theme.breakpoints.down('large')]: {
              width: '100%',
              justifyContent: 'stretch',
            },
          },

          '& .Mui-selected': {
            backgroundColor: baseColors.mono0,
            color: baseColors.purple500,
            boxShadow: `0px 0px 0px 2px ${baseColors.purple500} inset`,
          },

          '& .MuiTab-root': {
            color: baseColors.purple500,
            padding: `${gutters[8]} ${gutters[16]}`,
            textTransform: 'unset',
            borderRadius: '8px',
            minHeight: 'unset',
            height: '100%',
            overflow: 'visible',
            position: 'relative',
            minWidth: '100px',
            maxWidth: '100px',

            '&:hover': {
              backgroundColor: baseColors.purple100,

              '&:not(.Mui-selected)': {
                boxShadow: `0px 0px 0px 1px ${baseColors.purple500} inset`,
              },
            },

            '&:active': {
              backgroundColor: baseColors.purple300,
            },

            '&.FDBSegmentedPicker__tab--fullWidth': {
              minWidth: '100px',
              maxWidth: 'unset',
            },

            [theme.breakpoints.down('large')]: {
              minWidth: 'unset',
              flexGrow: '1',

              '& .MuiTypography-root': {
                maxWidth: '260px',
                whiteSpace: 'nowrap',
                overflowX: 'hidden',
                textOverflow: 'ellipsis',
              },
            },

            '& .Mui-selected': {
              fontWeight: `600 !important`,
            },

            '&.Mui-focusVisible': {
              outline: 'none',
              border: 'none',
              boxShadow: `0px 0px 0px 2px ${baseColors.accent500} inset`,

              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },

            [theme.breakpoints.up('large')]: {
              '&:not(:last-of-type)': {
                '&:after': {
                  content: '""',
                  right: '-4px',
                  position: 'absolute',
                  height: '12px',
                  width: '1px',
                  top: '12px',
                  backgroundColor: baseColors.mono400,
                },
              },
            },
          },
        },

        '&.FDBTabs': {
          borderBottom: `solid 1px ${baseColors.mono400}`,
          borderRadius: '8px 8px 0 0',
          height: '48px',
          minHeight: 'unset',
          overflow: 'visible',

          '&--hideActiveLine': {
            '& .MuiTabs-indicator': {
              display: 'none',
            },
          },

          '& .MuiTabs-indicator': {
            height: '3px',
            backgroundColor: baseColors.purple500,
          },

          '& .FDBTabs__tab.MuiTab-root': {
            color: baseColors.mono900,
            textTransform: 'unset',
            borderRadius: '8px 8px 0 0',
            minHeight: 'unset',
            // height and bottom to allow focus outline to show on the top
            height: '46px',
            bottom: '-2px',
            overflow: 'visible',
            position: 'relative',
            maxWidth: 'unset',
            minWidth: '60px',
            boxSizing: 'border-box',
            border: '2px solid',
            borderBottom: 'none',
            borderColor: 'transparent',

            '&.Mui-selected:not([aria-disabled="true"])': {
              '& .MuiTypography-root': {
                fontWeight: typography.bodyBold.fontWeight,
              },
            },

            // replacement for the ripple effect
            // static grey instead
            '&.FDBTabs__tab': {
              '&:hover:not([aria-disabled="true"])': {
                color: baseColors.purple500,
                backgroundColor: baseColors.purple100,
                borderColor: baseColors.purple100,
              },
              '&:active': {
                backgroundColor: baseColors.mono100,

                '&[aria-disabled="true"]': {
                  backgroundColor: baseColors.mono0,
                },
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },

            '&.FDBTabs__tab--scrollable': {
              paddingLeft: '24px',
              paddingRight: '24px',
            },

            '&.FDBTabs__tab:active:not([aria-disabled="true"])': {
              backgroundColor: baseColors.purple300,
              borderColor: baseColors.purple300,

              '& .FDBIcon': {
                zIndex: 3,
              },
            },

            '&.Mui-focusVisible': {
              border: `2px solid`,
              borderColor: `${baseColors.accent500} !important`,
              borderBottom: 'none',
              borderRadius: '8px 8px 0 0',
              color: baseColors.purple500,
              // boxShadow to get the bottom line to show when focus visible
              boxShadow: `0px -2px 0px ${baseColors.accent500} inset`,

              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },

            '&[aria-disabled="true"], &[aria-disabled="true"].Mui-selected': {
              cursor: 'not-allowed',
              backgroundColor: 'transparent',
              color: baseColors.mono500,
              opacity: 1,
            },
          },
        },
      }),
    },
  },
};
