import { ROLE_RECEIVER_TYPE } from '../constants';
export const formatSelectedRolesForTable = (policyRoles, locationRoles) => {
  if (!policyRoles || !locationRoles) return [];
  const displayReceivers = [];
  policyRoles.forEach(roleId => {
    const locationRole = locationRoles.find(r => r.id === roleId);
    if (locationRole) displayReceivers.push(locationRole.name);
  });
  return displayReceivers;
};

export const getPolicyRolesFromReceivers = policyReceivers =>
  policyReceivers
    ?.filter(receiver => receiver.receiver_type === ROLE_RECEIVER_TYPE)
    ?.map(r => r.receiver_id) || [];

export const getReceiverIdFromRoleId = (policyReceivers, roleId) => {
  if (!policyReceivers || !roleId) return null;
  const role = policyReceivers.find(
    r => r.receiver_id === roleId && r.receiver_type === ROLE_RECEIVER_TYPE
  );
  return role?.id;
};
