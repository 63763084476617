import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgEdit = ({
  alt = 'Edit Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M19.79 10.34 8.846 21.277l-1.59-1.59.164-.165H5.256a.753.753 0 0 1-.75-.75v-2.164l-.165.164a1.905 1.905 0 0 0-.473.798l-1.079 3.67 3.668-1.08c.259-.089.573-.253.798-.474l1.591 1.591a4.115 4.115 0 0 1-1.755 1.042l-5.647 1.662a1.06 1.06 0 0 1-1.114-.324c-.292-.249-.4-.676-.284-1.075l1.66-5.646a4.138 4.138 0 0 1 1.043-1.755L13.689 4.24l6.101 6.1Zm3.332-7.582a3.005 3.005 0 0 1 0 4.25l-2.271 2.27-6.101-6.1L17.02.909a3.008 3.008 0 0 1 4.252 0l1.85 1.85Z"
    />
  </svg>
);
export default SvgEdit;
