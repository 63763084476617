import { fromJS } from 'immutable';

import { actionTypes } from 'actions/segmentedOnboarding';

import { SEGMENTED_ONBOARDING_DEFAULTS as defaults } from 'features/signUp/modals/SegmentedOnboardingModals/constants';

const INITIAL_STATES = fromJS({
  departments: defaults.departments,
  schedule: defaults.schedule,
  scheduleEnforcement: defaults.scheduleEnforcement,
  getMobileApp: defaults.getMobileApp,
  managerLog: defaults.managerLog,
  scheduleSettings: defaults.scheduleSettings,
  saveMoney: defaults.saveMoney,
  roles: defaults.roles,
  timeTrackingAlerts: defaults.timeTrackingAlerts,
  employees: defaults.employees,
});

export default (state = INITIAL_STATES, action) => {
  switch (action.type) {
    case actionTypes.SAVE_SEGMENTED_ONBOARDING_STEP:
      return state.set(action.payload.step, action.payload.data);
    default:
      return state;
  }
};
