import { moment } from 'util/dateTime';

export const isValidInputFormat = (typedDate: string): boolean => {
  if (typedDate.length < 6) {
    return false; // Invalid if length is not at least m-d-yyyy length
  }
  const allowedFormats = [
    'MMMM D YYYY',
    'MMMM D, YYYY',
    'MMM D YYYY',
    'MMM D, YYYY',
    'MMMM DD YYYY',
    'MMMM DD, YYYY',
    'MMM DD YYYY',
    'MMM DD, YYYY',
    'MM-DD-YYYY',
    'MM-D-YYYY',
    'M-DD-YYYY',
    'M-D-YYYY',
    'MM-DD-YY',
    'MM-D-YY',
    'M-DD-YY',
    'M-D-YY',
    'MM/DD/YYYY',
    'MM/D/YYYY',
    'M/DD/YYYY',
    'M/D/YYYY',
    'MM/DD/YY',
    'MM/D/YY',
    'M/DD/YY',
    'M/D/YY',
  ];

  return allowedFormats.some(format => {
    const date = moment(typedDate, format);
    return (
      date.isValid() &&
      date.format(format).toLowerCase() === typedDate.toLowerCase()
    );
  });
};

// duplicate keys for date_time_formats.yml because df() uses getHBFormat() not avail in admin
const PRETTY_LONG_WDAY = 'dddd, MMM DD, YYYY';
const PRETTY_NO_WDAY = 'MMM DD, YYYY';
export const PARSABLE_REVERSED = 'YYYY-MM-DD';
export const PARSABLE_DATE = 'MM-DD-YYYY';
export const parseDate = (
  val: string | undefined,
  displayWeekday: boolean | undefined
): string => {
  if (!val) return '';

  const displayFormat = displayWeekday ? PRETTY_LONG_WDAY : PRETTY_NO_WDAY;
  return moment(val, PARSABLE_REVERSED).format(displayFormat);
};

export const validateDateFormat = (date: string | undefined): boolean => {
  if (!date) return false;
  return /^\d{4}-\d{2}-\d{2}$/.test(date);
};
