import * as routes from 'api';

import * as tiersSelectors from 'selectors/departments';

import * as constants from 'features/departments/constants';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import * as reduxUtil from 'util/redux';

export const actionTypes = {
  FETCH_DEPARTMENTS_DATA_REQUEST: 'DEPARTMENTS/FETCH_DEPARTMENTS_DATA_REQUEST',
  FETCH_DEPARTMENTS_DATA_SUCCESS: 'DEPARTMENTS/FETCH_DEPARTMENTS_DATA_SUCCESS',
  FETCH_DEPARTMENTS_DATA_FAILURE: 'DEPARTMENTS/FETCH_DEPARTMENTS_DATA_FAILURE',
  ADD_DEPARTMENT_ROLE_REQUEST: 'DEPARTMENTS/ADD_DEPARTMENT_ROLE_REQUEST',
  ADD_DEPARTMENT_ROLE_SUCCESS: 'DEPARTMENTS/ADD_DEPARTMENT_ROLE_SUCCESS',
  ADD_DEPARTMENT_ROLE_FAILURE: 'DEPARTMENTS/ADD_DEPARTMENT_ROLE_FAILURE',
  REMOVE_DEPARTMENT_ROLE_REQUEST: 'DEPARTMENTS/REMOVE_DEPARTMENT_ROLE_REQUEST',
  REMOVE_DEPARTMENT_ROLE_SUCCESS: 'DEPARTMENTS/REMOVE_DEPARTMENT_ROLE_SUCCESS',
  REMOVE_DEPARTMENT_ROLE_FAILURE: 'DEPARTMENTS/REMOVE_DEPARTMENT_ROLE_FAILURE',
  ADD_DEPARTMENT_REQUEST: 'DEPARTMENTS/ADD_DEPARTMENT_REQUEST',
  ADD_DEPARTMENT_SUCCESS: 'DEPARTMENTS/ADD_DEPARTMENT_SUCCESS',
  ADD_DEPARTMENT_FAILURE: 'DEPARTMENTS/ADD_DEPARTMENT_FAILURE',
  REMOVE_DEPARTMENT_REQUEST: 'DEPARTMENTS/REMOVE_DEPARTMENT_REQUEST',
  REMOVE_DEPARTMENT_SUCCESS: 'DEPARTMENTS/REMOVE_DEPARTMENT_SUCCESS',
  REMOVE_DEPARTMENT_FAILURE: 'DEPARTMENTS/REMOVE_DEPARTMENT_FAILURE',
  UPDATE_DEPARTMENT_REQUEST: 'DEPARTMENTS/UPDATE_DEPARTMENT_REQUEST',
  UPDATE_DEPARTMENT_SUCCESS: 'DEPARTMENTS/UPDATE_DEPARTMENT_SUCCESS',
  UPDATE_DEPARTMENT_FAILURE: 'DEPARTMENTS/UPDATE_DEPARTMENT_FAILURE',
  ADD_DEPARTMENT_MANAGER_REQUEST: 'DEPARTMENTS/ADD_DEPARTMENT_MANAGER_REQUEST',
  ADD_DEPARTMENT_MANAGER_SUCCESS: 'DEPARTMENTS/ADD_DEPARTMENT_MANAGER_SUCCESS',
  ADD_DEPARTMENT_MANAGER_FAILURE: 'DEPARTMENTS/ADD_DEPARTMENT_MANAGER_FAILURE',
  DEPARTMENTS_BATCH_CREATE_REQUEST:
    'DEPARTMENTS/DEPARTMENTS_BATCH_CREATE_REQUEST',
  DEPARTMENTS_BATCH_CREATE_SUCCESS:
    'DEPARTMENTS/DEPARTMENTS_BATCH_CREATE_SUCCESS',
  DEPARTMENTS_BATCH_CREATE_FAILURE:
    'DEPARTMENTS/DEPARTMENTS_BATCH_CREATE_FAILURE',
  REMOVE_DEPARTMENT_MANAGER_REQUEST:
    'DEPARTMENTS/REMOVE_DEPARTMENT_MANAGER_REQUEST',
  REMOVE_DEPARTMENT_MANAGER_SUCCESS:
    'DEPARTMENTS/REMOVE_DEPARTMENT_MANAGER_SUCCESS',
  REMOVE_DEPARTMENT_MANAGER_FAILURE:
    'DEPARTMENTS/REMOVE_DEPARTMENT_MANAGER_FAILURE',
};

const fetchDepartmentsDataMeta = {
  requestId: constants.REQUEST_KEYS.departmentsData,
};

export const fetchDepartmentsData = () =>
  reduxUtil.createAsyncGetAction(
    routes.departmentsRoute(),
    [
      {
        type: actionTypes.FETCH_DEPARTMENTS_DATA_REQUEST,
        meta: fetchDepartmentsDataMeta,
      },
      {
        type: actionTypes.FETCH_DEPARTMENTS_DATA_SUCCESS,
        meta: fetchDepartmentsDataMeta,
      },
      {
        type: actionTypes.FETCH_DEPARTMENTS_DATA_FAILURE,
        meta: fetchDepartmentsDataMeta,
      },
    ],
    {
      bailout: state =>
        tiersSelectors.getFetchDepartmentsDataShouldBailout(state),
    }
  );

export const addDepartmentRole = (departmentId, name) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.departmentRolesRoute(departmentId),
      [
        {
          type: actionTypes.ADD_DEPARTMENT_ROLE_REQUEST,
          meta: { departmentId, name },
        },
        {
          type: actionTypes.ADD_DEPARTMENT_ROLE_SUCCESS,
          meta: { departmentId, name },
        },
        {
          type: actionTypes.ADD_DEPARTMENT_ROLE_FAILURE,
          meta: { departmentId, name },
        },
      ],
      {
        body: { role: { name, department_id: departmentId } },
      }
    ),
    {
      onSuccess: response => {
        if (response.payload.new_record) {
          flashNotice.success(
            toI18n('schedule_builder.departments.add_role_success')
          );
        } else {
          flashNotice.info(
            toI18n('schedule_builder.departments.role_already_exists')
          );
        }
      },
      error: toI18n('schedule_builder.departments.add_role_error'),
    }
  );

export const removeDepartmentRole = (departmentId, role) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncDeleteAction(routes.roleRoute(role.get('id')), [
      {
        type: actionTypes.REMOVE_DEPARTMENT_ROLE_REQUEST,
        meta: { departmentId, role },
      },
      {
        type: actionTypes.REMOVE_DEPARTMENT_ROLE_SUCCESS,
        meta: { departmentId, role },
      },
      {
        type: actionTypes.REMOVE_DEPARTMENT_ROLE_FAILURE,
        meta: { departmentId, role },
      },
    ]),
    {
      success: toI18n('schedule_builder.departments.remove_role_success'),
      error: toI18n('schedule_builder.departments.remove_role_error'),
    }
  );

export const batchSaveDepartments = (departments, onSuccess) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.departmentsBatchCreateRoute(),
      [
        actionTypes.DEPARTMENTS_BATCH_CREATE_REQUEST,
        actionTypes.DEPARTMENTS_BATCH_CREATE_SUCCESS,
        actionTypes.DEPARTMENTS_BATCH_CREATE_FAILURE,
      ],
      {
        body: { departments },
      }
    ),
    { onSuccess }
  );

export const addDepartmentManager = (departmentId, user) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPostAction(
      routes.departmentManagersRoute(departmentId, user.get('id')),
      [
        {
          type: actionTypes.ADD_DEPARTMENT_MANAGER_REQUEST,
          meta: { departmentId, user },
        },
        {
          type: actionTypes.ADD_DEPARTMENT_MANAGER_SUCCESS,
          meta: { departmentId, user },
        },
        {
          type: actionTypes.ADD_DEPARTMENT_MANAGER_FAILURE,
          meta: { departmentId, user },
        },
      ],
      {
        body: { job_id: user.get('id') },
      }
    ),
    {
      success: toI18n('schedule_builder.departments.add_manager_success'),
      error: toI18n('schedule_builder.departments.add_manager_error'),
    }
  );

export const removeDepartmentManager = (departmentId, user) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncDeleteAction(
      routes.departmentManagersRoute(departmentId, user.get('id')),
      [
        {
          type: actionTypes.REMOVE_DEPARTMENT_MANAGER_REQUEST,
          meta: { departmentId, user },
        },
        {
          type: actionTypes.REMOVE_DEPARTMENT_MANAGER_SUCCESS,
          meta: { departmentId, user },
        },
        {
          type: actionTypes.REMOVE_DEPARTMENT_MANAGER_FAILURE,
          meta: { departmentId, user },
        },
      ]
    ),
    {
      success: toI18n('schedule_builder.departments.remove_manager_success'),
      error: toI18n('schedule_builder.departments.remove_manager_error'),
    }
  );

export const addDepartment = name => dispatch =>
  dispatch(
    reduxUtil.withAlerts(
      reduxUtil.createAsyncPostAction(
        routes.departmentsRoute(),
        [
          { type: actionTypes.ADD_DEPARTMENT_REQUEST },
          { type: actionTypes.ADD_DEPARTMENT_SUCCESS },
          { type: actionTypes.ADD_DEPARTMENT_FAILURE },
        ],
        {
          body: { department: { name } },
        }
      ),
      {
        success: () => {
          toI18n('schedule_builder.departments.add_dep_success');
        },
        error: toI18n('schedule_builder.departments.add_dep_error'),
      }
    )
  );

export const removeDepartment = department =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncDeleteAction(
      routes.departmentRoute(department.get('id')),
      [
        { type: actionTypes.REMOVE_DEPARTMENT_REQUEST, meta: { department } },
        { type: actionTypes.REMOVE_DEPARTMENT_SUCCESS, meta: { department } },
        { type: actionTypes.REMOVE_DEPARTMENT_FAILURE, meta: { department } },
      ]
    ),
    {
      success: toI18n('schedule_builder.departments.remove_dep_success'),
      error: toI18n('schedule_builder.departments.remove_dep_error'),
    }
  );

export const updateDepartment = (department, attrs) =>
  reduxUtil.withAlerts(
    reduxUtil.createAsyncPutAction(
      routes.departmentRoute(department.get('id')),
      [
        {
          type: actionTypes.UPDATE_DEPARTMENT_REQUEST,
          meta: { department, attrs },
        },
        {
          type: actionTypes.UPDATE_DEPARTMENT_SUCCESS,
          meta: { department, attrs },
        },
        {
          type: actionTypes.UPDATE_DEPARTMENT_FAILURE,
          meta: { department, attrs },
        },
      ],
      {
        body: { department: attrs },
      }
    ),
    {
      success: toI18n('schedule_builder.departments.update_dep_success'),
      error: toI18n('schedule_builder.departments.update_dep_error'),
    }
  );
