import { Map } from 'immutable';

import {
  getCurrentLocationId,
  getCurrentLocationName,
} from 'selectors/session';
import { getEmployees, getRoles } from 'selectors/timesheets';

import { duration, toMoment } from 'features/timesheets/util';

import {
  initTimeDurationUxEvent,
  trackTimeDurationUxEvent,
} from 'util/tracking';
import {
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';

import { NO_DURATION, NO_HOURS, TIME_FORMAT } from './constants';

export const convertOldShiftDataToNewFormat = (shift, state) => {
  const roles = getRoles(state);
  const locationId = getCurrentLocationId(state);
  const locationName = getCurrentLocationName(state);
  const employees = getEmployees(state);

  return new Map({
    id: shift.get('id'),
    date: shift.get('date'),
    scheduled: shift.get('scheduled'),
    job_id: shift.get('job_id'),
    employee_name: employees[shift.get('job_id')]
      ? employees[shift.get('job_id')].get('full_name')
      : null,
    employee_first_name: employees[shift.get('job_id')]
      ? employees[shift.get('job_id')].get('first_name')
      : null,

    role_id: shift.get('role_id'),
    role_name: roles[shift.get('role_id')]
      ? roles[shift.get('role_id')].get('name')
      : null,
    location_id: locationId,
    location_name: locationName,
    locked_message: shift.get('approved') ? 'Timecard is locked' : null,
    actual_start_at: shift.get('actual_start_at'),
    actual_end_at: shift.get('actual_end_at'),
  });
};

export const getTimecardDuration = (startAt, endAt) => {
  const startTime = toMoment(startAt);
  const endTime = toMoment(endAt);
  if (startTime.isValid() && endTime.isValid()) {
    return duration(endTime.diff(startTime, 'minutes'));
  }

  return NO_DURATION;
};

export const formattedScheduledTime = (scheduled, startAt, endAt) => {
  if (scheduled) {
    const startTime = toMoment(startAt);
    const endTime = toMoment(endAt);
    return `${duration(endTime.diff(startTime, 'minutes'))} (${startAt.format(
      TIME_FORMAT
    )} - ${endAt.format(TIME_FORMAT)})`;
  }

  return NO_HOURS;
};

export const DEFAULT_VALIDATION_ERRORS = {
  general: [],
  timecard_errors: [],
  timebreaks_errors: {
    paid: [],
    unpaid: [],
  },
};

const TRACK_ISSUE_EVENTS = {
  no_show: 'No-show',
  late: 'Late',
  over_scheduled: 'Overscheduled',
  missing_clock_out: 'Missing clock-out',
  unscheduled_shift: 'Unscheduled shift',
  employee_edit: 'Employee Edit',
  missing_paid_breaks: 'Missing Breaks',
  missing_unpaid_breaks: 'Missing Breaks',
  employee_added_clock_out: 'Employee Edit',
};

const uxEventAttrs = (issue, key, startingPath, suffix) => ({
  actionType: TRACK_ACTION_TYPES.CLICK,
  productArea: PRODUCT_AREAS.TIMESHEETS,
  eventCategory: EVENT_CATEGORIES.TIMECARD_DETAIL,
  eventAction: `${issue} resolve time duration_${suffix}`,
  key,
  properties: {
    starting_path: startingPath,
  },
});

export const handleResolveIssuesTracking = (
  currentIssues,
  previousIssues,
  key,
  startingPath,
  suffix
) => {
  // Initialize tracking for new issues
  currentIssues.forEach(i => {
    if (previousIssues.includes(i)) {
      return;
    }

    if (TRACK_ISSUE_EVENTS[i]) {
      initTimeDurationUxEvent(
        uxEventAttrs(TRACK_ISSUE_EVENTS[i], key, startingPath, suffix)
      );
    }
  });

  // Trigger tracking events for issues that has been resolved
  previousIssues.forEach(i => {
    if (currentIssues.includes(i)) {
      return;
    }

    if (TRACK_ISSUE_EVENTS[i]) {
      trackTimeDurationUxEvent(
        uxEventAttrs(TRACK_ISSUE_EVENTS[i], key, startingPath, suffix)
      );
    }
  });
};

export function determineContext(originPath) {
  return originPath.includes('daily_review') ? 'daily_review' : 'timesheets';
}
