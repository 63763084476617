import React from 'react';
import Icon from 'react-icon-base';

const UserPlus = props => (
  <Icon viewBox="0 0 16 16" {...props}>
    <g>
      <path
        fill="#969BA0"
        d="M14.625 4.875c.11 0 .195.04.258.117.078.063.117.149.117.258V6c0 .11-.04.203-.117.281-.063.063-.149.094-.258.094h-1.5v1.5c0 .11-.04.203-.117.281-.063.063-.149.094-.258.094H12c-.11 0-.203-.031-.281-.094-.063-.078-.094-.172-.094-.281v-1.5h-1.5c-.11 0-.203-.031-.281-.094C9.78 6.203 9.75 6.11 9.75 6v-.75c0-.11.031-.195.094-.258.078-.078.172-.117.281-.117h1.5v-1.5c0-.11.031-.195.094-.258C11.797 3.04 11.89 3 12 3h.75c.11 0 .195.04.258.117.078.063.117.149.117.258v1.5h1.5zm-7.266.258C6.781 5.71 6.08 6 5.25 6c-.828 0-1.54-.29-2.133-.867C2.54 4.539 2.25 3.828 2.25 3c0-.828.29-1.531.867-2.11C3.711.298 4.422 0 5.25 0c.828 0 1.531.297 2.11.89.593.579.89 1.282.89 2.11 0 .828-.297 1.54-.89 2.133zm0 1.617c.86 0 1.594.313 2.204.938.624.609.937 1.343.937 2.203v.984c0 .313-.11.578-.328.797-.219.219-.485.328-.797.328h-8.25c-.313 0-.578-.11-.797-.328C.11 11.453 0 11.187 0 10.875v-.984c0-.86.305-1.594.914-2.204.625-.625 1.367-.937 2.227-.937h.398c.547.25 1.117.375 1.711.375.594 0 1.164-.125 1.71-.375h.4z"
      />
    </g>
  </Icon>
);

export default UserPlus;
