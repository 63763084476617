import React from 'react';
import Icon from 'react-icon-base';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

const ClockOutCircle = ({ color, height, width, ...props }) => (
  <Icon
    width={width || '36'}
    height={height || '36'}
    viewBox="0 0 36 36"
    {...props}
  >
    <path
      fill={color || colors.purple}
      fillRule="evenodd"
      stroke={color || colors.purple}
      strokeWidth=".75"
      d="M1.984 16.23c0-7.27 5.75-13.164 12.841-13.164 6.977 0 12.653 5.703 12.837 12.808h.926c-.184-7.63-6.275-13.756-13.763-13.756C7.222 2.118 1.06 8.436 1.06 16.23c0 6.262 3.978 11.572 9.486 13.417v-1.002c-4.988-1.807-8.56-6.683-8.56-12.415z"
      clipRule="evenodd"
    />
    <path
      fill={color || colors.purple}
      fillRule="evenodd"
      stroke={color || colors.purple}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth=".75"
      d="M14.258 7.412c.312 0 .565.21.565.47v9.647c0 .26-.253.471-.565.471h-7.34c-.313 0-.565-.21-.565-.47s.252-.471.564-.471h6.777V7.882c0-.26.253-.47.564-.47zM22.72 18.963c-5.004 0-9.06 4.094-9.06 9.144v5.294a.48.48 0 0 1-.477.481.479.479 0 0 1-.477-.48v-5.295C12.706 22.525 17.19 18 22.72 18h7.51a.48.48 0 0 1 .477.481.48.48 0 0 1-.477.482h-7.51z"
      clipRule="evenodd"
    />
    <path
      fill={color || colors.purple}
      fillRule="evenodd"
      stroke={color || colors.purple}
      strokeLinecap="square"
      strokeMiterlimit="16"
      strokeWidth="1.5"
      d="M30.77 20.442l3.113-1.842-3.113-1.843v3.685z"
      clipRule="evenodd"
    />
  </Icon>
);

ClockOutCircle.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

export default ClockOutCircle;
