import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSafety = ({
  alt = 'Safety Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M2.667 2.7A2.67 2.67 0 0 0 0 5.367V18.7a2.67 2.67 0 0 0 2.667 2.667H4V2.7H2.667Zm2.666 0v18.667h13.334V2.7H5.333Zm16 18.667A2.67 2.67 0 0 0 24 18.7V5.367A2.67 2.67 0 0 0 21.333 2.7H20v18.667h1.333ZM10.667 8.7c0-.366.3-.666.666-.666h1.334c.366 0 .666.3.666.666v2h2c.367 0 .667.3.667.667V12.7c0 .367-.3.667-.667.667h-2v2c0 .366-.3.666-.666.666h-1.334a.669.669 0 0 1-.666-.666v-2h-2A.669.669 0 0 1 8 12.7v-1.333c0-.367.3-.667.667-.667h2v-2Z"
    />
  </svg>
);
export default SvgSafety;
