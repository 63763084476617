import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgRefresh = ({
  alt = 'Refresh Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M4.927 9.497a7.423 7.423 0 0 1 1.771-2.803 7.5 7.5 0 0 1 10.608 0l.802.806H15.75c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h5.995c.83 0 1.5-.67 1.5-1.5V3c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v2.4l-.82-.825C15.323.474 8.677.474 4.575 4.575A10.44 10.44 0 0 0 2.1 8.503a1.5 1.5 0 0 0 2.827.999v-.005Zm-3.099 4.064c-.234.07-.46.197-.642.384a1.458 1.458 0 0 0-.417.835 1.37 1.37 0 0 0-.019.239V21c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-2.395l.825.82c4.102 4.097 10.748 4.097 14.845 0a10.472 10.472 0 0 0 2.48-3.923 1.5 1.5 0 0 0-2.827-.999 7.422 7.422 0 0 1-1.771 2.803 7.5 7.5 0 0 1-10.608 0l-.005-.004-.801-.802H8.25c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H2.269c-.075 0-.15.005-.225.014-.075.01-.146.024-.216.047Z"
    />
  </svg>
);
export default SvgRefresh;
