import './NextIntegrationDialog.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import IconButton from 'fe-design-base/molecules/IconButton';
import Dialog from 'fe-design-base/organisms/Dialog';
import { useScript } from 'hooks/useScript';

import { actions as monetizationActions } from 'features/monetization/NextIntegrationDialog/slice';

import { cxHelpers } from 'util/className';
import * as flashNotice from 'util/flashNotice';
const { cx } = cxHelpers('NextIntegrationDialog');

export const NextIntegrationDialog = ({ closeNextIntegrationDialog }) => {
  const [quoteData, setQuoteData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { status, scriptElement } = useScript(
    window.Homebase.env === 'production'
      ? 'https://app.nextinsurance.com/ni-sdk.bundle.js'
      : 'https://uat.nextinsurance.com/ni-sdk.bundle.js'
  );

  const handleClickOnClose = useCallback(() => {
    scriptElement.remove();
    closeNextIntegrationDialog();
  }, [closeNextIntegrationDialog, scriptElement]);

  useEffect(() => {
    postJSON('/monetization/next/affiliate_data')
      .then(({ data }) => setQuoteData(data))
      .catch(e => setErrorMessage(e.message));
  }, []);

  useEffect(() => {
    if (errorMessage) {
      flashNotice.error(errorMessage);
      handleClickOnClose();
    }
  }, [errorMessage, handleClickOnClose]);

  if (!quoteData) return null;

  return (
    <Dialog
      open
      noPadding
      hideCloseIcon
      disableBackdropClick
      uxElement="next-integration-dialog"
    >
      {() => (
        <Box
          h={645}
          w={1000}
          bgcolor="mono0"
          className={cx()}
          overflow="hidden"
        >
          <Box absolute top={14} right={14}>
            <IconButton
              icon="Close"
              size="small"
              onClick={handleClickOnClose}
              dataTestId="next-integration-dialog-close-icon"
            />
          </Box>
          {status === 'ready' && quoteData.signature && (
            <div
              data-embed="true"
              data-env={quoteData.env}
              className="next-insurance-button"
              data-testid="next-insurance-button"
              data-signature={quoteData.signature}
              data-timestamp={quoteData.timestamp}
              data-affiliate-name={quoteData.affiliate_name}
              data-partner-request-id={quoteData.partner_request_id}
              // If prefill_token exists, it means the quote has not been created on NEXT yet
              {...(quoteData.prefill_token
                ? {
                    'data-pft': quoteData.prefill_token,
                    'data-pfr': quoteData.prefill_request,
                  }
                : {})}
            />
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default connect(null, {
  closeNextIntegrationDialog: monetizationActions.closeNextIntegrationDialog,
})(NextIntegrationDialog);
