import React, { PureComponent } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { parsedCreatedAt } from 'util/dateTime';
import { toI18n } from 'util/i18n';

import BaseListItem from '../BaseListItem';
import * as constants from '../constants';

@cxHelpers('BaseListItem')
export default class RequestsListItem extends PureComponent {
  static propTypes = {
    item: ImmutablePropTypes.map.isRequired,
    bg: PropTypes.string,
    acceptButtonTheme: PropTypes.string,
    acceptButtonCopy: PropTypes.string.isRequired,
    declineButtonCopy: PropTypes.string.isRequired,
    showAcceptButton: PropTypes.bool,
    showDeclineButton: PropTypes.bool,
    hideDate: PropTypes.bool,
    bodyProps: PropTypes.object,
    body: PropTypes.string,
    icon: PropTypes.string,
    titleI18n: PropTypes.string,
    onDecline: PropTypes.func,
    onApprove: PropTypes.func,
  };

  static defaultProps = {
    hideDate: false,
    acceptButtonTheme: null,
    acceptButtonCopy: toI18n('actions.approve'),
    declineButtonCopy: toI18n('actions.decline'),
    bodyProps: {},
    bg: null,
    showAcceptButton: true,
    showDeclineButton: true,
  };

  createdAtDate() {
    if (this.props.hideDate) {
      return null;
    }

    return parsedCreatedAt(this.props.item);
  }

  handleApprove = event => {
    event.stopPropagation();
    this.props.onApprove();
  };

  handleDecline = event => {
    event.stopPropagation();
    this.props.onDecline();
  };

  renderDeclineButton = () => (
    <Button
      className={this.cxEl('decline')}
      onClick={this.handleDecline}
      theme={this.props.declineButtonTheme || 'secondary-blue'}
    >
      <Text fs16>{this.props.declineButtonCopy}</Text>
    </Button>
  );

  renderApproveButton = () => (
    <Button
      className={this.cxEl('approve')}
      onClick={this.handleApprove}
      theme={this.props.acceptButtonTheme || 'primary'}
      size="small"
    >
      <Text fs16 ptxxs pbxxs pls prs>
        {this.props.acceptButtonCopy}
      </Text>
    </Button>
  );

  renderReason = () => (
    <Box vtop mts row>
      <Text fs14>"{this.props.item.get('reason').trim()}"</Text>
    </Box>
  );

  render() {
    const leftBasis = this.props.icon ? constants.LEFT_COLUMN_WIDTH : 0;
    const shouldShowReason = this.props.item.get('reason')
      ? this.props.item.get('reason').trim() !== ''
      : false;

    return (
      <BaseListItem
        bg={this.props.bg}
        icon={this.props.icon}
        titleI18n={
          this.props.titleI18n ||
          `notifications.requests.${this.props.item.get('type')}.title`
        }
        date={this.createdAtDate()}
      >
        <Box vtop>
          <Box basis={leftBasis} shrink={0} />

          <Box grow>
            <Text fs14 {...this.props.bodyProps}>
              {this.props.body}
            </Text>
          </Box>
        </Box>
        {shouldShowReason && this.renderReason()}
        <Box mts row spacebetween>
          {this.props.showDeclineButton && this.renderDeclineButton()}
          {this.props.showAcceptButton && this.renderApproveButton()}
        </Box>
      </BaseListItem>
    );
  }
}
