import { useMediaQuery } from 'react-responsive';
import breakpoints from 'fe-design-base/styles/breakpoints';

export const useIsSmallScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.smallBreakpoint})` });
export const useIsMediumScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.mediumBreakpoint})` });
export const useIsMediumLargeScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.mediumLargeBreakpoint})` });
export const useIsLargeScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.largeBreakpoint})` });
export const useIsXLargeScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.xlargeBreakpoint})` });
export const useIsXXLargeScreen = () =>
  useMediaQuery({ query: `(min-width: ${breakpoints.xxlargeBreakpoint})` });
