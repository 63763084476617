export const PTD_TYPE_CHILD_SUPPORT = 'child_support';
export const PTD_TYPE_MISC = 'miscellaneous';
export const PTD_TYPE_CALSAVERS = 'calsavers';

export const PTD_TYPES_LABEL = {
  [PTD_TYPE_CHILD_SUPPORT]: 'Child Support Garnishment',
  [PTD_TYPE_MISC]: 'Miscellaneous',
  [PTD_TYPE_CALSAVERS]: 'CalSavers',
};

export const PTD_TYPE_OPTIONS = [
  { value: PTD_TYPE_CHILD_SUPPORT, label: PTD_TYPES_LABEL.child_support },
  { value: PTD_TYPE_MISC, label: PTD_TYPES_LABEL.miscellaneous },
];

export const PTD_TYPE_OPTIONS_WITH_CALSAVERS = [
  { value: PTD_TYPE_CHILD_SUPPORT, label: PTD_TYPES_LABEL.child_support },
  { value: PTD_TYPE_MISC, label: PTD_TYPES_LABEL.miscellaneous },
  { value: PTD_TYPE_CALSAVERS, label: PTD_TYPES_LABEL.calsavers },
];

export const NAME_FIELD_CHAR_LIMIT = 40;
export const INITIAL_VALUES = {
  amount_type: 'amount',
  contribution_type: 'maximum_amount',
};
export const MISC_TYPE = 'miscellaneous';
