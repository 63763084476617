import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgColorDribbble = ({
  alt = 'Dribble Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      d="M16.005 28.923c7.135 0 12.918-5.783 12.918-12.918 0-7.135-5.783-12.918-12.918-12.918-7.135 0-12.918 5.783-12.918 12.918 0 7.135 5.783 12.918 12.918 12.918Z"
      fill="#EA4C89"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.005 2.67C8.643 2.67 2.67 8.643 2.67 16.005c0 7.362 5.973 13.335 13.335 13.335 7.347 0 13.335-5.973 13.335-13.335 0-7.362-5.988-13.335-13.335-13.335Zm8.808 6.147a11.337 11.337 0 0 1 2.574 7.087c-.376-.073-4.136-.84-7.925-.362-.087-.188-.16-.39-.246-.593a33.878 33.878 0 0 0-.752-1.649c4.194-1.706 6.103-4.165 6.349-4.483Zm-8.808-4.18c2.893 0 5.54 1.085 7.55 2.864-.203.289-1.924 2.588-5.974 4.107-1.865-3.428-3.933-6.233-4.252-6.667.853-.203 1.75-.304 2.676-.304Zm-4.845 1.07c.303.405 2.328 3.225 4.223 6.58-5.322 1.418-10.023 1.39-10.53 1.39.738-3.53 3.125-6.466 6.307-7.97ZM4.608 16.02v-.347c.492.015 6.017.087 11.7-1.62a33.9 33.9 0 0 1 .926 1.938c-.144.044-.304.087-.448.13-5.872 1.895-8.996 7.073-9.256 7.507a11.364 11.364 0 0 1-2.922-7.608Zm11.397 11.383A11.31 11.31 0 0 1 9.019 25c.203-.42 2.517-4.874 8.938-7.116.03-.014.044-.014.073-.029 1.605 4.151 2.256 7.637 2.43 8.635a11.204 11.204 0 0 1-4.455.91Zm6.35-1.953c-.117-.694-.724-4.02-2.214-8.114 3.573-.564 6.697.362 7.087.492-.491 3.168-2.314 5.901-4.874 7.622Z"
      fill="#C32361"
    />
  </svg>
);
export default SvgColorDribbble;
