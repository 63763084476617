export const getColumnValue = (state, props) =>
  state.getIn([
    'bulkTerminate',
    'selectedEmployees',
    props.rowIndex,
    props.column,
    'value',
  ]);

export const getColumnError = (state, props) =>
  state.getIn([
    'bulkTerminate',
    'selectedEmployees',
    props.rowIndex,
    props.column,
    'error',
  ]);

export const getRowsCount = state =>
  state.getIn(['bulkTerminate', 'selectedEmployees']).size;

export const getHasErrors = state =>
  state
    .getIn(['bulkTerminate', 'selectedEmployees'])
    .some(e => e.some(cell => cell.get('error')));

export const getShowTooltip = state =>
  state.getIn(['bulkTerminate', 'showTooltip']);
