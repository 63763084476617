import React from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';

interface Props {
  action?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  forceStacked?: boolean;
}

const AlertActions = ({
  action,
  secondaryAction,
  forceStacked,
  cx,
}: Props & CxProps) =>
  action ? (
    <Box
      ml={forceStacked ? 0 : 16}
      mt={forceStacked ? 16 : 0}
      row
      vcenter
      className={cx({ multiple: secondaryAction })}
    >
      {action}
      {secondaryAction && <Box ml={16}>{secondaryAction}</Box>}
    </Box>
  ) : null;

export default withCx<Props>('FDBAlertActions')(AlertActions);
