import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgChevronRight = ({
  alt = 'Chevron Right Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M17.719 12.73 7.9 23.64a1.09 1.09 0 1 1-1.621-1.462L15.442 12 6.28 1.82A1.094 1.094 0 0 1 6.36.28a1.092 1.092 0 0 1 1.541.08l9.817 10.849c.376.478.376 1.105 0 1.52Z"
    />
  </svg>
);
export default SvgChevronRight;
