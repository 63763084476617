import { createSlice } from '@reduxjs/toolkit';

import { RESOURCES_SLICE } from '../module-constants';

import { create, destroy, get, post, update } from './actions';

const setResourceStateForAction = (state, action, actionName, data) => {
  const id = action.meta.arg.id?.toString() || '1';
  state[action.meta.arg.name] ||= {};
  state[action.meta.arg.name][actionName] ||= {};
  state[action.meta.arg.name][actionName][id] = data;
};

const slice = createSlice({
  name: RESOURCES_SLICE,
  initialState: {},
  reducers: {},
  extraReducers: builder => {
    const actionNames = ['fetch', 'create', 'post', 'update', 'destroy'];
    [get, create, post, update, destroy].forEach((resourceAction, i) => {
      builder.addCase(resourceAction.pending, (state, action) => {
        setResourceStateForAction(state, action, actionNames[i], {
          pending: true,
          error: null,
        });
      });
      builder.addCase(resourceAction.fulfilled, (state, action) => {
        setResourceStateForAction(state, action, actionNames[i], {
          pending: false,
          error: null,
        });
      });
      builder.addCase(resourceAction.rejected, (state, action) => {
        setResourceStateForAction(state, action, actionNames[i], {
          pending: false,
          error: action.error.message,
        });
      });
    });
  },
});

export default slice;
