import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteJSON, fetchJSON, patchJSON, postJSON } from 'api/fetch';

import { TASK_MANAGER_SLICE_NAME } from 'features/taskManager/constants';
import {
  OnFetchTaskProps,
  PaginatedTaskResult,
  SingleTask,
  TaskList,
} from 'features/taskManager/index.interface';

export const createTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/createTask`,
  async (data: SingleTask): Promise<SingleTask> =>
    postJSON('/task_management/tasks', data)
);

export const updateSingleTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/updateSingleTask`,
  async (data: SingleTask): Promise<SingleTask> =>
    patchJSON(`/task_management/tasks/${data.id}`, data)
);

export const fetchTasks = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/fetchTasks`,
  async ({
    perPage = 20,
    pageNumber = 1,
  }: OnFetchTaskProps): Promise<PaginatedTaskResult> =>
    fetchJSON(
      `/task_management/manager_tasks?page=${pageNumber}&per_page=${perPage}`
    )
);

export const deleteSingleTask = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/deleteSingleTask`,
  async (id: string): Promise<number> => {
    await deleteJSON(`/task_management/tasks/${id}`);
    return Number(id);
  }
);

export const createTaskList = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/createTaskList`,
  async (data: TaskList) => postJSON('/task_management/lists', data)
);

export const updateTaskList = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/updateTaskList`,
  async (data: TaskList) => patchJSON(`/task_management/lists/${data.id}`, data)
);

export const deleteTaskList = createAsyncThunk(
  `${TASK_MANAGER_SLICE_NAME}/deleteTaskList`,
  async (id: number) => {
    await deleteJSON(`/task_management/lists/${id}`);
    return id;
  }
);
