import { actions as ADP } from './adp';
import { actions as AppleAppStore } from './AppleAppStore';
import { actions as Carbon } from './Carbon';
import { actions as Clover } from './clover';
import { actions as GooglePlayStore } from './GooglePlayStore';
import { actions as Poynt } from './poynt';
import { actions as Q2 } from './q2';
import { actions as Shopify } from './shopify';
import { actions as Square } from './square';
import { actions as Stripe } from './stripe';

export default {
  ADP,
  AppleAppStore,
  Clover,
  GooglePlayStore,
  Q2,
  Shopify,
  Square,
  Carbon,
  Stripe,
  Poynt,
};
