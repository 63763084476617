import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  sidePanelType: null,
  initiatedFrom: null,
  callback: null,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    openSidePanel: (
      state,
      { payload: { sidePanelType, initiatedFrom = null, callback = null } }
    ) =>
      state
        .set('sidePanelType', sidePanelType)
        .set('initiatedFrom', initiatedFrom)
        .set('callback', callback),
    closeSidePanel: state =>
      state
        .set('sidePanelType', null)
        .set('initiatedFrom', null)
        .set('callback', null),
  },
});

export const { actions, reducer } = slice;
