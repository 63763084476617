import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgLocation = ({
  alt = 'Location Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M10.884 23.46c-2.453-3.017-7.91-10.33-7.91-14.437a9.023 9.023 0 1 1 18.047 0c0 4.108-5.498 11.42-7.91 14.438a1.42 1.42 0 0 1-2.227 0Zm1.114-11.429a3.01 3.01 0 0 0 3.008-3.008 3.01 3.01 0 0 0-3.008-3.007A3.01 3.01 0 0 0 8.99 9.023a3.01 3.01 0 0 0 3.008 3.008Z"
    />
  </svg>
);
export default SvgLocation;
