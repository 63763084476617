import React from 'react';
import Icon from 'react-icon-base';

const Send = props => (
  <Icon viewBox="0 0 24 17" {...props}>
    <path
      d="M0.128906 16.1836L2.77734 8.25L0.128906 0.316406L23.9297 8.25L0.128906 16.1836ZM4.10156 7.5H16.9453L2.49609 2.68359L4.10156 7.5ZM2.49609 13.8164L16.9453 9H4.10156L2.49609 13.8164Z"
      fill="#313A43"
    />
  </Icon>
);

export default Send;
