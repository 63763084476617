import { Set } from 'immutable';

import { actionTypes as applicantMessengerActionTypes } from 'actions/applicantMessenger';
import { actionTypes as hiringActionTypes } from 'actions/hiring';
import { actionTypes as notificationActionTypes } from 'actions/notifications';
import { actionTypes as settingsActionTypes } from 'actions/settings';
import { actionTypes as teamEmailsActionTypes } from 'actions/teamEmails';
import { actionTypes as timecardActionTypes } from 'actions/timecard';

export default (state = Set(), action) => {
  switch (action.type) {
    case hiringActionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_REQUEST:
    case hiringActionTypes.FETCH_APPLICANT_DATA_REQUEST:
    case hiringActionTypes.HIRE_APPLICATION_REQUEST:
    case hiringActionTypes.FETCH_APPLICANT_PROFILE_REQUEST:
    case hiringActionTypes.SUBMIT_APPLICANT_PROFILE_REQUEST:
    case hiringActionTypes.CREATE_JOB_POST_REQUEST:
    case hiringActionTypes.UPDATE_JOB_POST_REQUEST:
    case hiringActionTypes.CREATE_JOB_APPLICATION_NOTE_REQUEST:
    case hiringActionTypes.DELETE_JOB_APPLICATION_NOTE_REQUEST:
    case hiringActionTypes.SUBMIT_WHITELISTING_WIDGET_REQUEST:
    case settingsActionTypes.SAVE_LOCATION_SETTINGS_REQUEST:
    case applicantMessengerActionTypes.FETCH_CONVERSATIONS_REQUEST:
    case applicantMessengerActionTypes.FETCH_CONVERSATION_MESSAGES_REQUEST:
    case teamEmailsActionTypes.FETCH_TEAM_EMAILS_REQUEST:
    case teamEmailsActionTypes.FETCH_TEAM_ANNOUNCEMENTS_REQUEST:
    case teamEmailsActionTypes.SEND_TEAM_EMAILS_REQUEST:
    case teamEmailsActionTypes.UNPIN_TEAM_ANNOUNCEMENT_REQUEST:
    case notificationActionTypes.FETCH_NOTIFICATIONS_REQUEST:
    case settingsActionTypes.UPDATE_CURRENT_USER_REQUEST:
    case hiringActionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_SUCCESS:
    case hiringActionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_FAILURE:
    case hiringActionTypes.FETCH_APPLICANT_DATA_SUCCESS:
    case hiringActionTypes.FETCH_APPLICANT_DATA_FAILURE:
    case hiringActionTypes.HIRE_APPLICATION_SUCCESS:
    case hiringActionTypes.HIRE_APPLICATION_FAILURE:
    case hiringActionTypes.FETCH_APPLICANT_PROFILE_SUCCESS:
    case hiringActionTypes.FETCH_APPLICANT_PROFILE_FAILURE:
    case hiringActionTypes.SUBMIT_APPLICANT_PROFILE_SUCCESS:
    case hiringActionTypes.SUBMIT_APPLICANT_PROFILE_FAILURE:
    case hiringActionTypes.CREATE_JOB_POST_SUCCESS:
    case hiringActionTypes.CREATE_JOB_POST_FAILURE:
    case hiringActionTypes.UPDATE_JOB_POST_SUCCESS:
    case hiringActionTypes.UPDATE_JOB_POST_FAILURE:
    case hiringActionTypes.CREATE_JOB_APPLICATION_NOTE_SUCCESS:
    case hiringActionTypes.CREATE_JOB_APPLICATION_NOTE_FAILURE:
    case hiringActionTypes.DELETE_JOB_APPLICATION_NOTE_SUCCESS:
    case hiringActionTypes.DELETE_JOB_APPLICATION_NOTE_FAILURE:
    case hiringActionTypes.SUBMIT_WHITELISTING_WIDGET_SUCCESS:
    case hiringActionTypes.SUBMIT_WHITELISTING_WIDGET_FAILURE:
    case settingsActionTypes.SAVE_LOCATION_SETTINGS_FAILURE:
    case settingsActionTypes.SAVE_LOCATION_SETTINGS_SUCCESS:
    case applicantMessengerActionTypes.FETCH_CONVERSATION_MESSAGES_SUCCESS:
    case applicantMessengerActionTypes.FETCH_CONVERSATIONS_SUCCESS:
    case applicantMessengerActionTypes.FETCH_CONVERSATION_MESSAGES_FAILURE:
    case teamEmailsActionTypes.FETCH_TEAM_EMAILS_SUCCESS:
    case teamEmailsActionTypes.FETCH_TEAM_EMAILS_FAILURE:
    case teamEmailsActionTypes.FETCH_TEAM_ANNOUNCEMENTS_FAILURE:
    case teamEmailsActionTypes.FETCH_TEAM_ANNOUNCEMENTS_SUCCESS:
    case teamEmailsActionTypes.SEND_TEAM_EMAILS_SUCCESS:
    case teamEmailsActionTypes.SEND_TEAM_EMAILS_FAILURE:
    case teamEmailsActionTypes.UNPIN_TEAM_ANNOUNCEMENT_SUCCESS:
    case teamEmailsActionTypes.UNPIN_TEAM_ANNOUNCEMENT_FAILURE:
    case notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
    case settingsActionTypes.UPDATE_CURRENT_USER_SUCCESS:
    case settingsActionTypes.UPDATE_CURRENT_USER_FAILURE:
    case timecardActionTypes.SUBMIT_TIMEBREAK_CHANGES_FAILURE:
      return state.remove(action.meta.requestId);

    default:
      return state;
  }
};
