import './CheckboxInput.scss';

import React, { PureComponent } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from 'fe-design-base/atoms/Checkbox';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import { colors, typography } from 'theme/constants';

import Box from 'components/Box';

import cxHelpers from 'util/className';

export const SIZES = ['sm', 'md'];

export const LABEL_PLACEMENT = ['start', 'end', 'top', 'bottom'];

@cxHelpers('CheckboxInput')
class CheckboxInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.bool,
    size: PropTypes.oneOf(SIZES),
    inline: PropTypes.bool,
    bigLabel: PropTypes.bool,
    thinLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    labelStyle: PropTypes.object,
    color: PropTypes.string,
    hideHtmlInput: PropTypes.bool,
    labelPlacement: PropTypes.oneOf(LABEL_PLACEMENT),
  };

  static defaultProps = {
    value: false,
    labelStyle: {},
    color: 'default',
    labelPlacement: 'end',
  };

  handleCheck = event =>
    !this.props.disabled &&
    this.props.onChange &&
    this.props.onChange(event.target.checked, event);

  render() {
    const {
      value,
      size,
      inline,
      bigLabel,
      thinLabel,
      disabled,
      label,
      labelStyle,
      color,
      style = {},
      hideHtmlInput,
      labelPlacement,
      ...checkboxProps
    } = this.props;
    const checkboxStyle = { ...style };
    const labelStyleFinal = {
      fontSize: typography.fs14,
      color: colors.navy,
    };

    if (inline) {
      labelStyleFinal.width = 'auto';
    }

    if (bigLabel) {
      labelStyleFinal.fontSize = typography.fs16;
      labelStyleFinal.fontWeight = typography.fw400;
    }

    if (thinLabel) {
      labelStyleFinal.fontWeight = typography.fw400;
    }

    const finalLabelStyle = { ...labelStyleFinal, ...labelStyle };

    return (
      <FormControlLabel
        classes={{
          root: this.cx({
            [size]: size,
            disabled,
            hideHtmlInput,
          }),
        }}
        style={finalLabelStyle}
        labelPlacement={labelPlacement}
        control={
          <Checkbox
            {...omit(checkboxProps, ['onChange'])}
            color={color}
            checked={!!value}
            onChange={this.handleCheck}
            style={checkboxStyle}
            disabled={disabled}
          />
        }
        label={
          <Box tag="span" style={finalLabelStyle}>
            {label}
          </Box>
        }
      />
    );
  }
}

export default CheckboxInput;
