import React, { PureComponent } from 'react';

import Icon from 'components/Icon';

import cxHelpers from 'util/className';

import NavItem from '../NavItem';

@cxHelpers('CloseSideNavButton')
export default class CloseSideNavButton extends PureComponent {
  render() {
    return (
      <NavItem className={this.cx()}>
        <Icon size="xl" type="closeSimple" />
      </NavItem>
    );
  }
}
