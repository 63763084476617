import baseColors from 'fe-design-base/styles/colors';

import { hexToRGBA } from 'util/hexToRGBA';

import { FlexProps, StyleProp } from './interface';

/**
 * In Typescript we cannot iterate over the type since there are no types in the runtime.
 * Hence we still need to keep this CLASS_PROPS array which contains same properties as ClassProps.
 */
export const CLASS_PROPS = [
  'absolute',
  'absoluteCover',
  'alignItemsBaseline',
  'alignItemsCenter',
  'alignItemsEnd',
  'alignItemsStart',
  'alignItemsStretch',
  'baseline',
  'block',
  'borderbox',
  'column',
  'columnreverse',
  'ellipsis',
  'fixed',
  'flex',
  'grid',
  'hcenter',
  'hide',
  'hleft',
  'hright',
  'inline',
  'inlineBlock',
  'justifyContentCenter',
  'justifyContentEnd',
  'justifyContentSpaceAround',
  'justifyContentSpaceBetween',
  'justifyContentSpaceEvenly',
  'justifyContentStart',
  'noWrap',
  'pointer',
  'pointerEventsNone',
  'relative',
  'bradiusfull',
  'bradiusl',
  'bradiusm',
  'bradiuss',
  'roundedMedium',
  'roundedSmall',
  'row',
  'rowreverse',
  'scrollX',
  'scrollY',
  'spacearound',
  'spacebetween',
  'spaceevenly',
  'sticky',
  'stretch',
  'tcenter',
  'tleft',
  'tright',
  'vcenter',
  'vtop',
  'vbottom',
  'wrap',
  'wrapreverse',
];

export const CLASS_PROP_SET = new Set(CLASS_PROPS);

export const COLOR_PROP_MAPPING: Record<string, string> = {
  color: 'color',
  bcolor: 'borderColor',
  bgcolor: 'backgroundColor',
};

export const DEFAULT_BOX_SHADOW_COLOR = baseColors.mono900;
export const SHADOW_OPACITY_Z1 = '0.08';
export const SHADOW_OPACITY_DEFAULT = '0.1';

export const SHADOW_DIRECTION_MAP: Record<string, { [key: string]: string }> = {
  bottom: {
    z1: '0 2px 4px',
    z2: '0 5px 6px',
    z3: '0 5px 16px',
    z4: '0 10px 24px',
  },
  top: {
    z1: '0 -2px 4px',
    z2: '0 -5px 6px',
    z3: '0 -5px 16px',
    z4: '0 -10px 24px',
  },
  right: {
    z1: '2px 0 4px',
    z2: '5px 0 6px',
    z3: '5px 0 16px',
    z4: '10px 0 24px',
  },
  left: {
    z1: '-2px 0 4px',
    z2: '-5px 0 6px',
    z3: '-5px 0 16px',
    z4: '-10px 0 24px',
  },
};
export const BOX_SHADOW_VARIANTS = ['z1', 'z2', 'z3', 'z4'] as const;
export const BOX_SHADOW_DIRECTIONS = Object.keys(SHADOW_DIRECTION_MAP);

export const STYLE_PROP_MAPPING: Record<
  string,
  | string
  | ((
      styleProps: Record<string, StyleProp>,
      value: StyleProp,
      allProps: FlexProps
    ) => void)
> = {
  b: 'border',
  bbw: 'borderBottomWidth',
  blw: 'borderLeftWidth',
  boxShadow: 'boxShadow',
  brw: 'borderRightWidth',
  bs: 'borderStyle',
  btw: 'borderTopWidth',
  bw: 'borderWidth',
  gridTemplateColumns: 'gridTemplateColumns',
  w: 'width',
  h: 'height',
  top: 'top',
  bottom: 'bottom',
  left: 'left',
  right: 'right',
  lh: 'lineHeight',
  m: 'margin',
  maxh: 'maxHeight',
  maxw: 'maxWidth',
  minh: 'minHeight',
  minw: 'minWidth',
  ml: 'marginLeft',
  mr: 'marginRight',
  mb: 'marginBottom',
  mh: (props, value) => {
    props.marginLeft = value;
    props.marginRight = value;
  },
  mt: 'marginTop',
  mv: (props, value) => {
    props.marginTop = value;
    props.marginBottom = value;
  },
  opacity: 'opacity',
  order: 'order',
  overflow: 'overflow',
  overflowX: 'overflowX',
  overflowY: 'overflowY',
  p: 'padding',
  pt: 'paddingTop',
  pb: 'paddingBottom',
  ph: (props, value) => {
    props.paddingLeft = value;
    props.paddingRight = value;
  },
  pl: 'paddingLeft',
  pv: (props, value) => {
    props.paddingTop = value;
    props.paddingBottom = value;
  },
  pr: 'paddingRight',
  grow: 'flexGrow',
  shadow: (props, value, { shadowDirection }: { shadowDirection?: string }) => {
    const dir = shadowDirection || 'bottom';
    const opacity = value === 'z1' ? SHADOW_OPACITY_Z1 : SHADOW_OPACITY_DEFAULT;
    props.boxShadow = `${
      SHADOW_DIRECTION_MAP[dir as keyof typeof SHADOW_DIRECTION_MAP][value]
    } ${hexToRGBA(DEFAULT_BOX_SHADOW_COLOR, opacity)}`;
  },
  shrink: 'flexShrink',
  textTransform: 'textTransform',
  basis: 'flexBasis',
  z: 'zIndex',
  zIndex: 'zIndex',
  gap: 'gap',
  visibility: 'visibility',
};
