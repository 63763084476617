import { showModal } from 'actions/modals';

import { BILLER_MOBILE_CHANGE_TIER_MODAL } from 'features/modals/constants';

export const createChangeTierAction = ({ biller }) =>
  showModal(BILLER_MOBILE_CHANGE_TIER_MODAL, {
    deprecatedModal: true,
    auto: true,
    biller,
    noPadding: true,
  });
