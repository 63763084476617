declare global {
  interface Window {
    DD_LOGS: any;
  }
}

type Props = {
  message: string;
  context?: Record<string, any>;
};

export const datadogLogInfo = ({ message, context }: Props) => {
  window.DD_LOGS?.onReady(() => {
    window.DD_LOGS.logger.info(message, context);
  });
};

export const datadogLogError = ({ message, context }: Props) => {
  window.DD_LOGS?.onReady(() => {
    window.DD_LOGS.logger.error(message, context);
  });
};
