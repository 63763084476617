import './AnnounceKitButton.scss';

import React, { useCallback, useMemo } from 'react';
import withCx from 'fe-core/util/withCx';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';
import {
  ELEMENTS,
  EVENT_ACTIONS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import AnnounceKit from './AnnounceKit';
import { WHATS_NEW_SIDEBAR_URL } from './constants';

const AnnounceKitButton = ({ cx, cxEl }) => {
  const trackUx = useTrackUx();
  const buttonText = useMemo(() => toI18n('nav_links.whats_new'), []);

  const handleClick = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      buttonText,
      element: ELEMENTS.WHATS_NEW_BUTTON,
    });
  }, [buttonText, trackUx]);

  return (
    <button className={cx()}>
      <AnnounceKit widget={WHATS_NEW_SIDEBAR_URL} onWidgetOpen={handleClick}>
        <Text
          component="span"
          variant="bodySm"
          color="mono300"
          className={cxEl('text')}
        >
          {buttonText}
        </Text>
      </AnnounceKit>
    </button>
  );
};

export default withCx('AnnounceKitButton')(AnnounceKitButton);
