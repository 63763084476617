import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { I18N_JOB_DETAILS_PATH } from '../../constants';

const { cx } = cxHelpers('JobRolesPlaceholder');

const Placeholder = ({ label, body }) => (
  <Box column gap={16}>
    <Text color="mono700" variant="bodySmBold">
      {label}
    </Text>
    <Text color="mono900" variant="body">
      {body}
    </Text>
  </Box>
);

const JobRolesPlaceholder = () => (
  <Box className={cx()}>
    <Box row spacebetween mb={20} pr={50}>
      <Placeholder
        label={toI18n(`${I18N_JOB_DETAILS_PATH}.role`)}
        body="••••••••••••"
      />
      <Placeholder
        label={toI18n(`${I18N_JOB_DETAILS_PATH}.wage`)}
        body={`${toI18n('number.currency.format.unit')} ••••`}
      />
      <Placeholder
        label={toI18n(`${I18N_JOB_DETAILS_PATH}.rate`)}
        body="••••••"
      />
    </Box>

    <Text color="mono700" variant="captions">
      {toI18n(`${I18N_JOB_DETAILS_PATH}.no_permission`)}
    </Text>
  </Box>
);

export default JobRolesPlaceholder;
