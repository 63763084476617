export const TRACK_EVENTS = {
  HIRING_DASHBOARD: 'Hiring Dashboard',
  HIRING_SUBNAV_DROPDOWN: 'Hiring Sub-Nav (drop-down)',
  HIRING_SUBNAV_BAR: 'Hiring Sub-Nav (bar)',
  MANAGE_APPLICANTS: 'Manage Applicants',
};

export const TRACK_ADD_APPLICANTS_ACTIONS = {
  CLICKED_ADD_APPLICANT: 'Clicked Add Applicant',
};

export const TRACK_PROPERTIES = {
  ANY_ROLE: 'Any Role',
};

export const TRACK_ADD_APPLICANTS_CATEGORY = {
  ADD_APPLICANTS_MANUALLY: 'Add Applicants Manually',
};
