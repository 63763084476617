import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgSecurity = ({
  alt = 'Security Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <g>
      <path
        fill="currentColor"
        d="M12.628.136a1.513 1.513 0 0 0-1.256 0L2.545 3.88C1.515 4.317.745 5.334.75 6.563c.023 4.65 1.936 13.157 10.012 17.024a2.863 2.863 0 0 0 2.476 0c8.076-3.867 9.989-12.374 10.012-17.024.005-1.229-.764-2.246-1.795-2.682L12.628.136Zm4.669 9.66-6 6a1.12 1.12 0 0 1-1.59 0l-3-3a1.125 1.125 0 0 1 1.59-1.59l2.203 2.205 5.203-5.208a1.125 1.125 0 0 1 1.59 1.59l.004.004Z"
      />
    </g>
    <defs>
      <clipPath>
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSecurity;
