import React from 'react';
import Icon from 'react-icon-base';

const Hired = props => (
  <Icon viewBox="0 0 135 121" {...props}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#EEE" d="M-362.5-199.5h859v1312h-859z" />
      <path
        fill="currentColor"
        d="M43.997 57.986a9.631 9.631 0 0 0 1.188 3.33c-.778 3.001-1.214 6.323-1.252 9.949.192 13.413 7.698 25.09 16.045 30.347v6.744h-9.29c-8.898-.095-14.587 6.388-16.888 12.645C19.951 110.559 11 94.123 11 75.48 11 44.345 36.52 19 68.422 19 99.48 19 125 44.345 125 75.48c0 18.71-9.014 35.2-22.8 45.52-2.744-6.175-8.96-12.739-16.889-12.644h-8.444v-6.744c7.841-5.3 15.318-16.96 15.2-30.347.02-3.198-.28-6.16-.855-8.873 1.054-1.085 1.715-3.031 1.904-4.406h.057c0-.174-.002-.347-.006-.52.004-.076.006-.149.006-.218h-.012c-.406-12.697-11.267-22.87-24.606-22.87-13.338 0-24.199 10.173-24.605 22.87h-.012c0 .07.002.142.006.217-.004.173-.006.347-.006.521h.06z"
      />
      <path
        fill="currentColor"
        d="M68.556 68.047c11.525-.094 18.806-2.287 22.761-5.581 1.457-1.214 2.27-4.064 2.27-5.487H43.524c0 1.568.986 4.549 2.736 5.855 4.145 3.095 10.77 5.307 22.295 5.213z"
      />
      <path
        fill="currentColor"
        d="M68.556 34.378c-13.596 0-24.618 10.198-24.618 22.778h49.235c0-12.58-11.022-22.778-24.617-22.778m0 .737c12.867 0 23.384 9.49 23.806 21.303H44.749c.422-11.813 10.94-21.303 23.807-21.303"
      />
      <path
        fill="currentColor"
        d="M73.12 54.877h-2.592v-5.555c0-.605-.157-1.032-.47-1.285-.313-.251-.77-.377-1.369-.377-.911 0-1.66.359-2.245 1.077v6.14H63.85V42.275h2.594v4.667c.353-.39.834-.724 1.44-1.001.605-.277 1.282-.417 2.03-.417 1.063 0 1.862.253 2.4.757.538.504.807 1.209.807 2.116v6.48z"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M68.158 13.955L80.6-.818l7.217 6.08-19.065 22.632-12.582-12.58 6.673-6.672 5.314 5.313z"
      />
      <path
        fill="currentColor"
        d="M68.627 24.94L59 15.314l3.844-3.843 5.44 5.439L80.842 2 85 5.502z"
      />
      <path
        fill="#FFF"
        d="M68.674 33.38c-1.507 0-2.728.367-2.728.818v.575c0 .452 1.22.819 2.728.819 1.507 0 2.727-.367 2.727-.82v-.574c0-.451-1.22-.818-2.727-.818"
      />
    </g>
  </Icon>
);

export default Hired;
