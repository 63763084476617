import React, { useCallback } from 'react';
import Button, { ButtonProps } from 'fe-design-base/molecules/Button';

import { publishOnConfirmAndPay } from './events';

const PurchaseMethodButton: React.FC<ButtonProps> = ({ onClick, ...props }) => {
  const handleOnClick = useCallback(
    // TODO: https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e => {
      onClick?.(e);
      publishOnConfirmAndPay();
    },
    [onClick]
  );
  return <Button {...props} onClick={handleOnClick} />;
};
export default PurchaseMethodButton;
