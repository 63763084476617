import React from 'react';
import Icon from 'react-icon-base';

const Applicants = props => (
  <Icon viewBox="0 0 239 121" {...props}>
    <g fill="none">
      <path d="M-310.5-199.5h859v1312h-859z" />
      <g fill="currentColor">
        <path d="M52 67.001c0 22.116 10.6 41.613 27 54 2.725-7.422 9.462-15.113 20-15h11v-8c-9.884-6.236-18.773-20.089-19-36 .227-22.13 12.967-34.715 29-35 15.4.285 28.14 12.87 28 35 .14 15.88-8.714 29.711-18 36v8h10c9.39-.113 16.75 7.673 20 15 16.326-12.244 27-31.805 27-54 0-36.934-30.22-67-67-67-37.78 0-68 30.066-68 67z" />
        <path d="M160 65.314c0 12.978 6.203 24.418 15.8 31.687 1.595-4.356 5.537-8.868 11.704-8.802h6.437v-4.694c-5.784-3.66-10.986-11.788-11.119-21.125.133-12.986 7.588-20.37 16.97-20.537 9.012.167 16.468 7.551 16.386 20.537.082 9.319-5.1 17.435-10.534 21.125v4.694h5.852c5.495-.066 9.802 4.502 11.704 8.802 9.554-7.185 15.8-18.663 15.8-31.687C239 43.642 221.315 26 199.793 26 177.685 26 160 43.642 160 65.314zM0 65.314c0 12.978 6.203 24.418 15.8 31.687 1.595-4.356 5.537-8.868 11.704-8.802h6.437v-4.694c-5.784-3.66-10.986-11.788-11.119-21.125.133-12.986 7.588-20.37 16.97-20.537 9.012.167 16.468 7.551 16.386 20.537.082 9.319-5.1 17.435-10.534 21.125v4.694h5.852C56.991 88.133 61.298 92.7 63.2 97 72.754 89.816 79 78.338 79 65.314 79 43.642 61.315 26 39.793 26 17.685 26 0 43.642 0 65.314z" />
      </g>
    </g>
  </Icon>
);

export default Applicants;
