import { addQuery } from './util';

const API_PREFIX = '/messaging/v2';

export const getChannelsApiRoute = ({ itemsPerPage, lastItemId }) => {
  let route = `${API_PREFIX}/channels.json`;

  if (itemsPerPage) route = addQuery(route, `item_count=${itemsPerPage}`);

  if (lastItemId) {
    route = addQuery(route, `item_id=${lastItemId}&direction=after`);
  }

  return route;
};

export const getChannelMessagesApiRoute = ({ channelId, page, perPage }) => {
  let route = `${API_PREFIX}/channels/${channelId}/messages.json`;

  if (page) route = addQuery(route, `page_number=${page}`);
  if (perPage) route = addQuery(route, `per_page=${perPage}`);

  return route;
};

export const getMessageReadReceiptsApiRoute = ({
  messageId,
  page,
  perPage,
}) => {
  let route = `${API_PREFIX}/messages/${messageId}/read_receipts`;

  if (page) route = addQuery(route, `page_number=${page}`);
  if (perPage) route = addQuery(route, `per_page=${perPage}`);

  return route;
};

export const createChannelApiRoute = () => `${API_PREFIX}/channels`;

export const updateChannelApiRoute = channelId =>
  `${API_PREFIX}/channels/${channelId}`;

export const markMessageAsReadApiRoute = messageId =>
  `${API_PREFIX}/messages/${messageId}/read_receipts`;

export const getLocationParticipantsApiRoute = ({
  page,
  filter,
  perPage,
  locationId,
}) => {
  let route = `/locations/${locationId}/participants?include_quick_access=true`;

  if (page) route = addQuery(route, `page_number=${page}`);

  if (perPage) route = addQuery(route, `per_page=${perPage}`);

  if (filter) route = addQuery(route, `filter=${filter}`);

  return route;
};

export const getChannelParticipantsApiRoute = ({
  page,
  filter,
  perPage,
  channelId,
  locationId,
}) => {
  let route = `${API_PREFIX}/channels/${channelId}/participants_invitable`;

  if (page) route = addQuery(route, `page_number=${page}`);

  if (locationId) route = addQuery(route, `location_id=${locationId}`);

  if (perPage) route = addQuery(route, `per_page=${perPage}`);

  if (filter) route = addQuery(route, `filter=${filter}`);

  return route;
};

export const createMessageApiRoute = channelId =>
  `${API_PREFIX}/channels/${channelId}/messages`;

export const deleteMessageApiRoute = messageId =>
  `${API_PREFIX}/messages/${messageId}`;

export const fetchChannelParticipantsApiRoute = ({
  page,
  perPage,
  channelId,
}) => {
  let route = `${API_PREFIX}/channels/${channelId}/participants`;

  if (page) route = addQuery(route, `page_number=${page}`);

  if (perPage) route = addQuery(route, `per_page=${perPage}`);

  return route;
};
