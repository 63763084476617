import { fromJS } from 'immutable';

import { actionTypes as applicantMessengerActionTypes } from 'actions/applicantMessenger';
import { actionTypes } from 'actions/hiring';

import * as constants from 'features/hiring/constants';

export default (state = constants.INITIAL_MANAGE_APPLICANTS_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN_HIRE_MODAL:
      return state.merge({
        hireModalIsOpen: true,
        hireModalApplicant: action.payload.applicant,
      });

    case actionTypes.CLOSE_HIRE_MODAL:
      return constants.INITIAL_MANAGE_APPLICANTS_STATE;

    case actionTypes.HIRE_APPLICATION_SUCCESS:
      return state.merge({ hiredUserId: action.payload.user_id });

    case actionTypes.FETCH_INITIAL_MANAGE_APPLICANTS_SUCCESS: {
      const { prefill_most_recent_conversation: conversation } = action.payload;
      let prefillLastMessageId = null;
      if (conversation) {
        prefillLastMessageId = fromJS(conversation.application_messages).getIn(
          [0, 'id'],
          null
        );
      }
      return state.merge({ prefillLastMessageId });
    }

    case applicantMessengerActionTypes.SEND_MESSAGE_SUCCESS: {
      const { prefill_last_message_id: prefillLastMessageId } = action.payload;
      if (!prefillLastMessageId) return state;

      return state.merge({ prefillLastMessageId });
    }

    default:
      return state;
  }
};
