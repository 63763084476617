import {
  chalk_play,
  cukoo_clock,
  hey_plane,
  hooray_banner,
} from 'fe-design-base/assets/gifsDeprecated/imageFiles';
import { GifProps } from 'fe-design-base/assets/types';
import Box from 'fe-design-base/atoms/Box';
const buildComponent = (name: string, args: GifProps, alt: string) => (
  <Box {...args}>
    <img src={name} style={{ width: '100%' }} alt={alt} />
  </Box>
);

export const ChalkPlay = (args: GifProps) =>
  buildComponent(chalk_play, args, 'Chalk Play');
export const CukooClock = (args: GifProps) =>
  buildComponent(cukoo_clock, args, 'Cukoo Clock');
export const HeyPlane = (args: GifProps) =>
  buildComponent(hey_plane, args, 'Hey Plane');
export const HoorayBanner = (args: GifProps) =>
  buildComponent(hooray_banner, args, 'Hooray Banner');
