import React from 'react';
import Icon from 'react-icon-base';

const UserO = props => (
  <Icon viewBox="0 0 48 48" {...props}>
    <g>
      <path
        fill="#70CAD9"
        d="M32.4 27c-2.69 0-3.984 1.5-8.4 1.5S18.3 27 15.6 27C8.644 27 3 32.644 3 39.6v3.9C3 45.984 5.016 48 7.5 48h33c2.484 0 4.5-2.016 4.5-4.5v-3.9C45 32.644 39.356 27 32.4 27zM42 43.5c0 .825-.675 1.5-1.5 1.5h-33c-.825 0-1.5-.675-1.5-1.5v-3.9c0-5.297 4.303-9.6 9.6-9.6 1.838 0 3.666 1.5 8.4 1.5 4.725 0 6.563-1.5 8.4-1.5 5.297 0 9.6 4.303 9.6 9.6v3.9zM24 24c6.628 0 12-5.372 12-12S30.628 0 24 0 12 5.372 12 12s5.372 12 12 12zm0-21c4.96 0 9 4.04 9 9s-4.04 9-9 9-9-4.04-9-9 4.04-9 9-9z"
      />
    </g>
  </Icon>
);

export default UserO;
