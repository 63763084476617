export const REQUEST_CALLBACK = '/monetization/click_to_call/request_support';

export const DOWNGRADE_REQUEST_CALLBACK = '/request_callback';

export const SLICE_NAME = 'newBusinessKit';
export const NBK_TRACKING = {
  MODAL_VERSION: 'new_business_kit',
  MODAL_SOURCE: 'manage_plan_page',
};

export const NBK_DOWNGRADE_BOTTOM_DRAWER = 'NBK_DOWNGRADE_BOTTOM_DRAWER';
