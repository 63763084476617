export const FEIN_VERIFICATION_STATUS = {
  processing: 'processing',
  verified: 'verified',
  rejected: 'rejected',
  final_rejected: 'final_rejected',

  /* NOT CHECK related, transient status for indicating when a rejected user has
   * made changes and is "waiting" to initiate another verification by clicking
   * the next button
   */
  waiting: 'waiting',

  /* NOT CHECK related, transient status for indicating the processing time has been
   * more than 1 minute
   */
  expired: 'expired',
};

export const NEXT_STEP_VALID_FEIN_VERIFICATION_STATUS = {
  [FEIN_VERIFICATION_STATUS.verified]: true,
  [FEIN_VERIFICATION_STATUS.final_rejected]: true,
  [FEIN_VERIFICATION_STATUS.expired]: true,
};

export const COMPANY_DETAILS_SLUGS = [
  'business_legal_name',
  'federal_employer_identification_number',
  'business_phone_number',
  'dba_or_trade_name',
  'company_industry',
  'average_total_payroll_amount',
  'business_type',
];

export const COMPANY_SOCIAL_DATA_SLUGS = ['website', 'social_media'];

export const COMPANY_ADDRESS_SLUGS = [
  'business_legal_address_line1',
  'business_legal_address_line2',
  'business_legal_address_city',
  'business_legal_address_state',
  'business_legal_address_postal_code',
];

export const WORKPLACE_ADDRESS_SLUGS = [
  'workplace_legal_address_line1',
  'workplace_legal_address_line2',
  'workplace_legal_address_city',
  'workplace_legal_address_state',
  'workplace_legal_address_postal_code',
];
