export const showUpgradeModal = (
  minTier,
  feature,
  locationProperty,
  serverKey,
  target,
  stripeEnrolled,
  mppFocusViewProps
) => {
  window.AccessBox.showFeature({
    minTier,
    feature,
    locationProperty,
    serverKey,
    target,
    stripeEnrolled,
    mppFocusViewProps,
  });
};
