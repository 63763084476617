import { SVGIconProps } from 'fe-design-base/atoms/Icon/Icon';
const SvgQuestion = ({
  alt = 'Question Icon',
  id,
  screenReadable = false,
}: SVGIconProps) => (
  <svg
    {...(screenReadable
      ? { role: 'img', 'aria-labelledby': id }
      : { 'aria-hidden': 'true' })}
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    {screenReadable && <title id={id}>{alt}</title>}
    <path
      fill="currentColor"
      d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0Zm0 18.75a1.48 1.48 0 0 1-1.5-1.5c0-.844.614-1.5 1.5-1.5.802 0 1.5.656 1.5 1.5s-.698 1.5-1.5 1.5Zm3.24-6.656-2.115 1.312v.094A1.14 1.14 0 0 1 12 14.625a1.14 1.14 0 0 1-1.125-1.125v-.75c0-.375.188-.75.563-.984l2.671-1.594c.329-.188.516-.516.516-.89 0-.563-.51-1.032-1.073-1.032h-2.396c-.604 0-1.031.469-1.031 1.031A1.14 1.14 0 0 1 9 10.406a1.14 1.14 0 0 1-1.125-1.125C7.875 7.453 9.328 6 11.115 6h2.394c1.913 0 3.366 1.453 3.366 3.281 0 1.125-.61 2.203-1.636 2.813Z"
    />
  </svg>
);
export default SvgQuestion;
