import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import { List } from 'immutable';

import SelectInput from 'components/forms/SelectInput';
import { TabletAndBelow } from 'components/MediaQuery';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
const { cx } = cxHelpers('PaymentModuleCreditCardPicker');

export const PaymentMethodModuleCreditCardPicker = ({
  creditCards,
  value,
  onChange,
}: PaymentMethodModuleCreditCardPickerProps) => {
  const hasMultipleCards = creditCards.size > 1;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let cardOptions: Iterable<number, { label: string; value: string }> = [];

  if (hasMultipleCards) {
    cardOptions.push({
      value: creditCards.first().get('payment_method_id'),
      label: `${creditCards.first().get('label')} - ${toI18n(
        'biller.change_payment_method.most_recently_added'
      )}`,
    });
    const remainingCards = creditCards.rest().toJS();
    remainingCards.forEach((card: any) =>
      cardOptions.push({
        value: card.payment_method_id,
        label: card.label,
      })
    );
  } else {
    cardOptions = creditCards.map(card => ({
      value: card.get('payment_method_id'),
      label: card.get('label'),
    }));
  }

  return (
    <TabletAndBelow>
      {(tabletAndBelow: boolean) => (
        <Box mb={tabletAndBelow ? 16 : 20} className={cx()} maxw={296}>
          <SelectInput
            options={cardOptions}
            value={value}
            onChange={onChange}
            hideClear
          />
        </Box>
      )}
    </TabletAndBelow>
  );
};

interface PaymentMethodModuleCreditCardPickerProps {
  creditCards: List<any>;
  value: string;
  onChange: (...args: unknown[]) => void;
}
