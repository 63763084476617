import { ACTIONS_LIST_DRAWER_KEY } from 'features/drawers/keys';

export const actionTypes = {
  CLOSE_DRAWER: 'DRAWERS/CLOSE_DRAWER',
  OPEN_DRAWER: 'DRAWERS/OPEN_DRAWER',
  ACTIONS_LIST_DRAWER: 'DRAWERS/ACTIONS_LIST_DRAWER',
  HEALTH_REPORT_EXPORT_DRAWER: 'DRAWERS/HEALTH_REPORT_EXPORT_DRAWER',
};

// If no drawer key is passed, will close any open drawer
export const closeDrawer = drawerKey => ({
  type: actionTypes.CLOSE_DRAWER,
  payload: { drawerKey, drawerOpen: false },
});

export const openDrawer = drawerKey => ({
  type: actionTypes.OPEN_DRAWER,
  payload: { drawerKey, drawerOpen: true },
});

export const toggleActionsListDrawer = (show, drawerProps = {}) => ({
  type: actionTypes.ACTIONS_LIST_DRAWER,
  payload: {
    drawerKey: ACTIONS_LIST_DRAWER_KEY,
    drawerOpen: !!show,
    drawerProps,
  },
});
