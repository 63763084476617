import {
  DividerType,
  GroupLabelType,
  OptionType,
} from 'fe-design-base/atoms/Select/Select';

type ShouldRenderPlaceholderProps = {
  placeholder?: string;
  selected?: string | number | readonly (string | number)[];
};
export const shouldRenderPlaceholder = ({
  placeholder,
  selected,
}: ShouldRenderPlaceholderProps) =>
  Boolean(
    placeholder &&
      (selected === '' ||
        selected == null ||
        (Array.isArray(selected) && selected?.length === 0))
  );

type ShouldShowApplySectionProps = {
  showApplyActions?: boolean;
  multiple?: boolean;
  displayAsText?: boolean;
};

export const shouldShowApplySection = ({
  showApplyActions,
  multiple,
  displayAsText,
}: ShouldShowApplySectionProps): boolean =>
  Boolean(showApplyActions && multiple && displayAsText);

type ShowClearIconProps = {
  value: string | number | readonly (string | number)[];
  showApplyActions?: boolean;
  clearIcon?: boolean;
  disabled?: boolean;
};

export const shouldShowClearIcon = ({
  value,
  showApplyActions,
  clearIcon,
  disabled,
}: ShowClearIconProps): boolean => {
  const hasValue = Array.isArray(value)
    ? value.length > 0
    : value === 0 || !!value;

  return Boolean(hasValue && !showApplyActions && clearIcon && !disabled);
};

export const isOptionType = (
  option: OptionType | DividerType | GroupLabelType
): option is OptionType => 'value' in option;
