import './Loader.scss';

import React, { PureComponent } from 'react';
import { CSSTransition } from 'react-transition-group';
import { keys, omit } from 'lodash';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import LoadingIndicator from 'components/LoadingIndicator';

import cxHelpers from 'util/className';

class TransitionWrapper extends PureComponent {
  componentWillUnmount() {
    this.props.handleTransitionEnd();
  }

  render() {
    return this.props.children;
  }
}

@cxHelpers('Loader')
class Loader extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    size: PropTypes.number,
    showIndicator: PropTypes.bool,
    centered: PropTypes.bool,
    centeredRelative: PropTypes.bool,
    centeredAbsolute: PropTypes.bool,
    onTransitionEnd: PropTypes.func,
    type: PropTypes.string,
  };

  static defaultProps = {
    showIndicator: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      transitionEnd: true,
    };
  }

  // TODO: https://joinhomebase.atlassian.net/browse/HIRING-441
  UNSAFE_componentWillReceiveProps({ isLoading }) {
    if (isLoading !== this.props.isLoading) {
      this.setState({ transitionEnd: false });
    }
  }

  handleTransitionEnd = () => {
    this.setState({ transitionEnd: true }, () => {
      if (this.props.onTransitionEnd) {
        this.props.onTransitionEnd();
      }
    });
  };

  renderLoader = () => {
    const {
      isLoading,
      size,
      centered,
      type,
      centeredRelative,
      centeredAbsolute,
    } = this.props;
    const { transitionEnd } = this.state;

    if (isLoading && transitionEnd) {
      return (
        <TransitionWrapper
          key="loader"
          handleTransitionEnd={this.handleTransitionEnd}
        >
          <LoadingIndicator
            size={size}
            className={this.cx({ centered, centeredAbsolute })}
            type={type}
            centered={centeredRelative}
          />
        </TransitionWrapper>
      );
    }

    return null;
  };

  renderChild = () => {
    const { isLoading, children } = this.props;
    const { transitionEnd } = this.state;

    if (!isLoading && transitionEnd) {
      return (
        <TransitionWrapper
          key="child"
          handleTransitionEnd={this.handleTransitionEnd}
        >
          {children}
        </TransitionWrapper>
      );
    }

    return null;
  };

  wrapWithTransitionGroup = children => {
    // Transition group causes issues in test, just use a plain ol' <div> to skip transitions
    if (window.Homebase && window.Homebase.env === 'test') {
      return <div>{children}</div>;
    }

    return (
      <CSSTransition classNames="Loader__transition" timeout={500}>
        {children}
      </CSSTransition>
    );
  };

  render() {
    const passthroughProps = omit(this.props, keys(Loader.propTypes));

    return this.wrapWithTransitionGroup(
      <Box {...passthroughProps}>
        {this.props.showIndicator ? this.renderLoader() : null}
        {this.renderChild()}
      </Box>
    );
  }
}

export default Loader;
