import {
  CLASS_PROP_SET,
  EXCLUDED_PROPS,
  STYLE_PROP_MAPPING,
} from './constants';
import { ClassProps, StyleProp, StyleProps } from './interface';

export const extractProps = (
  props: Record<string, ClassProps | StyleProps>
): Record<string, ClassProps | StyleProps> =>
  Object.keys(props).reduce(
    (memo, value) => {
      const classProp = CLASS_PROP_SET.has(value);
      const styleProp = STYLE_PROP_MAPPING[value];

      /** Class props */
      if (CLASS_PROP_SET.has(value)) memo.classes[value] = props[value];

      /** Style props */
      if (styleProp) {
        if (styleProp instanceof Function) {
          styleProp(
            memo.style as Record<string, StyleProp>,
            props[value] as StyleProp
          );
        } else {
          memo.style[styleProp] = props[value];
        }
      }

      /** Other props e.g. onClick, onTouchStart */
      if (!classProp && !styleProp && !EXCLUDED_PROPS[value])
        memo[value] = props[value] as any;

      return memo;
    },
    {
      classes: {} as Record<string, ClassProps>,
      style: {} as Record<string, StyleProps>,
    } as Record<string, Record<string, ClassProps | StyleProps>>
  );

export const getProps = (
  props: Record<string, ClassProps | StyleProps>,
  cx: (mods: Record<string, unknown>, className?: string) => string,
  className?: string
): any => {
  const { style, classes, ...others } = extractProps(props);

  return {
    className: cx(classes, className),
    style,
    ...others,
  };
};
