import React from 'react';
import Icon from 'react-icon-base';

const Decline = props => (
  <Icon viewBox="0 0 36 36" {...props}>
    <g id="Canvas" transform="translate(-3767 -1283)">
      <g id="X icon">
        <g id="Vector">
          <use
            xlinkHref="#decline_path0_fill"
            transform="translate(3767 1283)"
            fill="#959EAC"
          />
        </g>
        <g id="NEW X">
          <g id="Rectangle">
            <use
              xlinkHref="#decline_path1_fill"
              transform="matrix(0.707107 0.707107 -0.707107 0.707107 3778.06 1293)"
              fill="#959EAC"
            />
          </g>
          <g id="Rectangle">
            <use
              xlinkHref="#decline_path1_fill"
              transform="matrix(0.707107 -0.707107 0.707107 0.707107 3777 1301.57)"
              fill="#959EAC"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <path
        id="decline_path0_fill"
        d="M 14.9538 28.75C 22.5118 28.75 28.6615 22.5813 28.6615 15C 28.6615 7.41875 22.5118 1.25 14.9538 1.25C 7.39592 1.25 1.24615 7.41875 1.24615 15C 1.24615 22.5813 7.39592 28.75 14.9538 28.75ZM 14.9538 0C 23.1996 0 29.9077 6.72875 29.9077 15C 29.9077 23.2713 23.1996 30 14.9538 30C 6.70805 30 0 23.2713 0 15C 0 6.72875 6.70805 0 14.9538 0Z"
      />
      <path
        id="decline_path1_fill"
        d="M 0 0.75C 0 0.335786 0.335786 0 0.75 0L 11.3675 0C 11.7817 0 12.1175 0.335786 12.1175 0.75L 12.1175 0.75C 12.1175 1.16421 11.7817 1.5 11.3675 1.5L 0.75 1.5C 0.335787 1.5 0 1.16421 0 0.75L 0 0.75Z"
      />
    </defs>
  </Icon>
);

export default Decline;
